import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PartnershipsService} from '../../../../services/partnerships.service';
import {TranslationService} from '../../../../services/translation.service';
import {ActivatedRoute} from '@angular/router';
import {LocaleService} from '../../../../services/locale.service';
import {environment} from '../../../../../environments/environment';


@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss'],
    providers: [NgbActiveModal, PartnershipsService]
})
export class PartnersComponent implements OnInit {

    public societies_list: Array<any> = [];
    public journals_list: Array<any> = [];
    public magazines_list: Array<any> = [];
    public locale: string;
    public nav_location: string;
    public magazine: any;
    private env: any;

    environment = environment;

    constructor(private modalService: NgbModal,
                public activeModal: NgbActiveModal,
                private activatedRoute: ActivatedRoute,
                public partnershipsService: PartnershipsService,
                public localeService: LocaleService,
                public translationService: TranslationService) {
        this.env = environment;
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.getSocieties();
        this.getJournals();
        this.getMagazines();
    }

    getSocieties(): void {
        this.partnershipsService.get(this.env.congress_id, 'societies')
            .subscribe(r => {
                this.societies_list = r.map(v => v.scientific_society);
                this.setNavLocation();
            });
    }

    getMagazines(): void {
        this.partnershipsService.get(this.env.congress_id, 'magazines', true, true)
            .subscribe(r => {
                this.magazines_list = r.map(v => v.publication);
                this.setNavLocation();
            });
    }

    getJournals(): void {
        this.partnershipsService.get(this.env.congress_id, 'journals', true, true)
            .subscribe(r => {
                this.journals_list = r.map(v => v.publication);
                this.setNavLocation();
            });
    }

    setNavLocation() {
        this.activatedRoute.params.subscribe(params => {
            this.nav_location = params['tab'] || 'societies';
            if (this.nav_location === 'societies' && this.societies_list.length === 0) {
                if (this.magazines_list.length > 0) {
                    this.nav_location = 'magazines';
                } else if (this.journals_list.length > 0) {
                    this.nav_location = 'journals';
                }
            } else if (this.nav_location === 'magazines' && this.magazines_list.length === 0) {
                if (this.societies_list.length > 0) {
                    this.nav_location = 'societies';
                } else if (this.journals_list.length > 0) {
                    this.nav_location = 'journals';
                }
            } else if (this.nav_location === 'journals' && this.journals_list.length === 0) {
                if (this.societies_list.length > 0) {
                    this.nav_location = 'societies';
                } else if (this.magazines_list.length > 0) {
                    this.nav_location = 'magazines';
                }
            }
        });

    }

    open(content, magazine) {
        this.magazine = magazine;
        const modal = this.modalService.open(content, {windowClass: 'modal-wide'});
    }
}

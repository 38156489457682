import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationMetaModel} from "../../models/pagination-meta.model";
import {placeholdersToParams} from "@angular/compiler/src/render3/view/i18n/util";

@Component({
  selector: 'app-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.scss']
})
export class PaginationListComponent implements OnInit {
  @Input() meta: PaginationMetaModel;
  @Input() displayedPagesNumber: number = 3;
  @Output() actionEvent: EventEmitter<{action: string, param: number}> = new EventEmitter();
  totalPagesNumber: number = 1;
  currentPage: number = 1;
  lastPage: number = 1;
  firstPage: number = 1;
  pagesNumbers: number[] = [];
  gapPagesNumber: number = 1;
  constructor() { }

  ngOnInit(): void {
    this.currentPage = this.meta.current_page;
    this.gapPagesNumber = Math.round(this.displayedPagesNumber / 2);
    this.firstPage = 1;
    this.totalPagesNumber = Math.ceil(this.meta.total / this.meta.per_page);
    this.lastPage = Math.ceil(this.meta.total / this.meta.per_page);
    this.pagesNumbers = Array.from({
      length: this.totalPagesNumber
    }, (v, i) => i + 1);
  }

  emitAction(action, param?) {
    this.actionEvent.emit({action: action, param: param});
  }

}

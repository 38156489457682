<button (click)="connect()" [routerLink]="profile && profile.friendship_status_with_auth === 'accept_friend_request' ? '/account/connections' : null"
        *ngIf="(!user || user.id !== profile.id) && (profile.friendship_status_with_auth !== 'friend' && profile.friendship_status_with_auth !== 'friendship_refused')"
        class="button button-connect"
        [ngClass]="{'connected': profile && profile.friendship_status_with_auth === 'waiting_friend_accept'}"
        [ngbTooltip]="profile.friendship_status_with_auth === 'waiting_friend_accept' ? ('academy.Already_sent_request'|translate) : ( profile.friendship_status_with_auth === 'accept_friend_request' ? ('alert.Accept_connection_request'|translate) : '')">
    <i class="fa fa-check" aria-hidden="true"></i>
    <i class="fa fa-plus" aria-hidden="true"></i>
    <svg version=" 1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="922px" height="467.777px" viewBox="0 0 922 467.777" enable-background="new 0 0 922 467.777" xml:space="preserve">
					<path fill="#FFFFFF" d="M914.771,416.097c-12.948-66.822-191.163-59.719-234.113-112.057c-9.059-11.047-9.059-11.047-2.986-52.062 c10.367-45.912,19.762-17.417,34.584-108.99C724.704,66.083,698.144,0,617.5,0c-80.643,0-107.204,66.083-94.756,142.988
						c14.822,91.573,24.21,63.078,34.584,108.99c6.07,41.018,6.07,41.018-2.986,52.062c-42.949,52.338-221.165,45.232-234.115,112.057
						c-1.536,7.908-8.727,51.678-8.727,51.678h612C923.5,467.775,916.309,424.005,914.771,416.097z"></path>
				</svg>
</button>
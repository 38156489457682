import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {PusherService, PusherStatus} from 'app/services/pusher.service';


@Component({
    selector: 'app-pusher-chat',
    templateUrl: './pusher-chat.component.html',
    styleUrls: ['./pusher-chat.component.scss'],
    providers: [PusherService]
})

export class PusherChatComponent implements OnChanges, OnInit, AfterViewInit {
    @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;
    @Input() comments: any[] = [];
    commentToSend: string = '';
    banned: boolean = false;
    show: boolean = false;
    shownComments: any[] = [];
    @Input() channels: string[];
    @Input() statusWeb: string[];
    @Input() webinarId: number;
    @Output() status: EventEmitter<PusherStatus> = new EventEmitter<PusherStatus>();

    constructor(private pusherService: PusherService) {
    }

    ngOnInit() {
        const comments = [];
        for (const key of Object.keys(this.comments)) {
            this.comments[key].comment = this.urlify(this.comments[key].comment);
            comments.push(this.comments[key]);
        }
        this.comments = comments;
        this.comments.sort((a, b) => (
            b.pin - a.pin
        ));

        this.shownComments = this.comments.slice(Math.max(this.comments.length - 50, 0))
        this.scrollToBottom();
        this.pusherService.commentsStream$
            .subscribe(upcomingComment => {
                switch (upcomingComment.type) {
                    case 'new': {
                        upcomingComment.comment = this.urlify(upcomingComment.comment);
                        if ( !upcomingComment.is_question_live) {
                            this.comments.push(upcomingComment);
                            this.shownComments = this.comments.slice(Math.max(this.comments.length - 50, 0))
                            this.scrollToBottom();
                        }
                        break;
                    }
                    case 'edit': {
                        const foundComment = this.comments.find(comment => comment.id === upcomingComment.id);
                        if (foundComment) {
                            upcomingComment.comment = this.urlify(upcomingComment.comment);
                            if (upcomingComment.pin === 1) {
                              this.comments.filter(c => c.id !== upcomingComment.id).map((comment) => {
                                comment.pin = 0
                              })
                            }
                            this.comments[this.comments.indexOf(foundComment)] = upcomingComment;
                            this.shownComments = this.comments.slice(Math.max(this.comments.length - 50, 0))
                        }
                        break;
                    }
                    case 'delete': {
                        this.comments = this.comments.filter(comment => comment.id != upcomingComment.id);
                        this.shownComments = this.comments.slice(Math.max(this.comments.length - 50, 0))
                        break;
                    }
                }
            });


        this.pusherService.statusStream$
            .subscribe(newStatus => {
                this.show = newStatus.status === 'launched' ? true : false;
                this.status.emit(newStatus);
                this.scrollToBottom();
            });
    }

    sendMessage(event) {
        if (event.keyCode === 13) {
            this.sendComment(this.commentToSend);
            this.commentToSend = null;
        }
    }

    ngAfterViewInit() {
        this.scrollToBottom();
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })

    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (const p in changes) {
            if (p === 'channels' && changes[p].currentValue && !changes[p].previousValue) {
                this.pusherService.initializePusher(changes[p].currentValue);
            }
        }
    }

    private sendComment(commentToSend: string) {
        this.pusherService.sendComment(this.webinarId, commentToSend);
    }

    scrollToBottom(): void {
        try {
            setTimeout(() => {
                if (this.myScrollContainer) {
                    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
                }
            }, 50);
        } catch (err) {
        }
    }
}

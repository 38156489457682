import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {User} from '../../../../models/user.model';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'account-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
  providers: [
    UserService,
  ]
})
export class AccountCertificationsComponent implements OnInit {
  user: User;
  locale: string;

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.user = this.authService.getCurrentUser();
  }
}

import {AuthService} from 'app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {TranslationService} from 'app/services/translation.service';
import {AlertService} from 'app/services/alert.service';
import {DateMomentService} from 'app/services/date-moment.service';
import {MediaService} from 'app/services/media.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocaleService} from '../../../services/locale.service';
import {User} from '../../../models/user.model';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {LocalStorage} from '@ngx-pwa/local-storage';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    providers: [
        AlertService,
        MediaService
    ]
})
export class AlertComponent implements OnInit {
    groups: any = [];
    userGroups: any = [];
    groups$: Observable<any>;
    locale: any;
    cases: any;
    highCase: any;
    loading: any;
    error: string | boolean;
    user: User;
    environment: any = environment;
    group: any;
    modalNotPremium: any;
    dataReceivedFromWeb = [];

    constructor(private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                private modalService: NgbModal,
                public translationService: TranslationService,
                public dateMomentService: DateMomentService,
                private translate: TranslateService,
                public authService: AuthService,
                public localeService: LocaleService,
                private router: Router,
                protected localStorage: LocalStorage) {
    }

    ngOnInit() {

        this.locale = this.localeService.currentLocale();
        this.authService.isLoggedIn().subscribe(logged => {
            if (logged) {
                this.localStorage.getItem <any>('ALERT-cases_' + environment.domain).subscribe((data) => {
                    if (!('cases' in this.dataReceivedFromWeb) && data) {
                        this.setDataCases(data);
                    }
                });

                this.alertService.getCases(false, false, false, false, false, 3).subscribe(cases => {
                    this.setDataCases(cases);
                    this.dataReceivedFromWeb['cases'] = true;
                    this.localStorage.setItem('ALERT-cases_' + environment.domain, cases).subscribe(() => {
                    });
                });

                this.alertService.getCase(1432).subscribe(highCases => {
                    this.highCase = highCases;
                });

                this.localStorage.getItem <any>('ALERT-userGroups_' + environment.domain).subscribe((data) => {
                    if (!('userGroups' in this.dataReceivedFromWeb) && data) {
                        this.setDataCases(data);
                    }
                });

                this.alertService.getUserGroups(this.locale).subscribe(groups => {
                    this.setDataUserGroups(groups);
                    this.dataReceivedFromWeb['userGroups'] = true;
                    this.localStorage.setItem('ALERT-userGroups_' + environment.domain, groups).subscribe(() => {
                    });
                })
            }
        });

        this.user = this.authService.getCurrentUser();
        this.error = false;
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.error === 'not-physician') {
                this.translate.get('alert.Error_not_doctor').subscribe(res => {
                    this.error = res;
                })
            }
        });
        let params = {};
        const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        if (w > 767) {
            params = {with_group: '0', with_last_case: '1'};
        }

        this.localStorage.getItem<any>('ALERT-groups_' + environment.domain).subscribe((data) => {
            if (!('groups' in this.dataReceivedFromWeb) && data) {
                this.setDataGroups(data);

            }
        });

        this.alertService.getGroups({...params, front_locale: this.locale}).subscribe(groups => {
            this.setDataGroups(groups);

            this.dataReceivedFromWeb['groups'] = true;
            this.localStorage.setItem('ALERT-groups_' + environment.domain, groups).subscribe(() => {
            });
        });

    }

    open(content) {
        this.modalNotPremium = this.modalService.open(content);
    }

    openAlertList(group) {
        this.authService.isLoggedIn().subscribe(logged => {
            if (logged && !this.error) {
                this.router.navigate(['/alert', group.slug]);
            } else if (!this.error) {
                this.router.navigate(['/account/login']);
            }
        });
    }

    setDataCases(cases) {
        this.cases = cases.data;
    }

    setDataUserGroups(groups) {
        this.userGroups = groups;
    }

    setDataGroups(groups) {
        this.groups = groups;
        this.authService.isLoggedIn().subscribe(logged => {
            if (groups.length === 1 && logged) {
                return this.openAlertList(groups[0]);
            } else if (groups.length === 1) {
                this.group = groups[0];
            }
        });
    }
}

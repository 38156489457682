import {ArticleModel} from 'app/models/article.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class ArticleService {

  private articleEndpoint: string = environment.apiURL + '/articles';

  constructor(private httpClient: HttpClient) { }

  public getArticle(id: number, params?: any): Observable<any> {
    const reqOpts = {
      params: new HttpParams()
    };
    if (params) {
        for (const k in params) {
            if (params.hasOwnProperty(k)) {
                reqOpts.params = reqOpts.params.set(k, '' + params[k]);
            }
        }
    }
    return this.httpClient.get<any>(this.articleEndpoint + '/' + id, reqOpts).pipe(shareReplay());
  }

  public getArticles(company_profile_ids?: Array<number>, params?: any): Observable<any> {
    const reqOpts = {
      params: new HttpParams()
    };
    if (params) {
        for (const k in params) {
            if (params.hasOwnProperty(k) && params[k] !== undefined) {
                reqOpts.params = reqOpts.params.set(k, '' + params[k]);
            }
        }
    }
    if (company_profile_ids && company_profile_ids.length > 0) {
      reqOpts.params = reqOpts.params.set('company_profile_ids', company_profile_ids.join(','));
    }

    return this.httpClient.get<any>(this.articleEndpoint, reqOpts).pipe(shareReplay());
  }

  getHeaderArticles(): Observable<ArticleModel[]> {
    return this.httpClient
      .get<ArticleModel[]>(this.articleEndpoint, {
        params: new HttpParams().set('zone', 'HOMEPAGE').set('per_page', '4')
      })
      .pipe(shareReplay());
  }
}

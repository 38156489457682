import {Thread} from 'app/models/thread.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, shareReplay} from 'rxjs/operators';

@Injectable()
export class ThreadService {
    private endpoint: string = '/threads';
    public messagesUnreadCount = new BehaviorSubject<number>(0);

    constructor(
        private httpClient: HttpClient
    ) { }

    getNewThreads(): Observable<Thread[]> {
        return this.httpClient.get<Thread[]>(environment.apiURL + this.endpoint + '/new').pipe(shareReplay());
    }

    getThreads(term: string, params?: any): Observable<Thread[]> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k) && params[k]) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<Thread[]>(environment.apiURL + this.endpoint, reqOpts)
            .pipe(map(results => {
                return results.filter(thread => {
                    if (thread.participant && thread.participant.user) {
                        if ((thread.participant.user.title + ' ' + thread.participant.user.first_name +
                            ' ' + thread.participant.user.last_name).toLowerCase().indexOf(term.toLowerCase()) > -1) {
                            return true;
                        }
                    }
                    return false;
                })
                    .sort(function (a, b) {
                        return (a.last_message && a.last_message.read === false && b.last_message && b.last_message.read === true) ? -1 :
                            (a.last_message && a.last_message.read === true && b.last_message && b.last_message.read === false) ? 1 :
                                (a.participant && b.participant && a.participant.last_read < b.participant.last_read ? 1 :
                                    a.participant && b.participant && a.participant.last_read > b.participant.last_read ? -1 : 0);
                    });
            }), shareReplay());
    }

    getThread(threadId): Observable<Thread> {
        return this.httpClient.get<Thread>(environment.apiURL + this.endpoint + '/' + threadId)
            .pipe(shareReplay());
    }

    sendMessage(message: string, threadId: number): Observable<any> {
        return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/' + threadId + '/messages', {body: message})
            .pipe(shareReplay());
    }

    newThread(userId): Observable<Thread> {
        return this.httpClient.post<Thread>(environment.apiURL + this.endpoint, {user_id: userId})
            .pipe(shareReplay());
    }

    readThread(threadId): Observable<Thread> {
        return this.httpClient.put<Thread>(environment.apiURL + this.endpoint + '/' + threadId + '/read', null)
            .pipe(shareReplay());
    }

    getMessagesUnreadCount() {
        this.getNewThreads().subscribe(
            newThreads => {
                this.messagesUnreadCount.next(newThreads.length);
            });
    }

    observeMessagesUnreadCount(): Observable<number> {
        return this.messagesUnreadCount.asObservable();
    }
}

<app-header-imcas *ngIf="environment.domain === 'aesthetics' && !environment.mobile"
                  [headerMenuOpened]="headerMenuOpened"
                  [logo]="logo"
                  (loggedOutClick)="openLogoutConfirmation()"
></app-header-imcas>
<app-header-aop *ngIf="environment.domain === 'ophthalmology' && !environment.mobile"
                [headerMenuOpened]="headerMenuOpened"
                [logo]="logo"
                (loggedOutClick)="openLogoutConfirmation()"
></app-header-aop>
<app-mobile-menu *ngIf="environment.mobile && !router.url.includes('/home') && !router.url.includes('/account/login') &&
        !router.url.includes('/account/create') && !router.url.includes('/account/create/step-2') &&
        !router.url.includes('/terms-of-use') && !router.url.includes('/password-lost') && !router.url.includes('/tutorial')" (loggedOutClick)="openLogoutConfirmation()"></app-mobile-menu>
<div class="header-menu-overlay"
     [ngClass]="{'header-menu-overlay-visible': headerMenuOpened}"
     (click)="headerMenuOpened = false">
</div>

<div class="modal-overlay"
     [ngClass]="{'modal-overlay-visible': modalOpened || logoutOpened}"
     (click)="modalOpened = !modalOpened; logoutOpened = !logoutOpened">
</div>

<div [class.offlineApp]="networkService.isOffline">
  <router-outlet></router-outlet>
</div>

<footer *ngIf="!environment.mobile" class="footer">
  <div class="footer-contact" *ngIf="environment.domain === 'aesthetics'">
    <div class="content">
      <h2>{{'academy.Have_question'|translate}}</h2>
      <a routerLink="/faq">{{'layout.Browse_our_faq'|translate}}</a><br>
     <!-- <p style="max-width: 400px;margin: auto;"
         [innerHTML]="'layout.Footer_contact_academy'|translate:{num:'<a href=tel:' + phonenumber + '>' + phonenumber + '</a>', mail:'<a href=mailto:' + emailSupport + '>' + emailSupport + '</a>'}"></p>
-->
      <p style="max-width: 400px;margin: auto;padding-bottom: 1rem;">Or</p>
      <a routerLink="/contact">Contact us</a>
    </div>
  </div>
  <div class="footer-main">
    <div class="content">
      <div class="row">
        <div class="col col-20">
          <strong>{{'company_profile.Learn_more'|translate}}</strong>
          <ul class="first-letter-capitalize-only">
            <li><a routerLink="/library/courses">{{'pluralize.Courses'|translate}}</a></li>
            <li><a routerLink="/library/lectures">{{'profile.lectures'|translate}}</a></li>
            <li *ngIf="environment.domain === 'aesthetics'"><a routerLink="/webinars">{{'sponsor.Family_Webinar'|translate}}</a></li>
          </ul>
        </div>
        <div class="col col-20" *ngIf="environment.domain === 'aesthetics'">
          <strong>{{'academy.Community'|translate}}</strong>
          <ul>
            <li><a routerLink="/alert" *ngIf="showAlert">{{'academy.Alert'|translate}}</a></li>
            <li><a routerLink="/blog">{{'academy.Blog'|translate}}</a>
            </li>
            <li><a routerLink="/premium">{{'academy.Subscription'|translate}}</a></li>
            <li><a href="/about">{{'layout.About'|translate}}</a></li>
          </ul>
        </div>
        <div class="col col-20" *ngIf="environment.domain === 'aesthetics'">
          <strong>{{'academy.Explore'|translate}}</strong>
          <ul>
            <li><a routerLink="/explore/scientific-board">{{'academy.Scientific_board'|translate}}</a>
            </li>
            <li><a routerLink="/explore/partners">{{'pluralize.Scientific_partners'|translate}}</a>
            </li>
            <li><a routerLink="/explore/worldwide-events">{{'explore.Worldwide_events'|translate}}</a></li>
            <li *ngIf="environment.domain !== 'ophthalmology'"><a
              routerLink="/explore/humanitarian-missions">{{'explore.Humanitarian_missions'|translate}}</a>
            </li>
          </ul>
        </div>
        <div class="col col-20">
          <ul>
            <li *ngIf="environment.domain === 'ophthalmology'"><a href="/about">{{'layout.About'|translate}}</a></li>
            <li><a routerLink="/contact">{{'aop_layout.Contact_us'|translate}}</a></li>
            <li *ngIf="environment.domain === 'aesthetics'"><a routerLink="/faq">{{'sponsor.FAQ'|translate}}</a></li>
            <li><a routerLink="/terms-of-use">{{'layout.Terms_of_use'|translate}}</a></li>
            <li *ngIf="environment.domain === 'aesthetics'"><a href="/legal-information">{{'layout.Legal_information'|translate}}</a></li>
            <li><a routerLink="/privacy-policy">{{'layout.Privacy_Policy'|translate}}</a></li>
          </ul>
        </div>
        <div class="col col-20">
          <div class="footer-socials">
            <strong>{{'layout.Follow_us'|translate}}</strong>
            <a rel="noreferrer" target="_blank"
               [href]="environment.domain == 'aesthetics' ? 'https://www.facebook.com/pages/IMCAS-International-Master-Course-on-Aging-Skin/139427552789246' : 'https://www.facebook.com/aopcongress'"
               class="icon icon-facebook"></a>
            <a rel="noreferrer" target="_blank" *ngIf="environment.domain == 'aesthetics'"
               href="https://twitter.com/imcascongress"
               class="icon icon-twitter"></a>
            <a rel="noreferrer" target="_blank"
               [href]="environment.domain == 'aesthetics' ? 'https://www.linkedin.com/company/IMCAS/' : 'https://www.linkedin.com/company/aop-congress/'"
               class="icon icon-linkedin"></a>
            <a rel="noreferrer" target="_blank"
               [href]="'https://instagram.com/' + (environment.domain == 'aesthetics' ? 'imcascongress' : 'aopcongress')"
               class="icon icon-instagram"></a>
          </div>
          <div class="footer-languages">
            <strong>{{'academy.Languages'|translate}}</strong>
            <div class="select-wrap">
              <dropdown-locale-component [options]="getLanguagesAvailable()"
                                         [id]="'locale'"
                                         [name]="'locale'"
                                         [(selectedOption)]="selectedLanguage"
                                         (optionChange)="languageChanged($event)">
              </dropdown-locale-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-subscribe" [style.background]="environment.domain == 'ophthalmology' ? '#19487c' : null">
    <div class="content">
      <div *ngIf="environment.domain !== 'ophthalmology' && showBlogSubscribeButton()">
        <p>
          {{'blog.Get_the_latest'|translate}}
        </p>
        <div>
          <label>
            <input type="checkbox" name="consent" [(ngModel)]="consent">
            <span style="margin-left:10px;"><a routerLink="/privacy-policy" style="color:white;text-decoration: underline;">{{'layout.Gdpr_popin_consent_text_no_link'|translate}}</a></span>
          </label>
        </div>
        <div *ngIf="missingConsent" style="color:red; margin-bottom:5px;">
          {{'layout.Gdpr_popin_consent_warning'|translate}}
        </div>
        <input type="text" *ngIf="!user || (user && !user.id)" [(ngModel)]="email" class="textinput"
               placeholder="{{ 'account.Email_address'|translate }}"/>
        <button (click)="subscribeToBlog()" class="button button-orange">{{'layout.Subscribe_blog'|translate}}</button>
      </div>
      <p class="footer-copy" *ngIf="environment.domain !== 'ophthalmology'">
        © {{ year }} {{ 'layout.IMCAS'| translate }}
        - {{'layout.International_Master_Course_on_Aging_Science'|translate}}
      </p>
      <p class="footer-copy" *ngIf="environment.domain === 'ophthalmology'">
        © {{ year }} {{ 'layout.IMCAS'| translate }} - {{'aop_layout.AOP_title'|translate}}
      </p>
    </div>
  </div>
</footer>

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WebinarService} from '../../../../services/webinar.service';
import {UserService} from '../../../../services/user.service';
import {Course} from '../../../../models/course.model';
import {User} from '../../../../models/user.model';
import {environment} from '../../../../../environments/environment';
import {TranslationService} from '../../../../services/translation.service';
import {AuthService} from '../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DateMomentService} from '../../../../services/date-moment.service';
import {LocaleService} from '../../../../services/locale.service';

@Component({
    selector: 'app-on-demand',
    templateUrl: './on-demand.component.html',
    styleUrls: ['./on-demand.component.scss'],
    providers: [
        WebinarService,
        NgbActiveModal,
        UserService
    ]
})
export class OnDemandComponent implements OnInit {
    previousThemes: any[] = [];
    previousTheme: any = '';
    previousWebinars: Course[] = [];
    shownWebinars: Course[] = [];
    locale: any;
    environment: any = environment;
    user: User;
    dynamicResize = true;
    showLoading: boolean = false;
    isAop: boolean = environment.domain === 'ophthalmology';
    eventId: number;

    public specialities_list: Array<any>;
    ChartLegend = {
        position: 'top',
        alignment: 'center',
        maxLines: 6
    };

    ChartArea = {
        left: 30,
        width: '90%'
    };

    PieChartOptions = {
        legend: this.ChartLegend,
        chartArea: this.ChartArea,
        height: 400
    };

    pieChartSpecialtyDataRegis = {
        chartType: 'PieChart',
        containerId: 'pieChartSpecialtyRegis',
        dataTable: [
            ['Specialty', 'Percentage']
        ],
        options: this.PieChartOptions
    };
    pieChartGeoDataRegis = {
        chartType: 'PieChart',
        containerId: 'pieChartGeoDataRegis',
        dataTable: [
            ['Continent', 'Percentage']
        ],
        options: this.PieChartOptions
    };
    pieChartSpecialtyDataLiveView = {
        chartType: 'PieChart',
        containerId: 'pieChartSpecialtyLiveView',
        dataTable: [
            ['Specialty', 'Percentage']
        ],
        options: this.PieChartOptions
    };
    pieChartGeoDataLiveView = {
        chartType: 'PieChart',
        containerId: 'pieChartGeoDataLiveView',
        dataTable: [
            ['Continent', 'Percentage']
        ],
        options: this.PieChartOptions
    };

    constructor(private modalService: NgbModal,
                public activeModal: NgbActiveModal,
                private activatedRoute: ActivatedRoute,
                private webinarService: WebinarService,
                private localeService: LocaleService,
                private authService: AuthService,
                public dateMomentService: DateMomentService,
                public translationService: TranslationService,
                private router: Router,
                private userService: UserService) {
    }

    getTitlePage() {
        if (environment.domain === 'aesthetics') {
            return 'webinar.Previous_webinars_title';
        }
        return 'webinar.Previous_webinars_title_aop';
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                })
        }
        this.showLoading = true;
        this.webinarService.getPreviousWebinars().subscribe(response => {
            const webinars = response.data
            webinars.forEach((webinar) => {
                webinar.speakers = webinar.speakers.slice(0, 10);
                const sponsorIdsNoDouble = webinar.academy_medias_sponsor_ids.filter((m, i) => webinar.academy_medias_sponsor_ids.indexOf(m) === i);
                if (sponsorIdsNoDouble.length > 1) {
                    webinar.hasSeveralSponsors = true;
                } else { webinar.hasSeveralSponsors = false; }
            });
            this.showLoading = false;
            this.previousWebinars = webinars;
            this.shownWebinars = this.previousWebinars;
            this.getAvailableSpecialities();
        });
    }

    getAvailableSpecialities() {
        if (!this.specialities_list) {
            const specialities = {};
            const trans = this.translationService.getTranslatedName;
            this.specialities_list = [];
            for (const d of this.previousWebinars) {
                if (d.themes) {
                    d.themes.forEach((t, i) => {
                        specialities[t.id] = t;
                    });
                }
            }
            for (const key of Object.keys(specialities)) {
                this.specialities_list.push(specialities[key]);
            }
            this.specialities_list = this.specialities_list.sort((a, b) => {
                if (trans(a.translations, this.locale) < trans(b.translations, this.locale)) {
                    return -1;
                } else if (trans(a.translations, this.locale) > trans(b.translations, this.locale)) {
                    return 1;
                } else {
                    return 0;
                }
            })
        }
    }

    open(content, previousWebinar) {
        this.pieChartSpecialtyDataRegis.dataTable = [['Specialty', 'Percentage']]
        previousWebinar.webinar_registrants_specialties.forEach(item => {
            this.pieChartSpecialtyDataRegis.dataTable.push([item]);
        });
        previousWebinar.webinar_registrants_stats_specialties.forEach((item, index) => {
            this.pieChartSpecialtyDataRegis.dataTable[index + 1].push(item);
        });

        this.pieChartGeoDataRegis.dataTable = [['Continent', 'Percentage']]
        previousWebinar.webinar_registrants_areas.forEach(item => {
            this.pieChartGeoDataRegis.dataTable.push([item]);
        });
        previousWebinar.webinar_registrants_stats_areas.forEach((item, index) => {
            this.pieChartGeoDataRegis.dataTable[index + 1].push(item);
        });

        this.pieChartSpecialtyDataLiveView.dataTable = [['Specialty', 'Percentage']]
        previousWebinar.webinar_live_viewers_specialties.forEach(item => {
            this.pieChartSpecialtyDataLiveView.dataTable.push([item]);
        });
        previousWebinar.webinar_live_viewers_stats_specialties.forEach((item, index) => {
            this.pieChartSpecialtyDataLiveView.dataTable[index + 1].push(item);
        });

        this.pieChartGeoDataLiveView.dataTable = [['Continent', 'Percentage']]
        previousWebinar.webinar_live_viewers_areas.forEach(item => {
            this.pieChartGeoDataLiveView.dataTable.push([item]);
        });
        previousWebinar.webinar_live_viewers_stats_areas.forEach((item, index) => {
            this.pieChartGeoDataLiveView.dataTable[index + 1].push(item);
        });

        this.modalService.open(content, {windowClass: 'modal-wide'});
    }

    selectPreviousTheme(event) {
        if(event === 'all') {
          return this.shownWebinars = this.previousWebinars;
        }
        this.shownWebinars = this.previousWebinars.filter(webinar => {
            return webinar.themes.find(theme => theme.id === parseInt(event)) !== undefined;
        });
    }

    goWebinarsCourses() {
        if (this.isAop) {
            this.eventId = 5801;
        } else {
            this.eventId = 5799;
        }

        if (this.user && this.user.premium_membership) {
            this.router.navigate(['/library/courses'], { queryParams: { languages: '', 'event': this.eventId } });
        } else {
            this.router.navigate(['/library/courses'], { queryParams: { languages: '', 'event': this.eventId, 'free': 'true' } });
        }
    }
}

import {AcademyMedia} from '../../../../models/academy-media.model';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {DateMomentService} from 'app/services/date-moment.service';
import {environment} from '../../../../../environments/environment';
import {EmitterService} from 'app/services/emitter.service';
import {MediaService} from 'app/services/media.service';
import {LocaleService} from '../../../../services/locale.service';


@Component({
  selector: 'contributions-videos-approval',
  templateUrl: './videos-approval.component.html',
  styleUrls: ['./videos-approval.component.scss'],
  providers: [
    MediaService
  ]
})
export class ContributionsVideosApprovalComponent implements OnInit {
  locale: string;
  videosWaiting: AcademyMedia[];
  loading: boolean;
  environment: any = environment;
  contributionsVideosWaitingEmitter = 'contributionsVideosWaitingEmitter';
  contributionsVideoAcceptEmitter = 'contributionsVideoAcceptEmitter';
  contributionsVideoDeclineEmitter = 'contributionsVideoDeclineEmitter';

  constructor(
    private activatedRoute: ActivatedRoute,
    public translationService: TranslationService,
    public dateMomentService: DateMomentService,
    private mediaService: MediaService,
    private localeService: LocaleService
  ) { }

  ngOnInit() {
    this.loading  = true;
    this.locale = this.localeService.currentLocale();
    EmitterService
    .get(this.contributionsVideosWaitingEmitter)
    .subscribe((result) => {
        this.loading = false;
        this.videosWaiting = [];
        if (!result.error) {
            result.forEach(el => {
                this.videosWaiting.push(new AcademyMedia(el));
            })
        }
    });
  }

  acceptVideo(video) {
    EmitterService.get(this.contributionsVideoAcceptEmitter).emit(video);
  }

  declineVideo(video) {
    EmitterService.get(this.contributionsVideoDeclineEmitter).emit(video);
  }
}

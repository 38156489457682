import {User} from './../models/user.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {Course} from 'app/models/course.model';
import {ArticleModel} from 'app/models/article.model';
import {map, shareReplay} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserService {
    private endpoint: string = '/users';
    private percentCompletion = new BehaviorSubject<number| undefined>(undefined);
    percentCompletion$ = this.percentCompletion.asObservable();

    public membershipEmitter = new EventEmitter();

    constructor(private httpClient: HttpClient) {
    }

    updatePercent(percent: number) {
        this.percentCompletion.next(percent);
    }

    getUser(id: number | string, params?: any): Observable<User> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<User>(environment.apiURL + this.endpoint + '/' + id, reqOpts).pipe(shareReplay());
    }

    getUsersCount(): Observable<any> {
        return this.httpClient.get<any>(environment.apiURL + this.endpoint + '/counts', {
            params: new HttpParams().set('physician', '1').set('website_ready', '1').set('academy', '1')
        }).pipe(shareReplay());
    }

    getUserFriends(params?: any): Observable<User[]> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<User[]>(environment.apiURL + this.endpoint, reqOpts).pipe(shareReplay());
    }

    registerUser(user: any): Observable<User> {
        return this.httpClient.post<User>(environment.apiURL + this.endpoint, user).pipe(shareReplay());
    }

    updateUser(user: any): Observable<User> {
        return this.httpClient.put<User>(environment.apiURL + this.endpoint, user).pipe(shareReplay());
    }

    addUserInterests(themes: number[], keywords: number[]): Observable<User> {
        return this.httpClient.put<User>(environment.apiURL + this.endpoint, {
            themes: themes,
            keywords: keywords
        }).pipe(shareReplay());
    }

    getCoursesInProgress(userId): Observable<Course[]> {
        return this.httpClient.get<Course[]>(environment.apiURL + this.endpoint + '/' + userId, {
            params: new HttpParams().set('courses_in_progress', '1')
        }).pipe(map((result: any) => {
            if (typeof result.courses_in_progress !== 'undefined') {
                return result.courses_in_progress.sort((a, b) => b.percentage_viewed - a.percentage_viewed)
            }
        }), shareReplay());
    }

    getCoursesFinished(userId): Observable<Course[]> {
        return this.httpClient.get<Course[]>(environment.apiURL + this.endpoint + '/' + userId, {
            params: new HttpParams().set('courses_finished', '1')
        }).pipe(map((result: any) => {
            if (typeof result.courses_finished !== 'undefined') {
                return result.courses_finished.sort((a, b) => {
                    if (a.certificate_url && !b.certificate_url) {
                        return 1;
                    }
                    if (!a.certificate_url && b.certificate_url) {
                        return -1;
                    }
                    return 0;
                });
            }
        }), shareReplay());
    }

    getFavorited(userId, term?): Observable<any> {
        return this.httpClient.get<any>(environment.apiURL + this.endpoint + '/' + userId, {
            params: new HttpParams().set('medias_favorited', '1').set('courses_favorited', '1').set('media_cdn', '0')
        }).pipe(map((result: any) => {
            return result.favorited;
        }), shareReplay());
    }

    getUserWithConnections(userId: number): Observable<any> {
        return this.httpClient.get<any>(environment.apiURL + this.endpoint + '/' + userId, {
            params: new HttpParams()
                .set('connections', '1')
                .set('user_specialty', '1')
                .set('user_country', '1')
                .set('user_id', '1')
        })
            .pipe(shareReplay());
    }

    connect(userId: number): Observable<any> {
        return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/' + userId + '/friends', null)
            .pipe(shareReplay());
    }

    acceptConnection(connectionId: number): Observable<any> {
        return this.httpClient.put<any>(environment.apiURL + this.endpoint + '/' + connectionId + '/friends', {'answer': '1'})
            .pipe(shareReplay());
    }

    declineConnection(connectionId: number): Observable<any> {
        return this.httpClient.put<any>(environment.apiURL + this.endpoint + '/' + connectionId + '/friends', {'answer': '0'})
            .pipe(shareReplay());
    }

    uploadNewImage(data): Observable<any> {
        return this.httpClient.put<any>(environment.apiURL + this.endpoint, data).pipe(shareReplay());
    }

    uploadNewDiplomas(data): Observable<any> {

      return this.httpClient.put<any>(environment.apiURL + this.endpoint, data).pipe(shareReplay());
    }

    setCoverFromGallery(coverId: number): Observable<any> {
        return this.httpClient.put<any>(environment.apiURL + this.endpoint, {cover_gallery: coverId})
            .pipe(shareReplay());
    }

    updatePubmedVisibility(pubmedId, visibility): Observable<any> {
        return this.httpClient.put<any>(environment.apiURL + this.endpoint + '/pubmed/' + pubmedId, {visibility: visibility}).pipe(shareReplay());
    }

    getUserArticles(userId): Observable<ArticleModel[]> {
        return this.httpClient.get<ArticleModel[]>(environment.apiURL + '/articles', {
            params: new HttpParams().set('user_id', '' + userId).set('all', '1')
        }).pipe(shareReplay());
    }

    createArticle(article: ArticleModel): Observable<any> {
        return this.httpClient.post<any>(environment.apiURLV2 + '/articles', article).pipe(shareReplay());
    }

    getUserMembershipStatus(userId): Observable<any> {
        return this.httpClient
            .get<any>(environment.apiURL + this.endpoint + '/' + userId + '/membership')
            .pipe(shareReplay());
    }

    getUserInvoices(userId): Observable<any> {
        return this.httpClient
            .get<any>(environment.apiURL + '/invoices', {
                params: new HttpParams().set('user_id', '' + userId)
            })
            .pipe(map(invoices => invoices.sort(function (a, b) {
                return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);
            })), shareReplay());
    }

    getUserMembershipYears(userId): Observable<any> {
      return this.httpClient.get(environment.apiURLV2 + this.endpoint + '/' + userId + '/membership-years').pipe(shareReplay());
    }

    getCompletion(user: User) {
      let percentCompletion = 5;
      if (user.gender !== 'n') {
        percentCompletion += 10;
      }
      if (user.themes.length > 0) {
        percentCompletion += 10;
      }
      if (user.picture_url.indexOf('default') === -1) {
        percentCompletion += 20;
      }
      if (user.dob) {
        percentCompletion += 5;
      }
      if (user.city) {
        percentCompletion += 5;
      }
      if (user.phone) {
        percentCompletion += 5;
      }
      if (user.social_network.twitter || user.social_network.facebook ||
        user.social_network.instagram) {
        percentCompletion += 5;
      }
      if (user.specialty_category === 'physician') {
        if (user.address) {
          percentCompletion += 10;
        }
        if (user.biostatement) {
          percentCompletion += 15;
        }
        if (user.scientific_societies && user.scientific_societies.length > 0) {
          percentCompletion += 10;
        }
      } else {
        if (user.address) {
          percentCompletion += 15;
        }
        if (user.biostatement) {
          percentCompletion += 20;
        }
      }
      return percentCompletion;
  }

  checkLatinInput(input: string): boolean {
    const regex = /^[ -˙]*$/;
    return !input.match(regex);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app--modal-partner-offer',
    templateUrl: './partner-offer.component.html',
    styleUrls: ['./partner-offer.component.scss']
})
export class ModalPartnerOfferComponent implements OnInit {
    @Input() d: any;
    locale: any;

    constructor(public activeModal: NgbActiveModal) {
    }

    close(doNotShowAgain) {
        if (this.d) {
            this.d();
        } else {
            this.activeModal.close('Cross click')
        }
        if (doNotShowAgain) {
            try {
            } catch (e) {}
        }
    }

    ngOnInit() {

    }
}

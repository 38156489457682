import {User} from './../models/user.model';
import {BehaviorSubject, Observable} from 'rxjs/index';
import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Device, DeviceInfo} from "@capacitor/device";

@Injectable()
export class AuthService {
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  userUpdated: EventEmitter<number> = new EventEmitter();

  constructor(private httpClient: HttpClient,
              private userService: UserService,
              private router: Router,
              private cookieService: CookieService) {
  }

  public login(loginData: { username: string, password: string, platform: string }): Observable<any> {
    loginData.platform = 'Web';
    return this.httpClient.post<User>(environment.apiURL + '/auth/login', loginData, {observe: 'response'});
  }

  public loginAs(loginData: string): Observable<any> {
    return this.httpClient.post<User>(environment.apiURL + '/auth/login', {token: loginData}, {observe: 'response'});
  }

  public loginMainWebsite(loginData): Observable<any> {
    return this.httpClient.post<User>(environment.base_url + '/en/account/logincheck', loginData, {observe: 'response'});
  }

  public loginMainWebsiteToken(token): Observable<any> {
    return this.httpClient.get<User>(environment.base_url + '/admin/auth/' + token, {observe: 'response'});
  }

  public logoutMainWebsite(): Observable<any> {
    return this.httpClient.get<User>(environment.base_url + '/en/logout', {observe: 'response'});
  }

  public deleteAccount(): Observable<any> {
    return this.httpClient.post<User>(environment.apiURLNew + 'account/delete', {observe: 'response'});
  }

  public registerDeviceEndpoint(firebasePushToken, deviceInfo): Observable<any> {
    return this.httpClient.post<User>(environment.apiURLNew + 'account/academy-register-device', {
      firebase_push_token: firebasePushToken,
      device_type: 'phone-'+ deviceInfo.platform
    }, {
      observe: 'response'
    });
  }

  public setSession(authResult, token?: any, user?: any) {
    try {
      if (authResult) {
        localStorage.setItem('personal_token', authResult.body.personal_token);
        localStorage.setItem('currentUser', JSON.stringify(authResult.body.user));
      } else {
        localStorage.setItem('personal_token', token);
        localStorage.setItem('currentUser', JSON.stringify(user));
      }
    } catch (e) {
    }
    this.isLoginSubject.next(true);
  }

  public logout() {
    this.logoutWithoutRedirect();
    this.isLoginSubject.next(false);
    this.router.navigate(['/home']);
  }

  public delete() {
    this.deleteAccount().subscribe(() => {
      this.logout();
    }, err => {

    });
  }

  public registerDevice(firebasePushToken) {
    Device.getInfo().then(deviceInfo =>{
      this.registerDeviceEndpoint(firebasePushToken, deviceInfo).subscribe(result => {
        console.log('Device registered');
        console.log(result);
      }, err => {});
    });
  }

  public logoutWithoutRedirect() {
    try {
      localStorage.removeItem('personal_token');
      localStorage.removeItem('currentUser');
    } catch (exception) {
    }

    try {
      sessionStorage.removeItem('imcas_session');
      sessionStorage.removeItem('token');
    } catch (exception) {
    }

    this.cookieService.delete('imcas_session', '/', 'imcas.com');
    this.cookieService.delete('imcas_session', '/', 'localhost:4200');
    this.cookieService.delete('imcas_session', '/', 'aop-academy.com');
    this.logoutMainWebsite().subscribe(() => {
    }, err => {
    });
  }

  public hasToken(): boolean {
    try {
      return !!localStorage.getItem('personal_token');
    } catch (exception) {
      return false;
    }
  }

  public getToken() {
    try {
      return localStorage.getItem('personal_token');
    } catch (exception) {
      return false;
    }
  }

  public getCurrentUser() {
    let user = '';
    try {
      user = localStorage.getItem('currentUser');
    } catch (exception) {
    }
    return user ? new User(JSON.parse(user)) : new User();
  }

  public setCurrentUser(user) {
    try {
      localStorage.setItem('currentUser', JSON.stringify(user));
    } catch (exception) {
    }
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }


  getUserUpdatedEmitter() {
    return this.userUpdated;
  }

  emitUserUpdated() {
    this.userUpdated.emit();
  }

}

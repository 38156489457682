<h1>{{'academy.Publications'|translate}}</h1>
<div class="journal">
	<div class="alert alert-danger" *ngIf="!user.pubmed_references && !loading && !error">
		{{ 'academy.Pubmed_not_found'|translate }}
	</div>
	<div class="journal-note" *ngIf="user.pubmed_references || loading">
		<p>{{'academy.Please_note_pubmed'|translate}}</p>
	</div>
	<app-loader *ngIf="loading"></app-loader>

	<div *ngIf="error" class="alert alert-danger">
		{{ error }}
	</div>
	<div class="journal-item" *ngFor="let pubmed of user.pubmed_references">
		<div class="journal-item-content" (click)="goToPubmed(pubmed.pmid)">
			<div class="journal-item-title">
				<h3>{{pubmed.title}}</h3>
			</div>
			<div class="journal-item-date">
				<span>{{dateMomentService.getPublicationDate(pubmed.date)}}</span>
			</div>
			<div class="journal-item-desc">
				<p>{{pubmed.journal}}</p>
				<p>
					{{('academy.by'|translate)}}
					<ng-container *ngFor="let author of pubmed.authors; let i=index;">
						{{ (i > 0 ? ', ' : '') + author }}
					</ng-container>
				</p>
			</div>
		</div>
		<div class="journal-item-actions">
			<div class="journal-item-actions-display">
				<span>{{'account.Hide'|translate}}</span>
				<input type="checkbox" class="checkbox-switch" [id]="pubmed.pmid" [checked]="pubmed.visibility === 'show'" (change)="changeVisibility(pubmed)" >
				<label [for]="pubmed.pmid"></label>
				<span>{{'account.Display'|translate}}</span>
			</div>
			<div class="journal-item-actions-remove">
				<a (click)="remove(pubmed)"><i class="fa fa-trash-o" aria-hidden="true"></i> <span>{{'academy.Remove'|translate}}</span></a>
			</div>
		</div>
	</div>
</div>

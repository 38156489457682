<div class="page single-webinar" [style.background]="isAop ? '#0f2a48' : null">
  <app-loader *ngIf="showLoading"></app-loader>
  <div  *ngIf="webinar$ | async as webinar" class="col-md-12">

    <div class="modal-body" *ngIf="webinarRegisterError">
      <div id="alert-danger" class="alert alert-danger">
        {{ webinarRegisterMsg }}
      </div>
    </div>

    <div class="modal-body" *ngIf="webinar.auth_registered">
      <div id="alert-success" class="alert alert-success">
        {{ 'webinar.Registered'|translate }}
      </div>
      <p class="alert alert-info" *ngIf="webinar.past && !webinar.webinar_launched">
        {{ 'webinar.Finished'|translate }}</p>
    </div>

    <div class="col-md-12" *ngIf="webinar.webinar_launched == 1 && logged && !isChrome">
      <div class="register-form">
        <p [innerHTML]="'webinar.Browser_warning'|translate"></p>
      </div>
    </div>

    <!-- Header w/ title, subtitle & dates -->
    <div class="webinar-header">
      <div class="webinar-header-title">
        <h1>
          Webinar : {{ translationService.getTranslatedAttribute('title',
          webinar.translations, locale)|translate
          }}
        </h1>
        <div *ngIf="translationService.getTranslatedAttribute('subtitle', webinar.translations, locale)
                  && translationService.getTranslatedAttribute('subtitle', webinar.translations, locale) !== 'n/a'" style="text-align: center">
          {{ translationService.getTranslatedAttribute('subtitle',
          webinar.translations, locale)|translate }}
        </div>
      </div>

      <div class="dates" *ngIf="!webinar.webinar_launched">
        <div class="dates-content" *ngFor="let pairs of webinar_dates;let i = index">
          <svg class="time-icon" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-width="1.5"></circle>
            <path stroke="currentColor" stroke-width="1.5" d="M12 8V12L14 14"></path>
          </svg>
          <span>{{ dateMomentService.getDateTimeBeginEnd(pairs.val) }}
            , {{'layout.timezone_time' | translate:{city: pairs.key} }}</span>

        </div>
      </div>

      <div class="register-form" *ngIf="!webinar.webinar_launched">
        <p *ngIf="environment.domain == 'aesthetics'" class="tagline"
           [innerHTML]="'webinar.Tagline'|translate"></p>
        <p *ngIf="environment.domain == 'ophthalmology'"
           [innerHTML]="'webinar.Tagline_aop'|translate"></p>
      </div>

      <!--    if webinar has launched, available languages button is shown -->
      <div *ngIf="webinar.webinar_launched">
        <div *ngIf="videoAvailableLanguages && videoAvailableLanguages.length > 1"
             style="color:white; padding: 10px 0;">
          <p class="language-title">This webinar is translated into different languages</p>
<!--          <p class="language-title">{{ ('webinar.webinar_translate'|translate) }}</p>-->
          <button class="language-btn" (click)="showAvailableLanguages=!showAvailableLanguages">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff">
              <path d="M0 0h24v24H0V0z" fill="none"/><path d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"/>
            </svg>
           <span style="margin-left: 5px">{{ ('webinar.Choose_your_language'|translate) }}</span>
          </button>

          <!-- List of languages shown on click -->
          <div class="language-preferences-container">
            <div class="language-preferences-content">
              <div *ngFor="let language of videoAvailableLanguages">
                <div *ngIf="showAvailableLanguages" [ngStyle]="{'background-color': (language.key == videoLanguage) ? '#fa7732' : ''}">
                  <a
                     (click)="changeLanguage(language.key)"
                     style="margin-right:5px; margin-left: 5px;"
                     [ngStyle]="{'color': (language.key == videoLanguage) ? '#fff' : '#5a5a5a', 'width':'100%' }"
                  >
                    {{language.val}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Main content
          -> Video
            -> Live video
            OR
            -> Waiting screen
            OR
            -> Pre-video
          -> Sub-video
          -> Sidebar
      -->
    <div class="row live" [ngClass]="{'row-eq-height': webinar.webinar_launched == 1}">

      <div class="live-video-bottom col-sm-12 col-md-8 col-lg-9"
           *ngIf="webinar.webinar_launched == 1 && webinar.auth_registered">
        <div class="webinar-theme-and-format">
          <p class="webinar-item-theme" *ngIf="webinar.theme">
            {{('academy.Theme'|translate)}}:
            {{translationService.getTranslatedAttribute('name',
            webinar.theme.translations, locale)}}&ensp;-&nbsp;
          </p>
          <p class="webinars-item-format" *ngIf="webinar.format">
            {{('abstract.Format'|translate)}}:
            {{translationService.getTranslatedAttribute('name',
            webinar.format.translations, locale) | titlecase}}</p>
        </div>
      </div>

      <!-- Main content -> Video (left on desktop) -->
      <div class="col-sm-12 col-md-8 col-lg-9">

        <!-- Main content -> Video -> Live Video (when webinar has launched) -->
        <div *ngIf="webinar.webinar_launched == 1">
          <div class="live-video" *ngIf="webinar.auth_registered">
            <app-jwplayer-video
              *ngIf="logged"
              #player
              [file]="webinar.webinar_video_url"
              [file2]="webinar.webinar_video_url"
              [width]="'100%'"
              [aspectratio]="'16:9'"
              (progress)="storeProgress($event, webinar.id)">
            </app-jwplayer-video>
            <div class="live-video-banner" *ngIf="!webinar.webinar_launched">
                <span class="live-video-banner-icon">
                    <svg fill="#fff" height="100%" viewBox="0 0 24 24" width="100%">
                        <path
                          d="M16.94 6.91l-1.41 1.45c.9.94 1.46 2.22 1.46 3.64s-.56 2.71-1.46 3.64l1.41 1.45c1.27-1.31 2.05-3.11 2.05-5.09s-.78-3.79-2.05-5.09zM19.77 4l-1.41 1.45C19.98 7.13 21 9.44 21 12.01c0 2.57-1.01 4.88-2.64 6.54l1.4 1.45c2.01-2.04 3.24-4.87 3.24-7.99 0-3.13-1.23-5.96-3.23-8.01zM7.06 6.91c-1.27 1.3-2.05 3.1-2.05 5.09s.78 3.79 2.05 5.09l1.41-1.45c-.9-.94-1.46-2.22-1.46-3.64s.56-2.71 1.46-3.64L7.06 6.91zM5.64 5.45L4.24 4C2.23 6.04 1 8.87 1 11.99c0 3.13 1.23 5.96 3.23 8.01l1.41-1.45C4.02 16.87 3 14.56 3 11.99s1.01-4.88 2.64-6.54z">
                        </path>
                        <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                </span>
              <span class="live-video-banner-messages">
                  <div class="live-video-banner-main-text" *ngIf="countDownTime && !webinar.past">
                      <p *ngIf="countDownTime.days > 1">{{ 'webinar.Countdown_days'|translate:{
                        days:
                        countDownTime.days
                      } }}</p>
                      <p *ngIf="countDownTime.hours > 1 && countDownTime.days <= 1">{{
                        'webinar.Countdown_hours'|translate:{hours: countDownTime.hours} }}</p>
                      <p *ngIf="countDownTime.minutes > 1 && countDownTime.hours <= 1">{{
                        'webinar.Countdown_mins'|translate:{min: countDownTime.minutes} }}</p>
                      <p *ngIf="countDownTime.seconds > 1 && countDownTime.minutes <= 1">{{
                        'webinar.Countdown_seconds'|translate:{seconds: countDownTime.seconds} }}</p>
                      <p *ngIf="countDownTime.seconds <= 1 && countDownTime.minutes <= 0">{{
                        'webinar.Countdown_soon'|translate }}</p>
                  </div>
                  <div class="live-video-banner-subtitle-text">
                      {{dateMomentService.getDateTime(webinar.datetime_begin)}}
                    , {{'layout.timezone_time' | translate:{city: 'Paris'} }}
                  </div>
              </span>
            </div>
          </div>

          <!-- Main content -> Video -> Live Video -> Waiting screen (when webinar has launched but user is not registered for it) -->
          <div class="fake-player" *ngIf="!webinar.auth_registered">
            <div class="content content-intro">
              <h1>{{ webinar.live_demonstration ? 'webinar.Title_live_demo' : 'webinar.Webinar'|translate }}:
                {{ translationService.getTranslatedAttribute('title', webinar.translations, locale)|translate
                }}</h1>
              <p class="page-top-intro">{{ translationService.getTranslatedAttribute('subtitle',
                webinar.translations, locale)|translate }}</p>
              <div class="row dates">
                <div class="col-12"
                     *ngFor="let pairs of webinar_dates;let i = index">
                  <p>
                    <span>{{ dateMomentService.getDateTimeBeginEnd(pairs.val) }}
                    , {{'layout.timezone_time' | translate:{city: pairs.key} }}</span>
                  </p>
                </div>
              </div>
              <div class="row buttons">
                <div class="col-sm-12" *ngIf="!logged"
                     [ngClass]="{'col-sm-12': webinar.webinar_launched == 1}">
                  <a style="padding: 9px 35px;" (click)="openLoginPage()" class="button button-orange">{{
                    'webinar.Register_now'|translate }}</a>
                </div>

                <div class="col-sm-12" *ngIf="logged">
                  <a [routerLink]="['/webinar', webinar.slug, currentUser.register_webinar_token]"
                     style="padding: 9px 35px;" class="button button-orange"
                     *ngIf="currentUser.register_webinar_token">{{ 'webinar.Register_webinar'|translate }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Main content -> Video -> Pre-video (when webinar has not launched but there is a video to display) -->
        <div *ngIf="!webinar.webinar_launched && webinar.teaser_media && !teaserThumbIsShown"
          class="teaser-video"
        >
          <app-jwplayer-video
            [file]="webinar.teaser_media.cdn_file"
            [file2]="webinar.teaser_media.cdn_file"
            [width]="'100%'"
            [aspectratio]="'16:9'"
            [autoload]="true"
            [mute]="true"
            [controls]="false"
            [replay]="true"
            (completed)="showTeaserThumb()"
          >
          </app-jwplayer-video>

          <div class="sound-toggle">
            <svg *ngIf="!isMuted"
                 (click)="toggleSound()"
                 xmlns="http://www.w3.org/2000/svg"
                 width="34"
                 height="34"
                 viewBox="0 0 24 24"
                 fill="none">
              <path d="M18.5 7.5C18.5 7.5 20 9 20 11.5C20 14 18.5 15.5 18.5 15.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4 14V10C4 9.44772 4.44772 9 5 9H7.69722C7.89465 9 8.08766 8.94156 8.25192 8.83205L12.4453 6.03647C13.1099 5.59343 14 6.06982 14 6.86852V17.1315C14 17.9302 13.1099 18.4066 12.4453 17.9635L8.25192 15.1679C8.08766 15.0584 7.89465 15 7.69722 15H5C4.44772 15 4 14.5523 4 14Z" stroke="white" stroke-width="1.5"/>
            </svg>
            <svg *ngIf="isMuted"
                 (click)="toggleSound()"
                 xmlns="http://www.w3.org/2000/svg"
                 width="34"
                 height="34"
                 viewBox="0 0 24 24"
                 fill="none">
              <path d="M17 14L19.0005 12M21 10L19.0005 12M19.0005 12L17 10M19.0005 12L21 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 14V10C3 9.44772 3.44772 9 4 9H6.69722C6.89465 9 7.08766 8.94156 7.25192 8.83205L11.4453 6.03647C12.1099 5.59343 13 6.06982 13 6.86852V17.1315C13 17.9302 12.1099 18.4066 11.4453 17.9635L7.25192 15.1679C7.08766 15.0584 6.89465 15 6.69722 15H4C3.44772 15 3 14.5523 3 14Z" stroke="white" stroke-width="1.5"/>
            </svg>
          </div>

          <!--  available languages banner (left on desktop) -->
          <div class="left-display-languages" *ngIf="videoAvailableLanguages && videoAvailableLanguages.length > 1">
            <div class="display-languages-container">
              <div class="display-languages-title">
                <i class="fas fa-language fa-2x" style="margin-right: 5px;"></i>
                <span  class="language-title" style="font-size: 14px;">This webinar is available in : </span>
              </div>
              <div  *ngFor="let language of videoAvailableLanguages">
                <p>{{language.val}}</p>
              </div>
            </div>
          </div>

          <div class="buttons">
            <div>
              <ng-template #popContent>
                <div class="button-set">
                  <a [href]="'https://www.google.com/calendar/event?action=TEMPLATE&dates='+dateMomentService.formatGoogle(webinar.datetime_begin)
                          +'%2F'+dateMomentService.formatGoogle(webinar.datetime_end)
                          +'&text='+translationService.getTranslatedAttribute('subtitle', webinar.translations, locale)
                          +'&location='+(environment.congress_id === 68 ? 'IMCAS' : 'AOPCONGRESS')
                          +'&details='+translationService.getTranslatedAttribute('subtitle', webinar.translations, locale)+'-'
                          +translationService.getTranslatedAttribute('title', webinar.translations, locale)"
                     target="_blank">Google</a>
                  <a [href]="'https://www.imcas.com/en/academy/webinar/ical/'+webinar.id">iCal</a>
                </div>
              </ng-template>
              <button type="button" class="button button-white"
                      [ngbPopover]="popContent">{{
                'company_profile.Add_to_calendar'|translate }}
              </button>
            </div>
            <div *ngIf="!logged">
              <a (click)="openLoginPage()"
                 class="button button-orange">{{
                'webinar.Register_now'|translate }}</a>
            </div>
            <div *ngIf="logged && !webinar.auth_registered">
              <a [routerLink]="['/webinar', webinar.slug, currentUser.register_webinar_token]"
                 class="button button-orange"
                 *ngIf="currentUser.register_webinar_token">{{ 'webinar.Register_webinar'|translate }}</a>
            </div>

          </div>
        </div>

        <!-- Main content -> Video -> Pre-video thumbnail (when webinar has not launched and video has ended) -->
        <div *ngIf="!webinar.webinar_launched && webinar.teaser_media && teaserThumbIsShown"
             class="teaser-thumbnail"
        >
          <div class="fake-player webinar-thumbnail"
               [style.background-image]="webinar.teaser_media.thumb ?
               'url('+webinar.teaser_media.thumb+')'
               : 'url(' + environment.cdn_base_url + '/sessions/' + webinar.id + '.jpg)'
          ">
            <div class="buttons">
              <div>
                <ng-template #popContent>
                  <div class="button-set">
                    <a [href]="'https://www.google.com/calendar/event?action=TEMPLATE&dates='+dateMomentService.formatGoogle(webinar.datetime_begin)
                          +'%2F'+dateMomentService.formatGoogle(webinar.datetime_end)
                          +'&text='+translationService.getTranslatedAttribute('subtitle', webinar.translations, locale)
                          +'&location='+(environment.congress_id === 68 ? 'IMCAS' : 'AOPCONGRESS')
                          +'&details='+translationService.getTranslatedAttribute('subtitle', webinar.translations, locale)+'-'
                          +translationService.getTranslatedAttribute('title', webinar.translations, locale)"
                       target="_blank">Google</a>
                    <a [href]="'https://www.imcas.com/en/academy/webinar/ical/'+webinar.id">iCal</a>
                  </div>
                </ng-template>
                <button type="button" class="button button-white"
                        [ngbPopover]="popContent">{{
                  'company_profile.Add_to_calendar'|translate }}
                </button>
              </div>
              <div *ngIf="!logged">
                <a (click)="openLoginPage()"
                   class="button button-orange">{{
                  'webinar.Register_now'|translate }}</a>
              </div>
              <div *ngIf="logged && !webinar.auth_registered">
                <a [routerLink]="['/webinar', webinar.slug, currentUser.register_webinar_token]"
                   class="button button-orange"
                   *ngIf="currentUser.register_webinar_token">{{ 'webinar.Register_webinar'|translate }}</a>
              </div>

            </div>
          </div>

        </div>

        <!-- Main content -> Video -> Waiting screen (when webinar has not launched) -->
        <div *ngIf="!webinar.webinar_launched && !webinar.teaser_media">
          <div class="fake-player webinar-thumbnail"
               [style.background-image]="'url(' + environment.cdn_base_url + '/sessions/' + webinar.id + '.jpg)'">
            <div class="filter">
              <div class="content content-intro">
                <div class="row buttons">
                  <div class="col-sm-{{ (logged && webinar.auth_registered) ? 12 : 6 }}">
                    <ng-template #popContent>
                      <div class="button-set">
                        <a [href]="'https://www.google.com/calendar/event?action=TEMPLATE&dates='+dateMomentService.formatGoogle(webinar.datetime_begin)
                            +'%2F'+dateMomentService.formatGoogle(webinar.datetime_end)
                            +'&text='+translationService.getTranslatedAttribute('subtitle', webinar.translations, locale)
                            +'&location='+(environment.congress_id === 68 ? 'IMCAS' : 'AOPCONGRESS')
                            +'&details='+translationService.getTranslatedAttribute('subtitle', webinar.translations, locale)+'-'
                            +translationService.getTranslatedAttribute('title', webinar.translations, locale)"
                           target="_blank" style="margin-right: 8px">Google</a>
                        <a [href]="'https://www.imcas.com/en/academy/webinar/ical/'+webinar.id">iCal</a>
                      </div>
                    </ng-template>
                    <button style="padding: 9px 35px;" type="button" class="button button-white"
                            [ngbPopover]="popContent">{{
                      'company_profile.Add_to_calendar'|translate }}
                    </button>
                  </div>
                  <div class="col-sm-6" *ngIf="!logged">
                    <a style="padding: 9px 35px;" (click)="openLoginPage()"
                       class="button button-orange">{{
                      'webinar.Register_now'|translate }}</a>
                  </div>
                  <div class="col-sm-6" *ngIf="logged && !webinar.auth_registered">
                    <a [routerLink]="['/webinar', webinar.slug, currentUser.register_webinar_token]"
                       style="padding: 9px 35px;" class="button button-orange"
                       *ngIf="currentUser.register_webinar_token">{{ 'webinar.Register_webinar'|translate }}</a>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="live-video-banner">
            <span class="live-video-banner-icon">
                <svg fill="#fff" height="100%" viewBox="0 0 24 24" width="100%">
                    <path
                      d="M16.94 6.91l-1.41 1.45c.9.94 1.46 2.22 1.46 3.64s-.56 2.71-1.46 3.64l1.41 1.45c1.27-1.31 2.05-3.11 2.05-5.09s-.78-3.79-2.05-5.09zM19.77 4l-1.41 1.45C19.98 7.13 21 9.44 21 12.01c0 2.57-1.01 4.88-2.64 6.54l1.4 1.45c2.01-2.04 3.24-4.87 3.24-7.99 0-3.13-1.23-5.96-3.23-8.01zM7.06 6.91c-1.27 1.3-2.05 3.1-2.05 5.09s.78 3.79 2.05 5.09l1.41-1.45c-.9-.94-1.46-2.22-1.46-3.64s.56-2.71 1.46-3.64L7.06 6.91zM5.64 5.45L4.24 4C2.23 6.04 1 8.87 1 11.99c0 3.13 1.23 5.96 3.23 8.01l1.41-1.45C4.02 16.87 3 14.56 3 11.99s1.01-4.88 2.64-6.54z">
                    </path>
                    <circle cx="12" cy="12" r="3"></circle>
                </svg>
            </span>
            <span class="live-video-banner-messages">
                <div class="live-video-banner-main-text" *ngIf="countDownTime && !webinar.past">
                    <p *ngIf="countDownTime.days > 1">{{ 'webinar.Countdown_days'|translate:{
                      days:
                      countDownTime.days
                    } }}</p>
                    <p *ngIf="countDownTime.hours > 1 && countDownTime.days <= 1">{{
                      'webinar.Countdown_hours'|translate:{hours: countDownTime.hours} }}</p>
                    <p *ngIf="countDownTime.minutes > 1 && countDownTime.hours <= 1">{{
                      'webinar.Countdown_mins'|translate:{min: countDownTime.minutes} }}</p>
                    <p *ngIf="countDownTime.seconds > 1 && countDownTime.minutes <= 1">{{
                      'webinar.Countdown_seconds'|translate:{seconds: countDownTime.seconds} }}</p>
                    <p *ngIf="countDownTime.seconds <= 1 && countDownTime.minutes <= 0">{{
                      'webinar.Countdown_soon'|translate }}</p>
                </div>
                <div class="live-video-banner-subtitle-text">
                    {{dateMomentService.getDateTime(webinar.datetime_begin)}}
                  , {{'layout.timezone_time' | translate:{city: 'Paris'} }}</div>
            </span>
          </div>
          <!--  available languages banner (right on desktop) -->
          <div class="right-display-languages" *ngIf="videoAvailableLanguages && videoAvailableLanguages.length > 1">
            <div class="right-display-languages-container">
              <div class="right-display-languages-title">
                <i class="fas fa-language fa-2x" style="margin-right: 5px;"></i>
                <span class="language-title" style="font-size: 14px;">This webinar is available in : </span>
              </div>
              <div  *ngFor="let language of videoAvailableLanguages">
                <p>{{language.val}}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Main-content -> Sub-video (user not logged in) -->
        <div *ngIf="!webinar.auth_registered || !webinar.webinar_launched">

          <div class="live-video-bottom"
               *ngIf="webinar.webinar_launched == 1 && webinar.auth_registered">
            <ng-template #askQuestionLiveContent let-modal>
              <div class="modal-body text-center modal-ask-question">
                <button type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3>Ask your question here, we will come back to you</h3>
                <br>
                <textarea name="ask-your-question" id="" cols="50" rows="5" [(ngModel)]="questionToSend"
                          (ngModelChange)="sendMessage($event)"></textarea>
                <br>
                <button [disabled]="!questionToSend || questionToSend === ''"
                        (click)="sendQuestion(questionToSend); questionToSend=null;"
                        class="button button-orange">Join us
                </button>
              </div>
            </ng-template>
            <ng-template #technicalIssuesContent let-modal>
              <div class="modal-body text-center modal-ask-question">
                <button type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3>IMCAS Academy website:</h3>
                <ul>
                  <li>
                    We recommend using the latest version of the Chrome browser : <a
                    href="https://www.google.com/intl/en_en/chrome/" target="_blank">download Chrome</a>
                    , <a
                    href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en"
                    target="_blank">update Chrome</a>
                  </li>
                  <li>
                    Clear your browser cache (<a
                    href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
                    target="_blank">here</a> for Chrome, select ‘Pictures and cache’)
                  </li>
                  <li>
                    Refresh the page
                  </li>
                </ul>

                <h3>IMCAS Academy App:</h3>
                <p>We remind you that you can watch webinar on your mobile phone or tablet directly on the IMCAS
                  Academy App</p>
                <div class="col-md-12 text-center download-buttons">
                  <div class="buttons">
                    <a href="https://itunes.apple.com/us/app/imcas-academy/id1410088543?l=fr&ls=1&mt=8"
                       target="_blank">
                      <img src="{{ environment.cdn_base_url }}/assets-front/img/download_app_store.png"
                           alt="download app on app store">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.imcas.academy"
                       target="_blank">
                      <img src="{{ environment.cdn_base_url }}/assets-front/img/download_google_play.png"
                           alt="download app on google play">
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
            <a (click)="open(askQuestionLiveContent)" class="ask-question-button" *ngIf="webinar.question_live"
               [ngClass]="{'isDisabled': !webinar.question_live}">
              <i class="fa fa-comments" style="margin-right: 10px;"></i>
              {{'academy.Ask_a_question_live'|translate}}
            </a>
            <a (click)="open(technicalIssuesContent)" class="ask-question-button">
              <i class="fa fa-tools" style="margin-right: 10px;"></i>
              {{'academy.Technical_guidelines'|translate}}
            </a>
            <div ngbDropdown class="d-inline-block share-dropdown">
              <button class="btn" id="dropdown-share" ngbDropdownToggle><i class="fa fa-share"
                                                                           aria-hidden="true"></i>
                {{'academy.Share'|translate}}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdown-share" class="open-dropdown">
                <li ngbDropdownItem>
                  <a [style.color]="isAop ? '#0f2a48' : null"
                     [href]="'mailto:?subject='+('webinar.Tweet_message'|translate)+'&amp;body='+('academy.Share_mail_line1'|translate)+'%0A%0A'+('academy.Share_mail_line2_course'|translate)+'%0A'+environment.base_url+'%2F'+locale+'%2Fwebinar%2F'+webinar.slug+'%0A%0A'+('webinar.Tweet_message'|translate)+'%0A%0A'+(currentUser ? currentUser.first_name + ' ' + currentUser.last_name : '')"><i
                    class="fa fa-envelope" aria-hidden="true"></i> {{ 'account.Email'|translate }}</a>
                </li>
                <li ngbDropdownItem>
                  <a [style.color]="isAop ? '#0f2a48' : null" target="_blank"
                     [href]="'https://www.facebook.com/sharer/sharer.php?u='+environment.base_url+'%2F'+locale+'%2Facademy'+'%2Fwebinar%2F'+webinar.slug"><i
                    class="fab fa-facebook" aria-hidden="true"></i> Facebook</a>
                </li>
                <li ngbDropdownItem>
                  <a [style.color]="isAop ? '#0f2a48' : null" target="_blank"
                     [href]="'https://twitter.com/intent/tweet?text='+('webinar.Tweet_message'|translate)+'&amp;url='+environment.base_url+'%2F'+locale+'%2Fwebinar%2F'+webinar.slug+'&amp;hashtags=IMCASAcademy&amp;via=imcascongress&amp;related=imcascongress'"><i
                    class="fab fa-twitter" aria-hidden="true"></i> Twitter</a>
                </li>
                <li ngbDropdownItem>
                  <a [style.color]="isAop ? '#0f2a48' : null" target="_blank"
                     [href]="'https://www.linkedin.com/shareArticle?mini=true&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy'+'%2Fwebinar%2F'+webinar.slug+'&amp;title='+('webinar.Tweet_message'|translate)"><i
                    class="fab fa-linkedin" aria-hidden="true"></i> LinkedIn</a>
                </li>
              </div>
            </div>
            <span class="nb-live-viewers btn"
                  *ngIf="webinar.webinar_launched == 1 && webinar.auth_registered && viewers && viewers.live_viewers > 50">
                    <i class="fa fa-eye" aria-hidden="true"></i>{{viewers.error ? '0' : viewers.live_viewers}}
                </span>
            <div class="webinar-rating" *ngIf="showRating">

              <ngb-rating
                [rate]="userRating"
                (hover)="setCurrentRating($event)"
                (click)="rateLiveWebinar()"
                max="5"
                min="1"
                [readonly]="userRating != null ? true : false"
              >
                <ng-template let-fill="fill">
                  <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                  <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                  <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
                </ng-template>
              </ngb-rating>
            </div>
          </div>

          <div class="program" *ngIf="webinar.lectures?.length">
            <h3>{{ 'webinar.Webinar_program'|translate }}</h3>
            <div class="program-item media" *ngFor="let lecture of webinar.lectures">
              <a [routerLink]="'/profile/' + lecture.user.slug" *ngIf="lecture.user" target="_blank">
                <div class="rounded-picture"
                     [style.background]="'url('+lecture.user.picture_url+')'"
                     [style.background-size]="'cover'"></div>
              </a>
              <p style="margin-left: 79px" *ngIf="!lecture.user"></p>
              <div class="media-body">
                <h6 style="float: right;">{{
                  dateMomentService.formatTimeWithSecondToTimeithout(lecture.time_begin) }}</h6>
                <h6 *ngIf="lecture.user" [routerLink]="'/profile/' + lecture.user.slug">
                  {{lecture.user.professor ===
                1 ? 'Pr ' : lecture.user.is_doctor ?
                  'Dr ' : ''}}{{lecture.user.first_name + ' ' + lecture.user.last_name}}</h6>
                <span *ngIf="lecture.user">
                  {{lecture.user.specialty ? translationService.getTranslatedAttribute('name', lecture.user.specialty.translations, locale) : ''}}{{lecture.user.country ? ', ' + translationService.getTranslatedAttribute('name', lecture.user.country.translations, locale) : ''}}
					      </span>
                <p>{{translationService.getTranslatedAttribute('title', lecture.translations, locale)}}</p>
                <span *ngIf="lecture.lecture_time != 0"> {{ dateMomentService.formatTimeMinutes(lecture.lecture_time)}}
                  min</span>
                <h6 style="float: right;">{{
                  dateMomentService.formatTimeWithSecondToTimeithout(lecture.time_end) }}</h6>
                <div class="video-buttons">
                  <a (click)="open('abstract', lecture)" class="button button-rounded button-orange"
                     *ngIf="lecture && lecture.disclosure_status === 'accepted'">{{'academy.Abstract'|translate}}</a>
                  <a (click)="open('disclosures', lecture)" class="button button-rounded button-orange"
                     *ngIf="lecture && lecture.disclosure_status === 'accepted'">{{'academy.Disclosures'|translate}}</a>
                </div>
              </div>
            </div>
            <br>
            <div class="register" [style.background]="isAop ? '#0f2a48' : null"
                 *ngIf="webinar.has_program">
              <a href="{{ environment.new_cdn_base_url +  '/sessions/pdf_details/en_' + webinar.id + '.pdf' }}"
                 style="cursor:pointer;color:white" class="button button-orange"
                 target="_blank">
                <p style="text-align: center;cursor: pointer;margin-bottom: 0;font-size: 14px">
                                                <span style="text-align: center; display: inline-block;" placement="top"
                                                      class="fa fa-download"
                                                      aria-hidden="true"></span>
                  {{ ('academy.PDF_program'|translate) }}
                </p>
              </a>
            </div>

          </div>

          <div class="register-form" *ngIf="!webinar.webinar_launched">
            <p class="tagline"
               [innerHTML]="'webinar.Questions_for_experts'|translate:{mail:'<a href=mailto:' + emailSupport + '>' + emailSupport + '</a>'}"></p>
            <p>
              <a href="" [routerLink]="'/webinars/system-check'">
                {{ 'webinar.Test_system'|translate }}
              </a>
            </p>
          </div>
        </div>

      </div>

      <!-- Main content -> Sidebar -->
      <div class="col-sm-12 col-md-4 col-lg-3">
        <div *ngIf="webinar.auth_registered && webinar.webinar_launched == 1" class="live-chat">
          <ngb-tabset class="poll-tab" [destroyOnHide]="false">
            <ngb-tab [title]="'academy.Chat'|translate">
              <ng-template ngbTabContent>
                <app-pusher-chat #chat
                                 [channels]="channels"
                                 [statusWeb]="webinar.webinar_launched"
                                 [comments]="chatComments"
                                 [webinarId]="webinar.id"
                                 (status)="chatStatusChange($event)">
                </app-pusher-chat>
              </ng-template>
            </ngb-tab>

            <ngb-tab [title]="'academy.Poll'|translate" [disabled]="!showPoll">
              <ng-template ngbTabContent>
                <app-webinar-poll (hasPoll)="setPollStatus($event)"
                                  [sessionId]="webinar.id"></app-webinar-poll>
              </ng-template>
            </ngb-tab>

          </ngb-tabset>
        </div>

        <div id="1" *ngIf="(!webinar.auth_registered || !webinar.webinar_launched) && ( (webinar.partners?.industry && webinar.granted == 1) || (webinar.partners?.industry && webinar.sponsored)
            || (webinar.partners?.scientific_societies.length > 0 || webinar.partners?.publication))">
          <app-webinar-sponsors [webinar]="webinar"></app-webinar-sponsors>
        </div>
      </div>

    </div>

    <!-- Main-content -> Sub-video (user logged in) -->
    <div class="row" *ngIf="webinar.auth_registered && webinar.webinar_launched == 1">

      <div class="col-sm-12 col-md-8 col-lg-9">

        <div class="live-video-bottom"
             *ngIf="webinar.webinar_launched == 1 && webinar.auth_registered">
          <ng-template #askQuestionLiveContent let-modal>
            <div class="modal-body text-center modal-ask-question">
              <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
              <h3>Ask your question here, we will come back to you</h3>
              <br>
              <textarea name="ask-your-question" id="" cols="50" rows="5" [(ngModel)]="questionToSend"
                        (ngModelChange)="sendMessage($event)"></textarea>
              <br>
              <button [disabled]="!questionToSend || questionToSend === ''"
                      (click)="sendQuestion(questionToSend); questionToSend=null;"
                      class="button button-orange">Join us
              </button>
            </div>
          </ng-template>
          <ng-template #technicalIssuesContent let-modal>
            <div class="modal-body text-center modal-ask-question">
              <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
              <h3>IMCAS Academy website:</h3>
              <ul>
                <li>
                  We recommend using the latest version of the Chrome browser : <a
                  href="https://www.google.com/intl/en_en/chrome/" target="_blank">download Chrome</a>
                  , <a
                  href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en"
                  target="_blank">update Chrome</a>
                </li>
                <li>
                  Clear your browser cache (<a
                  href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
                  target="_blank">here</a> for Chrome, select ‘Pictures and cache’)
                </li>
                <li>
                  Refresh the page
                </li>
              </ul>

              <h3>IMCAS Academy App:</h3>
              <p>We remind you that you can watch webinar on your mobile phone or tablet directly on the IMCAS
                Academy App</p>
              <div class="col-md-12 text-center download-buttons">
                <div class="buttons">
                  <a href="https://itunes.apple.com/us/app/imcas-academy/id1410088543?l=fr&ls=1&mt=8"
                     target="_blank">
                    <img src="{{ environment.cdn_base_url }}/assets-front/img/download_app_store.png"
                         alt="download app on app store">
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.imcas.academy"
                     target="_blank">
                    <img src="{{ environment.cdn_base_url }}/assets-front/img/download_google_play.png"
                         alt="download app on google play">
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <a (click)="open(askQuestionLiveContent)" class="ask-question-button" *ngIf="webinar.question_live"
             [ngClass]="{'isDisabled': !webinar.question_live}">
            <i class="fa fa-comments" style="margin-right: 10px;"></i>
            {{'academy.Ask_a_question_live'|translate}}
          </a>
          <a (click)="open(technicalIssuesContent)" class="ask-question-button">
            <i class="fa fa-tools" style="margin-right: 10px;"></i>
            {{'academy.Technical_guidelines'|translate}}
          </a>
          <div ngbDropdown class="d-inline-block share-dropdown">
            <button class="btn" id="dropdown-share" ngbDropdownToggle><i class="fa fa-share"
                                                                         aria-hidden="true"></i>
              {{'academy.Share'|translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdown-share" class="open-dropdown">
              <li ngbDropdownItem>
                <a [style.color]="isAop ? '#0f2a48' : null"
                   [href]="'mailto:?subject='+('webinar.Tweet_message'|translate)+'&amp;body='+('academy.Share_mail_line1'|translate)+'%0A%0A'+('academy.Share_mail_line2_course'|translate)+'%0A'+environment.base_url+'%2F'+locale+'%2Fwebinar%2F'+webinar.slug+'%0A%0A'+('webinar.Tweet_message'|translate)+'%0A%0A'+(currentUser ? currentUser.first_name + ' ' + currentUser.last_name : '')"><i
                  class="fa fa-envelope" aria-hidden="true"></i> {{ 'account.Email'|translate }}</a>
              </li>
              <li ngbDropdownItem>
                <a [style.color]="isAop ? '#0f2a48' : null" target="_blank"
                   [href]="'https://www.facebook.com/sharer/sharer.php?u='+environment.base_url+'%2F'+locale+'%2Facademy'+'%2Fwebinar%2F'+webinar.slug"><i
                  class="fab fa-facebook" aria-hidden="true"></i> Facebook</a>
              </li>
              <li ngbDropdownItem>
                <a [style.color]="isAop ? '#0f2a48' : null" target="_blank"
                   [href]="'https://twitter.com/intent/tweet?text='+('webinar.Tweet_message'|translate)+'&amp;url='+environment.base_url+'%2F'+locale+'%2Fwebinar%2F'+webinar.slug+'&amp;hashtags=IMCASAcademy&amp;via=imcascongress&amp;related=imcascongress'"><i
                  class="fab fa-twitter" aria-hidden="true"></i> Twitter</a>
              </li>
              <li ngbDropdownItem>
                <a [style.color]="isAop ? '#0f2a48' : null" target="_blank"
                   [href]="'https://www.linkedin.com/shareArticle?mini=true&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy'+'%2Fwebinar%2F'+webinar.slug+'&amp;title='+('webinar.Tweet_message'|translate)"><i
                  class="fab fa-linkedin" aria-hidden="true"></i> LinkedIn</a>
              </li>
            </div>
          </div>
          <span class="nb-live-viewers btn"
                *ngIf="webinar.webinar_launched == 1 && webinar.auth_registered && viewers && viewers.live_viewers > 50">
                    <i class="fa fa-eye" aria-hidden="true"></i>{{viewers.error ? '0' : viewers.live_viewers}}
                </span>
          <div class="webinar-rating" *ngIf="showRating">

            <ngb-rating
              [rate]="userRating"
              (hover)="setCurrentRating($event)"
              (click)="rateLiveWebinar()"
              max="5"
              min="1"
              [readonly]="userRating != null ? true : false"
            >
              <ng-template let-fill="fill">
                <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
              </ng-template>
            </ngb-rating>
          </div>
        </div>

        <div class="program" *ngIf="webinar.lectures?.length">
          <h3>{{ 'webinar.Webinar_program'|translate }}</h3>
          <div class="program-item media" *ngFor="let lecture of webinar.lectures">
            <a [routerLink]="'/profile/' + lecture.user.slug" *ngIf="lecture.user" target="_blank">
              <div class="rounded-picture"
                   [style.background]="'url('+lecture.user.picture_url+')'"
                   [style.background-size]="'cover'"></div>
            </a>
            <p style="margin-left: 79px" *ngIf="!lecture.user"></p>
            <div class="media-body">
              <h6 style="float: right;">{{
                dateMomentService.formatTimeWithSecondToTimeithout(lecture.time_begin) }}</h6>
              <h6 *ngIf="lecture.user" [routerLink]="'/profile/' + lecture.user.slug">
                {{lecture.user.professor ===
              1 ? 'Pr ' : lecture.user.is_doctor ?
                'Dr ' : ''}}{{lecture.user.first_name + ' ' + lecture.user.last_name}}</h6>
              <span *ngIf="lecture.user">
						{{lecture.user.specialty ? translationService.getTranslatedAttribute('name', lecture.user.specialty.translations, locale) : ''}}
                {{lecture.user.country ? ', ' + translationService.getTranslatedAttribute('name', lecture.user.country.translations, locale) : ''}}
					</span>
              <p>{{translationService.getTranslatedAttribute('title', lecture.translations, locale)}}</p>
              <span *ngIf="lecture.lecture_time != 0"> {{ dateMomentService.formatTimeMinutes(lecture.lecture_time)}}
                min (id: {{lecture.id}}
                )</span>
              <span *ngIf="lecture.lecture_time == 0">id: {{lecture.id}}</span>
              <h6 style="float: right;">{{
                dateMomentService.formatTimeWithSecondToTimeithout(lecture.time_end) }}</h6>
              <div class="video-buttons">
                <a (click)="open('abstract', lecture)" class="button button-rounded"
                   *ngIf="lecture && lecture.disclosure_status === 'accepted'">{{'academy.Abstract'|translate}}</a>
                <a (click)="open('disclosures', lecture)" class="button button-rounded"
                   *ngIf="lecture && lecture.disclosure_status === 'accepted'">{{'academy.Disclosures'|translate}}</a>
              </div>
            </div>
          </div>
          <br>
          <div class="register" [style.background]="isAop ? '#0f2a48' : null"
               *ngIf="webinar.has_program">
            <a href="{{ environment.new_cdn_base_url +  '/sessions/pdf_details/en_' + webinar.id + '.pdf' }}"
               style="cursor:pointer;color:white" class="button button-orange"
               target="_blank">
              <p style="text-align: center;cursor: pointer;margin-bottom: 0;font-size: 14px">
                                                <span style="text-align: center; display: inline-block;" placement="top"
                                                      class="fa fa-download"
                                                      aria-hidden="true"></span>
                {{ ('academy.PDF_program'|translate) }}
              </p>
            </a>
          </div>

        </div>

        <div class="register-form" *ngIf="!webinar.webinar_launched">
          <p class="tagline"
             [innerHTML]="'webinar.Questions_for_experts'|translate:{mail:'<a href=mailto:' + emailSupport + '>' + emailSupport + '</a>'}"></p>
          <p>
            <a href="" [routerLink]="'/webinars/system-check'">
              {{ 'webinar.Test_system'|translate }}
            </a>
          </p>
        </div>


      </div>

      <div class="col-sm-12 col-md-4 col-lg-3">
        <div id="2" *ngIf="( (webinar.partners?.industry && webinar.granted == 1) || (webinar.partners?.industry && webinar.sponsored)
            || (webinar.partners?.scientific_societies.length > 0 || webinar.partners?.publication))">
          <app-webinar-sponsors [webinar]="webinar"></app-webinar-sponsors>
        </div>
      </div>

    </div>


  </div>
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class PollService {
    private endpoint: string = '/webinars/';

    constructor(private httpClient: HttpClient) {
    }

    getPoll(sessionId): Observable<any> {
      if (!sessionId) {
        return;
      }

      return this.httpClient.get<any>(environment.apiURL + this.endpoint + sessionId + '/survey');
    }

    answerPoll(surveyId, answer): Observable<any> {
      if (!surveyId || !answer) {
        return;
      }
      const reqOpts = {
        'answer': answer
      };
      return this.httpClient.post<any>(environment.apiURL + this.endpoint + 'survey/' + surveyId + '/answer', reqOpts);
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../models/course.model';
import {TranslationService} from '../../services/translation.service';
import {DateMomentService} from '../../services/date-moment.service';
import {Router} from '@angular/router';
import {LocaleService} from '../../services/locale.service';
import {User} from '../../models/user.model';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-course-element',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss'],
    providers: [
        TranslationService,

    ]
})
export class CourseElementComponent implements OnInit {
    @Input() course: Course;
    @Input() actions: boolean;
    @Input() user: User;
    @Input() showPublicationDate: boolean = true;
    locale: any;
    isAop: boolean = environment.domain === 'ophthalmology';
    environment: any = environment;
    userToken: string;

    constructor(public dateMomentService: DateMomentService,
                public translationService: TranslationService,
                private router: Router,
                private localeService: LocaleService,
                private authService: AuthService) {
        this.locale = localeService.currentLocale();

    }

    ngOnInit() {
      this.userToken = this.authService.getCurrentUser().personal_token;
    }

    goToCourse(course) {
        this.router.navigateByUrl('/course/' + course.getId() + '/' + course.getSlug());
    }
}

import {environment} from './../../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from 'app/services/auth.service';
import {User} from '../../../models/user.model';
import {UserService} from 'app/services/user.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
    accountMenuOpened: any;
    user: User;
    environment = environment;
    percent: number;
    subscription: Subscription;

    constructor(private modalService: NgbModal,
                private authService: AuthService,
                private userService: UserService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.user = this.authService.getCurrentUser();
        this.subscription = this.userService.percentCompletion$.subscribe(
            percent => {
                this.percent = percent;
            }
        );
        this.accountMenuOpened = { opened: false };
    }
}

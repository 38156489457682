import {Specialty} from './../models/specialty.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class SpecialtyService {
    private endpoint: string = '/specialties';

    constructor(
        private httpClient: HttpClient
    ) { }

    getSpecialties(params?: any): Observable<Specialty[]> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k) && params[k]) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }else {
            reqOpts.params = reqOpts.params.set('all', '1');
        }
        return this.httpClient.get<Specialty[]>(environment.apiURL + this.endpoint, reqOpts).pipe(shareReplay());
    }
}

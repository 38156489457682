import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Tweet} from '../models/tweet.model';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class TwitterService {
    private endpoint: string = '/tweets';

    constructor(private httpClient: HttpClient) {
    }

    getLastTweets(): Observable<Tweet[]> {
        return this.httpClient.get<any>(environment.apiURL + this.endpoint).pipe(shareReplay());
    }
}

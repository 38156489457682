<div class="page">
	<div class="content" [ngClass]="{'has-active-conversation': selectedThread}">
		<h1>{{ 'academy.Inbox'|translate }}</h1>
		<div class="row inbox-wrapper">
			<aside class="col-md-4 inbox-sidebar">
				<div class="inbox-search">
					<input type="search" maxlength="511" class="inbox-search-input" [placeholder]="'academy.Search'|translate" #searchBox id="search-box" (keyup)="searchUserThreads(searchBox.value)" (focus)="showSuggest = true">
					<i class="fa fa-search" aria-hidden="true"></i>
				</div>
				<div *ngIf="users.length > 0 && showSuggest" class="ui-search-algolia-suggest">
					<ul>
						<li *ngFor="let user of users" (click)="selectThread(null, user)"
							[innerHTML]="user._highlightResult.fullname.value"></li>
					</ul>
				</div>
				<div class="inbox-contacts">
					<div *ngIf="filteredThreads && filteredThreads.length === 0">
						<p class="no-message">{{ 'application.No_message'|translate }}</p>
					</div>
					<ng-container *ngFor="let thread of filteredThreads">
						<div *ngIf="thread.participant && thread.last_message" [ngClass]="{'inbox-contacts-item': true,'active': (selectedThread && (thread.id === selectedThread.id)), 'unread': (thread.last_message && !thread.last_message.read)}"
						 (click)="selectThread(thread)">
							<div class="media">
								<img class="rounded-circle"
                     [src]="!!thread.participant.user.company ? thread.participant.user.logo_url : thread.participant.user.picture_url"
                     [alt]="!!thread.participant.user.company ? thread.participant.user.company_name : thread.participant.user.first_name + ' ' + thread.participant.user.last_name"
                >
								<div class="media-body">
									<h6>{{thread.participant.user.company ? thread.participant.user.company_name : thread.participant.user.title + ' ' + thread.participant.user.first_name + ' ' + thread.participant.user.last_name}}</h6>
									<span>{{thread.last_message.created_at > thread.participant.last_read ? dateMomentService.formatHumanWay(thread.last_message.created_at) : dateMomentService.formatHumanWay(thread.participant.last_read)}}</span>
									<p>{{thread.last_message.body}}</p>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</aside>
			<main class="col-md-8 inbox-container">
				<div class="inbox-header" *ngIf="selectedThread && selectedThread.participant">
					<h6>
						<i class="fa fa-long-arrow-left" aria-hidden="true" (click)="selectThread()"></i>
						<span [style.background-image]="'url(' + selectedThread.participant.user.picture_url + ')'"
							  [style.background-size]="'cover'" class="rounded-circle"></span>
						<span class="title-name" [routerLink]="'/profile/' + selectedThread.participant.user.slug">{{selectedThread.participant.user.first_name ? selectedThread.participant.user.title + ' ' + selectedThread.participant.user.first_name + ' ' + selectedThread.participant.user.last_name : selectedThread.participant.user.company_name}}</span>
					</h6>
				</div>
				<div class="inbox-messages" *ngIf="selectedThread" #scrollMe>
					<ng-container *ngFor="let message of selectedThread.messages; index as i">
						<div class="inbox-message-date" *ngIf="i===0 || dateMomentService.getDifference(selectedThread.messages[i].created_at, selectedThread.messages[i-1].created_at) > 0">
							<span>{{message.created_at.split('-').join('/') | date:'d MMMM y':'':locale}}</span>
						</div>
						<div class="inbox-message-item" [ngClass]="{'inbox-message-from': message.user_id !== user.id, 'inbox-message-to':  message.user_id === user.id}">
							<div class="media">
								<img class="rounded-circle" [src]="selectedThread.participant.user.picture_url"  *ngIf="message.user_id !== user.id">
								<div class="media-body">
									<p>{{message.body}}</p>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="inbox-textarea" *ngIf="selectedThread">
					<input type="text" placeholder="Type here..." [(ngModel)]="messageToSend">
					<button [disabled]="!messageToSend" class="button button-orange" (click)="sendMessage(messageToSend, selectedThread.id)">Send</button>
				</div>
			</main>
		</div>
	</div>
</div>

<div class="modal-body">
  <div *ngIf="content == 'imcas'">
    <h2>{{'academy.Welcome_to_premium_community'|translate}}</h2>

    <p>{{'academy.Covid_modal_imcas_text'|translate}}</p>

    <button (click)=activate() class="btn btn-full">{{'academy.Unlock_now'|translate}}</button>
  </div>

  <div *ngIf="content == 'ophthalmologist'">
    <h2>{{'academy.Welcome_to_AOP_premium_community'|translate}}</h2>

    <p> {{'academy.Covid_modal_ophthalmologist_text'|translate}}</p>

    <button (click)=activate() class="btn btn-full">{{'academy.Unlock_now'|translate}}</button>
  </div>

  <div *ngIf="content == 'orthoptist'">
    <h2>{{'academy.Welcome_to_AOP_premium_community'|translate}}</h2>

    <p>{{'academy.Covid_modal_orthoptist_text'|translate}}</p>

    <button (click)=activate() class="btn btn-full">{{'academy.Unlock_now'|translate}}</button>
  </div>
</div>


import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../models/user.model';
import {Comment} from '../../models/comment.model';
import {Router} from '@angular/router';
import {TranslationService} from '../../services/translation.service';
import {MediaService} from '../../services/media.service';
import {CommentService} from '../../services/comment.service';
import {DateMomentService} from '../../services/date-moment.service';


@Component({
    selector: 'app-comment-area',
    templateUrl: './comment-area.component.html',
    styleUrls: ['./comment-area.component.scss'],
    providers: [CommentService]
})
export class CommentAreaComponent implements OnInit {
    @Input() user: User;
    @Input() comments: Comment[];
    @Input() locale: string;
    @Input() id: number;
    @Input() type: string;
    @Input() mustBePremium: boolean;
    @Input() mustBuyInMarketplace: boolean;
    @Input() userIsSpeaker: boolean;
    @Input() readOnly: boolean;
    commentToPost: string = '';
    commentRequestLaunch: boolean = false;


    constructor(private router: Router,
                public translationService: TranslationService,
                private mediaService: MediaService,
                private commentService: CommentService,
                public dateMomentService: DateMomentService) {
    }

    ngOnInit() {
    }

    postComment(comment) {
        this.commentRequestLaunch = true;
        this.commentToPost = null;
        if (this.type === 'academy_media') {
            this.mediaService.addMediaComment(this.id, comment).subscribe(
                comm => {
                    this.commentRequestLaunch = false;
                    this.comments.push(comm);
                },
                err => {
                    this.commentRequestLaunch = false;
                });
        } else if (this.type === 'article') {
            this.commentService.commentArticle(this.id, comment).subscribe(comm => {
                    this.commentRequestLaunch = false;
                    this.comments.push(comm);
                },
                err => {
                    this.commentRequestLaunch = false;
                });
        }

    }

    openUrlProfile(userInfo) {
        if (userInfo.public == 0) {
          return
        }
        const user = new User(userInfo);
        this.router.navigateByUrl(user.getProfileUrl());
    }
}

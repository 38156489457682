<div class="page">
    <div class="page-content content">
        <div *ngIf="env.domain == 'aesthetics'">
            <h1>IMCAS Academy - Contributors Terms & Conditions</h1>
            <p>
                IMCAS wants to thank you for your presentation at the IMCAS Congress (and/or any other partner event).
                We want to take this opportunity to invite you to be a part of our online e-learning platform project,
                the IMCAS Academy, by allowing us to make your presentation and supporting materials available to the
                members of the IMCAS Academy.
            </p>
            <p>
                You will find below a description of the IMCAS Academy and the terms and conditions under which we may
                publish your presentation and supporting materials on the IMCAS Academy.
            </p>
            <p>
                <span>DEFINITION</span>
                <br> Learning Material (LM): Which shall include your recorded presentation during the IMCAS Congress
                and all supporting learning materials, including but not limited to audiovisuals, photographs,
                recordings and slides.
                <br> IMCAS Academy Contributor (IAC): Which shall include both physicians and non-physicians that
                deliver a presentation either during an IMCAS congress (and/or any other related partner event) or
                directly through the IMCAS Academy platform.
            </p>
            <span>WHAT IS IMCAS ACADEMY?</span>
            <p>
                IMCAS Academy is the IMCAS web e-learning platform. It gathers all LM activities submitted by the
                Contributor or by IMCAS and accepted by the Board.
            </p>
            <p>
                <span>What is an IMCAS Academy Contributor?</span>
                The person providing the LM (speaker, chair).
                <br>
                <span>What is a User?</span> The person benefiting
                from the LM (attendee of a conference or web subscriber of IMCAS Academy)
                <br>
                <span>Who has access to the LM?</span> All LM are
                made strictly available to Users according to the LM access policy. A few selected videos will be
                periodically made accessible to any visitors of the website, prior consent of the contributor of such
                videos.
            </p>
            <ul>
                There are two available statuses for Users:
                <li>Physicians</li>
                <li>Non-physicians (registered nurses, physician assistants, industrials, media, financial analysts)
                </li>
            </ul>
            <p>
                Subscriptions under the physician status require a proof of medical certification, which will be
                strictly verified by the IMCAS team.
            </p>
            <ul>
                According to the Academy's access policy:
                <li>Physicia s will have unrestricted access to all LM featured in the Academy</li>
                <li>Non physicians will be able to access the LM featured in the following categories: Cosmeceutics,
                    Peelings, Mesotherapy & Anti-Aging, Professional Business, IMCAS Beyond, Injectables (depending
                    on your right to practice this specialty in your country) and Lasers & EBD (depending on your
                    right to practice this specialty in your country)
                </li>
            </ul>
            <p>
                The access policy is displayed on the Academy website on the subscription page, featured here: <a
                    href="https://www.imcas.com/en/academy/premium">https://www.imcas.com/en/academy/premium</a>. This
                access policy may be subject to change. Any such changes will be updated on the webpage aforementioned.
            </p>
            <p>
                On the web, the compliance with this policy is ensured by the observance of a strict connection policy
                (access secured with a unique login and password).
            </p>
            <p>
                <span>How is my LM stored?</span> From the moment
                your LM is downloaded onto the IMCAS server, it becomes securely stored. All accesses to this server and
                to its backups are consigned and available for verification at any time.
            </p>
            <p>
                Upon submission of your LM, IMCAS might edit the audiovisual content of the recording, without altering
                the educational content of the material, in order to protect patient's privacy or confidentiality.
            </p>
            <p><span>What are the 5 top reasons why my LM should be included within the IMCAS Academy?</span>
            </p>
            <ol>
                <li>IMCAS Academy is the first web platform issued from a renowned international conference (IMCAS)
                    entirely dedicated to continuous education within the field of aesthetic science: plastic and
                    aesthetic surgery, clinical and cosmetic dermatology, and all related fields.
                </li>
                <li>IMCAS Academy already includes hundreds of videos from international speakers, all classified by
                    themes, keywords, and courses.
                </li>
                <li>IMCAS Community today: 18 000 physicians already attended an IMCAS conference, with 120 000 unique
                    visitors every year on IMCAS.COM. Therefore, IMCAS Academy gives you the best international exposure
                    ever, while making sure your LM benefits from the security, ethic, and quality insurance IMCAS has
                    always been famous for since 1998.
                </li>
                <li>IMCAS Academy is the only web platform ensuring a strict access to its Contributors and Users,
                    enlightening each Contributor with an exceptional qualified and certified audience.
                </li>
                <li>Due to its organizational structure, IMCAS Academy provides an immediate personal assistance to each
                    of its Contributors and Users, anytime, anywhere.
                </li>
            </ol>
            <h1>IMCAS Contributor Agreement</h1>
            <p>
                By participating at an IMCAS Congress, you authorize IMCAS to record, film, photograph, edit and stream
                your intervention during the IMCAS Congress, including any supporting materials used during your
                presentation.
            </p>
            <p>
                By accepting to publish your LM on the IMCAS Academy portal, you grant IMCAS and any third party
                licensee or assignee thereof, a worldwide, non-exclusive license to publish, reproduce, broadcast,
                distribute, modify, translate and adapt your LM on the IMCAS Academy web e-learning platform.
            </p>
            <p>
                You also expressly authorize IMCAS and any third party licensee or assignee thereof to use, reproduce
                and exploit your image, full name and biographical information on the IMCAS Academy web e-learning
                portal or any material used to promote the IMCAS Academy.
            </p>
            <p>
                We will always identify your name on any of your LM published on the IMCAS Academy, featuring you as
                author or co-author where applicable. Should you need to make any material amendments on the content of
                your LM, you can contact the IMCAS Team to make such changes at any time.
            </p>
            <p>
                We may freely edit your LM to the extent needed to adapt it to the technical and design layout
                requirements of the IMCAS Academy portal, and particularly, to respect third party and privacy rights.
                For example, we may edit any photographs or other materials included in your LM by blurring the eyes of
                the patients, hiding recognizable tattoos...
            </p>
            <p>
                By accepting this agreement, you also guarantee that to your best knowledge, your LM does not infringe
                any third party intellectual or other property rights or violate anyone's privacy or data protection
                rights, or otherwise violate any statutory or common law right of anyone. In the event of an
                infringement claim, you accept to be held liable for the eventual damages caused by it and you agree to
                eventually indemnify IMCAS for any claim or action alleging facts, which, if true, constitute a breach
                of any of the foregoing warranties. Should you become aware that any submitted LM may actually or
                potentially be infringing, you commit to inform us immediately.
            </p>
            <p>
                You have the right at all times to ask for the removal of your LM, provided you are the exclusive author
                of it. If you decide to exert this right, we commit to remove your video from the Academy's platform and
                our servers within the following 6 (six) weeks after the request.
            </p>
        </div>
        <div *ngIf="env.domain == 'ophthalmology' && locale != 'en'">
            <h1>AOP Academy - Modalités Contributeurs</h1>

            <p>
                Les AOP tiennent à vous remercier pour votre présentation au Congrès AOP (et/ou tout autre évènement
                partenaire). Nous souhaitons profiter de cette opportunité pour vous inviter à faire partie de notre projet
                de plateforme d'apprentissage en ligne, l'AOP Academy, en nous autorisant à publier votre présentation et
                matériel éducatif pour les membres de l'AOP Academy.
            </p>
            <p>
                Vous trouverez ci-dessous une description de l'AOP Academy ainsi que les modalités et conditions selon
                lesquelles nous publierons votre présentation et matériel éducatif sur l'AOP Academy.
            </p>
            <p>
                <span>DEFINITION</span> <br>

                Learning Material (LM): Qui doit inclure l'enregistrement de votre vidéo pendant le congrès AOP et tous les
                outils pédagogiques, y compris mais sans s'y limiter, l'audiovisuel, les photographies, enregistrements et
                diapositives.
                <br>
                AOP Academy Contributor (AAC): Qui doit inclure médecins et non-médecin fournissant une présentation pendant
                le congrès AOP (et/ou tout autre évènement partenaire lié) ou directement via la plateforme AOP Academy.
            </p>
            <span>QU'EST-CE QUE L'AOP ACADEMY?</span>

            <p>
                L'AOP Academy est la plateforme d'apprentissage en ligne des AOP. Elle regroupe toutes les activités de LM
                soumises par les Contributeurs et acceptées par le Comité.
            </p>
            <p>
                <span>Qu'est-ce qu'un Contributeur de l'AOP Academy?</span>
                La personne qui fournit le LM (orateur, modérateur).<br>

                <span>Qu'est-ce qu'un Utilisateur?</span> La personne
                qui bénéficie du LM (participant à un congrès ou abonné en ligne de l'AOP Academy).
                <br>

                <span>Qui a accès au LM?</span> Tous les LM sont
                accessibles aux Utilisateurs strictement selon la politique d'accès au LM. Quelques vidéos sélectionnées
                seront ponctuellement accessibles à tous les visiteurs du site, avec l'autorisation préalable du
                contributeur de ces vidéos.
            </p>
            <ul>
                Il y a deux types de statuts d'Utilisateur:
                <li>Médecins</li>
                <li>Non-médecins (infirmières diplômées, assistants personnels, industriels,
                    média, analystes financiers)
                </li>
            </ul>

            <p>
                Les inscriptions sous le statut de médecin nécessite une preuve de certification médicale, qui sera
                strictement vérifiée par l'équipe AOP.
            </p>
            <ul>
                Selon la politique d'accès à l'Academy:
                <li>Les médecins auront un accès illimité à tous les LM disponibles sur
                    l'Academy
                </li>
                <li>Les non médecins auront un accès limité.</li>
            </ul>

            <p>
                La politique d'accès est disponible sur le site de l'Academy, sur la page inscription, affichée ici: <a
                    href="https://www.aop-academy.com/fr/academy/premium">https://www.aop-academy.com/en/academy/premium</a>.
                Cette politique d'accès peut faire l'object de modifications. Toute modification sera mise à jour sur la
                page web susmentionnée.
            </p>
            <p>
                Sur internet, la conformité de cette politique est assurée par le respect d'une stricte politique de
                connexion (accès sécurisé par un identifiant et mot de passe unique).
            </p>
            <p>
                <span>Comment mon LM est-il stocké??</span> A partir
                du moment où votre LM est téléchargé sur le serveur AOP, il est stocké de manière sécurisée. Tous les accès
                à ce serveur et à ses sauvegardes sont consignés et mis à disposition pour consultation à tout moment.

            </p>
            <p>
                Une fois votre LM soumis, les AOP peuvent modifier le contenu audiovisuel de l'enregistrement, sans altérer
                le contenu éducatif du matériel, afin de protéger l'anonymat du patient.
            </p>
            <p><span>Quelles sont les 4 meilleures raisons d'inclure mon LM à l'AOP Academy?</span>
            </p>
            <ol >
                <li>L'AOP Academy est une des premières plateformes en ligne issue d'un congrès renommé (AOP) entièrement
                    dédié à une formation continue dans le domaine de l'ophtalmologie.
                </li>
                <li>L'AOP Academy comprend des centaines de vidéos d'orateurs internationaux, toutes classées par thème,
                    mots clés et cours.
                </li>
                <li>L'AOP Academy est une des seule plateforme en ligne qui assure un accès strictement
                    réservé à ses Contributeurs et Utilisateurs, offrant à chaque Contributeur une audience certifiée et
                    qualifiée.
                </li>
                <li>Grâce à sa structure organisationnelle, l'AOP Academy fournit une assistance personnelle et immédiate à
                    chacun de ses Contributeurs et Utilisateurs, à tout moment, et en tout lieu.
                </li>
            </ol>
            <h1>Accord du Contributeur AOP</h1>

            <p>
                En participant au congrès AOP, vous autorisez les AOP à enregistrer, filmer, photographier, éditer et
                diffuser votre intervention lors du congrès AOP, y compris tout matériel éducatif utilisé lors de votre
                présentation.
            </p>
            <p>
                En acceptant de publier votre LM sur le portail AOP Academy, vous octroyez les AOP et tout tiers ou
                représentant de ceux-ci, un permis non-exclusif, international, de publier, reproduire, diffuser,
                distribuer, modifier, traduire et adapter votre LM sur la plateforme d'apprentissage en ligne AOP Academy.
            </p>
            <p>
                Vous autorisez également les AOP et tout tiers ou représentant de ceux-ci, à utiliser, reproduire et
                exploiter votre image, non complet et renseignements biographiques sur la plateforme d'apprentissage en
                ligne AOP Academy ou tout matériel utilisé pour promouvoir l'AOP Academy.
            </p>
            <p>
                Nous mentionnerons toujours votre nom sur chacun de vos LM publiés sur l'AOP Academy, vous présentant comme
                auteur ou co-auteur, si c'est le cas. Si vous souhaitez effectuer toute modification du contenu de votre LM,
                vous pouvez contacter l'équipe AOP à tout moment pour apporter de ces changements.
            </p>
            <p>
                Il se peut que nous modifions librement votre LM si nécessaire afin de l'adapter aux exigences techniques ou
                de mise en page du portail de l'AOP Academy, et en particulier, pour respecter le droit à la vie privée de
                tiers. Par exemple, nous pouvons modifier toute photographie ou autre matériel compris dans votre LM en
                masquant les yeux du patient, dissimulant des tatouages reconnaissables...
            </p>
            <p>
                En acceptant ces conditions, vous garantissez également, que votre LM ne porte pas atteinte aux droits de la
                propriété intellectuelle ou à tout autre droit de parties tierces ou ne viole des droits d'auteur ou de
                protection de données, ou enfreigne toute loi relative au droit statutaire ou commun de quiconque. En cas de
                violation, vous acceptez d'être tenu responsable des éventuels dommages causés et vous acceptez d'indemniser
                les AOP pour toutes réclamations ou revendications, qui, si elles s'avèrent vraies, constituent une rupture
                de toutes les garanties précédentes. Il est important que vous soyez conscients que tout LM soumit peut
                potentiellement enfreindre ses droits. Vous vous engagez à nous en informer immédiatement.
            </p>
            <p>
                Vous avez le droit, à tout moment, de demander à ce que l'on retire votre LM, en tant qu'auteur de celui-ci.
                Si vous décidez d'exercer ce droit, nous nous engageons à retirer votre vidéo de la plateforme Academy et de
                nos serveur dans les 6 (six) semaines suivant la demande.
            </p>
        </div>
        <div *ngIf="env.domain == 'ophthalmology' && locale == 'en'">
            <h1>AOP Academy - Contributors Terms & Conditions</h1>

            <p>
                AOP wants to thank you for your presentation at the AOP Congress (and/or any other partner event). We want
                to take this opportunity to invite you to be a part of our online e-learning platform project, the AOP
                Academy, by allowing us to make your presentation and supporting materials available to the members of the
                AOP Academy.
            </p>
            <p>
                You will find below a description of the AOP Academy and the terms and conditions under which we may publish
                your presentation and supporting materials on the AOP Academy.
            </p>
            <p>
                <span>DEFINITION</span> <br>

                Learning Material (LM): Which shall include your recorded presentation during the AOP Congress and all
                supporting learning materials, including but not limited to audiovisuals, photographs, recordings and
                slides.
            </p>
            <span>WHAT IS AOP ACADEMY?</span>

            <p>
                AOP Academy is the AOP web e-learning platform. It gathers all LM activities submitted by the Contributor or
                by AOP and accepted by the Board.
            </p>
            <p>
                <span>What is an AOP Academy Contributor?</span> The
                person providing the LM (speaker, chair).<br>

                <span>What is a User?</span> The person benefiting
                from the LM (attendee of a conference or web subscriber of AOP Academy)
                <br>

                <span>Who has access to the LM?</span> All LM are made
                strictly available to Users according to the LM access policy. A few selected videos will be periodically
                made accessible to any visitors of the website, prior consent of the contributor of such videos.
            </p>
            <ul>
                There are two available statuses for Users:
                <li>Physicians</li>
                <li>Non-physicians (registered nurses, physician assistants, industrials, media,
                    financial analysts)
                </li>
            </ul>

            <p>
                Subscriptions under the physician status require a proof of medical certification, which will be strictly
                verified by the AOP team.
            </p>
            <ul>
                According to the Academy's access policy:
                <li>Physicians will have unrestricted access to all LM featured in the Academy
                </li>
                <li>Non physicians will have a limited access</li>
            </ul>

            <p>
                The access policy is displayed on the Academy website on the subscription page, featured here: <a
                    href="https://www.aop-academy.com/en/academy/premium">https://www.aop-academy.com/en/academy/premium</a>.
                This access policy may be subject to change. Any such changes will be updated on the webpage aforementioned.
            </p>
            <p>
                On the web, the compliance with this policy is ensured by the observance of a strict connection policy
                (access secured with a unique login and password).
            </p>
            <p>
                <span>How is my LM stored?</span> From the moment your
                LM is downloaded onto the AOP server, it becomes securely stored. All accesses to this server and to its
                backups are consigned and available for verification at any time.

            </p>
            <p>
                Upon submission of your LM, AOP might edit the audiovisual content of the recording, without altering the
                educational content of the material, in order to protect patient's privacy or confidentiality.
            </p>
            <p><span>What are the 5 top reasons why my LM should be included within the AOP Academy?</span>
            </p>
            <ol>
                <li>AOP Academy is the first web platform issued from a renowned international conference (AOP) entirely
                    dedicated to continuous education within the field of ophthalmology.
                </li>
                <li>AOP Academy already includes hundreds of videos from international speakers, all classified by themes,
                    keywords, and courses.
                </li>
                <li>AOP Academy is the only web platform ensuring a strict access to its Contributors and Users,
                    enlightening each Contributor with an exceptional qualified and certified audience.
                </li>
                <li>Due to its organizational structure, AOP Academy provides an immediate personal assistance to each of
                    its Contributors and Users, anytime, anywhere.
                </li>
            </ol>
            <h1>AOP Contributor Agreement</h1>

            <p>
                By participating at an AOP Congress, you authorize AOP to record, film, photograph, edit and stream your
                presentation/demonstration during the AOP Congress, including any supporting materials used during your
                presentation.
            </p>
            <p>
                By accepting to publish your LM on the AOP Academy portal, you grant AOP and any third party licensee or
                assignee thereof, a worldwide, non-exclusive license to publish, reproduce, broadcast, distribute, modify,
                translate and adapt your LM on the AOP Academy web e-learning platform.
            </p>
            <p>
                You also expressly authorize AOP and any third party licensee or assignee thereof to use, reproduce and
                exploit your image, full name and biographical information on the AOP Academy web e-learning portal or any
                material used to promote the AOP Academy.
            </p>
            <p>
                We will always identify your name on any of your LM published on the AOP Academy, featuring you as author or
                co-author where applicable. Should you need to make any material amendments on the content of your LM, you
                can contact the AOP Team to make such changes at any time.
            </p>
            <p>
                We may freely edit your LM to the extent needed to adapt it to the technical and design layout requirements
                of the AOP Academy portal, and particularly, to respect third party and privacy rights. For example, we may
                edit any photographs or other materials included in your LM by blurring the eyes of the patients, hiding
                recognizable tattoos...
            </p>
            <p>
                By accepting this agreement, you also guarantee that to your best knowledge, your LM does not infringe any
                third party intellectual or other property rights or violate anyone's privacy or data protection rights, or
                otherwise violate any statutory or common law right of anyone. In the event of an infringement claim, you
                accept to be held liable for the eventual damages caused by it and you agree to eventually indemnify AOP for
                any claim or action alleging facts, which, if true, constitute a breach of any of the foregoing warranties.
                Should you become aware that any submitted LM may actually or potentially be infringing, you commit to
                inform us immediately.
            </p>
            <p>
                You have the right at all times to ask for the removal of your LM, provided you are the exclusive author of
                it. If you decide to exert this right, we commit to remove your video from the Academy's platform and our
                servers within the following 6 (six) weeks after the request.
            </p>
        </div>
    </div>

</div>

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'app-profile-complete',
    templateUrl: './profile-complete.component.html',
    styleUrls: ['./profile-complete.component.scss']
})
export class ProfileCompleteComponent implements OnInit {

    title: string;
    subtitle: string;

    constructor(public activeModal: NgbActiveModal,
                private cookieService: CookieService) {
    }

    ngOnInit() {

    }

    acceptButton() {
        this.cookieService.set('modal_profile_complete', 'ok', 10);
        this.activeModal.close('ok');
    }
}

import {LocaleService} from 'app/services/locale.service';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class UserThemesGuardService implements CanActivate {

    constructor(private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private localeService: LocaleService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.authService.isLoggedIn().subscribe(value => {
                if (value) {
                    const user = this.authService.getCurrentUser();
                    if (user && (!user.themes || user.themes.length === 0)) {
                        this.router.navigate(['/account/create/step-2', {redirection: this.router.routerState.snapshot.url}]);
                        return false;
                    }
                }
            }
        );
        return true;
    }
}

import {Translation} from './translation.model';
import {User} from './user.model';
import {Lecture} from './lecture.model';

export class Media {
    id: number;
    objectID: number;
    mediaID: number;
    media_id: number;
    academy_media_id: number;
    pivot: any;
    viewed: boolean;
    enrolled: boolean;
    favorite: boolean;
    date: number | string;
    others: string;
    free: boolean;
    sponsored: boolean;
    certificate: boolean;
    type: string;
    duration: number | string;
    thumb: string;
    avatar: string;
    author_links: any[];
    mediaTitles: any[];
    sponsor: any;
    comments: any;
    rating: number;
    keywords: any[];
    comment: string;
    currentRate: number;
    lecture: Lecture;
    translations: Translation[];
    speakers: User[];
    cdn_file: any;
    cdn_file_short: any;
    captation_date: any;
    cdn_dash_file: any;
    picture_url: string;
    thumb_squared: string;
    header: string;
    publication_date: string;
    intro_html: string;
    languages: any[];
    languages1: any[];

    isViewed(user: User): boolean {
        if (!user || !user.media_viewed) {
            return false;
        }
        for (let i = 0; i < user.media_viewed.length; ++i) {
            if (+user.media_viewed[i] === +this.objectID) {
                return true;
            }
        }
        return false;
    }

    isEnrolled(user: User): boolean {
        if (!user || !user.favorited || !user.favorited.academy_medias) {
            return false;
        }
        for (let i = 0; i < user.favorited.academy_medias.length; ++i) {
            if (+user.favorited.academy_medias[i] === +this.getAcademyMediaId()) {
                this.favorite = true;
                return true;
            }
        }
        this.favorite = false;
        return false;
    }

    getAcademyMediaId() {
        return this.objectID ? this.objectID : this.academy_media_id;
    }

    constructor(obj?: any) {
        this.id = obj && obj.id;
        this.objectID = obj && obj.objectID;
        this.mediaID = obj && obj.mediaID;
        this.academy_media_id = obj && obj.academy_media_id;
        this.media_id = obj && obj.media_id;
        this.pivot = obj && obj.pivot;
        this.viewed = obj && obj.viewed;
        this.enrolled = obj && obj.enrolled;
        this.favorite = obj && obj.favorite;
        this.date = obj && obj.date;
        this.others = obj && obj.others;
        this.free = obj && obj.free;
        this.sponsored = obj && obj.sponsored;
        this.certificate = obj && obj.certificate;
        this.type = obj && obj.type;
        this.duration = obj && obj.duration;
        this.thumb = obj && obj.thumb;
        this.avatar = obj && obj.avatar;
        this.thumb_squared = obj && obj.thumb_squared;
        this.header = obj && obj.header;
        this.author_links = obj && obj.author_links;
        this.mediaTitles = obj && obj.mediaTitles;
        this.sponsor = obj && obj.sponsor;
        this.comments = obj && obj.comments;
        this.intro_html = obj && obj.intro_html;
        this.rating = obj && obj.rating;
        this.keywords = obj && obj.keywords;
        this.currentRate = obj && obj.currentRate;
        this.comment = obj && obj.comment;
        this.lecture = obj && obj.lecture;
        this.translations = obj && obj.translations;
        this.captation_date = obj && obj.captation_date;
        this.languages1 = obj && obj.languages ? obj.languages : [];
        this.languages = [];
        const arrayLength = this.languages1.length;
        for (let i = 0; i < arrayLength; i++) {
            if (this.languages1[i]) {
                this.languages.push(this.languages1[i])
            }
        }
        if (obj && obj.speakers) {
            this.speakers = [];
            if (obj.speakers.constructor === Array) {
                obj.speakers.forEach(element => {
                    this.speakers.push(new User(element));
                });
            } else {
                Object.keys(obj.speakers).map(objectKey => {
                    this.speakers.push(new User(obj.speakers[objectKey]));
                });
            }
            this.speakers.sort((a, b) => (a.last_name > b.last_name) ? 1 : ((b.last_name > a.last_name) ? -1 : 0));
        }
        this.cdn_file = obj && obj.cdn_file;
        this.cdn_file_short = obj && obj.cdn_file_short;
        this.cdn_dash_file = obj && obj.cdn_dash_file;
        this.picture_url = obj && obj.picture_url;
        this.publication_date = obj && obj.publication_date;
        this.lecture = obj && obj.lecture && !obj.objectID ? new Lecture(obj.lecture) : new Lecture(obj);
    }
}

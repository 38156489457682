<div class="page webinar" [style.background]="isAop ? '#0f2a48' : null">

  <app-section-top [title]="getTitlePage()" [subtitle]="'webinar.Previous_webinars_subtitle'|translate"
                   [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-webinar.jpg'"></app-section-top>
  <div class="content webinar-content">
    <div class="tabset1">
      <ul class="nav nav-tabs justify-content-start">
        <li class="nav-item">
          <a class="nav-link" routerLink="/webinars" routerLinkActive="active"
             [style.background]="isAop ? '#19487c' : '#250055'">
            {{ upcomingWebinars.length === 1 ?
            ('webinar.Upcoming_webinars'|translate).split('|')[0] :
            ('webinar.Upcoming_webinars'|translate).split('|')[1] }}
          </a>
        </li>
        <li class="nav-item"><a class="nav-link" routerLink="/webinars/on-demand"
                                [style.background]="isAop ? '#19487c' : '#250055'">{{'webinar.Webinars_on_demand'|translate}}</a>
        </li>
      </ul>
    </div>
    <app-loader [color]="'#ffffff'" *ngIf="loading"></app-loader>

    <ng-container *ngIf="!loading && (upcomingWebinars.length > 0 || upcomingFilterCanAccess || upcomingTheme)">
      <div class="webinars">
        <div class="row" style="margin-bottom:15px;">
          <div class="col-sm-12">
            <select class="filters-select" id="filter-theme-upco" [(ngModel)]="upcomingTheme" name="theme"
                    (change)="selectTheme($event)">
              <option value="">{{('academy.Filter_by_theme'|translate)}}</option>
              <option *ngFor="let theme of upcomingThemes" [value]="theme.id">
                {{translationService.getTranslatedAttribute('name',
                theme.translations, locale)}}
              </option>
            </select>
          </div>
        </div>

        <ng-container *ngFor="let upcomingWebinar of upcomingWebinars | slice:0:29; let i = index;">
          <ng-template #statsupcomingmodal let-c="close" let-d="dismiss">
            <div class="modal-body modal-no-title modal-wide" style="padding:0px;">
              <button type="button" class="close" aria-label="Close" (click)="d()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <div class="modal-content">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="webinars-item-title-modal">
                        {{translationService.getTranslatedAttribute('title',
                        upcomingWebinar.translations,
                        locale)}}<br>
                        {{translationService.getTranslatedAttribute('subtitle',
                        upcomingWebinar.translations,
                        locale)}}
                      </h2>
                    </div>
                    <div class="col-sm-12">
                      <h2 class="webinars-item-title-modal">
                        {{('webinar.Registrants_distribution'|translate)}}</h2>
                    </div>
                    <div class="col-sm-6">
                      <h3>Distribution by specialty</h3>
                      <google-chart id="pieChartSpecialtyUpcoRegis"
                                    style="width: 100%;" [data]="pieChartSpecialtyDataUpcoRegis"></google-chart>
                    </div>
                    <div class="col-sm-6">
                      <h3>Distribution by geographic area</h3>
                      <google-chart id="pieChartGeoUpcoRegis"
                                    style="width: 100%;" [data]="pieChartGeoDataUpcoRegis"></google-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <div class="webinars-item">
            <div class="row no-gutters">
              <div class="col-sm-4 webinar-thumbnail"
                   [style.background-image]="'url(' + environment.cdn_base_url + '/sessions/' + upcomingWebinar.id + '.jpg)'"
                   alt="">
                <a [routerLink]="['/webinar', upcomingWebinar.slug]">
                  <div class="filter">
                    <div class="tag"
                         *ngIf="upcomingWebinar.sponsored && upcomingWebinar.partners?.industry?.id">
                      {{'academy.Sponsored' | translate}}
                    </div>
                    <div class="content">
                      <div class="webinars-item-date"
                           *ngFor="let date of upcomingWebinar.dates">
                        <p *ngIf="date.date">{{ date.date }}</p>
                        <p>{{ date.time }}, {{'layout.timezone_time' | translate:{city: date.key} }} </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-sm-8" style="padding-left:15px;">

                <h2 class="webinars-item-title">
                  <a [routerLink]="['/webinar', upcomingWebinar.slug]">
                    {{translationService.getTranslatedAttribute('title', upcomingWebinar.translations, locale)}}
                    {{translationService.getTranslatedAttribute('subtitle', upcomingWebinar.translations, locale)
                  && translationService.getTranslatedAttribute('subtitle', upcomingWebinar.translations, locale) !== 'n/a'
                    ? ' - ' + translationService.getTranslatedAttribute('subtitle', upcomingWebinar.translations, locale) : ''}}
                  </a>
                  <!--                                        <div class="lecture-badges">-->
                  <!--                                          <span class="sponsored" *ngIf="upcomingWebinar.live_demonstration">{{'academy.Sponsored'|translate}}</span>-->
                  <!--                                        </div>-->
                </h2>

                <div style="display: inline-block">
                  <p class="webinars-item-theme upco-theme" *ngIf="upcomingWebinar.theme">
                    {{('academy.Theme'|translate)}}:
                    {{translationService.getTranslatedAttribute('name',
                    upcomingWebinar.theme.translations, locale)}}&ensp;-&nbsp;
                  </p>
                  <p class="webinars-item-theme upco-theme" *ngIf="upcomingWebinar.format">
                    {{('abstract.Format'|translate)}}:
                    {{translationService.getTranslatedAttribute('name',
                    upcomingWebinar.format.translations, locale) | titlecase}}</p>
                </div>
                <div *ngIf="(upcomingWebinar.sponsored || upcomingWebinar.granted)
                                           && upcomingWebinar.partners['industry']
                                           && upcomingWebinar.partners['industry']['company_profiles']
                                           && upcomingWebinar.partners['industry']['company_profiles'][0]">
                  <a class="link-dark"
                     [routerLink]="['/profile', upcomingWebinar.partners['industry']['company_profiles'][0]['slug']]"
                     target="_blank"><i
                    class="fa fa-rss" aria-hidden="true"></i>
                    {{'company_profile.Follow_company_name_news'|translate:
                      {company: upcomingWebinar.partners['industry']['company_name']}
                    }}
                  </a>
                </div>
                <div class="webinars-item-report" *ngIf="upcomingWebinar">
                  <div class="data-report"
                       *ngIf="upcomingWebinar.registrants > 2 && environment.domain === 'aesthetics'">
                    <p style="text-align: center;margin-bottom: 0;font-size: 14px">
                                                <span style="text-align: center; display: inline-block;" placement="top"
                                                      ngbTooltip="{{upcomingWebinar.registrants + ' ' + ('webinar.Registrants'|translate)}}"
                                                      class="fa fa-users" aria-hidden="true">
                                                </span>
                      {{ upcomingWebinar.registrants }}
                      {{('academy.Registrants'|translate)}}
                    </p>
                  </div>
                  <div class="data-report"
                       *ngIf="upcomingWebinar.registrants > 2 && environment.domain === 'ophthalmology' && i == 0">
                    <p style="text-align: center;margin-bottom: 0;font-size: 14px">
                                                <span style="text-align: center; display: inline-block;" placement="top"
                                                      ngbTooltip="{{upcomingWebinar.registrants + ' ' + ('webinar.Registrants'|translate)}}"
                                                      class="fa fa-users" aria-hidden="true">
                                                </span>
                      {{ upcomingWebinar.registrants }}
                      {{('academy.Registrants'|translate)}}
                    </p>
                  </div>
                  <div class="data-report"
                       *ngIf="environment.domain === 'aesthetics' || environment.domain === 'ophthalmology' && i == 0">
                    <p style="text-align: center;cursor: pointer;margin-bottom: 0;font-size: 14px"
                       (click)="openUpco(statsupcomingmodal, upcomingWebinar)">
                                                <span style="text-align: center; display: inline-block;" placement="top"
                                                      ngbTooltip="Stats" class="fa fa-chart-pie"
                                                      aria-hidden="true"></span>
                      <u> {{('academy.Statistics'|translate)}}</u>
                    </p>
                  </div>
                  <div class="data-report">
                    <a *ngIf="upcomingWebinar.has_program"
                       href="{{ environment.new_cdn_base_url +  '/sessions/pdf_details/en_' + upcomingWebinar.id + '.pdf' }}"
                       style="cursor:pointer;color:white"
                       target="_blank">
                      <p style="text-align: center;cursor: pointer;margin-bottom: 0;font-size: 14px">
                                                <span style="text-align: center; display: inline-block;" placement="top"
                                                      class="fa fa-download"
                                                      aria-hidden="true"></span>
                        {{ ('academy.PDF_program'|translate) }}
                      </p>
                    </a>
                    <p *ngIf="!upcomingWebinar.has_program"
                       style="text-align: center;cursor: pointer;margin-bottom: 0;font-size: 14px">
                                                <span style="text-align: center; display: inline-block;"
                                                      class="fa fa-download"
                                                      aria-hidden="true"></span>
                      {{ ('academy.PDF_program_soon_available'|translate) }}
                    </p>
                  </div>
                </div>
                <div class="container">
                  <div class="row">

                    <div class="col-sm-8 webinars-speakers">
                      <div class="webinars-speakers-item"
                           *ngFor="let user of upcomingWebinar.speakers | slice:0:7; let i=index">
                        <a [routerLink]="['/profile', user.slug]">
                          <div class="rounded-picture"
                               [style.background]="'url('+user.picture_url+')'"
                               [style.background-size]="'cover'"></div>
                        </a>
                      </div>
                    </div>

                    <div class="col-sm-4 webinars-button">
                      <a *ngIf="!upcomingWebinar.auth_registered && upcomingWebinar.webinar_launched != 1"
                         routerLink="/webinar/{{upcomingWebinar.slug}}/{{(user && user.register_webinar_token ? user.register_webinar_token : '')}}"
                         class="button button-blue">{{user && user.premium_membership
                        ? ('attend.Register'|translate) :
                        ('webinar.Previous_webinar_of_month_cta'|translate)}}</a>
                      <a *ngIf="upcomingWebinar.auth_registered && upcomingWebinar.webinar_launched != 1"
                         routerLink="/webinar/{{upcomingWebinar.slug}}"
                         class="button button-blue">
                        <span class="fa fa-check"></span>
                        {{('webinar.Registered_short'|translate)}}
                      </a>
                      <a *ngIf="upcomingWebinar.webinar_launched === 1"
                         routerLink="/webinar/{{upcomingWebinar.slug}}/{{(user && user.register_webinar_token ? user.register_webinar_token : '')}}"
                         class="button button-orange">
                        <span class="fa fa-video"></span>
                        Watch the live now!
                      </a>
                      <br>
                      <!-- <img src="{{ upcomingWebinar.partners.industry.logo_url }}"-->
                      <!-- alt=""-->
                      <!-- *ngIf="upcomingWebinar.partners && upcomingWebinar.partners.industry && upcomingWebinar.live_demonstration">-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="upcomingWebinars.length <= 0 && isEmpty">
      <div class="modal-body modal-no-title modal-wide">
        <div id="alert-danger" class="alert alert-danger">
            {{'webinar.No_upcoming_webinars'|translate}}

        </div>

      </div>


    </ng-container>
    <router-outlet></router-outlet>
  </div>
</div>


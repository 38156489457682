import {AuthService} from 'app/services/auth.service';
import {UserService} from '../../../services/user.service';
import {Specialty} from '../../../models/specialty.model';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import {Country} from '../../../models/country.model';
import {LocaleService} from '../../../services/locale.service';
import {SpecialtyService} from '../../../services/specialty.service';
import {CountryService} from '../../../services/country.service';
import {tap} from 'rxjs/operators';
import {CovidModalComponent} from '../../covid-modal/covid-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'sign-up-component',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [
    SpecialtyService,
    CountryService,
    UserService
  ]
})

export class SignUpComponent implements OnInit {
  @Input() page: string = '';
  mask = ['\d+'];
  discountVisibility = false;
  password_confirmation: null;
  registrationError: string;
  specialities$: Observable<Specialty[]>;
  countries$: Observable<Country[]>;
  selectedCountry: Country;
  selectedSpecialty: Specialty;
  environment: any = environment;
  loading: boolean = false;
  locale: any;
  user = {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    country_id: null,
    user_specialty_id: null,
    subscribe_web: null,
    subscribe_academy_web: null,
    partners_optin: null,
    discountCode: null,
    password: null,
    password_confirmation: null,
    medical_resident: null
  };
  specialtyRequired: boolean = true;
  countryRequired: boolean = true;
  firstEnterSpecialty: boolean = true;
  firstEnterCountry: boolean = true;
  firstNameInvalid: boolean = false;
  lastNameInvalid: boolean = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private localeService: LocaleService,
    private specialtyService: SpecialtyService,
    private countryService: CountryService,
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    this.getCountries();
    this.getSpecialties();
  }

  getSpecialties() {
    this.specialities$ = this.specialtyService.getSpecialties();
    this.selectedSpecialty = null;
  }

  getCountries() {
    this.countries$ = this.countryService.getCountries();
    this.selectedCountry = null;
  }

  specialtyChanged(value) {
    this.specialtyRequired = value === null || value === 'null';
    this.selectedSpecialty = value === 'null' ? null : value;
  }

  countryChanged(value) {
    this.countryRequired = value === null || value === 'null';
    this.selectedCountry = value === 'null' ? null : value;
  }

  signUp() {
    this.loading = true;
    if (this.selectedCountry && this.selectedSpecialty) {
      this.user.country_id = this.selectedCountry.id;
      this.user.user_specialty_id = this.selectedSpecialty.id;
    }

    const loginData = {
      username: this.user.email,
      password: this.user.password,
      platform: ''
    };

    this.user.subscribe_web = this.user.subscribe_web ? 1 : 0;
    this.user.subscribe_academy_web = this.user.subscribe_web ? 1 : 0;
    this.user.partners_optin = this.user.partners_optin ? 1 : 0;
    if (this.user.discountCode === 'null' || !this.user.discountCode) {
      this.user.discountCode = '';
    }
    if (!this.lastNameInvalid && !this.firstNameInvalid) {
      this.userService.registerUser(this.user).subscribe(
        userRegistered => {
          this.authService.loginMainWebsite({email: loginData.username, password: loginData.password}).subscribe(() => {
          }, err => {
          });
          this.authService.login(loginData).subscribe(
            logged => {
              this.loading = false;
              if (logged.body.oneMonthFree) {
                this.modalService.open(CovidModalComponent);
              }
              this.authService.setSession(logged);
              this.router.navigate(['/account/create/step-2'])
            },
            logErr => {
              this.registrationError = logErr;
            });
        },
        regError => {
          this.registrationError = '<ul>';
          if (regError.error) {
            for (const ruleKey in regError.error.rules) {
              if (ruleKey === 'email') {
                this.registrationError += '<li>' + this.translateService.instant('academy.Email_taken_sign_up', {
                  link: environment.base_url + '/academy/account/login'
                }) + '</li>';

              } else if (regError.error.rules.hasOwnProperty(ruleKey)) {

                regError.error.rules[ruleKey].forEach((element) => {
                  this.registrationError += '<li>' + element + '</li>';
                });
              }
            }
          }
          this.registrationError += '</ul>';
          this.loading = false;
        }
      );
    }
  }

  checkRequiredField(requirement: string) {
    if (requirement === 'speciality') {
      this.firstEnterSpecialty = false;
      this.specialtyRequired = this.selectedSpecialty === null  || this.selectedSpecialty === undefined;
    } else {
      this.firstEnterCountry = false;
      this.countryRequired = this.selectedCountry === null || this.selectedCountry === undefined;
    }
  }

  checkFirstName(firstName) {
    this.firstNameInvalid = this.userService.checkLatinInput(firstName);
  }

  checkLastName(lastName){
    this.lastNameInvalid = this.userService.checkLatinInput(lastName);
  }
}

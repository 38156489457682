import {environment} from './../../environments/environment';
import {Injectable} from '@angular/core';
import {WebinarService} from 'app/services/webinar.service';
import {AuthService} from 'app/services/auth.service';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs/index';

declare const Pusher: any;

export interface PusherComment {
    type: string;
    id: number;
    comment: string;
    user: string;
    user_id: number;
    slug: string;
    country: string;
    specialty: string;
    is_question_live: boolean;
    pin: number;
}

export interface PusherMsg {
    message: string;
    user_id: number;
    created_at: string;
}

export interface PusherStatus {
    status: string;
}

export interface PusherPopup {
  launch: string;
}

@Injectable()
export class PusherService {
    pusher: any;
    commentsStream$ = new ReplaySubject<PusherComment>();
    msgsStream$ = new Subject<PusherMsg>();
    statusStream$ = new BehaviorSubject<PusherStatus>({status: ''});
    popupStream$ = new BehaviorSubject<PusherPopup>({launch: ''});

    constructor(private webinarService: WebinarService, private authService: AuthService) {
        if (typeof Pusher !== 'undefined') {
            Pusher.logToConsole = false;
            this.pusher = new Pusher(environment.pusher.key, {
                authEndpoint: 'https://api.imcas.com/broadcasting/auth',
                auth: {
                    headers: {
                        'Authorization': `Bearer ${authService.getToken()}`
                    }
                },
                cluster: 'mt1'
            });
        } else {
            this.pusher = false;
        }

    }

    initializePusher(channels: string[]): void {
        if (this.pusher) {
            for (let i = 0; i < channels.length; i++) {

                this.pusher.subscribe(channels[i])

                    .bind('pusher:subscription_succeeded', function () {

                    })

                    .bind('pusher:subscription_error', function () {

                    })

                    .bind('status', (status) => {
                        this.statusStream$.next(status);
                    })

                    .bind('new-comment', (comment) => {
                        comment.type = 'new';
                        this.commentsStream$.next(comment);
                    })

                    .bind('edit-comment', (comment) => {
                        comment.type = 'edit';
                        this.commentsStream$.next(comment);
                    })

                    .bind('delete-comment', (comment) => {
                        comment.type = 'delete';
                        this.commentsStream$.next(comment);
                    })

                    .bind('new-message', (msg) => {
                        this.msgsStream$.next(msg);
                    })

                    .bind('popup', (launch) => {
                        this.popupStream$.next(launch);
                    });
            }
        }

    }

    sendComment(webinarId: number, commentToSend: string) {
        this.webinarService.addComment(webinarId, commentToSend).subscribe();
    }

    sendQuestion(webinarId: number, questionToSend: string) {
        this.webinarService.addQuestion(webinarId, questionToSend).subscribe();
    }
}

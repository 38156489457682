<div class="page container">
    <p>
        {{ 'academy.Partner_offer_page_para1'|translate }}
    </p>
    <p>
        <b>{{ 'academy.Partner_offer_page_catch'|translate }}</b>
    </p>
    <p>
        {{ 'academy.Partner_offer_page_para2'|translate }}
    </p>
    <p>
        {{ 'academy.Partner_offer_page_para3'|translate }}
    </p>
    <ul>
        <li>{{ 'academy.Partner_offer_page_li1'|translate }}</li>
        <li>{{ 'academy.Partner_offer_page_li2'|translate }}</li>
        <li>{{ 'academy.Partner_offer_page_li3'|translate }}</li>
        <li>{{ 'academy.Partner_offer_page_li4'|translate }}</li>
    </ul>
    <p>
        {{ 'academy.Partner_offer_page_para4'|translate }}
    </p>

    <div class="text-center">
        <div *ngIf="showWarning" class="alert alert-danger" role="alert">
            {{ 'academy.Partner_offer_page_alert'|translate }}
        </div>
        <div *ngIf="showSuccess" class="alert alert-success" role="alert">
            {{ 'academy.Partner_offer_page_success'|translate }}
        </div>
        <div class="custom-control custom-checkbox">
            <input type="checkbox" id="accept-rules" name="accept-rules" class="custom-control-input" [(ngModel)]="acceptRule">
            <label class="custom-control-label" for="accept-rules">{{ 'academy.Partner_offer_page_label'|translate }}</label>
        </div>
        <button (click)="submit()" class="button button-orange">{{ 'academy.Partner_offer_page_button'|translate }}</button>
    </div>
    <p>
        {{ 'academy.Partner_offer_page_para4'|translate }}
    </p>
    <p>
        {{ 'academy.Partner_offer_page_para5'|translate }}
    </p>
    <p [innerHTML]="'academy.Partner_offer_page_para6'|translate">
    </p>
</div>

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'countryFlag'})
export class CountryFlag implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    if (value) {


      if (value === 'united states') {
        return 'usa';
      }

      if (value === 'brasil') {
        return 'brazil';
      }

      if (value === 'uk' || value === 'united kingdom') {
        return 'kingdom';
      }

      return value;
    }
  }
}

<ul class="social-list" [ngClass]="{'social-items-visible': showSocialItems}">
  <li class="social-item" *ngIf="favoriteItem">
    <a class="button"
       (click)="addToFavorite.emit(element);">
      <i class="fa-heart"
         [ngClass]="favorite ? 'fa': 'far'"></i>
    </a>
    <p class="label">{{ 'academy.Favorite'|translate }}</p>
  </li>
  <li class="social-item" *ngIf="element">
    <a target="_blank"
       [href]="twitterHref"
       class="button">
      <i class="fab fa-twitter"></i>
    </a>
    <p class="label">Twitter</p>
  </li>
  <li class="social-item" *ngIf="element">
    <a target="_blank"
       [href]="facebookHref"
       class="button">
      <i class="fab fa-facebook"></i>
    </a>
    <p class="label">Facebook</p>
  </li>
  <li class="social-item" *ngIf="element">
    <a target="_blank"
       [href]="linkedinHref"
       class="button">
      <i class="fab fa-linkedin"></i>
    </a>
    <p class="label">LinkedIn</p>
  </li>
  <li class="social-item" *ngIf="element">
    <a [href]="mailHref"
       class="button">
      <i class="fa fa-envelope"></i>
    </a>
    <p class="label">{{ 'account.Email'|translate }}</p>
  </li>
  <li class="social-item"
      *ngIf="certificateItem">
    <a (click)="openCertificate.emit(true)"
       class="button">
      <i class="fa fa-certificate"></i>
    </a>
    <p class="label">{{'account.Certificate'|translate}}</p>
  </li>
  <li class="social-item" *ngIf="element" (click)="showSocialItems = !showSocialItems">
                  <span class="button">
                    <i class="fa fa-share"></i>
                  </span>
    <p class="label">{{'academy.Share'|translate}}</p>
  </li>
</ul>
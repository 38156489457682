<div class="page home">

  <app-scroll-top></app-scroll-top>
  <section id="download-app" class="section dashboard-mobile-app">
    <div class="content" *ngIf="environment.domain == 'aesthetics'">
      <h2>{{ 'academy.Download_app'|translate }}</h2>
      <div class="row">
        <div class="col-md-6 text-right desktop-only">
          <img src="{{ environment.cdn_base_url }}/assets-front/img/app_mockeup_iphone.png" class="mockup" alt="mockup-iphone">
        </div>
        <div class="col-md-6 text-center download-buttons">
          <div class="buttons">
            <a href="https://itunes.apple.com/us/app/imcas-academy/id1410088543?l=fr&ls=1&mt=8"  target="_blank" *ngIf="getMobileOperatingSystem() == 'iOS' || isDesktop()">
              <img src="{{ environment.cdn_base_url }}/assets-front/img/download_app_store.png" alt="download app on app store">
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.imcas.academy" target="_blank" *ngIf="getMobileOperatingSystem() == 'Android' || isDesktop()">
              <img src="{{ environment.cdn_base_url }}/assets-front/img/download_google_play.png" alt="download app on google play">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="content" *ngIf="environment.domain == 'ophthalmology'">
      <h2>{{ 'academy.Download_app'|translate }}</h2>
      <div class="row">
        <div class="col-md-6 text-right desktop-only">
          <img src="{{ environment.cdn_base_url }}/assets-front/img/app_mockeup_iphone_aop.png" class="mockup" alt="mockup-iphone">
        </div>
        <div class="col-md-6 text-center download-buttons">
          <div class="buttons">
            <a href="https://apps.apple.com/app/aop-academy/id1438836126"  target="_blank" *ngIf="getMobileOperatingSystem() == 'iOS' || isDesktop()">
              <img src="{{ environment.cdn_base_url }}/assets-front/img/download_app_store.png" alt="download app on app store">
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.aop.academy" target="_blank" *ngIf="getMobileOperatingSystem() == 'Android' || isDesktop()">
              <img src="{{ environment.cdn_base_url }}/assets-front/img/download_google_play.png" alt="download app on google play">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

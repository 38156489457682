import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss'],
})
export class LikeButtonComponent implements OnInit {
  @Input() likeableId: number = 0;
  @Input() likeableType: string = 'comment';
  @Input() currentUser: any;
  likeable: any;
  likesCount: number = 0;
  displayAsLike: boolean = true;
  loading: boolean = false;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.getLikeable().subscribe(likeable => {
      this.likeable = likeable;
      this.likesCount = this.likeable.likes ? this.likeable.likes.length : 0;
    });

    this.displayAsLike = !this.hasLiked();
  }

  like() {
    if (!this.displayAsLike) {
      return false;
    } else {
      this.loading = true;

      const fd = this.getFormData();

      this.sendLikeRequest(fd).subscribe(likeable => {
        this.displayAsLike = false;
        this.loading = false;

        this.likeable = likeable;
        this.likesCount = this.likeable.likes ? this.likeable.likes.length : 0;
      });
    }
  }

  unlike() {
    if (this.displayAsLike) {
      return false;
    } else {
      this.loading = true;

      const fd = this.getFormData();

      this.sendUnlikeRequest(fd).subscribe(likeable => {
        this.displayAsLike = true;
        this.loading = false;

        this.likeable = likeable;
        this.likesCount = this.likeable.likes ? this.likeable.likes.length : 0;
      });
    }
  }

  hasLiked() {
    return !!(this.currentUser.likes && this.currentUser.likes.find(like => like.likeable_id === this.likeableId));
  }

  sendLikeRequest(fd): Observable<any> {
    return this.httpClient.post<any>(environment.apiURL + '/like', fd, {headers: {multipart: 'yes'}}).pipe(shareReplay());
  }

  sendUnlikeRequest(fd): Observable<any> {
    return this.httpClient.post<any>(environment.apiURL + '/unlike', fd, {headers: {multipart: 'yes'}}).pipe(shareReplay());
  }

  getLikeable(): Observable<any> {
    if (this.likeableType === 'comment') {
      return this.httpClient.get<any>(environment.apiURL + '/comments/' + this.likeableId).pipe(shareReplay());
    }
  }

  getFormData() {
    const fd = new FormData();
    fd.append('likeable_id', '' + this.likeableId);
    fd.append('likeable_type', '' + this.likeableType);

    return fd;
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocaleService} from '../../../services/locale.service';
import {User} from '../../../models/user.model';
import {AuthService} from '../../../services/auth.service';


@Component({
  selector: 'app-sign-up-step2',
  templateUrl: './sign-up-step2.component.html',
  styleUrls: ['./sign-up-step2.component.scss']
})

export class SignUpStep2Component implements OnInit {

  locale: string;
  user: User;
  certificateStatus: string = 'not_needed';
  passCertificatesForced: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localeService: LocaleService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    this.user = this.authService.getCurrentUser();
    this.checkCertificateStatus();
  }

  checkCertificateStatus() {
    if (this.user.specialty_category === 'non physician') {
      this.certificateStatus = 'not_needed'
    } else if (!this.user.diploma_status) {
      this.certificateStatus = 'not_needed'
    } else if (this.user.diploma_status.includes('not_verified')) {
      this.certificateStatus = 'not_verified'
    } else if (this.user.diploma_status.includes('under_process')) {
      this.certificateStatus = 'under_process'
    } else if (this.user.diploma_status.includes('verified')) {
      this.certificateStatus = 'verified'
    } else {
      this.certificateStatus = 'not_needed'
    }
  }

  updatedUser(event) {
    if (event) {
      const url = this.route.snapshot.paramMap.get('redirection') ? this.route.snapshot.paramMap.get('redirection') : '/account/create/thanks';
      this.router.navigateByUrl(url);
    }
  }

  updateCertificateStatus(status) {
    this.certificateStatus = status;
  }

  forcePassCertificates() {
    this.passCertificatesForced = true;
  }

}

<app-mobile-sign-up *ngIf="environment.mobile"></app-mobile-sign-up>
<div *ngIf="!environment.mobile" class="page login">
    <section class="section section-login">
        <div class="content">
            <form class="form form-login" #signUpForm="ngForm" (ngSubmit)="signUp()">
                <h1 *ngIf="page ==='webinar'">{{ 'webinar.Create_your_free_account'|translate }}</h1>
                <h4 *ngIf="page ==='webinar'"><a routerLink="/account/login">{{
                    'academy.If_you_have_account_login'|translate }}</a></h4>
                <h1 *ngIf="page !=='webinar'">{{ 'account.Create_title'|translate }}</h1>
                <p class="form-login-warning" *ngIf="page !=='webinar'">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    {{'account.Create_message'|translate}}
                </p>
                <p *ngIf="page !=='webinar'" class="form-login-small" style="margin-bottom:0"
                   [innerHTML]="'account.Create_message3'|translate:{link:('<a href=/contact>'+('company_profile.Contact_us'|translate)+'</a>')}">
                </p>
              <p class="form-login-small">
                {{'account.Already_have_account_login'|translate}} : <a href='/account/login'>{{ 'layout.Sign_in'|translate }}</a>
              </p>

                <div class="row">
                    <div class="col col-50">
                        <div class="">
                            <label for="first_name" class="form-label form-label-text">
                                {{'account.First_name'|translate}}
                            </label>
                            <input id="first_name" class="textinput form-control" name="first_name"
                                   [(ngModel)]="user.first_name" #first_name="ngModel" (ngModelChange)="checkFirstName(user.first_name)"
                                   required/>
                            <div *ngIf="(first_name.invalid && (first_name.dirty || first_name.touched)) || firstNameInvalid"
                                 class="form-login-error">
                                <div *ngIf="firstNameInvalid">
                                  Firstname must be contains only latin letters <!--COMING SOON: account.No_latin_firstname-->
                                </div>
                                <div *ngIf="!firstNameInvalid && first_name.errors.required">
                                    {{'company_account_badges.First_name_is_required'|translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-50">
                        <div class="">
                            <label for="last_name" class="form-label form-label-text">
                                {{'account.Last_name'|translate}}
                            </label>
                            <input id="last_name" class="textinput form-control" required name="last_name"
                                   [(ngModel)]="user.last_name" #last_name="ngModel" (ngModelChange)="checkLastName(user.last_name)"
                            />
                            <div *ngIf="(last_name.invalid && (last_name.dirty || last_name.touched)) || lastNameInvalid"
                                 class="form-login-error">
                              <div *ngIf="lastNameInvalid">
                                Lastname must be contains only latin letters <!--COMING SOON: account.No_latin_lastname-->
                              </div>
                                <div *ngIf="!lastNameInvalid && last_name.errors!.required">
                                    {{'company_account_badges.Last_name_is_required'|translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <label class="form-label form-label-text">
                        {{'account.Specialty'|translate}}
                    </label>
                    <div class="select-wrap select-wrap-small" *ngIf="specialities$ | async as specialities">
                        <dropdown-select-component [options]="specialities" [id]="'speciality'" [name]="'speciality'"
                                                   (optionChange)="specialtyChanged($event)"
                                                   (click)="checkRequiredField('speciality')"
                                                   [placeholder]="('account.Choose_specialty'|translate)"
                                                   [addEmpty]="true" [selectedOption]="selectedSpecialty">
                        </dropdown-select-component>
                    </div>
                  <div *ngIf="specialtyRequired && !firstEnterSpecialty" class="form-login-error">
                    <div>
                      Specialty is required<!--COMING SOON: account.Specialty_required-->
                    </div>
                  </div>
                </div>

              <div class="form-row">
                <label for="resident" class="form-label form-label-text" style="margin: 0 ;margin-right: 0.5em;">
                  {{'account.I_am_medical_resident'|translate}}
                </label>
                <input type="checkbox" id="resident" name="resident" [(ngModel)]="user.medical_resident" #medical_resident="ngModel"/>
              </div>


                <div class="form-row">
                    <label class="form-label form-label-text">
                        {{'account.Country'|translate}}
                    </label>
                    <div class="select-wrap select-wrap-small" *ngIf="countries$ | async as countries">
                        <dropdown-select-component [options]="countries" [id]="'country'" [name]="'country'"
                                                   (optionChange)="countryChanged($event)"
                                                   (click)="checkRequiredField('country')"
                                                   [placeholder]="('account.Choose_country'|translate)"
                                                   [addEmpty]="true" [selectedOption]="selectedCountry">
                        </dropdown-select-component>
                    </div>
                  <div *ngIf="countryRequired && !firstEnterCountry" class="form-login-error">
                    <div>
                      Country is required<!--COMING SOON: account.Specialty_required-->
                    </div>
                  </div>
                </div>
                <div class="form-row">
                    <label for="phone" class="form-label form-label-text">
                        {{'account.Phone_number'|translate}}
                    </label>
                    <input type="tel" id="phone" class="textinput form-control" required minlength="3"
                           name="phone" [(ngModel)]="user.phone" #phone="ngModel"/>
                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="form-login-error">
                        <div *ngIf="phone.errors.required"
                             [innerHtml]="'validation.phone'|translate:{attribute:('account.Phone_number'|translate)}">
                        </div>
                        <div *ngIf="phone.errors.minlength"
                             [innerHtml]="'validation.phone'|translate:{attribute:('account.Phone_number'|translate)}">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <label for="email" class="form-label form-label-text">
                        {{'account.Email_address'|translate}}
                    </label>
                    <input type="email" id="email" class="textinput form-control" name="email" required email
                           [(ngModel)]="user.email" #email="ngModel">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-login-error">
                        <div *ngIf="email.errors.required">
                            {{'aop_layout.Launching_email_error_flash'|translate}}
                        </div>
                        <div *ngIf="email.errors.email && !email.errors.required">
                            {{'aop_layout.Launching_email_error_flash'|translate}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-50">
                        <div class="form-row">
                            <label for="password" class="form-label form-label-text">
                                {{'account.Password'|translate}}
                            </label>
                            <input type="password" name="password" id="password" class="textinput form-control"
                                   [(ngModel)]="user.password" required validateEqual="password_confirmation_input"
                                   reverse="true" #password="ngModel">
                            <div class="form-group-error"
                                 [hidden]="password.valid || (password.pristine && !signUpForm.submitted)">
                                <p>Password is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="col col-50">
                        <div class="form-row">
                            <label for="password_confirmation" class="form-label form-label-text">
                                {{'account.Password_confirmation'|translate}}
                            </label>
                            <input type="password" class="form-control" id="password_confirmation" name="password_confirmation_input"
                                   [(ngModel)]="password_confirmation"
                                   required validateEqual="password" reverse="false"
                                   #password_confirmation_input="ngModel">
                            <div class="form-group-error"
                                 [hidden]="password_confirmation_input.valid || (password_confirmation_input.pristine && !signUpForm.submitted)">
                                <p>Password mismatch</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row form-promo">
                    <a (click)="discountVisibility = !discountVisibility">
                        {{'account.Promotional_code'|translate}}
                    </a>
                    <div class="form-login-discount" [ngClass]="{'form-login-discount-visibility': discountVisibility}">
                        <label for="discountCode" class="form-label form-label-text">
                            {{'cart.Promotional_code'|translate}}
                        </label>
                        <input id="discountCode" name="discountCode" class="textinput form-control"
                               [(ngModel)]="user.discountCode" #discountCode="ngModel"/>
                    </div>
                </div>
                <div class="form-row form-checkbox">
                    <label>
                        <input type="checkbox" class="form-checkbox  form-control" name="subscribe_web"
                               [(ngModel)]="user.subscribe_web" #subscribe_web="ngModel">
                        <span>
              {{'account.Subscribe_label'|translate}}
            </span>
                    </label>
                </div>
                <div class="form-row form-checkbox">
                    <label>
                        <input type="checkbox" class="form-checkbox  form-control" name="partners_optin"
                               [(ngModel)]="user.partners_optin"
                               #partners_optin="ngModel">
                        <span>
              {{'account.Partners_optin_label'|translate}}
            </span>
                    </label>
                </div>
                <div class="form-row form-button">
                    <button class="button button-block button-large button-orange"
                            [disabled]="!signUpForm.valid || loading || specialtyRequired || countryRequired || firstNameInvalid || lastNameInvalid">
                        <span *ngIf="!loading">{{page ==='webinar' ? 'Register to watch the webinar' : 'account.Create_button'|translate}}</span>
                        <span *ngIf="loading">{{ 'program.Loading'|translate }}...</span>
                    </button>
                </div>
                <div *ngIf="registrationError" [innerHTML]="registrationError"
                     class="form-login-error form-login-error-main"></div>
                <div *ngIf="page !=='webinar'" class="form-information">
                    <p [innerHTML]="'layout.Form_account_academy_creating_message'|translate:{
                      terms_of_use_link:('<a target=_blank href=/academy/terms-of-use>'+('layout.Terms_of_use'|translate)+'</a>'),
                      privacy_policy_link:('<a target=_blank href=/privacy-policy>'+('layout.Privacy_Policy'|translate)+'</a>'),
                      cookies_internet_link:('<a target=_blank href=/cookies-internet-advertising-policy>'+('layout.Cookies_Internet_Advertising_Policy'|translate)+'</a>') }"
                    ></p>
                    <p>{{'layout.Form_privacy_policy_2_message'|translate}}</p>
                </div>
                <p *ngIf="page ==='webinar'" [innerHTML]="'webinar.Contact_webinar_before_launch'|translate"></p>
            </form>
        </div>
    </section>
</div>

<ng-template #tooltip>
  {{tooltipMsg}}
  <i class="fa fa-check" aria-hidden="true"></i>
</ng-template>
<div class="badge-logo-wrapper"
     [style.border-width]="badge.isUltimate || (size / 2) < 1 ? '0' : size / 2 + 'px'"
     [style.padding]="badge.isUltimate || (size / 2) < 1 ? '0' : size / 2 + 'px'"
     [style.border-color]="badge.color"
     [style.width]="size + 'rem'"
     [style.height]="size + 'rem'"
     [style.font-size]="size + 'rem'"
>
  <div class="badge-logo" id="{{badge.id}}"
       [ngbTooltip]="tooltip"
       [style.background-color]="badge.isUltimate ? 'white' : badge.color"
       [style.border-radius]="badge.isUltimate ? '0' : '50%'"
       [style.background-image]="badge.isUltimate ? 'url(' + environment.cdn_base_url + '/assets-front/img/badge_background.svg)' : 'none'"
  >
    <div class="badge-icon">
      <i class="fa {{badge.image}}" aria-hidden="true" *ngIf="(badge.group === 'VideosWatched' && !badge.category) || badge.group !== 'VideosWatched'"></i>
      <span [ngClass]="'icon-theme icon-theme_' + badge.category.id" aria-hidden="true" *ngIf="badge.group === 'VideosWatched' && badge.category"></span>
    </div>
    <div class="title-wrapper">
      <div class="title-content">
        <div class="image">
          <img
            *ngIf="badge.title"
            [src]="environment.cdn_base_url + '/assets-front/img/badge_banner.svg'"
            alt="Badge title banner">
        </div>
        <div class="title" *ngIf="badge.title">
          {{ badge.title }}
        </div>
      </div>
    </div>
  </div>
</div>

import {Feedback} from './../models/feedback.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class FeedbackService {
    private endpoint: string = '/feedbacks';

    constructor(
        private httpClient: HttpClient
    ) { }

    getFeedback(id: number): Observable<Feedback> {
        return this.httpClient.get<Feedback>(environment.apiURL + this.endpoint + '/' + id).pipe(shareReplay());
    }

    getFeedbacks(otherParams?): Observable<Feedback[]> {
        let params = new HttpParams()
            .set('congress_id', environment.congress_id + '')
            .set('all', '1')
            .set('best_of_the_best', '1');
        for (const key in otherParams) {
            if (otherParams.hasOwnProperty(key)) {
                params = params.append(key, otherParams[key]);
            }
        }
        return this.httpClient.get<Feedback[]>(environment.apiURL + this.endpoint, {
            params: params
        }).pipe(shareReplay());
    }
}

<a [routerLink]="['/show/' + lecture.getId() + '/' + lecture.getSlug()]">
    <div *ngIf="!card">
        <div class="lecture-image" (click)="goToVideo(lecture)">
            <div class="lecture-play">
                <i class="fa"
                   [ngClass]="lecture.user_auth && lecture.user_auth[0] && lecture.user_auth[0].pivot.viewed===1 ? 'fa-check': 'fa-play-circle'"></i>
            </div>
            <div class="lecture-languages" *ngIf="lecture.media.languages.length > 0">
                <img *ngFor="let language of lecture.media.languages"
                     [src]="environment.cdn_base_url + '/assets-front/img/flags/' +  language + '.svg'"
                     class="flag"
                     alt="">
            </div>
            <img [src]="lecture.getThumb('squared')">
            <div class="lecture-mask"></div>
        </div>
        <div class="lecture-content">
            <h3 (click)="goToVideo(lecture)">{{lecture.media ?
                translationService.getTranslatedAttribute('title',
                lecture.media.translations, locale) : ''}}</h3>
            <div class="author" *ngIf="lecture.media && lecture.media.speakers.length === 1">
                <h6>{{lecture.media.speakers[0].title + ' ' + lecture.media.speakers[0].first_name + ' ' +
                    lecture.media.speakers[0].last_name}}
                </h6>
            </div>
            <span>{{lecture.media && lecture.media.duration ? lecture.media.duration : ''}}</span>
        </div>
    </div>
</a>

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {Congress} from './../models/congress.model';
import {Partnership} from './../models/partnership.model';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class CongressService {
    private endpoint: string = '/congresses';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getCongress(id: number): Observable<Congress> {
        return this.httpClient.get<Congress>(environment.apiURL + this.endpoint + '/' + id).pipe(shareReplay());
    }

    getPartnerships(): Observable<Partnership[]> {
        return this.httpClient.get<Partnership[]>(
            environment.apiURL
            + '/partnerships?congress_id='
            + environment.congress_id, {
                params: new HttpParams().set('all', '1').set('type', 'all')
            }).pipe(shareReplay());
    }

    searchCongresses(params?: any): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<any>(environment.apiURL + '/congresses', reqOpts).pipe(shareReplay());
    }

    getCongressProducts(params?: any): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        if (environment.domain === 'aesthetics') {
            reqOpts.params = reqOpts.params
                .set('congress_id', '68')
                .set('duration', '365')
                .set('product_family_id', '207')
        } else {
            reqOpts.params = reqOpts.params
                .set('congress_id', '84')
                .set('duration', '365')
                .set('product_family_id', '207')
        }

        return this.httpClient.get<any>(
            environment.apiURL + '/congress_products', reqOpts)
            .pipe(shareReplay());
    }

    getActivesCongresses(lang){
      const header = new HttpHeaders({
        'x-imcas-language': lang
      });
/*      const reqOpts = {
        params: new HttpParams()
      };
      reqOpts.params = reqOpts.params.set('brand', brand);*/
      return this.httpClient.get<any>(environment.apiURLNew + 'congress/get/actives',{
        headers: header
      }).pipe(shareReplay());
    }
}

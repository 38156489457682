import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Capacitor} from "@capacitor/core";

@Injectable()
export class IsCapacitorService {
  constructor() {
  }

  isAppIos() {
    if (this.isAppCapacitor() && Capacitor.getPlatform() === 'ios') {
      return true;
    } else {
      return false;
    }
  }


  isAppAndroid() {
    if (this.isAppCapacitor() && Capacitor.getPlatform() === 'android') {
      return true;
    } else {
      return false;
    }
  }

  isAppCapacitor() {
    if (environment.mobile) {
      return true;
    } else {
      return false;
    }
  }

  isPaymentAvailable() {
    // Temp disabled
    return false;
  }
}

import {Component, OnInit} from '@angular/core';
import {Feedback} from '../../../models/feedback.model';
import {FeedbackService} from '../../../services/feedback.service';
import {TranslationService} from '../../../services/translation.service';
import {LocaleService} from '../../../services/locale.service';


@Component({
    selector: 'app-feedbacks',
    templateUrl: './feedbacks.component.html',
    styleUrls: ['./feedbacks.component.scss'],
    providers: [FeedbackService]
})
export class FeedbacksComponent implements OnInit {

    mainFeedbacks: Feedback[] = [];
    feedbacks: Feedback[] = [];
    locale: string;
    loading: boolean = true;

    constructor(
        private feedbackService: FeedbackService,
        public translationService: TranslationService,
        private localeService: LocaleService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();

        this.feedbackService.getFeedbacks().subscribe(feedbacks => {
            if (feedbacks.length !== 0) {
                Object.keys(feedbacks).map((objectKey, index) => {
                    if (this.mainFeedbacks.length < 3 && feedbacks[objectKey].sorting_order === 1) {
                        this.mainFeedbacks.push(new Feedback(feedbacks[objectKey]));
                    } else {
                        this.feedbacks.push(new Feedback(feedbacks[objectKey]));
                    }
                });
                this.loading = false;
            }
        });
    }

}

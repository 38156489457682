import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Cart} from '../../../models/cart.model';
import {TranslationService} from '../../../services/translation.service';
import {ConfirmationComponent} from '../../modal/confirmation/confirmation.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AcademySubscriptionService} from '../../../services/academy-subscription.service';
import {User} from '../../../models/user.model';

@Component({
  selector: 'app-academy-subscriptions-recap',
  templateUrl: './academy-subscriptions-recap.component.html',
  styleUrls: ['./academy-subscriptions-recap.component.scss'],
  providers: [TranslationService, AcademySubscriptionService]
})
export class AcademySubscriptionsRecapComponent implements OnInit {
  @Input() cart: Cart;
  @Input() locale: string;
  @Input() user: User;
  @Input() readOnly: boolean;
  @Input() withDeliveryFees: boolean;
  @Input() withDeliveryFeesValidated: boolean;
  @Input() giftProduct: boolean = false;
  currency: string;
  @Output() newCart: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(public translationService: TranslationService,
              private modalService: NgbModal,
              private academySubscriptionService: AcademySubscriptionService) {
  }

  ngOnInit() {
  }

  updateQuantity(academySub, type) {
    let quantity = academySub.quantity;
    if (type === '+') {
      quantity += 1;
    } else {
      quantity -= 1;
    }
    if (quantity === 0) {
      this.removeAcademySubscription(academySub.id);
    } else {
      this.academySubscriptionService.update(academySub.id, {
        quantity: quantity
      }).subscribe(cart => {
        this.newCart.emit(true);
      });
    }

  }

  getPrice(prices, key) {
    let price = '';
    if (this.user.country_id === 78) {
      this.currency = 'eur';
      prices.forEach(element => {
        if (element.currency === 'eur') {
          price = element[key] + ' €';
        }
      });
    } else {
      this.currency = 'usd';
      prices.forEach(element => {
        if (element.currency === 'usd') {
          price = ' $' + element[key];
        }
      });
    }
    if (!price && prices[0]) {
      if (prices[0].currency === 'eur') {
        this.currency = 'eur';
        price = prices[0][key] + ' €';
      } else if (prices[0].currency === 'usd') {
        this.currency = 'usd';
        price = ' $' + prices[0][key];
      }
    }
    return price;
  }

  getCartTotal() {
    if (this.currency === 'eur') {
      return this.cart.total[this.currency] + ' €';
    } else if (this.currency === 'usd') {
      return '$' + this.cart.total[this.currency];
    }
  }

  getCartDeliveryFees() {
    if (this.currency === 'eur') {
      return this.cart.delivery_fees_eur + ' €';
    } else if (this.currency === 'usd') {
      return '$' + this.cart.delivery_fees_usd;
    }
  }

  removeAcademySubscription(academySubscriptionId) {
    if (!this.readOnly) {
      const modalRef = this.modalService.open(ConfirmationComponent);
      modalRef.componentInstance.title = 'Do you want to delete this product from your cart?';
      modalRef.result.then(result => {
        this.academySubscriptionService.deleteSub(academySubscriptionId).subscribe(resultDelete => {
          this.newCart.emit(true);
        });
      }, result => {
      });
    }

  }
}

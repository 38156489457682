import {Venue} from './venue.model';

export class Congress {
    id: number;
    code: string;
    slug: string;
    datetime_start: string;
    datetime_end: string;
    venue: Venue;
    translations: any;

    constructor(obj?: any) {
        this.id = obj && obj.id;
        this.code = obj && obj.code;
        this.slug = obj && obj.slug;
        this.translations = obj && obj.translations;
        this.datetime_end = obj && obj.datetime_end;
        this.datetime_start = obj && obj.datetime_start;
        this.venue = obj && obj.venue ? new Venue(obj.venue) : null;
    }

    backgroundAccountBackUrl() {
        return 'url(https://comexposium-healthcare-assets.s3.eu-west-1.amazonaws.com/congresses/' + this.code + '/account_back.jpg)';
    }
}

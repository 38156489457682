<app-mobile-dashboard *ngIf="environment.mobile"></app-mobile-dashboard>
<div *ngIf="!environment.mobile" class="page dashboard">
  <section class="section section-filtered home-top dashboard-top" [ngClass]="isAop ? 'section-filtered-aop' : 'section-filtered-imcas'">
        <div class="content">
            <h1>{{realHeading}}</h1>
            <app-search-ui
                    [transparent]="true"
                    [usersIndex]="true"
                    [productsIndex]="true"
                    [topicsIndex]="true"
                    [companiesIndex]="true"
                    [pagesIndex]="true"
                    [congressesIndex]="true"
                    [coursesIndex]="true"
                    [mediasIndex]="true"
                    [articlesIndex]="true"
            ></app-search-ui>
        </div>
    </section>
    <app-magazine-section *ngIf="magazine && magazine.id" [magazine]="magazine"></app-magazine-section>
    <section class="dashboard-connected alert alert-success" *ngIf="connected">
        {{ 'academy.You_are_now_connected'|translate }}
    </section>
    <!-- courses -->
    <section class="section dashboard-courses">
        <div class="content">
            <h2><a [routerLink]="'/library/courses'">{{'academy.New_courses_selected_only_for_you'|translate}}<i
                    class="fal fa-chevron-right"></i></a></h2>
            <div class="slick-slider-previous-left slick-slider-arrow left-courses">
                <a class="icon-chevron-left" id="slick-slider-previous-courses"><i class="fal fa-chevron-left"></i></a>
            </div>
            <div class="row courses-carousel">
                <div class="col col-33 col-m-50 course" *ngFor="let course of courses">
                    <app-course-element [user]="user" [course]="course"></app-course-element>
                </div>
            </div>
            <div class="slick-slider-next-right slick-slider-arrow right-courses">
                <a class="icon-chevron-right" id="slick-slider-next-courses"><i class="fal fa-chevron-right"></i></a>
            </div>
        </div>
    </section>
    <!-- / courses -->
    <section id="download-app" class="section dashboard-mobile-app mobile-only" *ngIf="env.domain == 'aesthetics'">
        <div class="content">
            <div class="row">
              <div class="col-md-5 download-buttons">
                <h2 class="title_bicolor" [innerHTML]="'academy.Download_the_app'|translate"></h2>
                <p>{{'academy.Download_app_details'|translate}}</p>
                <div class="buttons">
                  <a href="https://itunes.apple.com/us/app/imcas-academy/id1410088543?l=fr&ls=1&mt=8"  target="_blank">
                    <img src="{{ environment.cdn_base_url }}/assets-front/img/download_app_store.png" alt="download app on app store">
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.imcas.academy" target="_blank">
                    <img src="{{ environment.cdn_base_url }}/assets-front/img/download_google_play.png" alt="download app on google play">
                  </a>
                </div>

              </div>
            </div>
        </div>
    </section>
    <section class="section dashboard-themes">
        <div class="content">
            <h2><a [routerLink]="'/library/courses'">{{ 'academy.Browse_through'|translate }}<i
                    class="fal fa-chevron-right"></i></a></h2>
            <div class="slick-slider-previous-left slick-slider-arrow left-themes">
                <a class="icon-chevron-left" id="slick-slider-previous-themes"><i class="fal fa-chevron-left"></i></a>
            </div>
            <div class="row themes-wrapper themes-carousel">
                <div class="col col-33 col-m-50" *ngFor="let theme of themes">
                    <a [style.background-image]="'url('+ theme.picture_url + ')'"
                       [routerLink]="theme.linkLibrary()"
                       class="home-theme-wrapper">
                        <div class="theme-mask"></div>

                        <span class="theme-name"
                                              [style.background-color]="theme.color_code"> {{ theme.getTranslatedName(locale)}}</span>
                    </a>
                </div>
            </div>
            <div class="slick-slider-next-right slick-slider-arrow right-themes">
                <a class="icon-chevron-right" id="slick-slider-next-themes"><i class="fal fa-chevron-right"></i></a>
            </div>
        </div>
    </section>

    <!-- lectures -->
    <section class="section dashboard-lectures">
        <div class="content">
            <h2><a [routerLink]="'/library/lectures'">{{'academy.Newest_lectures'|translate}}<i class="fal fa-chevron-right"></i></a></h2>
            <div class="search-results search-results-videos">
                <div class="row">
                    <div class="col col-33 col-m-50 desktop-only" *ngFor="let academyMedia of academyMedias">
                        <app-lecture [card]="true" [user]="user" [lecture]="academyMedia"
                                     [thumbFormat]="'rectangular'"></app-lecture>
                    </div>
                    <div class="col-md-12 mobile-only" *ngFor="let academyMedia of academyMedias">
                        <app-lecture [card]="false" [user]="user" [lecture]="academyMedia"
                                     [thumbFormat]="'squared'"></app-lecture>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / lectures -->
    <section class="section dashboard-not-premium" *ngIf="!user.premium_membership && env.domain == 'aesthetics'">
        <div class="content">
            <h2>{{ 'academy.Get_access_nb'|translate:{nb: mediasHits} }}</h2>
            <a [routerLink]="'/premium'" class="button button-large button-orange">{{ 'academy.Subscribe'|translate }}</a>
        </div>
    </section>
    <!-- webinars -->
    <section class="section dashboard-webinar" *ngIf="upcomingWebinars && upcomingWebinars.length > 0 && environment.domain === 'aesthetics'">
        <div class="content">
            <h2><a [routerLink]="'/webinars'">{{ 'webinar.Upcoming_live_web'|translate }}<i class="fal fa-chevron-right"></i></a></h2>
            <div class="row">
                <div class="col col-{{ upcomingWebinars.length > 1 ? '6' : '12' }} col-m-50"
                     *ngFor="let webinar of upcomingWebinars" (click)="goToWebinar(webinar)">
                    <div class="dashboard-webinar-item">
                        <div class="dashboard-webinar-image"
                             [style.background-image]="'url(' + webinar.picture_url + ')'"></div>
                        <div class="dashboard-webinar-month">{{ webinar.getMonth() }} {{
                            'sponsor.Family_Webinar'|translate }}
                        </div>
                        <div class="dashboard-webinar-title">
                            {{ webinar.getTitle(locale) }}
                        </div>
                        <div class="dashboard-webinar-details" [style.background]="isAop ? '#19487c' : null">
                            {{ webinar.getFullDateTime(locale) }}, LIVE Paris
                            <a *ngIf="!webinar.auth_registered"
                               routerLink="/webinar/{{webinar.slug}}/{{(user && user.register_webinar_token ? user.register_webinar_token : '')}}">{{user
                                && user.premium_membership ? ('attend.Register'|translate) :
                                ('webinar.Previous_webinar_of_month_cta'|translate)}}</a>
                            <a *ngIf="webinar.auth_registered" routerLink="/webinar/{{webinar.slug}}">{{('company_profile.Learn_more'|translate)}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /webinars -->

    <!-- IMCAS Alert -->
    <section class="section dashboard-alert" *ngIf="cases && cases.length > 0 && (user && user.specialty_category === 'others' || user.specialty_category === 'physician') && env.domain == 'aesthetics'">
        <div class="content">
            <h2><a [routerLink]="'/alert'">{{ 'alert.Main_subtitle' | translate }}<i class="fal fa-chevron-right"></i></a></h2>
            <p>{{ 'alert.Homepage_subtitle'|translate}}</p>
            <app-alert-last-cases [cases]="cases"></app-alert-last-cases>
            <div class="text-center">
                <a [routerLink]="'/alert'" class="button button-red">{{'alert.Homepage_cta'|translate }}</a>
            </div>
        </div>
    </section>
    <!-- /IMCAS Alert -->
    <section class="section dashboard-mobile-app desktop-only" *ngIf="env.domain == 'aesthetics'" [style.background]="env.domain != 'aesthetics' ? '#5585be' : null">
        <div class="content">
          <div class="row">
                <div class="col-md-7 text-right desktop-only">
                  <img src="{{ environment.cdn_base_url }}/assets-front/img/app_mockeup_iphone.png" class="mockup"
                       alt="mockup-iphone">
                </div>
                <div class="col-md-5 download-buttons">
                  <h2 class="title_bicolor" [innerHTML]="'academy.Download_the_app'|translate"></h2>
                  <p>{{'academy.Download_app_details'|translate}}</p>
                    <div class="buttons">
                        <a href="https://itunes.apple.com/us/app/imcas-academy/id1410088543?l=fr&ls=1&mt=8"  target="_blank">
                            <img src="{{ environment.cdn_base_url }}/assets-front/img/download_app_store.png" alt="download app on app store">
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.imcas.academy" target="_blank">
                            <img src="{{ environment.cdn_base_url }}/assets-front/img/download_google_play.png" alt="download app on google play">
                        </a>
                    </div>

                </div>
            </div>

        </div>
    </section>
    <!-- blog articles -->
    <section class="section dashboard-blog" *ngIf="env.domain == 'aesthetics'">
        <div class="content">
            <h2><a [routerLink]="'/blog'">{{ 'blog.Homepage_title'|translate }}<i class="fal fa-chevron-right"></i></a></h2>
            <div class="row">
                <div class="col col-50" *ngFor="let article of articles">
                    <app-blog-post-item [label]="true" [article]="article"></app-blog-post-item>
                </div>
            </div>
        </div>
    </section>
    <!-- /blog articles -->
</div>

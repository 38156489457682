import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../models/user.model';
import {DateMomentService} from '../../services/date-moment.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-section-top',
    templateUrl: './section-top.component.html',
    styleUrls: ['./section-top.component.scss']
})
export class SectionTopComponent implements OnInit {
    @Input() title: string;
    @Input() title2: string;
    @Input() subtitle: string;
    @Input() subtitle2: string;
    @Input() backgroundUrl: string;
    @Input() user: User;
    @Input() webinar: string;
    isAop: boolean = environment.domain === 'ophthalmology';

    constructor(public dateMomentService: DateMomentService) {
    }

    ngOnInit() {
    }

}

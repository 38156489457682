import {Component, Input, OnInit} from '@angular/core';
import {WebpageService} from '../../services/webpage.service';
import {TranslationService} from '../../services/translation.service';
import {LocaleService} from '../../services/locale.service';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-content-faq',
    templateUrl: './faq-content.component.html',
    styleUrls: ['./faq-content.component.scss'],
    providers: [WebpageService],
})
export class FaqContentComponent implements OnInit {
    groups: Array<string>;
    environment: any = environment;
    parts: Array<any>;
    locale: string;
    @Input() showSummary: boolean;
    model: any = '';
    fragment: string;
    questions: { rank: string, question: string, group: string, id: string }[] = [];
    search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.questions.filter(v => v.question.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
    formatter = (x: { question: string }) => x.question;


    constructor(
        private webpageService: WebpageService,
        public translationService: TranslationService,
        private route: ActivatedRoute,
        private router: Router,
        private localeService: LocaleService) {

    }


    encode(value) {
        return btoa(unescape(encodeURIComponent(value))).replace(/=/g, '').replace(/\//g, '');
    }

    splitRank(value) {
        const array = value.split('.', 2);
        return array[0] + '-' + array[1];
    }

    ngOnInit() {
        this.groups = [];
        this.locale = this.localeService.currentLocale();
        this.webpageService.getWebpage('academy_faq').subscribe(response => {
            this.parts = Object.keys(response.parts).map(personNamedIndex => {
                this.groups.push(personNamedIndex);
                return response.parts[personNamedIndex];
            });
            this.parts.forEach((values, index) => {
                values.forEach(element => {
                    const question = this.translationService.getTranslatedAttribute('title', element.translations, this.locale);
                    this.questions.push({
                        rank: element.rank,
                        question: question,
                        group: this.groups[index],
                        id: this.encode(question)
                    })
                });
            });
            setTimeout(() => this.subscribeToFragments(), 500)
          });

        }

    subscribeToFragments() {
      this.route.fragment.subscribe(fragment => {
        this.scrollToFragment(fragment)
      });
    }

    navToFragment(fragment) {

      this.router.navigate(['/faq'], { fragment: fragment })
    }


    scrollToFragment(fragment) {
      this.fragment = fragment
      try {
          if (this.fragment) {
            $('html, body').animate({scrollTop: $('#' + this.fragment).offset().top - 100}, 'slow');
          }
      } catch (e) {}

    }



}

<div class="homepage_app container_app page" [class.container_app_aop]="environment.domain == 'ophthalmology'">


  <section class="courses-selection">
    <h2><a routerLink="/library/courses">{{'academy.New_courses_selected_only_for_you'|translate}} <i
      class="fal fa-chevron-right"></i></a></h2>

    <div class="courses-slider-cache" [class.show]="coursesCache.length || !courses.length">

      <div class="course" [ngStyle]="{'background-image': gradientValues+', url(\''+course.picture_url+'\')'}"
           *ngFor="let course of coursesCache" id="/course/{{course.getId()}}/{{course.getSlug()}}">
        <div class="container">
          <div class="publication-info">
            <p class="date">{{'webinar.Published_on'|translate}} {{getDateFormatted(course)}}</p>
            <p class="detail">{{course.getMediaCount()}} {{ course.getMediaCount() === 1 ?
              ('pluralize.Videos'|translate).split('|')[0] : ('pluralize.Videos'|translate).split('|')[1] }}</p>
          </div>
          <div class="publication-title">
            <p>{{course.courseTitles ?
              translationService.getTranslatedTitle(course.courseTitles, locale) :
              translationService.getTranslatedAttribute('title', course.translations, locale)}}</p>
          </div>
          <div class="publication-footer">
            <div class="authors">
              <div class="author" [style.background-image]="'url('+ speaker.picture_url + ')'"
                   *ngFor="let speaker of course.getSpeakersArr()"></div>
            </div>
            <div class="ranking" *ngIf="course.rating">
              <ng-container *ngFor="let star of [0, 1, 2, 3 ,4]">
                <i class="far fa-star-media fa-star" *ngIf="course.rating < star"></i>
                <i class="fas fa-star-half-alt" *ngIf="course.rating >= star && course.rating <= (star + 0.5)"></i>
                <i class="fa fa-star-media fa-star" *ngIf="course.rating > (star + 0.5)"></i>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="courses-prev-cache" [class.show]="coursesCache.length && !courses.length">
      <i class="fal fa-angle-left"></i>
    </div>
    <div id="courses-next-cache" [class.show]="coursesCache.length && !courses.length">
      <i class="fal fa-angle-right"></i>
    </div>
    <div class="courses-dots-cache"></div>
    <div class="courses-slider" [class.show]="!coursesCache.length && courses.length">

      <div class="course" [ngStyle]="{'background-image': gradientValues+', url(\''+course.picture_url+'\')'}"
           *ngFor="let course of courses" id="/course/{{course.getId()}}/{{course.getSlug()}}">
        <div class="container">
          <div class="publication-info">
            <p class="date">{{'webinar.Published_on'|translate}} {{getDateFormatted(course)}}</p>
            <p class="detail">{{course.getMediaCount()}} {{ course.getMediaCount() === 1 ?
              ('pluralize.Videos'|translate).split('|')[0] : ('pluralize.Videos'|translate).split('|')[1] }}</p>
          </div>
          <div class="publication-title">
            <p>{{course.courseTitles ?
              translationService.getTranslatedTitle(course.courseTitles, locale) :
              translationService.getTranslatedAttribute('title', course.translations, locale)}}</p>
          </div>
          <div class="publication-footer">
            <div class="authors">
              <div class="author" [style.background-image]="'url('+ speaker.picture_url + ')'"
                   *ngFor="let speaker of course.getSpeakersArr()"></div>
            </div>
            <div class="ranking" *ngIf="course.rating">
              <ng-container *ngFor="let star of [0, 1, 2, 3 ,4]">
                <i class="far fa-star-media fa-star" *ngIf="course.rating < star"></i>
                <i class="fas fa-star-half-alt" *ngIf="course.rating >= star && course.rating <= (star + 0.5)"></i>
                <i class="fa fa-star-media fa-star" *ngIf="course.rating > (star + 0.5)"></i>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="courses-prev" [class.show]="!coursesCache.length && courses.length">
      <i class="fal fa-angle-left"></i>
    </div>
    <div id="courses-next" [class.show]="!coursesCache.length && !courses.length">
      <i class="fal fa-angle-right"></i>
    </div>
    <div class="courses-dots"></div>

  </section>


<!--  <section class="discover_board_dash" *ngIf="environment.domain == 'ophthalmology'">
    <a class="button button-orange"
       routerLink="/explore/scientific-board">{{'application.Discover_board'|translate}}</a>
  </section>-->
<!--  <section class="newest-lectures" *ngIf="user.premium_membership">
    <h2><a routerLink="/account/offline-videos">{{ 'application.Videos_stored'|translate }} <i
      class="fal fa-chevron-right"></i></a></h2>

    <app-offline-video-element [dashboard]="true"></app-offline-video-element>
  </section>-->
  <section class="library">
    <h2><a routerLink="/library/courses">{{ 'academy.Browse_through'|translate }} <i
      class="fal fa-chevron-right"></i></a></h2>
    <div class="categories">
      <a class="category" *ngFor="let themes of themesSlides" [routerLink]="themes.linkLibrary()">
        <div class="category-image" [style.background-image]="'url('+ themes.picture_url + ')'"></div>
        <div class="category-text"><span>{{ themes.getTranslatedName(locale) }}</span></div>
      </a>
      <div class="spacer"></div>
    </div>
  </section>


  <section class="newest-lectures">
    <h2><a routerLink="/library/lectures">{{'academy.Newest_lectures'|translate}} <i
      class="fal fa-chevron-right"></i></a></h2>
    <div class="container">
      <hr/>
      <ng-container *ngFor="let lecture of academyMedias">
        <div class="lecture">
          <div class="preview"
               [ngStyle]="{'background-image': 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(\''+lecture.getThumb('squared')+'\')'}"
               (click)="goToLecture(lecture)">
            <div class="play-image"><i class="fal fa-play"></i></div>

          </div>
          <div class="content_lecture">
            <div class="lecture-header">
              <p class="title"
                 [innerHTML]=" (translationService.getTranslatedTitle(lecture.getMediaTitles(), locale).length>56)? (translationService.getTranslatedTitle(lecture.getMediaTitles(), locale) | slice:0:56)+'...':(translationService.getTranslatedTitle(lecture.getMediaTitles(), locale)) "
                 (click)="goToLecture(lecture)"></p>

            </div>

            <div class="infos">
              <p>{{'academy.by'|translate}} <a (click)="goToUserProfile(speaker.getProfileUrl())"
                                               *ngIf="lecture.getSpeakersArr().length > 0">{{lecture.getSpeakersArr()[0].getFullName()}}
                <ng-container *ngIf="lecture.getSpeakersArr().length>1">...</ng-container>
              </a></p>
              <p class="infos-date"> {{getPublishedDate(lecture)}}</p>

            </div>
            <div class="ranking" *ngIf="lecture.rating">

              <ng-container *ngFor="let star of [0, 1, 2, 3 ,4]">
                <i class="far fa-star-media fa-star" *ngIf="lecture.rating < star"></i>
                <i class="fas fa-star-half-alt" *ngIf="lecture.rating >= star && lecture.rating <= (star + 0.5)"></i>
                <i class="fa fa-star-media fa-star" *ngIf="lecture.rating > (star + 0.5)"></i>
              </ng-container>


            </div>
          </div>
        </div>
        <hr/>
      </ng-container>
    </div>
  </section>
  <ng-container *ngIf="isCapacitorService.isPaymentAvailable()">
    <section class="discover_board_dash" *ngIf="!user.premium_membership">
      <h2><a routerLink="/premium">{{ 'academy.Get_access_nb'|translate:{nb: nbAcademyMedias} }} <i
        class="fal fa-chevron-right"></i></a></h2>
      <a class="button button-orange" routerLink="/premium">{{'layout.Subscribe'|translate}}</a>
    </section>
  </ng-container>


  <section class="upcoming-webinars" *ngIf="upcomingWebinars.length > 0 || upcomingWebinarsCache.length > 0 && environment.domain === 'aesthetics'">
    <h2><a routerLink="/webinars">{{ 'webinar.Upcoming_live_web'|translate }} <i class="fal fa-chevron-right"></i></a>
    </h2>
    <div class="webinars-slider-cache" [class.show]="upcomingWebinarsCache.length || !upcomingWebinars.length">
      <div class="webinar" *ngFor="let webinar of upcomingWebinarsCache"
           [style.background-image]="'url('+ webinar.picture_url + ')'" id="/webinar/{{webinar.slug}}">
        <div class="webinar-text">
          <div class="webinar-nav">
            <span class="title">{{ webinar.getTitle(locale) }}</span>
          </div>
          <p class="webinar-date">{{ webinar.getFullDateTime(locale) }}, <span class="webinar-type">LIVE</span> Paris
          </p>
        </div>
      </div>

    </div>
    <div id="webinar-prev-cache" [class.show]="upcomingWebinarsCache.length || !upcomingWebinars.length">
      <i class="fal fa-angle-left"></i>
    </div>
    <div id="webinar-next-cache" [class.show]="upcomingWebinarsCache.length || !upcomingWebinars.length">
      <i class="fal fa-angle-right"></i>
    </div>
    <div class="webinars-dots-cache"></div>

    <div class="webinars-slider" [class.show]="!upcomingWebinarsCache.length && upcomingWebinars.length">
      <div class="webinar" *ngFor="let webinar of upcomingWebinars"
           [style.background-image]="'url('+ webinar.picture_url + ')'" id="/webinar/{{webinar.slug}}">
        <div class="webinar-text">
          <div class="webinar-nav">
            <span class="title">{{ webinar.getTitle(locale) }}</span>
          </div>
          <p class="webinar-date">{{ webinar.getFullDateTime(locale) }}, <span class="webinar-type">LIVE</span> Paris
          </p>
        </div>
      </div>

    </div>
    <div id="webinar-prev" [class.show]="!upcomingWebinarsCache.length && upcomingWebinars.length">
      <i class="fal fa-angle-left"></i>
    </div>
    <div id="webinar-next" [class.show]="!upcomingWebinarsCache.length && upcomingWebinars.length">
      <i class="fal fa-angle-right"></i>
    </div>
    <div class="webinars-dots"></div>
  </section>


  <section class="alert-discussion">
    <h2><a routerLink="/alert">{{ 'alert.Main_subtitle' | translate }} <i class="fal fa-chevron-right"></i></a></h2>
    <div class="container">
      <ng-container *ngFor="let case of cases">
        <div class="discussion" (click)="openCase(case.group)">
          <p class="category">{{translationService.getTranslatedAttribute('name', case.group.translations, locale)}}</p>
          <p class="topic">{{case.title}}</p>
          <div class="infos">
            <p>{{dateMomentService.getPublicationDate(case.created_at)}}</p>
            <p>{{ (case.comments_count === 0 ?
              ('academy.Nb_reply_alet_case'|translate).split('|')[0]?.slice(4) :
              case.comments_count == 1 ?
                ('academy.Nb_reply_alet_case'|translate :{count: case.comments_count}).split('|')[1]?.slice(4) :
                ('academy.Nb_reply_alet_case'|translate :{count: case.comments_count}).split('|')[2]?.slice(8))}}</p>
          </div>
        </div>
        <hr/>
      </ng-container>

    </div>
  </section>


  <section class="latest-articles" *ngIf="environment.domain == 'aesthetics'">
    <h2><a routerLink="/blog">{{ 'academy.Whats_new_on_the_Academy'|translate }} <i
      class="fal fa-chevron-right"></i></a></h2>

    <div class="articles">
      <div class="article" *ngFor="let article of articles" (click)="goToPost(article)">
        <div class="image" [style.background-image]="'url('+ article.pictures_url.thumbnail + ')'">
          <span class="label">{{ 'blog.Blog_article' | translate}}</span>
        </div>
        <div class="text">
          <p class="title" (click)="goToPost(article)">{{article.title}}</p>
          <div class="infos" *ngIf="article.authors[0]">
            <p>{{article.authors[0].title + ' ' + article.authors[0].first_name + ' ' + article.authors[0].last_name}}</p>
            <p *ngIf="article.themes[0]">
              [{{translationService.getTranslatedAttribute('name', article.themes[0].translations, locale)}}]</p>
          </div>
        </div>
      </div>

    </div>


  </section>


</div>

<div class="page">
  <app-section-top [title]="'academy.Industry_supporters'"
                   [subtitle]="(environment.domain == 'aesthetics' ? 'academy.Delve_into' : 'academy.Delve_into_aop')|translate"
                   [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-industry.jpg'">
  </app-section-top>
  <div class="content has-max-width">
    <div class="d-flex justify-content-end mt-4" >
      <a class="button button-orange" [href]="environment.base_url + '/academy/sponsor'">{{'academy.become_partner' | translate }}</a>
    </div>
    <div class="d-flex align-items-center">
      <p class="text-center has-custom-style">{{ 'academy.Industry_partners_introduction'| translate }}</p>

    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm">
          <select class="filters-select text-wrap" id="filter-theme-company-profiles" [(ngModel)]="filterTheme" name="theme"
                  (change)="select($event, 'theme')">
            <option value="">{{('academy.Filter_by_theme'|translate)}}</option>
            <option *ngFor="let theme of allThemes | keyvalue" [value]="theme.key">
              {{theme.key}}
            </option>
          </select>
        </div>
        <div class="col-sm">
          <select class="filters-select text-wrap" id="filter-category-company-profiles" [(ngModel)]="filterCategory"
                  name="category"
                  (change)="select($event, 'category')">
            <option value="">{{('academy.Filter_by_category'|translate)}}</option>
            <option *ngFor="let category of allCategories | keyvalue" [value]="category.key">
              {{category.key}}
            </option>
          </select>
        </div>
        <div class="col-sm">
          <select class="filters-select" id="filter-country-company-profiles" [(ngModel)]="filterCountry"
                  name="country"
                  (change)="select($event, 'country')">
            <option value="">Filter by Country</option>
            <option *ngFor="let country of allCountries | keyvalue" [value]="country.key">
              {{country.key}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div [ngClass]="(companies$ | async) ? 'd-flex justify-content-between results' : 'd-flex justify-content-center results'">
      <ng-container *ngIf="companies$ | async as companies; else Loader">
        <ng-container *ngIf="companies.length > 0; else NoCompanies">
          <div [ngClass]="(ads$ | async) && (ads$ | async).length > 0 ? 'col-10' : 'col-12'">
            <h5 class="search-results-number">{{'Results : ' + allDisplayedCompanies.size + ' / ' + allCompanies.length + ' ' + ('academy.Industry_supporters' | translate)}}</h5>
            <div class="d-flex flex-wrap justify-content-around">
              <app-card *ngFor="let company of pagination.get(currentPage)" [media]="company.thumbnail_url ? company.thumbnail_url : company.logo_url" [buttons]="getButton(company.slug, company.total_videos)" [title]="company.company_name"></app-card>
            </div>
          </div>
        </ng-container>

        <ng-template #NoCompanies>  {{'academy.No_result_found'|translate}}</ng-template>
        <ng-container *ngIf="ads$ | async as ads">
          <div class="col-3 ad-container" #Container>
            <div *ngIf="ads.length >0" class="d-flex flex-column" #Ads>
              <a *ngFor="let ad of ads" target="_blank" [href]="ad.url" class="is-ad" [style.pointer-events]="ad.url ? 'auto' : 'none'">
                <img class="" [src]="ad.image" alt="">
              </a>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #Loader><app-loader></app-loader></ng-template>
    </div>
    <nav>
      <ul class="pagination pagination-sm justify-content-center">
        <li [ngClass]="currentPage === 1 ? 'page-item disabled' : 'page-item'">
          <a class="page-link" (click)="setCurrentPage(currentPage - 1)">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li *ngFor="let page of pagination | keyvalue" [ngClass]="currentPage === page.key ? 'page-item active' : 'page-item'">
            <a class="page-link" (click)="setCurrentPage(page.key)">{{page.key}}</a>
        </li>
        <li [ngClass]="currentPage === pagination.size ? 'page-item disabled' : 'page-item'">
          <a class="page-link" (click)="setCurrentPage(currentPage + 1)">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div #Discover class="discover" *ngIf="articles$ | async as articles">

    <div class="content">
      <h1>{{ 'company_profile.Homepage_title'|translate }}</h1>
      <div class="discover-news row no-gutters" >
        <div class="discover-news-item col-md-4" *ngFor="let article of articles">
          <a
            [href]="['/' + locale + '/academy/profile/' + article.company_profile.slug + '/articles/' + article.id + '/' + article.slug]">
            <img [src]="article.company_profile.logo_url" class="discover-news-image" alt="">
            <span class="dotted-line"></span>
            <h5 class="discover-news-title">{{article.title}}</h5>
            <span class="discover-news-date">{{ dateMomentService.getPublicationDate(article.publication_date) }}</span>
            <span class="discover-news-link">{{ 'company_profile.Homepage_link'|translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>

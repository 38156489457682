import {Media} from './media.model';
import {Feedback} from './feedback.model';
import {Course} from './course.model';

export class Webpage {
    view: string;
    images: Array<any>;
    texts: Array<any>;
    links: Array<any>;
    main_background_color: String;
    trusted_by_notes: String;
    pictos: Array<any>;
    numbers: Array<any>;
    main_banner: boolean;
    two_side_banner: boolean;
    media: Media;
    feedbacks: Feedback[];
    academy_courses: Course[];

    constructor(obj?: any) {
        this.view = obj && obj.view;
        this.images = obj && obj.images;
        this.texts = obj && obj.texts;
        this.links = obj && obj.links;
        this.main_background_color = obj && obj.main_background_color;
        this.trusted_by_notes = obj && obj.trusted_by_notes;
        this.pictos = obj && obj.pictos;
        this.numbers = obj && obj.numbers;
        this.main_banner = obj && obj.main_banner;
        this.two_side_banner = obj && obj.two_side_banner;
        this.media = obj && obj.media ? new Media(obj.media) : null;
        if (obj && obj.feedbacks) {
            this.feedbacks = [];
            obj.feedbacks.forEach(element => {
                this.feedbacks.push(new Feedback(element));
            });
        }
        if (obj && obj.academy_courses) {
            this.academy_courses = [];
            obj.academy_courses.forEach(element => {
                this.academy_courses.push(new Course(element));
            });
        }
    }
}

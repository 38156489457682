<div class="topic-list">
    <div [class.topic-item]="case" *ngFor="let case of cases">
        <div *ngIf="!isAop" class="topic-type" (click)="openCase(case.group)">{{translationService.getTranslatedAttribute('name', case.group.translations, locale)}}
          {{ case.group_2 && case.group_2 !== null && case.group_2.translations ? " / " + translationService.getTranslatedAttribute('name', case.group_2.translations, locale) : null}}</div>
        <div [routerLink]="['/alert', case.group.slug, case.id, case.slug]" class="topic-case-data">
            <h5>{{case.title}}</h5>
            <p>{{dateMomentService.getPublicationDate(case.created_at)}}</p>
            <p>{{ (case.comments_count === 0 ?
                ('academy.Nb_reply_alet_case'|translate).split('|')[0]?.slice(4) :
                case.comments_count == 1 ?
                    ('academy.Nb_reply_alet_case'|translate :{count: case.comments_count}).split('|')[1]?.slice(4) :
                    ('academy.Nb_reply_alet_case'|translate :{count: case.comments_count}).split('|')[2]?.slice(8))}}
            </p>
        </div>
    </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from "../../../services/translation.service";
import { environment } from 'environments/environment';
import {LocalStorage} from "@ngx-pwa/local-storage";
import {DateMomentService} from "../../../services/date-moment.service";
import {LocaleService} from "../../../services/locale.service";
import {Router} from "@angular/router";
import {OfflineVideoService} from "../../../services/mobile-services/offline-video.service";
import {AcademyMedia} from "../../../models/academy-media.model";

@Component({
  selector: 'app-offline-video-element',
  templateUrl: './offline-video.component.html',
  styleUrls: ['./offline-video.component.scss'],
  providers: [
      TranslationService
    ]
})

export class OfflineVideoElementComponent implements OnInit {

  environment: any = environment;
  academyMedia: any = [];

  @Input() dashboard;


  constructor(
    public translationService: TranslationService,
    private localStorage: LocalStorage,
    private dateMomentService: DateMomentService,
    private localeService: LocaleService,
    private router: Router,
    private offlineVideoService: OfflineVideoService
  ) {


  }

  ngOnInit() {

    this.academyMedia = [];
    this.offlineVideoService.videoSaved.forEach(video => {
      const academyMediaObj: AcademyMedia = new AcademyMedia(video.academyMedia);

      this.academyMedia.push(academyMediaObj);
    })
  }

  getPublishedDate(lecture) {
    const date = this.dateMomentService.getUnixDate(lecture.date);
    if (this.localeService.locale === 'fr') {
      return date.format('Do MMMM YYYY');
    }
    return date.format('MMMM Do, YYYY');
  }

  goToLecture(lecture) {
    this.router.navigateByUrl('/show/' + lecture.getId() + '/' + lecture.getSlug());
  }

  deleteVideo(lecture){
    this.offlineVideoService.deleteVideo(lecture.id);

    let idx = 0;
    this.offlineVideoService.videoSaved.forEach((video, key) => {
      if (video.id == lecture.id){
        idx = key;
      }
    })
    this.offlineVideoService.videoSaved.splice(idx, 1);
    this.academyMedia.splice(idx, 1);
    this.localStorage.setItem('VIDEO_index', this.offlineVideoService.videoSaved).subscribe(() => {});

  }

}

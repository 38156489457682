<h3>Certifications</h3>
<div class="alert alert-success" *ngIf="status == 'verified'" >
  <p>{{'academy.certifications_verified'|translate}}</p>
</div>

<div class="alert alert-info" *ngIf="status == 'under_process'" >
  <p>{{'academy.certifications_under_process'|translate}}</p>
</div>

<div *ngIf="status == 'not_verified'" >

  <div class="alert alert-danger">
    <p>{{'academy.diploma_required_title'|translate}}</p>
    <p *ngIf="user.premium_membership">{{'academy.diploma_required_text_premium'|translate}}</p>
    <p *ngIf="!user.premium_membership">{{'academy.diploma_required_text_free'|translate}}</p>
  </div>

  <button class="btn btn-info" (click)="onClick()" type="button">
    {{'academy.add_certificates'|translate}}
  </button>
  <p style="font-size: 12px;">(jpg, png, pdf)</p>
  <div class="row files">
    <div *ngFor="let file of files" class="file">
      <div class="delete">
        <button (click)="removeFile(file)">
          <i class="fa fa-times-circle"></i>
        </button>
      </div>
      <i class="fa fa-file"></i>
      <p>{{file.name}}</p>
    </div>

  </div>
  <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" max="5" accept="image/*, application/pdf" style="display:none;" />
  <div class="button-set text-center">
    <button (click)="uploadFiles()" class="button button-orange" [hidden]="!files.length">{{'academy.upload_my_certifications'|translate}}</button>
  </div>
</div>


import {environment} from './../../../../environments/environment';
import {Component} from '@angular/core';


@Component({
  selector: 'not-found-component',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})


export class NotFoundComponent {
  environment = environment;
}

import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
    environment: any = environment;

  constructor() { }

  ngOnInit() {
  }

}

<div class="page container">
    <h1>{{ 'webinar.System_check_title'|translate }}</h1>
    <h2 *ngIf="status == 'good'">{{ 'webinar.System_good'|translate }}</h2>
    <h2 *ngIf="status == 'issues'">{{ 'webinar.System_failed'|translate }}</h2>
    <div class="row">
        <div class="col-md-6 icon-desktop">
            <i class="fal fa-desktop-alt"></i>
            <i class="fal fa-check" *ngIf="status == 'good'"></i>
            <i class="fal fa-times" *ngIf="status == 'issues'"></i>
        </div>
        <div class="col-md-6" *ngIf="browser">
            <p><i class="fal fa-check-circle"></i>{{ browser.os }}</p>
            <p><i class="fal fa-check-circle" *ngIf="checkVersion(browser.name, browser.version)"></i><i *ngIf="!checkVersion(browser.name, browser.version)" class="fal fa-times-circle"></i>{{ browser.name.charAt(0).toUpperCase() + browser.name.slice(1) + ' ' + browser.version }}</p>
            <p *ngIf="isConnectionValid"><i class="fal fa-check-circle"></i>{{ 'webinar.Internet_good'|translate }}</p>
            <p *ngIf="!isConnectionValid"><i class="fal fa-times-circle"></i>{{ 'webinar.Internet_poor'|translate }}</p>
        </div>
    </div>
    <div class="row row-bottom">
        <div class="col-md-12">
            <a [routerLink]="'/webinars'">{{ 'webinar.Go_back_webinars'|translate }}</a>
        </div>
    </div>
</div>

<div class="page premium">
  <app-section-top [title]="'premium.For_experts_by_experts'" [subtitle]="'premium.Join_them_now'|translate"
                   [subtitle2]="'premium.Join_them_now_part2'|translate"
                   [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-industry.jpg'"></app-section-top>
  <ng-template #partialaccess let-c="close" let-d="dismiss">
    <div class="modal-body modal-no-title modal-wide">
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div class="modal-content">
        <div *ngIf="environment.domain == 'aesthetics'">
          <p>{{ 'premium.Access_to_the_scientific_content'|translate }}:</p>
          <ul>
            <li *ngFor="let theme of themes">{{ translationService.getTranslatedName(theme.translations, locale) }}
              <small
                *ngIf="theme.id == 40 || theme.id == 42 || theme.id == 50">({{ 'premium.Right_to_practice'|translate }}
                )</small></li>
          </ul>
        </div>
        <div *ngIf="environment.domain == 'ophthalmology'">
          <p>{{ 'premium.Access_to_aop'|translate }}</p>
        </div>
      </div>
    </div>
  </ng-template>
  <section class="section offer-plan" *ngIf="environment.mobile">
    <div class="content text-center">
      Manage your subscription on the academy website
    </div>
  </section>
  <section class="section alert alert-danger" *ngIf="environment.domain === 'ophthalmology'">
    <div class="content text-center">
     {{'academy.aop_subscription_deleted' | translate}}
    </div>
  </section>
  <section class="section premium-plans" [class.aop-plans]="environment.domain === 'ophthalmology'" *ngIf="products">
    <section class="alert alert-danger" *ngIf="requestError">
      {{ requestError }}
    </section>
    <div class="content">
      <div class="row justify-content-md-center">
        <ng-template #tootltipExplore>
          <div>
            {{ 'premium.Explore_our_selection'|translate }}
          </div>
        </ng-template>
        <ng-template #tootltipDiscuss>
          <div>
            {{ 'premium.Forum_that_will_give_you_opportunities'|translate }}
          </div>
        </ng-template>
        <ng-template #tootltipConnect>
          <div>{{ 'premium.Send_messages_to_you_friends'|translate }}</div>
        </ng-template>
        <ng-template #tootltipAlert>
          <div>{{ 'premium.Get_expert_opinion'|translate }}</div>
        </ng-template>
        <ng-template #tootltipTrack>
          <div>{{ 'premium.Monitor_your_development'|translate }}</div>
        </ng-template>
        <ng-template #tootltipCertificate>
          <div>{{ 'premium.Receive_a_certificate'|translate }}</div>
        </ng-template>
        <div class="col-md-6" *ngIf="products[0]">
          <div class="premium-plan premium-plan-physicians">
            <h2 class="premium-plan-title">{{ 'premium.Physicians'|translate }}</h2>
            <div class="premium-price">
              <span *ngIf="products[0].getPriceObj('usd').currency == 'usd'">US$ </span>
              {{ products[0].getPriceObj('usd').price_subscription_ttc }}
              <span *ngIf="products[0].getPriceObj('usd').currency == 'eur'"> €</span>
            </div>
            <div class="premium-price-period">{{ 'premium.Per_12_months'|translate }}</div>
            <div class="premium-subscribe" [ngbTooltip]="subscribeTooltip()">
              <button *ngIf="isAutoSubscribe && !environment.mobile" class="button button-large button-orange-outline"
                      (click)="openCart(products[0].id, 'personal')">See my cart</button>
              <button *ngIf="(!user || !user.premium_membership) && !environment.mobile" [disabled]="!!subscribeTooltip()"
                      class="button button-large button-orange-outline"
                      (click)="openCart(products[0].id, 'personal')">{{ 'academy.Subscribe_now'|translate }}</button>
            </div>
            <ul class="premium-features">
              <li *ngIf="!isAop && mediasCount$ | async as mediasCount"
                  [innerHTML]="'premium.Full_access_physician_imcas'|translate: {nb: mediasCount.physician}"></li>
              <li *ngIf="isAop && mediasCount$ | async as mediasCount"
                  [innerHTML]="'premium.Full_access_physician'|translate: {nb: mediasCount.physician}"></li>
              <li><span [ngbTooltip]="tootltipExplore">{{ 'premium.IMCAS_Explore'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipDiscuss">{{ 'premium.IMCAS_Discuss'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipConnect">{{ 'premium.Connect_with_peers'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipAlert">{{ 'premium.IMCAS_Alert'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipTrack">{{ 'premium.Progress_tracking'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipCertificate">{{ 'premium.Certificate_of_achievement'|translate }}</span>
              </li>
              <li>{{ 'premium.Rate_content'|translate }}</li>
              <li>{{ 'premium.Priority_support'|translate }}</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6" *ngIf="products[1] && !(user && user.specialty_category === 'physician')">
          <div class="premium-plan premium-plan-nonphysicians">
            <h2 class="premium-plan-title">{{ 'premium.Non_physicians'|translate }} <span class="icon icon-help-mark"
                                                                                          (click)="open(partialaccess)"></span>
            </h2>
            <div class="premium-price">
              <span *ngIf="products[1].getPriceObj('usd').currency == 'usd'">US$ </span>
              {{ products[1].getPriceObj('usd').price_subscription_ttc }}
              <span *ngIf="products[1].getPriceObj('usd').currency == 'eur'"> €</span>
            </div>
            <div class="premium-price-period">{{ 'premium.Per_12_months'|translate }}</div>
            <div class="premium-subscribe">
              <button *ngIf="isAutoSubscribe && !environment.mobile" class="button button-large button-red-outline"
                      (click)="openCart(products[1].id, 'personal')">See my cart</button>
              <button *ngIf="(!user || !user.premium_membership) && !environment.mobile" class="button button-large button-red-outline"
                      (click)="openCart(products[1].id, 'personal')">{{ 'academy.Subscribe_now'|translate }}</button>
            </div>
            <ul class="premium-features">
              <li (click)="open(partialaccess)" class="clickable" *ngIf="!isAop && mediasCount$ | async as mediasCount"
                  [innerHTML]="'premium.Partial_access'|translate: {nb: mediasCount.other}">
              </li>
              <li *ngIf="isAop && mediasCount$ | async as mediasCount"
                  [innerHTML]="'premium.Full_access_physician'|translate: {nb: mediasCount.physician}"></li>
              <li><span [ngbTooltip]="tootltipExplore">{{ 'premium.IMCAS_Explore'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipDiscuss">{{ 'premium.IMCAS_Discuss'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipConnect">{{ 'premium.Connect_with_peers'|translate }}</span></li>
              <li *ngIf="isAop"><span [ngbTooltip]="tootltipAlert">{{ 'premium.IMCAS_Alert'|translate }}</span></li>
              <li><span [ngbTooltip]="tootltipTrack">{{ 'premium.Progress_tracking'|translate }}</span></li>
              <li *ngIf="isAop"><span
                [ngbTooltip]="tootltipCertificate">{{ 'premium.Certificate_of_achievement'|translate }}</span></li>
              <li>{{ 'premium.Rate_content'|translate }}</li>
              <li>{{ 'premium.Priority_support'|translate }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section offer-plan" *ngIf="environment.domain === 'aesthetics' && !environment.mobile && (products[0] && mediasCount$ | async) as mediasCount">
    <div class="content">
      <!-- <a href="javascript:void(0)" class="button button-orange"
          (click)="openCart(products[0].id, 'gift')">{{ 'premium.Offer_title'|translate }}</a><br>-->
      <p>{{ 'premium.Offer_para'|translate :{nb: mediasCount.lecture} }}</p>
    </div>
  </section>

  <section class="section premium-faq" *ngIf="environment.domain === 'aesthetics'">
    <div class="content">
      <app-content-faq [showSummary]="false"></app-content-faq>
    </div>
  </section>
</div>

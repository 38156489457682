import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()

export class GuestGuardService implements CanActivate {
    constructor(private authService: AuthService,
                private router: Router) {
    }

    canActivate() {
        if (!this.authService.getToken()) {
            return true;
        } else {
            this.router.navigateByUrl('/dashboard');
            return false;
        }
    }
}

<div class="container_app" [class.container_app_aop]="environment.domain == 'ophthalmology'">

  <header class="mobile-header">
    <a class="btn_header" routerLink="/home"><i class="fal fa-arrow-left"></i></a>
    <span>Login</span>
  </header>

  <div class="bg-login" style="background-image:url('assets/img/bg_image_aop.png')" *ngIf="environment.domain == 'ophthalmology'"></div>

  <div [className]="environment.domain === 'ophthalmology' ? 'login-screen aop-color' : 'login-screen imcas-color'">


    <div class="login">
      <div class="login-text">
        <img class="logo_app light" src="../../../../../assets/img/logo_white.png" alt="Logo IMCAS Academy" *ngIf="environment.domain == 'aesthetics'">
        <img class="logo_app light" src="../../../../../assets/img/logo_white_aop.png" alt="Logo AOP Academy" *ngIf="environment.domain == 'ophthalmology'">
        <h1>{{'application.Login_screen_title'|translate}}</h1>

        <h2>{{'application.Login_screen_subtitle'|translate}}</h2>
      </div>

      <form class="login-form" (ngSubmit)="login()" [formGroup]="loginForm">
        <div class="line-input">
          <input type="email" formControlName="username" name="username" autocorrect="off" autocapitalize="none" autocomplete="off" />
          <span class="floating-label">{{'attend.Email'|translate}}</span>


        </div>
        <div *ngIf="loginForm.get('username').errors && (formValidated)" class="form-login-error">
          {{'validation.required'|translate:{attribute: 'account.Username'|translate } }}
        </div>

        <div class="line-input">
          <input type="password" formControlName="password" name="password" #password />
          <span class="floating-label">{{'reminders.password_label'|translate}}</span>
        </div>

        <div *ngIf="loginForm.get('password').errors && (formValidated)" class="form-login-error">
          {{'validation.required'|translate:{attribute: 'account.Password'|translate } }}
        </div>

        <button class="transparent-button primary">
          <span *ngIf="!loading">{{'academy.Login'|translate}}</span>
          <span *ngIf="loading">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </span>

        </button>

        <div *ngIf="loginError" class="form-login-error form-login-error-main">
          <div>{{'account.Login_failed_flash'|translate}}</div>
        </div>
      </form>
    </div>


    <div class="login-options">
      <p><a [routerLink]="['/password-lost']">{{'account.Password_lost'|translate}}</a></p>

      <p><a (click)="linkService.openBlank('mailto:'+environment.emailSupport)">{{'application.Login_screen_problem'|translate}}</a></p>

    </div>
  </div>
</div>

<div class="notifications">
  <div class="notification dropdown-item"
       *ngFor="let notification of notifications | keys">
    <a class="notification-url" (click)="openNotificationUrl(notifications[notification])">
      <div class="clearfix">
        <div class="img-thumb">
          <div class="picture"
               *ngIf="notifications[notification].picture?.substring(0, 4) == 'http';else divChar"
               [style.background]="'url(' + notifications[notification].picture + ')'"
               [style.background-size]="'cover'">
          </div>
          <ng-template #divChar> <div [innerHTML]="notifications[notification].picture | safeHtml"></div></ng-template>
        </div>
        <div class="notification-content-wrapper">
          <span class="date-hour">{{ notifications[notification].date_for_humans }}</span>
          <div class="notification-content">{{ notifications[notification].text}}</div>
        </div>
      </div>
    </a>
  </div>
  <div class="notification dropdown-item" *ngIf="notifications.length == 0">
    <a class="notification-url">
      <div class="clearfix">
        No notification for now...
      </div>
    </a>
  </div>

</div>

import {AfterViewInit, Component, Input} from '@angular/core';


@Component({
    selector: 'app-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss'],
})
export class SlideshowComponent implements AfterViewInit {
    @Input() elements: [];

    constructor() {
    }

    ngAfterViewInit() {
      const marquee = <HTMLElement>document.querySelector('.marquee-inner');
      marquee?.style.setProperty('--time-banner', this.elements.length + 's');
      marquee?.style.setProperty('--nbElts-banner', this.elements.length.toString());
    }

    getUrl(element) {
        let website;
        if (element.scientific_society) {
            website = element.scientific_society.website;
        }
        if (element.publication) {
            website = element.publication.website;
        }
        return website;
    }

    getSourceImg(element) {
        let path;
        if (element.scientific_society) {
            path = element.scientific_society.logo_url;
        }
        if (element.publication) {
            path = element.publication.logo_url;
        }
        return path;
    }

}

import {Component, OnInit} from '@angular/core';
import {LocaleService} from '../../../services/locale.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {User} from 'app/models/user.model';
import {MediaService} from 'app/services/media.service';
import {CourseService} from 'app/services/course.service';
import {TranslationService} from 'app/services/translation.service';
import {AcademyMedia} from 'app/models/academy-media.model';
import {Course} from 'app/models/course.model';
import {map} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'sign-up-thanks-component',
    templateUrl: './sign-up-thanks.component.html',
    styleUrls: ['./sign-up-thanks.component.scss'],
    providers: [
        MediaService,
        CourseService
    ]
})
export class SignUpThanksComponent implements OnInit {
    user: User;
    locale: string;
    environment: any = environment;
    content: any[] = [];
    medias: AcademyMedia[];
    courses: Course[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private mediaService: MediaService,
        private localeService: LocaleService,
        private courseService: CourseService,
        public translationService: TranslationService,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.locale = this.localeService.currentLocale();
        this.getUser();
        this.getContent();
    }

    getUser() {
        this.user = this.authService.getCurrentUser();
    }

    getSlug(translations) {
        return this.translationService.getSlugFromTranslations('title', translations);
    }

    getContent() {
        this.mediaService.getAcademyMedias({
            user_id: this.user.id,
            per_page: 2,
            media_cdn: 0,
            media_lecture: 0,
            media_speakers: 0,
            academy_media_event: 0,
            academy_media_course: 0,
            comments_auth: 0
        })
            .pipe(map((medias: any) => medias.data))
            .subscribe(
                medias => {
                    this.medias = medias;
                    this.content[1] = medias[0];
                    this.content[4] = medias[1];
                }
            );

        this.courseService.getCourses({
            user_id: this.user.id,
            per_page: 4
        })
            .pipe(map((courses: any) => courses.data))
            .subscribe(
                courses => {
                    this.courses = courses;
                    this.content[0] = courses[0];
                    this.content[2] = courses[1];
                    this.content[3] = courses[2];
                    this.content[5] = courses[3];
                }
            );
    }


    isPhysicianNotVerified() {
      if (this.user && this.user.specialty_category === 'physician') {
        return (this.user.diploma_status && this.user.diploma_status.includes('not_verified')) ? true : false ;
      }
      return false;
    }
}

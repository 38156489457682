import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'removeTags'})
export class RemoveTagsPipe implements PipeTransform {
    constructor() {
    }

    transform(text) {
        return text.replace( /<.*?>/g, '' );
    }
}

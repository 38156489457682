<ul class="pagination justify-content-center">
  <li class="page-item" [ngClass]="{disabled: currentPage === firstPage}" *ngIf="currentPage">
    <a class="page-link" (click)="emitAction('first', firstPage)" aria-label="first">
      <span>{{'pagination.first'|translate}}</span>
    </a>
  </li>
  <li class="page-item" [ngClass]="{disabled: currentPage === firstPage}" *ngIf="currentPage">
    <a class="page-link" (click)="emitAction('prev', currentPage -1)" aria-label="Previous">
      <span aria-hidden="true">&laquo;</span>
      <span class="sr-only">{{'pagination.previous'|translate}}</span>
    </a>
  </li>
  <ng-container *ngFor="let pageNr of pagesNumbers">
    <li class="page-item" [ngClass]="{active: currentPage === pageNr}"
        *ngIf="currentPage && (currentPage === 1 ? (pageNr >= currentPage - gapPagesNumber && pageNr <= currentPage + displayedPagesNumber) :(currentPage === totalPagesNumber ? (pageNr >= currentPage - displayedPagesNumber && pageNr <= currentPage + gapPagesNumber) :(pageNr >= currentPage - gapPagesNumber && pageNr <= currentPage + gapPagesNumber)))">
      <a class="page-link" (click)="emitAction('to', pageNr)">{{pageNr}}</a>
    </li>
  </ng-container>
  <li class="page-item" [ngClass]="{disabled: currentPage === lastPage}" *ngIf="currentPage">
    <a class="page-link" (click)="emitAction('next', currentPage+1)" aria-label="Next">
      <span aria-hidden="true">&raquo;</span>
      <span class="sr-only">{{'pagination.next'|translate}}</span>
    </a>
  </li>
  <li class="page-item"  [ngClass]="{disabled: currentPage === lastPage}" *ngIf="currentPage">
    <a class="page-link" (click)="emitAction('last', lastPage)" aria-label="last">
      <span>{{'pagination.last'|translate}}</span>
    </a>
  </li>
</ul>

<div *ngIf="downloadApp" class="landing">
    <div class="landing-header" [ngStyle]="{'background-image' : 'url(' + landing.images['main'] + ')'}"
         *ngIf="landing.main_banner">
        <div class="mask" [ngStyle]="{'background' : landing.main_background_color}"></div>
        <div class="container">
            <img class="logo-academy" [src]="landing.images['logo']" alt="">
            <h1>{{ landing.texts['title'] }}</h1>
            <h2>{{ landing.texts['subtitle'] }}</h2>
            <div class="buttons">
                <a *ngIf="landing.links['buttons'][0] && landing.images['buttons'][0]"
                   [href]="landing.links['buttons'][0]" target="_blank"><img alt="" *ngIf="landing.images['buttons'][0]"
                                                                             [src]="landing.images['buttons'][0]"></a>
                <a *ngIf="landing.links['buttons'][0] && landing.texts['buttons'][0]" class="button button-orange"
                   [href]="landing.links['buttons'][0]" target="_blank">{{ landing.texts['buttons'][0] }}</a>

                <a *ngIf="landing.links['buttons'][1] && landing.images['buttons'][1]"
                   [href]="landing.links['buttons'][1]" target="_blank"><img alt="" *ngIf="landing.images['buttons'][1]"
                                                                             [src]="landing.images['buttons'][1]"></a>
                <a *ngIf="landing.links['buttons'][1] && landing.texts['buttons'][1]" class="button button-orange"
                   [href]="landing.links['buttons'][1]" target="_blank">{{ landing.texts['buttons'][1] }}</a>
            </div>
            <div class="reassuming">{{ landing.texts['title_under_btn']}}</div>
        </div>
        <img class="trusted-by" [src]="landing.trusted_by_notes" alt="">
    </div>
    <div class="container">
        <div class="row secondary-banner" *ngIf="landing.two_side_banner">
            <div class="col-md-5 secondary-banner-text">
                <h1>{{ landing.texts['secondary_title'] }}</h1>
                <h2>{{ landing.texts['secondary_subtitle'] }}</h2>
            </div>
            <div class="col-md-7 secondary-banner-img">
                <img [src]="landing.images['secondary']" alt="">
            </div>
        </div>
    </div>
    <div class="background-fluid">
        <div class="container">
            <div class="row justify-content-center main-info-numbers" *ngIf="landing.numbers['main_info'][0]">
                <div class="col-md-4" *ngFor="let i of [0,1,2]">
                    <i class="fal fa-{{ landing.pictos['main_info'][i] }}"
                       [ngStyle]="{'color': landing.main_background_color}"></i>
                    <br>
                    <span>{{ landing.numbers['main_info'][i] }}</span>
                    <p>{{ landing.texts['main_info'][i] }}</p>
                </div>
            </div>
            <div class="row justify-content-center detailed-info" *ngIf="landing.texts['detailed_info'][0]">
                <div class="col-md-4" *ngFor="let i of [0,1,2]">
                    <i class="fal fa-{{ landing.pictos['detailed_info'][i] }}"
                       [ngStyle]="{'color': landing.main_background_color}"></i>
                    <br>
                    <p class="detailed-info" [innerHTML]="landing.texts['detailed_info'][i]"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row" *ngIf="landing.feedbacks && landing.feedbacks.length > 0">
            <div class="col-md-12">
                <h2>What the experts are saying?</h2>
            </div>
            <div class="col-md-4" *ngFor="let feedback of landing.feedbacks">
                <app-feedback [feedback]="feedback"></app-feedback>
            </div>
        </div>
        <div class="row" *ngIf="landing.academy_courses && landing.academy_courses.length > 0">
            <div class="col-md-12">
                <h2>Discover the content</h2>
            </div>
            <div class="col-md-3 courses-element" *ngFor="let course of landing.academy_courses">
                <app-course-element [course]="course" [showPublicationDate]="false"></app-course-element>
            </div>
        </div>
    </div>
    <div class="background-dark">
        <div class="container">
            <div class="row player" *ngIf="landing.media">
                <div class="col-md-12">
                    <h2>{{translationService.getTranslatedElement(landing.media.translations, locale).title }}</h2>
                    <app-jwplayer-video
                            [file]="landing.media.cdn_dash_file"
                            [file2]="landing.media.cdn_file"
                            [media]="landing.media"
                            [width]="'80%'"
                            [aspectratio]="'16:9'"
                            [image]="landing.media.thumb">
                    </app-jwplayer-video>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row last-cta" *ngIf="landing.links['last_cta']">
            <div class="col-md-12 text-center">
                <a class="button button-orange" [href]="landing.links['last_cta']">{{ landing.texts['last_cta'] }}</a>
            </div>
        </div>
    </div>
</div>
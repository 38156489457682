export class Tweet {
    full_text: string;

    constructor(obj?: any) {
        this.full_text = obj && obj.full_text || '';
    }

    getFormattedFullText() {
        let output;
        const text = this.full_text,
            regexTwitterHandle   = /(^|[^@\w])@(\w{1,15})\b/g,
            regexTwitterHashtag   = /(^|\s)#(\w+)/g,
            regexUrls   = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
            replaceTwitterHandle = '$1<a href="https://twitter.com/$2">@$2</a>',
            replaceUrls = '<a href="$1">$1</a>',
            replaceTwitterHashtag = '$1<a href="https://twitter.com/search?q=$2">#$2</a>';

        output = text.replace(regexUrls, replaceUrls);
        output = output.replace(regexTwitterHandle, replaceTwitterHandle);
        output = output.replace(regexTwitterHashtag, replaceTwitterHashtag);
        return output;
    }

}

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
  export class ContactService {
  private endpoint: string = 'contact';

  constructor(
    private httpClient: HttpClient
  ) { }

  send(data: FormData) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
    return this.httpClient.post<any>(environment.apiURLNew + this.endpoint + '/send', data,{headers: {multipart: 'yes'}}).pipe(shareReplay());
  }
}

import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {LocaleService} from '../../services/locale.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lecture-abstract',
  templateUrl: './lecture-abstract.component.html',
  styleUrls: ['./lecture-abstract.component.scss']
})
export class LectureAbstractComponent implements OnInit {
  lecture: any;
  locale: any;

  constructor(
    public translationService: TranslationService,
    private localeService: LocaleService,
    public activeModal: NgbActiveModal) {
    this.locale = this.localeService.currentLocale();
  }

  ngOnInit() {
  }

}

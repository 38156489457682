<div [className]="!isSubcomment ? 'post comment' : 'post'">
    <div class="media post-content">
        <div class="user-info">
            <div class="post-thumb" *ngIf="comment.user_info"
                 [routerLink]="'/profile/' + comment.user_info?.slug"
                 [style.background]="'url(' + comment.user_info.picture_url + ')'"
                 [style.background-size]="'cover'">
            </div>
            <div>
                <img class="post-thumb" *ngIf="!comment.user_info"
                     src="https://comexposium-healthcare-assets.s3.eu-west-1.amazonaws.com/users/default/man_doctor.png"
                     alt="">
            </div>
            <div class="badges big-screens" *ngIf="comment.user_badges && comment.user_badges.length > 0">
                <div class="badge-wrapper" *ngFor="let badge of comment.user_badges | slice:0:3;">
                    <app-badge
                            [badge]="badge"
                            [size]="1.5"
                            [tooltipMsg]="badge.title"
                    ></app-badge>
                </div>
            </div>
            <div class="post-author-mobile">
                <h6><span *ngIf="!comment.user_info">{{ 'abstract.Form_author'|translate }}</span><span
                        *ngIf="comment.user_info">{{ comment.user_info.title + ' ' + comment.user_info.first_name + ' ' + comment.user_info.last_name }}</span>
                </h6>
                <p *ngIf="comment.user_info">
                  <span *ngIf="comment.user_info.specialty">{{ translationService.getTranslatedName(comment.user_info.specialty.translations, locale) }}</span>
                  <span *ngIf="comment.user_info.country">, {{ translationService.getTranslatedName(comment.user_info.country.translations, locale) }}</span>
                </p>
                <div class="badges" *ngIf="comment.user_badges && comment.user_badges.length > 0">
                    <div class="badge-wrapper" *ngFor="let badge of comment.user_badges | slice:0:3;">
                        <app-badge
                                [badge]="badge"
                                [size]="1.5"
                                [tooltipMsg]="badge.title"
                        ></app-badge>
                    </div>
                </div>
            </div>
        </div>
        <div class="media-body">
            <div class="post-author">
                <h6>
                <span *ngIf="!comment.user_info">
                    {{ 'abstract.Form_author'|translate }}
                </span>
                    <span *ngIf="comment.user_info">
                    {{ comment.user_info.title + ' ' + comment.user_info.first_name + ' ' + comment.user_info.last_name }}
                </span>
                </h6>
                <p *ngIf="comment.user_info">
                  <span *ngIf="comment.user_info.specialty">{{ translationService.getTranslatedName(comment.user_info.specialty.translations, locale) }}</span>
                    <span *ngIf="comment.user_info.country">, {{ translationService.getTranslatedName(comment.user_info.country.translations, locale) }}</span>
                </p>
            </div>
            <div class="post-date text-right">
                <p>{{ dateMomentService.getFullDateTime(comment.created_at, locale) }}</p>
                <p *ngIf="comment.edited_at" class="edit-date">{{ 'alert.Edited' | translate }} {{dateMomentService.getFullDateTime(comment.edited_at, locale) }}</p>
            </div>
            <div class="post-badge" *ngIf="isWrapup">
              <span class="badge badge-success badge-coordinator">
                <i class="fa fa-check"></i>
                  {{ 'alert.Case_wrapup'|translate }}
              </span>
            </div>
            <div class="post-text">
                <p *ngIf="!comment.edit_comment_mode" [class]="comment.user_info && comment.user_info.coordinator ? 'comment-bold' : ''"
                   [innerHTML]="comment.urlifyContent ()"></p>
                <textarea class="alert-textarea" *ngIf="comment.edit_comment_mode" [(ngModel)]="newComment"></textarea>
                <div  *ngIf="comment.edit_comment_mode"  class="post-actions">
                    <button class="button button-orange-outline mr-2" (click)="cancelEditComment(comment)">{{'alert.cancel' | translate}}</button>
                    <button class="button button-orange" (click)="editComment(comment)">{{'alert.edit' | translate}}</button>
                </div>
            </div>
            <div class="post-medias d-flex flex-column" *ngIf="!comment?.edit_files_mode && comment?.pictures && comment?.pictures.length > 0">
                <ng-container *ngFor="let file of comment?.pictures">
                    <ng-container *ngIf="(!isPicture(file) && !isVideo(file.title)) || isHeic(file.title)">
                        <a *ngIf="!isVideo(file.title)" [href]="file.location"> <span><i class="fas fa-file"></i> {{file.title}}</span></a>
                    </ng-container>
                </ng-container>
            </div>
            <div class="post-medias" *ngIf="!comment?.edit_files_mode && comment?.videos && comment?.videos.length > 0">
                <ng-container *ngFor="let file of comment?.videos">
                    <video *ngIf="isVideo(file.file_name)" controlsList="nodownload" controls
                           class="img-responsive" [src]="file.url"></video>
                </ng-container>
            </div>
            <div class="post-medias" *ngIf="!comment?.edit_files_mode && comment?.pictures && comment?.pictures.length > 0">
                <a *ngFor="let picture of comment?.pictures" class="thumbnail fancybox slide"
                   [attr.rel]="'gallery_'+comment.id" [href]="picture.location">
                    <ng-container *ngIf="isPicture(picture)">
                        <img class="img-responsive" [src]="picture.location" [title]="picture.title"
                             alt=""/>
                    </ng-container>
                </a>
            </div>
            <div class="post-medias d-flex flex-column" *ngIf="comment.edit_files_mode">
                <p class="text-secondary" style="font-size: 13px; font-weight: bold;">{{'alert.accepted_format' | translate}} - {{'alert.max_medias' | translate}}</p>
                <div class="post-actions-files" *ngIf="newCommentFiles.length < 5">
                    <label *ngIf="(newCommentFiles.length + filesToUpload.length) < 5">
                        <input (change)="commentFilesUpload($event)" type="file" [accept]="acceptedFormat"
                               class="custom-file-input" style="display: none;" multiple>
                        <a class="button button-blue text-white" ><i class="fas fa-plus-circle" style="font-size: 20px; cursor:pointer"></i> {{ 'alert.add_files' | translate }}</a>
                    </label>
                    <button class="button button-orange" [class.ml-2]="!environment.mobile" (click)="uploadFiles(comment)">{{'alert.submit_files' | translate}}</button>
                </div>
                <ng-container *ngIf="filesToUpload && filesToUpload.length > 0 && filesToUpload !== 'Error'">
                    <div class="d-flex">
                        <div class="d-flex flex-column align-items-start">
                  <span class="badge badge-success m-1" *ngFor="let media of filesToUpload; let index = index;">
                    {{ media.text }}
                      <i class="fa fa-times text-white" style="font-size: 15px" (click)="removeFromList(index, true)"></i>
                  </span>
                        </div>
                    </div>
                </ng-container>
                <span *ngIf="filesError === 'Error'" class="badge badge-danger">
                        {{ ('alert.Type_of_file_error'|translate) + ' : .' + extension + ' Not supported'}}
                  </span>
                <span *ngIf="filesError === 'Max'" class="badge badge-danger">
                         {{'alert.max_files_reached' | translate}} - {{'alert.max_medias' | translate}}
                  </span>
                <span *ngIf="filesError === 'Size'" class="badge badge-danger">
                       {{fileName}} {{'alert.max_files_reached' | translate}} - {{'alert.max_files_size' | translate}}
                  </span>
                <span *ngIf="filesError === 'Back'" class="badge badge-danger my-1">
                    {{'alert.Something_went_wrong' | translate}}
                </span>
              <span *ngIf="filesError === 'Max2'" class="badge badge-danger">
                         {{'alert.max_files_reached' | translate}} - {{'alert.max_medias' | translate}} ({{'alert.delete_for_space' | translate}})
                  </span>
            </div>
            <div class="post-medias courses" *ngIf="comment.edit_files_mode">
                <!--IF FILE-->
                <ng-container *ngIf="displayedDocumentFiles && displayedDocumentFiles.length > 0">
                  <div class="d-flex flex-column" style="margin-bottom: 3rem;">
                    <ng-container *ngFor="let file of displayedDocumentFiles; let index = index;">
                      <div>
                        <a [href]="file.location"><span><i class="fas fa-file"></i> {{file.title}}</span></a>
                        <i  class="fas fa-times-circle ml-2 text-danger delete-picture" style="font-size: 20px; z-index:700; cursor: pointer" (click)="removeFile(file.id, comment, true,true)"></i>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="displayedFiles && displayedFiles.length > 0">
                  <app-loader *ngIf="loadingFiles"></app-loader>
                    <div class="d-flex flex-column align-items-center" *ngIf="!loadingFiles">
                        <div class="row pictures-slider-for" [style.width]="environment.mobile ? '100%' : '750px'" [style.margin-bottom.rem]="3">
                            <div class="d-flex align-items-start justify-content-center"  *ngFor="let file of displayedFiles; let index= index">
                                <ng-container *ngIf="isPicture(file)">
                                    <img class="img-responsive"[src]="file.location" [title]="file.title"
                                         alt=""/>
                                    <i class="fas fa-times-circle text-danger delete-picture" style="font-size: 30px; z-index:700; cursor: pointer" (click)="removeFile(file.id, comment)"></i>
                                </ng-container>
                                <ng-container *ngIf="isVideo(file.title)">
                                    <video controlsList="nodownload" controls
                                           class="img-responsive" [src]="file.location"></video>
                                    <i class="fas fa-times-circle text-danger delete-picture" style="font-size: 30px; z-index:700; cursor: pointer" (click)="removeFile(file.id, comment, false)"></i>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row pictures-slider-nav"  [style.width.px]="environment.mobile ? 150: 550">
                            <div class="d-flex justify-content-center align-items-center picture-block"  *ngFor="let file of displayedFiles; let index= index">
                                <ng-container *ngIf="isPicture(file)">
                                    <img class="img-responsive" style="cursor: pointer"  width="150px"  [src]="file.location" [title]="file.title"
                                         alt=""/>
                                </ng-container>
                                <ng-container *ngIf="isVideo(file.title)">
                                    <div style="cursor:pointer;width: 150px; height: 150px; position: absolute; z-index: 999">
                                    </div>
                                    <video controlsList="nodownload" controls style="width: 150px"
                                           class="img-responsive" [src]="file.location"></video>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="post-actions">
            <button *ngIf="(comment.edit_comment_mode || comment.edit_files_mode) && alreadyInEditMode" class="button button-red mr-4" (click)="quitEditMode(comment)">{{'alert.quit_edit' | translate}}</button>
            <i *ngIf="(comment.can_be_edited$ | async) && !alreadyInEditMode && case.open" class="fas fa-paperclip mr-3 is-bigger-on-mobile" style="color: #DC6A00; cursor:pointer" (click)="canEditFile(comment)" [ngbTooltip]="'alert.update_files' | translate"></i>
            <i (click)="canEditComment(comment)" *ngIf="(comment.can_be_edited$ | async) && !alreadyInEditMode && case.open" class="fas fa-edit is-bigger-on-mobile mr-4"  style="color: #DC6A00; cursor:pointer"  [ngbTooltip]="'alert.edit_comment'|translate"></i>
            <like-button
              [likeableType]="'comment'"
              [likeableId]="comment.id"
              [currentUser]="currentUser"
            ></like-button>
            <a *ngIf="canReply && !isWrapup && !isSubcomment && !alreadyInEditMode" (click)="replyTo(comment)" class="link">{{
              'alert.Reply_answer'|translate }}</a>
          </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>

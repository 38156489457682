import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ReportStatistics} from '../models/report-statistics';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportStatisticsService {

  private endpoint: string = '/report_statistics';

  constructor(private http: HttpClient) { }

  public fetchReportStats(): Observable<ReportStatistics> {
    return this.http.get<ReportStatistics>(environment.apiURL + this.endpoint);
  }
}

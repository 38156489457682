<div class="page partners">
    <app-section-top [title]="'premium.IMCAS_Explore'" [subtitle]="'academy.Discover_the_world'|translate"
                     [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-explore.jpg'"></app-section-top>

    <div class="content">
        <div class="page-content">
            <div class="page-introduction">
                <h3>{{ 'attend.Scientific_Partners'|translate }}</h3>
                <p>{{ 'explore.Partners_introduction'|translate }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill">
                <li class="nav-item" *ngIf="societies_list.length > 0">
                    <a class="nav-link" [routerLink]="['/explore', 'partners', 'societies']"
                       [class.active]="nav_location=='societies'">{{ 'academy.Scientific_societies'|translate }}</a>
                </li>
                <li class="nav-item" *ngIf="journals_list.length > 0">
                    <a class="nav-link" [routerLink]="['/explore', 'partners', 'journals']"
                       [class.active]="nav_location=='journals'">{{ 'academy.Publications'|translate }}</a>
                </li>
                <li class="nav-item" *ngIf="magazines_list.length > 0">
                    <a class="nav-link" [routerLink]="['/explore', 'partners', 'magazines']"
                       [class.active]="nav_location=='magazines'">{{ 'explore.Magazines'|translate }}</a>
                </li>
            </ul>
            <div class="society container" *ngIf="nav_location=='societies' && societies_list.length > 0">
                <div class="society-item row" *ngFor="let society of societies_list">
                    <div class="society-image col-sm-4">
                        <img [src]="society.logo_url" [alt]="society.acronym">
                    </div>
                    <div class="col-sm-8">
                        <div class="society-title">
                            <h5>{{ society.name }}</h5>
                        </div>
                        <div class="society-action">
                            <a *ngIf="society.website" [href]="society.website" target="_blank"
                               class="society-action-link">
                                {{society.website}}
                            </a>
                            <a *ngIf="society.facebook" [href]="society.facebook" target="_blank"
                               class="society-action-link">
                                {{ society.facebook }}
                            </a>
                            <span class="link" (click)="open(societydetailed, society)">{{ 'company_profile.More_info'|translate }}</span>
                        </div>
                    </div>
                    <ng-template #societydetailed let-c="close">
                      <div class="modal-body modal-no-title modal-wide">
                        <button type="button" class="close" (click)="c('closed')" aria-label="Close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <div class="society-details">
                            <div class="society-details-head">
                                <h3>{{ society.name }}</h3>

                            </div>
                            <div class="society-details-info">
                                <div class="row">
                                    <div class="col-md-3">
                                        <img src="{{ society.logo_url }}" alt="">
                                    </div>
                                    <div class="col-md-9">
                                      <p *ngIf="society.translations.length > 0">{{
                                        translationService.getTranslatedAttribute('content', society.translations, locale)
                                        }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="society-details-button">
                                <a [href]="society.website" target="_blank" class="button button-orange">{{
                                    'explore.Go_to_link'|translate }}</a>
                            </div>
                        </div>
                      </div>
                    </ng-template>
                </div>
            </div>
            <div class="journal" *ngIf="nav_location=='journals' && journals_list.length > 0">
                <div class="journal-item" *ngFor="let journal of journals_list">
                    <div class="row">
                        <div class="journal-image col-md-3 align-self-center">
                            <img [src]="journal.logo_url" alt="">
                        </div>
                        <div class="journal-content col-md-9" *ngIf="journal && journal.editor != null">
                            <h3>{{ journal.name }}</h3>
                            <p>
                              {{ 'explore.Edited_by'|translate }}
                              <span>{{ journal.editor.title + ' ' + journal.editor.first_name + ' ' + journal.editor.last_name }}</span>
                                - {{translationService.getTranslatedName(journal.editor.specialty.translations, locale)}}
                              ({{translationService.getTranslatedName(journal.editor.country.translations, locale)}})
                              <ng-container *ngIf="journal && journal.editor2 != null">
                                {{ 'academy.And'|translate }}
                                <span>{{ journal.editor2.title + ' ' + journal.editor2.first_name + ' ' + journal.editor2.last_name }}</span>
                                - {{translationService.getTranslatedName(journal.editor2.specialty.translations, locale)}}
                                ({{translationService.getTranslatedName(journal.editor2.country.translations, locale)}})
                              </ng-container>
                            </p>
                            <p>{{ 'explore.Published_by'|translate }} <span>{{ journal.publisher }}</span></p>
                            <a target="_blank" [href]="journal.website" class="link">{{ journal.website }}</a>
                            <span class="link" (click)="open(journaldetailed, journal)">{{ 'company_profile.More_info'|translate }}</span>
                        </div>
                    </div>
                    <ng-template #journaldetailed let-c="close">
                      <div class="modal-body modal-no-title modal-wide">
                        <button type="button" class="close" (click)="c('closed')" aria-label="Close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <div class="journal-details">
                            <div class="journal-details-info">
                                <div class="row">
                                    <div class="col-md-3">
                                      <h3>{{ journal.name }}</h3>
                                      <img src="{{ journal.logo_url }}" alt="">
                                    </div>
                                    <div class="journal-content col-md-9" *ngIf="journal && journal.editor != null">
                                      <p>
                                        {{ 'explore.Edited_by'|translate }} <span>{{ journal.editor.title + ' ' + journal.editor.first_name + ' ' + journal.editor.last_name }}</span>
                                          - {{translationService.getTranslatedName(journal.editor.specialty.translations, locale)}}
                                        ({{translationService.getTranslatedName(journal.editor.country.translations, locale) }})
                                        <ng-container *ngIf="journal && journal.editor2 != null">
                                          {{ 'academy.And'|translate }}
                                          <span>{{ journal.editor2.title + ' ' + journal.editor2.first_name + ' ' + journal.editor2.last_name }}</span>
                                          - {{translationService.getTranslatedName(journal.editor2.specialty.translations, locale)}}
                                          ({{translationService.getTranslatedName(journal.editor2.country.translations, locale)}})
                                        </ng-container>
                                      </p>
                                      <p>{{ 'explore.Published_by'|translate }} <span>{{ journal.publisher }}</span></p>
                                      <a target="_blank" [href]="journal.website" class="link">{{ journal.website }}</a>
                                      <div class="journal-description" [innerHTML]="journal.description"></div>

                                  </div>

                                </div>
                            </div>
                            <div class="journal-details-button">
                                <a [href]="journal.website" target="_blank" class="button button-orange">{{
                                    'explore.Go_to_link'|translate }}</a>
                            </div>
                        </div>
                      </div>
                    </ng-template>
                </div>
            </div>
            <div class="magazine" *ngIf="nav_location=='magazines' && magazines_list.length > 0">
                <ng-template #magazinedetailed let-c="close">
                    <div class="modal-body modal-no-title modal-wide">
                        <button type="button" class="close" (click)="c('closed')" aria-label="Close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <div class="magazine-details">
                            <div class="magazine-details-head">
                                <h3>{{ magazine.name }}</h3>
                                <p *ngIf="magazine.editor">{{
                                    translationService.getTranslatedName(magazine.editor.country.translations, locale)
                                    }}</p>
                            </div>
                            <div class="magazine-details-info">
                                <div class="row">
                                    <div class="col-md-3">
                                        <img src="{{ magazine.logo_url }}" alt="">
                                    </div>
                                    <div class="col-md-9">
                                        <p *ngIf="magazine.editor">
                                          {{ 'explore.Edited_by'|translate }}
                                          <a
                                            [routerLink]="['/profile', magazine.editor.slug]"
                                            target="_blank"
                                          >
                                            {{magazine.editor.first_name + ' ' +
                                            magazine.editor.last_name }}
                                          </a>
                                          -
                                          {{ translationService.getTranslatedName(magazine.editor.specialty.translations, locale)}}
                                          ({{ translationService.getTranslatedName(magazine.editor.country.translations, locale) }})
                                          <ng-container *ngIf="magazine && magazine.editor2 != null">
                                            {{ 'academy.And'|translate }}
                                            <span>{{ magazine.editor2.title + ' ' + magazine.editor2.first_name + ' ' + magazine.editor2.last_name }}</span>
                                            - {{translationService.getTranslatedName(magazine.editor2.specialty.translations, locale)}}
                                            ({{translationService.getTranslatedName(magazine.editor2.country.translations, locale)}})
                                          </ng-container>
                                        </p>
                                        <p>{{ 'explore.Published_by'|translate }} <span>{{ magazine.publisher }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="magazine-details-desc">
                                <p [innerHTML]="magazine.description"></p>
                            </div>
                            <div class="magazine-details-button">
                                <a [href]="magazine.website" target="_blank" class="button button-orange">{{
                                    'explore.Go_to_link'|translate }}</a>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="magazine-item" *ngFor="let magazine of magazines_list">
                    <div class="row">
                        <div class="magazine-image col-md-3">
                            <img [src]="magazine.logo_url" alt="">
                        </div>
                        <div class="magazine-content col-md-9">
                            <h4><a target="_blank" [href]="magazine.website">{{magazine.name}}</a></h4>
                            <p *ngIf="magazine.editor">{{ 'explore.Edited_by'|translate }}
                                <!--<a [routerLink]="['/profile', magazine.editor.slug]"-->
                                   <!--target="_blank" class="highlight">-->
                                    <!--{{magazine.editor.first_name + ' ' + magazine.editor.last_name}}-->
                                <!--</a>-->
                                <span>
                                    {{magazine.editor.first_name + ' ' + magazine.editor.last_name}}
                                </span> -
                                {{translationService.getTranslatedName(magazine.editor.specialty.translations, locale)}}
                              <span *ngIf="magazine.editor.country">({{ translationService.getTranslatedName(magazine.editor.country.translations, locale)}})</span>
                              <ng-container *ngIf="magazine && magazine.editor2 != null">
                                {{ 'academy.And'|translate }}
                                <span>{{ magazine.editor2.title + ' ' + magazine.editor2.first_name + ' ' + magazine.editor2.last_name }}</span>
                                - {{translationService.getTranslatedName(magazine.editor2.specialty.translations, locale)}}
                                ({{translationService.getTranslatedName(magazine.editor2.country.translations, locale)}})
                              </ng-container>
                            </p>
                            <p>{{ 'explore.Published_by'|translate }} <span>{{ magazine.publisher }}</span></p>
                            <a [href]="magazine.website" class="link" target="_blank">{{ magazine.website }}</a>
                            <span class="link" (click)="open(magazinedetailed, magazine)">{{ 'company_profile.More_info'|translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

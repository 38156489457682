import {DateMomentService} from 'app/services/date-moment.service';
import {MediaService} from 'app/services/media.service';
import {ThemeService} from 'app/services/theme.service';
import {UserService} from 'app/services/user.service';
import {environment} from '../../../../environments/environment';
import {TranslationService} from 'app/services/translation.service';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {forkJoin} from 'rxjs';
import {CourseService} from 'app/services/course.service';
import {Course} from 'app/models/course.model';
import {Theme} from 'app/models/theme.model';
import {User} from 'app/models/user.model';
import {CongressService} from 'app/services/congress.service';
import * as $ from 'jquery';
import 'slick-carousel';
import {LocaleService} from '../../../services/locale.service';
import {Session} from '../../../models/session.model';
import {AuthService} from '../../../services/auth.service';
import {AcademyMedia} from '../../../models/academy-media.model';
import {map} from 'rxjs/operators';
import {timer} from 'rxjs/index';
import {TranslateService} from '@ngx-translate/core';
import {HistoryService} from "../../../services/mobile-services/history.service";

@Component({
    selector: 'app-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss'],
    providers: [
        CourseService,
        UserService,
        ThemeService,
        CongressService,
        MediaService
    ]
})
export class CourseComponent implements OnInit {
    mediasToRate: AcademyMedia[] = [];
    user: User;
    theme: Theme;
    related: any;
    sessions: any;
    locale: any;
    course: Course;
    showMoreEducationalObjectives: boolean = false;
    showMoreSummary: boolean = false;
    coursesRelated: Course[];
    id: number;
    environment: any = environment;
    moment: any = moment;
    loadingAcademyMedia: boolean;
    showSocialItems: any;
    answers: any[] = [];
    errorSurvey: string = '';
    certificateValidated: boolean = false;
    favorited: boolean = false;
    showFavorited: boolean = false;
    userToken: any;
    @ViewChild('surveyModal', {static: false}) surveyModal: ElementRef;
    @ViewChild('content', {static: false}) content: ElementRef;

    constructor(private activatedRoute: ActivatedRoute,
                public translationService: TranslationService,
                private router: Router,
                private modalService: NgbModal,
                private courseService: CourseService,
                private userService: UserService,
                private authService: AuthService,
                private themeService: ThemeService,
                private congressService: CongressService,
                public mediaService: MediaService,
                private localeService: LocaleService,
                public dateMomentService: DateMomentService,
                private translateService: TranslateService,
                private historyService: HistoryService) {
    }

    downloadPDF(id) {
        const url = this.environment.new_cdn_base_url + '/sessions/pdf_details/en_' + id + '.pdf';
        window.open(url);
    }

    ngOnInit() {
        this.loadingAcademyMedia = true;
        this.locale = this.localeService.currentLocale();
        this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.getCourse();
            this.getCourseSessions();
        });
        this.userToken = this.authService.getCurrentUser().personal_token;
    }

    getCourse() {
        const sliderSlick = $('.academy-slider');
        if (sliderSlick.hasClass('slick-initialized')) {
            sliderSlick.slick('unslick');
        }
        this.coursesRelated = [];
        this.courseService.getCourse(this.id, {course_sponsor: 1, social_network: 1, course_surveys: 1}).subscribe(
            course => {
                this.certificateValidated = course.certificate_validated;
                this.course = new Course(course);
                if (this.course.sponsorAndGrant) {
                  this.course.sponsorAndGrant = this.course.sponsorAndGrant.filter((e, i) =>
                    this.course.sponsorAndGrant.indexOf(this.course.sponsorAndGrant.find((y) => y.id === e.id)) === i
                  );
                }
                this.favorited = this.course.favorite
                this.mediaService.getAcademyMedias({
                    ids: course.academy_media_ids.join(','),
                    all: 1,
                    user_country: 1,
                    user_specialty: 1,
                    user_media_ids: 0,
                    media_cdn: 0,
                    comments_auth: 1,
                    academy_media_course: 0,
                    order_by: 'rank',
                    media_lecture: 1,
                    lecture_user: 0,
                    academy_media_keywords: 0,
                    academy_media_related: 0
                }).subscribe(
                    academyMedias => {
                        this.loadingAcademyMedia = false;
                        this.course.academy_medias = [];
                        academyMedias.forEach(n => {
                            const academyMediaObj: AcademyMedia = new AcademyMedia(n);
                            this.course.academy_medias.push(academyMediaObj);
                        });
                        this.course.academy_medias = this.course.academy_medias.filter((academy_media, index, self) =>
                            index === self.findIndex((t) => (
                                t.id === academy_media.id
                            ))
                        ).sort((a, b) => (a.rank > b.rank) ? 1 : 0);
                        this.getUser();
                    }
                );
                this.courseService.getCourses({ids: course.related.join(','), course_sponsor: 1}).subscribe(
                    related => {
                        related = related['data'];
                        related.forEach(n => {
                            const courseObj: Course = new Course(n);
                            this.coursesRelated.push(courseObj);
                        });
                        setTimeout(() => {
                            const jqueryCourses = $('.course-related');
                            if (jqueryCourses.length > 0) {
                                jqueryCourses.not('.slick-initialized').slick({
                                    infinite: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                    autoplay: false,
                                    dots: true,
                                    prevArrow: '',
                                    nextArrow: '',
                                });
                            }
                        }, 50);
                    }
                );
                this.getTheme();
            });
    }

    getCourseSessions() {
        this.courseService.getCourseSessions(this.id)
            .pipe(map(courseSessions => courseSessions.sort(function (a, b) {
                return (a.date_begin > b.date_begin) ? 1 : ((b.date_begin > a.date_begin) ? -1 : 0);
            }).slice(0, 3)))
            .subscribe(
                courseSessions => {
                    this.sessions = [];
                    courseSessions.forEach(n => {
                        this.sessions.push(new Session(n));
                    });
                });
    }

    openModalCertificate() {
        if (this.course.percentage_viewed === 100) {
            if (this.course.mcq && this.course.surveys && this.course.surveys.length > 0) {
                this.open(this.surveyModal);
            } else if (
                !this.course.cme_accredited &&
                this.course.mcq &&
                this.course.surveys &&
                this.course.surveys.length === 0
            ) {
                this.open(this.content);
            } else if (!this.course.cme_accredited) {
              this.open(this.content);
            }
        }
    }

    downloadCertificate() {
      window.open(environment.base_url + '/academy/course/' + this.course.id + '/certificate/' + this.userToken, '_blank').focus();
    }

    getUser() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {courses_favorited: 1}).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                    this.course.favorite = this.course.isEnrolled(this.user);
                    this.activatedRoute.queryParams.subscribe(params => {
                        if (params['modal'] === 'certificate') {
                            this.openModalCertificate()
                        }
                    });
                });
        }
    }

    getTheme() {
        if (this.course.themes_ids[0]) {
            this.themeService.getTheme(this.course.themes_ids[0]).subscribe(
                theme => {
                    this.theme = theme;
                });
        }
    }

    goToCourse(course) {
        this.router.navigateByUrl('/course/' + course.id + '/' + course.slug);
    }

    addCourseToFavorite(course) {
        const id = course.id;
        if (this.user && this.user.id) {
            this.courseService.addCourseToFavorite(id).subscribe(
                (favorited) => {
                    this.favorited = favorited.favorited
                    this.showFavorited = true
                    const timerfFavorited = timer(3000).subscribe(() => {
                        this.showFavorited = false
                        timerfFavorited.unsubscribe()
                    })
                }
            );
        } else {
            this.router.navigate(['/', 'account', 'login']);
        }
    }

    getMediasToRate(academy_medias) {
        const mediasToRate = [];
        if (academy_medias) {
            for (let i = 0; i < academy_medias.length; ++i) {
                if (academy_medias[i].user_auth.length === 0 ||
                    (academy_medias[i].user_auth[0].pivot && !academy_medias[i].user_auth[0].pivot.rate) ||
                    (academy_medias[i].comments_auth && academy_medias[i].comments_auth.length === 0)) {
                    if (academy_medias[i].user_auth[0] && academy_medias[i].user_auth[0].pivot.rate) {
                        academy_medias[i].media.currentRate = academy_medias[i].user_auth[0].pivot.rate;
                    } else {
                        academy_medias[i].media.currentRate = null;
                    }
                    if (academy_medias[i].comments_auth && academy_medias[i].comments_auth.length !== 0) {
                        academy_medias[i].media.comment = academy_medias[i].comments_auth[0].content;
                    } else {
                        academy_medias[i].media.currentRate = null;
                    }
                    mediasToRate.push(academy_medias[i]);
                }
            }
        }
        return mediasToRate;
    }

    openAbstract(content) {
        this.modalService.open(content);
    }

    changeAnswer(answerId) {
        if (this.answers.indexOf(answerId) > -1) {
            delete this.answers[this.answers.indexOf(answerId)];
        } else {
            this.answers.push(answerId)
        }
    }

    checkAnswers() {
        this.errorSurvey = '';
        this.courseService.validateAnswersSurvey(this.course.id, this.answers.filter(Number)).subscribe(res => {
            if (res.success) {
                this.certificateValidated = true;
            } else {
                if (this.course.cme_accredited) {
                    this.errorSurvey = 'Some questions are wrong, please correct them to validate your CME.';
                } else {
                    this.errorSurvey = 'Some questions are wrong, please correct them to get your certificate of completion.';
                }
                this.errorSurvey += ' If you believe something is wrong, please contact support@imcas.com with the course ID: '
                    + this.course.id;
            }
        });
    }

    open(content) {
        if (this.user && this.user.id) {
            const ngbModalOptions: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false
            };
            if (this.course && this.course.academy_medias && this.course.surveys.length === 0) {
                this.mediasToRate = this.getMediasToRate(this.course.academy_medias);
                if (this.mediasToRate.length === 0) {
                    window.location.href = this.course.certificate_url ?
                        environment.base_url + '/' + this.course.certificate_url :
                        environment.base_url + '/academy/courses/certificate/' + this.id;
                } else {
                    this.modalService.open(content, ngbModalOptions).result.then((mediasToRate) => {
                        const oArray = [];
                        for (let i = 0; i < this.mediasToRate.length; ++i) {
                            oArray.push(this.mediaService.addMediaComment(this.mediasToRate[i].id, this.mediasToRate[i].media.comment));
                            oArray.push(this.mediaService.addMediaRate(this.mediasToRate[i].id, this.mediasToRate[i].media.currentRate));
                        }
                        forkJoin(oArray)
                            .subscribe(t => {
                                window.location.href = this.course.certificate_url ?
                                    environment.base_url + '/' + this.course.certificate_url :
                                    environment.base_url + '/academy/courses/certificate/' + this.id;
                            });
                    }, error => {
                    });
                }
            } else if (this.course.surveys.length > 0) {
                this.modalService.open(content, ngbModalOptions).result.then((answers) => {
                }, error => {
                });
            }
        } else {
            this.router.navigate(['/', 'account', 'login']);
        }
    }


    isNew(course) {
        if (!course.date) {
            course.date = course.publication_date;
        }
        return this.dateMomentService.isNew(course.date);
    }

    getTag(academyMedia) {
        if (academyMedia.isSponsored()) {
            return {class: 'sponsored', label: this.translateService.instant('academy.Sponsored')}
        }
        if (academyMedia.isFree() && !academyMedia.isWebinar() && (!this.user || (this.user && !this.user.premium_membership))) {
            return {class: 'free', label: this.translateService.instant('academy.Free')}
        }
        if (academyMedia.isFree() && academyMedia.isWebinar() && (this.user && this.user.premium_membership)) {
            return {class: 'free', label: this.translateService.instant('sponsor.Family_Webinar')}
        }
        if (academyMedia.isFree() && academyMedia.isWebinar() && (!this.user || (this.user && !this.user.premium_membership))) {
            return {class: 'free', label: this.translateService.instant('academy.Free_webinar')}
        }
        if (this.isNew(academyMedia) && !academyMedia.getMedia().isViewed(this.user)) {
            return {class: 'new', label: this.translateService.instant('academy.New')}
        }
        if (academyMedia.mcq === true) {
            return {class: 'certificate', label: this.translateService.instant('account.Certificate')}
        }
        if (academyMedia.user_auth && academyMedia.user_auth[0] && academyMedia.user_auth[0].pivot.favorite === 1) {
            return {class: 'sponsored', label: this.translateService.instant('academy.Enrolled')}
        }

        if (academyMedia.type === 'demonstration') {
            return {class: 'free', label: 'Demonstration'}
        }

    }

  goBack() {
    if (!environment.mobile) {
      this.router.navigateByUrl('/library/courses');
    } else {
      this.historyService.goBack();
    }
  }
}

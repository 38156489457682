import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';
import {NotificationService} from '../../../../services/notification.service';
import {LinkService} from '../../../../services/link.service';

@Component({
  selector: 'notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  providers: [
    TranslationService,
    UserService,
    NotificationService
  ]
})
export class NotificationsListComponent implements OnInit {
  user: User;
  locale: string;
  currentTab: string = 'pending';
  notifications = [];

  constructor(private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private authService: AuthService,
              private router: Router,
              private linkService: LinkService,
              private localeService: LocaleService,
              public translationService: TranslationService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    this.user = this.authService.getCurrentUser();


    this.notificationService.getNotifications().subscribe(
      notifications => {
        this.notifications = notifications;
        this.notifications.forEach(notification => {
          notification.url = notification.url.replace(/(https:\/\/www\.imcas\.com(\/[a-z]+)*)?\/academy/i, '');
          notification.url = notification.url.replace(/(https:\/\/www\.aop-academy\.com(\/[a-z]+)*)?\/academy/i, '');

          if (notification.url.indexOf('https://') !== -1 || notification.url.indexOf('http://') !== -1) {
            notification.absolute = true;
          } else {
            notification.absolute = false;
          }
        });
      });
  }

  openNotificationUrl(notification) {
    if (notification.absolute) {
      this.linkService.openBlank(notification.url);
    } else {
      this.router.navigateByUrl(notification.url);
    }
  }

}

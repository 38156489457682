import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslationService} from 'app/services/translation.service';
import {environment} from '../../../environments/environment';
import {LocaleService} from 'app/services/locale.service';


@Component({
    selector: 'app-corporate-funding-modal',
    templateUrl: './corporate-funding-modal.component.html',
    styleUrls: ['./corporate-funding-modal.component.css'],
    providers: [
        LocaleService
    ]
})
export class CorporateFundingModalComponent {
    locale: any;
    @Input() academyMedia: any;
    environment: any = environment;

    constructor(public activeModal: NgbActiveModal,
                public translationService: TranslationService,
                public localeService: LocaleService) {
        this.locale = localeService.currentLocale();
    }
}

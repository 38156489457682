import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PollService} from '../../services/poll.service';

interface Poll {
    question: string;
    id: number;
    choices: Choice[];
    answers: any[];
}

interface Choice {
    content: string;
    id: number;
}


@Component({
    selector: 'app-webinar-poll',
    templateUrl: './webinar-poll.component.html',
    styleUrls: ['./webinar-poll.component.scss']
})
export class WebinarPollComponent implements OnInit, OnDestroy {

    @Input() sessionId: number;
    @Output() hasPoll = new EventEmitter<boolean>();
    pollData: any[] = [];
    currentPoll: Poll;
    currentPollChoice: number;
    interval: any;
    currentPollStats: any;
    showQuestion: boolean = false;
    showStats: boolean = false;
    showLoading: boolean = false;

    // chartOptions: any = {
    //     pieHole: 0.4,
    //     legend: {position: 'top', alignment: 'center'},
    //     chartArea: {top: '20%', left: 5, width: '90%', height: '80%'},
    //     colors: [
    //         'rgb(37, 5, 83)',
    //         'rgb(250, 119, 50)',
    //         'rgb(240, 173, 138)',
    //         'rgb(255, 219, 74)',
    //     ],
    //     height: 250,
    //     width: 300
    //
    // }

    constructor(private pollService: PollService) {
    }

    ngOnInit() {
    }

    answerPoll(id) {
        const choice = this.pollData.find(p => p.id === id).response
        if (!choice) {
            return;
        }
        this.pollService.answerPoll(id, choice).subscribe(
            poll => {
                this.managePollResponse(poll)
            }
        );
    }

    fetchCurrentPoll() {
        if (this.sessionId !== 0) {
            this.pollService.getPoll(this.sessionId).subscribe(
                poll => {
                    this.managePollResponse(poll);
                    this.showLoading = false;
                }
            );
        }
    }

    managePollResponse(poll) {
        if (poll.length !== this.pollData.length) {
            this.pollData = []
        }
        if (poll.length) {
            this.hasPoll.emit(true)
            poll.forEach(p => {
                const pollQuestion = this.pollData.findIndex(pdata => pdata.question === p.question)
                if (pollQuestion !== -1) {
                    if (p.answers) {
                        this.pollData[pollQuestion].answers = this.createStatisticsData(p);
                    } else {

                        this.pollData[pollQuestion] = JSON.parse(JSON.stringify(p));
                    }

                } else {
                    if (p.answers) {
                        p.answers = this.createStatisticsData(p);
                    }
                    this.pollData.push(JSON.parse(JSON.stringify(p)))
                }
            });

        } else {
            this.hasPoll.emit(false);
            this.showLoading = true;
        }
    }

    createStatisticsData(rawData): any[] {
        return rawData.answers.map((answer) => {
            return [answer.content, answer.answers_count, Math.round(answer.answers_count / rawData.total_answers_count * 100)];
        })
    }

    ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

}




import {Injectable, Type} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';
import {CompanyProfileModel} from "../models/company-profile.model";
@Injectable()
export class CompanyProfileService {
  private companyProfileEndpoint: string = environment.apiURL + '/company_profiles';

  constructor(private httpClient: HttpClient) {
  }

  public get(params?: any): Observable<Array<CompanyProfileModel>> {
  const reqOpts = {
      params: new HttpParams()
    };
    if (params) {
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, '' + params[k]);
        }
      }
    }

    if (environment.domain === 'aesthetics') {
      reqOpts.params = reqOpts.params.set('domain_of_interest_aesthetics', '' + 1);
    }
    if (environment.domain === 'ophthalmology') {
      reqOpts.params = reqOpts.params.set('domain_of_interest_ophthalmology', '' + 1);
    }
    return this.httpClient.get<any>(this.companyProfileEndpoint, reqOpts).pipe(shareReplay());
  }

  public getAds(): Observable<any> {
    let params = new HttpParams();
    if (environment.domain === 'aesthetics') {
      params = params.set('domain_of_interest_aesthetics', '' + 1);
    }
    if (environment.domain === 'ophthalmology') {
      params = params.set('domain_of_interest_ophthalmology', '' + 1);
    }
    return this.httpClient.get<any>(this.companyProfileEndpoint + '/ads', {params: params}).pipe(shareReplay());
  }
}

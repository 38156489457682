import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-specialty-verified',
  templateUrl: './specialty-verified.component.html',
  styleUrls: ['./specialty-verified.component.scss']
})
export class SpecialtyVerifiedComponent implements OnInit {

  title: string;
  subtitle: string;
  button: string;

  constructor(public activeModal: NgbActiveModal,
              private cookieService: CookieService,
              private router: Router) {
  }

  ngOnInit() {

  }

  acceptButton() {
    this.cookieService.set('modal_diploma', 'ok', 10);
    this.activeModal.close('ok');
    this.router.navigate(['/account/current-plan']);
  }

}

 <div class="modal-body modal-no-title modal-wide">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <div class="modal-profile">
      <h2>Upload a new profile picture</h2>
      <p>Your profile picture needs to be at least 150 width and 150 height and the file must be a jpeg or a png image.</p>
      <input type="file" id="uploader-picture" accept="image/*" (change)="onProfileImageFileChange($event)">
      <div class="button-set text-center">
        <a (click)="uploadProfileImage()" class="button button-orange">Update my profile picture</a>
      </div>
      <div class="crop-wrapper text-center">
        <img *ngIf="!profileImage" class="rounded-circle" [src]="getProfilePicture()" alt="">
        <img-cropper *ngIf="profileImage" #cropper [image]="data2" [settings]="cropperSettings"></img-cropper>
      </div>
      <div class="button-set text-center">
        <a class="button button-orange" (click)="cropProfilePicture()">Crop my profile picture</a>
      </div>
    </div>
  </div>

import {AuthService} from 'app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from 'app/services/translation.service';
import {LocaleService} from '../../../../../services/locale.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertNoPremiumComponent} from 'app/components/modal/alert-no-premium/alert-no-premium.component';

@Component({
    selector: 'app-alert-group',
    templateUrl: './alert-group.component.html',
    styleUrls: ['./alert-group.component.scss'],
})
export class AlertGroupComponent implements OnInit {
    @Input() groups: any;
    group: any;
    locale: any;

    constructor(public translationService: TranslationService,
                private activatedRoute: ActivatedRoute,
                public authService: AuthService,
                private router: Router,
                public localeService: LocaleService,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
    }

    openAlertList(group) {
        this.authService.isLoggedIn().subscribe(logged => {
            if (logged) {
                this.router.navigate(['/alert', group.slug]);
            } else {
                this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url}})
            }
        });
    }

    handleGroupClick(group) {

      if (group.soon && (group.user_access === 'waiting' || group.user_access === 'granted')) {
        return
      }

      if (group.user_access === 'not-premium') {
        this.modalService.open(AlertNoPremiumComponent, { centered: true })
      }

      return this.openAlertList(group)
    }
}

<h1>{{ 'academy.Articles'|translate }}</h1>
<div class="articles">
	<div class="articles-list">
		<table class="articles-table">
			<thead>
			<tr>
				<th>{{'company_profile.Article_title'|translate}}</th>
				<th>{{'account.Abstract_status'|translate}}</th>
				<th>{{'company_profile.Publication_date'|translate}}</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let article of articles">
				<td>{{article.title}}</td>
				<td>{{article.status}}</td>
				<td><span *ngIf="article.publication_date">{{dateMomentService.getPublicationDate(article.publication_date)}}</span></td>
			</tr>
			</tbody>
		</table>
		<app-loader *ngIf="showLoading"></app-loader>
		<div class="articles-button text-center" *ngIf="!showSubmitForm">
			<a (click)="showSubmitForm = !showSubmitForm" class="button button-orange">{{'blog.Submit_article'|translate}}</a>
		</div>
	</div>
	<div class="alert alert-success alert-dismissable" *ngIf="articleSubmitted">
		<button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="articleSubmitted = false">×</button>
		{{'blog.Article_submitted'|translate}}
	</div>
	<div class="articles-form" *ngIf="showSubmitForm">
		<ng-template #conflict>
			<div class="modal-body modal-no-title">
				<button type="button" class="close" aria-label="Close">
					<i class="fa fa-times" aria-hidden="true"></i>
				</button>
				<div class="test">
					<h1>{{'abstract.Conflict_of_Interest_Policy'|translate}}</h1>
					<p>{{ 'blog.Coi_line1'|translate }}</p>
					<p>{{ 'blog.Coi_line2'|translate }}</p>
					<p>{{ 'blog.Coi_line3'|translate }}</p>
					<p>{{ 'blog.Coi_line4'|translate }}</p>
					<h3>1. {{ 'blog.What_is_coi'|translate }}</h3>
					<p>{{ 'blog.Coi_line5'|translate }}</p>
					<h4>{{ 'blog.What_bias'|translate }}</h4>
					<p>{{ 'blog.Coi_line6'|translate }}</p>
					<h4>{{ 'blog.What_about'|translate }}</h4>
					<p>{{ 'blog.Coi_line7'|translate }}</p>
					<h4>{{ 'blog.How_about'|translate }}</h4>
					<p>{{ 'blog.Coi_line8'|translate }}</p>
					<h3>2. {{ 'blog.Who_is'|translate }}</h3>
					<p>{{ 'blog.Coi_line9'|translate }}</p>
					<h3>3. {{ 'blog.How_to'|translate }}</h3>
					<p><strong>{{ 'blog.If_you_are'|translate }}</strong></p>
					<ul class="list">
						<li>{{ 'blog.Coi_line10'|translate}}</li>
					</ul>
				</div>
			</div>
		</ng-template>
		<form #articleForm="ngForm" (ngSubmit)="addArticle()">
			<div class="articles-form-box">
				<h2>{{'abstract.Form_your_submission'|translate}}</h2>
				<p>{{'blog.Your_article_will_be'|translate}}<br>
					{{('blog.The_submission_must_be'|translate).split('\{\{coi\}\}')[0]}}<a (click)="open(conflict)">{{'abstract.Conflict_of_Interest_Policy'|translate}}</a>{{('blog.The_submission_must_be'|translate).split('\{\{coi\}\}')[1]}}</p>
				<div class="form-group">
					<input type="text" class="form-control" id="article_title" name="article_title" [placeholder]="'company_profile.Article_title'|translate" [(ngModel)]="newArticle.title" #article_title="ngModel" required>
				</div>
				<div *ngIf="article_title.invalid && (article_title.dirty || article_title.touched || !notSubmitted)" class="form-group-error">
					<p>{{ 'validation.Field_required'|translate }}</p>
				</div>
				<div class="form-group">
					<input type="text" class="form-control" name="short_description" [placeholder]="('blog.Short_description'|translate) + ' (' + ('abstract.Optional'|translate) + ')'" [(ngModel)]="newArticle.short_description">
				</div>
				<div class="form-group">
					<input type="text" class="form-control" maxlength="255" name="other_authors" [placeholder]="('abstract.Other_authors'|translate) + ' (' + ('abstract.Optional'|translate) + ')'" [(ngModel)]="newArticle.other_authors">
				</div>
			</div>
			<div class="articles-form-box">
				<h2>{{'abstract.Form_choose_the_related_themes'|translate}}</h2>
				<div class="row">
					<div class="col-md-12">
						<div class="alert alert-danger" [hidden]="newArticle.theme1_id || notSubmitted">
							<p>{{ 'account.Choose_theme'|translate }}.</p>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<select *ngIf="themes$ | async as themes1"
									id="theme1"
									name="theme1"
									class="form-control"
									[(ngModel)]="newArticle.theme1_id" required>
								<option [ngValue]="undefined">{{'abstract.Theme_1'|translate}}</option>
								<option *ngFor="let option of themes1"
										[ngValue]="option.id">
									{{translationService.getTranslatedName(option['translations'], locale)}}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<select *ngIf="themes$ | async as themes2"
									id="theme2"
									name="theme2"
									class="form-control"
									[(ngModel)]="newArticle.theme2_id">
								<option [ngValue]="undefined">{{('abstract.Theme_2'|translate) + ' (' + ('abstract.Optional'|translate) + ')'}}</option>
								<option *ngFor="let option of themes2"
										[ngValue]="option.id">
									{{translationService.getTranslatedName(option['translations'], locale)}}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<select *ngIf="themes$ | async as themes3"
									id="theme3"
									name="theme3"
									class="form-control"
									[(ngModel)]="newArticle.theme3_id">
								<option [ngValue]="undefined">{{('account.Theme_3'|translate) + ' (' + ('abstract.Optional'|translate) + ')'}}</option>
								<option *ngFor="let option of themes3"
										[ngValue]="option.id">
									{{translationService.getTranslatedName(option['translations'], locale)}}
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div class="articles-form-box">
				<h2>{{'blog.Your_article'|translate}}</h2>
				<div class="form-group">
					<textarea cols="30" rows="10" [placeholder]="article_content_placeholder" class="form-control" (change)="truncateContent($event)" [(ngModel)]="newArticle.content" [attr.maxlength]="updateArticleLimit()" name="content" required #content="ngModel"></textarea>
					<div *ngIf="content.invalid && (content.dirty || content.touched || !notSubmitted)" class="form-group-error">
						<p>{{ 'validation.Field_required'|translate }}</p>
					</div>
					<p class="small text-right">{{('abstract.Form_xxx_words_maximum'|translate:{'limit': '2,500'})}} ({{('abstract.xxx_words_left'|translate:{'chars': updateCounter() })}})</p>
				</div>
				<p [innerHTML]="('blog.Please_read_the_link'|translate: {link: environment.base_url + '/academy/terms-of-use/contributor#contributor'})"></p>
			</div>
			<div class="articles-form-box">
				<h2>{{'abstract.Conflict_of_Interest_Disclosure_title'|translate}}</h2>
				<div class="form-group">
					<h5>{{'abstract.Disclosure_1'|translate}}</h5>
					<div class="form-radio">
						<h6>{{'abstract.Disclosure_question1'|translate}}</h6>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-1-yes" name="disclosure-1" class="custom-control-input" [(ngModel)]="newArticle.disclosure1" value="1" required #disc1="ngModel">
							<label class="custom-control-label" for="disclosure-1-yes">{{'abstract.Yes'|translate}}</label>
						</div>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-1-no" name="disclosure-1" class="custom-control-input" [(ngModel)]="newArticle.disclosure1" value="0" required #disc1="ngModel">
							<label class="custom-control-label" for="disclosure-1-no">{{'abstract.No'|translate}}</label>
						</div>
						<div class="form-group-error" [hidden]="disc1.valid || notSubmitted">
							<p>{{ 'validation.Field_required'|translate }}</p>
						</div>
						<div class="form-group form-group-inner" *ngIf="newArticle.disclosure1==='1'">
							<input type="text" name="disclosure1_details" class="form-control" [placeholder]="'abstract.Disclosure_question1b'|translate" [(ngModel)]="newArticle.disclosure1_details" #disclosure1_details="ngModel" required>
							<div class="form-group-error" [hidden]="disclosure1_details.valid || notSubmitted">
								<p>{{ 'validation.Field_required'|translate }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<h5>{{'abstract.Disclosure_2'|translate}}</h5>
					<div class="form-radio">
						<h6>{{'abstract.Disclosure_question2'|translate}}</h6>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-2-yes" name="disclosure-2" class="custom-control-input" [(ngModel)]="newArticle.disclosure2" value="1" required #disc2="ngModel">
							<label class="custom-control-label" for="disclosure-2-yes">{{'abstract.Yes'|translate}}</label>
						</div>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-2-no" name="disclosure-2" class="custom-control-input" [(ngModel)]="newArticle.disclosure2" value="0" required #disc2="ngModel">
							<label class="custom-control-label" for="disclosure-2-no">{{'abstract.No'|translate}}</label>
						</div>
						<div class="form-group-error" [hidden]="disc2.valid || notSubmitted">
							<p>{{ 'validation.Field_required'|translate }}</p>
						</div>
						<div class="form-group form-group-inner" *ngIf="newArticle.disclosure2==='1'">
							<input type="text" name="disclosure2_details" class="form-control" [placeholder]="'abstract.Disclosure_question2b'|translate" [(ngModel)]="newArticle.disclosure2_details" required #disclosure2_details="ngModel">
							<div class="form-group-error" [hidden]="disclosure2_details.valid || notSubmitted">
								<p>{{ 'validation.Field_required'|translate }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<h5>{{'abstract.Disclosure_3'|translate}}</h5>
					<div class="form-radio">
						<h6>{{'abstract.Disclosure_question3'|translate}}</h6>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-3-yes" name="disclosure-3" class="custom-control-input" [(ngModel)]="newArticle.disclosure3" value="1" required #disc3="ngModel">
							<label class="custom-control-label" for="disclosure-3-yes">{{'abstract.Yes'|translate}}</label>
						</div>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-3-no" name="disclosure-3" class="custom-control-input" [(ngModel)]="newArticle.disclosure3" value="0" required #disc3="ngModel">
							<label class="custom-control-label" for="disclosure-3-no">{{'abstract.No'|translate}}</label>
						</div>
						<div class="form-group-error" [hidden]="disc3.valid || notSubmitted">
							<p>{{ 'validation.Field_required'|translate }}</p>
						</div>
						<div class="form-group form-group-inner" *ngIf="newArticle.disclosure3==='1'">
							<input type="text" name="disclosure3_details" class="form-control" [placeholder]="'abstract.Disclosure_question3b'|translate" [(ngModel)]="newArticle.disclosure3_details" required #disclosure3_details="ngModel">
							<div class="form-group-error" [hidden]="disclosure3_details.valid || notSubmitted">
								<p>{{ 'validation.Field_required'|translate }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<h5>{{'abstract.Disclosure_4'|translate}}</h5>
					<div class="form-radio">
						<h6>{{'abstract.Disclosure_question4'|translate}}</h6>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-4-yes" name="disclosure-4" class="custom-control-input" [(ngModel)]="newArticle.disclosure4" value="1" required #disc4="ngModel">
							<label class="custom-control-label" for="disclosure-4-yes">{{'abstract.Yes'|translate}}</label>
						</div>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-4-no" name="disclosure-4" class="custom-control-input" [(ngModel)]="newArticle.disclosure4" value="0" required #disc4="ngModel">
							<label class="custom-control-label" for="disclosure-4-no">{{'abstract.No'|translate}}</label>
						</div>
						<div class="form-group-error" [hidden]="disc4.valid || notSubmitted">
							<p>{{ 'validation.Field_required'|translate }}</p>
						</div>
						<div class="form-group form-group-inner" *ngIf="newArticle.disclosure4==='1'">
							<input placeholder="yyyy-mm-dd" (focus)="d.toggle()"
								   ngbDatepicker #d="ngbDatepicker" name="disclosure4_details_date" class="form-control"
								   [placeholder]="'abstract.Disclosure_question4b'|translate" [(ngModel)]="tmpDate" (ngModelChange)="newArticle.disclosure4_details_date = $event.year && $event.month && $event.day ? $event.year + '-' + $event.month + '-' + $event.day : ''" required>
						</div>
						<div class="form-group form-group-inner" *ngIf="newArticle.disclosure4==='1'">
							<input type="text" name="disclosure4_details_status" class="form-control" [placeholder]="'abstract.Disclosure_question4c'|translate" [(ngModel)]="newArticle.disclosure4_details_status" required #disclosure4_details_status="ngModel">
							<div class="form-group-error" [hidden]="disclosure4_details_status.valid || notSubmitted">
								<p>{{ 'validation.Field_required'|translate }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<h5>{{'abstract.Disclosure_5'|translate}}</h5>
					<div class="form-radio">
						<h6>{{'abstract.Disclosure_question5'|translate}}</h6>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-5-yes" name="disclosure-5" class="custom-control-input" [(ngModel)]="newArticle.disclosure5" value="1" required #disc5="ngModel">
							<label class="custom-control-label" for="disclosure-5-yes">{{'abstract.Yes'|translate}}</label>
						</div>
						<div class="custom-control custom-radio">
							<input type="radio" id="disclosure-5-no" name="disclosure-5" class="custom-control-input" [(ngModel)]="newArticle.disclosure5" value="0" required #disc5="ngModel">
							<label class="custom-control-label" for="disclosure-5-no">{{'abstract.No'|translate}}</label>
						</div>
						<div class="form-group-error" [hidden]="disc5.valid || notSubmitted">
							<p>{{ 'validation.Field_required'|translate }}</p>
						</div>
						<div class="form-group form-group-inner" *ngIf="newArticle.disclosure5==='1'">
							<input type="text" name="disclosure5_details_sponsor" class="form-control" placeholder="Please specify entities (individual, company, society)" [(ngModel)]="newArticle.disclosure5_details_sponsor" required #disclosure5_details_sponsor="ngModel">
							<div class="form-group-error" [hidden]="disclosure5_details_sponsor.valid || notSubmitted">
								<p>{{ 'validation.Field_required'|translate }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-info">
					<p>{{'blog.An_acknowledgement_of_receipt'|translate}}</p>
				</div>
			</div>
			<div class="articles-form-submit">
				<button  *ngIf="!showAddLoading" class="button button-blue">{{'blog.Submit_your_article'|translate}}</button>
				<app-loader *ngIf="showAddLoading"></app-loader>

			</div>
		</form>
	</div>
</div>

import {Component, OnInit} from '@angular/core';
import {Cart} from '../../../models/cart.model';
import {CartService} from '../../../services/cart.service';
import {TranslationService} from '../../../services/translation.service';
import {LocaleService} from '../../../services/locale.service';
import {AcademySubscriptionService} from '../../../services/academy-subscription.service';
import {User} from '../../../models/user.model';
import {AuthService} from '../../../services/auth.service';
import {tap} from 'rxjs/operators';
import {SpecialtyService} from '../../../services/specialty.service';
import {CountryService} from '../../../services/country.service';
import {Country} from '../../../models/country.model';
import {Specialty} from '../../../models/specialty.model';
import {Observable} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {Payment} from '../../../models/payment.model';
import {PaymentService} from '../../../services/payment.service';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {UserProductService} from '../../../services/user-product.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [
    CartService,
    TranslationService,
    AcademySubscriptionService,
    SpecialtyService,
    CountryService,
    PaymentService,
    UserProductService
  ]
})
export class CartComponent implements OnInit {
  cart: Cart = null;
  locale: string;
  env: any;
  marketplace: boolean = false;
  cartLoading: boolean = false;
  requestPaymentSent: boolean = false;
  requestDeliverySent: boolean = false;
  company: boolean = false;
  specialities$: Observable<Specialty[]>;
  countries$: Observable<Country[]>;
  selectedCountry: Country;
  selectedCountryDelivery: Country;
  selectedSpecialty: Specialty;
  promotionalCodeBox: boolean = false;
  promotional_code: string;
  emailConfirmation: string;
  terms_of_use_sentence: string;
  promoError: boolean = false;
  optionInjectables: boolean = false
  optionLaser: boolean = false;
  optionThreads: boolean = false;
  isDoctor: boolean = false;
  privacy: string = '';
  otherPrivacySentences: Array<string> = [];
  user: User;
  payment: Payment;
  billingData: Payment;
  deliveryData: Payment;
  oneProductIsAcademy: Boolean = false;
  withDeliveryFees: Boolean = false;
  marketplaceBankTransfer: Boolean = false;
  withDeliveryFeesValidated: Boolean = false;
  withDeliveryFeesForCongressProductId: number = null;
  productsAcademyIds: Array<number> = [518, 2243, 2244];
  isGiftProduct: boolean = false;
  deliveryFirstNameInvalid: boolean = false;
  deliveryLastNameInvalid: boolean = false;
  billingFirstNameInvalid: boolean = false;
  billingLastNameInvalid: boolean = false;
  constructor(private cartService: CartService,
              public translationService: TranslationService,
              public translateService: TranslateService,
              private academySubscriptionService: AcademySubscriptionService,
              private localeService: LocaleService,
              private specialtyService: SpecialtyService,
              private countryService: CountryService,
              private router: Router,
              private authService: AuthService,
              private paymentService: PaymentService,
              private userService: UserService,
              private userProductService: UserProductService,
              private activatedRoute: ActivatedRoute,
              private location: Location) {
    this.locale = this.localeService.currentLocale();
    this.env = environment;
    this.isGiftProduct = this.router.getCurrentNavigation()?.extras.state?.type === 'gift';
  }

  ngOnInit() {
    this.cartLoading = true;
    this.getCart();
    this.user = this.authService.getCurrentUser();
    this.translateService.get('layout.Privacy_Policy').subscribe((privacy: string) => {
      this.privacy = privacy;
      this.translateService.get('layout.Terms_of_use').subscribe((terms: string) => {
        this.translateService.get('layout.Form_cart_payment_message', {
          privacy_policy_link: '<a href=\'/' + this.locale + '/academy/privacy-policy\' target=\'_blank\'>' + this.privacy + '</a>',
          terms_of_use_link: '<a href=\'/' + this.locale + '/academy/terms-of-use\' target=\'_blank\'>' + terms + '</a>.'
        }).subscribe((res: string) => {
          this.terms_of_use_sentence = res;
        });
      });
    });
  }

  goBack() {
    this.location.back()
  }

  cartIsFree() {
    const cart = this.cart;
    return cart &&
      cart.promotional_codes.length > 0 &&
      cart.academy_subscriptions.length > 0 &&
      (cart.total['usd'] === 0 || cart.total['eur'] === 0);
  }

  getCart() {
    this.cartLoading = true;
    this.getSpecialties();
    this.getCountries();
    this.cartService.getCart(this.cartService.getCurrentCartId()).subscribe(cart => {
      this.cart = new Cart(cart);
      this.cartIsFree();
      if (this.cart.payment && this.cart.payment) {
        this.billingData = this.cart.payment;
        this.deliveryData = this.cart.payment;
      }
      this.withDeliveryFees = false;
      this.marketplaceBankTransfer = false;
      this.withDeliveryFeesForCongressProductId = null;
      this.withDeliveryFeesValidated = false;
      this.otherPrivacySentences = [];
      for (let i = 0; i < this.cart.products.length; ++i) {
        const congressProduct = this.cart.products[i].congress_product;
        const product = congressProduct.product;
        if (product.privacy_policy_url) {
          this.otherPrivacySentences.push('You also agree that we transfer your data to ' +
            congressProduct.partner.company_name + ' and to accept their <a href="' + product.privacy_policy_url +
            '" target="_blank">' + this.privacy + '</a>.');
        }
        if (!this.marketplace && product.marketplace_para_medias) {
          this.marketplace = true;
        }

        if (congressProduct.add_delivery_fees) {
          this.withDeliveryFees = true;
          this.withDeliveryFeesForCongressProductId = congressProduct.id;
        }

        if (congressProduct.marketplace_bank_transfer) {
          this.marketplaceBankTransfer = true;
        }

      }
      this.userService.getUser(this.user.id, {user_specialty: 1, user_country: 1}).subscribe(user => {
        if (user.specialty) {
          this.isDoctor = user.specialty.doctor;
        }
        if (!this.cart.payment) {
          this.billingData = new Payment(user);
        }
      })

      if (this.withDeliveryFees) {
        if (cart.delivery_fees_id) {
          this.withDeliveryFeesValidated = true;
        } else {
          this.withDeliveryFeesValidated = false;
        }
        this.deliveryData = new Payment(cart);
      }

      if (cart.academy_subscriptions && cart.academy_subscriptions.length) {
        cart.academy_subscriptions.forEach(subscription => {

          if (this.productsAcademyIds.includes(subscription.congress_product.product.id)) {
            this.oneProductIsAcademy = true
          } else {
            this.oneProductIsAcademy = false
          }

        });
      } else {
        this.oneProductIsAcademy = false
      }
      this.cartLoading = false;
      window.scrollTo(0, 0);
    }, error => {
      this.cartService.deleteCurrentCartId();
      this.router.navigate(['/premium']);
    })
  }

  specialtyChange(specialty) {
    this.billingData.user_specialty_id = specialty.id;
    this.selectedSpecialty = specialty;
  }

  countryChange(country) {
    this.billingData.country_id = country.id;
    this.selectedCountry = country;
  }

  countryDeliveryChange(country) {
    this.deliveryData.delivery_country_id = country.id;
    this.selectedCountryDelivery = country;
  }

  getSpecialties() {
    this.specialities$ = this.specialtyService
      .getSpecialties({all: 1})
      .pipe(tap(data => {
        if (this.billingData && this.billingData.user_specialty_id) {
          const specialtyToSelect = data.find(specialty => specialty.id === this.billingData.user_specialty_id);
          if (specialtyToSelect) {
            this.selectedSpecialty = specialtyToSelect;
          }
        }
      }));
  }

  getCountries() {
    this.countries$ = this.countryService
      .getCountries()
      .pipe(tap(data => {
        if (this.billingData && this.billingData.country_id) {
          const countryToSelect = data.find(country => country.id === this.billingData.country_id);
          if (countryToSelect) {
            this.selectedCountry = countryToSelect;
          }
        }
        if (this.deliveryData && this.deliveryData.delivery_country_id) {
          const countryDeliveryToSelect = data.find(country => country.id === this.deliveryData.delivery_country_id);
          if (countryDeliveryToSelect) {
            this.selectedCountryDelivery = countryDeliveryToSelect;
          }
        }
      }));
  }

  validateFreeCart() {
    this.userProductService.create(this.cart.id).subscribe(result => {
      window.location.href = this.env.base_url + '/' + this.locale + '/academy/account/thanks';
    });
  }

  applyPromoCode() {
    if (this.promotional_code) {
      this.cartService.applyPromoCode(this.promotional_code, this.cart.id).subscribe(cart => {
        this.cart = new Cart(cart);
        this.cartIsFree();
        this.promoError = false;
      }, error => {
        this.promoError = true;
      })
    }
  }

  proceedPayment() {
    let billingDataInfo = false;
    if (this.billingData.gender &&
      this.billingData.first_name &&
      this.billingData.last_name &&
      this.billingData.user_specialty_id &&
      ((this.company && this.billingData.company_function && this.billingData.company_name && this.billingData.company_vat) ||
        !this.company) &&
      this.billingData.address_l1 &&
      this.billingData.zip &&
      this.billingData.city &&
      this.billingData.country_id &&
      this.billingData.email &&
      this.emailConfirmation &&
      !this.billingLastNameInvalid &&
      !this.billingFirstNameInvalid &&
      (!this.withDeliveryFees || (this.withDeliveryFees && this.withDeliveryFeesValidated)) &&
      this.billingData.email === this.emailConfirmation && !this.requestPaymentSent) {
      billingDataInfo = true;
    }
    console.log('billing spe', this.billingData.user_specialty_id);
    if (billingDataInfo && !this.requestPaymentSent) {
      this.requestPaymentSent = true;
      this.paymentService.post({
        gender: this.billingData.gender,
        last_name: this.billingData.last_name,
        first_name: this.billingData.first_name,
        user_specialty_id: this.billingData.user_specialty_id,
        address_l1: this.billingData.address_l1,
        address_l2: this.billingData.address_l2,
        company_function: this.billingData.company_function,
        company_name: this.billingData.company_name,
        company_vat: this.billingData.company_vat,
        zip: this.billingData.zip,
        city: this.billingData.city,
        country_id: this.billingData.country_id,
        email: this.billingData.email,
        phone: this.billingData.phone,
        email_confirmation: this.emailConfirmation,
        cart_id: this.cart.id,
        congress_id: environment.congress_id,
        option_injectables: this.optionInjectables ? 1 : 0,
        option_lasers: this.optionLaser ? 1 : 0,
        option_threads: this.optionThreads ? 1 : 0
      }).subscribe(result => {
        this.router.navigate(['/cart/payment']);
      })
    }
  }


  proceedDelivery() {
    let deliveryDataInfo = false;
    if (this.deliveryData.delivery_first_name &&
      this.deliveryData.delivery_last_name &&
      this.deliveryData.delivery_address_l1 &&
      this.deliveryData.delivery_zip &&
      this.deliveryData.delivery_city &&
      !this.deliveryLastNameInvalid &&
      !this.deliveryFirstNameInvalid &&
      this.deliveryData.delivery_country_id) {
      deliveryDataInfo = true;
    }
    if (deliveryDataInfo) {
      this.requestDeliverySent = true;
      this.paymentService.postDelivery({
        delivery_last_name: this.deliveryData.delivery_last_name,
        delivery_first_name: this.deliveryData.delivery_first_name,
        delivery_address_l1: this.deliveryData.delivery_address_l1,
        delivery_address_l2: this.deliveryData.delivery_address_l2,
        delivery_company_name: this.deliveryData.delivery_company_name,
        delivery_zip: this.deliveryData.delivery_zip,
        delivery_city: this.deliveryData.delivery_city,
        delivery_country_id: this.deliveryData.delivery_country_id,
        delivery_phone: this.deliveryData.delivery_phone,
        delivery_congress_product_id: this.withDeliveryFeesForCongressProductId,
        cart_id: this.cart.id,
        congress_id: environment.congress_id,
      }).subscribe(result => {
        if (!result.delivery_fees_id) {
          this.withDeliveryFeesValidated = false;
          alert('Warning! Delivery not available in this country...');
        } else {
          this.withDeliveryFeesValidated = true;
        }
        this.cartLoading = true;
        this.getCart();
      })
    }
  }

  checkFirstName(firstName: string, type: string) {
    if (type === 'delivery') {
      this.deliveryFirstNameInvalid = this.userService.checkLatinInput(firstName);
    } else {
      this.billingFirstNameInvalid = this.userService.checkLatinInput(firstName);
    }
  }

  checkLastName(lastName: string, type: string) {
    if(type === 'delivery') {
      this.deliveryLastNameInvalid = this.userService.checkLatinInput(lastName);
    } else {
      this.billingLastNameInvalid = this.userService.checkLatinInput(lastName);
    }
  }

  protected readonly Specialty = Specialty;
}

<div class="like-button">
  <div class="like-button-action">
    <form (ngSubmit)="displayAsLike ? like() : unlike()">
      <button *ngIf="!loading" type="submit">
        <i class="{{ displayAsLike ? 'far fa-thumbs-up' : 'fas fa-thumbs-up' }}"></i>
      </button>
      <span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span>
      <input type="hidden" name="likeable_id" [ngModel]="likeableId" />
      <input type="hidden" name="likeable_type" [ngModel]="likeableType" />
    </form>
  </div>

  <div class="like-button-count">{{ likesCount }}</div>
</div>

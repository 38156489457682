<div class="modal-body text-center">
  <button type="button" class="close" aria-label="Close"
          (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <img src="{{company.logo_url}}" style="margin: 0 auto;height: 100px;"
       alt="">
  <div class="col-sm-12 text-left sponsor-info">
    <h3>{{company.company_name}}</h3>
    <p>
      {{translationService.getTranslatedName(company.country.translations,
      locale)}}</p>
    <button *ngIf="company.social_network.website" class="button button-orange"><a [href]="company.company_profiles[0]?.user?.slug ?
				environment.base_url + '/profile/' + company.company_profiles[0]?.user?.slug :
				company.social_network.website" target="_blank">
      {{'explore.Go_to_link'|translate }}
    </a></button>

    <p
      [innerHTML]="company.description"
      style="font-size: 14px;margin-top: 10px"
    ></p>
  </div>
</div>

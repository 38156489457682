import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Locales} from "../locales";
import * as _ from "lodash";
import {LocaleService} from "./locale.service";
import {environment} from "../../environments/environment";
import {LocalStorage} from "@ngx-pwa/local-storage";

@Injectable()
export class DateMomentService {
    locales = Locales;
    constructor() {
      const wLocale = !environment.mobile ? window.location.pathname.substr(1, 2) : localStorage.getItem('lang');
      const locale = _.find(this.locales, language => {
        return language.locale === wLocale;
      });
      moment.locale(_.isEmpty(locale) ? 'en': locale.locale);
    }

    getNow() {
        return moment();
    }

    getDuration(duration) {
        return moment('1900-01-01 00:00:00').add(duration,
            'seconds').format('HH:mm:ss');
    }

    getUnixDate(date) {
        const newDate = moment.unix(date);
        return newDate.isValid() ? newDate : moment(date);
    }

    formatLong(date) {
        return moment.unix(date).format('LL');
    }

    formatPublication(date) {
        if (window.location.pathname.slice(1, 3) === 'fr') {
            return moment.unix(date).format('Do MMMM YYYY');
        }
        return moment.unix(date).format('MMMM Do, YYYY');

    }

    formatGoogle(date) {
        return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD']).utc().format('YYYYMMDDTHHmmss') + 'Z';
    }

    formatTimeWithSecondToTimeithout(timeWithSecond) {
        return moment(timeWithSecond, ['HH:mm:ss']).format('HH:mm');

    }

    formatTimeMinutes(date) {
        return moment.duration(date, 'hours').asMinutes();
    }

    getPublicationDate(date) {
        if (window.location.pathname.slice(1, 3) === 'fr') {
            return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'YYYYMMDD']).format('Do MMMM YYYY');
        }
        return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'YYYYMMDD']).format('MMMM Do, YYYY');
    }

    getFullDateTime(date, locale) {
        const format = locale === 'fr' ? 'Do MMMM, YYYY HH:mm' : 'MMMM Do, YYYY HH:mm';
        return moment(date, ['YYYY-MM-DD HH:mm:ss']).format(format);
    }

    getDateTime(date) {
        if (window.location.pathname.slice(1, 3) === 'fr') {
            return moment(date, ['YYYY-MM-DD @HH:mm']).format('Do MMMM YYYY @HH:mm');
        }
        return moment(date, ['YYYY-MM-DD @HH:mm']).format('MMMM Do, YYYY @HH:mm');
    }

    getDateTimeBegin(dates) {
        if (window.location.pathname.slice(1, 3) === 'fr') {
            return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('Do MMMM YYYY @HH:mm');
        }
        return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('MMMM Do, YYYY @HH:mm');
    }

    getDateTimeBeginEnd(dates) {
        if (window.location.pathname.slice(1, 3) === 'fr') {
            return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('Do MMMM YYYY @HH:mm') +
                ' - ' + moment(dates.end, ['YYYY-MM-DD @HH:mm']).format('HH:mm');
        }
        return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('MMMM Do, YYYY @HH:mm') +
            ' - ' + moment(dates.end, ['YYYY-MM-DD @HH:mm']).format('HH:mm');
    }

    getWebinarDate(dates) {
        if (window.location.pathname.slice(1, 3) === 'fr') {
            return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('Do MMMM YYYY');
        }
        return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('MMMM Do, YYYY');
    }

    getWebinarTime(dates) {
        if (window.location.pathname.slice(1, 3) === 'fr') {
            return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('@HH:mm') +
                ' - ' + moment(dates.end, ['YYYY-MM-DD @HH:mm']).format('HH:mm');
        }
        return moment(dates.begin, ['YYYY-MM-DD @HH:mm']).format('@HH:mm') +
            ' - ' + moment(dates.end, ['YYYY-MM-DD @HH:mm']).format('HH:mm');
    }


    isNew(date) {

        let itemDate = moment.unix(date);
        if (!itemDate.isValid()) {
            itemDate = moment(date, 'YYYY-MM-DD hh:mm:ss');
        }
        return this.getNow().diff(itemDate, 'days') < 30;
    }

    getDay(date) {
        return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD']).format('YYYY-MM-DD');
    }

    getDate(date) {
        return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD']);
    }

    getYear(date) {
        return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD']).format('YYYY');
    }

    getMonth(date) {
        return moment(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD']).format('MMMM');
    }

    formatHumanWay(date) {
        return moment(date).fromNow();
    }

    getDifference(date1, date2) {
        return moment(date1).diff(moment(date2), 'days');
    }

    getDifferenceInHour(date1, date2) {
        return moment(date1).diff(moment(date2), 'hours');
    }
}

import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {TranslationService} from 'app/services/translation.service';
import {SpecialtyService} from 'app/services/specialty.service';
import {CountryService} from 'app/services/country.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../services/auth.service';


@Component({
  selector: 'profile-biostatement',
  templateUrl: './biostatement.component.html',
  styleUrls: ['./biostatement.component.scss'],
  providers: [
    UserService,
    CountryService,
    SpecialtyService
  ]
})
    export class ProfileBiostatementComponent implements OnInit {
  user: User;
  updated: boolean = false;
  newUser = {
    biostatement_en: '',
  };
  public tools: object = {
    items: [
      'Bold', 'Italic', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink']
  };

  static isHTML(str) {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return doc.body ? Array.from(doc.body.childNodes).some(node => node.nodeType === 1) : false;
  }

  constructor(private userService: UserService,
              private authService: AuthService,
              public translationService: TranslationService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      this.userService.getUser(this.user.id, {user_biostatement: 1}).subscribe(
        user => {
          this.user = new User({...this.user, ...user});
          this.newUser.biostatement_en = this.user.biostatement;
        }
      );
    }
  }

  updateBiostatement() {
    this.userService.updateUser(this.newUser).subscribe(success => {
      this.updated = true;
      scroll({
        top: 1200,
        behavior: 'smooth'
      });
    });
  }

  open(content) {
    this.modalService.open(content);
  }

  getRemainingText(max, html) {
    if (ProfileBiostatementComponent.isHTML(html)) {
      return html ? max - jQuery(html).text().length : max;
    } else {
      return html ? max - html.length : max;
    }
  }
}

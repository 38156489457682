import {DateMomentService} from 'app/services/date-moment.service';
import {Component, OnInit} from '@angular/core';
import {WebinarService} from 'app/services/webinar.service';
import {Webinar} from 'app/models/webinar.model';
import {TranslationService} from 'app/services/translation.service';
import {ActivatedRoute,Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {LocaleService} from '../../../services/locale.service';
import {AuthService} from '../../../services/auth.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss'],
  providers: [
    WebinarService,
    NgbActiveModal,
    UserService
  ]
})
export class WebinarsComponent implements OnInit {
  upcomingUserSpecialties: any[] = [];
  upcomingThemes: any[] = [];
  upcomingTheme: any = '';
  upcomingFilterCanAccess: boolean = null;
  upcomingWebinars: Webinar[] = [];
  // upcomingData: any[] = [];
  locale: any;
  environment: any = environment;
  user: User;
  logged: any;
  dynamicResize = true;
  isAop: boolean = environment.domain === 'ophthalmology';
  isEmpty: boolean = false;
  loading: boolean = true;

  ChartLegend = {
    position: 'top',
    alignment: 'start',
    maxLines: 6
  };

  ChartArea = {
    left: 30,
    width: '90%'
  };

  PieChartOptions = {
    legend: this.ChartLegend,
    chartArea: this.ChartArea,
    height: 400
  };

  pieChartSpecialtyDataUpcoRegis = {
    chartType: 'PieChart',
    containerId: 'pieChartSpecialtyUpcoRegis',
    dataTable: [
      ['Specialty', 'Percentage']
    ],
    options: this.PieChartOptions
  };
  pieChartGeoDataUpcoRegis = {
    chartType: 'PieChart',
    containerId: 'pieChartGeoDataUpcoRegis',
    dataTable: [
      ['Continent', 'Percentage']
    ],
    options: this.PieChartOptions
  };

  constructor(private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private activatedRoute: ActivatedRoute,
              private webinarService: WebinarService,
              private localeService: LocaleService,
              private authService: AuthService,
              public dateMomentService: DateMomentService,
              public translationService: TranslationService,
              private userService: UserService,
              private router: Router) {
  }

  getTitlePage() {
    if (environment.domain === 'aesthetics') {
      return 'webinar.Previous_webinars_title';
    }
    return 'webinar.Previous_webinars_title_aop';
  }

  toggleCanAccess(isChecked: boolean) {
    if (isChecked) {
      this.upcomingFilterCanAccess = true;
    } else {
      this.upcomingFilterCanAccess = null;
    }
    this.webinarService.getUpcomingWebinars(this.dateMomentService.getNow().subtract(1, 'day'), {
        all: 1,
        public: 1,
        company_profiles: 1,
        theme_id: this.upcomingTheme
      }
    ).subscribe(data => {
      // this.upcomingData = data;
      this.formatUpcomingWebinars(data);

    });
  }

  ngOnInit() {
    this.logged = false;
    this.authService.isLoggedIn().subscribe(logged => {
      this.logged = logged
    });
    this.locale = this.localeService.currentLocale();
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      this.userService.getUser(this.user.id).subscribe(
        user => {
          this.user = new User({...this.user, ...user});
        })
    }
    this.getUpcoming();
  }

  getUpcoming() {
    const trans = this.translationService.getTranslatedName;
    this.webinarService.getUpcomingWebinars(this.dateMomentService.getNow().subtract(1, 'day'), {
      all: 1,
      public: 1,
      company_profiles: 1
    }).subscribe(data => {
      data?.forEach((webinar) => {
        if (webinar.theme && this.upcomingThemes.find(theme => theme.id === webinar.theme.id) === undefined) {
          this.upcomingThemes.push(webinar.theme);
        }
      });

      this.upcomingThemes = this.upcomingThemes.sort((a, b) => {
        if (trans(a.translations, this.locale) < trans(b.translations, this.locale)) {
          return -1;
        } else if (trans(a.translations, this.locale) > trans(b.translations, this.locale)) {
          return 1;
        } else {
          return 0;
        }
      })
      if (data.length <= 0) {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }

      // this.upcomingData = data;
      this.formatUpcomingWebinars(data);

    });
  }

  openUpco(content, upcomingWebinar) {
    this.pieChartSpecialtyDataUpcoRegis.dataTable = [['Specialty', 'Percentage']]
    upcomingWebinar.webinar_registrants_specialties.forEach(item => {
      this.pieChartSpecialtyDataUpcoRegis.dataTable.push([item]);
    });
    upcomingWebinar.webinar_registrants_stats_specialties.forEach((item, index) => {
      this.pieChartSpecialtyDataUpcoRegis.dataTable[index + 1].push(item);
    });

    this.pieChartGeoDataUpcoRegis.dataTable = [['Continent', 'Percentage']]
    upcomingWebinar.webinar_registrants_areas.forEach(item => {
      this.pieChartGeoDataUpcoRegis.dataTable.push([item]);
    });
    upcomingWebinar.webinar_registrants_stats_areas.forEach((item, index) => {
      this.pieChartGeoDataUpcoRegis.dataTable[index + 1].push(item);
    });

    this.modalService.open(content, {windowClass: 'modal-wide'});
  }

  formatUpcomingWebinars(data) {
    this.upcomingWebinars = [];
    data.forEach(webinar => {
      if (webinar && webinar.dates) {
        let dates = [];
        // We add a check on the index to make sure that only max 2 dates are displayed here
        let index = 0;
        for (const key of Object.keys(webinar.dates)) {
          if (index < 2) {
            const newDate = {
              key: key,
              date: this.dateMomentService.getWebinarDate(webinar.dates[key]),
              time: this.dateMomentService.getWebinarTime(webinar.dates[key]),
            }

            dates.push(newDate);
          }
          index += 1;
        }
        ;
        this.authService.isLoggedIn().subscribe(logged => {
          if (logged && dates.length > 1) {
            dates = [dates[1]];
          } else if (dates.length > 1) {
            dates[1]['date'] = dates[0]['date'] === dates[1]['date'] ? null : dates[1]['date'];
          }
        });
        webinar.dates = dates;
        webinar.speakers = webinar.speakers.slice(0, 10);
      }

      /*
      // Temp removed. Access webinar. Useless for now
         let accessFound = true;

         if (this.logged && this.upcomingFilterCanAccess && webinar.allowed_user_specialty_ids.length > 0) {
           accessFound = false;
           if (webinar.allowed_user_specialty_ids.indexOf(this.user.user_specialty_id) !== -1) {
             accessFound = true;
           }
         }
             if (accessFound) {
        this.upcomingWebinars.push(new Webinar(webinar));
      }
    */
      this.upcomingWebinars.push(new Webinar(webinar));

    });
    this.upcomingWebinars = this.upcomingWebinars.sort((a, b) => {
      if (a.datetime_begin > b.datetime_begin) {
        return 1;
      } else if (a.datetime_begin < b.datetime_begin) {
        return 0;
      } else {
        return -1;
      }
    });
    this.loading = false;

  }

  selectTheme(event) {
    this.upcomingTheme = event.target.value;
    this.webinarService.getUpcomingWebinars(this.dateMomentService.getNow().subtract(1, 'day'), {
      all: 1,
      public: 1,
      company_profiles: 1,
      theme_id: this.upcomingTheme
    }).subscribe(data => {
      // this.upcomingData = data;
      this.formatUpcomingWebinars(data);

    });
  }
}

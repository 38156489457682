import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class WebPasswordReminderService {
    private endpoint: string = '/web_password_reminders';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    sendReminder(email: string): Observable<any> {
        return this.httpClient.post(environment.apiURL + this.endpoint, {
            email: email
        }).pipe(shareReplay());
    }
}

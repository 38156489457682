import {DateMomentService} from 'app/services/date-moment.service';
import {MediaService} from 'app/services/media.service';
import {UserService} from 'app/services/user.service';
import {ThemeService} from 'app/services/theme.service';
import {Keyword} from 'app/models/keyword.model';
import {Theme} from 'app/models/theme.model';
import {WorldwideEvent} from 'app/models/worldwide-event.model';
import {User} from 'app/models/user.model';
import {SearchService} from 'app/services/search.service';
import {TranslationService} from 'app/services/translation.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, of, Subject} from 'rxjs';
import {Course} from 'app/models/course.model';
import {environment} from '../../../../environments/environment';
import {AcademyMedia} from '../../../models/academy-media.model';
import {LocaleService} from '../../../services/locale.service';
import {AuthService} from '../../../services/auth.service';
import {Locales} from '../../../locales';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {LocalStorage} from '@ngx-pwa/local-storage';
import * as $ from 'jquery';
import {isArray} from "rxjs/internal-compatibility";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";
import {HistoryService} from "../../../services/mobile-services/history.service";

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  providers: [
    SearchService,
    ThemeService,
    UserService,
    MediaService
  ]
})
export class LibraryComponent implements OnInit {
  isLoggedIn: boolean;
  locale: any;
  mediasHits: number;
  coursesHits: number;
  themes: Theme[];
  resultNotfoundThemes: Theme[] = [];
  keywords: Keyword[] = [];
  worldwideEvents: WorldwideEvent[];
  medias: AcademyMedia[] = [];
  courses: Course[] = [];
  selectedTheme: Theme;
  selectedThemeId: number;
  nbHitsToRemove: number = 0;
  selectedThemeSlug: string;
  selectedKeywordId: any;
  selectedKeywordSlug: string;
  onlyKeywordNoTheme: boolean = false;
  selectedKeywordsIds: any[];
  selectedLanguages: any[] = [];
  selectedWorldwideEvent: any;
  languages: any;
  selectedWorldwideEventId: number;
  private subject: Subject<string> = new Subject();
  currentCoursesPage = 0;
  currentMediasPage = 0;
  loadingUser: boolean = false;
  coursesPages = 0;
  mediasPages = 0;
  currentTab = 'courses';
  currentCoursesIndex;
  currentMediasIndex;
  free = false;
  canAccess = false;
  withCertificate = false;
  demo = false;
  searchQuery = '';
  user: User;
  environment: any = environment;
  autoscroll: boolean = true;
  showMediasLoading: boolean = false;
  showCoursesLoading: boolean = false;
  obsCombined: any;
  searchMediaParams = new Subject<{
    searchType: string,
    searchQuery: string,
    indices: string[],
    currentPage: number,
    facetFilters: any[],
  }>();

  searchCourseParams = new Subject<{
    searchType: string,
    searchQuery: string,
    indices: string[],
    currentPage: number,
    facetFilters: any[],
  }>();

  dataReceivedFromWeb = [];
  openKeywords: boolean = true;
  keepSelectedIds = [];

  constructor(private searchService: SearchService,
              private route: ActivatedRoute,
              public translationService: TranslationService,
              private router: Router,
              private localeService: LocaleService,
              private authService: AuthService,
              private userService: UserService,
              private themeService: ThemeService,
              private mediaService: MediaService,
              public dateMomentService: DateMomentService,
              protected localStorage: LocalStorage,
              public historyService: HistoryService) {
  }

  ngOnInit() {

    this.localStorage.getItem <any>('LIBRARY-courses_' + environment.domain).subscribe((data: any) => {
      if (!('courses' in this.dataReceivedFromWeb) && data && data.length > 0) {
        const courses = data.results[0];
        this.coursesHits = courses.nbHits;
        this.coursesPages = courses.nbPages;
        for (let i = 0; i < courses.hits.length; ++i) {
          const tmpCourse = new Course(courses.hits[i]);
          tmpCourse.enrolled = false;
          tmpCourse.pctViewed = false;
          this.courses.push(tmpCourse);
        }
      }
    });
    this.localStorage.getItem <any>('LIBRARY-medias_' + environment.domain).subscribe((data: any) => {
      if (!('medias' in this.dataReceivedFromWeb) && data && data.results) {
        const medias = data.results[0];
        this.mediasHits = medias.nbHits;
        this.mediasPages = medias.nbPages;
        for (let i = 0; i < medias.hits.length; ++i) {
          const tmpMedia = new AcademyMedia(medias.hits[i]);
          tmpMedia.getMedia().enrolled = false;
          tmpMedia.getMedia().viewed = false
          this.medias.push(tmpMedia);
        }
      }
    });

    this.getUser();

    this.languages = [];

    this.mediaService.getLocales().subscribe(result => {
      const resultArr = Object.keys(result).map(key => result[key]);
      for (let i = 0; i < resultArr.length; ++i) {
        if (Locales[resultArr[i]]) {
          const checked = this.selectedLanguages.indexOf(Locales[resultArr[i]].locale) !== -1;
          this.languages.push({
            locale: Locales[resultArr[i]].locale,
            name: Locales[resultArr[i]].name,
            checked: checked
          });
        }
      }
    });

    this.locale = this.localeService.currentLocale();

    this.authService.isLoggedIn().subscribe((data) => {
      this.isLoggedIn = data;
    });

    if (this.isLoggedIn) {
      this.themeService.getThemesForUser(this.user.id).subscribe(data => {
        data.forEach(n => {
          this.resultNotfoundThemes.push(new Theme(n));
        });
      })
    } else {
      this.themeService.getMainThemes('alpha').subscribe((themes: Theme[]) => {
        themes.forEach(n => {
          this.resultNotfoundThemes.push(new Theme(n));
        });
      });
    }

  }

  getThemes(selectedThemeId) {
    if (this.themes) {
      this.selectTheme(selectedThemeId, this.themes);
    } else {
      this.themeService.getMainThemes('alpha').subscribe((themes: Theme[]) => {
        this.themes = themes;
        this.selectTheme(selectedThemeId, this.themes);
      });
    }

    setTimeout(function () {
      const jqueryThemes = $('.themes-carousel');
      if (jqueryThemes.length > 0) {
        jqueryThemes.not('.slick-initialized').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          dots: false,
          prevArrow: '.left-themes',
          nextArrow: '.right-themes',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        });
      }
    }, 500);
  }

  selectTheme(themeId, themes) {
    if (themeId) {
      const themeToSelect = themes.find(theme => theme.id === +themeId);
      if (themeToSelect) {
        this.onlyKeywordNoTheme = false;
        this.selectedTheme = themeToSelect;
        this.getKeywords(this.selectedTheme.id);
      } else {
        this.onlyKeywordNoTheme = true;
        this.getKeywords(themeId);
      }
    }
    this.onlyKeywordNoTheme = false;
  }

  getWorldWideEvents(selectedWorldwideEventId) {
    if (this.worldwideEvents) {
      this.selectWorldwideEvent(selectedWorldwideEventId, this.worldwideEvents);
    } else {
      this.mediaService.getWorldwideEvents().subscribe((worldwideEvents: any) => {
        this.worldwideEvents = worldwideEvents.filter(
          worldwideEvent => worldwideEvent.id !== 8583);
        this.selectWorldwideEvent(selectedWorldwideEventId, this.worldwideEvents);
      });
    }

  }

  selectWorldwideEvent(selectedWorldwideEventId, worldwideEvents) {
    if (selectedWorldwideEventId) {
      const eventToSelect = worldwideEvents.find(worldwideEvent => worldwideEvent.id === +selectedWorldwideEventId);
      if (eventToSelect) {
        this.selectedWorldwideEvent = eventToSelect;
        this.launchSearch();
      }
    }
  }

  setAgloliaIndexes(sortBy) {
    this.currentCoursesIndex = 'courses';
    this.currentMediasIndex = 'medias';
    if (sortBy === 'popular') {
      this.currentCoursesIndex = 'courses_relevance_popular';
    } else if (sortBy === 'rating') {
      this.currentMediasIndex = 'medias_relevance_rating';
    }
  }

  getUser() {
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      if (this.user.courses_bought && this.user.courses_bought.length > 0) {
        this.initPage();
      } else {
        this.loadingUser = true;
      }
      this.userService.getUser(this.user.id, {
        courses_in_progress: 1,
        courses_finished: 1,
        courses_bought: 1,
        media_viewed: 1,
        medias_favorited: 1,
        courses_favorited: 1,
        only_media_ids: 1,
        only_course_ids: 1
      }).subscribe(
        user => {
          this.user = new User({...this.user, ...user});
          if (this.loadingUser) {
            this.initPage();
          }
          this.loadingUser = false;
        },
        err => {
          this.loadingUser = false;
        });
    } else {
      this.initPage();
    }
  }

  initPage() {
    this.subscribeMedias();
    this.subscribeCourses();
    this.subject.pipe(distinctUntilChanged()).subscribe(searchTextValue => {
      this.router.navigate([], {
        queryParams: {
          q: searchTextValue
        },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      });
    });
    const params = [];

    this.obsCombined = combineLatest(this.route.params, this.route.queryParams).pipe(map((params, qparams) => {
      return Object.assign(params, qparams);
    })).subscribe(ap => {
      this.showMediasLoading = true;
      this.showCoursesLoading = true;
      this.medias = [];
      this.courses = [];
      const params = ap['0'];
      const qparams = ap['1'];
      if (params) {
        this.currentTab = params.category;
        this.selectedThemeId = params.themeId;
        this.selectedThemeSlug = params.themeSlug;
        this.selectedKeywordId = params.keywordId;
        this.selectedKeywordSlug = params.keywordSlug;
      }
      if (qparams) {
        this.getWorldWideEvents(qparams.event);
        this.searchQuery = qparams.q;
        this.free = qparams.free;
        this.canAccess = qparams.canAccess;
        this.demo = qparams.demo;
        this.withCertificate = qparams.certificate;
        if (this.currentTab === 'lectures') {
          this.toggleWithCertificate(false);
        }
        this.getThemes(this.selectedThemeId);
      }
      this.selectedLanguages = qparams && qparams.languages ? qparams.languages.split(',') : [];
      this.selectedKeywordsIds = qparams && qparams.keywords ? qparams.keywords.split(',') : [];
      this.setAgloliaIndexes(qparams ? qparams.sortBy : false);
      this.launchSearch();
    });
  }

  subscribeMedias() {
    this.searchMediaParams
      .pipe(debounceTime(300), distinctUntilChanged(), switchMap(term => {
        return term ? this.searchService.searchEntries(term) : of([])
      }))
      .subscribe(
        content => {
          const medias = content.results[0];
          this.mediasHits = medias.nbHits;
          this.mediasPages = medias.nbPages;
          for (let i = 0; i < medias.hits.length; ++i) {
            const tmpMedia = new AcademyMedia(medias.hits[i]);
            tmpMedia.getMedia().enrolled = tmpMedia.getMedia().favorite = tmpMedia.getMedia().isEnrolled(this.user);
            tmpMedia.getMedia().viewed = tmpMedia.getMedia().isViewed(this.user);
            this.medias.push(tmpMedia);
          }

          this.dataReceivedFromWeb['medias'] = true;
          this.localStorage.setItem('LIBRARY-medias_' + environment.domain, content).subscribe(() => {
          });
          this.showMediasLoading = false;
        },
        err => {
        }
      );
  }

  generateUrl() {
    let params;
    const urlParts = [];
    urlParts.push('/');
    urlParts.push('library');
    urlParts.push(this.currentTab);
    let keywords = null;
    if (this.selectedThemeId) {
      urlParts.push('theme');
      urlParts.push(this.selectedThemeId);
      urlParts.push(this.selectedThemeSlug);

      if (this.selectedKeywordsIds.length > 1) {
        keywords = this.selectedKeywordsIds.join(',');
      } else if (this.selectedKeywordId) {
        urlParts.push('keyword');
        urlParts.push(this.selectedKeywordId);
        urlParts.push(this.selectedKeywordSlug);
      }
    }
    params = {
      keywords: keywords,
      languages: this.selectedLanguages.join(','),
      event: this.selectedWorldwideEvent ? this.selectedWorldwideEvent.id : null
    };
    this.router.navigate(urlParts, {queryParams: params, queryParamsHandling: 'merge'})
  }

  subscribeCourses() {
    this.searchCourseParams
      .pipe(distinctUntilChanged(), switchMap(term => term
        ? this.searchService.searchEntries(term) : of([])))
      .subscribe(
        content => {
          const courses = content.results[0];
          this.coursesPages = courses.nbPages;
          this.nbHitsToRemove = 0;
          for (let i = 0; i < courses.hits.length; ++i) {
            const tmpCourse = new Course(courses.hits[i]);
            tmpCourse.enrolled = tmpCourse.isEnrolled(this.user);
            tmpCourse.pctViewed = tmpCourse.getPctViewed(this.user);
            if (tmpCourse.paid_course === true && !tmpCourse.isBoughtByAuth(this.user)) {
              this.nbHitsToRemove++;
            }
            this.courses.push(tmpCourse);
          }
          this.coursesHits = courses.nbHits - this.nbHitsToRemove;
          if (this.coursesHits === 0) {
            this.currentTab = 'lectures';
            this.toggleWithCertificate(false);
            this.generateUrl();
          }
          this.dataReceivedFromWeb['courses'] = true;
          this.localStorage.setItem('LIBRARY-courses_' + environment.domain, content).subscribe(() => {
          });
          this.showCoursesLoading = false;
        },
        err => {
        }
      );
  }

  searchQueryChange(searchTextValue) {
    this.subject.next(searchTextValue);
  }

  getSlug(translations) {
    return this.translationService.getSlugFromTranslations('name', translations);
  }

  themeChange(value) {
    this.showCoursesLoading = true;
    this.showMediasLoading = true;
    this.selectedKeywordsIds = [];
    this.keepSelectedIds = [];
    this.selectedKeywordId = null;
    if (value) {
      this.selectedThemeId = value.id;
      this.selectedTheme = value;
      this.selectedThemeSlug = this.getSlug(this.selectedTheme.translations);
      this.getKeywords(value.id);
    } else { // changed to themes all
      this.selectedThemeId = null;
      this.selectedTheme = null;
      this.selectedThemeSlug = '';
      this.keywords = [];
    }
    this.generateUrl();
  }

  toggleLanguage(language, isChecked: boolean) {
    if (isChecked) {
      this.selectedLanguages.push(language.locale);
    } else {
      this.selectedLanguages = this.selectedLanguages.filter(kw => kw !== language.locale);
    }
    language.checked = isChecked;
    this.generateUrl();

  }

  toggleKeyword(keyword: Keyword, isChecked: boolean) {
    if (isChecked) { // add keyword
      if (!this.selectedKeywordId && this.selectedKeywordsIds.length === 0) {
        this.router.navigate(['/', 'library', this.currentTab, 'theme', this.selectedThemeId, this.selectedThemeSlug,
            'keyword', keyword.id, this.getSlug(keyword.translations)],
          {queryParamsHandling: 'merge'});
      } else if (!this.selectedKeywordId && this.selectedKeywordsIds.length > 0) {
        this.selectedKeywordsIds.push(keyword.id);
        this.router.navigate(['/', 'library', this.currentTab, 'theme', this.selectedThemeId, this.selectedThemeSlug],
          {queryParams: {keywords: this.selectedKeywordsIds.join(',')}, queryParamsHandling: 'merge'});
        this.launchSearch();
      } else {
        if (this.selectedKeywordId) {
          this.selectedKeywordsIds.push(this.selectedKeywordId);
        }
        this.selectedKeywordsIds.push(keyword.id);
        this.generateUrl();
      }
      keyword.checked = true;
    } else { // remove keyword
      if (this.selectedKeywordId) {
        this.selectedKeywordId = null;
        this.generateUrl()
      } else if (this.selectedKeywordsIds && this.selectedKeywordsIds.length > 0) {
        this.selectedKeywordsIds.filter(kw => kw !== +keyword.id);
        const index = this.selectedKeywordsIds.indexOf(+keyword.id, 0);
        this.selectedKeywordsIds = this.selectedKeywordsIds.filter(kw => +kw !== +keyword.id);
        if (this.selectedKeywordsIds.length === 1) {
          this.router.navigate(['/', 'library', this.currentTab, 'theme', this.selectedThemeId, this.selectedThemeSlug,
              'keyword', this.selectedKeywordsIds[0], this.getKeywordSlugFromKeywords(this.selectedKeywordsIds[0])],
            {queryParams: {keywords: null}, queryParamsHandling: 'merge'});
        } else if (this.selectedKeywordsIds.length > 1) {
          this.router.navigate(['/', 'library', this.currentTab, 'theme', this.selectedThemeId, this.selectedThemeSlug],
            {queryParams: {keywords: this.selectedKeywordsIds.join(',')}, queryParamsHandling: 'merge'});
          this.launchSearch();
        }
      }
      keyword.checked = false;
    }
  }

  getKeywordSlugFromKeywords(keywordId) {
    if (this.keywords) {
      const keywordToSelect = this.keywords.find(keyword => +keyword.id === +keywordId);
      return keywordToSelect ? this.getSlug(keywordToSelect.translations) : '';
    } else {
      return '';
    }
  }

  worldwideEventChange(value) {
    this.selectedWorldwideEvent = value;
    this.generateUrl();
  }

  clearFilter() {
    if (this.selectedTheme) {
      this.selectedThemeId = null;
      this.selectedTheme = null;
      this.selectedThemeSlug = '';
      if (this.selectedWorldwideEvent) {
        this.selectedWorldwideEvent = null;
        this.selectedWorldwideEventId = null;
      }
    }
    if (this.selectedWorldwideEvent) {
      this.selectedWorldwideEvent = null;
      this.selectedWorldwideEventId = null;
    }
    this.generateUrl();

  }

  toggleFree(isChecked: boolean) {
    if (isChecked) {
      this.free = true;
    } else {
      this.free = null;
    }
    this.router.navigate([], {
      queryParams: {
        free: this.free,
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  toggleCanAccess(isChecked: boolean) {
    if (isChecked) {
      this.canAccess = true;
    } else {
      this.canAccess = null;
    }
    this.router.navigate([], {
      queryParams: {
        canAccess: this.canAccess,
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  toggleDemo(isChecked: boolean) {
    if (isChecked) {
      this.demo = true;
    } else {
      this.demo = null;
    }
    this.router.navigate([], {
      queryParams: {
        demo: this.demo,
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  toggleWithCertificate(isChecked: boolean) {
    if (isChecked) {
      this.withCertificate = true;
    } else {
      this.withCertificate = null;
    }
    this.router.navigate([], {
      queryParams: {
        certificate: this.withCertificate,
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  onSortCoursesChange(index: string, isChecked: boolean) {
    if (isChecked) {
      this.currentCoursesIndex = index;
    }
    this.router.navigate([], {
      queryParams: {
        sortBy: index === 'courses_relevance_popular' ? 'popular' : 'date',
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  onSortMediasChange(index: string, isChecked: boolean) {
    if (isChecked) {
      this.currentMediasIndex = index;
    }
    this.router.navigate([], {
      queryParams: {
        sortBy: index === 'medias_relevance_rating' ? 'rating' : 'date',
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  searchMedias(): void {
    this.searchMediaParams.next({
      searchType: 'medias',
      searchQuery: this.searchQuery,
      indices: [this.currentMediasIndex],
      currentPage: this.currentMediasPage,
      facetFilters: this.prepareFacetFilters()
    });
  }

  searchCourses(): void {
    this.searchCourseParams.next({
      searchType: 'courses',
      searchQuery: this.searchQuery,
      indices: [this.currentCoursesIndex],
      currentPage: this.currentCoursesPage,
      facetFilters: this.prepareFacetFilters()
    });
  }

  getKeywords(themeId) {
    this.themeService.getKeywordsAcademyMedias(themeId)
      .subscribe(
        keywords => {
          if (this.onlyKeywordNoTheme) {
            keywords = keywords.filter(keyword => keyword.id === parseInt(this.selectedKeywordId, 10));
          }
          this.keywords = keywords;

          for (let i = 0; i < this.keywords.length; i++) {
            const keywordToCheck = this.selectedKeywordsIds.find(kw => +kw === +this.keywords[i].id);
            this.keywords[i].checked = keywordToCheck ||
              (this.selectedKeywordId && +this.selectedKeywordId === +this.keywords[i].id);
          }
        });
  }

  prepareFacetFilters() {
    const facetFilters = [];
    if (this.selectedThemeId) {
      facetFilters.push('themesIds:' + this.selectedThemeId);
    }
    if (this.selectedWorldwideEvent) {
      facetFilters.push('event:' + this.selectedWorldwideEvent.title);
    }
    if (this.free) {
      facetFilters.push('free:true');
    }
    if (this.canAccess) {
      const canAccessThemes = [];
      for (let i = 0; i < this.user.theme_accesses.length; ++i) {
        canAccessThemes.push('themesIds:' + this.user.theme_accesses[i]);
      }
      facetFilters.push(canAccessThemes);
    }
    if (this.demo) {
      facetFilters.push('type:demonstration');
    }
    if (this.withCertificate) {
      facetFilters.push('certificate:true');
    }
    if (this.selectedKeywordId) {
      facetFilters.push('keywordsIds:' + this.selectedKeywordId);
    } else if (this.selectedKeywordsIds) {
      const keywords = [];
      for (let i = 0; i < this.selectedKeywordsIds.length; ++i) {
        keywords.push('keywordsIds:' + this.selectedKeywordsIds[i]);
      }
      facetFilters.push(keywords);
    }
    if (this.selectedLanguages) {
      const languages = [];
      for (let i = 0; i < this.selectedLanguages.length; ++i) {
        languages.push('languages:' + this.selectedLanguages[i]);
      }
      facetFilters.push(languages);
    }
    return facetFilters;
  }

  launchCoursesSearch() {
    this.showCoursesLoading = true;
    this.courses = [];
    this.currentCoursesPage = 0;
    this.searchCourses();
  }

  launchMediasSearch() {
    this.showMediasLoading = true;
    this.medias = [];
    this.currentMediasPage = 0;
    this.searchMedias();
  }

  launchSearch() {
    this.launchCoursesSearch();
    this.launchMediasSearch();
  }

  onCoursesScroll() {
    if (!this.autoscroll) {
      return;
    }
    if (this.currentCoursesPage === this.coursesPages) {
      return;
    }
    this.currentCoursesPage++;
    if (this.currentCoursesPage % 3 === 0) {
      this.autoscroll = false;
    }
    this.searchCourses();
  }

  onMediasScroll() {
    if (!this.autoscroll) {
      return;
    }
    if (this.currentMediasPage === this.mediasPages) {
      return;
    }
    this.currentMediasPage++;
    if (this.currentMediasPage % 3 === 0) {
      this.autoscroll = false;
    }
    this.searchMedias();
  }

  selectTab(tab: string) {
    if (tab === 'courses' && this.demo === true) {
      this.toggleDemo(false);
    } else if (tab === 'lectures' && this.withCertificate === true) {
      this.toggleWithCertificate(false);
    }
    if (this.selectedTheme) {
      this.selectedThemeSlug = this.getSlug(this.selectedTheme.translations);
      if (this.selectedKeywordId) {
        this.router.navigate(['/', 'library', tab,
            'theme', this.selectedThemeId, this.selectedThemeSlug,
            'keyword', this.selectedKeywordId, this.selectedKeywordSlug],
          {queryParamsHandling: 'merge'});
      } else {
        this.router.navigate(['/', 'library', tab,
            'theme', this.selectedThemeId, this.selectedThemeSlug],
          {queryParamsHandling: 'merge'});
      }
    } else if (!this.selectedTheme && this.selectedKeywordId) {
      this.router.navigate(['/', 'library', tab,
          'theme', 26, 'na',
          'keyword', this.selectedKeywordId, this.selectedKeywordSlug],
        {queryParamsHandling: 'merge'});
    } else { // changed to themes all
      this.router.navigate(['/', 'library', tab],
        {queryParams: {keywords: null}, queryParamsHandling: 'merge'});
    }
  }

  redirect(open: boolean) {
    if (!open) {
      this.keepSelectedIds = this.selectedKeywordsIds && this.selectedKeywordsIds.length > 0 ? this.selectedKeywordsIds : this.selectedKeywordId;
    }
    if(isArray(this.keepSelectedIds)) {
      this.router.navigate(['/', 'library', this.currentTab,
          'theme', this.selectedThemeId, this.selectedThemeSlug],
        {
          queryParams: {
            keywords: this.keepSelectedIds.join(','),
          },
          queryParamsHandling: 'merge'
        });
    } else if (isNotNullOrUndefined(this.keepSelectedIds)) {
      this.router.navigate(['/', 'library', this.currentTab, 'theme', this.selectedThemeId, this.selectedThemeSlug,
          'keyword', this.keepSelectedIds, this.getSlug(this.keywords.find(kw => kw.id === +this.keepSelectedIds).translations)],
        {queryParamsHandling: 'merge'});
    }
  }
}

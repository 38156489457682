import {Injectable, NgZone} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {AuthService} from '../auth.service';
import {Router} from "@angular/router";

@Injectable()
export class PushNotificationsService {

  constructor(private authService: AuthService, private ngZone:NgZone, private router: Router) {
  }

  public instanciatePushNotifications() {
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

    if (isPushNotificationsAvailable) {
      console.log('Initializing Dashboard');
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {

          this.authService.registerDevice(token.value);

          console.log('Push registration success, token: ' + token.value);
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
          if (notification.notification.data) {
            const redirectTo = notification.notification.data.redirectTo;
            if (!notification.notification.data.foreground) {
              if (redirectTo) {
                if (localStorage.getItem('personal_token')) {
                  this.ngZone.run(() => {
                    this.router.navigate([redirectTo]);
                  });
                } else {
                  localStorage.setItem('push_redirect', redirectTo);
                }
              }
            }
          }
        }
      );
    }
  }
}

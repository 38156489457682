import {Component, Input, OnInit} from '@angular/core';
import {Media} from '../../../models/media.model';
import {TranslationService} from '../../../services/translation.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LocaleService} from '../../../services/locale.service';


@Component({
    selector: 'app-abstract',
    templateUrl: './abstract.component.html',
    styleUrls: ['./abstract.component.scss'],
    providers: [NgbActiveModal]
})
export class AbstractComponent implements OnInit {
    @Input() media: Media;
    @Input() d: any;
    locale: any;

    constructor(public translationService: TranslationService, public activeModal: NgbActiveModal, private localeService: LocaleService) {
    }
    close() {
        if (this.d) {
            this.d();
        } else {
            this.activeModal.dismiss('Cross click')
        }
    }
    ngOnInit() {
        this.locale = this.localeService.currentLocale();
    }

}

import {AuthService} from 'app/services/auth.service';
import {UserService} from '../../../services/user.service';
import {Specialty} from '../../../models/specialty.model';
import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import {Country} from '../../../models/country.model';
import {ScientificSociety} from '../../../models/scientific-society.model';
import {LocaleService} from '../../../services/locale.service';
import {SpecialtyService} from '../../../services/specialty.service';
import {CountryService} from '../../../services/country.service';
import {ScientificSocietyService} from '../../../services/scientific-society.service';

import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {TranslationService} from '../../../services/translation.service';
import {UserProductService} from '../../../services/user-product.service';
import {User} from '../../../models/user.model';

@Component({
  selector: 'join-component',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
  providers: [
    SpecialtyService,
    CountryService,
    ScientificSocietyService,
    UserService,
    UserProductService
  ]
})

export class JoinComponent implements OnInit {
  @Input() page: string = '';
  mask = ['\d+'];
  password_confirmation: null;
  registrationError: string;
  specialities$: Observable<Specialty[]>;
  countries$: Observable<Country[]>;
  scientificSociety: ScientificSociety;
  selectedCountry: Country;
  selectedSpecialty: Specialty;
  sCountry: Country;
  sSpecialty: Specialty;
  scientificSocietyId: number;
  scientificSocietySlug: string;
  environment: any = environment;
  loading: boolean = false;
  currentUser: User;
  membership: any;
  locale: any;
  user = {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    country_id: null,
    user_specialty_id: null,
    subscribe_web: null,
    subscribe_academy_web: null,
    partners_optin: null,
    discountCode: null,
    password: null,
    from_scientific_society_id: null,
    password_confirmation: null
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private localeService: LocaleService,
    public translationService: TranslationService,
    private specialtyService: SpecialtyService,
    private countryService: CountryService,
    private scientificSocietyService: ScientificSocietyService,
    private userService: UserService,
    private authService: AuthService,
    private userProductService: UserProductService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.scientificSocietyId = params['id'];
      this.scientificSocietySlug = params['slug'];
      this.getScientificSociety();
    });
    this.locale = this.localeService.currentLocale();
    this.getUser();
    this.getCountries();
    this.getSpecialties();
  }

  getSpecialties() {
    this.specialities$ = this.specialtyService.getSpecialties().pipe(tap(data => this.selectedSpecialty = data[0]));
  }

  getCountries() {
    this.countries$ = this.countryService.getCountries().pipe(tap(data => this.selectedCountry = data[0]));
  }

  getUser() {
    this.currentUser = this.authService.getCurrentUser();
    if (this.currentUser.id) {
      this.userService.getUserMembershipStatus(this.currentUser.id).subscribe(
        membership => {
          this.membership = membership
        }
      );

    }

  }

  getScientificSociety() {
    this.scientificSocietyService.getScientificSociety(this.scientificSocietyId).subscribe(scientificSociety => {
      if (scientificSociety.academy_trial_page !== 1) {
        this.router.navigateByUrl('/not-found');
      }
      this.scientificSociety = new ScientificSociety(scientificSociety);
    });
  }

  specialtyChanged(value) {
    this.selectedSpecialty = value;
  }

  countryChanged(value) {
    this.selectedCountry = value;
  }

  signUp() {
    this.loading = true;
    if (this.selectedCountry && this.selectedSpecialty) {
      this.user.country_id = this.selectedCountry.id;
      this.user.user_specialty_id = this.selectedSpecialty.id;
    }

    const loginData = {
      username: this.user.email,
      password: this.user.password,
      platform: ''
    };

    this.user.subscribe_web = this.user.subscribe_web ? 1 : 0;
    this.user.subscribe_academy_web = this.user.subscribe_web ? 1 : 0;
    this.user.partners_optin = this.user.partners_optin ? 1 : 0;
    this.user.from_scientific_society_id = this.scientificSocietyId;
    this.userService.registerUser(this.user).subscribe(
      userRegistered => {
        this.authService.loginMainWebsite({email: loginData.username, password: loginData.password}).subscribe(() => {

        }, err => {

        });
        this.authService.login(loginData).subscribe(
          logged => {
            this.authService.setSession(logged);
            this.userProductService.createFromScientificSociety(this.scientificSocietyId).subscribe(result => {
              this.loading = false;
              this.router.navigate(['/account/create/step-2'])
            });
          },
          logErr => {
            this.registrationError = logErr;
          });

      },
      regError => {
        this.registrationError = '<ul>';
        if (regError.error) {
          for (const ruleKey in regError.error.rules) {
            if (ruleKey === 'email') {
              this.registrationError += '<li>' + this.translateService.instant('academy.Email_taken_sign_up', {
                link: environment.base_url + '/academy/account/login'
              }) + '</li>';

            } else if (regError.error.rules.hasOwnProperty(ruleKey)) {

              regError.error.rules[ruleKey].forEach((element) => {
                this.registrationError += '<li>' + element + '</li>';
              });
            }
          }
        }
        this.registrationError += '</ul>';
        this.loading = false;
      }
    );
  }

  signUpLogged() {
    this.loading = true;
    this.user.subscribe_web = this.user.subscribe_web ? 1 : 0;
    this.user.subscribe_academy_web = this.user.subscribe_web ? 1 : 0;
    this.user.partners_optin = this.user.partners_optin ? 1 : 0;
    this.userProductService.createFromScientificSociety(this.scientificSocietyId).subscribe(result => {
      this.userService.updateUser({
        subscribe_web: this.user.subscribe_web,
        subscribe_academy_web: this.user.subscribe_academy_web,
        partners_optin: this.user.partners_optin
      }).subscribe(success => {
        this.loading = false
        this.router.navigate(['/account/current-plan'])
      });
    });
  }


}

import {ActivatedRoute, Router} from '@angular/router';
import {
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import {TranslationService} from 'app/services/translation.service';
import {AlertService} from 'app/services/alert.service';
import {environment} from '../../../../environments/environment';
import {DateMomentService} from 'app/services/date-moment.service';
import {User} from 'app/models/user.model';
import {Comment} from 'app/models/comment.model';
import {AuthService} from 'app/services/auth.service';
import {LocaleService} from '../../../services/locale.service';
import {UserService} from '../../../services/user.service';
import {AcademyMedia} from '../../../models/academy-media.model';
import {ConfirmationComponent} from '../../modal/confirmation/confirmation.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Case} from '../../../models/case.model';
import {KeywordService} from "../../../services/keyword.service";
import {HistoryService} from "../../../services/mobile-services/history.service";
import {Capacitor} from "@capacitor/core";
import {of} from "rxjs";
import * as $ from "jquery";
import {FilesService} from "../../../services/files.service";

export class ExtendComment extends Comment {
    can_be_edited$: any;
    edit_comment_mode: boolean;
    edit_files_mode:boolean;
    user_id: number;

    constructor(obj?:any) {
        super(obj);
        this.can_be_edited$ = obj && obj.can_be_edited$;
        this.edit_comment_mode = obj && obj.edit_comment_mode;
        this.user_id = obj && obj.user_id;
        this.edit_files_mode = obj && obj.edit_files_mode;
    }
}
@Component({
    selector: 'alert-topic',
    templateUrl: './alert-topic.component.html',
    styleUrls: ['./alert-topic.component.scss'],
    providers: [
        AlertService,
        UserService,
        KeywordService,
        FilesService
    ]
})
export class AlertTopicComponent implements OnInit {
    @ViewChild('focusInput', {static: false}) vc: ElementRef;
    @ViewChild('DivCollapse', {static: false}) divCollapse!: ElementRef<HTMLDivElement>;
    environment: any = environment;
    currentUser: User;
    locale: any;
    groupSelect: any;
    slug: string;
    group: any;
    groups: any;
    topicId: number;
    topicSlug: string;
    followRequestPending: boolean;
    anonymous: any = 1;
    case: Case;
    groupCaseId: number;
    groupCaseId2: number;
    groupSubmit: boolean = false;
    replyToComment: any;
    case_comments: Array<ExtendComment>;
    case_wrapups: Array<ExtendComment>;
    commentSubmitted: boolean;
    imagesToUpload: {text: string, file: File, type: string}[] = [];
    videos_related: any = [];
    loadingCase: boolean;
    comment: Comment = new Comment({entity_type: 'alert_case'});
    rulesAccepted: boolean = false;
    error: string | boolean;
    closing: boolean = false;
    acceptedFormat: string ='';
    extension: string = '';
    defaultElt: any;
    coordinatorLoading: boolean = true;
    open: boolean = false;
    fileName: string = '';
    filesAnswerError: string = '';
    commentError: boolean = false;
    canAnswerCaseEditMode: boolean = true;

    constructor(private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                public translationService: TranslationService,
                public dateMomentService: DateMomentService,
                private router: Router,
                private modalService: NgbModal,
                private userService: UserService,
                private localeService: LocaleService,
                private authService: AuthService,
                public historyService: HistoryService,
                public filesService: FilesService) {
        this.acceptedFormat = filesService.acceptedFormat;
    }


    ngOnInit() {
        this.commentSubmitted = false;
        this.coordinatorLoading = true;
        this.loadingCase = true;
        this.error = false;
        this.locale = this.localeService.currentLocale();
        this.activatedRoute.params.subscribe(params => {
            this.slug = params['group'];
            this.topicId = params['topicId'];
            this.topicSlug = params['topicSlug'];
        });
        this.currentUser = this.authService.getCurrentUser();
        if (!this.currentUser || !this.currentUser.id) {
            this.router.navigate(['/', 'account', 'login']);
        } else if (!this.currentUser.specialty) {
            this.userService.getUser(this.currentUser.id, {user_specialty: 1}).subscribe(user => {
                Object.assign(this.currentUser, user);
            })
            this.authService.isLoggedIn().subscribe(logged => {
                if (!logged) {
                    this.router.navigate(['/account/login']);
                }
            });
            this.alertService.getGroups().subscribe(groups => {
                this.groups = groups;
                this.alertService.getCase(this.topicId, this.topicSlug).subscribe(caseObj => {
                    this.loadingCase = false;
                    this.videos_related = [];
                    this.case = caseObj;
                    this.groupCaseId = caseObj.group.id;
                    this.groupCaseId2 = caseObj.group_2 && caseObj.group_2.id ? caseObj.group_2.id : null;
                    this.defaultElt = this.groups.find(elt => elt.id === this.groupCaseId2);
                    this.groupSelect = this.defaultElt ? this.defaultElt.id : '';
                    for (let i = 0; i < caseObj.videos_related.length; ++i) {
                        this.videos_related.push(new AcademyMedia(caseObj.videos_related[i]));
                    }
                    if (!this.case.id) {
                        this.router.navigate(['/', 'alert', this.slug])
                    }
                    this.alertService.getGroup(this.slug, {
                        coordinators: '1',
                        with_treatments: '1',
                        with_themes: '1',
                        keywords: '1',
                        complications: '1',
                        treatments: '1',
                        purposes: '1',
                        themes: '1'
                    }).subscribe(params => {
                        this.group = params;
                        this.coordinatorLoading = false;
                    }, error => {
                        this.router.navigate(['/alert', this.slug]);
                    });
                    this.groupComments();
                    jQuery(() => {
                        setTimeout(() => {
                            jQuery('.fancybox').fancybox({
                                closeEffect: 'none',
                                beforeShow: function () {
                                    $('.fancybox-overlay').on('contextmenu', function (e) {
                                        return false;
                                    });
                                }
                            });
                        }, 100);
                    });

                });
            }, error => {
                this.router.navigate(['/alert', this.slug]);
            })

            this.comment.content = '';
        }
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s<]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })

    }

    groupComments() {
        if (this.case.comments) {
            const wrap_up_comments = [];
            this.case.comments.forEach((c) => {
                if (c.case_closing === 1) {
                    if (!c.user_id) {
                        c['can_be_edited$'] = this.alertService.canEditAnonymousComment(c.id);
                    } else if (c.user_id === this.currentUser.id) {
                        c['can_be_edited$'] =  of(true);
                    } else {
                        c['can_be_edited$'] = of(false);
                    }
                    wrap_up_comments.push(c);
                }
            });
            this.case_wrapups = this.grapComments(wrap_up_comments);
            const comments_not_assigned = Array.from(this.case.comments.filter(c => c.case_closing !== 1));
            comments_not_assigned.forEach((c: ExtendComment, i) => {
                c['comments'] = []
                if (!c.user_id) {
                    c['can_be_edited$'] = this.alertService.canEditAnonymousComment(c.id);
                } else if (c.user_id === this.currentUser.id) {
                    c['can_be_edited$'] =  of(true);
                } else {
                    c['can_be_edited$'] = of(false);
                }
            });
            this.case_comments = this.grapComments(comments_not_assigned);
        }
    }

    grapCommentsFor(comment, orphans) {
        comment = new ExtendComment(comment);
        comment.comments = [];
        orphans.forEach((c, i) => {
            if (c.reply_to === comment.id) {
                if (!c.user_id) {
                    c['can_be_edited$'] = this.alertService.canEditAnonymousComment(c.id);
                } else if (c.user_id === this.currentUser.id) {
                    c['can_be_edited$'] = of(true);
                } else {
                    c['can_be_edited$'] = of(false);
                }
                const com = this.grapCommentsFor(c, Array.from(orphans.filter(o => o.id !== c.id)));
                comment.comments.push(new ExtendComment(com));
            }
        });
        return comment;
    }

    grapComments(comments_left) {
        const top = comments_left.filter(c => !c.reply_to);
        const orphans = comments_left.filter(c => c.reply_to);
        const comments = [];
        top.forEach((c, i) => {
            comments.push(this.grapCommentsFor(c, orphans));
        });
        return comments;
    }

    replyTo(comment: any) {
        this.setFocus();
        this.replyToComment = comment;
        this.closing = false;
    }

    cancelReplyTo() {
        this.replyToComment = null;
    }

    is_premium(): boolean {
        if (this.group) {
            return this.group.user_access === 'granted';
        }
        return false;
    }

    is_physician(): boolean {
        if (this.currentUser) {
            return (this.currentUser.specialty && this.currentUser.specialty.doctor) || this.currentUser.specialty_category === 'physician';
        }
        return false;
    }

    is_group_member(): boolean {
        if (this.group) {
            if (this.is_physician() && this.group.user_access === 'unauthorized') {
                return false;
            }
            return (this.group.user_access === 'granted' || this.group.user_access === 'not-premium');
        }
        return false;
    }

    isGroupCoordinator(): boolean {
        return this.group.coordinators.find(user => user.id === this.currentUser.id);
    }

    canReply(): boolean {
        return this.case.open ? this.is_premium() || this.is_group_member() : false;
    }


    commentFilesUpload(event) {
        const returnValue = this.filesService.uploadFiles(event, this.imagesToUpload);
        if (returnValue.error.name.length > 0) {
            switch (returnValue.error.name) {
                case 'Size' || 'Error': {
                    this.filesAnswerError = returnValue.error.name;
                    this.fileName = returnValue.error.subTextError;
                    break;
                }
                default: {
                    this.filesAnswerError = returnValue.error.name;
                }
            }
            setTimeout(() => this.filesAnswerError = '', 5000);
        } else {
            this.imagesToUpload = returnValue.newFilesList;
        }
    }

    setFocus(): void {
        if (this.vc.nativeElement) {
            this.vc.nativeElement.focus();
        }
    }

    followCase(event) {
        this.followRequestPending = true;
        this.alertService.followCase(this.case.id).subscribe(params => {
            this.followRequestPending = false;
            this.case.subscriber = !this.case.subscriber;
        });
    }

    joinGroup() {
        if (this.rulesAccepted === true && this.group) {
            this.alertService.acceptTerms(this.group.id, this.rulesAccepted).subscribe(params => {
                this.group.user_access = 'waiting';
            });
        }
    }

    isAuthor() {
        return this.currentUser.id === this.case.user_id;
    }

    submitComment() {
        this.comment.case_closing = 0;
        if (this.replyToComment && !this.replyToComment.reply_to) {
            this.comment.reply_to = this.replyToComment.id;
        } else {
            delete this.comment.reply_to;
        }

        this.comment.entity_id = this.case.id;
        if (this.case.can_choose_anonymity) {
            this.comment.anonymous = this.anonymous ? 1 : 0;
        } else if (!this.isAuthor()) {
            this.comment.anonymous = 0;
        }
        if (!this.case.anonymous) {
            this.comment.anonymous = 0;
        }

        const data= new FormData();
        this.imagesToUpload.forEach((file, index) => {
            data.append('files['+ index +'][content]', file.file, file.text);
            data.append('files[' + index +'][name]', file.text);
            data.append('files[' +index + '][type]', file.type);
        })
        if (this.comment.content && typeof this.comment.content !== 'undefined') {
            this.commentSubmitted = true;
            this.alertService.addCaseComment(this.case.id, this.comment).subscribe(params => {
                const images_for_comment = this.imagesToUpload && this.imagesToUpload.length > 0;
                if (images_for_comment === true) {
                    data.append('alert_id', this.case.id.toString());

                    this.alertService.addFiles(params.id, data).subscribe(r => {
                        if(Capacitor.getPlatform() !== 'web') {
                            this.comment = new Comment({entity_type: 'alert_case'});
                            this.alertService.getCase(this.topicId, this.topicSlug).subscribe(r => {
                                this.imagesToUpload = [];
                                this.case = r;
                                this.groupComments();
                                this.commentSubmitted = false;
                            }, error => {
                                this.commentSubmitted = false;
                                if (error.status === 403) {
                                    this.error = 'alert.Error_not_doctor';
                                } else {
                                    this.error = 'alert.Something_went_wrong';
                                }
                            });
                        }  else {
                            window.location.reload();
                        }
                    });
                }
                this.comment = new Comment({entity_type: 'alert_case'});
                this.alertService.getCase(this.topicId, this.topicSlug).subscribe(r => {
                    this.case = r;
                    this.groupComments();
                    this.commentSubmitted = false;
                }, error => {
                    this.commentSubmitted = false;
                    if (error.status === 403) {
                        this.error = 'alert.Error_not_doctor';
                    } else {
                        this.error = 'alert.Something_went_wrong';
                    }
                });
            }, error => {
                this.commentSubmitted = false;
                if (error.status === 403) {
                    this.error = 'alert.Error_not_doctor';
                } else {
                    this.error = 'alert.Something_went_wrong';
                }
            });
        }
    }

    typeComment(event) {
        if (!this.case.subscriber && !this.followRequestPending) {
            this.followCase(event);
        }
    }

    openCase() {
        const modalRef = this.modalService.open(ConfirmationComponent);
        modalRef.componentInstance.title = 'alert.Open_your_case';
        modalRef.result.then(result => {
            this.alertService.openOrCloseCase(this.case.id)
                .subscribe(r => {
                    this.commentSubmitted = true;
                    this.alertService.getCase(this.topicId, this.topicSlug).subscribe(res => {
                        this.case = res;
                        this.groupComments();
                        this.commentSubmitted = false;
                    });
                });
        });
    }

    closeCase() {
        const modalRef = this.modalService.open(ConfirmationComponent);
        modalRef.componentInstance.title = 'alert.Close_your_case';
        modalRef.result.then(result => {
            this.alertService.openOrCloseCase(this.case.id).subscribe(() =>{
                if (this.comment.content && typeof this.comment.content !== 'undefined') {
                    delete this.comment.reply_to;
                    this.comment.entity_id = this.case.id;
                    this.comment.case_closing = 1;
                    this.commentSubmitted = true;
                    if (this.case.can_choose_anonymity) {
                        this.comment.anonymous = this.anonymous ? 1 : 0;
                    } else if (!this.isAuthor()) {
                        this.comment.anonymous = 0;
                    }
                    if (!this.case.anonymous) {
                        this.comment.anonymous = 0;
                    }
                    const data= new FormData();
                    this.imagesToUpload.forEach((file, index) => {
                        data.append('files['+ index +'][content]', file.file, file.text);
                        data.append('files[' + index +'][name]', file.text);
                        data.append('files[' +index + '][type]', file.type);
                    })
                    this.alertService.addCaseComment(this.case.id, this.comment).subscribe(params => {
                        const images_for_comment = this.imagesToUpload && this.imagesToUpload.length > 0;
                        if (images_for_comment === true) {
                            data.append('alert_id', this.case.id.toString());
                            this.alertService.addFiles(params.id, data).subscribe(r => {});
                        }
                        this.comment = new Comment({entity_type: 'alert_case'});

                        this.alertService.getCase(this.topicId, this.topicSlug).subscribe(res => {
                            this.case = res;
                            this.imagesToUpload = [];
                            this.groupComments();
                            this.commentSubmitted = false;
                        });

                    });
                } else {
                    this.commentSubmitted = true;
                    this.alertService.getCase(this.topicId, this.topicSlug).subscribe(res => {
                        this.case = res;
                        this.imagesToUpload = [];
                        this.groupComments();
                        this.commentSubmitted = false;
                    });
                }
            });
        });
    }
    removeFromList(index: number) {
        this.imagesToUpload.splice(index, 1);
    }

    isPicture(picture) {
        return this.filesService.isPicture(picture);
    }

    openInfos() {
        this.open = !this.open;
        this.open ? this.divCollapse.nativeElement.classList.add('show') : this.divCollapse.nativeElement.classList.remove('show');
    }

    reloadCase($event: boolean) {
        this.loadingCase = true;
        if ($event) {
            this.alertService.getCase(this.topicId, this.topicSlug).subscribe(r => {
                this.imagesToUpload = [];
                this.case = r;
                this.groupComments();
                this.commentSubmitted = false;
                this.loadingCase = false;
            }, error => {
                this.commentSubmitted = false;
                if (error.status === 403) {
                    this.error = 'alert.Error_not_doctor';
                } else {
                    this.error = 'alert.Something_went_wrong';
                }
            });
        } else {
            this.loadingCase = false;
        }
    }

    setRight($event: boolean) {
     this.canAnswerCaseEditMode = $event;
    }
}

export const Locales = {
  'en':
    {
      name: 'English',
      locale: 'en'
    },
  'es':
    {
      name: 'Español',
      locale: 'es'
    },
  'ru':
    {
      name: 'Русский',
      locale: 'ru'
    },
  'zh':
    {
      name: '简体中文',
      locale: 'zh'
    },
  'fr':
    {
      name: 'Français',
      locale: 'fr'
    },
  'pt': {
    name: 'Português',
    locale: 'pt'
  }
};

import {Component, OnInit} from '@angular/core';
import {LocaleService} from '../../../../services/locale.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
    env = environment;
    locale: any;
    constructor(public localeService: LocaleService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
    }
}

<div class="course-content" (click)="goToCourse(course)"
     [ngClass]="{'course-completed': user && course.getPctViewed(user) === 100, 'course-onhover': course.getSpeakers() }">
    <div class="course-image">
        <div class="course-badges">
            <span class="sponsored" *ngIf="course.sponsored == true">{{'academy.Sponsored'|translate}}</span>
            <span class="certificate" *ngIf="course.certificate_cme === true">{{ 'certificate.Tag'|translate }}</span>
            <span class="free"
                  *ngIf="course.free === true && course.others === 'webinar' && (!user || (user && !user.premium_membership))">{{'academy.Free_webinar'|translate}}</span>
            <span class="free"
                  *ngIf="course.free === true && course.others === 'webinar' && (user && user.premium_membership)">{{'sponsor.Family_Webinar'|translate}}</span>
            <span class="free"
                  *ngIf="course.free === true && course.others !== 'webinar' && (!user || (user && !user.premium_membership))">{{'academy.Free'|translate}}</span>
            <span class="certificate"
                  *ngIf="course.board_selection === true">{{'academy.Board_selection'|translate}}</span>
            <span class="enrolled" *ngIf="course.isEnrolled(user)">{{'academy.Favorited'|translate}}</span>
            <span class="new" *ngIf="dateMomentService.isNew(course.getDate())">{{'academy.New'|translate}}</span>
          <span class="highlighted" *ngIf="course.highlighted"><i class="fa fa-star-media fa-star"></i> {{'academy.Featured'|translate}}</span>
        </div>
        <div class="course-progress" *ngIf="user && course.getPctViewed(user) > 0">
            <div class="course-progress-bar" [ngStyle]="{'width': course.getPctViewed(user) + '%'}"></div>
            <div class="course-progress-number">{{course.getPctViewed(user)}}%</div>
        </div>
        <img [src]="course.getPictureUrl()" *ngIf="course.getPictureUrl()">
        <div class="course-image-hover-content">
            <div class="course-image-hover-content-bg"></div>
            <span class="course-image-hover-content-view">{{ 'academy.view_course'|translate}}</span>

        </div>
    </div>
    <div [ngClass]="'course-body'">
        <div class="course-body-wrapper">
            <h4 class="course-body-title">{{course.courseTitles ?
                translationService.getTranslatedTitle(course.courseTitles, locale) :
                translationService.getTranslatedAttribute('title', course.translations, locale)}}</h4>
            <div class="course-body-info">
                <p *ngIf="course.getMediaCount() > 0">{{course.getMediaCount()}} {{ course.getMediaCount() === 1 ?
                    ('pluralize.Videos'|translate).split('|')[0] : ('pluralize.Videos'|translate).split('|')[1] }}</p>
                <span *ngIf="course.getDate() && showPublicationDate">
                    {{'webinar.Published_on'|translate}} {{course.getDateFormatted()}} {{course.event ? ' - ' + ('academy.Recorded_during'|translate) + ' ' +
                    (course.event) : ''}}
                </span>
            </div>
        </div>
    </div>
    <div class="course-body-bottom" [ngClass]="{'no-progress': !course.percentage_viewed}">
        <div class="course-body-authors">
            <p [innerHTML]="course.getSpeakersTitleFormatted()"></p>
        </div>
        <div class="course-body-themes">

            <span *ngFor="let theme of course.getThemesIds()" placement="top"
                  [ngbTooltip]="course.themes && course.themes[locale] ? course.themes[locale][theme] :''"
                  [ngClass]="'icon-theme icon-theme_' + theme"></span>
        </div>
        <div class="course-body-rating">
            <ngb-rating [(rate)]="course.rating" max="5" [readonly]="true">
                <ng-template let-fill="fill">
                    <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                    <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                    <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
                </ng-template>
            </ngb-rating>

        </div>
        <span *ngIf="course.getDate()" class="mobile-only">{{course.getDateFormatted()}}
											</span>
    </div>

    <div class="course-around-progress" *ngIf="course.percentage_viewed">
      <div class="course-progress">
        <div class="course-progress-bar" [ngStyle]="{'width': course.percentage_viewed + '%'}"></div>
        <div class="course-progress-number">{{course.percentage_viewed}}%</div>
      </div>
    </div>
</div>
<div class="course-actions" *ngIf="actions">
    <a [href]="course.certificate_url ? environment.base_url + '/' +  course.certificate_url : environment.base_url + '/academy/courses/certificate/' + course.id"
       class="course-actions-button download" *ngIf="course.certificate_url">Download certicate</a>
    <a [routerLink]="['/course', course.id, course.slug]" class="course-actions-button download"
       [queryParams]="{modal: 'certificate'}" *ngIf="course.mcq && !course.certificate_url">Get Certificate</a>
    <a [href]="environment.base_url + '/academy/course/'+course.id+'/certificate/'+userToken" class="course-actions-button download"
       *ngIf="!course.mcq && course.with_certificate && course.percentage_viewed === 100" target="_blank">
      Get Certificate
      <i class="fa fa-certificate"></i></a>
  <a [routerLink]="['/course', course.id, course.slug]" class="course-actions-button watch">{{'account.Watch_again'|translate}}</a>
</div>

import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Locales} from '../locales';
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {LocalStorage} from "@ngx-pwa/local-storage";

@Injectable()

export class LocaleService {
  locales = Locales;
  locale: any;

  constructor(private translate: TranslateService, private authService: AuthService, private localeStorage: LocalStorage,
    private router: Router) {
  }

  validateLocale(locale) {
      if (this.doLocaleExist(locale)) {
        this.translate.use(locale);
      } else {
        this.translate.use('en');
      }
  }

  currentLocale() {
    if (environment.mobile) {
      this.locale = this.authService.getCurrentUser().language;
    } else {
      this.locale = window.location.pathname.substr(1, 2);
    }
    localStorage.setItem('lang', this.locale);
    return (this.doLocaleExist(this.locale) ? this.locale : 'en');
  }

  doLocaleExist(locale) {
    if (_.isEmpty(locale)) {
      return false;
    }
    const localeSearchResult = _.find(this.locales, language => {
      return language.locale === locale;
    });

    return !_.isEmpty(localeSearchResult);
  }
}

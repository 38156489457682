import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class AcademyBoardService {
  private articleEndpoint: string = environment.apiURL + '/academy_boards';

  constructor(private httpClient: HttpClient) { }

  public get(theme_id?: number, country_id?: number, connections?: any, all?: any, user_country?: any,
             user_specialty?: any, order_by?: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('all', '1');
    if (connections) {
      params = params.set('connections', '1');
    }
    if (user_country) {
      params = params.set('user_country', '1');
    }
    if (user_specialty) {
      params = params.set('user_specialty', '1');
    }
    if (order_by) {
      params = params.set('order_by', order_by);
    }
    if (theme_id) {
      params = params.set('theme_id', '' + theme_id);
    }
    if (country_id) {
      params = params.set('country_id', '' + country_id);
    }

    return this.httpClient.get<any>(this.articleEndpoint, {params: params}).pipe(shareReplay());
  }

}

<div class="header-profile">
  <div *ngIf="logged">
    <div class="header-signup">
      <div class="header-profile-item header-profile-user dropdown dropdown-hover dropdown-right">
        <a>
          <img *ngIf="user.id" src="{{ user.picture_url }}" class="avatar"/>
          <span class="header-profile-user-name">{{user?.first_name}}</span>
          <span class="arrow-down"></span>
        </a>
        <div class="dropdown-content">
          <a (click)="menuClick()" routerLink="/account" class="dropdown-item">{{'live.Your_account'|translate}}</a>
          <a  (click)="menuClick()" routerLink="/contact" class="dropdown-item">{{'aop_layout.Contact_us'|translate}}</a>
          <span class="dropdown-item logout" (click)="logout()">
            {{'account.Logout_button'|translate}}
          </span>
        </div>
      </div>
      <div class="header-messages">
      <div class="dropdown dropdown-hover dropdown-right">
        <a class="header-profile-item header-profile-alert">
          <span class="icon icon-alert"></span><span class="unread-count" *ngIf="alertUnread?.length > 0">{{alertUnread?.length}}</span>
        </a>
        <app-notifications class="dropdown-content" (notificationViewed)="setAlertUnread()" *ngIf="notifications" [notifications]="notifications"></app-notifications>
      </div>
      <a routerLink="/inbox" class="header-profile-item header-profile-message" (click)="menuClick()">
        <span class="icon icon-message"></span><span class="unread-count" *ngIf="messageUnreadCount > 0">{{messageUnreadCount}}</span>
      </a>
    </div>
      <div class="header-subscribe" *ngIf="(user.id && !user.premium_membership) && environment.domain === 'aesthetics'" (click)="clickSubscribe()"><a [routerLink]="'/premium'">{{ 'layout.Subscribe'|translate }}</a></div>
    </div>
  </div>
  <div *ngIf="!logged">
    <div class="header-login header-profile-item">
      <a (click)="goToLogin(); menuClick()">{{'account.Login_button'|translate}}</a>
    </div>
    <div class="header-profile-item header-signup">
      <a routerLink="/account/create" class="button button-orange" (click)="menuClick()">
        {{'account.Sign_up_now'|translate}}
      </a>
    </div>
  </div>
</div>

<div class="container" *ngIf="academyMedia.length > 0">
  <ng-container *ngFor="let lecture of academyMedia">
      <div class="lecture" [class.account]="!dashboard">
          <div class="preview" [ngStyle]="{'background-image': 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(\''+lecture.getThumb('rectangular')+'\')'}" (click)="goToLecture(lecture)">
              <div class="play-image"><i class="fal fa-play"></i></div>
          </div>
          <div class="content_lecture">
              <div class="lecture-header">
                  <p class="title" [innerHTML]=" (translationService.getTranslatedTitle(lecture.getMediaTitles(), locale).length>56)? (translationService.getTranslatedTitle(lecture.getMediaTitles(), locale) | slice:0:56)+'...':(translationService.getTranslatedTitle(lecture.getMediaTitles(), locale)) " (click)="goToLecture(lecture)"></p>

              </div>

              <div class="infos">
                  <p>
                    {{'academy.by'|translate}} 
                      <a (click)="goToUserProfile(speaker.getProfileUrl())" *ngIf="lecture.getSpeakersArr().length > 0">{{lecture.getSpeakersArr()[0].getFullName()}}
                      <ng-container *ngIf="lecture.getSpeakersArr().length>1">...</ng-container>
                    </a>
                    <ng-container *ngIf="!dashboard">
                      <div class="btn-remove" (click)="deleteVideo(lecture)" ><i class="fa fa-times" aria-hidden="true"></i></div>
                    </ng-container>
                  </p>
                  <p class="infos-date"> {{getPublishedDate(lecture)}}</p>
                
                  

              </div>
              <div class="ranking" *ngIf="lecture.rating">
                
                

                  <ng-container *ngFor="let star of [0, 1, 2, 3 ,4]"> 
                    <i class="far fa-star-media fa-star" *ngIf="lecture.rating < star"></i>
                    <i class="fas fa-star-half-alt" *ngIf="lecture.rating >= star && lecture.rating <= (star + 0.5)"></i>
                    <i class="fa fa-star-media fa-star" *ngIf="lecture.rating > (star + 0.5)"></i>
                  </ng-container>
                
                


              </div>
          </div>
      </div>
      <hr />
  </ng-container>
</div>

<div class="row courses" *ngIf="academyMedia.length == 0">
  <div class="col-12">
    <p>{{ 'application.No_videos_stored'|translate }}</p>
    <button class="btn button-orange" [routerLink]="'/library/courses'">{{ 'academy.Browse_through'|translate }}</button>
  </div>
</div>
import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-download-app',
    templateUrl: './download-app.component.html',
    styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {
    environment: any = environment;
    constructor() {
    }

    ngOnInit() {
    }
    isDesktop() {
        const ua = this.getMobileOperatingSystem();
        if (ua === 'Android') {
            return false;
        } else if (ua === 'iOS') {
            return false;
        }
        return true;
    }
    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor;
        // Windows Phone must come before Android because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }
        if (/android/i.test(userAgent)) {
            return 'Android';
        }
        if (/MSIE|Edge|Trident/.test(userAgent)) {
            return 'IE/Edge';
        }
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'iOS';
        }
        return 'unknown';
    }

}

<div class="article-page page">
    <app-loader *ngIf="loadingArticle"></app-loader>

    <div class="content" *ngIf="article && !loadingArticle">
        <a [routerLink]="['/blog']" class="return-button button button-blue" style="margin-bottom: 20px">
            <i class="fa fa-chevron-left" style="margin-right: 5px"></i>{{'academy.Return'|translate}}
        </a>
        <div class="article-author media" *ngIf="article.authors">
            <a *ngIf="article.authors.length === 1" href="" routerLink="/profile/{{article.authors[0].slug}}">
                <img class="article-author-thumb rounded-circle" [src]="article.authors[0].picture_url">
            </a>
            <div class="media-body">
                <div class="article-author">
                    <h6>
                        <ng-container *ngFor="let author of article.authors; index as i">
                            {{(i > 0 ? ('academy.And'|translate) : '') + ' ' }}<a href="" routerLink="/profile/{{author.slug}}" style="color:black">{{ author.title + ' ' + author.first_name +
                            ' ' + author.last_name }}</a>
                            <app-connect-btn *ngIf="article.authors.length === 1" [profile]="author"></app-connect-btn>
                        </ng-container>
                    </h6>
                    <p *ngIf="article.authors.length === 1">{{(article.authors[0].specialty ?
                        translationService.getTranslatedName(article.authors[0].specialty.translations, locale) :
                        '')}}</p>
                    <p *ngIf="article.authors.length === 1">{{(article.authors[0].country ?
                      translationService.getTranslatedName(article.authors[0].country.translations, locale) :
                      '')}}</p>
                </div>
                <div class="article-details">
                    <span>{{dateMomentService.formatHumanWay(article.publication_date)}}</span>
                    <span>{{('blog.X_min_read'|translate:{nb: article.min_to_read})}}</span>
                </div>
            </div>
        </div>
        <ng-template #disclosures>
            <div class="modal-body modal-no-title">
                <button type="button" class="close" aria-label="Close" (click)="modal.close()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
                <div class="faq">
                    <h2>{{article.title}}</h2>
                    <h5>{{'academy.Disclosures'|translate}}</h5>
                    <div class="faq-list" *ngIf="article.disclosures != null">
                        <div class="faq-item" *ngIf="article.disclosures.disclosure1 !== null">
                            <h6>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                {{'abstract.Disclosure_question1'|translate}}</h6>
                            <p>{{article.disclosures.disclosure1 == 1 ? ('abstract.Yes'|translate) :
                                ('abstract.No'|translate)}}</p>
                        </div>
                        <div class="faq-item" *ngIf="article.disclosures.disclosure2 !== null">
                            <h6>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                {{'abstract.Disclosure_question2'|translate}}</h6>
                            <p>{{article.disclosures.disclosure2 == 1 ? ('abstract.Yes'|translate) :
                                ('abstract.No'|translate)}}</p>
                        </div>
                        <div class="faq-item" *ngIf="article.disclosures.disclosure3 !== null">
                            <h6>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                {{'abstract.Disclosure_question3'|translate}}</h6>
                            <p>{{article.disclosures.disclosure3 == 1 ? ('abstract.Yes'|translate) :
                                ('abstract.No'|translate)}}</p>
                        </div>
                        <div class="faq-item" *ngIf="article.disclosures.disclosure4 !== null">
                            <h6>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                {{'abstract.Disclosure_question4'|translate}}</h6>
                            <p>{{article.disclosures.disclosure4 == 1 ? ('abstract.Yes'|translate) :
                                ('abstract.No'|translate)}}</p>
                        </div>
                        <div class="faq-item" *ngIf="article.disclosures.disclosure5 !== null">
                            <h6 style="text-decoration: underline">
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                              {{article.disclosures.disclosure5 == 1 ? ('abstract.Disclosure_answer5_1'|translate) : ('abstract.Disclosure_answer5_0'|translate) }}</h6>
                            <p>{{article.disclosures.disclosure5 == 1 ? (article.disclosures.disclosure5_sponsor ? article.disclosures.disclosure5_sponsor : article.disclosures.disclosure5_details) : ''}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <div class="article-content">
            <h2>{{article.title}}</h2>
            <a class="button button-violet-outline" (click)="open(disclosures)" *ngIf="article.disclosures != null">{{'academy.Disclosures'|translate}}</a>
            <img [src]="article.pictures_url.thumbnail">
            <div class="article-text" *ngIf="article.content" [innerHTML]="article.content"></div>
        </div>
        <div class="article-pdf" *ngIf="article.pdf">
            <object [data]="pdfUrl(article.pdf)" type="text/html" width="500" height="375"></object>
        </div>
        <div class="article-more" *ngIf="article.link">
            <a [href]="article.link" class="button button-blue">Find out more</a>
        </div>
        <div class="article-tags" *ngIf="article.themes">
            <span>{{'blog.Tagged'|translate}}: </span>
            <ng-container *ngFor="let keyword of article.themes; index as i">{{i > 0 ? ', ' : ''}}<a
                    routerLink="/library/courses/theme/{{keyword.id}}/{{translationService.getSlugFromTranslations('name',keyword.translations)}}">{{translationService.getTranslatedName(keyword.translations,
                locale)}}</a></ng-container>
        </div>
        <div class="article-social">
            <app-social-list
                    [showSocialItems]="true"
                    [element]="article"
                    [favorite]="false"
                    [favoriteItem]="false"
                    [twitterHref]="'https://twitter.com/intent/tweet?text='+('academy.Share_mail_subject'|translate)+'&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fblog%2F'+article.id+'%2F'+article.slug+'&amp;hashtags=IMCASAcademy&amp;via=imcascongress&amp;related=imcascongress'"
                    [facebookHref]="'https://www.facebook.com/sharer/sharer.php?u='+environment.base_url+'%2F'+locale+'%2Facademy%2Fblog%2F'+article.id+'%2F'+article.slug"
                    [linkedinHref]="'https://www.linkedin.com/shareArticle?mini=true&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fblog%2F'+article.id+'%2F'+article.slug+'&amp;title='+('academy.Share_mail_subject'|translate)"
                    [mailHref]="'mailto:?subject='+('academy.Share_mail_subject'|translate)+'&amp;body='+('academy.Share_mail_line1'|translate)+'%0A%0A'+('academy.Share_mail_line2_course'|translate)+'%0A'+environment.base_url+'/'+locale+'/academy/blog/'+article.id+'/'+article.slug+'%0A%0A'+('academy.Share_mail_subject'|translate)+'%0A%0A'+(user ? user.first_name + ' ' + user.last_name : '')"
                    [certificateItem]="false"
            ></app-social-list>
        </div>
        <div class="article-related-themes"></div>
        <div class="article-share"></div>
        <div class="article-about">
            <h5>{{'blog.About_the_author'|translate}}</h5>
            <div class="article-author media" *ngIf="article.authors">
                <img *ngIf="article.authors.length === 1" class="article-author-thumb rounded-circle"
                     [src]="article.authors[0].picture_url">
                <div class="media-body">
                    <div class="article-author">
                        <h6>
                            <ng-container *ngFor="let author of article.authors; index as i">
                                {{(i > 0 ? (('academy.And'|translate) + ' ') : '')}} <a href="" routerLink="/profile/{{author.slug}}" style="color:black">{{ author.title + ' ' + author.first_name +
                            ' ' + author.last_name }}</a>
                                <app-connect-btn *ngIf="article.authors.length === 1"
                                                 [profile]="author"></app-connect-btn>
                            </ng-container>
                        </h6>
                        <p *ngIf="article.authors.length === 1">{{(article.authors[0].specialty ?
                            translationService.getTranslatedName(article.authors[0].specialty.translations, locale) :
                            '')}}</p>
                    </div>
                </div>
            </div>
            <div class="article-author-bio" *ngIf="article.authors && article.authors.length === 1">
                <p *ngIf="article.authors[0].biostatement"
                   [innerHTML]=" (article.authors[0].biostatement.length>250)? (article.authors[0].biostatement | slice:0:250)+'...':(article.authors[0].biostatement) "></p>
                <a routerLink="/profile/{{article.authors[0].slug}}">{{'blog.Learn_more_about_this_author'|translate}}</a>
            </div>
        </div>
        <div class="article-about" *ngIf="relatedArticles">
            <h5>{{'blog.Related_posts'|translate}}</h5>
            <div class="row">
                <div class="col-md-6" *ngFor="let relatedArticle of relatedArticles">
                    <div class="article-related media">
                        <img class="article-related-thumb" [src]="relatedArticle.pictures_url.thumbnail">
                        <div class="media-body">
                            <div class="article-related-content">
                                <h6><a routerLink="/blog/{{relatedArticle.id}}/{{relatedArticle.slug}}">{{relatedArticle.title}}</a>
                                </h6>
                                <span>{{dateMomentService.formatHumanWay(relatedArticle.publication_date)}}</span>
                                <span>{{('blog.X_min_read'|translate:{nb: relatedArticle.min_to_read})}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="article-about" *ngIf="article.related_academy_medias && article.related_academy_medias.length > 0">
            <h5>{{'academy.Related_videos'|translate}}</h5>
            <div class="row">
                <div class="col-md-6" *ngFor="let relatedVideo of article.related_academy_medias">
                    <div class="article-related media">
                        <img class="article-related-thumb" [src]="relatedVideo.media.thumb_squared">
                        <div class="media-body">
                            <div class="article-related-content">
                                <h6 (click)="mediaService.goToVideo(relatedVideo)">
                                    {{translationService.getTranslatedAttribute('title',
                                    relatedVideo.media.translations, this.locale)}}</h6>
                                <span>{{dateMomentService.formatHumanWay(relatedVideo.publication_date)}}</span>
                                <span>{{relatedVideo.media.duration}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-comment-area [user]="user" [comments]="article.comments" [locale]="locale" [id]="article.id"
                          [type]="'article'" [mustBePremium]="false"></app-comment-area>
    </div>
</div>

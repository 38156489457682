import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {shareReplay} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/index';

@Injectable()
export class AcademySubscriptionService {
  private endpoint: string = '/academy_subscriptions';

  constructor(
    private httpClient: HttpClient
  ) {
  }

  post(params) {
    return this.httpClient.post<any>(environment.apiURL + this.endpoint, params)
      .pipe(shareReplay());
  }

  postMarketplaceFree(params) {
    return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/marketplace-free', params)
      .pipe(shareReplay());
  }

  deleteSub(id: number): Observable<any> {
    return this.httpClient.delete<any>(environment.apiURL + this.endpoint + '/' + id).pipe(shareReplay());
  }

  update(id: number, body): Observable<any> {
    return this.httpClient.put<any>(environment.apiURL + this.endpoint + '/' + id, body).pipe(shareReplay());
  }
}

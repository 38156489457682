import {Magazine} from './../models/magazine.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class MagazineService {
    private endpoint: string = '/magazines';

    constructor(
        private httpClient: HttpClient
    ) { }

    getLastMagazine(): Observable<Magazine> {
        return this.httpClient.get<Magazine>(environment.apiURL + this.endpoint, {
            params: new HttpParams().set('only_last', '1')
        }).pipe(shareReplay());
    }

    downloadMagazine(id: number): Observable<any> {
        return this.httpClient.get(environment.apiURL + this.endpoint + '/' + id + '/download', {
            responseType: 'blob'
        }).pipe(shareReplay());
    }
}

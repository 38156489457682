<h1 [innerHTML]="(currentTab==='inProgress' ? 'academy.Courses_progress' : 'academy.Courses_done')|translate"></h1>
<ul class="nav nav-tabs nav-fill">
	<li class="nav-item" *ngIf="coursesInProgress && coursesFinished">
		<a class="nav-link" [ngClass]="{active: currentTab==='inProgress'}" (click)="currentTab='inProgress'">
			{{'academy.In_progress'|translate}} <span>{{coursesInProgress ? coursesInProgress.length : '0'}}</span></a>
	</li>
	<li class="nav-item" *ngIf="coursesFinished && coursesFinished.length > 0">
		<a class="nav-link" [ngClass]="{active: currentTab==='finished'}" (click)="currentTab='finished'">
			{{'academy.Completed'|translate}} <span>{{coursesFinished.length}}</span></a>
	</li>
</ul>
<div class="courses courses-progress" *ngIf="coursesInProgress && currentTab == 'inProgress'">
	<div class="row" *ngIf="coursesInProgress.length > 0">
		<div class="course-items col-6" *ngFor="let course of coursesInProgress">
			<app-course-element [course]="course"></app-course-element>
		</div>
	</div>
	<div *ngIf="coursesInProgress.length === 0">
		<div class="courses-button text-center">
			<a [routerLink]="['/library', 'courses']" class="button button-orange">{{ 'academy.Discover_our_library'|translate }}</a>
		</div>
	</div>
</div>
<div class="courses courses-completed" *ngIf="coursesFinished && currentTab=='finished'">
	<div class="row">
		<div class="course-items col-6" *ngFor="let course of coursesFinished">
			<app-course-element [course]="course" [actions]="true"></app-course-element>
		</div>
	</div>
</div>

import {LocaleService} from 'app/services/locale.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Theme} from '../models/theme.model';
import {Keyword} from '../models/keyword.model';
import {TranslationService} from 'app/services/translation.service';
import {map, shareReplay} from 'rxjs/operators';

@Injectable()
export class ThemeService {
    private endpoint: string = '/themes';

    constructor(private httpClient: HttpClient,
                private translationService: TranslationService,
                private localeService: LocaleService) {
    }

    getTheme(id: number): Observable<Theme> {
        return this.httpClient.get<Theme>(environment.apiURL + this.endpoint + '/' + id).pipe(shareReplay());
    }

    getMainThemes(orderBy): Observable<Theme[]> {
        return this.httpClient.get<Theme[]>(environment.apiURL + this.endpoint, {
            params: new HttpParams().set('all', '1').set('public', '1').set('theme_pictures', '1')
        })
            .pipe(map(events => {
                if (orderBy === 'alpha') {
                    events.sort((a, b) => {
                        if (this.translationService.getTranslatedName(a.translations, this.localeService.currentLocale()) <
                            this.translationService.getTranslatedName(b.translations, this.localeService.currentLocale())) {
                            return -1;
                        }
                        if (this.translationService.getTranslatedName(a.translations, this.localeService.currentLocale()) >
                            this.translationService.getTranslatedName(b.translations, this.localeService.currentLocale())) {
                            return 1;
                        }
                        return 0;
                    });
                }
                return events;
            }), shareReplay());
    }

    getThemes(params?: any): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        reqOpts.params = reqOpts.params.set('all', '1');
        reqOpts.params = reqOpts.params.set('public', '1');
        reqOpts.params = reqOpts.params.set('theme_pictures', '1');
        return this.httpClient.get<any>(environment.apiURL + this.endpoint, reqOpts).pipe(shareReplay());
    }
    getThemesForUser(userId): Observable<Theme[]> {
        return this.httpClient.get<Theme[]>(environment.apiURL + this.endpoint, {
            params: new HttpParams().set('all', '1').set('public', '1').set('user_id', userId).set('theme_pictures', '1')
        }).pipe(shareReplay());
    }

    getKeywords(themeId): Observable<Keyword[]> {
        return this.httpClient.get<Keyword[]>(environment.apiURL + this.endpoint + '/' + themeId, {
            params: new HttpParams().set('all', '1').set('public', '1')
        }).pipe(map((res: any) => res.keywords), shareReplay());
    }
    getKeywordsAcademyMedias(themeId): Observable<Keyword[]> {
        return this.httpClient.get<Keyword[]>(environment.apiURL + this.endpoint + '/' + themeId, {
            params: new HttpParams().set('all', '1').set('public', '1')
        }).pipe(map((res: any) => res.keywords_with_medias), shareReplay());
    }
}

<div class="card has-border-radius">
  <div *ngIf="hasBadges" class="badges">
    <span *ngFor="let badge of badges" class="text-wrap" [style.background-color]="badge.color">{{badge.title}}</span>
  </div>
  <div *ngIf="!isVideo" class="has-size-fixed-image has-border-radius" [style.background-image]="'url('+media+')'">
    <div class="has-linear-gradient has-border-radius"></div>
  </div>
  <div *ngIf="isVideo" class="has-size-fixed-video has-border-radius">
    <video autoplay loop [muted]="true" class="has-border-radius">
      <source [src]="media" type="video/mp4">
    </video>
   <div class="has-linear-gradient has-border-radius"></div>
  </div>
  <div *ngIf="title.length > 0">
    <h2 class="is-card-title">{{title}}</h2>
  </div>
  <div *ngIf="hasButtons" class="is-card-buttons">
    <button class="is-card-button text-wrap text-uppercase" *ngFor="let button of buttons"><a [href]="button.href" target="_blank">{{button.title | translate}}</a></button>
  </div>
</div>

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.production) {
  document.write('<link rel="stylesheet" href="https://comexposium-healthcare-assets.s3.eu-west-1.amazonaws.com/assets-front/fonts/icomoon/icomoon.css">');
} else {
  document.write('<link rel="stylesheet" href="https://comexposium-healthcare-assets-test.s3.eu-west-1.amazonaws.com/assets-front/fonts/icomoon/icomoon.css"">');
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  /*if ('serviceWorker' in navigator && environment.production) {
    navigator.serviceWorker.register('/ngsw-worker.js');
  }*/
}).catch(err => console.log(err));

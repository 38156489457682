import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class UserBadgeService {
  endpoint: string = '/user_badges';

  constructor(private httpClient: HttpClient) {
  }

  getAllBadges() {
    return this.httpClient.get<any>(environment.apiURLV2 + this.endpoint + '/all').pipe(shareReplay());
  }

  getUserBadges() {
    return this.httpClient.get<any>(environment.apiURLV2 + this.endpoint + '/user').pipe(shareReplay());
  }
}

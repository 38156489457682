import {environment} from './../../../../environments/environment';
import {Component, HostListener, OnInit} from '@angular/core';
import {CongressProduct} from '../../../models/congress-product.model';
import {CongressProductService} from '../../../services/congress-product.service';
import {Router} from '@angular/router';
import {CartService} from '../../../services/cart.service';

@Component({
    selector: 'app-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.scss'],
    providers: [CongressProductService, CartService]
})
export class MarketplaceComponent implements OnInit {
    congressProducts: CongressProduct[];
    locale: string;
    innerWidth: number;
    colors = ['28a745', '0981AA', 'FA7732', 'B85A64', '6F42C1', '007BFF'];
    colorsIndexer: number;

    environment = environment;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    constructor(private congressProductService: CongressProductService, private router: Router, private cartService: CartService) {
    }

    ngOnInit() {
        this.congressProducts = [];
        this.colorsIndexer = 0;
        this.innerWidth = window.innerWidth;
        this.congressProductService.searchCongressProducts().subscribe(result => {
            result.forEach(m => {
                this.congressProducts.push(new CongressProduct(m));
            });
            // products are shuffled so that it's not always the same one that is displayed in the first (best) spot
            this.congressProducts = this.congressProducts.sort(() => Math.random() - 0.5);
        });

        this.colors = this.colors.sort(() => Math.random() - 0.5);
    }

    openCart() {
        if (!this.cartService.getCurrentCartId()) {
            this.cartService.getCartId('marketplace_cart_id').subscribe(cartId => {
                this.cartService.setCurrentCartId(cartId);
                this.router.navigate(['cart']);
            }, error => {
                this.cartService.deleteCurrentCartId();
            });
        } else {
            this.router.navigate(['cart']);
        }
    }

    // We receive index from this.congressProducts and we want to loop infinitely through this.colors
    getColor(index) {
      const maxIndex = this.colors.length - 1;
      let color = this.colors[0];

      if (this.colorsIndexer === 0) {

        if (index !== 0 && (index % maxIndex === 0)) {
          color = this.colors[index];
          this.colorsIndexer++;
        } else {
          color = this.colors[index];
        }

      } else if (this.colorsIndexer > 0) {

        const subIndex = index - (this.colorsIndexer + maxIndex * this.colorsIndexer);
        color = this.colors[subIndex];
      }

      return color;
    }
}

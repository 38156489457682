<div class="marquee-inner">

  <div *ngFor="let element of elements">
    <a [href]="getUrl(element)" target="_blank">
      <img [src]="getSourceImg(element)" class="partners_img">
    </a>
  </div>
    <div *ngFor="let element of elements">
      <a [href]="getUrl(element)" target="_blank">
        <img [src]="getSourceImg(element)" class="partners_img">
      </a>
    </div>

</div>


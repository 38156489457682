import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class PaymentService {
    private endpoint: string = '/payments';

    constructor(private httpClient: HttpClient) {
    }

    post(body) {
        return this.httpClient.post<any>(environment.apiURL + this.endpoint, body)
            .pipe(shareReplay());
    }

  postDelivery(body) {
    return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/delivery', body)
      .pipe(shareReplay());
  }

    get(paymentId) {
        const reqOpts = {
            params: new HttpParams()
        };
        return this.httpClient.get<any>(environment.apiURL + this.endpoint, reqOpts)
            .pipe(shareReplay());
    }

    getLast() {
        const reqOpts = {
            params: new HttpParams()
        };
        reqOpts.params = reqOpts.params.set('payment_card_data', '' + 1);
        reqOpts.params = reqOpts.params.set('cart_payment', '' + 0);
        return this.httpClient.get<any>(environment.apiURL + this.endpoint + '/last', reqOpts)
            .pipe(shareReplay());
    }

    cancelSubscription(body) {
        return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/cancel', body)
            .pipe(shareReplay());
    }
}

<ng-container *ngIf="statusWeb">
    <div class="chatbox">
        <section class="chat-container">
            <ng-container *ngFor="let comment of comments">
                <ng-container *ngIf="comment.pin == 1">
                    <div class="pin-comment">
                        <h5>
                            <a *ngIf="comment.user_id !== 91074" [href]="'academy/profile/'+comment.slug"
                               target="_blank" style="color: white;">{{comment.user}}
                                :</a>
                            <span *ngIf="comment.user_id === 91074" style="color: white;">{{comment.user}} :</span>
                        </h5>
                        <p [innerHTML]="comment.comment" style="color: white;"></p>
                    </div>
                </ng-container>
            </ng-container>
            <div class="chat-log" *ngIf="show || statusWeb" #scrollMe>
                <div class="chat-message" *ngFor="let comment of shownComments"
                     [ngClass]="{'bubble-disappear' : comment.pin == 1}">
                    <div class="chat-message__text" >
                        <h5>
                            <a *ngIf="comment.user_id !== 91074" [href]="'academy/profile/'+comment.slug"
                               target="_blank">{{comment.user}}
                                :</a>
                            <span *ngIf="comment.user_id === 91074" style="color: #fa7732;">{{comment.user}} :</span>
                        </h5>
                    <p [innerHTML]="comment.comment">
                    </div>
                </div>
            </div>

            <div class="chat-input-area" *ngIf="show || statusWeb">
                <input [(ngModel)]="commentToSend" (keydown)="sendMessage($event)"
                        type="text" [placeholder]="'academy.Write_something_dotdotdot'|translate" class="chat-input-area__input">
                <button [disabled]="!commentToSend || commentToSend === ''"
                        (click)="sendComment(commentToSend); commentToSend=null;"
                        type="submit" class="chat-input-area__submit-button"><i class="fas fa-paper-plane"></i></button>
            </div>
        </section>
    </div>
</ng-container>

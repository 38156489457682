import {Translation} from './translation.model';

export class Specialty {
  id: number;
  color: string;
  doctor: number;
  translations: Translation[];

  constructor(obj?: any) {
    this.id             = obj && obj.id             ||   54;
    this.color          = obj && obj.color          ||   'b687c6';
    this.doctor         = obj && obj.doctor         ||   1;
    this.translations = obj && obj.translations     || [
      { 'name': 'Anatomopathologist', 'locale': 'en' },
      { 'name': '解剖病理学家', 'locale': 'zh' },
      { 'name': 'Анатомотерапевт', 'locale': 'ru' },
      { 'name': 'Anatomopathologiste', 'locale': 'fr' },
      { 'name': 'Anatomopatólogo ', 'locale': 'zh' },
      { 'name': 'Anatomopatólogo ', 'locale': 'zh' },
      { 'name': 'Anatomopatólogo', 'locale': 'es' }
    ];
  }
}

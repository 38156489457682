import {TranslationService} from '../../services/translation.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocaleService} from '../../services/locale.service';


@Component({
    selector: 'dropdown-select-component',
    templateUrl: './dropdown-select.component.html',
    styleUrls: ['./dropdown-select.component.scss']
})

export class DropdownSelectComponent implements OnInit {
    @Input() options: any;
    @Input() selectedOption: any;
    @Input() id: any;
    @Input() name: any;
    @Input() addEmpty: boolean = false;
    @Input() componentClass: any = "";
    @Input() placeholder: any = "";
    @Output() optionChange = new EventEmitter();
    locale: any;

    constructor(private translate: TranslateService,
                public translationService: TranslationService,
                private localeService: LocaleService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        if (this.addEmpty) {
            this.selectedOption = null;
        }
    }

    onOptionChange() {
        this.optionChange.emit(this.selectedOption);
    }
}

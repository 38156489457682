<a [href]="event.website" target="_blank" class="">
  <img *ngIf="event.banner_file != null" class="event-img" src="{{event.banner_url}}"
       alt="Image banner of {{event.title}}">
  <span class="event-item-badge partner" [ngClass]="{'with_banner' : event.banner_file != null}"
        *ngIf="event.tag == true">{{ ('explore.Scientific_partners'|translate).split('|')[0] }}</span>
  <span class="event-item-badge imcas" [ngClass]="{'with_banner' : event.banner_file != null}"
        *ngIf="event.tag == 'IMCAS'">IMCAS</span>
  <div class="event-content">
    <h6 [ngClass]="{'no_banner' : event.banner_file != null, 'is-partner' : event.tag}"> {{event.title}}</h6>

    <div class="event-infos">
      <p class="event-info"><i class="fas fa-calendar"></i>{{ event.city }} ( <span
        *ngIf="event.country && event.country.translations">
        <div #thenBlock
             *ngIf="translationService.getTranslatedName(event.country.translations, locale).length > 0 && event.city && event.city.length > 0 ;  else elseBlock">
          <div
            *ngIf="translationService.getTranslatedName(event.country.translations, locale).length + event.city.length > 25 ;  else elseBlock">
            {{translationService.getTranslatedName(event.country.translations, locale).substring(0, 23 - event.city.length)}}
            ...
          </div>
        <ng-template
          #elseBlock>{{translationService.getTranslatedName(event.country.translations, locale)}}</ng-template>

        </div>
        <ng-template
          #elseBlock>{{translationService.getTranslatedName(event.country.translations, locale)}}</ng-template>
      </span>)
      </p>
      <p class="event-info" *ngIf="event.start_date == event.end_date"><i
        class="fas fa-map-marker-alt"></i> {{ event.start_date }}</p>
      <p class="event-info" *ngIf="event.start_date != event.end_date"><i
        class="fas fa-map-marker-alt"></i> {{ event.start_date }} - {{ event.end_date }}</p>
    </div>

  </div>
</a>

<div class="login_app container_app" [class.container_app_aop]="environment.domain == 'ophthalmology'">

  <header class="mobile-header">
    <a class="btn_header" (click)="handlePrevButton()"><i class="fal fa-arrow-left"></i></a>
    <span>{{'account.Sign_up_now'|translate}}</span>

  </header>
  <div class="bg-login" style="background-image:url(assets/img/bg_image_aop.png)" *ngIf="environment.domain == 'ophthalmology'"></div>

  <div class="signup-screen login-screen">

    <div class="login">
      <div class="login-text">
        <img class="logo_app light" src="assets/img/logo_white.png" alt="Logo IMCAS Academy" *ngIf="environment.domain == 'aesthetics'">
        <img class="logo_app light" src="assets/img/logo_white_aop.png" alt="Logo AOP Academy" *ngIf="environment.domain == 'ophthalmology'">
        <h2>{{'account.Create_button'|translate}}</h2>
      </div>
    </div>

    <form class="form-slider signup" #signUpForm="ngForm" (ngSubmit)="signUp()">

      <!-- FIRST SLIDE -->
      <div>


        <div class="vertical-align">

          <p class="text">{{'account.Create_message'|translate}}<br />
            <custom-inner-html [content]="'account.Create_message3'|translate: {link: linkContact}"></custom-inner-html>



          <div class="line-input">
            <input type="text" name="first_name" [(ngModel)]="user.first_name" #first_name="ngModel"

                   required />
            <span class="floating-label">{{'account.First_name'|translate}} <span class="text-danger">*</span></span>
          </div>

          <div *ngIf="first_name.invalid && (first_name.dirty || first_name.touched || formValidated)" class="form-login-error">
            <div *ngIf="first_name.errors.required">
              {{'company_account_badges.First_name_is_required'|translate}}
            </div>
          </div>

          <div class="line-input">
            <input type="text" required name="last_name" [(ngModel)]="user.last_name" #last_name="ngModel"/>
            <span class="floating-label">{{'account.Last_name'|translate}} <span class="text-danger">*</span></span>
          </div>

          <div *ngIf="last_name.invalid && (last_name.dirty || last_name.touched || formValidated)" class="form-login-error">
            <div *ngIf="last_name.errors.required">
              {{'company_account_badges.Last_name_is_required'|translate}}
            </div>
          </div>

          <div class="line-input line-input-select">
            <ng-container *ngIf="specialities$ | async as specialities">
              <dropdown-select-component (touchend)="checkSpecialtyOrCountryRequirement('specialty')" [options]="specialities" [id]="'speciality'" [name]="'speciality'" (optionChange)="specialtyChanged($event)"
                                         [addEmpty]="true" [selectedOption]="selectedSpecialty" [componentClass]="'transparent-select'" [placeholder]="('account.Choose_specialty'|translate)">
              </dropdown-select-component>
            </ng-container>

            <span class="floating-label" [class.selected]="selectedSpecialty != null">{{'account.Choose_specialty'|translate}} <span class="text-danger">*</span></span>

          </div>
          <div *ngIf="specialtyRequired" class="form-login-error">
            <div>
              Specialty is required<!--COMING SOON: account.Specialty_required-->
            </div>
          </div>
          <div class="line-input line-input-select">
            <ng-container *ngIf="countries$ | async as countries">
              <dropdown-select-component (touchend)="checkSpecialtyOrCountryRequirement('country')" [options]="countries" [id]="'country'"   [name]="'country'" (optionChange)="countryChanged($event)"
                                         [addEmpty]="true" [selectedOption]="selectedCountry" [componentClass]="'transparent-select'" [placeholder]="('account.Choose_country'|translate)">
              </dropdown-select-component>
            </ng-container>

            <span class="floating-label" [class.selected]="selectedCountry != null">{{'account.Choose_country'|translate}}</span>

          </div>
          <div *ngIf="countryRequired" class="form-login-error">
            <div>
              Country is required<!--COMING SOON: account.Specialty_required-->
            </div>
          </div>
          <div class="align-self-baseline" style="margin-left: 18px; margin-top: 30px; font-weight: 300">
            <input type="checkbox" id="resident" style="margin-right: 0.5em" name="resident" [(ngModel)]="user.medical_resident" #medical_resident="ngModel"/>
            <label for="resident">
              {{'account.I_am_medical_resident'|translate}}
            </label>
          </div>
        </div>

        <div *ngIf="registrationError" class="form-login-error form-login-error-main" >
          <div>
            <custom-inner-html [content]="registrationError"></custom-inner-html>
          </div>
        </div>
      </div>

      <!-- SECOND SLIDE -->
      <div>

        <div class="vertical-align">

          <div class="line-input">
            <input type="phone" required minlength="3"
                   name="phone" [(ngModel)]="user.phone" #phone="ngModel" />
            <span class="floating-label">{{'account.Phone_number'|translate}} <span class="text-danger">*</span></span>

          </div>

          <div *ngIf="phone.invalid && (phone.dirty || phone.touched || formValidated)" class="form-login-error">
            <div *ngIf="phone.errors.required">
              <custom-inner-html [content]="'validation.phone'|translate:{attribute:('account.Phone_number'|translate)}"></custom-inner-html>
            </div>


            <div *ngIf="phone.errors.minlength">
              <custom-inner-html [content]="'validation.phone'|translate:{attribute:('account.Phone_number'|translate)}"></custom-inner-html>
            </div>
          </div>

          <div class="line-input">
            <input type="email" name="email" required email [(ngModel)]="user.email" #email="ngModel" />
            <span class="floating-label">{{'attend.Email'|translate}} <span class="text-danger">*</span></span>
          </div>

          <div *ngIf="email.invalid && (email.dirty || email.touched || formValidated)" class="form-login-error">
            <div *ngIf="email.errors.required">
              {{'aop_layout.Launching_email_error_flash'|translate}}
            </div>
            <div *ngIf="email.errors.email && !email.errors.required">
              {{'aop_layout.Launching_email_error_flash'|translate}}
            </div>
          </div>

          <div class="line-input">
            <input type="password" name="password"[(ngModel)]="user.password" required validateEqual="password_confirmation_input" reverse="true" #password="ngModel" />
            <span class="floating-label">{{'reminders.password_label'|translate}} <span class="text-danger">*</span></span>
          </div>
          <div class="form-group-error form-login-error" [hidden]="password.valid || (password.pristine && !formValidated)">
            <div>{{'account.Password_required'|translate}}</div>
          </div>

          <div class="line-input">
            <input type="password" name="password_confirmation_input" [(ngModel)]="password_confirmation"
                   required validateEqual="password" reverse="false" #password_confirmation_input="ngModel" />
            <span class="floating-label">{{'application.Password_confirmation'|translate}} <span class="text-danger">*</span></span>
          </div>

          <div class="form-group-error form-login-error" [hidden]="password_confirmation_input.valid || (password_confirmation_input.pristine && !formValidated)">
            <div>{{'account.Password_mismatch'|translate}}</div>
          </div>
        </div>
        <div class="signup-checkbox">
          <label class="transparent-checkbox" [class.checked]="user.subscribe_academy_web">{{'account.Partners_optin_label'|translate}}
            <input type="checkbox" name="subscribe_academy_web" [(ngModel)]="user.subscribe_academy_web"
                   #subscribe_academy_web="ngModel">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="signup-checkbox">
          <label class="transparent-checkbox"[class.checked]="user.subscribe_web">{{'account.Subscribe_label'|translate}}
            <input type="checkbox" name="subscribe_web" [(ngModel)]="user.subscribe_web" #subscribe_web="ngModel">
            <span class="checkmark"></span>
          </label>
        </div>

        <div *ngIf="registrationError" class="form-login-error form-login-error-main" >
          <div>
            <custom-inner-html [content]="registrationError"></custom-inner-html>
          </div>
        </div>
      </div>

    </form>
    <div class="navDots"></div>
    <button class="transparent-button primary nextButton" id="nextArrow" (click)="handleNextButton()">
      <span *ngIf="!loading">{{'academy.Next'|translate}}</span>
      <span *ngIf="loading">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      </span>
    </button>
  </div>
</div>

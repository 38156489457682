import {User} from './user.model';
import {Congress} from './congress.model';

export class Session {
    id: number;
    date_begin: string | number;
    congress_id: number;
    congress: any;
    translations: any;
    speakers: User[];

    constructor(obj?: any) {
        this.id = obj && obj.id;
        this.date_begin = obj && obj.date_begin;
        this.congress_id = obj && obj.congress_id;
        this.translations = obj && obj.translations;
        this.congress = obj && obj.congress ? new Congress(obj.congress) : null;
        if (obj && obj.speakers) {
            this.speakers = [];
            obj.speakers.forEach((n) => {
                this.speakers.push(new User(n));
            })
        }
    }

    getSpeakersString() {
        let speakersString = '';
        this.speakers.forEach((user) => {
            speakersString += user.getFullName() + ', ';
        });
        return speakersString.slice(0, -2);
    }
}

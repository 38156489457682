<h1>{{ 'academy.Languages'|translate }}</h1>
<div class="modal-body" *ngIf="newLanguage">
  <div id="alert-success" class="alert alert-success">
    Language updated !
  </div>
</div>
<div class="select-wrap">
  <dropdown-locale-component [options]="getLanguagesAvailable()"
                             [id]="'locale'"
                             [name]="'locale'"
                             [(selectedOption)]="selectedLanguage"
                             (optionChange)="languageChanged($event)">
  </dropdown-locale-component>
</div>

import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class CommentService {
    private endpoint: string = '/comments';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    commentArticle(articleId: number, comment: string): Observable<any> {
        const body = {
            entity_type: 'article',
            entity_id: articleId,
            content: comment,
            public: 1
        };
        return this.httpClient.post<any>(environment.apiURL + this.endpoint, body)
            .pipe(shareReplay());
    }
}

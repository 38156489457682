import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class UserProductService {
  private endpoint: string = '/user_products';

  constructor(private httpClient: HttpClient) {
  }

  create(cartId: number): Observable<any> {
    return this.httpClient.post<any>(environment.apiURL + this.endpoint, {
      cart_id: cartId
    }).pipe(shareReplay());
  }

  createFromScientificSociety(scientificSocietyId: number): Observable<any> {
    return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/create_scientific_society', {
      scientific_society_id: scientificSocietyId
    }).pipe(shareReplay());
  }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/index';

@Injectable()
export class SerializingInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((request.method === 'POST' || request.method === 'PUT') && !request.headers.get('multipart')) {
      const result = [], obj = request.body;
      for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
          result.push(encodeURIComponent(property) + '=' + encodeURIComponent(obj[property]));
        }
      }
      request = request.clone({ body: result.join('&') });
    }
    return next.handle(request);
  }
}

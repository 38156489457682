<div class="notifications">
  <div class="notifications-mark-all-as-read">
    <a (click)="readAllNotifications($event) ">{{'academy.Mark_all_as_read'|translate}}</a>
  </div>
  <div class="notification dropdown-item {{ notifications[notification].read ? '' : 'read' }}"
       (mouseenter)="readNotification($event, notifications[notification]) "
       *ngFor="let notification of notifications | keys">
    <a class="notification-url" href="{{ notifications[notification].url}}"
       [target]="notifications[notification].url?.includes('imcas') || notifications[notification].url?.includes('/academy') ? '_self' : '_blank'">
      <div class="clearfix">
        <div class="img-thumb">
          <div class="picture"
               *ngIf="notifications[notification].picture?.substring(0, 4) == 'http';else divChar"
               [style.background-image]="'url(' + notifications[notification].picture + ')'"
               [style.background-size]="'cover'"
               [style.background-position]="'0%'">
          </div>
          <ng-template #divChar>
            <div [innerHTML]="notifications[notification].picture | safeHtml"></div>
          </ng-template>
        </div>
        <div class="notification-content-wrapper">
          <span class="date-hour">{{ notifications[notification].date_for_humans }}</span>
          <div class="notification-content">{{ notifications[notification].text}}</div>
        </div>
      </div>
    </a>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {UserService} from 'app/services/user.service';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../models/user.model';


@Component({
    selector: 'settings-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    providers: [
        UserService
    ]
})
export class SettingsPasswordComponent implements OnInit {
    newUser = {
        password_old: null,
        password: null,
        password_confirmation: null
    };
    updated: boolean = false;
    error: string;
    user: User;

    constructor(private userService: UserService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.user = this.authService.getCurrentUser();
    }

    updateProfile() {
        this.updated = false;
        this.error = null;
        this.userService
            .updateUser(this.newUser)
            .subscribe(
                (result: any) => {
                    if (!result.error) {
                        this.updated = true;
                    } else {
                        this.error = result.error;
                    }
                });
    }
}

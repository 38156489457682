import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {WebpageService} from '../../../services/webpage.service';
import {ActivatedRoute} from '@angular/router';
import {LocaleService} from '../../../services/locale.service';
import {TranslationService} from '../../../services/translation.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    providers: [WebpageService]
})
export class PrivacyPolicyComponent implements OnInit {
    environment: any = environment;
    locale: string;
    groups: Array<string>;
    parts: Array<any>;

    constructor(
        private webpageService: WebpageService,
        public translationService: TranslationService,
        private route: ActivatedRoute,
        private localeService: LocaleService
    ) {
    }

    ngOnInit() {
        this.groups = [];
        this.locale = this.localeService.currentLocale();
        this.webpageService.getWebpage('privacy_policy').subscribe(response => {
            this.parts = Object.keys(response.parts).map(personNamedIndex => {
                this.groups.push(personNamedIndex);
                return response.parts[personNamedIndex];
            });
        });
    }

}

import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {TranslationService} from "../../../services/translation.service";
import {CompanyProfileService} from "../../../services/company-profile.service";
import {ArticleService} from "../../../services/article.service";
import {DateMomentService} from "../../../services/date-moment.service";
import {LocaleService} from "../../../services/locale.service";
import {CompanyProfileModel} from "../../../models/company-profile.model";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";


@Component({
  selector: 'funding-component',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.scss'],
  providers: [
    TranslationService,
    CompanyProfileService,
    ArticleService,
    DateMomentService
  ]
})

export class FundingComponent implements OnInit {
  environment = environment;
  @ViewChild('Container', {static: false}) container!: ElementRef<HTMLDivElement>;
  @ViewChild('Discover', {static: false}) discover!: ElementRef<HTMLDivElement>;
  @ViewChild('Ads', {static: false}) ads!: ElementRef<HTMLDivElement>;
  private adsOffsetTop: number = 0;
  private discoverOffsetTop: number = 0;
  public sponsorshipLevelOrder: Array<string> = ['ultimate', 'elite', 'diamond', 'emerald', 'platinum', 'gold', 'silver', 'bronze', 'classic', 'standard'];
  public ordering: Map<string, number> = new Map();
  public locale: string = "en";
  public ads$: Observable<Array<any>>;
  public companies$: Observable<Array<CompanyProfileModel>>;
  public articles$: Observable<any>;
  public filterTheme: string = '';
  public filterCountry: string = '';
  public filterCategory: string = '';
  public allThemes: Map<string, Array<CompanyProfileModel>> = new Map();
  public allCategories: Map<string, Array<CompanyProfileModel>> = new Map();
  public allCountries: Map<string, Array<CompanyProfileModel>> = new Map();
  public allDisplayedCompanies: Set<CompanyProfileModel> = new Set();
  public allCompanies: Array<CompanyProfileModel>= [];
  public button: Array<{title: string, href: string}> = new Array<{title: string; href: string}>();
  public pagination : Map<number, Array<CompanyProfileModel>> = new Map();
  public currentPage: number = 1;
  public paginationElementsNumber: number = 12;

  constructor(public translationService: TranslationService,
              private companyProfileService: CompanyProfileService,
              private articleService: ArticleService,
              public dateMomentService: DateMomentService,
              public localeService: LocaleService) {

  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    for(var i = 0; i < this.sponsorshipLevelOrder.length; i++) {
      this.ordering[this.sponsorshipLevelOrder[i]] = i;
    }
    this.ads$ = this.companyProfileService.getAds();
    this.loadCompaniesAndFilters();
  }

  loadCompaniesAndFilters() {
    this.companies$ = this.companyProfileService.get().pipe(map(companies => {
      this.allCompanies = companies;
      companies.forEach(cp => {
        this.allDisplayedCompanies.add(cp);
        this.createMap(cp.industry_categories, this.allCategories, cp);
        this.createMap(cp.themes, this.allThemes, cp);
        const country = cp.country.translations.find(tr => tr.locale === this.locale).name;
        if (this.allCountries.has(country)) {
          const countries = this.allCountries.get(country);
          countries.push(cp);
          this.allCountries.set(country, countries);
        } else {
          this.allCountries.set(country, [cp]);
        }
      });
      this.sortDisplayedCompaniesArrayBySponsorshipLevel();
      this.loadArticles();
      return companies;
    }));
  }

  private loadArticles() {
    const year_from_now = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
    this.articles$ = this.articleService.getArticles(Array.from(this.allDisplayedCompanies).map(cp => cp.id), {
      all: 1,
      pub_date_after: year_from_now,
      article_company_profile: 1
    }).pipe(
      map(articles => articles.slice(0,6)),
      map(articles => {
        this.adsOffsetTop = this.container.nativeElement.offsetTop - 80; //It's the header height size
        return articles;
      }));
  }

  select($event, selectName: string) {
    switch(selectName) {
      case 'theme': {
        this.filterTheme = $event.target.value;
        break;
      }
      case 'category': {
        this.filterCategory = $event.target.value;
        break;
      }
      case 'country': {
        this.filterCountry = $event.target.value;
        break;
      }
    }
    const hasTheme = this.allThemes.has(this.filterTheme);
    const hasCategory = this.allCategories.has(this.filterCategory);
    const hasCountry = this.allCountries.has(this.filterCountry);
    this.allDisplayedCompanies.clear();
    if (!hasTheme && !hasCategory && !hasCountry) {
      this.allCompanies.forEach(cp => this.allDisplayedCompanies.add(cp));
    }
    if(hasTheme)
    {
      this.allThemes.get(this.filterTheme).forEach(cp => this.allDisplayedCompanies.add(cp));
    }
    if(hasCategory){
      this.allCategories.get(this.filterCategory).forEach(cp => this.allDisplayedCompanies.add(cp));
    }
    if(hasCountry){
      this.allCountries.get(this.filterCountry).forEach(cp => this.allDisplayedCompanies.add(cp));
    }
    this.sortDisplayedCompaniesArrayBySponsorshipLevel();
    this.loadArticles();
  }

  getButton(slug: string, total_videos: number) {
    return [{
      title: total_videos > 0 ? 'academy.Watch_videos_and_news' : 'academy.Read_news_and_more',
      href: '/' + this.locale + '/academy/profile/' + slug
    }];
  }

  createMap(list: Array<any>, map: Map<string, Array<CompanyProfileModel>>, element: CompanyProfileModel) {
    list.forEach((item: any) =>  {
      const translation = item.translations.find(tr => tr.locale === this.locale).name;
      if(map.has(translation)) {
        const companiesList = map.get(translation);
        companiesList.push(element);
        map.set(translation, companiesList);
      } else {
        map.set(translation, [element]);
      }
    });
  }

  private sortDisplayedCompaniesArrayBySponsorshipLevel() {
    this.allDisplayedCompanies = new Set(Array.from(this.allDisplayedCompanies).sort((a,b) => {
      return (this.ordering[a.exhibitor.sponsorship_level] - this.ordering[b.exhibitor.sponsorship_level] || a.company_name.localeCompare(b.company_name));
    }));
    this.paginateList();
  }

  paginateList() {
    var pages = 0;
    var lastIndex = -1;
    var size = this.allDisplayedCompanies.size;
    this.pagination.clear();
    for(let i = 0; i < size; i+=this.paginationElementsNumber) {
      pages++;
      this.pagination.set(pages, Array.from(this.allDisplayedCompanies).slice(i, i+this.paginationElementsNumber));
      lastIndex = i + this.paginationElementsNumber;
    }
    if (lastIndex < size) {
      this.pagination.set(pages + 1, Array.from(this.allDisplayedCompanies).slice(lastIndex, size -1));
    }
  }
  @HostListener('window:resize')
  @HostListener('window:scroll')
  handleScrolling() {
    if (window.innerWidth >= 1024
      && isNotNullOrUndefined(this.discover)
      && isNotNullOrUndefined(this.ads)
      && isNotNullOrUndefined(this.container)) {
      this.discoverOffsetTop = this.discover.nativeElement.offsetTop - this.adsOffsetTop;
      if (window.scrollY >= this.adsOffsetTop && window.scrollY <= this.discoverOffsetTop) {
        this.ads.nativeElement.style.position = 'fixed';
        this.ads.nativeElement.style.top = "100px";
        this.container.nativeElement.style.marginLeft = '2rem';
        this.container.nativeElement.classList.remove('text-center');
      } else if (window.scrollY >= this.discoverOffsetTop) {
        this.ads.nativeElement.style.position = "absolute";
        this.ads.nativeElement.style.top = `${this.discoverOffsetTop}`;
      }
      else {
        this.ads.nativeElement.style.position = 'static';
        this.ads.nativeElement.style.top = '0px';
        this.container.nativeElement.style.marginLeft = '0px';
        this.container.nativeElement.classList.add('text-center');
      }
    }
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }
}

import {Component, HostListener} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
    selector: 'app-scroll-top',
    templateUrl: './scroll-top.component.html',
    styleUrls: ['./scroll-top.component.scss'],
    animations: [
        trigger('scrollAnimation', [
            state('show', style({
                opacity: 1
            })),
            state('hide', style({
                opacity: 0
            })),
            transition('* => *', animate('.2s'))
        ])
    ]
})
export class ScrollTopComponent {

    state: string;

    constructor() {
        this.state = 'hide'
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event: any): void {
        if (window.pageYOffset >= 50) {
            this.state = 'show'
        } else {
            this.state = 'hide'
        }
    }

    scrollToTop(event) {
        $('html, body').animate({
            scrollTop: 0
        }, 700);
        return false;
    }
}

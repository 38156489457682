import {DateMomentService} from 'app/services/date-moment.service';
import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {Course} from 'app/models/course.model';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';


@Component({
    selector: 'account-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.scss'],
    providers: [
        UserService
    ]
})
export class AccountCoursesComponent implements OnInit {
    user: User;
    coursesInProgress: Course[];
    coursesFinished: Course[];
    locale: string;
    currentTab: string = 'inProgress';

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private userService: UserService,
                public translationService: TranslationService,
                public localeService: LocaleService,
                public dateMomentService: DateMomentService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.user = this.authService.getCurrentUser();
        this.userService.getCoursesInProgress(this.user.id).subscribe(courses => {
            this.coursesInProgress = [];
            if ( typeof courses !== 'undefined') {
                courses.forEach(n => {
                    const courseObj: Course = new Course(n);
                    this.coursesInProgress.push(courseObj);
                });
            }
        });
        this.userService.getCoursesFinished(this.user.id).subscribe(courses => {
            this.coursesFinished = [];
            if (typeof courses !== 'undefined') {
                courses.forEach(n => {
                    const courseObj: Course = new Course(n);
                    this.coursesFinished.push(courseObj);
                });
            }
        });
    }
}

<h1>{{'account.Professional_data'|translate}}</h1>
<form id="form" class="form" #profileForm="ngForm" (ngSubmit)="updateProfile()" *ngIf="user">
  <div class="alert alert-success alert-dismissable" *ngIf="updated">
    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="updated = false">×
    </button>
    {{'account.Update_success_flash'|translate}}
  </div>
  <span *ngIf="percent < 100"
        class="user-percent-completed">{{ 'academy.Percent_completed'|translate:{percent: percent} }}</span>
  <div class="form-radio">
    <label class="form-header">{{ 'account.Gender'|translate }}</label>
    <div class="custom-control custom-radio">
      <input type="radio" name="gender" id="gender-m" class="custom-control-input"
             [(ngModel)]="newUser.gender" value="m">
      <label class="custom-control-label" for="gender-m">{{ 'account.Male'|translate }}</label>
    </div>
    <div class="custom-control custom-radio">
      <input type="radio" name="gender" id="gender-f" class="custom-control-input"
             [(ngModel)]="newUser.gender" value="f">
      <label class="custom-control-label" for="gender-f">{{ 'account.Female'|translate }}</label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.First_name'|translate }}</label>
        <input type="text" class="form-control" name="first_name" placeholder=""
               [(ngModel)]="newUser.first_name" readonly>
        <span class="input-disabled" [ngbPopover]="'academy.To_modify_your_name_contact'|translate"></span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.Last_name'|translate }}</label>
        <input type="text" class="form-control" name="last_name" placeholder=""
               [(ngModel)]="newUser.last_name" readonly>
        <span class="input-disabled" [ngbPopover]="'academy.To_modify_your_name_contact'|translate"></span>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.Date_of_birth'|translate }}</label>
        <input class="form-control" [(ngModel)]="newUser.dob" (click)="d.open()" [maxDate]="today" type="text"
               name="dob" ngbDatepicker #d="ngbDatepicker"/>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="form-header" for="">{{ 'account.Specialty'|translate }}</label>
    <select *ngIf="specialities$ | async as specialities"
            id="specialty"
            name="specialty"
            class="form-control"
            [disabled]="user.specialty_verified"
            [ngModel]="selectedSpecialty"
            (ngModelChange)="specialtyChange($event)">
      <option *ngFor="let option of specialities"
              [ngValue]="option">
        {{translationService.getTranslatedName(option['translations'], locale)}}
      </option>
    </select>
  </div>
  <div class="form-group">
    <label class="form-header" for="">{{ 'account.Company_name'|translate }} ({{
      'plan_your_imcas.Optional'|translate }})</label>
    <input type="text" class="form-control" name="company_name_web" placeholder=""
           [(ngModel)]="newUser.company_name_web">
  </div>
  <div class="form-group">
    <label class="form-header" for="">{{ 'account.Address'|translate }} ({{ 'plan_your_imcas.Optional'|translate
      }})</label>
    <input type="text" class="form-control" name="address_web" placeholder="" [(ngModel)]="newUser.address_web">
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.City'|translate }} ({{
          'plan_your_imcas.Optional'|translate }})</label>
        <input type="text" class="form-control" name="city_web" placeholder=""
               [(ngModel)]="newUser.city_web">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.Zip'|translate }} ({{
          'plan_your_imcas.Optional'|translate }})</label>
        <input type="text" class="form-control" name="postal_code_web" placeholder=""
               [(ngModel)]="newUser.postal_code_web">
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="form-header" for="">{{ 'account.Country'|translate }}</label>
    <select *ngIf="countries$ | async as countries"
            id="country"
            name="country"
            class="form-control"
            [ngModel]="selectedCountry"
            (ngModelChange)="countryChange($event)">
      <option *ngFor="let option of countries"
              [ngValue]="option">
        {{translationService.getTranslatedName(option['translations'], locale)}}
      </option>
    </select>
  </div>
  <div class="form-group">
    <label class="form-header" for="">{{ 'cart.Phone_number'|translate }} ({{
      'plan_your_imcas.Optional'|translate }})</label>
    <input type="tel" class="form-control" name="phone" placeholder="" [(ngModel)]="newUser.phone">
  </div>
  <div class="form-group">
    <label class="form-header" for="">{{ 'account.Email_address'|translate }}</label>
    <input type="email" class="form-control" name="email" placeholder="" [(ngModel)]="newUser.email" required
           email>
  </div>
  <div class="form-group">
    <label class="form-header" for="">{{ 'account.Website'|translate }} ({{
      'plan_your_imcas.Optional'|translate }})</label>
    <input type="text" class="form-control" name="website" placeholder="" [(ngModel)]="newUser.website">
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.Twitter'|translate }} ({{
          'plan_your_imcas.Optional'|translate }})</label>
        <input type="text" class="form-control" name="twitter_username" placeholder=""
               [(ngModel)]="newUser.twitter_username">
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.Facebook'|translate }} ({{
          'plan_your_imcas.Optional'|translate }})</label>
        <input type="text" class="form-control" name="facebook_url" placeholder=""
               [(ngModel)]="newUser.facebook_url">
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="form-header" for="">{{ 'account.Instagram'|translate }} ({{
          'plan_your_imcas.Optional'|translate }})</label>
        <input type="text" class="form-control" name="instagram_username" placeholder=""
               [(ngModel)]="newUser.instagram_username">
      </div>
    </div>
  </div>
  <span class="help-block">
        <i class="fa fa-exclamation-triangle"
           aria-hidden="true"></i> {{ 'account.Email_valid_form_update_help_message'|translate }}
    </span>
  <div class="form-submit text-center">
    <button class="button button-orange" [disabled]="!profileForm.valid">{{
      'account.Update_your_professional_data'|translate }}
    </button>
  </div>
</form>
<profile-biostatement></profile-biostatement>
<div class="row delete-account">
  <div class="col-md-12">
  <span class="help-block">
          <i class="fa fa-exclamation-triangle"
             aria-hidden="true"></i> {{ 'account.Account_deletion_help_message'|translate }}
      </span>
  </div>
</div>

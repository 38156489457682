import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AcademyMedia} from '../../../models/academy-media.model';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-course-completed',
    templateUrl: './course-completed.component.html',
    styleUrls: ['./course-completed.component.scss']
})
export class CourseCompletedComponent implements OnInit {
    userAction: any;
    showSocialItems: any;
    academyMedia: AcademyMedia;
    user: any;
    locale: any;
    environment: any = environment;
    slug: string;
    title: any;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

}

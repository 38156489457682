import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare var navigator: any;
declare var Connection: any;

@Injectable()
export class NetworkService {

  public isOffline = false;

  constructor() {
    document.addEventListener('deviceready', () => {
      if (navigator.connection.type == Connection.NONE){
        this.isOffline = true;
      }
      else {
      }

      document.addEventListener("offline", () => {
        this.isOffline = true;
      }, false);

      document.addEventListener("online", () => {
        this.isOffline = false;
      }, false);
    });
  }


}

import {Translation} from './translation.model';
import {TranslationService} from '../services/translation.service';

export class Theme {
    id: number;
    color_code: string;
    picture_squared_url: string;
    picture_url: string;
    translationService: TranslationService = new TranslationService();
    translations: Translation[];

    constructor(obj?: any) {
        this.color_code = obj && obj.color_code;
        this.id = obj && obj.id;
        this.picture_squared_url = obj && obj.picture_squared_url;
        this.picture_url = obj && obj.picture_url;
        this.translations = obj && obj.translations;
    }

    getTranslatedName(locale) {
        return this.translationService.getTranslatedName(this.translations, locale)
    }
    linkLibrary() {
        return '/library/lectures/theme/' + this.id + '/' + this.getTranslatedName('en').toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }
}

<div class="modal-body modal-no-title">
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <div class="abstract" >
        <div class="abstract-details text-center" *ngIf="media.speakers">
            <a routerLink="/profile/{{media.speakers[0].slug}}">
                <img [src]="media.speakers[0].picture_url"
                     class="abstract-details-thumb" alt=""/>
            </a>
            <p class="abstract-details-name">{{media.speakers[0].title + ' ' +
                media.speakers[0].first_name + ' ' +
                media.speakers[0].last_name}}</p>
            <p class="abstract-details-degree">
                {{(media.speakers[0].specialty ?
                translationService.getTranslatedName(media.speakers[0].specialty.translations,
                locale) : '')}}
            </p>
            <p class="abstract-details-country">
                {{(media.speakers[0].country ?
                translationService.getTranslatedName(media.speakers[0].country.translations, locale)
                : '')}}
            </p>
            <p class="abstract-details-other" *ngIf="media.lecture && media.lecture.other_authors">
                {{'program.Other_authors'|translate}}: {{ media.lecture.other_authors}}
            </p>
        </div>
        <div class="abstract-content" *ngIf="media.lecture">
            <h2>{{translationService.getTranslatedAttribute('title', media.translations,
                locale)}}</h2>
            <p *ngIf="media.lecture.abstract_objectives && media.lecture.abstract_objectives != 'n/a'">
                {{'abstract.Objectives'|translate}}: {{media.lecture.abstract_objectives}}</p>
            <p *ngIf="media.lecture.abstract_introduction && media.lecture.abstract_introduction != 'n/a'">
                {{'abstract.Introduction'|translate}}: {{media.lecture.abstract_introduction}}</p>
            <p *ngIf="media.lecture.abstract_materials && media.lecture.abstract_materials != 'n/a'">
                {{'abstract.Materials_method'|translate}}: {{media.lecture.abstract_materials}}</p>
            <p *ngIf="media.lecture.abstract_results && media.lecture.abstract_results != 'n/a'">
                {{'abstract.Results'|translate}}: {{media.lecture.abstract_results}}</p>
            <p *ngIf="media.lecture.abstract_conclusion && media.lecture.abstract_conclusion != 'n/a'">
                {{'abstract.Conclusion'|translate}}: {{media.lecture.abstract_conclusion}}</p>
            <p *ngIf="media.lecture.abstract_content1 && media.lecture.abstract_content1 != 'n/a'">
                {{media.lecture.abstract_content1}}</p>
            <p *ngIf="media.lecture.abstract_content2 && media.lecture.abstract_content2 != 'n/a'">
                {{media.lecture.abstract_content2}}</p>
            <p *ngIf="media.lecture.abstract_content3 && media.lecture.abstract_content3 != 'n/a'">
                {{media.lecture.abstract_content3}}</p>
            <p *ngIf="media.lecture.abstract_content4 && media.lecture.abstract_content4 != 'n/a'">
                {{media.lecture.abstract_content4}}</p>
            <p *ngIf="media.lecture.abstract_content5 && media.lecture.abstract_content5 != 'n/a'">
                {{media.lecture.abstract_content5}}</p>
        </div>
    </div>
</div>

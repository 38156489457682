import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';


@Component({
    selector: 'account-connections',
    templateUrl: './connections.component.html',
    styleUrls: ['./connections.component.scss'],
    providers: [
        UserService
    ]
})
export class AccountConnectionsComponent implements OnInit {
    user: User;
    locale: string;
    currentTab: string = 'pending';
    loading: boolean = true;

    constructor(private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private authService: AuthService,
                private router: Router,
                private localeService: LocaleService,
                public translationService: TranslationService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userWithConnection()
        }
    }

    openUserProfile(connection) {
        const user = new User(connection);
        this.router.navigateByUrl(user.getProfileUrl());
    }
    userWithConnection() {
        this.userService.getUserWithConnections(this.user.id).subscribe(
            user => {
                this.user = new User({...this.user, ...user});
                this.loading = false;
            }
        );
    }
    sendConnection(userId) {
        this.userService.connect(userId).subscribe(success => {
            this.userWithConnection()
        });
    }
    acceptConnection(connection) {
        this.userService.acceptConnection(connection.id).subscribe(success => {
            this.user.connections.pending = this.user.connections.pending.filter(obj => {
                return obj !== connection
            });
            this.user.connections.accepted.push(connection);
            this.user.connections.pending_count--;
            this.user.connections.accepted_count++;
        });
    }

    declineConnection(connection) {
        this.userService.declineConnection(connection.id).subscribe(success => {
            this.user.connections.pending = this.user.connections.pending.filter(obj => obj !== connection);
            this.user.connections.pending_count--;
        });
    }

    deleteConnection(connection) {
        this.userService.declineConnection(connection.id).subscribe(success => {
            this.user.connections.accepted = this.user.connections.accepted.filter(obj => obj !== connection);
            this.user.connections.accepted_count--;
        });
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Media} from '../../../models/media.model';
import {environment} from '../../../../environments/environment';
import {MediaService} from '../../../services/media.service';
import {Observable} from 'rxjs';


@Component({
    selector: 'app-alert-introduction',
    templateUrl: './alert-introduction.component.html',
    styleUrls: ['./alert-introduction.component.scss'],
    providers: [MediaService]
})
export class AlertIntroductionComponent implements OnInit {
    media$: Observable<Media>;
    environment: any = environment;
    @Input() locale: string;

    constructor(private mediaService: MediaService) {
    }

    ngOnInit() {
        const paramsMedia = {
            media_lecture: 0,
            media_speakers: 0
        };
        if (environment.domain === 'aesthetics') {
            if (this.locale === 'ru') {
              this.media$ = this.mediaService.getMedia(21963, paramsMedia);
            } else {
              this.media$ = this.mediaService.getMedia(21431, paramsMedia);
            }
        } else {
            if (this.locale === 'fr') {
                this.media$ = this.mediaService.getMedia(13863, paramsMedia);
            } else {
                this.media$ = this.mediaService.getMedia(13865, paramsMedia);
            }
        }
    }
}

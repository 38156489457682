import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {LocaleService} from '../../services/locale.service';


@Component({
    selector: 'app-alert-coordinators',
    templateUrl: './alert-coordinators.component.html',
    styleUrls: ['./alert-coordinators.component.scss']
})
export class AlertCoordinatorsComponent implements OnInit {
    @Input() coordinators: any;
    @Input() chairs: any;
    @Input() isBoard: any;
    locale: string;

    constructor(public translationService: TranslationService, private localeService: LocaleService) {
        this.locale = this.localeService.currentLocale();
    }

    ngOnInit() {
    }

}

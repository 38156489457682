<h1>{{ 'academy.Videos_approval'|translate }}</h1>
<div class="videos">
	<div class="videos-info">
		<p [innerHTML]="'academy.In_respect_to_TOU'|translate: {link: 'academy/terms-of-use/contributor'}"></p>
	</div>
	<app-loader *ngIf="loading"></app-loader>

	<div class="lectures" *ngIf="videosWaiting">
		<div class="row">
			<div class="course-item col-12 col-md-6" *ngFor="let video of videosWaiting">
				<app-lecture  [card]="true" [lecture]="video" [favoriteElement]="false"></app-lecture>
				<div class="course-request">
					<a (click)="acceptVideo(video)" class="course-request-item">
						<i class="fal fa-check-circle" aria-hidden="true"></i>
					</a>
					<a (click)="declineVideo(video)" class="course-request-item">
						<i class="fal fa-times-circle" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

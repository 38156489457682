import {merge, Observable, of, Subject} from 'rxjs/index';
import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {TranslationService} from 'app/services/translation.service';
import {HumanitarianOrganisationService} from 'app/services/humanitarian-organisation.service';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';


@Component({
    selector: 'profile-missions',
    templateUrl: './missions.component.html',
    styleUrls: ['./missions.component.scss'],
    providers: [
        UserService,
        HumanitarianOrganisationService
    ]
})
export class ProfileMissionsComponent implements OnInit {
    focus$ = new Subject<string>();
    user: User;
    updated: boolean = false;
    model: any;
    search: any;
    searching = false;
    searchFailed = false;
    hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
    locale: string;
    formatter = (r: { name: string }) => r.name;

    constructor(private userService: UserService,
                private humanitarianOrganisationService: HumanitarianOrganisationService,
                public translationService: TranslationService,
                private authService: AuthService,
                public localeService: LocaleService) {
        this.search = (text$: Observable<string>) => {
            const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged(),
                tap(() => this.searching = true),
                switchMap(term => {
                        return this.humanitarianOrganisationService.getHumanitarianOrganisations(term).pipe(
                            tap(() => this.searchFailed = false),
                            catchError(() => {
                                this.searchFailed = true;
                                return of([]);
                            })
                        )
                    }
                ),
                tap(() => this.searching = false),
            );
            return merge(debouncedText$, this.focus$, this.hideSearchingWhenUnsubscribed);
        }
    }

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {user_humanitarian_organizations: 1}).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                }
            );
        }
    }

    addOrganisation(event, input) {
        if (event && event.item && event.item.id) {
            const alreadyAdded = this.user.humanitarian_organizations.find(society => society.id === event.item.id);
            if (!alreadyAdded) {
                this.humanitarianOrganisationService.addHumanitarianOrganisation(event.item.id).subscribe(success => {
                    this.user.humanitarian_organizations.push(event.item);
                    this.updated = true;
                });
            }
        }
        event.preventDefault();
        input.value = '';
    }

    removeOrganisation(item) {
        this.humanitarianOrganisationService.deleteHumanitarianOrganisation(item.id).subscribe(success => {
            this.user.humanitarian_organizations = this.user.humanitarian_organizations.filter(obj => obj !== item);
            this.updated = true;
        });
    }
}

<app-loader *ngIf="coordinatorLoading"></app-loader>
<div *ngIf="!coordinatorLoading" class="d-flex justify-content-end">
  <button class="button button-blue align-self-end {{editMode ? 'disabled': ''}}" (click)="enableEditMode()" [disabled]="editMode">{{'academy.edit' | translate}}</button>
</div>
<form *ngIf="!coordinatorLoading" [formGroup]="coordinatorsForm" [className]="!editMode ? 'disabled-form': ''">
  <div class="form-group flex-column" style="margin-top: 1rem">
    <label class="form-header">{{ 'alert.Type_case'|translate }}</label>
    <div class="form-radio col-md-12">
      <div class="custom-control custom-radio" *ngFor="let type of caseTypes">
        <input type="radio" name="type" [id]="'type-' + type.value" class="custom-control-input" formControlName="type"
               [value]="type.value">
        <label class="custom-control-label"
               [for]="'type-' + type.value">{{ type.title | translate }} <i
          class="fa fa-info-circle"
          [ngbTooltip]="(type.tooltip | translate)"></i></label>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="form-header">{{'evaluation.Select_a_theme'|translate}}</label>
    <select
      id="theme"
      name="theme"
      class="form-control"
      formControlName="theme1Id">
      <option [ngValue]="undefined" [disabled]="coordinatorsForm.get('theme1Id').value !== undefined">{{'evaluation.Select_a_theme'|translate}}</option>
      <option *ngFor="let option of group.themes"
              [ngValue]="option.id">
        {{translationService.getTranslatedName(option.translations, locale)}}
    </select>
  </div>
  <div class="form-checkbox form-group" formArrayName="treatments" *ngIf="group.with_treatment && treatmentsFormArray?.controls.length > 0">
    <label class="form-header">{{ 'alert.Select_treatments'|translate }}</label>
    <div class="checkbox-element d-inline-block" *ngFor="let treatment of treatmentsFormArray?.controls; let index = index;">
      <div class="custom-control custom-checkbox" [formGroupName]="index">
        <input formControlName="checked"
               type="checkbox"
               [id]="'treatments-'+ index"
               class="custom-control-input">
        <label class="custom-control-label" [for]="'treatments-'+ index">{{treatment.get('text').value}}</label>
      </div>
    </div>
  </div>
  <div class="form-group" *ngIf="data.purposes.length > 0">
    <label class="form-header">{{ 'alert.Purpose'|translate }}</label>
    <ng-select name="purpose"
               [multiple]="true"
               [closeOnSelect]="false"
               [hideSelected]="true"
               formControlName="purposes">
      <ng-option *ngFor="let purpose of data.purposes" [value]="purpose.id">{{purpose.text}}</ng-option>
    </ng-select>
  </div>
  <div class="form-group" *ngIf="hasComplication && data.complications.length > 0">
    <label class="form-header">{{ 'alert.Select_complications'|translate }}</label>
    <ng-select name="complication"
               [multiple]="true"
               [closeOnSelect]="false"
               [hideSelected]="true"
               formControlName="complications">
      <ng-option *ngFor="let complication of data.complications" [value]="complication.id">{{complication.text}}</ng-option>
    </ng-select>
  </div>
  <div class="form-group" style="margin-top: 1rem;">
    <label for="alertGroup2" class="group_label">{{'alert.Choose_optional_group'|translate}} </label>
    <select name="alertGroup2" id="alertGroup2" class="group_select" formControlName="group2Id"  >
      <option [value]="''">None</option>
      <ng-container *ngFor="let singleGroup of groups">
        <option *ngIf="singleGroup.id !== groupCaseId" [value]="singleGroup.id">
          {{ translationService.getTranslatedName(singleGroup.translations, locale)}}
        </option>
      </ng-container>
    </select>
  </div>
  <app-loader *ngIf="!hasSecondGroup && coordinatorsForm.get('group2Id').value !== ''"></app-loader>
  <ng-container *ngIf="hasSecondGroup">
    <div class="form-group">
      <label class="form-header">{{'evaluation.Select_a_theme'|translate}} <span class="text-danger">*</span></label>
      <select
        (change)="theme2Change()"
        id="theme2"
        name="theme"
        class="form-control"
        formControlName="theme2Id">
        <option [ngValue]="undefined" [disabled]="coordinatorsForm.get('theme2Id').value !== undefined">{{'evaluation.Select_a_theme'|translate}}</option>
        <option *ngFor="let option of group2.themes"
                [ngValue]="option.id">
          {{translationService.getTranslatedName(option.translations, locale)}}
      </select>
    </div>
    <div class="form-checkbox form-group" formArrayName="treatmentsSecondGroup" *ngIf="group2.with_treatment && treatmentsSecondGroupFormArray?.controls.length > 0">
      <label class="form-header">{{ 'alert.Select_treatments'|translate }} <span class="text-danger">*</span></label>
      <div class="checkbox-element d-inline-block" *ngFor="let treatment of treatmentsSecondGroupFormArray?.controls; let index = index;">
        <div class="custom-control custom-checkbox" [formGroupName]="index">
          <input formControlName="checked"
                  (change)="treatmentsSecondGroupChange()"
                 type="checkbox"
                 [id]="'treatmentsSecondGroup-'+ index"
                 class="custom-control-input">
          <label class="custom-control-label" [for]="'treatmentsSecondGroup-'+ index">{{treatment.get('text').value}}</label>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="data.purposesSecondGroup.length > 0 && (treatmentsSecondGroupFormArray?.controls.length > 0 || coordinatorsForm.get('theme2Id').value)">
      <label class="form-header">{{ 'alert.Purpose'|translate }} <span class="text-danger">*</span></label>
      <ng-select name="purpose"
                 [multiple]="true"
                 [closeOnSelect]="false"
                 [hideSelected]="true"
                 (change)="purposesSecondGroupChange($event)"
                 formControlName="purposesSecondGroup">
        <ng-option *ngFor="let purpose of data.purposesSecondGroup" [value]="purpose.id">{{purpose.text}}</ng-option>
      </ng-select>
    </div>
    <div class="form-group" *ngIf="hasComplication && (coordinatorsForm.get('purposesSecondGroup').value.length > 0 || group2.id === 5) && data.complicationsSecondGroup.length > 0">
      <label class="form-header">{{ 'alert.Select_complications'|translate }} <span class="text-danger">*</span></label>
      <ng-select name="complication"
                 [multiple]="true"
                 [closeOnSelect]="false"
                 [hideSelected]="true"
                 (change)="complicationsSecondGroupChange($event)"
                 formControlName="complicationsSecondGroup">
        <ng-option *ngFor="let complication of data.complicationsSecondGroup" [value]="complication.id">{{complication.text}}</ng-option>
      </ng-select>
    </div>
  </ng-container>
</form>
<div *ngIf="errorsMsg && errorsMsg.length > 0" class="alert alert-danger">
  <ul>
    <li *ngFor="let error of errorsMsg">{{error}}</li>
  </ul>
</div>
<div [className]="!editMode ? 'd-flex disabled-form' : 'd-flex'">
  <button class="button button-orange" style="display:block;" [disabled]="errorsMsg.length > 0 && coordinatorsForm.invalid" (click)="submitGeneralInformation()">
    <span *ngIf="!groupSubmit">Submit modifications</span> <span *ngIf="groupSubmit"><i class="fas fa-spinner fa-spin"></i> Loading...</span></button>
  <button class="button" [disabled]="!coordinatorsForm.dirty" style="display:block;margin-left: 20px" (click)="cancelModifications()">Cancel</button>
</div>
<div *ngIf="submitted" class="alert alert-success">
  <p> {{'alert.General_informations_updated'|translate}}</p>
</div>
<div *ngIf="error" class="alert alert-danger">
  <p> {{'alert.Something_went_wrong'|translate}}</p>
</div>

import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {LocaleService} from '../../services/locale.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lecture-disclosures',
  templateUrl: './lecture-disclosures.component.html',
  styleUrls: ['./lecture-disclosures.component.scss']
})
export class LectureDisclosuresComponent implements OnInit {
  lecture: any;
  locale: any;

  constructor(public translationService: TranslationService,
              private localeService: LocaleService,
              public activeModal: NgbActiveModal) {
    this.locale = this.localeService.currentLocale();
  }

  ngOnInit() {
  }

}

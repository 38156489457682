import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {environment} from '../../environments/environment';
import {AuthService} from 'app/services/auth.service';
import {LocaleService} from 'app/services/locale.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    const localeService = this.injector.get(LocaleService);
    if (request.headers.get('multipart') !== 'yes') {
      request = request.clone({
        setHeaders: {
          'Content-Type': request.method === 'get' ?
            (request.responseType === 'blob' ? 'application/pdf' : 'application/json') :
            'application/x-www-form-urlencoded; charset=UTF-8',
          'domain-origin': environment.domain,
          'lang': localeService.currentLocale()
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json'
        }
      });
      if (request.headers.get('json') === 'yes') {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json'
          }
        });
      }
    }
    const token = authService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
}

<div class="modal-confirm">
  <h2>{{ title|translate }}</h2>
  <div class="row">
    <div class="col-6">
      <button class="button button-yes" (click)="activeModal.close()">{{'sponsor.Yes'|translate}}</button>
    </div>
    <div class="col-6">
      <button class="button button-no" (click)="activeModal.dismiss('no')">{{'sponsor.No'|translate}}</button>
    </div>
  </div>
</div>
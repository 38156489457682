import {environment} from './../../../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {HumanitarianOrganisationService} from '../../../../services/humanitarian-organisation.service';
import {TranslationService} from '../../../../services/translation.service';
import {LocaleService} from '../../../../services/locale.service';


@Component({
    selector: 'app-humanitarian-missions',
    templateUrl: './humanitarian-missions.component.html',
    styleUrls: ['./humanitarian-missions.component.scss'],
    providers: [HumanitarianOrganisationService]
})
export class HumanitarianMissionsComponent implements OnInit {
    public organisations_list: Array<any>;
    public filtered_org_list: Array<any>;
    public locale: string;
    public continents_list: Array<any>;
    public specialities_list: Array<any>;
    public speciality_id: number;
    public continent_id: number;

    environment = environment;

    constructor(public translationService: TranslationService,
                public localeService: LocaleService,
                public humanitarianOrganisationService: HumanitarianOrganisationService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();

        this.humanitarianOrganisationService.getHumanitarianOrganisations('')
            .subscribe(r => {
                this.organisations_list = r;
                const continents = {};
                const specialties = {};
                Array.from(this.organisations_list.map(f => f.area)).forEach((c, i) => {
                    continents[c.id] = c;
                });
                Array.from(this.organisations_list.map(f => f.specialty)).forEach((c, i) => {
                    specialties[c.id] = c;
                });
                this.specialities_list = [];
                this.continents_list = [];

                for (const key of Object.keys(specialties)) {
                    this.specialities_list.push(specialties[key]);
                }
                for (const key of Object.keys(continents)) {
                    this.continents_list.push(continents[key]);
                }
                this.continents_list = this.continents_list.sort((a, b) => {
                    if (a.continent < b.continent) {
                        return -1;
                    } else if (a.continent > b.continent) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                this.filtered_org_list = Array.from(this.organisations_list);
            })
    }

    filterBySpeciality(value) {
        this.speciality_id = parseInt(value, 10);
        if (this.speciality_id) {
            this.filtered_org_list = Array.from(this.organisations_list).filter(f => f.specialty.id === this.speciality_id);
        } else {
            this.filtered_org_list = Array.from(this.organisations_list);
        }
        if (this.continent_id) {
            this.filtered_org_list = Array.from(this.filtered_org_list).filter(f => f.area.id === this.continent_id);
        }
    }

    filterByContinent(value) {
        this.continent_id = parseInt(value, 10);
        if (this.continent_id) {
            this.filtered_org_list = Array.from(this.organisations_list).filter(f => f.area.id === this.continent_id);
        } else {
            this.filtered_org_list = Array.from(this.organisations_list);
        }
        if (this.speciality_id) {
            this.filtered_org_list = Array.from(this.filtered_org_list).filter(f => f.specialty.id === this.speciality_id);
        }
    }
}

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../../../models/user.model';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';

@Component({
    selector: 'app-register-webinar',
    templateUrl: './register-webinar.component.html',
    styleUrls: ['./register-webinar.component.scss'],
    providers: [
        UserService
    ]
})
export class RegisterWebinarComponent implements OnInit {

    title: string;
    subtitle: string;
    subtitle2: string;
    user: User;
    loading: boolean;
    updated: boolean = false;
    error: string;
    newUser = {
        partners_optin: null,
        subscribe_academy_web: null,
    };

    constructor(public activeModal: NgbActiveModal,
                private cookieService: CookieService,
                private userService: UserService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.loading = true;
        this.getUser();
    }

    getUser() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {user_notifications: 1}).subscribe(
                user => {
                    this.loading = false;
                    this.user = new User({...this.user, ...user});
                    this.newUser.subscribe_academy_web = this.user.email_notifications.subscribe_academy_web;
                    this.newUser.partners_optin = this.user.email_notifications.partners_optin;
                }
            );
        }
    }

    updateProfile() {
        const tmpUser = Object.assign({}, this.newUser);
        tmpUser.subscribe_academy_web = tmpUser.subscribe_academy_web ? 1 : 0;
        tmpUser.partners_optin = tmpUser.partners_optin ? 1 : 0;
        this.userService
            .updateUser(tmpUser)
            .subscribe(
                (result: any) => {
                    if (!result.error) {
                        this.updated = true;
                    } else {
                        this.error = result.error;
                    }
                });
        this.cookieService.set('modal_register_webinar', 'ok', 30);
        this.activeModal.close('ok');
    }

    close() {
        this.cookieService.set('modal_register_webinar', 'ok', 30);
        this.activeModal.close('ok');
    }

    isNotUndefined(val) {
        return typeof val !== 'undefined';
    }
}

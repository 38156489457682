import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {from, Observable} from 'rxjs/index';
import * as algoliasearch from 'algoliasearch';

@Injectable()
export class SearchService {
    courseAttributesToRetrieve = ['objectID', 'thumb', 'courseTitles', 'mediaID', 'date', 'sponsor', 'nbVideos', 'users', 'keywords',
        'themes', 'rating', 'free', 'sponsor_name', 'comments', 'event', 'captation_source', 'board_selection',
        'sponsored', 'speakers', 'others', 'type', 'certificate', 'paid_course', 'highlighted'];

    mediaAttributesToRetrieve = ['objectID', 'thumb', 'mediaTitles', 'mediaID', 'date', 'sponsor', 'users', 'keywords', 'themes',
        'rating', 'free', 'duration', 'comments', 'event', 'captation_source', 'board_selection', 'sponsored', 'author_links', 'type',
        'avatar', 'speakers', 'languages'];

    options = environment.algolia;
    client: any = algoliasearch(this.options.appId, this.options.apiKey);

    searchEntries(terms: {
        searchType: string,
        searchQuery: string,
        indices: string[],
        currentPage: number,
        facetFilters: any[],
    }): Observable<any> {
        const queries = [];
        if (terms.indices) {
            for (let i = 0; i < terms.indices.length; ++i) {
                queries.push({
                    indexName: terms.indices[i],
                    query: terms.searchQuery || '',
                    params: {
                        hitsPerPage: 6,
                        page: terms.currentPage,
                        filters: '_tags:' + environment.domain,
                        facetFilters: terms.facetFilters,
                        attributesToRetrieve: terms.searchType === 'courses' ? this.courseAttributesToRetrieve :
                            this.mediaAttributesToRetrieve
                    }
                });
            }
        }
        return from(this.client.search(queries));
    }
}

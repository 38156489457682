<div class="type">
    <div class="item" *ngFor="let group of groups"
         (click)="handleGroupClick(group)">

        <div *ngIf="group.premium_membership_mandatory" class="premium-only">
          <i class="fa fa-star"></i>
          {{'alert.Premium_content' | translate}}
        </div>
        <p class="title">{{translationService.getTranslatedAttribute('name', group.translations, locale)}}
          <span *ngIf="!group.soon">
          ({{'alert.Nb_cases'|translate: {nb: group.nb_cases } }})
          </span>
        </p>

        <p *ngIf="!group.soon" class="members"><i class="fa fa-user-o" aria-hidden="true"></i>{{('alert.Nb_members'|translate:
            {nb: group.nb_members})}}</p>

        <p *ngIf="group.soon">{{'alert.New_group' | translate}}</p>
        <div class="pictures">
            <div *ngFor="let pic of group.random_users_picture" [style.background]="'url(' + pic + ')'" [style.background-size]="'cover'">
            </div>
        </div>
    </div>
</div>

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ThreadService} from 'app/services/thread.service';
import {UserService} from 'app/services/user.service';
import {Thread} from 'app/models/thread.model';
import {BehaviorSubject, from, of} from 'rxjs';
import {DateMomentService} from 'app/services/date-moment.service';
import {User} from 'app/models/user.model';
import {LocaleService} from 'app/services/locale.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PusherService} from 'app/services/pusher.service';
import {WebinarService} from 'app/services/webinar.service';
import * as algoliasearch from 'algoliasearch';
import {environment} from '../../../../environments/environment';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import * as moment from 'moment';


@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  providers: [
    UserService,
    DateMomentService,
    PusherService,
    WebinarService
  ]
})
export class InboxComponent implements OnInit {
  @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;
  threads: Thread[];
  filteredThreads: Thread[] = [];
  private searchTerms = new BehaviorSubject<string>('');
  selectedThread: Thread;
  user: User;
  locale: any;
  messageToSend: string;
  client: any;
  search: string = '';
  users = [];
  lastMsg: any;
  moment: any = moment;
  showSuggest: boolean = false;

  constructor(
    private threadService: ThreadService,
    private userService: UserService,
    public dateMomentService: DateMomentService,
    private localeService: LocaleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pusherService: PusherService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.scrollToBottom();
    this.locale = this.localeService.currentLocale();
    const options = environment.algolia;
    this.client = algoliasearch(options.appId, options.apiKey);
    this.getUser();
    this.getThreads();
    this.activatedRoute.params.subscribe(params => {
      if (params['slug']) {
        this.userService.getUser(params['slug'], {
          cover_url: 1,
          user_badge: 1,
          user_specialty: 1,
          user_country: 1,
          user_biostatement: 1,
          thread_with_auth: 1,
          friendship_status_with_auth: 1,
          user_media_ids: 1,
          user_next_lectures: 1,
          lecture_user: 0,
          with_session_users: 0,
          pubmed: 1,
          pubmed_visibility: 'show',
          user_friends: 1,
          user_scientific_societies: 1,
          user_themes: 1,
          theme_pictures: 1,
          connections: 1,
          lecture_session: 1,
          session_congress: 1
        }).subscribe(
          user => {
// redirect to thread
            this.router.navigate(['/inbox/thread/', user.thread_with_auth]);
          }
        );
      } else {
        this.getThread(params['threadId']);
      }

    });

    this.searchTerms
      .pipe(debounceTime(300)
        , distinctUntilChanged(), switchMap((term: string) => {
          if (term.length > 0) {
            const queries = [
              {
                indexName: 'users',
                query: this.search,
                params: {
                  hitsPerPage: 2,
                  filters: '_tags:' + environment.domain
                }
              }
            ]
            return from(this.client.search(queries));
          } else {
            return of([]);
          }
        }))
      .subscribe((content: any) => {
        if (content.results && content.results.length > 0) {
          const users = content.results[0];
          this.filteredThreads = this.filteredThreads.filter(thread => {
            if (thread.participant && thread.participant.user && thread.last_message &&
              (users.hits[0] && +thread.participant.user.id === +users.hits[0].objectID.match(/\d+/)[0] ||
                (users.hits[1] && +thread.participant.user.id === +users.hits[1].objectID.match(/\d+/)[0]))) {
              return true;
            }
            return false;
          });

          if (!this.filteredThreads || this.filteredThreads.length === 0) {
            this.showSuggest = true;
            this.users = users.hits;
          }
        }
      });
  }

  searchUserThreads(term: string): void {
    this.users = [];
    this.filteredThreads = this.threads;
    this.search = term;
    this.searchTerms.next(term);
  }

  selectThread(thread: Thread, user: any) {
    this.showSuggest = false;
    if (!thread && user) {
      const userId = user.objectID.match(/\d+/) ? user.objectID.match(/\d+/)[0] : null;
      if (userId) {
        this.threadService.newThread(userId).subscribe((t: any) => {
          this.router.navigate(['/', 'inbox', 'thread', t.data ? t.data.id : t.id]);
        });
      }
    } else if (thread && !user) {
      this.router.navigate(['/', 'inbox', 'thread', thread.id]);
    } else {
      this.router.navigate(['/', 'inbox']);
    }
  }

  getThread(threadId: number) {
    if (threadId) {
      this.threadService.getThread(threadId).subscribe(t => {
        this.selectedThread = t;
        this.readThread(this.selectedThread);
        this.pusherService.initializePusher(['private-thread-' + this.selectedThread.id, 'thread-' + this.selectedThread.id]);
        this.pusherService.msgsStream$
          .subscribe(upcomingMsg => {
            if (this.selectedThread && this.selectedThread.messages && (!this.lastMsg || (this.lastMsg &&
              upcomingMsg.message !== this.lastMsg.message && upcomingMsg.created_at !== this.lastMsg.created_at))) {
              const tmpMsg = {
                body: upcomingMsg.message,
                created_at: upcomingMsg.created_at,
                read: true,
                user_id: upcomingMsg.user_id
              };
              this.selectedThread.messages.push(tmpMsg);
              if (this.threads) {
                const readThread = this.threads.find(th => th.id === this.selectedThread.id);
                if (readThread) {
                  readThread.last_message = tmpMsg;
                }
              }
              this.lastMsg = upcomingMsg;
              this.scrollToBottom();
            }
          });
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
      });
    }
  }

  readThread(thread) {
    this.threadService.readThread(thread.id).subscribe(success => {
      this.threadService.getMessagesUnreadCount();
      if (this.threads) {
        const readThread = this.threads.find(t => t.id === thread.id);
        if (readThread) {
          if (thread.messages && thread.messages.length > 0) {
            readThread.last_message = thread.messages[thread.messages.length - 1];
            readThread.last_message.read = true;
          }
        }
      }
    });
  }

  getUser() {
    this.user = this.authService.getCurrentUser();
  }

  getThreads() {
    this.threadService.getThreads('', {all: 1, messages: 1}).subscribe(results => {
      this.threads = results.sort(function (a, b) {
        return moment(b.last_message.created_at).valueOf() - moment(a.last_message.created_at).valueOf();
      });
      this.filteredThreads = results;
      if (this.selectedThread) {
        const readThread = this.filteredThreads.find(t => t.id === this.selectedThread.id);
        if (readThread) {
          readThread.last_message.read = true;
        }
      }
    });
  }

  sendMessage(msg: string, threadId: number) {
    this.threadService.sendMessage(msg, threadId).subscribe(t => {
      this.messageToSend = null;
    });
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

}

import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user.model';

@Component({
  selector: 'app-diploma-upload',
  templateUrl: './diploma-upload.component.html',
  styleUrls: ['./diploma-upload.component.scss']
})
export class DiplomaUploadComponent implements OnInit, OnDestroy {

  @Output()statusOutput = new EventEmitter<string>();
  @ViewChild('fileUpload', {static: false})
  fileUpload: ElementRef;
  files: File[] = [];
  uploadAbleFiles: File[] = [];
  user: User;
  status: string;
  index: number = 0;

  constructor(private userService: UserService, private authService: AuthService) { }


  ngOnInit() {
    this.user = this.authService.getCurrentUser();
    if (!this.user.diploma_status) {
      return;
    }
    if (this.user.diploma_status.includes('not_verified')) {
      this.status = 'not_verified'
    } else if (this.user.diploma_status.includes('under_process')) {
      this.status = 'under_process'
    } else if (this.user.diploma_status.includes('verified')) {
      this.status = 'verified'
    }
    this.statusOutput.emit(this.status);
  }


  uploadFiles() {
    this.files.forEach((file) => {
      this.addFile(file);
    })
  }

  sendFile(file) {
    this.status = 'under_process';

      let obj = {};
      obj['certificate' + this.index] = file;
      this.index += 1;
      this.userService.uploadNewDiplomas(obj).subscribe((event: any) => {
          this.statusOutput.emit(this.status);
      },
        (error) => {
        this.status = 'not_verified';
        this.statusOutput.emit(this.status);
      });
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        if (this.files.length > 4) {

          return alert('You cannot upload more than 5 files');
        }
        if (file.size > 15000000) {

          return alert('The files cannot be more than 15 Mo');
        }
        this.files.push(file);
      }
    };
    fileUpload.click();
  }

  addFile(file) {
    const myReader = new FileReader();
    const that = this;
    myReader.onloadend =  (ev: any) => {
      this.sendFile(ev.target.result);
    };
    myReader.readAsDataURL(file);
  }

  removeFile(file) {
    this.files = this.files.filter((arrFile) => {
      this.index = this.files.length || 0;
      return file !== arrFile;
    })
  }

  ngOnDestroy(): void {
    if (!this.user.diploma_status) {
      return;
    }
    if (this.user.diploma_status.includes('not_verified')) {
      this.user.diploma_status = this.user.diploma_status.replace('not_verified', this.status)
    } else if (this.user.diploma_status.includes('under_process')) {
      this.user.diploma_status = this.user.diploma_status.replace('under_process', this.status)
    } else if (this.user.diploma_status.includes('verified')) {
      this.user.diploma_status = this.user.diploma_status.replace('verified', this.status)
    }
    this.authService.setCurrentUser(this.user);
    this.statusOutput.emit(this.status);
  }


}

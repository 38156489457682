import {Locales} from '../../../locales';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {TranslationService} from 'app/services/translation.service';
import {AlertService} from 'app/services/alert.service';
import {environment} from '../../../../environments/environment';
import {DateMomentService} from 'app/services/date-moment.service';
import {User} from 'app/models/user.model';
import {AuthService} from 'app/services/auth.service';
import {Case} from 'app/models/case.model';
import {LocaleService} from '../../../services/locale.service';
import {tap} from 'rxjs/operators';
import {KeywordService} from '../../../services/keyword.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationComponent} from '../../modal/confirmation/confirmation.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HistoryService} from "../../../services/mobile-services/history.service";
import {FilesService} from "../../../services/files.service";
@Component({
    selector: 'alert-submit',
    templateUrl: './alert-submit.component.html',
    styleUrls: ['./alert-submit.component.scss'],
    providers: [
        AlertService,
        KeywordService,
        FilesService
    ]
})
export class AlertSubmitComponent implements OnInit {
    locale: any;
    environment: any = environment;
    currentUser: User;
    acceptRules: boolean;
    userAcceptConditions: boolean;
    slug: string;
    group$: Observable<any>;
    newCase: Case;
    topicId: number;
    groupObj: any;
    errors: string;
    locales = Locales;
    submittedCase: any;
    submitInProcess: boolean;
    updateRoute: boolean = false;
    acceptedFormat: string = '';
    public purposeData: Array<any> = [];
    public purposeOptions: Array<any>;
    public purposeValue: any[];
    public complicationsData: Array<any> = [];
    public complicationsOptions: Array<any>;
    public complicationsValue: any[];
    public tools: object = {
    items: [
      'Bold', 'Italic', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink']
   };
    loading: boolean = false;
    pictures: any[] = [];
    videos: any[] = [];
    extension: string = '';
    fileName: string = '';
    filesError: string = '';
    newCaseFiles: Array<any> = [];
    uploaded: boolean = false;
    caseId: number = 0;

    constructor(private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                public translationService: TranslationService,
                private translateService: TranslateService,
                public dateMomentService: DateMomentService,
                private keywordService: KeywordService,
                private router: Router,
                private authService: AuthService,
                private localeService: LocaleService,
                private modalService: NgbModal,
                public historyService: HistoryService,
                private filesService: FilesService) {
        this.newCase = new Case();
        this.newCase.patient_description = '';
        this.newCase.case_description = '';
        this.newCase.anonymous = 1;
        this.acceptedFormat = filesService.acceptedFormat;
    }

    ngOnInit() {
        this.submitInProcess = false;
        this.locale = this.localeService.currentLocale();

        this.activatedRoute.params.subscribe(params => {
            this.slug = params['group'];
            this.topicId = params['topicId'];
        });
        // We set up the component differently depending on the URL (submit a new topic / update an existing topic)
        if (this.topicId) {
          this.updateRoute = true;
        }
        if (this.updateRoute) {
          this.loading = true;
        }
        this.currentUser = this.authService.getCurrentUser();
        if (!this.currentUser || !this.currentUser.id) {
            this.router.navigate(['/', 'account', 'login']);
        }
        this.group$ = this.alertService.getGroup(this.slug, {
            with_treatments: '1', with_themes: '1', keywords: '1', complications: '1', treatments: '1', purposes: '1', themes: '1'
        })
        .pipe(tap(group => {
            this.newCase.alert_group_id = group.id;
            this.purposeData = [];
            this.groupObj = group;
            if (!this.groupObj.with_treatment) {
              this.purposeData = this.groupObj.purposes.map((item)=>{
                return {
                  id: item.id,
                  text: this.translationService.getTranslatedName(item.translations, this.locale)
                }
              });
            } else {
                const treatmentsList = this.threatmentsByTheme(this.newCase.theme_id);
                this.purposeData = this.groupObj.purposes.filter(purpose => {
                    const treatments = purpose.treatments.filter(tr => {return treatmentsList.find(t => t.id === tr.id)});
                    return treatments && treatments.length > 0;
                }).map(purpose =>  {
                    return {
                        id: purpose.id,
                        text: this.translationService.getTranslatedName(purpose.translations, this.locale)
                    }
                });
            }
        }));

        this.complicationsOptions = [];
        this.purposeValue = [];
        this.complicationsValue = [];

        // In an update setup we fetch existing 'compiled' data
        if (this.updateRoute && this.topicId) {
          this.alertService.getCase(this.topicId).subscribe(caseObj => {
            this.caseId = caseObj.id;
            this.newCase = Object.assign(this.newCase, caseObj);
            this.newCaseFiles.push(...this.newCase.pictures, ...this.newCase.videos.map(video => {
              return {
                location: video.url,
                title: video.file_name,
                id: video.id
              };
            }));
            if (this.currentUser.id !== this.newCase.user_id) {
              this.router.navigate(['/', 'account', 'login']);
            }

            if (this.newCase.themes[0]) {
              this.newCase.theme_id = this.newCase.themes[0].id;
            }

            if (this.newCase.purposes) {
              this.purposeValue = this.newCase.purposes.map(p => p.id);
              const ids = this.purposeValue.join(',');
              this.setComplications(ids);
            }

            if (this.newCase.complications) {
              this.complicationsValue = this.newCase.complications.map(c => c.id);
            }
            this.loading = false;
          });
        }
      }


      threatmentsByTheme(themeId) {
        const treatmentsByThemes = [];
        this.groupObj.treatments.forEach((p, i) => {
          if (p.theme_id === themeId) {
            treatmentsByThemes.push(p);
          }
        });
        return treatmentsByThemes;
      }
      purposeChanged(data:any[]) {
        this.newCase.purposes = data;
        const ids = this.newCase.purposes.join(',');
        this.setComplications(ids);
      }

      setComplications(ids) {
        if (!this.updateRoute) {
          this.complicationsData = [];
        } else {
          this.complicationsValue = [];
        }
        this.keywordService.getKeywords(ids).subscribe(keywords => {
          for (let i = 0; i < keywords.length; i++) {
            for (let j = 0; j < keywords[i].complications.length; j++) {
              let translation = this.translationService.getTranslatedName(keywords[i].complications[j].translations, this.locale)
              this.complicationsData.push({
                id: keywords[i].complications[j].id,
                text: translation != "n/a" ? translation : keywords[i].complications[j]["name"]
              });
            }
          }
            this.complicationsData = this.complicationsData.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.id === thing.id
                ))
            );
        });
    }

    complicationsChanged(data: number[]) {
        this.newCase.complications = data;
    }

    themeChange(e) {
        this.newCase.theme_id = e;
        if(this.groupObj.with_treatment) {
          this.purposeValue = [];
        }
    }

    treatmentChange(treatment: any, isChecked: boolean) {
        let data = this.purposeData;
        this.purposeData = [];
        if (isChecked) {
            this.newCase.treatments.push(treatment);
            for (let i = 0; i < this.groupObj.purposes.length; i++) {
                let hasPurpose = false;
                for (let j = 0; j < this.groupObj.purposes[i].treatments.length; j++) {
                    if (treatment.id === this.groupObj.purposes[i].treatments[j].id) {
                        hasPurpose = true;
                    }
                }
                if (hasPurpose) {
                    data.push({
                        id: this.groupObj.purposes[i].id,
                        text: this.translationService.getTranslatedName(this.groupObj.purposes[i].translations, this.locale)
                    });
                }
            }
        } else {
            for (let i = 0; i < this.groupObj.purposes.length; i++) {
                let hasPurpose = null;
                for (let j = 0; j < this.groupObj.purposes[i].treatments.length; j++) {
                    if (treatment.id === this.groupObj.purposes[i].treatments[j].id) {
                        hasPurpose = this.groupObj.purposes[i];
                    }
                }
                if (hasPurpose) {
                    for (let j = data.length - 1; j >= 0; j--) {
                        if (data[j].id === hasPurpose.id) {
                            data.splice(j, 1);
                        }
                    }
                }
            }
            const index = this.newCase.treatments.map(t => t.id).indexOf(treatment.id, 0);
            if (index > -1) {
                this.newCase.treatments.splice(index, 1);
            }

            const ids = this.newCase.treatments.map((treatment)=>{
              return treatment.id
            }).join(',');
            this.setComplications(ids);

        }
        setTimeout(() => {
            data = data.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.id === thing.id
                ))
            );
            this.purposeData = data;
                const ids = this.newCase.treatments.map(keyword => keyword.id).join(',');
                this.setComplications(ids);
        }, 0);


    }

    hasTreatment(id) {
      if (this.newCase.treatments) {
        return this.newCase.treatments.find(kw => kw.id === id);
      } else {
        return false;
      }
    }

    addFiles(event) {
      const returnValue = this.filesService.uploadFiles(event, this.newCaseFiles);
      if (returnValue.error) {
        switch (returnValue.error.name) {
          case 'Size' || 'Error': {
            this.filesError = returnValue.error.name;
            this.fileName = returnValue.error.subTextError;
            break;
          }
          default: {
            this.filesError = returnValue.error.name;
          }
        }
        setTimeout(() => this.filesError = '', 5000);
      } else {
        this.uploaded = true;
        this.newCaseFiles = returnValue.newFilesList;
      }
    }
    onFileChange(i,event) {
        this.alertService.deleteCaseFiles(this.caseId,{
            type: this.isPicture(this.newCaseFiles[i]) ? 'picture' : 'video',
            file_id: this.newCaseFiles[i].id}).subscribe(() => {
            const returnValue = this.filesService.replaceFile(event, this.newCaseFiles, i);
            if (returnValue.error) {
                switch (returnValue.error.name) {
                    case 'Size' || 'Error': {
                        this.filesError = returnValue.error.name;
                        this.fileName = returnValue.error.subTextError;
                        break;
                    }
                    default: {
                        this.filesError = returnValue.error.name;
                    }
                }
                setTimeout(() => this.filesError = '', 5000);
            } else {
                this.uploaded = true;
                this.newCaseFiles = returnValue.newFilesList;
            }
        }, ()=> {
            this.filesError = 'Back';
            setTimeout(() => {this.filesError = ''}, 5000);
        });
    }

    removeFile(index) {
      if (this.newCaseFiles[index].location) {
        this.alertService.deleteCaseFiles(this.caseId,{
          type: this.isPicture(this.newCaseFiles[index]) ? 'picture' : 'video',
          file_id: this.newCaseFiles[index].id}).subscribe(() => {
          this.newCaseFiles.splice(index,1);
        }, ()=> {
          this.filesError = 'Back';
          setTimeout(() => {this.filesError = ''}, 5000);
        })
      } else {
        this.newCaseFiles.splice(index,1);
      }
    }

    submitCase(newCase) {
       if (!this.submitInProcess) {
        this.submitInProcess = true;
        const response = {
          error: {
            rules: []
          }
        };
       const tmpCase = new Case(newCase);
        tmpCase.treatments = Array.from(this.newCase.treatments).map(t => t.id);
        if (!tmpCase.type) {
          response.error.rules.push([this.translateService.instant('alert.Type_required')]);
        }
        if (!tmpCase.theme_id) {
          response.error.rules.push([this.translateService.instant('alert.Theme_required')]);
        }
        if (!tmpCase.locale) {
          response.error.rules.push([this.translateService.instant('alert.Lang_required')]);
        }
        let hadTreatments = false;
        this.groupObj.treatments.forEach((p, i) => {
          if (p.theme_id === tmpCase.theme_id || p.theme_id === tmpCase.theme2_id) {
            hadTreatments = true;
          }
        });
        if (hadTreatments && tmpCase.treatments.length === 0 && tmpCase.type !== 'discussion' && this.groupObj.with_treatment) {
          response.error.rules.push([this.translateService.instant('alert.Treatments_required')]);
        }

        if (!this.groupObj.with_treatment && (!tmpCase.purposes || !tmpCase.purposes.length)) {
          response.error.rules.push([this.translateService.instant('alert.Purposes_required')]);
        }

        if (!tmpCase.title || typeof tmpCase.title === 'undefined') {
          response.error.rules.push([this.translateService.instant('alert.Title_required')]);
        }
        if ((!tmpCase.patient_description || typeof tmpCase.patient_description === 'undefined') && tmpCase.type !== 'discussion') {
          response.error.rules.push([this.translateService.instant('alert.Description_required')]);
        }
        if (tmpCase.type === 'complication' && !tmpCase.complications.length) {
          response.error.rules.push([this.translateService.instant('alert.complications_required')]);
        }
        if (this.groupObj.with_treatment && !tmpCase.purposes.length  && tmpCase.type === 'complication') {
          response.error.rules.push([this.translateService.instant('alert.Purposes_required')]);
        }
        if (!tmpCase.case_description || typeof tmpCase.case_description === 'undefined') {
          response.error.rules.push([this.translateService.instant('alert.Case_descr_required')]);
        }
        if ( !this.userAcceptConditions ) {
          response.error.rules.push([this.translateService.instant('alert.Accept_conditions')]);
        }
        if (!this.acceptRules ) {
          response.error.rules.push([this.translateService.instant('alert.Accept_rules')]);
        }
        if ((!tmpCase.patient_description || typeof tmpCase.patient_description === 'undefined')) {
          tmpCase.patient_description = 'n/a';
        }
        let fd = new FormData();
        tmpCase.files = 0;
        tmpCase.purposes = this.purposeValue;
        tmpCase.complications = this.complicationsValue;
        tmpCase.keywords = [...this.purposeValue, ...this.newCase.treatments.map(t => t.id)];
        this.newCaseFiles.filter(file => file.file).forEach((p, i) => {
            fd.append('files[' + i + '][content]', p.file, p.text);
            fd.append('files[' + i + '][name]', p.text);
            fd.append('files['+i+'][type]', p.type);
            tmpCase.files = 1;
        });
        if (response.error.rules.length > 0) {
          this.showErrors(response)
        }
         else if (this.updateRoute) {
           if (typeof tmpCase.theme2_id === 'undefined') {
             tmpCase.theme2_id = '';
           }
           // We overwrite default Case model constructor assuming noone ever edits a closed case
           tmpCase.open = 1;
           this.alertService.updateCase(tmpCase.id, tmpCase).subscribe(submittedCase => {
             if (this.newCaseFiles.filter(file => file.file).length > 0) {
               this.alertService.addCaseFilesV2(tmpCase.id, fd).subscribe(r => {
               this.submittedCase = submittedCase;
                 this.submitInProcess = false;
               }, asyncResponse => {
                 this.showErrors(asyncResponse);
               });
             } else {
               this.submitInProcess = false;
               this.submittedCase = submittedCase;
             }
           }, asyncResponse => this.showErrors(asyncResponse));
         } else {
           if (typeof tmpCase.theme2_id === 'undefined') {
             tmpCase.theme2_id = '';
           }
           // At submit (not update), we show a modal if there is no picture
           if (this.newCaseFiles.length === 0) {
             const modalRef = this.modalService.open(ConfirmationComponent);
             modalRef.componentInstance.title = 'alert.Submit_case_without_pictures';
             modalRef.result.then(result => {
               this.alertService.addCase(tmpCase).subscribe(submittedCase => {
                 if (submittedCase.files) {
                   this.alertService.addCaseFiles(submittedCase.id, fd).subscribe(r => {
                     this.submittedCase = submittedCase;
                     this.submitInProcess = false;
                   }, asyncResponse => {
                     this.showErrors(asyncResponse);
                     this.alertService.deleteCase(submittedCase.id).subscribe(r => {});
                   });
                 } else {
                   this.submitInProcess = false;
                   this.submittedCase = submittedCase;
                 }
               }, asyncResponse => this.showErrors(asyncResponse));
             }, result => {
               return this.submitInProcess = false;
             });
           } else {
             this.alertService.addCase(tmpCase).subscribe(submittedCase => {
               if (submittedCase.files) {
                 this.alertService.addCaseFilesV2(submittedCase.id, fd).subscribe(r => {
                   this.submittedCase = submittedCase;
                   this.submitInProcess = false;
                 }, asyncResponse => {
                   this.showErrors(asyncResponse);
                   this.alertService.deleteCase(submittedCase.id).subscribe(r => {});
                 });
               } else {
                 this.submitInProcess = false;
                 this.submittedCase = submittedCase;
               }
             }, asyncResponse => this.showErrors(asyncResponse));
           }
         }
      }
    }

    showErrors(response) {
        if (response.error.rules) {
            this.errors = '<ul>';
            for (const ruleKey in response.error.rules) {
                if (response.error.rules.hasOwnProperty(ruleKey)) {
                    response.error.rules[ruleKey].forEach((element) => {
                        this.errors += '<li>' + element + '</li>';
                    });
                }
            }
            this.errors += '</ul>';
        } else {
            this.errors = 'An error occured';
        }
        this.submitInProcess = false;
    }

  isPicture(picture) {
    return this.filesService.isPicture(picture);
  }


  isVideo(title: string) {
    return this.filesService.isVideo(title);
  }
}

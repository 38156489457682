<div class="row alert-introduction">
  <div *ngIf="environment.domain == 'aesthetics'" class="col-lg-7 col-md-6 alert-introduction-video">
    <div class="play">
      <i class="fa fa-play" aria-hidden="true"></i>
    </div>
    <app-jwplayer-video *ngIf="media$ | async as media"
                        [file]="media.cdn_dash_file"
                        [file2]="media.cdn_file"
                        [media]="media"
                        [width]="'100%'"
                        [aspectratio]="'16:9'"
                        [image]="media.thumb">
    </app-jwplayer-video>
  </div>
  <div class="col-lg-{{ environment.domain == 'aesthetics' ? 5 : 12 }} col-md-{{ environment.domain == 'aesthetics' ? 6 : 12 }} alert-introduction-text">
    <p>{{'alert.Home_descr_link'|translate}}</p>
    <a *ngIf="environment.domain  == 'aesthetics'" [routerLink]="['/library/lectures']" [queryParams]="{ q : 'alert'}" class="button button-orange">{{'alert.Alert_case'|translate}}</a>
  </div>
</div>

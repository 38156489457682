import {User} from './user.model';
import {Media} from './media.model';
import {Product} from './product.model';

export class CongressProduct {
  id: number;
  prices: any;
  original_prices: any;
  color: string;
  slug: string;
  product: Product;
  popular: boolean;
  marketplace_free_product: boolean;
  add_delivery_fees: boolean;
  marketplace_bank_transfer: boolean;
  bought_by_auth: any;
  free_order_by_auth: any;
  partner: User;
  medias: Media[];

  constructor(obj?: any) {
    this.id = obj && obj.id;
    this.prices = obj && obj.prices;
    this.original_prices = obj && obj.original_prices;
    this.product = obj && obj.product ? new Product(obj.product) : null;
    this.popular = obj && obj.popular;
    this.marketplace_free_product = obj && obj.marketplace_free_product;
    this.add_delivery_fees = obj && obj.add_delivery_fees;
    this.marketplace_bank_transfer = obj && obj.marketplace_bank_transfer;
    this.slug = obj && obj.slug;
    this.bought_by_auth = obj && obj.bought_by_auth;
    this.free_order_by_auth = obj && obj.free_order_by_auth;
    this.partner = obj && obj.partner ? new User(obj.partner) : null;
    if (obj && obj.medias) {
      this.medias = [];
      obj.medias.forEach(element => {
        this.medias.push(new Media(element));
      });
    }
  }

  getPriceObj(currency = null, original = false) {
    let price;
    let prices;
    if (original) {
      prices = this.original_prices;
    } else {
      prices = this.prices;
    }
    if (currency) {
      price = prices.find(x => x.currency === currency);
    }
    if (!price) {
      price = prices[0];
    }
    return price;
  }

  getPrice(currency) {
    const price = this.getPriceObj(currency);
    if (price) {
      return price.price_subscription_ttc;
    }
  }

  getPriceTtc(currency) {
    const price = this.getPriceObj(currency);
    if (price) {
      return price.price_ttc;
    }
  }

  getOriginalPriceTtc(currency) {
    const price = this.getPriceObj(currency, true);
    if (price) {
      return price.price_ttc;
    }
  }

  setColor(color) {
    this.color = color;
  }
}

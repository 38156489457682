<div class="modal-header">
    <h4 class="modal-title">{{'academy.Corporate_funding'|translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>IMCAS retrieves its revenues either from the industry’s subscriptions or from physicians’s subscriptions. IMCAS is conducting
        educational activities but it is also a profit-seeking business.</p>
    <p>How can then this dilemma be resolved? Simply by clearly identifying and differentiating sponsored activities from non
        sponsored ones. Business grants and sponsorships are then to be clearly explained in order for all concerned parties
        (doctors, industries, foundations, government bodies, private purpose funds) to stay aware and vigilant. Thus, IMCAS
        embraces and welcomes funding, always given the fact that funds origins are traced.
    </p>
    <p> Industries acting as business corporations, may support IMCAS by providing either an educational grant or a sponsorship.
    </p>
    <ul>
        <li> An educational grant for a program or an event is a financial donation with no exchange partnership at stake. Funds
            are given according to the donator wishes in order to support the recipient’s program or project. The scientific
            content is entirely developed and chosen by the Scientific Board and as such is eligible for CME accreditation.
            Unlike sponsoring, which is a bilateral exchange involving the recipient promoting its sponsor, granting is a
            unilateral gesture.
        </li>
        <li> A sponsorship is the financial support of an activity. Sponsoring an event involves a partnership exchange between
            the sponsor and the sponsee. Although a commercial intent may be at heart, one must bear in mind that reasons
            for sponsoring are various.
        </li>
    </ul>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class WorldwideEventsService {
    private partnershipsEndpoint: string = environment.apiURL + '/worldwide_events';

    constructor(private httpClient: HttpClient) {
    }

    public get(ending_date_after?: string,
               country_id?: number | string,
               show_partners?: boolean,
               ending_date_before?: string,
               public_explore?: boolean): Observable<any> {
        let params = new HttpParams();
        params = params.set('all', '1');
        if (ending_date_after) {
            params = params.set('ending_date_after', '' + ending_date_after);
        }
        if (ending_date_before) {
            params = params.set('ending_date_before', '' + ending_date_before);
        }
        if (country_id) {
            params = params.set('country_id', '' + country_id);
        }

        if (show_partners) {
            params = params.set('partner', '1');
        }
        if (public_explore) {
            params = params.set('public_explore', '1');
        }
        return this.httpClient.get<any>(this.partnershipsEndpoint, {params: params})
            .pipe(shareReplay());
    }

}

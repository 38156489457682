import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {Case} from "../../models/case.model";
import {AlertService} from "../../services/alert.service";
import _ from "lodash";
import {TranslationService} from "../../services/translation.service";
import {LocaleService} from "../../services/locale.service";
import {KeywordService} from "../../services/keyword.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-alert-coordinators-form',
  templateUrl: './alert-coordinators-form.component.html',
  styleUrls: ['./alert-coordinators-form.component.scss']
})
export class AlertCoordinatorsFormComponent implements OnInit {
  @Input() caseObj: Case;
  @Input() group: any;
  @Input() groupSelect: any;
  @Input() groups: any;
  @Input() slug: any;
  @Input() groupCaseId: any;
  locale: string;
  //COORDINATORS VARIABLES
  purposes = [];
  complications: any[] = [];
  treatments: any[] = [];
  theme1Id: number = undefined;
  caseType: string;
  caseTypes: any;
  hasComplication: boolean = false;
  hasSecondGroup: boolean = false;
  group2: any;
  data = {
    theme1Id: null,
    theme2Id: null,
    group2Id: 0,
    treatments: [],
    purposes: [],
    complications: [],
    treatmentsSecondGroup: [],
    purposesSecondGroup: [],
    complicationsSecondGroup: [],
    hasSecondGroup: false,
    hasComplication: false
  };
  defaultGroup2: any = null;
  defaultData = this.data;
  defaultCoordinatorsForm: FormGroup;
  coordinatorsForm: FormGroup;
  complicationsUncompleted$ = new BehaviorSubject(false);
  groupSubmit: boolean = false;
  coordinatorLoading: boolean = false;
  errorsMsg = [];
  error: boolean = false;
  editMode: boolean = false;
  submitted: boolean = false;
  constructor(private fb: FormBuilder,
              private alertService: AlertService,
              public translationService: TranslationService,
              private localeService: LocaleService,
              private keywordService: KeywordService,
              private router: Router,
              private translateService: TranslateService) {
    this.caseTypes = alertService.TYPE_CASE;
  }

  ngOnInit(): void {
    this.locale = this.localeService.currentLocale();
    this.caseType = this.caseObj.type;
    this.hasComplication =  this.data.hasComplication  = this.caseObj.type === 'complication';
    this.coordinatorsForm = this.fb.group({
      type: [this.caseObj.type],
      group2Id: [this.groupSelect],
      theme1Id: [''],
      treatments: this.fb.array([]),
      purposes: [[]],
      complications: [[]],
      theme2Id: [''],
      treatmentsSecondGroup: this.fb.array([]),
      purposesSecondGroup: [[]],
      complicationsSecondGroup: [[]]
    });
    this.data.theme1Id = this.caseObj.themes.find(theme => this.group.themes.find(th => th.id === theme.id)).id;
    this.coordinatorsForm.get('theme1Id').setValue(this.data.theme1Id);
    if (this.group.themes.length === 1) {
      this.coordinatorsForm.get('theme1Id').disable();
    }
    if(!this.group.with_treatment) {
      this.data.purposes = this.getPurposes(this.group.purposes);
    } else {
      this.data.treatments = this.getTreatments(this.group.treatments, this.data.theme1Id, this.caseObj.treatments);
      this.data.treatments.forEach(tr => this.treatmentsFormArray.push(this.fb.group({
        id: [tr.id],
        text: [tr.text],
        checked: [tr.checked]
      })));
      this.data.purposes = this.getTreatmentsPurposes(this.group.purposes, this.data.treatments);
    }
    if (this.groupSelect) {
      this.data.hasSecondGroup = true;
      this.groupChanged(this.groupSelect, true);
    }
    const purposes = this.caseObj.purposes.filter(purpose => this.group.purposes.find(p => p.id === purpose.id)).map(purpose =>purpose.id);
    this.coordinatorsForm.get('purposes').setValue(purposes);
    if (this.caseObj.type === 'complication') {
      this.hasComplication = true;
      this.getComplications(purposes, false, this.caseObj);
      //this.coordinatorsForm.get('complications').setValue(this.caseObj.complications.map(cp => cp.id));
    }
    this.coordinatorsForm.valueChanges.subscribe(() => {
      this.errorsMsg = [];
      this.error = false;
    });

    // LISTENING VALUE CHANGES
    this.coordinatorsForm.get('type')?.valueChanges.subscribe(value => {
      if (value === 'complication') {
        if(this.coordinatorsForm.get('purposes').value.length > 0) {
          this.updateComplications(this.coordinatorsForm.get('purposes').value);
          this.hasComplication = true;
          this.complicationsUncompleted$.next(true);
        }
        if (this.coordinatorsForm.get('purposesSecondGroup').value && this.coordinatorsForm.get('purposesSecondGroup').value.length > 0) {
          this.updateComplications(this.coordinatorsForm.get('purposesSecondGroup').value, true);
          this.hasComplication = true;
          this.complicationsUncompleted$.next(true);
        }
      } else {
        this.hasComplication = false;
        this.coordinatorsForm.get('complications')?.setValue([]);
        this.coordinatorsForm.get('complicationsSecondGroup')?.setValue([]);
        this.complicationsUncompleted$.next(false);
      }
    });
    this.coordinatorsForm.get('theme1Id')?.valueChanges.subscribe(value => {
      if (value) {
        if (this.group.with_treatment) {
          this.data.treatments = this.getTreatments(this.group.treatments, value, this.caseObj.treatments);
          this.treatmentsFormArray.clear();
          this.data.treatments.forEach(tr => this.treatmentsFormArray.push(this.fb.group({
            id: [tr.id],
            text: [tr.text],
            checked: [tr.checked]
          })));
        }
      }
    });
    this.coordinatorsForm.get('treatments').valueChanges.subscribe(value => {
      this.data.purposes = this.getTreatmentsPurposes(this.group.purposes, value);
      this.coordinatorsForm.get('purposes').setValue([]);
      this.coordinatorsForm.get('complications')?.setValue([]);
      this.hasComplication = false;
    });
    this.coordinatorsForm.get('purposes').valueChanges.subscribe(value => {
      this.updateComplications(value);
    });
    this.coordinatorsForm.get('complications').valueChanges.subscribe(value => {
      if (value && value.length !== 0) {
        this.complicationsUncompleted$.next(false);
      } else {
        this.complicationsUncompleted$.next(true);
      }
    });
    this.coordinatorsForm.get('group2Id').valueChanges.subscribe(value => {
      this.hasSecondGroup = false;
      this.groupChanged(value);
      this.treatmentsSecondGroupFormArray.clear();
      this.coordinatorsForm.get('complicationsSecondGroup').setValue([]);
      this.coordinatorsForm.get('purposesSecondGroup').setValue([]);
      if (!value) {
        this.removeSecondGroup();
        this.complicationsUncompleted$.next(this.coordinatorsForm.get('complications').value.length < 1);
      }
    });
  }

  get treatmentsFormArray() : FormArray {
    return this.coordinatorsForm.get('treatments') as FormArray;
  }
  get treatmentsSecondGroupFormArray() : FormArray {
    return this.coordinatorsForm.get('treatmentsSecondGroup') as FormArray;
  }

  getPurposes(purposes: any[]) {
    return purposes.map(purpose => {
      return {
        id: purpose.id,
        text: this.translationService.getTranslatedName(purpose.translations, this.locale)
      }
    });
  }

  getTreatmentsPurposes(purposes: any[], treatmentsList: any[]) {
    return purposes.filter(purpose => {
      const treatments = purpose.treatments.filter(tr => {return treatmentsList.find(t => t.id === tr.id && t.checked)});
      return treatments && treatments.length > 0;
    }).map(purpose => {
      return {
        id: purpose.id,
        text: this.translationService.getTranslatedName(purpose.translations, this.locale)
      }
    });
  }

  getTreatments(treatments: any[], themeId: any, selectedValues: any[]){
    return treatments.filter(treatment => themeId === treatment.theme_id).map(treatment => {
      if (themeId === treatment.theme_id) {
        const checked  = selectedValues?.find(tr => tr.id === treatment.id);
        return {
          id: treatment.id,
          text: this.translationService.getTranslatedName(treatment.translations, this.locale),
          checked:checked !== undefined
        }
      }
    });
  }

  checkErrors() {
    if (!this.treatmentsChecked(this.treatmentsFormArray) && this.coordinatorsForm.get('type').value !== 'discussion' && this.group.with_treatment) {
      this.errorsMsg.push(this.translateService.instant('alert.Treatments_required'));
    }
    if (!this.group.with_treatment && !this.coordinatorsForm.get('purposes').value.length) {
      this.errorsMsg.push(this.translateService.instant('alert.Purposes_required'));
    }
    if (this.group.with_treatment && !this.coordinatorsForm.get('purposes').value.length  && this.coordinatorsForm.get('type').value === 'complication') {
      this.errorsMsg.push(this.translateService.instant('alert.Purposes_required'));
    }
    if (this.coordinatorsForm.get('type').value === 'complication' && !this.coordinatorsForm.get('complications').value.length) {
      this.errorsMsg.push(this.translateService.instant('alert.complications_required'));
    }
    if (this.coordinatorsForm.get('group2Id').value && !this.coordinatorsForm.get('theme2Id').value) {
      this.errorsMsg.push(this.translateService.instant('alert.Theme_required'));
    }
    if (this.coordinatorsForm.get('group2Id').value && this.coordinatorsForm.get('type').value === 'complication' && !this.coordinatorsForm.get('complicationsSecondGroup').value.length) {
      this.errorsMsg.push(this.translateService.instant('alert.complications_required'));
    }
    if (this.coordinatorsForm.get('group2Id').value && !this.treatmentsChecked(this.treatmentsSecondGroupFormArray) && this.coordinatorsForm.get('type').value !== 'discussion' && this.group2.with_treatment) {
      this.errorsMsg.push(this.translateService.instant('alert.Treatments_required'));
    }
    if (this.coordinatorsForm.get('group2Id').value && !this.group2.with_treatment && !this.coordinatorsForm.get('purposesSecondGroup').value.length) {
      this.errorsMsg.push(this.translateService.instant('alert.Purposes_required'));
    }
    if (this.coordinatorsForm.get('group2Id').value && this.group2.with_treatment  && this.coordinatorsForm.get('type').value === 'complication' && (!this.coordinatorsForm.get('purposesSecondGroup').value.length && this.group2.id !==5)) {
      this.errorsMsg.push(this.translateService.instant('alert.Purposes_required'));
    }
    return this.errorsMsg.length > 0;
  }
  submitGeneralInformation() {
    const error = this.checkErrors();
    if (!error) {
      this.groupSubmit = true;
      const treatments = this.treatmentsFormArray.controls.filter(control => control.value.checked).map(control => control.value.id);
      const treatmentsSecondGroup = this.treatmentsSecondGroupFormArray?.controls.filter(control => control.value.checked).map(control => control.value.id);
      const complications = this.coordinatorsForm.get('complications').value;
      const complicationsSecondGroup = this.coordinatorsForm.get('complicationsSecondGroup')?.value;
      const purposes = this.coordinatorsForm.get('purposes').value;
      const purposesSecondGroup = this.coordinatorsForm.get('purposesSecondGroup')?.value;
      let tmpCase = new Case(this.caseObj);
      tmpCase.type = this.coordinatorsForm.get('type').value;
      tmpCase.treatments = treatmentsSecondGroup ? [...treatments, ...treatmentsSecondGroup] : treatments;
      tmpCase.theme_id = this.coordinatorsForm.get('theme1Id').value;
      tmpCase.theme2_id = this.coordinatorsForm.get('theme2Id')?.value === null ? '' : this.coordinatorsForm.get('theme2Id')?.value;
      tmpCase.complications = complicationsSecondGroup ? [...complications, ...complicationsSecondGroup] : complications;
      tmpCase.purposes = purposesSecondGroup ? [...purposes, ...purposesSecondGroup] : purposes;
      tmpCase.alert_group_id_2 = this.coordinatorsForm.get('group2Id').value ? parseInt(this.coordinatorsForm.get('group2Id').value) : '';
      this.alertService.updateCase(this.caseObj.id, tmpCase).subscribe(r => {
          this.errorsMsg = [];
          this.error = false;
          this.groupSubmit = false;
          this.submitted = true;
          this.editMode = false;
          setTimeout(() => {
            this.submitted = false;
          }, 3000);
        }, error => {
          this.groupSubmit = false;
          this.errorsMsg = [];
          this.error = true;
          this.editMode = false;
         setTimeout(() => {
           this.error = false;
          }, 3000);
        }
      );
    }
  }

  treatmentsChecked(treatments: FormArray) {
    if (treatments && treatments.length === 0) {
      return false;
    }
    return treatments.controls.find(treatment => treatment.value.checked) !== undefined;
  }
  getComplications(purposesIds: any, second: boolean = false, caseObj?: any) {
    second ? this.data.complicationsSecondGroup = [] : this.data.complications = [];
    return this.keywordService.getKeywords(purposesIds.join(',')).subscribe(keywords => {
      keywords.forEach(keyword => {
        keyword.complications.forEach(complication => {
          let translation = this.translationService.getTranslatedName(complication.translations, this.locale);
          (!second ? this.data.complications : this.data.complicationsSecondGroup).push({
            id: complication.id,
            text: translation != "n/a" ? translation : complication.name,
          });
        });
      });
      if (second) {
        this.data.complicationsSecondGroup = this.data.complicationsSecondGroup.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.id === thing.id
            ))
        );
        this.coordinatorsForm.get('complicationsSecondGroup').setValue(caseObj ? caseObj?.complications.filter(cp => this.data.complicationsSecondGroup.find(c => c.id === cp.id)).map(cp => cp.id) : []);
      } else {
        this.data.complications = this.data.complications.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.id === thing.id
            ))
        );
        this.coordinatorsForm.get('complications').setValue(caseObj ? caseObj?.complications.filter(cp => this.data.complications.find(c => c.id === cp.id)).map(cp => cp.id) : []);
      }
    });
  }

  createSecondGroup(group: any, caseObj?: any) {
    this.group2 = group;
    const theme2 = caseObj?.themes.find(theme => group.themes.find(th => th.id === theme.id)).id;
    this.data.theme2Id = theme2 ? theme2 : undefined;
    this.coordinatorsForm.get('theme2Id').setValue(this.data.theme2Id);
    if(!group.with_treatment) {
      this.data.purposesSecondGroup = this.getPurposes(group.purposes);
      this.coordinatorsForm.get('treatmentsSecondGroup').reset();
    } else {
      this.data.treatmentsSecondGroup = this.getTreatments(group.treatments, this.data.theme2Id, caseObj?.treatments);
      this.data.treatmentsSecondGroup.forEach(tr => this.treatmentsSecondGroupFormArray.push(this.fb.group({
        id: [tr.id],
        text: [tr.text],
        checked: [tr.checked]
      })));
      this.data.purposesSecondGroup = this.getTreatmentsPurposes(group.purposes, this.data.treatmentsSecondGroup);
    }
    const purposes = caseObj?.purposes.filter(purpose => group.purposes.find(p => p.id === purpose.id)).map(purpose =>purpose.id);
    this.coordinatorsForm.get('purposesSecondGroup').setValue(caseObj ? purposes : []);
    if (purposes) {
      this.getComplications(purposes, true, caseObj);
    }
  }

  groupChanged(groupId: any, update = false) {
      if (groupId) {
        const group = this.groups.find(group => group.id === parseInt(groupId));
        this.alertService.getGroup(group.slug, {
          coordinators: '1',
          with_treatments: '1',
          with_themes: '1',
          keywords: '1',
          complications: '1',
          treatments: '1',
          purposes: '1',
          themes: '1'
        }).subscribe(groupObj => {
          this.hasSecondGroup = true;
          this.coordinatorLoading = false;
          if (update) {
            this.defaultGroup2 = groupObj;
            this.createSecondGroup(groupObj, this.caseObj);
          } else {
            this.createSecondGroup(groupObj);
          }
        },error => {
          this.router.navigate(['/alert', this.slug]);
        });
      }
  }

  cancelModifications() {
    this.coordinatorLoading = true;
    this.hasComplication = this.data.hasComplication;
    if (this.hasSecondGroup && !this.groupSelect) {
      this.removeSecondGroup();
    }
    this.coordinatorsForm.patchValue(this.defaultCoordinatorsForm.value, {emitEvent: false});
    this.data = this.defaultData;
    if (this.groupSelect) {
      this.groupChanged(this.defaultGroup2.id, true);
    }
    this.hasSecondGroup = this.data.hasSecondGroup;
    setTimeout(() => {
      this.coordinatorLoading = false;
    }, 2000);
  }

  updateComplications(purposes, second: boolean = false) {
    const type = this.coordinatorsForm.get('type').value;
    this.hasComplication = type === 'complication';
    if(type === 'complication') {
      this.getComplications(purposes, second);
      this.coordinatorsForm.get(second ? 'complicationsSecondGroup' : 'complications').setValue([]);
      this.complicationsUncompleted$.next(true);
    } else {
      this.complicationsUncompleted$.next(false);
    }
  }

  removeSecondGroup() {
    this.coordinatorLoading = true;
    this.coordinatorsForm.get('purposesSecondGroup').reset();
    this.coordinatorsForm.get('theme2Id').reset();
    this.coordinatorsForm.get('treatmentsSecondGroup').reset();
    this.coordinatorsForm.get('complicationsSecondGroup').reset();
    this.coordinatorLoading = false;
  }

  enableEditMode() {
    this.editMode = true;
    this.defaultData = this.data;
    this.defaultCoordinatorsForm = _.cloneDeepWith(this.coordinatorsForm);
  }

  complicationsSecondGroupChange(value) {
    if (value && value.length !== 0) {
      this.complicationsUncompleted$.next(false);
    } else {
      this.complicationsUncompleted$.next(true);
    }
  }

  treatmentsSecondGroupChange() {
    if (this.group2.id !== 5) {
      this.data.purposesSecondGroup = this.getTreatmentsPurposes(this.group2.purposes, this.treatmentsSecondGroupFormArray.value);
      this.coordinatorsForm.get('purposesSecondGroup').setValue([]);
    } else {
      this.updateComplications(this.treatmentsSecondGroupFormArray.value.map(v => v.id), true);
    }
  }

  purposesSecondGroupChange(value) {
    if (value && value.length > 0 ){
      this.updateComplications(value, true);
    }
  }

  theme2Change() {
    const value = this.coordinatorsForm.get('theme2Id').value;
    if (value) {
      if (this.group2.with_treatment) {
        this.data.treatmentsSecondGroup = this.getTreatments(this.group2.treatments, value, this.caseObj.treatments);
        this.treatmentsSecondGroupFormArray.clear();
        this.data.treatmentsSecondGroup.forEach(tr => this.treatmentsSecondGroupFormArray.push(this.fb.group({
          id: [tr.id],
          text: [tr.text],
          checked: [tr.checked]
        })));
      } else {
        this.data.purposesSecondGroup = this.getPurposes(this.group2.purposes);
        this.coordinatorsForm.get('purposesSecondGroup').setValue([]);
      }
    }
  }
}

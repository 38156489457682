import {AcademyMedia} from '../../../../models/academy-media.model';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {DateMomentService} from 'app/services/date-moment.service';
import {environment} from '../../../../../environments/environment';
import {EmitterService} from 'app/services/emitter.service';
import {MediaService} from 'app/services/media.service';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../models/user.model';
import {LocaleService} from '../../../../services/locale.service';


@Component({
    selector: 'contributions-videos',
    templateUrl: './videos.component.html',
    styleUrls: ['./videos.component.scss'],
    providers: [
        MediaService
    ]
})
export class ContributionsVideosComponent implements OnInit {
    locale: string;
    videosPublished: AcademyMedia[] = [];
    environment: any = environment;
    contributionsVideosPublishedEmitter = 'contributionsVideosPublishedEmitter';
    contributionsVideosPublished10MoreEmitter = 'contributionsVideosPublished10MoreEmitter';
    videos: any;
    user: User;
    loading: boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
                public translationService: TranslationService,
                public dateMomentService: DateMomentService,
                public localeService: LocaleService,
                private mediaService: MediaService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.loading = true;
        this.locale = this.localeService.currentLocale();
        this.user = this.authService.getCurrentUser();
        EmitterService
            .get(this.contributionsVideosPublishedEmitter)
            .subscribe((result) => {
                this.loading = false;
                if (!result.error) {
                    result.forEach(el => {
                        this.videosPublished.push(new AcademyMedia(el));
                    })
                }
            });
    }

  onCoursesScroll() {
    this.loading = true;
    EmitterService
      .get(this.contributionsVideosPublished10MoreEmitter)
      .emit('get10More')
  }
}

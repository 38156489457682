export class ReportStatistics {

  ImcasMembership: {
    totalMembers: {
      value: string,
    },
    physiciansMembers: {
      value: string,
    },
    premiumMembers: {
      value: string,
    },
  };
  ImcasCountries: any;
  ImcasSpecialtyDistribution: any;
  ImcasGeographicalDistribution: any;
  ImcasSpecialtyTypeDistribution: any;
  ImcasGenderDistribution: any;
  ImcasTopTenCountries: any;
  ImcasPreferredLanguages: any;
  ImcasLibrary: any;
  ImcasVideosTypes: any;
  ImcasVideosByLanguage: any;
  ImcasActivityDevice: any;
  ImcasVideosViewsByYear: any;
  ImcasOverviewByYear: any;
  ImcasBounceRate: {
    bounceRate: {
      value: string;
    }
  };
  ImcasAlertOverview: {
      Cases: {
        value: string;
      },
    AverageReponsePerCase: {
      value: string;
    },
    Responses: {
      value: string;
    },
    NumberOfReplyingExperts: {
        value: string;
    }
  };
  ImcasTopTenSearch: {
    themeIds: {
      value: string;
    }
  };
}

import {AcademySubscription} from './academy-subscription.model';
import {PromotionalCode} from './promotional-code.model';
import {Payment} from './payment.model';

export class Cart {
    id: number;
    academy_subscriptions: AcademySubscription[];
    products: AcademySubscription[];
    total: Array<any>;
    promotional_codes: PromotionalCode[];
    payment: Payment;
    vads_page_action: string;
    academy_doctor: boolean;
    delivery_fees_eur: string;
    delivery_fees_usd: string;
    delivery_fees_id: string;

    constructor(obj?: any) {
        this.id = obj && obj.id;
        this.total = obj && obj.total;
        this.vads_page_action = obj && obj.vads_page_action;
        this.payment = obj && obj.payment ? new Payment(obj.payment) : null;
        this.academy_doctor = obj && obj.academy_doctor;
        this.delivery_fees_eur = obj && obj.delivery_fees_eur;
        this.delivery_fees_usd = obj && obj.delivery_fees_usd;
        this.delivery_fees_id = obj && obj.delivery_fees_id;
        if (obj && obj.academy_subscriptions) {
            this.academy_subscriptions = [];
            obj.academy_subscriptions.forEach(element => {
                this.academy_subscriptions.push(new AcademySubscription(element));
            });
        }
        if (obj && obj.products) {
            this.products = [];
            obj.products.forEach(element => {
                this.products.push(new AcademySubscription(element));
            });
        }
        if (obj && obj.promotional_codes) {
            this.promotional_codes = [];
            obj.promotional_codes.forEach(element => {
                this.promotional_codes.push(new PromotionalCode(element));
            });
        }
    }
}

<h1>{{ 'account.Change_password'|translate }}</h1>
<div class="password">
	<div class="email">{{ 'academy.Your_email'|translate }}: {{ user.email }}</div>
	<div class="alert alert-danger alert-dismissable" *ngIf="error">
		<button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="error=null">×</button>
		{{'validation.Bad_current_password'|translate}}
	</div>
	<div class="alert alert-success alert-dismissable" *ngIf="updated">
		<button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="updated = false">×</button>
		{{'account.Update_success_flash'|translate}}
	</div>
	<form #passwordForm="ngForm" novalidate (ngSubmit)="updateProfile()">
		<div class="form-group">
			<label class="form-header" for="">{{'account.Current_password'|translate}}</label>
			<input type="password" name="password_old" #passwordOld="ngModel" required [(ngModel)]="newUser.password_old" class="form-control"
			/>
			<div class="form-group-error" [hidden]="passwordOld.valid || (passwordOld.pristine && !passwordForm.submitted)">
				<p>
					{{ 'account.Previous_password'|translate }}
				</p>
			</div>
		</div>
		<div class="form-group">
			<label class="form-header" for="">{{'account.New_password'|translate}}</label>
			<input type="password" name="password" class="form-control" [(ngModel)]="newUser.password" required validateEqual="confirmPassword" reverse="true" #password="ngModel">
			<div class="form-group-error" [hidden]="password.valid || (password.pristine && !passwordForm.submitted)">
				<p>{{ 'account.Password_required'|translate }}</p>
			</div>
		</div>
		<div class="form-group">
			<label class="form-header" for="">{{'account.New_password_confirmation'|translate}}</label>
			<input type="password" class="form-control" name="confirmPassword" [(ngModel)]="newUser.password_confirmation"
				required validateEqual="password" reverse="false" #confirmPassword="ngModel">
			<div class="form-group-error" [hidden]="confirmPassword.valid || (confirmPassword.pristine && !passwordForm.submitted)">
				<p>{{ 'account.Password_mismatch'|translate }}</p>
			</div>
		</div>
		<div class="form-button">
			<button class="button button-orange" [disabled]="!passwordForm.valid">{{'account.Change_password'|translate}}</button>
		</div>
	</form>
</div>

import {Notification} from 'app/models/notification.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class NotificationService {
  private endpoint: string = '/notifications';

  constructor(private httpClient: HttpClient) {
  }

  getNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(environment.apiURL + this.endpoint).pipe(shareReplay());
  }

  getNewNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(environment.apiURL + this.endpoint + '/new').pipe(shareReplay());
  }

  markAsRead(notificationId: number): Observable<any> {
    return this.httpClient.put<any>(environment.apiURL + this.endpoint + '/' + notificationId, {
      read: 1
    }).pipe(shareReplay());
  }

  markAllAsRead(): Observable<any> {
    return this.httpClient.put<any>(environment.apiURL + this.endpoint + '/read-all', {}).pipe(shareReplay());
  }
}

<div class="page">
  <app-section-top [title]="'academy.About_title'" [subtitle]=""
                   [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-library.jpg'"></app-section-top>
  <section [className]="environment.mobile ? 'section has-back-button-padding-top-15' : 'section'">
    <div class="content">
      <div class="col-lg-12">
        <h1 class="about-title">{{ 'academy.About_audience_title'|translate }}</h1>
        <h2 class="about-subtitle">{{ 'academy.About_membership_title'|translate }}</h2>
        <div class="container">
          <div class="stats-section row">
            <div class="col-6 col-sm-4 one-stat">
              <h3>{{imcasMembership.totalMembers.value | number}}</h3><br>
              <p>{{ 'academy.About_stats_section_members'|translate }}</p>
            </div>
            <div class="col-6 col-sm-4 one-stat">
              <h3>{{imcasMembership.physiciansMembers.value| number}}</h3><br>
              <p>{{ 'academy.About_stats_section_physician_members'|translate }}</p>
            </div>
            <div class="col-6 col-sm-4 one-stat">
              <h3>{{imcasMembership.premiumMembers.value| number}}</h3><br>
              <p>{{ 'academy.About_stats_section_premium_members'|translate }}</p>
            </div>
          </div>
        </div>
        <!--                <h2 class="about-subtitle">PHYSICIAN MEMBERS (12 429)</h2>-->
        <div class="container charts-section">

          <h3 class="title-chart col-sm-12">{{ 'academy.About_audience_country_title'|translate }}</h3>
          <div class="content regions_div mb-5">
            <google-chart [data]="geoChartData" style="width: 100%;max-width: 750px;"></google-chart>
          </div>
          <div class="row no-gutters">
            <div class="col-md-6 mb-5 text-center">
              <h3 class="title-chart">{{ 'academy.About_chart_specialty_title'|translate }}</h3>
              <google-chart *ngIf="chartsReady" [data]="specialtyDistribPieChart" class="d-flex justify-content-center"></google-chart>
              <app-loader *ngIf="!chartsReady"></app-loader>
             </div>
             <div class="col-md-6 mb-5 text-center">
               <h3 class="title-chart">{{ 'academy.About_chart_geographical_title'|translate }}</h3>
              <google-chart *ngIf="chartsReady" [data]="geographicalDistribPieChart" class="d-flex justify-content-center"></google-chart>
               <app-loader *ngIf="!chartsReady"></app-loader>
             </div>
           </div>

         </div>
         <div class="container charts-section">
           <div class="row">
             <div class="col-sm-12">
               <h3 class="title-chart col-sm-12">{{ 'academy.About_audience_specialty_title'|translate
                 }}</h3>
               <div class="progress-number">
                 <span>{{specialtyTypeDistrib.surgical.percent}}%</span>
                 <span style="float: right;">{{specialtyTypeDistrib.nonSurgical.percent}}%</span>
               </div>
               <div class="progress">
                 <div class="progress-bar progress-bar-left" role="progressbar"
                      [style.width.%]="specialtyTypeDistrib.surgical.percent"></div>
                 <div class="progress-bar progress-bar-right" role="progressbar"
                      [style.width.%]="specialtyTypeDistrib.nonSurgical.percent"></div>
               </div>
               <div class="progress-text">
                 <span>{{ 'academy.About_progress_surgical_title'|translate }}</span>
                 <span style="float: right;">{{ 'academy.About_progress_nonsurgical_title'|translate }}</span>
               </div>
               <br>
               <div class="progress-number">
                 <span>{{genderDistrib.female.percent}}%</span>
                 <span style="float: right;">{{genderDistrib.male.percent}}%</span>
               </div>
               <div class="progress">
                 <div class="progress-bar progress-bar-left" role="progressbar"
                      [style.width.%]="genderDistrib.female.percent"></div>
                 <div class="progress-bar progress-bar-right" role="progressbar"
                      [style.width.%]="genderDistrib.male.percent"></div>
               </div>
               <div class="progress-icon">
                 <span class="fa fa-venus"></span>
                 <span style="float: right;" class="fa fa-mars fa-3x"></span>
               </div>
             </div>
           </div>
         </div>

         <div class="container charts-section">
           <div class="row">
             <div class="col-sm-6">
               <h3 class="title-chart">{{ 'academy.About_top_members_countries_title'|translate }}</h3>
               <ol id="top-10-countries">
                 <li *ngFor="let country of topTenCountries | mapToIterable"><img
                   src="{{ environment.cdn_base_url }}/assets-front/img/report/{{country.key | lowercase | countryFlag}}.svg">{{country.key}}
                   ({{country.value}}%)
                 </li>
               </ol>
             </div>
             <div class="col-sm-6">
               <h3 class="title-chart">{{ 'academy.About_preferred_language_title'|translate }}</h3>
               <ol>
                 <li *ngFor="let language of topFiveLanguages | mapToIterable">
                   {{ 'academy.' + language.key | translate }}
                   ({{language.value}}%)
                 </li>
               </ol>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
   <section class="section grey-bg">
     <div class="content">
       <div class="col-lg-12">
         <h1 class="about-title">{{ 'academy.About_learning_content_title'|translate }}</h1>
         <h2 class="about-subtitle">{{ 'academy.About_library_title'|translate }}</h2>
         <div class="container">
           <div class="stats-section row">
             <div *ngFor="let item of library | mapToIterable" class="col-6 col-sm-4 one-stat">

               <h3>{{item.value | number}}</h3>
               <br>
               <p>{{ 'academy.' + item.key | translate}}</p>

             </div>

             <div class="col-sm-12">
               <div class="progress-number ">
                 <span>{{videosTypes.nonSponsored.value}}</span>
                 <span style="float: right;">{{videosTypes.sponsored.value}}</span>
               </div>
               <div class="progress">
                 <div class="progress-bar progress-bar-left" role="progressbar"
                      [style.width.%]="videosTypes.nonSponsored.value"></div>
                 <div class="progress-bar progress-bar-right" role="progressbar"
                      [style.width.%]="videosTypes.sponsored.value"></div>
               </div>
               <div class="progress-text">
                 <span>{{ 'academy.About_video_nonsponsored_title'|translate }} ({{videosTypes.nonSponsored.percent}}
                   %)</span>
                 <span style="float: right;">{{ 'academy.About_video_sponsored_title'|translate }}
                   ({{videosTypes.sponsored.percent}}%)</span>
               </div>
             </div>
           </div>
         </div>

         <div class="charts-section row" style="margin-top: 15px;">
           <h3 class="title-chart col-sm-12">{{ 'academy.About_nbvideos_available_title'|translate }}</h3>
           <div class="col-md-4 col-md-offset-4">
             <ol>
               <li
                 *ngFor="let language of videosByLanguage.data | mapToIterable">{{ 'academy.' + language[0] | translate }}
                 ({{language[1]}})
               </li>
             </ol>
           </div>
           <!--                  <div class="barchart-com">-->
          <!--                        <google-chart-->
          <!--                                [title]="videosByLanguage.title"-->
          <!--                                [type]="videosByLanguage.type"-->
          <!--                                [data]="videosByLanguage.data"-->
          <!--                                [columnNames]="videosByLanguage.columnNames"-->
          <!--                                [options]="videosByLanguage.options">-->
          <!--                        </google-chart>-->
          <!--                    </div>-->
          <h3 class="title-chart col-sm-12" style="margin-top: 5%;">{{
            'academy.About_top_videos_views_title'|translate }}</h3>
          <div class="col-sm-12 panel-tab">
            <ul class="tabs row no-gutters">
              <li class="tab" style="width:33%; margin:0" *ngFor="let tabYear of videosYearTabs"
                  [ngClass]="{'active': videosYearTab === tabYear}" (click)="selectYear(tabYear)">
                <a>{{tabYear}}</a>
              </li>
            </ul>
          </div>
          <div class="content">
            <div class="library-courses" *ngIf="topTenVideosViewedByYear.length > 0">
              <div class="row">
                <div class="item video-card" *ngFor="let mItem of topTenVideosViewedByYear">
                  <!--                                  {{mItem | json}}-->
                  <app-lecture [card]="true" [lecture]="mItem" [user]="user" [favoriteElement]="false"
                               [isForReport]="true"></app-lecture>
                </div>
              </div>
            </div>
          </div>
          <h3 class="title-chart col-sm-12" style="margin-top: 5%;">{{
            'academy.About_webinars_by_nb_title'|translate }}</h3>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 panel-tab">
                <ngb-tabset>
                  <ngb-tab [title]="'academy.Registrants'|translate">
                    <ng-template ngbTabContent>
                      <ng-container *ngIf="webinarsByRegistrants">
                        <div class="webinars">
                          <div class="webinars-item"
                               *ngFor="let webinar of webinarsByRegistrants">
                            <h2 class="webinars-item-title">
                              <a
                                [routerLink]="['/course', webinar.id, webinar.slug]">{{translationService.getTranslatedAttribute('title',
                                webinar.translations, locale)}}</a>
                            </h2>
                            <span class="fa fa-users" aria-hidden="true"></span>
                            {{webinar.webinar_registrants}} {{
                            'academy.Registrants'|translate }}

                            <p class="webinars-item-date">
                              {{dateMomentService.getPublicationDate(webinar.publication_date)}}</p>
                            <div class="webinars-item-report"
                                 *ngIf="webinar.webinar_session">
                              <div class="data-report"
                                   *ngIf="webinar.webinar_session.nb_registered > 2">
                                <p style="text-align: left;">
                                          <span style="text-align: center; display: inline-block;" placement="top"
                                                ngbTooltip="Registrants" class="fa fa-users" aria-hidden="false"></span>
                                  {{webinar.webinar_session.nb_registered}}
                                </p>
                              </div>
                            </div>
                            <div class="webinars-speakers">
                              <div class="webinars-speakers-item"
                                   *ngFor="let user of webinar.speakers | mapToIterable">
                                <a [routerLink]="['/profile', user.slug]">
                                  <div class="rounded-picture"
                                       [style.background]="'url('+user.picture_url+')'"
                                       [style.background-size]="'cover'"></div>
                                </a>
                              </div>
                            </div>
                            <div class="webinars-button">
                              <a [routerLink]="['/course', webinar.id, webinar.slug]"
                                 class="button button-violet-outline">{{user &&
                              user.premium_membership ?
                                ('academy.Watch_it_now'|translate) :
                                ('webinar.Previous_webinars_cta'|translate)}}</a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-template>
                  </ngb-tab>
                  <ngb-tab [title]="'academy.Live_viewers'|translate">
                    <ng-template ngbTabContent>
                      <ng-container *ngIf="webinarsByLiveViewers">
                        <div class="webinars">
                          <div class="webinars-item"
                               *ngFor="let webinar of webinarsByLiveViewers">

                            <h2 class="webinars-item-title">
                              <a
                                [routerLink]="['/course', webinar.id, webinar.slug]">{{translationService.getTranslatedAttribute('title',
                                webinar.translations, locale)}}</a>
                            </h2>

                            <span class="fa fa-user-md-chat" aria-hidden="true"></span>
                            {{webinar.webinar_live_viewers}} live viewers

                            <p class="webinars-item-date">
                              {{dateMomentService.getPublicationDate(webinar.publication_date)}}</p>
                            <div class="webinars-item-report"
                                 *ngIf="webinar.webinar_session">
                              <div class="data-report"
                                   *ngIf="webinar.webinar_session.nb_viewers > 2">
                                <p style="text-align: left;">
                                          <span style="text-align: center; display: inline-block;" placement="top"
                                                ngbTooltip="Live viewers" class="fa fa-user-md-chat"
                                                aria-hidden="true"></span>
                                  {{webinar.webinar_session.nb_viewers}}
                                </p>
                              </div>
                            </div>
                            <div class="webinars-speakers">
                              <div class="webinars-speakers-item"
                                   *ngFor="let user of webinar.speakers | mapToIterable">
                                <a [routerLink]="['/profile', user.slug]">
                                  <div class="rounded-picture"
                                       [style.background]="'url('+user.picture_url+')'"
                                       [style.background-size]="'cover'"></div>
                                </a>
                              </div>
                            </div>
                            <div class="webinars-button">
                              <a [routerLink]="['/course', webinar.id, webinar.slug]"
                                 class="button button-violet-outline">{{user &&
                              user.premium_membership ?
                                ('academy.Watch_it_now'|translate) :
                                ('webinar.Previous_webinars_cta'|translate)}}</a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-template>
                  </ngb-tab>
                  <ngb-tab [title]="'academy.Replay_viewers'|translate">
                    <ng-template ngbTabContent>
                      <ng-container *ngIf="webinarsByReplayViewers">
                        <div class="webinars">
                          <div class="webinars-item"
                               *ngFor="let webinar of webinarsByReplayViewers">
                            <h2 class="webinars-item-title">
                              <a
                                [routerLink]="['/course', webinar.id, webinar.slug]">{{translationService.getTranslatedAttribute('title',
                                webinar.translations, locale)}}</a>
                            </h2>
                            <span class="fa fa-user-clock" aria-hidden="true"></span>
                            {{webinar.replay_viewers}} {{'academy.replay_viewers_min'|translate}}

                            <p class="webinars-item-date">
                              {{dateMomentService.getPublicationDate(webinar.publication_date)}}</p>
                            <div class="webinars-item-report"
                                 *ngIf="webinar.webinar_session">
                              <div class="data-report"
                                   *ngIf="webinar.replay_viewers > 2">
                                <p style="text-align: left;">
                                          <span style="text-align: center; display: inline-block;" placement="top"
                                                [ngbTooltip]="'academy.Replay_viewers'|translate"
                                                class="fa fa-user-clock"
                                                aria-hidden="true"></span>
                                  {{webinar.replay_viewers}}
                                </p>
                              </div>
                            </div>
                            <div class="webinars-speakers">
                              <div class="webinars-speakers-item"
                                   *ngFor="let user of webinar.speakers | mapToIterable">
                                <a [routerLink]="['/profile', user.slug]">
                                  <div class="rounded-picture"
                                       [style.background]="'url('+user.picture_url+')'"
                                       [style.background-size]="'cover'"></div>
                                </a>
                              </div>
                            </div>
                            <div class="webinars-button">
                              <a [routerLink]="['/course', webinar.id, webinar.slug]"
                                 class="button button-violet-outline">{{user &&
                              user.premium_membership ?
                                ('academy.Watch_it_now'|translate) :
                                ('webinar.Previous_webinars_cta'|translate)}}</a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-template>
                  </ngb-tab>
                </ngb-tabset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="content">
      <div class="col-lg-12">
        <h1 class="about-title">{{ 'academy.About_community_activity_title'|translate }}</h1>
        <div class="stats-device row">
          <div class="mobile col-sm-4">
            <span class="fal fa-mobile fa-3x text-center"></span>
            <h3 class="text-center">{{devices.Mobile.value}}%</h3>
            <p class="text-center">{{ 'academy.About_mobile_title'|translate }}</p>
          </div>
          <div class="tablet col-sm-4">
            <span class="fal fa-tablet fa-3x text-center"></span>
            <h3 class="text-center">{{devices.Tablet.value}}%</h3>
            <p class="text-center">{{ 'academy.About_tablet_title'|translate }}</p>
          </div>
          <div class="computer col-sm-4">
            <span class="fal fa-tv fa-3x text-center"></span>
            <h3 class="text-center">{{devices.Desktop.value}}%</h3>
            <p class="text-center">{{ 'academy.About_desktop_title'|translate }}</p>
          </div>
        </div>
        <h2 class="about-subtitle">{{ 'academy.About_video_view_year_title'|translate }}</h2>
        <div class="barchart-com">
          <google-chart *ngIf="chartsReady" [data]="videosViewsByYear"></google-chart>
          <app-loader *ngIf="!chartsReady"></app-loader>
        </div>
        <h2 class="about-subtitle">{{ 'academy.About_alert_overview_title'|translate }}</h2>
        <div class="container">
          <div class="stats-section row">
            <div class="col-6 col-sm-6 one-stat">
              <h3>{{alertOverview.Cases.value}}</h3><br>
              <p>{{ 'academy.About_cases_title'|translate }}</p>
            </div>
            <div class="col-6 col-sm-6 one-stat">
              <h3>{{alertOverview.AverageReponsePerCase.value}}</h3><br>
              <p>{{ 'academy.About_avg_responses_per_cases_title'|translate }}</p>
            </div>
            <div class="col-6 col-sm-6 one-stat">
              <h3>{{alertOverview.Responses.value | number}}</h3><br>
              <p>{{ 'academy.About_responses_title'|translate }}</p>
            </div>
            <div class="col-6 col-sm-6 one-stat">
              <h3>{{alertOverview.NumberOfReplyingExperts.value}}</h3><br>
              <p>{{ 'academy.About_nb_replying_experts_title'|translate }}</p>
            </div>
          </div>
        </div>
        <h3 class="title-chart col-sm-12" style="text-align: center;font-weight: bold;margin-bottom: 15px;">{{
          'academy.About_top_search_themes_title'|translate }}</h3>
        <ol class="row" id="top-interest">
          <ng-container *ngFor="let theme of themes">
            <li class="col-6 col-sm-4 col-md-3">
              <a routerLink="{{ theme.linkLibrary() }}">
                <div class="interest-box">
                  <img class="interest-image" [src]="theme.picture_squared_url"
                       [alt]="translationService.getTranslatedName(theme.translations, locale)">
                  <div class="interest-title">
                    <h5>{{translationService.getTranslatedName(theme.translations, locale)}}</h5>
                  </div>
                </div>
              </a>
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </section>
</div>

import {User} from './user.model';

export class Lecture {
    id: number;
    position_in_session: number;
    translations: Array<object>;
    lecture_time: string;
    time_begin: string;
    time_end: string;
    user: User;
    abstract_content: string;
    abstract_objectives: string;
    abstract_introduction: string;
    abstract_materials: string;
    abstract_results: string;
    abstract_conclusion: string;
    abstract_content1: string;
    abstract_content2: string;
    abstract_content3: string;
    abstract_content4: string;
    abstract_content5: string;
    abstract_status: string;
    chair: boolean | number;
    disclosure1: string;
    disclosure1_details: string;
    disclosure2: string;
    disclosure2_details: string;
    disclosure3: string;
    disclosure3_details: string;
    disclosure4: string;
    disclosure4_details: string;
    disclosure5: string;
    disclosure5_sponsor: string;
    disclosure_status: string;
    other_authors: string;

    constructor(obj?: any) {
        this.id                     = obj && obj.id;
        this.position_in_session    = obj && obj.position_in_session;
        this.translations           = obj && obj.translations;
        this.lecture_time           = obj && obj.lecture_time;
        this.time_begin             = obj && obj.time_begin;
        this.time_end               = obj && obj.time_end;
        this.user                   = obj && obj.user ? new User(obj.user) : null;
        this.abstract_content       = obj && obj.abstract_content;
        this.abstract_objectives    = obj && obj.abstract_objectives;
        this.abstract_introduction  = obj && obj.abstract_introduction;
        this.abstract_materials     = obj && obj.abstract_materials;
        this.abstract_results       = obj && obj.abstract_results;
        this.abstract_conclusion    = obj && obj.abstract_conclusion;
        this.abstract_content1      = obj && obj.abstract_content1;
        this.abstract_content2      = obj && obj.abstract_content2;
        this.abstract_content3      = obj && obj.abstract_content3;
        this.abstract_content4      = obj && obj.abstract_content4;
        this.abstract_content5      = obj && obj.abstract_content5;
        this.abstract_status        = obj && obj.abstract_status;
        this.chair                  = obj && obj.chair;
        this.disclosure1            = obj && obj.disclosure1;
        this.disclosure1_details            = obj && obj.disclosure1_details;
        this.disclosure2            = obj && obj.disclosure2;
        this.disclosure2_details            = obj && obj.disclosure2_details;
        this.disclosure3            = obj && obj.disclosure3;
        this.disclosure3_details            = obj && obj.disclosure3_details;
        this.disclosure4            = obj && obj.disclosure4;
        this.disclosure4_details            = obj && obj.disclosure4_details;
        this.disclosure5            = obj && obj.disclosure5;
        this.disclosure5_sponsor    = obj && obj.disclosure5_sponsor;
        this.disclosure_status      = obj && obj.disclosure_status;
        this.other_authors          = obj && obj.other_authors;
    }
}

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from 'app/services/auth.service';
import {UserService} from 'app/services/user.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {environment} from 'environments/environment';
import {User} from 'app/models/user.model';
import {ConfirmationComponent} from '../modal/confirmation/confirmation.component';

@Component({
  selector: 'app-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSidebarComponent implements OnInit {
  user: User;
  environment = environment;
  percent: number;
  subscription: Subscription;
  activeMenu: any;
  @Input() accountMenuOpened;

  constructor(private modalService: NgbModal,
              private authService: AuthService,
              private userService: UserService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.user = this.authService.getCurrentUser();
    this.subscription = this.userService.percentCompletion$.subscribe(
      percent => {
        this.percent = percent;
      }
    );
    this.setupMenus();
  }

  setupMenus() {
    this.route.params.subscribe(params => {
      if (!params['id']) {
        this.activeMenu = $('.navigation-link#link-' + 'courses')
        this.activeMenu.addClass('active');
      } else {
        $('.navigation-link').removeClass('active');
        this.activeMenu = $('.navigation-link#link-' + params['id']);
        this.activeMenu.addClass('active');
      }
    });
    // This is a bad fix for a display bug at first navigation action
    setTimeout(() => this.openActiveMenu(), 300);
  }

  openActiveMenu() {
    const height = this.getHeightFromChildren(this.activeMenu.parents('.navigation-submenu-wrapper'));
    this.activeMenu.parents('.navigation-submenu-wrapper').css('height', height);
  }

  openLogoutConfirmation() {
    const modalRef = this.modalService.open(ConfirmationComponent);
    modalRef.componentInstance.title = 'account.Do_you_want_to_logout';
    modalRef.result.then(result => {
      this.authService.logout();
      window.location.reload();
    }, result => {
    });
  }

  openDeleteConfirmation() {
    const modalRef = this.modalService.open(ConfirmationComponent);
    modalRef.componentInstance.title = 'account.Delete_your_account_confirm_message';
    modalRef.result.then(result => {
      this.authService.delete();
      // window.location.reload();
    }, result => {
    });
  }

  toggleSubmenu(menu) {
    // We calculate submenu height to make the CSS transition work
    const submenu = $('#' + menu + '-menu-wrapper');
    const height = this.getHeightFromChildren(submenu);

    if (submenu.css('height') === '0px') {
      // Hide all open submenus
      $('.navigation-submenu-wrapper').css('height', 0);
      submenu.css('height', height);
    } else {
      submenu.css('height', 0);
    }
  }

  toggleMobileMenu() {
    this.accountMenuOpened.opened = !this.accountMenuOpened.opened;
  }

  getHeightFromChildren(elt) {
    let height = 0;
    Array.from(elt.children()).forEach(item => {
      height += parseInt($(item).css('height'), 10);
    });
    return height;
  }
}

<div class="page course">
  <section class="section course-top page-top" *ngIf="congressProduct && congressProduct.product"
           [ngStyle]="{'background-image': 'url(' + congressProduct.product.marketplace_confirmation_media_url + ')'}">
    <div class="course-top-mask"></div>
    <div class="content">


      <h1
        class="d-none d-lg-block d-xl-block">{{ translationService.getTranslatedAttribute('marketplace_confirmation_page_title', congressProduct.product.translations, locale) }}</h1>

    </div>


  </section>

  <div class="mobile-only">
    <div class="marketplace-title text-center">
      <h1><span>{{ 'marketplace.Marketplace' | translate }}</span></h1>
    </div>
    <hr>
  </div>

  <div class="course-header-mobile" *ngIf="congressProduct && congressProduct.product">
    <div class="content">
      <div class="course-header-mobile d-lg-none d-xl-none">
        <h1>{{ translationService.getTranslatedAttribute('marketplace_confirmation_page_title', congressProduct.product.translations, locale)  }}</h1>
      </div>
    </div>
  </div>

  <section class="alert alert-success" *ngIf="justConnected">
    {{ 'academy.You_are_now_connected'|translate }}
  </section>
  <div class="course-page" *ngIf="congressProduct">
    <div class="content">
      <a [routerLink]="'/marketplace/'+congressProduct.slug" id="back-span"><span class="back-span"><i
        class="fa fa-chevron-left"></i> {{ 'marketplace.Back_product'|translate }}</span></a>
<br/>
      <h2>{{ translationService.getTranslatedAttribute('marketplace_confirmation_page_subtitle', congressProduct.product.translations, locale)  }}</h2>

      <div class="course-description-wrapper">
        <p class="course-description" [innerHTML]="translationService.getTranslatedAttribute('marketplace_confirmation_page_short_description', congressProduct.product.translations, locale)"></p>
      </div>

      <br/>

      <p [innerHTML]="terms_of_use_sentence" style="font-style:italic; font-size:12px;"></p>

      <p [innerHTML]="terms_of_use_sentence2" style="font-style:italic; font-size:12px;"></p>

      <br/>
      <p *ngIf="congressProduct" style="text-align:center;">
        <a class="button button-orange" (click)="confirmOrder()">{{ 'marketplace.Confirm_your_order'|translate }}</a>
      </p>

      <div class="row">
        <div class="course-confirmation-img-wrapper">
          <img src="{{congressProduct.product.medias[0].header}}" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page">
    <div class="container" *ngIf="payment">
        <div class="text-center">
            <i class="fas fa-check-circle"></i>
            <h1>{{ 'company_account_badges.Thank_you'|translate }}!</h1>
            <p *ngIf="payment.isRegisterSubscribePayment()"
               [innerHtml]="'cart.Your_subscription_extension_recorded' | translate"></p>
            <p *ngIf="payment.card_page_action === 'REGISTER_PAY_SUBSCRIBE'">
                {{ 'cart.An_automatic_email_has_been_sent_academy' | translate }}
            </p>
        </div>

        <table class="table table-stripped">
            <tr>
                <td> {{ 'cart.Order_date'|translate }}</td>
                <td>
                    {{ dateMomentService.getFullDateTime(payment.created_at, locale) }}
                </td>
            </tr>
            <tr>
                <td>{{ 'cart.Order_ID'|translate }}</td>
                <td> {{ payment.id}}</td>
            </tr>

            <tr>
                <td> {{ 'cart.Total'|translate }}</td>
                <td>
                    {{ getPrice() }} {{ payment.currency }}
                </td>
            </tr>

            <tr *ngIf="payment.type">
                <td> {{ 'cart.Payment_done_by' | translate }}</td>
                <td> {{ payment.type }}</td>
            </tr>


            <tr *ngIf="payment.type == 'card-online-cart' || payment.type == 'card-payment-link' || payment.type =='card-back-office'">
                <td> {{ 'cart.Bank_transaction_ID' | translate }}</td>
                <td> {{ payment.card_transaction_id }}</td>
            </tr>
            <tr *ngIf="payment.isRegisterSubscribePayment() && payment.card_sub_effect_date">
                <td> {{ 'cart.Payment_effective_on'| translate }}</td>
                <td>
                    {{ dateMomentService.getPublicationDate(payment.card_sub_effect_date) }}
                </td>
            </tr>
        </table>
        <p *ngIf="payment.isRegisterSubscribePayment()">
            {{ 'cart.Subscription_extend' | translate }}
            <b> {{ dateMomentService.getPublicationDate(payment.card_sub_effect_date) }}</b>
        </p>
        <p *ngIf="payment.isRegisterSubscribePayment()" [innerHTML]="'cart.Subscription_descr' | translate"></p>
        <div *ngIf="!payment.isRegisterSubscribePayment() && payment.card_sub_effect_date">
            <p>
                {{ 'cart.Email_academy_text1' | translate }}
            </p>
            <p>
                {{ 'cart.Email_academy_text1b' | translate }}<br>
                {{ 'cart.Email_academy_text2' | translate }} <br>
                <ng-container *ngIf="environment.domain == 'aesthetics'">
                    <span *ngIf="payment.academy_doctor">{{ 'cart.Email_academy_text3' | translate }}</span>
                    <span *ngIf="!payment.academy_doctor">{{ 'cart.Email_academy_text3b' | translate }}</span>
                </ng-container>
                <br *ngIf="environment.domain !== 'aesthetics'">
                {{ 'cart.Email_academy_text4' | translate }}
            </p>
        </div>
        <div *ngIf="payment.is_gift">
            <p>
                {{ 'cart.Thanks_gift' | translate }}
            </p>
        </div>
        <p>
            {{ 'cart.IMCAS_Team' | translate }}
        </p>
        <div class="text-right">
            <a [routerLink]="['/']" class="btn btn-primary btn-md">{{ 'cart.Go_back_to_IMCAS_academy' | translate }}</a>
        </div>
    </div>
</div>

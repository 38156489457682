import {environment} from './../../../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {AcademyBoardService} from '../../../../services/academy-board.service';
import {ThemeService} from '../../../../services/theme.service';
import {TranslationService} from '../../../../services/translation.service';
import {CountryService} from '../../../../services/country.service';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {LocaleService} from '../../../../services/locale.service';


@Component({
    selector: 'app-scientific-board',
    templateUrl: './scientific-board.component.html',
    styleUrls: ['./scientific-board.component.scss'],
    providers: [AcademyBoardService, ThemeService, UserService, CountryService]

})
export class ScientificBoardComponent implements OnInit {
    public doctors_list: Array<any>;
    public copy_list: Array<any>;
    public specialities_list: Array<any>;
    public countries_list: Array<any>;
    public locale: string;
    public theme_id: number;
    public country_id: number;
    public sort_alphabetic: boolean;
    public grouped: Array<any>;
    public error: string;
    public success: boolean;
    public state = 'hide';

    environment = environment;


    constructor(
        private activatedRoute: ActivatedRoute,
        private academyBoardService: AcademyBoardService,
        private themeService: ThemeService,
        private userService: UserService,
        public translationService: TranslationService,
        public localeService: LocaleService) {
        this.sort_alphabetic = false;
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.copy_list = null;
        this.getDoctors();
        this.error = '';
        this.success = false;
    }

    getAvailableSpecialities() {
        if (!this.specialities_list) {
            const specialities = {};
            const trans = this.translationService.getTranslatedName;
            this.specialities_list = [];
            for (const d of this.doctors_list) {
                if (d.themes) {
                    d.themes.forEach((t, i) => {
                        specialities[t.id] = t;
                    });
                }
            }
            for (const key of Object.keys(specialities)) {
                this.specialities_list.push(specialities[key]);
            }
            this.specialities_list = this.specialities_list.sort((a, b) => {
                if (trans(a.translations, this.locale) < trans(b.translations, this.locale)) {
                    return -1;
                } else if (trans(a.translations, this.locale) > trans(b.translations, this.locale)) {
                    return 1;
                } else {
                    return 0;
                }
            })
        }
    }

    getAvailableCountries() {
        if (!this.countries_list) {
            const countries = {};
            this.countries_list = [];
            const trans = this.translationService.getTranslatedName;
            for (const d of this.doctors_list) {
                if (d.user.country) {
                    countries[d.user.country.id] = d.user.country;
                }
            }
            for (const key of Object.keys(countries)) {
                this.countries_list.push(countries[key]);
            }
            this.countries_list = this.countries_list.sort((a, b) => {
                if (trans(a.translations, this.locale) < trans(b.translations, this.locale)) {
                    return -1;
                } else if (trans(a.translations, this.locale) > trans(b.translations, this.locale)) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
    }

    groupByTheme(): void {
        const groups = [];
        const trans = this.translationService.getTranslatedName;
        let theme = null;
        let doctor;
        const that = this;
        this.grouped = [];
        this.specialities_list.forEach((t, i) => {
            const name = trans(t.translations, that.locale);
            if (!that.theme_id || (that.theme_id && that.theme_id == t.id)) {
                groups[t.id] = {name: name, doctors: []}
            }
        });
        while (doctor = this.doctors_list.pop()) {
            while (theme = doctor.themes.pop()) {
                if (groups[theme.id]) {
                    groups[theme.id].doctors[doctor.id] = doctor;
                }
            }
        }
        groups.map(v => {
            v.doctors = v.doctors.filter(d => d)
                .sort((a, b) => {
                    if (a.user.last_name < b.user.last_name) {
                        return -1;
                    } else if (a.user.last_name > b.user.last_name) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            return v.doctors.length > 0 ? this.grouped.push(v) : false;
        });
        this.grouped.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            }
        });
    }

    groupByAlphabeticOrder(): void {
        this.grouped = [];
        const groups = [];
        this.doctors_list.forEach((d, i) => {
            const group_name = d.user.last_name[0];
            const group_key = group_name.charCodeAt(0);
            if (!groups[group_key]) {
                groups[group_key] = {name: group_name, doctors: []}
            }
            groups[group_key].doctors.push(d);
        });
        groups.map(v => {
            return v.doctors.length > 0 ? this.grouped.push(v) : false;
        });
    }

    sortByAlphabeticOrder(): void {
        this.sort_alphabetic = !this.sort_alphabetic;
        if (!this.sort_alphabetic) {
            this.theme_id = null;
            this.country_id = null;
            this.countries_list = null;
            this.specialities_list = null;
        }
        this.getDoctors();
    }

    getDoctors(): any {
        let order_by = null;
        if (!this.sort_alphabetic) {
            order_by = 'themes'
        }
        this.academyBoardService.get(this.theme_id, this.country_id, false, true, true, true, order_by)
            .subscribe(r => {
                this.doctors_list = r;
                delete this.grouped;
                this.grouped = [];
                this.getAvailableSpecialities();
                this.getAvailableCountries();
                if (this.sort_alphabetic) {
                    this.groupByAlphabeticOrder();
                } else {
                    this.groupByTheme();
                }
            });
    }

    filterByThemeId(value): void {
        this.theme_id = value;
        this.country_id = null;
        this.countries_list = null;
        this.getDoctors();
    }

    filterByCountryId(value): void {
        this.country_id = value;
        this.getDoctors();
    }

    addToFriend(user) {
        this.userService.connect(user.id).subscribe(success => {
            this.error = '';
            this.success = true;
        }, reponse => {
            this.success = false;
            this.error = reponse.error.message;
        });
    }
}

export class PaymentPrice {
  currency: string;
  price: number;
  grand_total: number;
  price_subscription: number;

  constructor(obj?: any) {
    this.currency = obj && obj.currency;
    this.price = obj && obj.price;
    this.grand_total = obj && obj.grand_total;
    this.price_subscription = obj && obj.price_subscription;
  }
}

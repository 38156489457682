import {environment} from './../../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../services/alert.service';
import {TranslationService} from '../../../services/translation.service';
import {LocaleService} from '../../../services/locale.service';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/user.model';


@Component({
  selector: 'app-alert-join-group',
  templateUrl: './alert-join-group.component.html',
  styleUrls: ['./alert-join-group.component.scss'],
  providers: [AlertService]
})
export class AlertJoinGroupComponent implements OnInit {

  public locale: string;
  public slug: string;
  public groupId: number;
  public group: any;
  public acceptedTerms: boolean = false;
  user: User;


  environment = environment;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localeService: LocaleService,
    public alertService: AlertService,
    public transService: TranslationService,
    private authService: AuthService) { }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    this.activatedRoute.params.subscribe(params => {
      this.slug = params['group'];
      this.groupId = params['groupId'];
    });
    this.alertService.getGroup(this.slug, {coordinators: 1, user_country: 1}).subscribe(r => {
      this.group = r;
    });
    this.user = this.authService.getCurrentUser();
  }

  joinGroup() {
    if (this.acceptedTerms) {
        this.alertService.acceptTerms(this.groupId, this.acceptedTerms)
            .subscribe( r => {
                this.router.navigate(['/', 'alert', this.slug]);
            });
    }
  }

  canValidate() {
    if (!this.acceptedTerms || this.isPhysicianNotVerified()) {
      return false;
    }
    return true;
  }

  isPhysicianNotVerified() {

    if (this.user && this.user.specialty_category === 'physician') {
      return this.user.diploma_status.includes('not_verified') ? true : false;
    }
    return false;
  }

  updateStatus(status) {
    this.user.diploma_status = status;

  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import { environment } from 'environments/environment';
import {LocaleService} from "../../../../services/locale.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {FormBuilder, Validators} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {Device} from "@capacitor/device";
import {BranchService} from "../../../../services/branch.service";
import {LinkService} from "../../../../services/link.service";

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.scss']
})
export class MobileLoginComponent implements OnInit {
  loginError: string;
  locale: string;
  loginData = {
    username: '',
    password: '',
    platform: '',
    rememberMeChecked: true
  };
  loading = false;
  formValidated = false;

  @ViewChild('password', {static: false}) passwordRef;
  token: string;
  environment = environment;

  platform = "";

  loginForm: any;

  constructor(
    private localeService: LocaleService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private branchService: BranchService,
    private formBuilder: FormBuilder,
    public linkService:LinkService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    });

    this.loginForm.valueChanges.pipe(debounceTime(500)).subscribe((changes) => {
      let passVal = this.passwordRef.nativeElement.value;
      if (passVal != changes.password){
        this.loginForm.get('password').setValue(passVal);
      }
    })

  };

  ngOnInit() {


    // reset login status
    this.authService.logoutWithoutRedirect();
    this.locale = this.localeService.currentLocale();


    setTimeout(() => {
      $('.line-input input').focus(function() {
        $(this).closest('.line-input').find('.floating-label').addClass('selected');
      });

      $('.line-input input').focusout(function() {
        if ($(this).val() == '') {
          $(this).closest('.line-input').find('.floating-label').removeClass('selected');
        }
      });

    })

    this.route.params.subscribe(params => {
      this.token = params['token'];
      if (this.token) {
        setTimeout(() => {
          this.authService.loginAs(this.token).subscribe(logged => {
            this.authService.setSession(logged);
            this.router.navigate(['/dashboard']);
          });
          this.authService.loginMainWebsiteToken(this.token).subscribe(() => {});
        }, 2000);
      }
    });
    Device.getInfo().then(deviceInfo => this.platform = deviceInfo.platform);
  }

  login() {
    this.formValidated = true;

    if (this.loginForm.valid){
      this.loading = true;
      this.loginData.username = this.loginForm.value.username;
      this.loginData.password = this.loginForm.value.password;

      this.loginData.platform = this.platform;
      this.authService.login(this.loginData).subscribe(
        logged => {
          this.authService.setSession(logged);
          this.loading = false;
          const user = this.authService.getCurrentUser();
          if (!user.themes || user.themes.length === 0) {


            this.router.navigateByUrl('/account/create/step-2');
          } else {
            this.branchService.navigateToDashboardOrBranchUrl();
          }
        },
        logErr => {
          this.loginError = logErr.error.message;
          this.loading = false;
        });

    }
  }
}

import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import bugsnag from '@bugsnag/js';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeZh from '@angular/common/locales/zh';
import localeEs from '@angular/common/locales/es';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExtraOptions, RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextMaskModule} from 'angular2-text-mask';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {Routes} from './routes';
import {environment} from '../environments/environment';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {RootComponent} from './components/root/root.component';
import {HomeComponent} from './components/pages/home/home.component';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {LoginComponent} from './components/pages/login/login.component';
import {SignUpComponent} from './components/pages/sign-up/sign-up.component';
import {JoinComponent} from './components/pages/join/join.component';
import {PremiumComponent} from './components/pages/premium/premium.component';
import {DropdownSelectComponent} from './components/dropdown-select/dropdown-select.component';
import {DropdownLocaleComponent} from './components/dropdown-locale/dropdown-locale.component';
import {AuthService} from './services/auth.service';
import {AuthGuardService} from './services/auth-guard.service';
import {GuestGuardService} from './services/guest-guard.service';
import {EqualValidator} from './validators/directives/validate-equal.directive';
import {ScientificBoardComponent} from './components/pages/explore/scientific-board/scientific-board.component';
import {LocaleComponent} from './components/locale/locale.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {LibraryComponent} from './components/pages/library/library.component';
import {WebinarsComponent} from './components/pages/webinars/webinars.component';
import {AlertComponent} from './components/pages/alert/alert.component';
import {PartnersComponent} from './components/pages/explore/partners/partners.component';
import {WorldwideEventsComponent} from './components/pages/explore/worldwide-events/worldwide-events.component';
import {
  HumanitarianMissionsComponent
} from './components/pages/explore/humanitarian-missions/humanitarian-missions.component';
import {FundingComponent} from './components/pages/funding/funding.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {InboxComponent} from './components/pages/inbox/inbox.component';
import {AccountComponent} from './components/pages/account/account.component';
import {AboutComponent} from './components/pages/about/about.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {TermsOfUseComponent} from './components/pages/terms-of-use/terms-of-use.component';
import {LearnSocietiesComponent} from './components/pages/learn-societies/learn-societies.component';
import {PublicationsComponent} from './components/pages/publications/publications.component';
import {KeysPipe} from './pipes/keys.pipe';
import {MapToIterablePipe} from './pipes/map-to-iterable.pipe';
import {SearchUiComponent} from './components/search-ui/search-ui.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {CourseComponent} from './components/pages/course/course.component';
import {LecturePageComponent} from './components/pages/lecture/lecture.component';
import {ModalComponent} from './components/modal/modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {JwplayerVideoComponent} from './components/jwplayer-video/jwplayer-video.component';
import {SlideshowComponent} from './components/slideshow/slideshow.component';
import {HeaderImcasComponent} from './components/headers/header-imcas/header-imcas.component';
import {HeaderAopComponent} from './components/headers/header-aop/header-aop.component';
import {LocaleService} from './services/locale.service';
import {FeedbackComponent} from './components/feedback/feedback.component';
import {PasswordReminderComponent} from './components/pages/password-reminder/password-reminder.component';
import {SignUpStep2Component} from './components/pages/sign-up-step2/sign-up-step2.component';
import {SignUpThanksComponent} from './components/pages/sign-up-thanks/sign-up-thanks.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NotificationsComponent} from './components/headers/notifications/notifications.component';
import {CorporateFundingModalComponent} from 'app/components/corporate-funding-modal/corporate-funding-modal.component';
import {RateModalComponent} from 'app/components/rate-modal/rate-modal.component';
import {UnauthorizedInterceptor} from './interceptors/unauthorized.interceptor';
import {HeaderInterceptor} from './interceptors/header.interceptor';
import {SerializingInterceptor} from './interceptors/serializing.interceptor';
import {WebinarComponent} from 'app/components/pages/webinar/webinar.component';
import {DateMomentService} from 'app/services/date-moment.service';
import {PusherChatComponent} from 'app/components/pusher-chat/pusher-chat.component';
import {PusherService} from 'app/services/pusher.service';
import {AlertListComponent} from 'app/components/pages/alert-list/alert-list.component';
import {AlertSubmitComponent} from 'app/components/pages/alert-submit/alert-submit.component';
import {AlertTopicComponent} from 'app/components/pages/alert-topic/alert-topic.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {AccountCoursesComponent} from 'app/components/pages/account/courses/courses.component';
import {AccountFavoritesComponent} from 'app/components/pages/account/favorites/favorites.component';
import {AccountConnectionsComponent} from 'app/components/pages/account/connections/connections.component';
import {ProfileDataComponent} from 'app/components/pages/account/data/data.component';
import {ProfilePictureCoverComponent} from 'app/components/pages/account/picture-cover/picture-cover.component';
import {ProfilePublicationsComponent} from 'app/components/pages/account/publications/publications.component';
import {ProfileSocietiesComponent} from 'app/components/pages/account/societies/societies.component';
import {ProfileMissionsComponent} from 'app/components/pages/account/missions/missions.component';
import {ProfileMainInterestsComponent} from 'app/components/pages/account/main-interests/main-interests.component';
import {ContributionsVideosComponent} from 'app/components/pages/account/videos/videos.component';
import {
  ContributionsVideosApprovalComponent
} from 'app/components/pages/account/videos-approval/videos-approval.component';
import {EmitterService} from 'app/services/emitter.service';
import {ContributionsArticlesComponent} from 'app/components/pages/account/articles/articles.component';
import {SettingsPasswordComponent} from 'app/components/pages/account/password/password.component';
import {NotificationsListComponent} from 'app/components/pages/account/notifications-list/notifications-list.component';
import {
  NotificationsSettingsComponent
} from 'app/components/pages/account/notifications-settings/notifications-settings.component';
import {SettingsInvoicesComponent} from 'app/components/pages/account/invoices/invoices.component';
import {ImageCropperModule} from 'ngx-img-cropper';
import {HeaderProfileComponent} from './components/headers/header-profile/header-profile.component';
import {BlogArticleComponent} from 'app/components/pages/blog-article/blog-article.component';
import {EditAvatarModalComponent} from './components/edit-avatar-modal/edit-avatar-modal.component';
import {EditCoverModalComponent} from './components/edit-cover-modal/edit-cover-modal.component';
import {MagazineSectionComponent} from 'app/components/magazine-section/magazine-section.component';
import {CourseElementComponent} from './components/course/course.component';
import {LectureComponent} from './components/lecture/lecture.component';
import {MainInterestsComponent} from 'app/components/main-interests/main-interests.component';
import {AlertJoinGroupComponent} from './components/pages/alert-join-group/alert-join-group.component';
import {UserThemesGuardService} from 'app/services/user-themes-guard.service';
import {TermsOfUseContributorComponent} from './components/pages/terms-of-use/contributor/contributor.component';
import {FacebookModule} from 'ngx-facebook';
import {AlertLastCasesComponent} from './components/alert-last-cases/alert-last-cases.component';
import {BlogPostItemComponent} from './components/blog-post-item/blog-post-item.component';
import {AbstractComponent} from './components/modal/abstract/abstract.component';
import {ScrollTopComponent} from 'app/components/scroll-top/scroll-top.component';
import {UserService} from './services/user.service';
import {CookieService} from 'ngx-cookie-service';
import {
  EndingMembershipModalComponent
} from './components/modal/ending-membership-modal/ending-membership-modal.component';
import {GetUserService} from './services/get-user.service';
import {CommentAreaComponent} from './components/comment-area/comment-area.component';
import {LoadingComponent} from './components/loading/loading.component';
import {SectionTopComponent} from './components/section-top/section-top.component';
import {ClickOutsideDirective} from './directives/click-outside.directive';
import {AlertIntroductionComponent} from './components/pages/alert-introduction/alert-introduction.component';
import {ConnectBtnComponent} from './components/connect-btn/connect-btn.component';
import {AlertCoordinatorsComponent} from './components/alert-coordinators/alert-coordinators.component';
import {FeedbacksComponent} from './components/pages/feedbacks/feedbacks.component';
import {SafeHtmlPipe} from './pipes/safeHtml.pipe';
import {ToDateObjPipe} from './pipes/toDateObj.pipe';
import {RemoveTagsPipe} from './pipes/remove-tags.pipe';
import {LoaderComponent} from './components/loader/loader.component';
import {StartingWebinarComponent} from './components/modal/starting-webinar/starting-webinar.component';
import {CourseCompletedComponent} from './components/modal/course-completed/course-completed.component';
import {CartComponent} from './components/pages/cart/cart.component';
import {ConfirmationComponent} from './components/modal/confirmation/confirmation.component';
import {PaymentComponent} from './components/pages/cart/payment/payment.component';
import {
  AcademySubscriptionsRecapComponent
} from './components/cart/academy-subscriptions-recap/academy-subscriptions-recap.component';
import {FormSystempayComponent} from './components/cart/form-systempay/form-systempay.component';
import {ThanksComponent} from './components/pages/cart/thanks/thanks.component';
import {
  ThanksBankTransfertComponent
} from './components/pages/cart/thanks-bank-transfert/thanks-bank-transfert.component';
import {MarketplaceComponent} from './components/pages/marketplace/marketplace.component';
import {MarketProductComponent} from './components/market-product/market-product.component';
import {ProductComponent} from './components/pages/marketplace/product/product.component';
import {
  MarketplaceFreeConfirmationComponent
} from './components/pages/marketplace/marketplace-free-confirmation/marketplace-free-confirmation.component';
import {
  MarketplaceFreeThanksComponent
} from './components/pages/marketplace/marketplace-free-thanks/marketplace-free-thanks.component';
import {FaqContentComponent} from './components/faq-content/faq-content.component';
import {SocialListComponent} from './components/social-list/social-list.component';
import {ThreadService} from './services/thread.service';
import {RulesComponent} from './components/pages/alert/rules/rules.component';
import {ModalPartnerOfferComponent} from './components/modal/partner-offer/partner-offer.component';
import {PagePartnerOfferComponent} from './components/pages/partner-offer/partner-offer.component';
import {SystemCheckComponent} from './components/pages/webinars/system-check/system-check.component';
import {LandingComponent} from './components/pages/landing/landing.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TranslationService} from './services/translation.service';
import {AlertGroupComponent} from './components/pages/alert/components/alert-group/alert-group.component';
import {DownloadAppComponent} from './components/pages/download-app/download-app.component';
import {CountryFlag} from './pipes/countryFlag';
import {ProfileCompleteComponent} from './components/modal/profile-complete/profile-complete.component';
import {CovidModalComponent} from './components/covid-modal/covid-modal.component';
import {DiplomaUploadComponent} from './components/diploma-upload/diploma-upload.component';
import {SpecialtyVerifiedComponent} from './components/modal/specialty-verified/specialty-verified.component';
import {OnDemandComponent} from './components/pages/webinars/on-demand/on-demand.component';
import {WebinarPollComponent} from './components/webinar-poll/webinar-poll.component';
import {WebinarSponsorsComponent} from './components/webinar-sponsors/webinar-sponsors.component';
import {WebinarSponsorModalComponent} from './components/webinar-sponsor-modal/webinar-sponsor-modal.component';
import {PollService} from './services/poll.service';
import {RegisterWebinarComponent} from './components/modal/register-webinar/register-webinar.component';
import {AlertNoPremiumComponent} from './components/modal/alert-no-premium/alert-no-premium.component';
import {LecturePlaylistComponent} from './components/lecture-playlist/lecture-playlist.component';
import {LikeButtonComponent} from './components/like-button/like-button.component';
import {LiveMasterclassComponent} from './components/pages/live-masterclass/live-masterclass.component';
import {AccountMembershipComponent} from './components/pages/account/membership/membership.component';
import {AccountMembershipCurrentPlanComponent} from './components/pages/account/current-plan/current-plan.component';
import {AccountSidebarComponent} from './components/account-sidebar/account-sidebar.component';
import {AccountLanguagesComponent} from './components/pages/account/languages/languages.component';
import {AccountCertificationsComponent} from './components/pages/account/certifications/certifications.component';
import {ProfileBiostatementComponent} from './components/biostatement/biostatement.component';
import {LectureAbstractComponent} from './components/lecture-abstract/lecture-abstract.component';
import {LectureDisclosuresComponent} from './components/lecture-disclosures/lecture-disclosures.component';
import {AccountBadgesComponent} from './components/pages/account/badges/badges.component';
import {UserBadgeService} from './services/user-badge.service';
import {BadgeComponent} from './components/badge/badge.component';
import {SingleEventComponent} from './components/pages/explore/worldwide-events/single-event/single-event.component';
import {IsCapacitorService} from "./services/is-capacitor.service";
import {LinkService} from "./services/link.service";
import {BranchService} from "./services/branch.service";
import {MobileLoginComponent} from './components/pages/mobile-pages/mobile-login/mobile-login.component';
import {MobileMenuComponent} from "./components/mobile-components/mobile-menu/mobile-menu.component";
import {NetworkService} from "./services/mobile-services/network.service";
import {OfflineVideoService} from "./services/mobile-services/offline-video.service";
import {MobileSignUpComponent} from "./components/pages/mobile-pages/mobile-sign-up/mobile-sign-up.component";
import {CustomInnerHtmlComponent} from "./components/mobile-components/custom-inner-html/custom-inner-html.component";
import {TutorialComponent} from "./components/mobile-components/tutorial/tutorial.component";
import {MobileDashboardComponent} from "./components/pages/mobile-pages/mobile-dashboard/mobile-dashboard.component";
import {OfflineVideoElementComponent} from "./components/mobile-components/offline-video/offline-video.component";
//import {GoogleChartsModule} from "angular-google-charts";
import {RichTextEditorAllModule} from "@syncfusion/ej2-angular-richtexteditor";
import {PushNotificationsService} from "./services/mobile-services/push-notifications.service";
import {Ng2GoogleChartsModule} from "ng2-google-charts";
import {HistoryService} from "./services/mobile-services/history.service";
import {AlertCoordinatorsFormComponent} from "./components/alert-coordinators-form/alert-coordinators-form.component";
import { CardComponent } from './components/card/card.component';
import {PaginationListComponent} from "./components/pagination-list/pagination-list.component";
import {AlertCommentTemplateComponent} from "./components/alert-comment-template/alert-comment-template.component";
import {FilesService} from "./services/files.service";

const bugsnagClient = bugsnag('5c5c73dc58b18d9a0f4f663c8ebc8598');

export function errorHandlerFactory()
{
  return new BugsnagErrorHandler(bugsnagClient)
}

registerLocaleData(localeFr);
registerLocaleData(localeZh);
registerLocaleData(localeEs);
registerLocaleData(localeRu);
registerLocaleData(localeEn);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.base_url + '/trads/', '-lang.json');
}

export function getUserFactory(config: GetUserService) {
  return () => config.load().catch(err => {

  })
}

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@NgModule({
    declarations: [
        RootComponent,
        HomeComponent,
        DashboardComponent,
        LoginComponent,
        JoinComponent,
        SignUpComponent,
        SignUpStep2Component,
        SignUpThanksComponent,
        DropdownLocaleComponent,
        DropdownSelectComponent,
        NotFoundComponent,
        EqualValidator,
        LocaleComponent,
        ScientificBoardComponent,
        PremiumComponent,
        ContactComponent,
        LibraryComponent,
        WebinarsComponent,
        WebinarComponent,
        AlertComponent,
        AlertListComponent,
        AlertSubmitComponent,
        AlertTopicComponent,
        PartnersComponent,
        WorldwideEventsComponent,
        HumanitarianMissionsComponent,
        FundingComponent,
        BlogComponent,
        BlogArticleComponent,
        InboxComponent,
        AccountComponent,
        AccountCoursesComponent,
        AccountFavoritesComponent,
        SettingsPasswordComponent,
        NotificationsSettingsComponent,
        NotificationsListComponent,
        SettingsInvoicesComponent,
        ContributionsVideosComponent,
        ContributionsVideosApprovalComponent,
        ContributionsArticlesComponent,
        AccountConnectionsComponent,
        ProfileDataComponent,
        ProfilePictureCoverComponent,
        ProfilePublicationsComponent,
        ProfileSocietiesComponent,
        ProfileMissionsComponent,
        ProfileMainInterestsComponent,
        AboutComponent,
        FaqComponent,
        FaqContentComponent,
        TermsOfUseComponent,
        LearnSocietiesComponent,
        PublicationsComponent,
        KeysPipe,
        MapToIterablePipe,
        CountryFlag,
        SafeHtmlPipe,
        ToDateObjPipe,
        SearchUiComponent,
        ProfileComponent,
        CourseComponent,
        LectureComponent,
        ModalComponent,
        JwplayerVideoComponent,
        LecturePageComponent,
        ScrollTopComponent,
        MainInterestsComponent,
        PusherChatComponent,
        CorporateFundingModalComponent,
        RateModalComponent,
        SlideshowComponent,
        HeaderImcasComponent,
        HeaderAopComponent,
        FeedbackComponent,
        PasswordReminderComponent,
        NotificationsComponent,
        HeaderProfileComponent,
        EditAvatarModalComponent,
        EditCoverModalComponent,
        MagazineSectionComponent,
        CourseElementComponent,
        LectureComponent,
        AlertJoinGroupComponent,
        TermsOfUseContributorComponent,
        AlertLastCasesComponent,
        BlogPostItemComponent,
        AbstractComponent,
        EndingMembershipModalComponent,
        CommentAreaComponent,
        LoadingComponent,
        SectionTopComponent,
        ClickOutsideDirective,
        AlertIntroductionComponent,
        ConnectBtnComponent,
        AlertCoordinatorsComponent,
        FeedbacksComponent,
        LoaderComponent,
        StartingWebinarComponent,
        CourseCompletedComponent,
        CartComponent,
        ConfirmationComponent,
        PaymentComponent,
        AcademySubscriptionsRecapComponent,
        FormSystempayComponent,
        ThanksComponent,
        ThanksBankTransfertComponent,
        MarketplaceComponent,
        MarketProductComponent,
        ProductComponent,
        MarketplaceFreeConfirmationComponent,
        MarketplaceFreeThanksComponent,
        SocialListComponent,
        RulesComponent,
        ModalPartnerOfferComponent,
        PagePartnerOfferComponent,
        SystemCheckComponent,
        LandingComponent,
        PrivacyPolicyComponent,
        AlertGroupComponent,
        DownloadAppComponent,
        ProfileCompleteComponent,
        CovidModalComponent,
        DiplomaUploadComponent,
        SpecialtyVerifiedComponent,
        OnDemandComponent,
        RemoveTagsPipe,
        WebinarPollComponent,
        WebinarSponsorsComponent,
        WebinarSponsorModalComponent,
        RegisterWebinarComponent,
        AlertNoPremiumComponent,
        LecturePlaylistComponent,
        LikeButtonComponent,
        LiveMasterclassComponent,
        AccountMembershipComponent,
        AccountMembershipCurrentPlanComponent,
        AccountSidebarComponent,
        AccountLanguagesComponent,
        AccountCertificationsComponent,
        ProfileBiostatementComponent,
        LectureAbstractComponent,
        LectureDisclosuresComponent,
        AccountBadgesComponent,
        BadgeComponent,
        SingleEventComponent,
        MobileLoginComponent,
        CardComponent,
        // BannerCongressComponent,
        MobileMenuComponent,
        MobileSignUpComponent,
        CustomInnerHtmlComponent,
        TutorialComponent,
        MobileDashboardComponent,
        OfflineVideoElementComponent,
        AlertCoordinatorsFormComponent,
        PaginationListComponent,
        AlertCommentTemplateComponent
    ],
  imports: [
    FacebookModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    TextMaskModule,
    InfiniteScrollModule,
    ImageCropperModule,
    RouterModule.forRoot(Routes, routerOptions),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    NgbModule,
    /*GoogleChartsModule.forRoot('AIzaSyBdu6pXtEQ4K30qGpvT3gFUuxyYrhFDWx4'),*/
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ReactiveFormsModule,
    Ng2GoogleChartsModule,
    RichTextEditorAllModule
  ],
  exports: [RouterModule],
  entryComponents: [
    JwplayerVideoComponent,
    MainInterestsComponent,
    PusherChatComponent,
    CorporateFundingModalComponent,
    RateModalComponent,
    CourseCompletedComponent,
    StartingWebinarComponent,
    EndingMembershipModalComponent,
    ConfirmationComponent,
    ProfileCompleteComponent,
    ModalPartnerOfferComponent,
    CovidModalComponent,
    SpecialtyVerifiedComponent,
    RegisterWebinarComponent,
    AlertNoPremiumComponent,
    LectureAbstractComponent,
    LectureDisclosuresComponent,
  ],
  providers: [
    AuthGuardService,
    UserThemesGuardService,
    AuthService,
    GuestGuardService,
    KeysPipe,
    TranslatePipe,
    MapToIterablePipe,
    SafeHtmlPipe,
    ToDateObjPipe,
    LocaleService,
    DateMomentService,
    PusherService,
    EmitterService,
    TranslationService,
    UserService,
    CookieService,
    GetUserService,
    ThreadService,
    PollService,
    {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SerializingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
    {provide: ErrorHandler, useFactory: errorHandlerFactory},
    {
      provide: APP_INITIALIZER,
      useFactory: getUserFactory,
      multi: true,
      deps: [GetUserService]
    },
    DatePipe,
    UserBadgeService,
    IsCapacitorService,
    LinkService,
    BranchService,
    NetworkService,
    OfflineVideoService,
    PushNotificationsService,
    HistoryService,
    FilesService
  ],
  bootstrap: [RootComponent]
})
export class AppModule {
  constructor() {

  }
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {LocaleService} from '../../../services/locale.service';
import {WebpageService} from '../../../services/webpage.service';
import {TranslationService} from '../../../services/translation.service';
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
    providers: [WebpageService],
    encapsulation: ViewEncapsulation.None,
})
export class TermsOfUseComponent implements OnInit {
    env = environment;
    locale: string;
    groups: Array<string>;
    parts: Array<any>;
    version: number;

    constructor(
        private webpageService: WebpageService,
        public translationService: TranslationService,
        private route: ActivatedRoute,
        private localeService: LocaleService
    ) {
    }

    ngOnInit() {
        this.groups = [];
        this.locale = this.localeService.currentLocale();
        this.webpageService.getWebpage('terms_of_use').subscribe(response => {
            this.parts = Object.keys(response.parts).map(personNamedIndex => {
                this.groups.push(personNamedIndex);
                return response.parts[personNamedIndex];
            });
            this.version = response.version;
        });
    }

}

import {Injectable} from '@angular/core';

@Injectable()

export class TranslationService {

    constructor() {
    }

    getTranslatedName(names, locale) {
        let name = '';
        if (typeof names === 'undefined') {
            return 'n/a';
        }
        names.forEach(function (element) {
            if (element.locale === locale) {
                name = element.name;
            }
        });
        if (!name) {
            names.forEach(function (element) {
                if (element.locale === 'en') {
                    name = element.name;
                }
            });
        }
        return name === '' ? 'n/a' : name;
    }

    getTranslatedElement(element, locale): any {
        let object = '';
        if (typeof element === 'undefined') {
            return 'n/a';
        }
        element.forEach(function (element1) {
            if (element1.locale === locale) {
                object = element1;
            } else {
                element.forEach(function (element2) {
                    if (element2[locale]) {
                        object = element2;
                    }
                });
            }
        });
        if (object) {
            return object;
        } else {
            element.forEach(function (element1) {
                if (element1.locale === 'en') {
                    object = element1;
                }
            });
            return object;
        }
    }

    getTranslatedArray(fromObject, locale) {
        const result = [];
        let obj = {};
        if (fromObject[locale]) {
            obj = fromObject[locale];
        } else {
            obj = fromObject['en'];
        }
        if (obj) {
            Object.keys(obj).forEach(key => {
                result.push({id: key, name: obj[key]});
            });
        }
        return result;
    }

    getTranslatedTitle(titles, locale) {
        if (!titles) {
            return 'n/a';
        } else if (titles[locale]) {
            return titles[locale];
        } else if (titles['en']) {
            return titles['en'];
        } else if (titles['fr']) {
            return titles['fr'];
        } else {
            return 'n/a';
        }
    }

    getTranslatedAttribute(attribute, names, locale) {
        let result = '';
        if (typeof names === 'undefined' || !names) {
            return 'n/a';
        }
        names.forEach(function (element) {
            if (element.locale === locale) {
                result = element[attribute];
            }
        });
        if (!result) {
            names.forEach(function (element) {
                if (element.locale === 'en') {
                    result = element[attribute];
                }
            });
        }
        return result === '' ? 'n/a' : result;
    }

    getSlugFromTranslations(attribute, translations) {
      if (translations[0] && translations[0][attribute]) {
        return translations[0][attribute].toLowerCase()
          .replace(/ /g, '-')
          .replace(/&/g, 'and')
          .replace(/[^\w-]+/g, '');
      }
    }

    getSlug(attribute) {
        return attribute.toLowerCase()
            .replace(/ /g, '-')
            .replace(/&/g, 'and')
            .replace(/[^\w-]+/g, '');
    }

    getTitleWithoutNb(attribute) {
        return attribute.replace(/[0-9]/g, '');
    }
}

<div class="page contact">
  <app-section-top [title]="'layout.Get_in_touch'" [subtitle]="'Our support team is here to help you...'"
                   [backgroundUrl]="environment.cdn_base_url  + '/assets-front/img/contact_img.jpg'"></app-section-top>
<ng-container *ngIf="!thanks">
    <div class="d-flex align-items-center justify-content-center flex-column contact-content mt-4 mx-4">
      <div class="form-introduction d-flex flex-column align-items-start" [style.max-width]="'700px'">
        <div class="d-flex align-items-center">
          <p style="margin-right: 1rem"><span class="font-weight-bold">By phone</span>:</p>
          <button class="button button-orange-outline button-large bg-white"><i class="fas fa-phone" style="margin-right: 1rem"></i><a class="phone-contact" href='tel: + phonenumber +'>+331-40-73-82-82</a></button>
        </div>
        <br>
        <p style="margin-bottom: 1rem" class="align-self-center">Or</p>
        <div class="d-flex align-items-center">
          <p><span class="font-weight-bold">By email</span>: {{'page.Contact_please_details'|translate}}</p>
        </div>

      </div>
      <div *ngIf="!loading" [className]="environment.mobile ? 'mt-4 p-4 card' : 'mt-4 w-50 p-4 card'">
        <form class="form d-flex flex-column align-items-center" [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()">
          <div class="w-100 d-flex justify-content-around"  [class.flex-column]="environment.mobile" >
            <div [style.width.%]="!environment.mobile ? 30 : 100">
              <div class="form-group">
                <label for="last_name">{{'page.Contact_lastname'|translate}}? <span class="text-danger">*</span></label>
                <input type="text" class="form-control" name="last_name" id="last_name" formControlName="last_name" required>
              </div>
              <div class="form-group">
                <label for="specialty">{{'account.Specialty'|translate}} <span class="text-danger">*</span></label>
                <select *ngIf="specialties"
                        id="specialty"
                        name="specialty"
                        class="form-control"
                        formControlName="specialty">
                  <option *ngFor="let option of specialties"
                          [ngValue]="option">
                    {{translationService.getTranslatedName(option['translations'], locale)}}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="email">{{'account.Your_email_address'|translate}} <span class="text-danger">*</span></label>
                <input type="email" class="form-control" name="email" id="email" formControlName="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
              </div>
              <div class="form-group">
                <label for="congress">{{'layout.About' | translate}} <span class="text-danger">*</span></label>
                <select *ngIf="congresses"
                        id="congress"
                        class="form-control"
                        formControlName="congress">
                  <option [value]="null">Select option</option>
                  <option *ngFor="let option of congresses"
                          [ngValue]="option">
                    {{option.congressName}}
                  </option>
                </select>
              </div>
              <div class="form-group"  *ngIf="contactForm.value.congress">
                <label for="inquiry">Your inquiry concerns <span class="text-danger">*</span></label>
                <select
                  id="inquiry"
                  name="specialty"
                  class="form-control"
                  formControlName="inquiry">
                  <option [value]="null">Select option</option>
                  <option *ngFor="let option of contactForm.value.congress.inquiries"
                          [ngValue]="option">
                    {{option.text}}
                  </option>
                </select>
              </div>
            </div>
            <div [style.width.%]="!environment.mobile ? 40 : 100">
              <div class="form-group">
                <label for="message">{{'page.Contact_textarea'|translate}} <span class="text-danger">*</span></label>
                <p class="text-secondary" style="font-size: 14px" [innerHTML]="contactForm.value.inquiry && contactForm.value.inquiry.additional_infos ? contactForm.value.inquiry.additional_infos : ''">
                </p>
                <textarea class="form-control" name="message" id="message" formControlName="message" [style.min-height]="!(filesToUpload && filesToUpload.length > 0 && filesToUpload !== 'Error') ? '120px': '90px'" required></textarea>
              </div>
              <p class="text-secondary" style="font-size: 13px; font-weight: bold;">{{'alert.accepted_format' | translate}} - {{'alert.max_files_form_contact' | translate}}</p>
              <div class="form-group form-control input-file-contact">
                <div class="d-flex flex-row align-items-center">
                  <input #INPUTFILE type="file" name="file" id="file-contact" (change)="commentImagesUpload($event)" [accept]="acceptedFormat" multiple/>
                  <label for="file-contact" style="margin:0">{{this.filesToUpload.length}} {{'academy.files' | translate}}</label>
                </div>
              </div>
              <ng-container *ngIf="filesToUpload && filesToUpload.length > 0 && filesError !== 'Error'">
                <div class="d-flex my-1">
                  <div class="d-flex flex-column align-items-start">
                  <span class="badge badge-success m-1" *ngFor="let media of filesToUpload; let index = index;">
                    {{ media }}
                    <i class="fa fa-times text-white" style="font-size: 15px" (click)="removeFromList(index)"></i>
                  </span>
                  </div>
                </div>
              </ng-container>
              <span *ngIf="filesError && filesError === 'Error'" class="badge badge-danger my-1">
                {{ ('alert.Type_of_file_error'|translate) + ' : .' + extension + ' Not supported'}}
          </span>
              <span *ngIf="filesError && filesError === 'Max'" class="badge badge-danger my-1">
            {{'alert.max_files_reached' | translate}} - {{'alert.max_files_form_contact' | translate}}
          </span>
              <span *ngIf="filesError && filesError === 'Size'" class="badge badge-danger my-1">
            {{fileName}} {{'alert.max_files_reached' | translate}} - {{'alert.max_files_size' | translate}}
          </span>
              <span *ngIf="filesError && filesError === 'back'" class="badge badge-danger my-1">
                {{'alert.Something_went_wrong' | translate}}
          </span>
            </div>
          </div>
          <div class="form-group w-50 mt-4">
            <button class="button button-orange button-full" [disabled]="contactForm.invalid || isSending"><i *ngIf="!isSending" class="fas fa-paper-plane"></i><span *ngIf="!isSending">{{'live.Send'|translate}}</span> <i *ngIf='isSending' class="fa fa-spinner fa-spin"></i> <span *ngIf="isSending">{{ 'academy.please_wait'|translate }}</span></button>
          </div>
        </form>
      </div>
      <app-loader *ngIf="loading"></app-loader>
    </div>
</ng-container>
<ng-container *ngIf="thanks">
  <div class="content">
    <div class="d-flex align-items-center flex-column justify-content-center">
      <p>{{'academy.contact_thanks' | translate}}</p>
      <div class="text-right">
        <a [routerLink]="[environment.mobile ? '/dashboard' : '/']" class="btn btn-primary btn-md">{{ 'cart.Go_back_to_IMCAS_academy' | translate }}</a>
      </div>
    </div>
  </div>
</ng-container>
</div>

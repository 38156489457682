<div [className]="environment.mobile ? 'm-2': ''">
  <h1>{{'account.Your_invoices'|translate}}</h1>
  <div class="invoices" *ngIf="invoices.length > 0" >
    <div class="invoices-list" >
      <div class="invoices-item" *ngFor="let invoice of invoices">
        <h6 *ngIf="invoice.payment">{{dateMomentService.getPublicationDate(invoice.date)}}</h6>
        <a *ngIf="invoice.payment" [href]="invoice.payment.url">{{'academy.Download_invoice'|translate}}</a>
      </div>
    </div>
  </div>
  <div *ngIf="invoices.length === 0">
    {{ 'academy.No_invoices'|translate }}
  </div>
</div>


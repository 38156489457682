<div class="page">
	<app-section-top [user]="user" [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-home.jpg'"></app-section-top>
	<div class="content">
		<div class="row">
			<aside class="sidebar col-md-3 col-12" [ngClass]="{'sidebar-opened': accountMenuOpened.opened}">
				<div class="profile media">
					<img [src]="user.picture_url" alt="">
					<div class="media-body">
						<h5>{{ user.getFullName() }}</h5>
						<a [routerLink]="user.getProfileUrl()">{{ 'academy.View_profile'|translate}}</a>
					</div>
				</div>
				<ul class="navigation h-100">
          <app-account-sidebar [accountMenuOpened]="accountMenuOpened"></app-account-sidebar>
				</ul>
			</aside>
			<main class="container col-md-9 col-12" >
				<div *ngIf="!environment.mobile" class="sidebar-menu" (click)="accountMenuOpened.opened = !accountMenuOpened.opened">
					<i class="fa fa-bars" aria-hidden="true" *ngIf="!accountMenuOpened.opened"></i>
					<i class="fa fa-times" aria-hidden="true" *ngIf="accountMenuOpened.opened"></i>
				</div>
				<router-outlet></router-outlet>
			</main>
		</div>
	</div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';
import {LocaleService} from '../../services/locale.service';

@Component({
  selector: 'app-webinar-sponsor-modal',
  templateUrl: './webinar-sponsor-modal.component.html',
  styleUrls: ['./webinar-sponsor-modal.component.scss']
})
export class WebinarSponsorModalComponent implements OnInit {

  @Input() company: any;
  @Input() modal: any;
  environment: any = environment;
  isAop: boolean = environment.domain === 'ophthalmology';
  locale: any;

  constructor(public translationService: TranslationService, private modalService: NgbModal, private localeService: LocaleService) {
    this.locale = this.localeService.currentLocale();
  }

  ngOnInit() {
  }

}




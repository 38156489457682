import {Media} from './media.model';
import {DateMomentService} from '../services/date-moment.service';
import {environment} from '../../environments/environment';
import {User} from './user.model';
import {Keyword} from './keyword.model';

export class AcademyMedia {
  id: number;
  media_id: number;
  academy_course: any;
  comments_auth: any;
  comments: any;
  media: Media;
  sponsored: number | boolean;
  lecture: any;
  rating: number;
  granted: number | boolean;
  full_video: number | boolean;
  surgical_only: number | boolean;
  objectID: number;
  sponsor_or_grant: any;
  sponsorOrGrant: User;
  comments_count: number;
  event: any;
  user_auth: any;
  publication_date: string;
  keywords: Keyword[];
  date: any;
  free: boolean;
  type: string;
  avatar: string;
  speakers: User[];
  thumb: string;
  mediaTitles: any;
  related: any;
  private dateMomentService: DateMomentService;
  private environment = environment;
  sponsor: string;
  views: number;
  speakersArr: User[];


  constructor(obj?: any) {
    this.id = obj && obj.id;
    this.media_id = obj && obj.media_id;
    this.comments_auth = obj && obj.comments_auth;
    this.academy_course = obj && obj.academy_course ? obj.academy_course : null;
    this.comments = obj && obj.comments || [];
    this.media = obj && obj.media && !obj.objectID ? new Media(obj.media) : new Media(obj);
    this.sponsored = !!(obj && obj.sponsored);
    this.rating = obj && obj.rating;
    this.granted = !!(obj && obj.granted);
    this.sponsor_or_grant = obj && obj.sponsor_or_grant;
    this.full_video = obj && obj.full_video;
    this.surgical_only = obj && obj.surgical_only;
    this.sponsorOrGrant = obj && obj.sponsorOrGrant ? new User(obj.sponsorOrGrant) : new User();
    this.comments_count = obj && obj.comments_count;
    this.event = obj && obj.event;
    this.user_auth = obj && obj.user_auth;
    this.publication_date = obj && obj.publication_date;
    this.keywords = obj && obj.keywords;
    this.date = obj && obj.date;
    this.free = obj && obj.free;
    this.type = obj && obj.type;
    this.objectID = obj && obj.objectID;
    this.thumb = obj && obj.thumb;
    this.avatar = obj && obj.avatar;
    this.speakers = obj && obj.speakers;
    this.mediaTitles = obj && obj.mediaTitles;
    this.sponsor = obj && obj.sponsor;
    this.related = obj && obj.related || [];
    this.views = obj && obj.views;
  }

  isNew() {
    if (!this.date) {
      this.date = this.publication_date;
    }
    return this.getDateService().isNew(this.date);
  }

  getDateService() {
    if (!this.dateMomentService) {
      this.dateMomentService = new DateMomentService();
    }
    return this.dateMomentService
  }

  getPublishedDate() {
    const date = this.getDateService().getUnixDate(this.date);
    if (window.location.pathname.slice(1, 3) === 'fr') {
      return date.format('Do MMMM YYYY');
    }
    return date.format('MMMM Do, YYYY');
  }

  isFree() {
    return this.free ? this.free : this.media.free;
  }

  isWebinar() {
    return this.type === 'webinar';
  }

  isSponsored() {
    return this.sponsored;
  }

  getMedia() {
    if (!this.media) {
      this.media = new Media(this); // algolia
    }
    this.media.academy_media_id = this.id;
    return this.media;
  }

  getSlug() {
    let title: string = this.getMediaTitles().en;
    if (!title) {
      title = this.getMediaTitles().fr;
    }
    if (title) {
      return title.toLowerCase()
        .replace('à', 'a')
        .replace('ç', 'c')
        .replace('é', 'e')
        .replace('è', 'e')
        .replace('ê', 'e')
        .replace('ë', 'e')
        .replace('ö', 'o')
        .replace('ô', 'o')
        .replace(/ /g, '-')
        .replace(/&/g, 'and')
        .replace(/[^\w-]+/g, '');
    }
    return 'n-a';
  }

  getId() {
    if (this.objectID) {
      this.id = this.objectID;
    }
    return this.id
  }

  getThumb(format) {
    if (format === 'rectangular' && this.getMedia().thumb) {
      return this.getMedia().thumb;
    }
    if (format === 'squared' && typeof this.media.mediaID !== 'undefined') {
      return this.environment.cdn_base_url + '/medias/thumbs_squared/' + this.media.mediaID + '.jpg';
    }
    if (!this.getMedia().thumb_squared && typeof this.media.mediaID !== 'undefined') {
      return this.environment.cdn_base_url + '/medias/thumbs/' + this.media.mediaID + '.jpg';
    }
    return this.getMedia().thumb_squared;
  }

  getAvatar() {
    if (this.avatar) {
      return this.avatar;
    }
    return this.getMedia().speakers && this.getMedia().speakers[0] ? this.getMedia().speakers[0].picture_url : false;
  }

  getFavorite(user) {
    return typeof this.getMedia().favorite === 'undefined' ? this.getMedia().isEnrolled(user) : this.getMedia().favorite;
  }

  getSpeakersArr() {
    const speakers = [];
    if (this.speakersArr) {
      return this.speakersArr;
    }
    if (this.speakers) {
      Object.keys(this.speakers).map((objectKey, index) => {
        speakers.push(new User(this.speakers[objectKey]));
      });
    } else if (this.getMedia().speakers) {
      this.getMedia().speakers.forEach(user => {
        speakers.push(new User(user));
      });
    }
    this.speakersArr = speakers;
    return this.speakersArr;
  }

  getMediaTitles() {

    if (this.mediaTitles) {
      return this.mediaTitles;
    }
    const trads = [];
    if (this.getMedia().translations) {
      this.getMedia().translations.forEach(trad => {
        trads[trad['locale']] = trad['title'];
      });
    }

    return trads;
  }

  getSponsor() {
    if (this.sponsor_or_grant) {
      return this.sponsor_or_grant;
    }
    if (this.sponsorOrGrant) {
      return this.sponsorOrGrant;
    }
    return false;
  }

  getSponsorName() {
    if (this.sponsored) {
      if (this.sponsor) {
        return this.sponsor;
      }
      return this.getSponsor().company_name;
    }
    return 'Not sponsored';
  }


  getCommentsCount() {
    return this.comments_count ? this.comments_count : this.comments;
  }

  getPublicationDate() {
    return this.getDateService().getPublicationDate(this.publication_date);
  }

  getCaptationSource() {
    if (this.academy_course && this.academy_course.captation_source) {
      return this.academy_course.captation_source;
    } else if (this.event) {
      return this.event.title;
    }
    return '';
  }
}

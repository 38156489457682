<div class="page container">
  <div class="mobile-only">
    <div class="marketplace-title text-center">
      <h1><span>{{ 'marketplace.Marketplace' | translate }}</span><i (click)="openCart()"
                                                                     class="fal fa-shopping-cart"></i></h1>
    </div>
    <hr>
  </div>
  <p><a [routerLink]="'/marketplace'"><i class="fal fa-chevron-left"></i> {{ 'marketplace.Back_products' | translate
    }}</a></p>
  <section class="alert alert-success" *ngIf="justConnected">
    {{ 'academy.You_are_now_connected'|translate }}
  </section>
  <h1 *ngIf="congressProduct && congressProduct.product" class="desktop-only">{{
    translationService.getTranslatedName(congressProduct.product.translations, locale) }}</h1>
  <div class="product-carousel academy-slider"
       *ngIf="congressProduct && congressProduct.product && congressProduct.product.medias">
    <div class="product-carousel-item" *ngFor="let media of congressProduct.product.medias;let slideIndex=index;">
      <img [src]="media.header" *ngIf="!media.cdn_file" alt="">
      <app-jwplayer-video
        *ngIf="media.cdn_file"
        [id]="slideIndex"
        [file]="media.cdn_file"
        [file2]="media.cdn_dash_file"
        [media]="media"
        [width]="'100%'"
        [aspectratio]="'16:9'"
        [image]="media.header"
        [autoload]="slideIndex === 0 ? true : false">
      </app-jwplayer-video>
    </div>
  </div>
  <h1 *ngIf="congressProduct && congressProduct.product" class="mobile-only">{{
    translationService.getTranslatedName(congressProduct.product.translations, locale) }}</h1>


  <div *ngIf="(congressProduct && (congressProduct.bought_by_auth || congressProduct.free_order_by_auth)) || licenseErrorMessage" class="mobile-only">
    <section class="alert alert-success" *ngIf="licenseMessage">
      {{ 'marketplace.License_key_message'|translate }}
    </section>
    <section class="alert alert-danger" *ngIf="licenseErrorMessage">
      {{ licenseErrorMessage }}
    </section>
    <section class="alert alert-danger" *ngIf="requestError">
      {{ requestError }}
    </section>
    <p>
      {{ 'marketplace.Already_bought'|translate }} <br>
      <a class="button button-orange" [routerLink]="'/contact'"
         *ngIf="congressProduct && congressProduct.partner">{{ 'marketplace.Contact_us_license'|translate }}</a>
    </p>
  </div>

  <div class="mobile-only" *ngIf="congressProduct && !congressProduct.marketplace_free_product && !congressProduct.bought_by_auth && !licenseErrorMessage">
    <div class="prices">
      <span
          class="price-barre">{{ currency == 'usd' ? '$' : ''}}{{ congressProduct.getOriginalPriceTtc(currency)}}{{ currency == 'eur' ? '€' : ''}}</span>
      <span
        class="price-real">{{ currency == 'usd' ? '$' : ''}}{{ congressProduct.getPriceTtc(currency)}}{{ currency == 'eur' ? '€' : ''}}</span>
      <span class="price-percent-saving">{{ 'marketplace.Save'|translate }} {{ getSaving()  }} %</span>
    </div>
    <p class="text-center">
      <a class="button button-orange" (click)="openCartAndAddProduct()">{{ 'marketplace.Add_to_cart'|translate }}</a>
    </p>
  </div>

  <div class="mobile-only" *ngIf="congressProduct && congressProduct.marketplace_free_product && !congressProduct.bought_by_auth && !licenseErrorMessage">
    <div class="prices">
      <span
          class="price-barre">{{ currency == 'usd' ? '$' : ''}}{{ congressProduct.getPriceTtc(currency)}}{{ currency == 'eur' ? '€' : ''}}</span>
    </div>
    <p class="text-center">
      <a class="button button-orange" (click)="addFreeProduct()">{{ 'marketplace.Enjoy_the_offer'|translate }}</a>
    </p>
  </div>

  <hr>
  <p *ngIf="congressProduct && congressProduct.product"
     [innerHTML]="translationService.getTranslatedAttribute('terms_and_conditions', congressProduct.product.translations, locale)"></p>


  <div class="text-right desktop-only" *ngIf="congressProduct && congressProduct.marketplace_free_product">
    <div class="prices">
      <span
        class="price-barre">{{ currency == 'usd' ? '$' : ''}}{{ congressProduct.getPriceTtc(currency)}}{{ currency == 'eur' ? '€' : ''}}</span>
    </div>
    <p *ngIf="congressProduct && !congressProduct.free_order_by_auth">
      <a class="button button-orange" (click)="addFreeProduct()">{{ 'marketplace.Enjoy_the_offer'|translate }}</a>
    </p>
    <section class="alert alert-success" *ngIf="licenseMessage">
      {{ 'marketplace.License_key_message'|translate }}
    </section>
    <section class="alert alert-danger" *ngIf="licenseErrorMessage">
      {{ licenseErrorMessage }}
    </section>
    <p *ngIf="(congressProduct && congressProduct.free_order_by_auth) || licenseErrorMessage">
      {{ 'marketplace.Already_bought'|translate }} <br>
      <a class="button button-orange" [routerLink]="'/contact'"
         *ngIf="congressProduct && congressProduct.partner">{{ 'marketplace.Contact_us_license'|translate }}</a>
    </p>
  </div>


  <div class="text-right desktop-only" *ngIf="congressProduct && !congressProduct.marketplace_free_product">
    <div class="prices">
      <span
        class="price-barre">{{ currency == 'usd' ? '$' : ''}}{{ congressProduct.getOriginalPriceTtc(currency)}}{{ currency == 'eur' ? '€' : ''}}</span>
      <span
        class="price-real">{{ currency == 'usd' ? '$' : ''}}{{ congressProduct.getPriceTtc(currency)}}{{ currency == 'eur' ? '€' : ''}}</span>
      <span class="price-percent-saving">{{ 'marketplace.Save'|translate }} {{ getSaving()  }} %</span>
    </div>
    <p *ngIf="congressProduct && !congressProduct.bought_by_auth  && !licenseErrorMessage">
      <a class="button button-orange" (click)="openCartAndAddProduct()">{{ 'marketplace.Add_to_cart'|translate }}</a>
    </p>
    <section class="alert alert-success" *ngIf="licenseMessage">
      {{ 'marketplace.License_key_message'|translate }}
    </section>
    <section class="alert alert-danger" *ngIf="licenseErrorMessage">
      {{ licenseErrorMessage }}
    </section>
    <section class="alert alert-danger" *ngIf="requestError">
      {{ requestError }}
    </section>
    <p *ngIf="(congressProduct && congressProduct.bought_by_auth) || licenseErrorMessage">
      {{ 'marketplace.Already_bought'|translate }} <br>
      <a class="button button-orange" [routerLink]="'/contact'"
         *ngIf="congressProduct && congressProduct.partner">{{ 'marketplace.Contact_us_license'|translate }}</a>
    </p>
  </div>

  <hr>
  <div class="description" *ngIf="congressProduct && congressProduct.product">
    <div class="row">
      <div class="col-md-6">
        <img [src]="congressProduct.product.marketplace_para_medias[0]" alt="">
      </div>
      <div class="col-md-6">
        <h5>{{ translationService.getTranslatedAttribute('marketplace_para_title1', congressProduct.product.translations, locale) }}</h5>
        <p
          [innerHTML]="translationService.getTranslatedAttribute('marketplace_para_text1', congressProduct.product.translations, locale)"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="blockquote"><span *ngIf="congressProduct.product.translations[0].marketplace_quote_title"
                                      class="quote-icon">“</span><span class="quote">
                {{ translationService.getTranslatedAttribute('marketplace_quote_title', congressProduct.product.translations, locale) }}
                </span>
          <div class="quote-author">
            {{ translationService.getTranslatedAttribute('marketplace_quote_subtitle', congressProduct.product.translations, locale) }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h5>{{ translationService.getTranslatedAttribute('marketplace_para_title2', congressProduct.product.translations, locale) }}</h5>
        <p
          [innerHTML]="translationService.getTranslatedAttribute('marketplace_para_text2', congressProduct.product.translations, locale)"></p>
      </div>
      <div class="col-md-6">
        <img [src]="congressProduct.product.marketplace_para_medias[1]" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5></h5>
      </div>
    </div>
    <div class="row characteristics">
      <div class="col-md-3 characteristic" *ngIf="congressProduct.product.translations[0].marketplace_chara1_title">
        <i class="fal fa-dna"></i>
        <h6>{{ translationService.getTranslatedAttribute('marketplace_chara1_title', congressProduct.product.translations, locale) }}</h6>
      </div>
      <div class="col-md-3 characteristic" *ngIf="congressProduct.product.translations[0].marketplace_chara2_title">
        <i class="fal fa-eye"></i>
        <h6>{{ translationService.getTranslatedAttribute('marketplace_chara2_title', congressProduct.product.translations, locale) }}</h6>
      </div>
      <div class="col-md-3 characteristic" *ngIf="congressProduct.product.translations[0].marketplace_chara3_title">
        <i class="fal fa-code"></i>
        <h6>{{ translationService.getTranslatedAttribute('marketplace_chara3_title', congressProduct.product.translations, locale) }}</h6>
      </div>
      <div class="col-md-3 characteristic" *ngIf="congressProduct.product.translations[0].marketplace_chara4_title">
        <i class="fal fa-heartbeat"></i>
        <h6>{{ translationService.getTranslatedAttribute('marketplace_chara4_title', congressProduct.product.translations, locale) }}</h6>
      </div>
    </div>

    <div class="row" *ngIf="congressProduct && (!congressProduct.bought_by_auth && !congressProduct.free_order_by_auth)">
      <div class="col-md-12">
        <p class="text-center">
          <a class="button button-large button-orange" (click)="addFreeProduct()"
             *ngIf="congressProduct.marketplace_free_product">{{
            'marketplace.Enjoy_the_offer'|translate }}</a>
          <a class="button button-large button-orange" (click)="openCartAndAddProduct()"
             *ngIf="!congressProduct.marketplace_free_product">{{
            'marketplace.Add_to_cart'|translate }}</a>
        </p>
      </div>
    </div>

  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {TranslationService} from 'app/services/translation.service';
import {DateMomentService} from 'app/services/date-moment.service';
import {AuthService} from '../../../../services/auth.service';


@Component({
    selector: 'profile-publications',
    templateUrl: './publications.component.html',
    styleUrls: ['./publications.component.scss'],
    providers: [
        UserService
    ]
})
export class ProfilePublicationsComponent implements OnInit {
    user: User;
    updated: boolean = false;
    loading: boolean = false;
    error: string = '';

    constructor(private userService: UserService,
                public translationService: TranslationService,
                private authService: AuthService,
                public dateMomentService: DateMomentService) {
    }

    ngOnInit() {
        this.loading = true;
        this.getUser();
    }

    getUser() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {pubmed: 1}).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                    if (this.user.pubmed_references) {
                        this.user.pubmed_references = this.user.pubmed_references.filter(p => p.visibility !== 'delete');
                    }
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.error = error.statusText + '. Code : ' + error.status + '. Please contact us to report the problem.';
                });
        }
    }

    changeVisibility(pubmed) {
        pubmed.visibility = pubmed.visibility === 'show' ? 'hide' : 'show';
        this.userService.updatePubmedVisibility(pubmed.pmid, pubmed.visibility).subscribe(r => {
            this.updated = true;
        });
    }

    remove(pubmed) {
        this.userService.updatePubmedVisibility(pubmed.pmid, 'delete').subscribe(r => {
            this.user.pubmed_references = this.user.pubmed_references.filter(p => p.pmid !== pubmed.pmid)
            this.updated = true;
        });
    }

    goToPubmed(pubmedId) {
        window.open('http://www.ncbi.nlm.nih.gov/pubmed/?term=' + pubmedId);
    }
}

<div class="page">
    <app-section-top [title]="'marketplace.Marketplace'" [subtitle]=""
                     [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-library.jpg'"></app-section-top>
    <div class="container">
        <div class="marketplace-title text-center">
            <h1><span class="mobile-only">{{ 'marketplace.Marketplace' | translate }}</span> <span class="desktop-only">{{ 'marketplace.Discover_products'|translate }}</span><i
                    (click)="openCart()" class="fal fa-shopping-cart"></i></h1>
        </div>
        <div>
            <p class="marketplace-intro">
                {{ 'marketplace.Intro_marketplace' | translate }}
            </p>
        </div>
        <hr>
        <div class="row">
            <ng-container *ngFor="let congressProduct of congressProducts; index as i">
                <div [class]="(i === 0 && congressProducts.length >= 2 ) || congressProducts.length === 2 || i === 0? 'col-md-12' : 'col-md-6'">
                    <!-- We pass colors from prop array to child, looping through this array infinitely -->
                    <app-market-product [color]="getColor(i)"
                                        [congressProduct]="congressProduct"
                                        [innerWidth]="innerWidth"
                                        [primary]="i === 0"
                                        [large]="((i === 0 && congressProducts.length >= 2) || congressProducts.length === 2 || i === 0) && innerWidth > 768"
                                        [imagePosition]="((i === 0 && congressProducts.length >= 2) || (congressProducts.length !== 2 && i === 0)) && innerWidth > 768? 'after' : 'before'"></app-market-product>
                </div>
            </ng-container>
        </div>
    </div>
</div>

import {Injectable, Injector} from '@angular/core';
import {UserService} from './user.service';

@Injectable()
export class GetUserService {

    private userService;

    public constructor(private injector: Injector) {
        // Avoid cyclid dependencies, inject manually:
        this.userService = injector.get(UserService);
    }

    public load(): Promise<any> {
        return new Promise((resolve, r) => {
            const academyApp = (<any>window).ACADEMY_APP;
            if (academyApp && academyApp.user_id && academyApp.bearer) {
                try {
                    localStorage.setItem('personal_token', academyApp.bearer);
                } catch (e) {}
                this.userService.getUser(academyApp.user_id, {user_themes: 1}).subscribe(userData => {
                    if (userData.email) {
                        try {
                            localStorage.setItem('currentUser', JSON.stringify(userData));
                        } catch (e) {}
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

}

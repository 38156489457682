import {CongressProduct} from './congress-product.model';
import {User} from './user.model';
import {AcademySubscriptionPrice} from './academy-subscription-price.model';

export class AcademySubscription {
    id: number;
    congress_product: CongressProduct;
    prices: AcademySubscriptionPrice[];
    user: User;
    quantity: number;
    subscription_data: any;

    constructor(obj?: any) {
        this.id = obj && obj.id;
        this.quantity = obj && obj.quantity;
        this.congress_product = obj && obj.congress_product ? new CongressProduct(obj.congress_product) : null;
        this.user = obj && obj.user ? new User(obj.user) : null;
        this.subscription_data = obj && obj.subscription_data;
        if (obj && obj.prices) {
            this.prices = [];
            obj.prices.forEach(element => {
                this.prices.push(new AcademySubscriptionPrice(element));
            });
        }
    }
}

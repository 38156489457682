import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MagazineService} from "../../../../services/magazine.service";
import {CourseService} from "../../../../services/course.service";
import {MediaService} from "../../../../services/media.service";
import {TranslationService} from "../../../../services/translation.service";
import {UserService} from "../../../../services/user.service";
import {ArticleService} from "../../../../services/article.service";
import {AlertService} from "../../../../services/alert.service";
import {WebinarService} from "../../../../services/webinar.service";
import {TwitterService} from "../../../../services/twitter.service";
import {ThemeService} from "../../../../services/theme.service";
import {Magazine} from "../../../../models/magazine.model";
import {Course} from "../../../../models/course.model";
import {AcademyMedia} from "../../../../models/academy-media.model";
import {Webinar} from "../../../../models/webinar.model";
import {ArticleModel} from "../../../../models/article.model";
import {User} from "../../../../models/user.model";
import {AuthService} from "../../../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DateMomentService} from "../../../../services/date-moment.service";
import {TranslateService} from "@ngx-translate/core";
import {LocaleService} from "../../../../services/locale.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {ThreadService} from "../../../../services/thread.service";
import {IsCapacitorService} from "../../../../services/is-capacitor.service";
import { environment } from 'environments/environment';
import {EndingMembershipModalComponent} from "../../../modal/ending-membership-modal/ending-membership-modal.component";
import {Theme} from "../../../../models/theme.model";

@Component({
  selector: 'app-mobile-dashboard',
  templateUrl: './mobile-dashboard.component.html',
  styleUrls: ['./mobile-dashboard.component.scss'],
  providers: [
    MagazineService,
    CourseService,
    MediaService,
    TranslationService,
    UserService,
    ArticleService,
    AlertService,
    WebinarService,
    TwitterService,
    ThemeService,
  ]
})
export class MobileDashboardComponent implements OnInit {

  magazine: Magazine;
  courses: Array < Course > = [] ;
  coursesCache: Array < Course > = [] ;
  academyMedias: Array < AcademyMedia > = [];
  locale: any;
  cases: any = [];
  upcomingWebinars: Webinar[] = [];
  upcomingWebinarsCache: Webinar[] = [];
  articles: ArticleModel[];

  themesSlides: Array < any > ;

  user: User;
  environment: any;
  nbAcademyMedias: number;

  dataReceivedFromWeb = [];

  gradientValues;

  constructor(
    private magazineService: MagazineService,
    private courseService: CourseService,
    private authService: AuthService,
    private themeService: ThemeService,
    private activatedRoute: ActivatedRoute,
    private mediaService: MediaService,
    private router: Router,
    private dateMomentService: DateMomentService,
    private webinarService: WebinarService,
    private articleService: ArticleService,
    private twitterService: TwitterService,
    private alertService: AlertService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    protected localStorage: LocalStorage,
    public translationService: TranslationService,
    private threadService: ThreadService,
    public isCapacitorService: IsCapacitorService,
    private cd: ChangeDetectorRef
  ) {
    this.locale = localeService.currentLocale();
    this.environment = environment;
  }

  ngOnInit() {
    if (environment.domain != 'ophthalmology') {
      this.gradientValues = 'linear-gradient(0deg, rgba(30,0,68,0.6) 0%, rgba(30,0,68,0.6) 100%)';
    } else {
      this.gradientValues = 'linear-gradient(0deg, rgba(33,30,70,0.74) 0%, rgba(93,129,183,0.74) 100%)';
    }


    this.user = this.authService.getCurrentUser();
    this.userService.getUser(this.user.id, {
      courses_in_progress: 1,
      courses_finished: 1,
      media_viewed: 1,
      medias_favorited: 1,
      courses_favorited: 1,
      only_media_ids: 1,
      only_course_ids: 1,
      user_country: 1,
      user_specialty: 1
    }).subscribe(
      user => {
        this.user = user;
        const currentUser = this.authService.getCurrentUser();
        if (currentUser.id === this.user.id) {
          this.userService.getUserMembershipStatus(this.user.id).subscribe(
            data => {
              if (data.ending_date) {
                const days = this.dateMomentService.getDifference(data.ending_date, this.dateMomentService.getNow());
                if (days < 14 && days > 0 && !data.recurrence) {
                  const modalRef = this.modalService.open(EndingMembershipModalComponent);
                  modalRef.componentInstance.membershipInfo = data;
                  modalRef.result.then(
                    () => {},
                    () => {}
                  );
                }
              }
            }
          );
        }
      }
    );


    // COURSES - CACHE DONE

    this.localStorage.getItem < any > ('DASHBOARD-courses_' + environment.domain).subscribe((data) => {
      if (!("courses" in this.dataReceivedFromWeb) && data) {
        this.setDataGetCourses(data, false);
      }
    });

    this.courseService.getCourses({
      user_id: this.authService.getCurrentUser().id,
      per_page: 6,
      course_speakers: 1
    }).subscribe(courses => {
      this.setDataGetCourses(courses, true);

      this.dataReceivedFromWeb["courses"] = true;
      this.localStorage.setItem('DASHBOARD-courses_' + environment.domain, courses).subscribe(() => {});
    });

    // END COURSES

    // LECTURES - CACHE DONE

    this.localStorage.getItem < any > ('DASHBOARD-lectures_' + environment.domain).subscribe((data) => {
      if (!("lectures" in this.dataReceivedFromWeb) && data) {
        this.setDataGetAcademyMedias(data);
      }
    });

    this.mediaService.getAcademyMedias({
      per_page: 3,
      academy_media_event: 0,
      academy_media_course: 0,
      media_cdn: 0,
      media_lecture: 0,
      media_speakers: 1,
      media_library: 1,
      lecture_user: 0,
      order_by: 'publication_date',
      order: 'desc'
    }).subscribe(data => {
      this.setDataGetAcademyMedias(data);

      this.dataReceivedFromWeb["lectures"] = true;
      this.localStorage.setItem('DASHBOARD-lectures_' + environment.domain, data).subscribe(() => {});
    });

    // END LECTURES

    // WEBINARS - CACHE DONE

    this.localStorage.getItem < any > ('DASHBOARD-webinars_' + environment.domain).subscribe((data) => {
      if (!("webinars" in this.dataReceivedFromWeb) && data) {
        this.setDataGetUpcomingWebinars(data, false);
      }
    });

    this.webinarService.getUpcomingWebinars(this.dateMomentService.getNow(), {
      per_page: 2,
      webinar_picture_url: 1
    }).subscribe(data => {
      this.setDataGetUpcomingWebinars(data, true);

      this.dataReceivedFromWeb["webinars"] = true;
      this.localStorage.setItem('DASHBOARD-webinars_' + environment.domain, data).subscribe(() => {});
    });

    // END WEBINARS

    // ALERT - CACHE DONE

    this.localStorage.getItem < any > ('DASHBOARD-cases_' + environment.domain).subscribe((cases) => {
      if (!("cases" in this.dataReceivedFromWeb) && cases) {
        this.setDataGetCases(cases);
      }
    });

    this.alertService.getCases(false, false, false, false, false, 3).subscribe(cases => {
      this.setDataGetCases(cases);

      this.dataReceivedFromWeb["cases"] = true;
      this.localStorage.setItem('DASHBOARD-cases_' + environment.domain, cases).subscribe(() => {});
    });

    // END ALERT

    // ARTICLES - CACHE DONE

    this.localStorage.getItem < any > ('DASHBOARD-articles_' + environment.domain).subscribe((articles) => {
      if (!("articles" in this.dataReceivedFromWeb) && articles) {
        this.setDataGetArticles(articles);
      }
    });

    this.articleService.getArticles([], {
      per_page: '2',
      academy_blog: '1',
      public: '1',
      status: 'accepted'
    }).subscribe(articles => {
      this.setDataGetArticles(articles);

      this.dataReceivedFromWeb["articles"] = true;
      this.localStorage.setItem('DASHBOARD-articles_' + environment.domain, articles).subscribe(() => {});
    });

    // END ARTICLES

    // THEMES - CACHE DONE

    this.localStorage.getItem < any > ('DASHBOARD-themes_' + environment.domain).subscribe((data) => {
      if (!("themes" in this.dataReceivedFromWeb) && data) {
        this.setDataGetThemes(data);
      }
    });

    this.themeService.getThemesForUser(this.user.id).subscribe(data => {
      this.setDataGetThemes(data);

      this.dataReceivedFromWeb["themes"] = true;
      this.localStorage.setItem('DASHBOARD-themes_' + environment.domain, data).subscribe(() => {});
    })

    // END THEMES

    this.threadService.getMessagesUnreadCount();
    localStorage.removeItem('librarySearchQParams');

  }


  setDataGetCourses(courses, fromNetwork) {

    let jqueryCourses, dots, prev, next;
    if (fromNetwork){
      jqueryCourses = $('.courses-slider');
      dots = $('.courses-dots');
      prev = $('#courses-prev');
      next = $('#courses-next');

      jqueryCourses.on('init', () => {
        this.coursesCache = [];
      })
    }
    else {
      jqueryCourses = $('.courses-slider-cache');
      dots = $('.courses-dots-cache');
      prev = $('#courses-prev-cache');
      next = $('#courses-next-cache');
    }

    courses['data'].forEach(n => {
      const courseObj: Course = new Course(n);
      if (fromNetwork){
        this.courses.push(courseObj);
      }
      else {
        this.coursesCache.push(courseObj);
      }

    });

    setTimeout(() => {
      jqueryCourses.slick({
        dots: true,
        appendDots: dots,
        prevArrow: prev,
        nextArrow: next,
        mobileFirst: true
      });

      let scope = this;

      jqueryCourses.on('touchstart', '.course', function (e) {

        var course = $(this);

        $(e.target).on('touchend', function (e) {
          $(e.target).off('touchend');

          scope.router.navigateByUrl(course.attr("id"));
        });

        $(e.target).on('touchmove', function (e) {
          $(e.target).off('touchmove');
          $(e.target).off('touchend');
        });
      })
    })


  }

  setDataGetAcademyMedias(data) {
    this.academyMedias = [];
    this.nbAcademyMedias = Math.floor(data.total / 10) * 10;
    data['data'].forEach(n => {
      const academyMediaObj: AcademyMedia = new AcademyMedia(n);
      this.academyMedias.push(academyMediaObj);
    })
  }

  setDataGetUpcomingWebinars(data, fromNetwork) {
    let jqueryWebinars, prev, next, dots;

    data.forEach(webinar => {
      if (fromNetwork){
        this.upcomingWebinars.push( new Webinar(webinar));
      }
      else {
        this.upcomingWebinarsCache.push(new Webinar(webinar));
      }
    });

    setTimeout(() => {
      if (fromNetwork){
        jqueryWebinars = $('.webinars-slider');
        prev = $('#webinar-prev');
        next = $('#webinar-next');
        dots = $('.webinars-dots');

        jqueryWebinars.on('init', () => {
          this.upcomingWebinarsCache = [];

        })
      }
      else {
        jqueryWebinars = $('.webinars-slider-cache');
        prev = $('#webinar-prev-cache');
        next = $('#webinar-next-cache');
        dots = $('.webinars-dots-cache');
      }

      jqueryWebinars.slick({
        dots: true,
        appendDots: dots,
        prevArrow: prev,
        nextArrow: next,
        mobileFirst: true
      });

      let scope = this;

      jqueryWebinars.on('touchstart', '.webinar', function (e) {

        var webinar = $(this);

        $(e.target).on('touchend', function (e) {
          $(e.target).off('touchend');

          scope.router.navigateByUrl(webinar.attr("id"));
        });

        $(e.target).on('touchmove', function (e) {
          $(e.target).off('touchmove');
          $(e.target).off('touchend');
        });
      })

    });

  }

  setDataGetCases(cases) {
    this.cases = cases.data;
  }

  setDataGetArticles(articles) {
    this.articles = [];
    articles.forEach(n => {
      const articleObj: ArticleModel = new ArticleModel(n);
      this.articles.push(articleObj);
    })
  }

  setDataGetThemes(data){
    this.themesSlides = [];

    data.forEach(n => {
      const themeObj = new Theme(n);
      this.themesSlides.push(themeObj);

    });
  }

  goToLecture(lecture) {
    this.router.navigateByUrl('/show/' + lecture.getId() + '/' + lecture.getSlug());
  }

  goToUserProfile(url) {
    this.router.navigateByUrl(url);
  }

  openCase(group) {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/alert', group.slug]);
    } else {
      this.router.navigate(['/account/login']);
    }
  }

  goToPost(article) {
    this.router.navigate(['/', 'blog', article.id, article.slug]);
  }

  getDateFormatted(course) {
    if (course.date) {
      return this.dateMomentService.formatPublication(course.date);
    } else {
      return this.dateMomentService.getPublicationDate(course.publication_date);
    }
  }

  getPublishedDate(lecture) {
    const date = this.dateMomentService.getUnixDate(lecture.date);
    if (this.localeService.locale === 'fr') {
      return date.format('Do MMMM YYYY');
    }
    return date.format('MMMM Do, YYYY');
  }

  getFullDateTime(webinar) {
    const format = this.localeService.locale === 'fr' ? 'Do MMMM, YYYY @HH:mm' : 'MMMM Do, YYYY @HH:mm';
    return this.dateMomentService.getDate(webinar.datetime_begin).format(format);
  }


}

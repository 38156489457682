import {User} from './user.model';

export class Case {
    theme_id: number;
    theme2_id: any;
    themes: any;
    user_id: number;
    id: number;
    can_choose_anonymity: boolean;
    locale: string;
    treatments: any[];
    purposes: any[];
    complications: any[];
    title: string;
    patient_description: string;
    case_description: string;
    pictures: any[];
    videos: any[];
    alert_group_id: number;
    alert_group_id_2: any;
    type: string;
    last_activity: string;
    anonymous: boolean | number;
    advisors: any;
    hits_count: any;
    subscriber: any;
    comments_count: any;
    last_activity_formatted: any = false;
    files: number = 0;
    comments: any;
    open: boolean | number;
    user: User;
    specialty: any;
    country: any;
    keywords: any;
    pictures_deleted: any[];
    videos_deleted: any[];
    edited_at: string;
    created_at: string;
    user_badges: any[];
    group_2: any;

    constructor(obj?: any) {
        this.user_id = obj && obj.user_id;
        this.can_choose_anonymity = obj && obj.can_choose_anonymity;
        this.theme_id = obj && obj.theme_id || '';
        this.theme2_id = obj && obj.theme2_id || '';
        this.id = obj && obj.id;
        this.themes = obj && obj.themes;
        this.type = obj && obj.type;
        this.anonymous = obj && obj.anonymous ? 1 : 0;
        this.locale = obj && obj.locale;
        this.subscriber = obj && obj.subscriber;
        this.treatments = obj && obj.treatments || [];
        this.title = obj && obj.title;
        this.patient_description = obj && obj.patient_description;
        this.case_description = obj && obj.case_description;
        this.pictures = obj && obj.pictures || [];
        this.videos = obj && obj.videos || [];
        this.alert_group_id = obj && obj.alert_group_id;
        this.purposes = obj && obj.purposes || [];
        this.complications = obj && obj.complications || '';
        this.advisors = obj && obj.advisors;
        this.last_activity = obj && obj.last_activity;
        this.hits_count = obj && obj.hits_count;
        this.comments_count = obj && obj.comments_count;
        this.files = obj && obj.files;
        this.comments = obj && obj.comments;
        this.open = obj && obj.open ? 1 : 0;
        this.user = obj && obj.user ? new User(obj.user) : null;
        this.specialty = obj && obj.specialty;
        this.country = obj && obj.country;
        this.keywords = obj && obj.keywords || '';
        this.pictures_deleted = obj && obj.pictures_deleted || [];
        this.videos_deleted = obj && obj.videos_deleted || [];
        this.edited_at = obj && obj.edited_at;
        this.created_at = obj && obj.created_at;
        this.user_badges = obj && obj.user_badges || [];
        this.alert_group_id_2 = obj && obj.alert_group_id_2 || '';
        this.group_2 = obj && obj.group_2 || null;
    }

    getLastActivityHumanWay(moment) {
        if (!this.last_activity_formatted) {
            this.last_activity_formatted = moment.formatHumanWay(this.last_activity);
        }
        return this.last_activity_formatted;
    }
}

<div class="page events">
	<app-section-top [title]="'academy.Welcome_to_IMCAS'" [subtitle]="'academy.Congrats_you_have_now_unlocked'|translate" [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/account-thanks.jpg'"></app-section-top>
	<div class="content">
		<div class="page-content">
      <div class="page-section" *ngIf="isPhysicianNotVerified()">
        <div class="alert alert-danger">
          <p>{{'academy.diploma_required_title'|translate}}</p>
          <p *ngIf="user.premium_membership">{{'academy.diploma_required_text_premium'|translate}}</p>
          <p *ngIf="!user.premium_membership">{{'academy.diploma_required_text_free'|translate}}</p>
          <button [routerLink]="'/account/certifications'" class="button button-violet-outline">{{ 'academy.upload_diploma_now' | translate }}</button>
        </div>
      </div>
			<div class="page-section">
				<h3>{{'academy.To_become_an_active'|translate}}</h3>
				<div class="row">
					<div class="col-md-{{ environment.domain == 'aesthetics' ? '4' : '6' }}">
						<div class="step">
							<span class="icon-user"></span>
							<h4>{{'academy.Complete_your_profile'|translate}}</h4>
							<p style="text-align:center;">{{'academy.Let_others_know'|translate}}</p>
							<a [routerLink]="['/account', 'professional-data']" class="button button-orange">{{'academy.Complete_your_profile'|translate}}</a>
						</div>
					</div>
					<div class="col-md-{{ environment.domain == 'aesthetics' ? '4' : '6' }}">
						<div class="step">
							<span class="icon-graduation-hat"></span>
							<h4>{{'home.IMCAS_Academy_button_text'|translate}}</h4>
							<p style="text-align:center;">{{'academy.Watch_instructional_videos'|translate}}</p>
							<a [routerLink]="['/library', 'courses']" class="button button-orange">{{'home.IMCAS_Academy_button_text'|translate}}</a>
						</div>
					</div>
					<div class="col-md-4" *ngIf="environment.domain == 'aesthetics'">
						<div class="step">
							<span class="icon-group-work"></span>
							<h4>{{'academy.Stay_sharp'|translate}}</h4>
							<p style="text-align:center;">{{'academy.Read_articles_from'|translate}}</p>
							<a [routerLink]="['/blog']" class="button button-orange">{{'academy.Stay_sharp'|translate}}</a>
						</div>
					</div>
				</div>
			</div>

			<div class="page-section">
				<h3>{{'academy.To_start'|translate}}</h3>
				<div class="row" *ngIf="courses && medias && content">
					<div class="col-md-4 col-sm-6 col-12" *ngFor="let item of content">
						<a *ngIf="item" [routerLink]="item.slug ? '/course/' + item.id + '/' + item.slug : '/show/' + item.id + '/' + getSlug(item.media.translations)" class="lecture-course">
							<img [src]="item.picture_url || item.media.thumb">
							<div class="overlay"></div>
							<h5>{{translationService.getTranslatedAttribute('title',
								item.media ? item.media.translations : item.translations, locale)}}</h5>
						</a>
					</div>
				</div>
			</div>
			<div class="page-section text-center" *ngIf="user && !user.premium_membership && !environment.mobile">
				<h3>{{'academy.If_you_feel_that_IMCAS'|translate}}</h3>
				<a [routerLink]="['/premium']" class="button button-orange">{{'academy.Subscribe_here'|translate}}</a>
			</div>
      <div class="page-section text-center" *ngIf="user && !user.premium_membership && environment.mobile">
        <h3>{{'academy.If_you_feel_that_IMCAS'|translate}}</h3>
        <section class="section offer-plan" style="padding:0">
          <div class="card  text-right">
            <h5 class="card-header"><b>Note :</b></h5>
            <div class="card-body">
              Manage your subscription on the academy website
            </div>
          </div>
        </section>
      </div>
			<div class="support">
				<p>{{'academy.Regards_and_remaining'|translate}}</p>
				<p><small>{{'academy.Contact'|translate}}: <a [href]="'mailto:'+environment.emailSupport">{{environment.emailSupport}}</a></small><small>{{'academy.Hotline'|translate}}: {{environment.phonenumber}}</small></p>
			</div>
		</div>
	</div>
</div>

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {Specialty} from "../../../../models/specialty.model";
import {Country} from "../../../../models/country.model";
import {SpecialtyService} from "../../../../services/specialty.service";
import {CountryService} from "../../../../services/country.service";
import {UserService} from "../../../../services/user.service";
import {Router} from "@angular/router";
import {LocaleService} from "../../../../services/locale.service";
import {AuthService} from "../../../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {tap} from "rxjs/operators";
import {Device} from "@capacitor/device";

@Component({
  selector: 'app-mobile-sign-up',
  templateUrl: './mobile-sign-up.component.html',
  styleUrls: ['./mobile-sign-up.component.scss'],
  providers: [
    SpecialtyService,
    CountryService,
    UserService
  ]
})
export class MobileSignUpComponent implements OnInit {

  environment = environment;
  @Input() page: string = '';
  mask = ['\d+'];
  discountVisibility = false;
  password_confirmation: null;
  registrationError: string;
  specialities$: Observable < Specialty[] > ;
  countries$: Observable < Country[] > ;
  selectedCountry: Country;
  selectedSpecialty: Specialty;
  sCountry: Country;
  sSpecialty: Specialty;
  loading: boolean = false;
  formValidated = false;

  linkContact = "";


  currentSlide = 0;
  user = {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    country_id: null,
    user_specialty_id: null,
    subscribe_web: null,
    subscribe_academy_web: null,
    discountCode: null,
    medical_resident: null,
    password: null,
    password_confirmation: null,
    source_details: ""
  };

  @ViewChild('signUpForm', {static: false}) signUpForm;

  platform = "";
  specialtyRequired: boolean = false;
  countryRequired: boolean = false;

  constructor(
    private router: Router,
    private locale: LocaleService,
    private specialtyService: SpecialtyService,
    private countryService: CountryService,
    private userService: UserService,
    private authService: AuthService,
    private translate: TranslateService ) {}

  ngOnInit() {

    this.getCountries();
    this.getSpecialties();

    this.translate.get('account.Create_message3_link').subscribe((res: string) => {
      this.linkContact = "<a href='mailto:" + environment.emailSupport + "' target='_blank'>" + res + "</a>"
    });



    setTimeout(() => {
      $('.line-input input').focus(function () {
        $(this).closest('.line-input').find('.floating-label').addClass('selected');
      });

      $('.line-input input').focusout(function () {
        if ($(this).val() == '') {
          $(this).closest('.line-input').find('.floating-label').removeClass('selected');
        }
      });

      //Slider for sign up screens

      $('.form-slider').slick({
        nextArrow: $('#nextArrow'),
        dots: true,
        infinite: false,
        appendDots: $('.navDots'),
        arrows: false,
        swipe: false
      });

      $('.form-slider').on('afterChange', (event, slick, currentSlide) => {
        this.currentSlide = currentSlide;
      });

      $('.form-slider').on('beforeChange', (event, slick, currentSlide) => {
        return false;
      });

    });
    Device.getInfo().then(deviceInfo => this.platform = deviceInfo.platform);
  }

  getSpecialties() {
    this.specialities$ = this.specialtyService.getSpecialties();
    this.selectedSpecialty = null;
  }

  getCountries() {
    this.countries$ = this.countryService.getCountries();
    this.selectedCountry = null;
  }

  specialtyChanged(value) {
    this.specialtyRequired = value === null || value === 'null';
    this.selectedSpecialty = value;
  }

  countryChanged(value) {
    this.countryRequired = value === null || value === 'null';
    this.selectedCountry = value;
  }

  handlePrevButton() {
    if (this.currentSlide == 0) {
      this.router.navigateByUrl('/home');
    } else {
      setTimeout(() => {
        $('.form-slider').slick("slickGoTo", 0);
        this.currentSlide = 0;
      });
    }
  }

  handleNextButton() {
    if (this.currentSlide == 0) {
      if (this.selectedCountry && this.selectedSpecialty && !this.countryRequired && !this.specialtyRequired && this.user.first_name && this.user.last_name) {
        this.registrationError = '';
        setTimeout(() => {
          $('.form-slider').slick("slickGoTo", 1);
          this.currentSlide = 1;
        });
      } else {
        this.translate.get('abstract.All_fields_are_required').subscribe(trad => {
          this.registrationError = '<ul>';
          this.registrationError += '<li>' + trad + '</li>';
          this.registrationError += '</ul>';
        }, );
        setTimeout(() => {
          this.registrationError = '';
        }, 2000);

      }
    } else {
      this.signUp();
    }
  }

  signUp() {
    this.formValidated = true;

    if (this.signUpForm.valid && !this.loading) {
      this.loading = true;
      if (this.selectedCountry && this.selectedSpecialty) {
        this.user.country_id = this.selectedCountry.id;
        this.user.user_specialty_id = this.selectedSpecialty.id;
      }

      const loginData = {
        username: this.user.email,
        password: this.user.password,
        platform : ''
      };

      this.user.subscribe_web = this.user.subscribe_web ? 1 : 0;
      this.user.subscribe_academy_web = this.user.subscribe_academy_web ? 1 : 0;
      if (this.user.discountCode === 'null' || !this.user.discountCode) {
        this.user.discountCode = '';

      }
      this.userService.registerUser(this.user).subscribe(
        userRegistered => {
          this.authService.loginMainWebsite({email: loginData.username, password: loginData.password}).subscribe(() => {}, err => {});
          this.authService.login(loginData).subscribe(
            logged => {
              this.loading = false;
              this.authService.setSession(logged);
              this.router.navigateByUrl('account/create/step-2');
            },
            logErr => {
              this.registrationError = '<ul>';
              this.registrationError += `<li>Register successful, but login failed</li>`;
              this.registrationError += `<li>Created userID : ${(userRegistered as any).user_id}</li>`;
              this.registrationError += `<li>Error is: ${logErr.error.message}</li>`;
              this.registrationError += `<li>Data: ${this.user.first_name} / ${this.user.last_name} / ${this.user.country_id} / ${this.user.user_specialty_id} / ${this.user.source_details} / ${this.user.password} / ${this.user.discountCode} / ${this.platform} / ${JSON.stringify(userRegistered)}</li>`;
              this.registrationError += '</ul>';
              this.loading = false;
            });
        },
        regError => {
          this.registrationError = '<ul>';
          for (const ruleKey in regError.error.rules) {
            if (regError.error.rules.hasOwnProperty(ruleKey)) {
              regError.error.rules[ruleKey].forEach((element) => {
                this.registrationError += '<li>' + element + '</li>';
              });
            }
          }
          this.registrationError += `<li>Please try again</li>`;
          this.registrationError += '</ul>';
          this.loading = false;
        }
      );
    }
  }

  checkSpecialtyOrCountryRequirement(requirement: string) {
    if (requirement === 'specialty') {
      this.specialtyRequired = this.selectedSpecialty === null;
    } else {
      this.countryRequired = this.selectedCountry === null;
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Magazine} from '../../models/magazine.model';
import {TranslationService} from '../../services/translation.service';
import {MagazineService} from '../../services/magazine.service';
import {LocaleService} from '../../services/locale.service';


@Component({
    selector: 'app-magazine-section',
    templateUrl: './magazine-section.component.html',
    styleUrls: ['./magazine-section.component.scss'],
})
export class MagazineSectionComponent implements OnInit {
    @Input() magazine: Magazine;
    locale: string;

    constructor(public translationService: TranslationService,
                private localeService: LocaleService,
                private magazineService: MagazineService) {
        this.locale = localeService.currentLocale();
    }

    downloadMagazine(event, id) {
        this.magazineService.downloadMagazine(id).subscribe(file => {
            const blob = new Blob([file], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        });
    }

    ngOnInit() {
    }

}

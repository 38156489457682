import {LocaleComponent} from './components/locale/locale.component';
import {HomeComponent} from './components/pages/home/home.component';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {SignUpComponent} from './components/pages/sign-up/sign-up.component';
import {JoinComponent} from './components/pages/join/join.component';
import {PremiumComponent} from './components/pages/premium/premium.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {AuthGuardService} from './services/auth-guard.service';
import {GuestGuardService} from './services/guest-guard.service';
import {ScientificBoardComponent} from './components/pages/explore/scientific-board/scientific-board.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {LibraryComponent} from './components/pages/library/library.component';
import {WebinarsComponent} from './components/pages/webinars/webinars.component';
import {AlertComponent} from './components/pages/alert/alert.component';
import {PartnersComponent} from './components/pages/explore/partners/partners.component';
import {WorldwideEventsComponent} from './components/pages/explore/worldwide-events/worldwide-events.component';
import {HumanitarianMissionsComponent} from './components/pages/explore/humanitarian-missions/humanitarian-missions.component';
import {FundingComponent} from './components/pages/funding/funding.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {InboxComponent} from './components/pages/inbox/inbox.component';
import {AccountComponent} from './components/pages/account/account.component';
import {AboutComponent} from './components/pages/about/about.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {TermsOfUseComponent} from './components/pages/terms-of-use/terms-of-use.component';
import {PublicationsComponent} from './components/pages/publications/publications.component';
import {LearnSocietiesComponent} from './components/pages/learn-societies/learn-societies.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {CourseComponent} from './components/pages/course/course.component';
import {LecturePageComponent} from './components/pages/lecture/lecture.component';
import {PasswordReminderComponent} from './components/pages/password-reminder/password-reminder.component';
import {SignUpStep2Component} from './components/pages/sign-up-step2/sign-up-step2.component';
import {SignUpThanksComponent} from './components/pages/sign-up-thanks/sign-up-thanks.component';
import {WebinarComponent} from 'app/components/pages/webinar/webinar.component';
import {AlertListComponent} from 'app/components/pages/alert-list/alert-list.component';
import {AlertSubmitComponent} from 'app/components/pages/alert-submit/alert-submit.component';
import {AlertTopicComponent} from 'app/components/pages/alert-topic/alert-topic.component';
import {AccountCoursesComponent} from 'app/components/pages/account/courses/courses.component';
import {AccountFavoritesComponent} from 'app/components/pages/account/favorites/favorites.component';
import {AccountConnectionsComponent} from 'app/components/pages/account/connections/connections.component';
import {ProfileDataComponent} from 'app/components/pages/account/data/data.component';
import {ProfilePictureCoverComponent} from 'app/components/pages/account/picture-cover/picture-cover.component';
import {ProfilePublicationsComponent} from 'app/components/pages/account/publications/publications.component';
import {ProfileSocietiesComponent} from 'app/components/pages/account/societies/societies.component';
import {ProfileMainInterestsComponent} from 'app/components/pages/account/main-interests/main-interests.component';
import {ProfileMissionsComponent} from 'app/components/pages/account/missions/missions.component';
import {ContributionsVideosComponent} from 'app/components/pages/account/videos/videos.component';
import {ContributionsVideosApprovalComponent} from 'app/components/pages/account/videos-approval/videos-approval.component';
import {ContributionsArticlesComponent} from 'app/components/pages/account/articles/articles.component';
import {NotificationsSettingsComponent} from 'app/components/pages/account/notifications-settings/notifications-settings.component';
import {NotificationsListComponent} from 'app/components/pages/account/notifications-list/notifications-list.component';
import {SettingsPasswordComponent} from 'app/components/pages/account/password/password.component';
import {BlogArticleComponent} from 'app/components/pages/blog-article/blog-article.component';
import {UserThemesGuardService} from 'app/services/user-themes-guard.service';
import {AlertJoinGroupComponent} from './components/pages/alert-join-group/alert-join-group.component';
import {TermsOfUseContributorComponent} from './components/pages/terms-of-use/contributor/contributor.component';
import {FeedbacksComponent} from './components/pages/feedbacks/feedbacks.component';
import {LoginComponent} from './components/pages/login/login.component';
import {CartComponent} from './components/pages/cart/cart.component';
import {PaymentComponent} from './components/pages/cart/payment/payment.component';
import {ThanksComponent} from './components/pages/cart/thanks/thanks.component';
import {ThanksBankTransfertComponent} from './components/pages/cart/thanks-bank-transfert/thanks-bank-transfert.component';
import {MarketplaceComponent} from './components/pages/marketplace/marketplace.component';
import {ProductComponent} from './components/pages/marketplace/product/product.component';
import {
  MarketplaceFreeConfirmationComponent
} from './components/pages/marketplace/marketplace-free-confirmation/marketplace-free-confirmation.component';
import {MarketplaceFreeThanksComponent} from './components/pages/marketplace/marketplace-free-thanks/marketplace-free-thanks.component';
import {RulesComponent} from './components/pages/alert/rules/rules.component';
import {PagePartnerOfferComponent} from './components/pages/partner-offer/partner-offer.component';
import {SystemCheckComponent} from './components/pages/webinars/system-check/system-check.component';
import {LandingComponent} from './components/pages/landing/landing.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {DownloadAppComponent} from './components/pages/download-app/download-app.component';
import {OnDemandComponent} from './components/pages/webinars/on-demand/on-demand.component';
import {LiveMasterclassComponent} from './components/pages/live-masterclass/live-masterclass.component';
import {AccountMembershipComponent} from './components/pages/account/membership/membership.component';
import {AccountMembershipCurrentPlanComponent} from './components/pages/account/current-plan/current-plan.component';
import {SettingsInvoicesComponent} from './components/pages/account/invoices/invoices.component';
import {AccountLanguagesComponent} from './components/pages/account/languages/languages.component';
import {AccountCertificationsComponent} from './components/pages/account/certifications/certifications.component';
import {AccountBadgesComponent} from './components/pages/account/badges/badges.component';
import {TutorialComponent} from "./components/mobile-components/tutorial/tutorial.component";

export const Routes = [
  {path: 'landing/:slug', component: LandingComponent},
  {
    path: '', component: LocaleComponent, children: [
      {path: 'about', component: AboutComponent},
      {
        path: 'account', component: AccountComponent, canActivate: [AuthGuardService, UserThemesGuardService],
        children: [
          {path: '', redirectTo: 'courses', pathMatch: 'full'},

          // Membership submenus
          {path: 'current-plan', component: AccountMembershipCurrentPlanComponent},
          {path: 'certificate', component: AccountMembershipComponent},
          {path: 'invoices', component: SettingsInvoicesComponent},

          {path: 'courses', component: AccountCoursesComponent},

          {path: 'favorites', component: AccountFavoritesComponent},

          {path: 'badges', component: AccountBadgesComponent},

          // Profile submenus
          {path: 'professional-data', component: ProfileDataComponent},
          {path: 'picture-and-cover', component: ProfilePictureCoverComponent},
          {path: 'main-interests', component: ProfileMainInterestsComponent},
          {path: 'publications', component: ProfilePublicationsComponent},
          {path: 'scientific-societies', component: ProfileSocietiesComponent},
          {path: 'humanitarian-missions', component: ProfileMissionsComponent},
          {path: 'certifications', component: AccountCertificationsComponent},

          {path: 'connections', component: AccountConnectionsComponent},

          // Contributions submenus
          {path: 'videos', component: ContributionsVideosComponent},
          {path: 'videos-approval', component: ContributionsVideosApprovalComponent},
          {path: 'articles', component: ContributionsArticlesComponent},

          // Settings submenus
          {path: 'change-password', component: SettingsPasswordComponent},
          {path: 'notifications', component: NotificationsListComponent},
          {path: 'notifications-settings', component: NotificationsSettingsComponent},
          {path: 'languages', component: AccountLanguagesComponent},
        ]
      },

      {path: 'join/:id/:slug', component: JoinComponent},

      {path: 'account/login', component: LoginComponent, canActivate: [GuestGuardService]},
      {path: 'account/login/:token', component: LoginComponent},
      {path: 'account/create', component: SignUpComponent, canActivate: [GuestGuardService]},
      {path: 'account/create/step-2', component: SignUpStep2Component, CanActivate: [AuthGuardService]},
      {
        path: 'account/create/thanks',
        component: SignUpThanksComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'account/thanks',
        component: SignUpThanksComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {path: 'alert', component: AlertComponent, canActivate: [UserThemesGuardService]},
      {
        path: 'alert/:group',
        component: AlertListComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'alert/:group/join/:groupId',
        component: AlertJoinGroupComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'alert/:group/submit/:groupId',
        component: AlertSubmitComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'alert/:group/:topicId/update',
        component: AlertSubmitComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'alert/:group/:topicId/:topicSlug',
        component: AlertTopicComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {path: 'blog', component: BlogComponent, canActivate: [UserThemesGuardService]},
      {path: 'blog/:id/:slug', component: BlogArticleComponent, canActivate: [UserThemesGuardService]},
      {path: 'cart', component: CartComponent, canActivate: [AuthGuardService, UserThemesGuardService]},
      {path: 'cart/subscribe', component: CartComponent, canActivate: [AuthGuardService, UserThemesGuardService]},
      {
        path: 'cart/payment',
        component: PaymentComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'cart/payment/thanks',
        component: ThanksComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'cart/payment/thanks-bank-transfert',
        component: ThanksBankTransfertComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {path: 'contact', component: ContactComponent},
      {path: 'course/:id/:slug', component: CourseComponent, canActivate: [UserThemesGuardService]},
      {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService, UserThemesGuardService]},
      {
        path: 'explore/humanitarian-missions',
        component: HumanitarianMissionsComponent,
        canActivate: [UserThemesGuardService]
      },
      {
        path: 'explore/learned-societies',
        component: LearnSocietiesComponent,
        canActivate: [UserThemesGuardService]
      },
      {path: 'explore/partners', component: PartnersComponent, canActivate: [UserThemesGuardService]},
      {path: 'explore/partners/:tab', component: PartnersComponent, canActivate: [UserThemesGuardService]},
      {path: 'explore/publications', component: PublicationsComponent, canActivate: [UserThemesGuardService]},
      {
        path: 'explore/scientific-board',
        component: ScientificBoardComponent,
        canActivate: [UserThemesGuardService]
      },
      {
        path: 'explore/worldwide-events',
        component: WorldwideEventsComponent,
        canActivate: [UserThemesGuardService]
      },
      {
        path: 'explore/worldwide-events/:tab',
        component: WorldwideEventsComponent,
        canActivate: [UserThemesGuardService]
      },
      {path: 'faq', component: FaqComponent},
      {path: 'feedback', component: FeedbacksComponent},
      {path: 'home', component: HomeComponent, canActivate: [GuestGuardService]},
      {path: 'tutorial', component: TutorialComponent},
      {path: 'download-app', component: DownloadAppComponent},
      {path: 'inbox', component: InboxComponent, canActivate: [AuthGuardService, UserThemesGuardService]},
      {
        path: 'inbox/thread/with/:slug',
        component: InboxComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {
        path: 'inbox/thread/:threadId',
        component: InboxComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {path: 'industry', component: FundingComponent, canActivate: [UserThemesGuardService]},
      {path: 'library/:category', component: LibraryComponent, canActivate: [UserThemesGuardService]},
      {
        path: 'library/:category/theme/:themeId/:themeSlug',
        component: LibraryComponent,
        canActivate: [UserThemesGuardService]
      },
      {
        path: 'library/:category/theme/:themeId/:themeSlug/keyword/:keywordId/:keywordSlug',
        component: LibraryComponent,
        canActivate: [UserThemesGuardService]
      },
   /*   {path: 'marketplace', component: MarketplaceComponent, canActivate: [UserThemesGuardService]},
      {path: 'marketplace/:slug', component: ProductComponent, canActivate: [UserThemesGuardService]},*/
      {path: 'marketplace/free-confirmation/:slug', component: MarketplaceFreeConfirmationComponent, canActivate: [UserThemesGuardService]},
      {path: 'marketplace/free-thanks/:slug', component: MarketplaceFreeThanksComponent, canActivate: [UserThemesGuardService]},
      {path: 'masterclass/:slug', component: LiveMasterclassComponent },
      {path: 'masterclass/:slug/language/:videoLanguage', component: LiveMasterclassComponent, canActivate: [UserThemesGuardService]},
      {path: 'not-found', component: NotFoundComponent},
      {
        path: 'partner-offer/:name',
        component: PagePartnerOfferComponent,
        canActivate: [AuthGuardService, UserThemesGuardService]
      },
      {path: 'password-lost', component: PasswordReminderComponent},
      {path: 'premium', component: PremiumComponent},
      {path: 'privacy-policy', component: PrivacyPolicyComponent},
      {path: 'profile/:slug', component: ProfileComponent, canActivate: [UserThemesGuardService]},
      {path: 'show/:id/:slug', component: LecturePageComponent, canActivate: [UserThemesGuardService]},
      {path: 'terms-of-use', component: TermsOfUseComponent},
      {
        path: 'terms-of-use/contributor',
        component: TermsOfUseContributorComponent,
        canActivate: [UserThemesGuardService]
      },
      {
        path: 'terms-of-use/alert',
        component: RulesComponent,
        canActivate: [UserThemesGuardService]
      },
      {path: 'webinar', component: WebinarComponent, canActivate: [UserThemesGuardService]},
      {path: 'webinar/:slug', component: WebinarComponent, canActivate: [UserThemesGuardService]},
      {path: 'webinar/:slug/language/:videoLanguage', component: WebinarComponent, canActivate: [UserThemesGuardService]},
      {path: 'webinar/:slug/:register', component: WebinarComponent, canActivate: [UserThemesGuardService]},
      {path: 'webinars', component: WebinarsComponent, canActivate: [UserThemesGuardService]},
      {path: 'webinars/on-demand', component: OnDemandComponent, canActivate: [UserThemesGuardService]},
      {path: 'webinars/system-check', component: SystemCheckComponent, canActivate: [UserThemesGuardService]},
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {path: '**', redirectTo: '/not-found', pathMatch: 'full'},
    ]
  }
];

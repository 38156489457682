export class AcademySubscriptionPrice {
    currency: string;
    price_subscription: number;
    price: number;

    constructor(obj?: any) {
        this.currency = obj && obj.currency;
        this.price_subscription = obj && obj.price_subscription;
        this.price = obj && obj.price;
    }
}

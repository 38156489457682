import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-learn-societies',
  templateUrl: './learn-societies.component.html',
  styleUrls: ['./learn-societies.component.scss']
})
export class LearnSocietiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

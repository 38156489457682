<div class="modal-confirm">
    <button type="button" class="close close-button" aria-label="Close" data-dismiss="modal" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <h2>{{ title|translate }}</h2>
    <div class="col-sm-12 question-modal" *ngIf="user?.email_notifications && user.email_notifications?.subscribe_academy_web === false">
        <h3>{{ subtitle|translate }}</h3>
        <div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.subscribe_academy_web)">
            <input type="checkbox" id="notification-1" [(ngModel)]="newUser.subscribe_academy_web"
                   class="custom-control-input">
            <label class="custom-control-label" for="notification-1">{{'account.Subscribe_academy_web_label'|translate}}</label>
        </div>
    </div>
    <div class="col-sm-12 question-modal" *ngIf="user?.email_notifications?.partners_optin === false">
        <h3>{{ subtitle2|translate }}</h3>
        <div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.partners_optin)">
            <input type="checkbox" id="notification-2" [(ngModel)]="newUser.partners_optin"
                   class="custom-control-input">
            <label class="custom-control-label" for="notification-2">{{'account.Partners_optin_label'|translate}}</label>
        </div>
    </div>
    <div class="notifications-button">
        <button (click)="updateProfile()" class="button button-orange">{{'account.Update_your_settings'|translate}}
        </button>
    </div>
</div>

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-starting-webinar',
    templateUrl: './starting-webinar.component.html',
    styleUrls: ['./starting-webinar.component.scss']
})
export class StartingWebinarComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

}

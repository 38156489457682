<div class="modal-body text-center">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <h4 id="modal-label">{{'academy.Thank_you_for_watching'|translate}}</h4>
    <p>{{ 'academy.Course_finished'|translate }}</p>
  <p>{{ 'academy.Course_finished_precise'|translate }} <br> "{{title}}"</p>
  <p>{{ 'academy.Share_course_completed'|translate }}</p>
  <app-social-list
    [showSocialItems]="showSocialItems"
    [element]="academyMedia"
    [twitterHref]="'https://twitter.com/intent/tweet?text='+('academy.Share_social_completed_line1'|translate)+title+('academy.Share_social_completed_line2'|translate)+'&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fshow%2F'+academyMedia.id+'%2F'+slug+'0A'+'&amp;hashtags=IMCASAcademy,IMCAS&amp;via=imcascongress&amp;related=imcascongress'"
    [facebookHref]="'https://www.facebook.com/sharer/sharer.php?u='+environment.base_url+'%2F'+locale+'%2Facademy%2Fshow%2F'+academyMedia.id+'%2F'+slug"
    [linkedinHref]="'https://www.linkedin.com/shareArticle?mini=true&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fshow%2F'+academyMedia.id+'%2F'+slug+'&amp;title='+('academy.Share_mail_subject_course_completed'|translate)"
    [mailHref]="'mailto:?subject='+('academy.Share_mail_subject_course_completed'|translate)+'&amp;body='+('academy.Share_mail_line1'|translate)+'%0A%0A'+('academy.Share_mail_completed_line1'|translate)+'%0A'+title+'%0A%0A'+('academy.Share_mail_completed_line2'|translate)+'%0A'+environment.base_url+'/'+locale+'/academy/show/'+academyMedia.id+'/'+slug+'%0A%0A'+('academy.Best_regards'|translate)+'%0A'+(user ? user.first_name + ' ' + user.last_name : '')"
  ></app-social-list>
  <button style="margin-top: 1em" type="submit" (click)="activeModal.close(true)" class="button button-blue button-rounded">{{ 'academy.Modal_course_finished_cta'|translate }}    </button>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ArticleModel} from '../../models/article.model';
import {TranslationService} from '../../services/translation.service';
import {LocaleService} from '../../services/locale.service';


@Component({
    selector: 'app-blog-post-item',
    templateUrl: './blog-post-item.component.html',
    styleUrls: ['./blog-post-item.component.scss']
})
export class BlogPostItemComponent implements OnInit {
    locale: string;
    @Input() article: ArticleModel;
    @Input() label: boolean;

    constructor(private router: Router,
                private localeService: LocaleService,
                public translationService: TranslationService) {
        this.locale = localeService.currentLocale();
    }

    ngOnInit() {
    }

    goToPost(article) {
        this.router.navigate(['/', 'blog', article.id, article.slug]);
    }
}

export class PromotionalCode {
    id: number;
    code: string;
    discount_rate: number;
    translations: [];

    constructor(obj?: any) {
        this.id = obj && obj.id;
        this.code = obj && obj.code;
        this.discount_rate = obj && obj.discount_rate;
        this.translations = obj && obj.translations;

    }
}

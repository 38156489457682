  <div class="page webinar" [style.background]="isAop ? '#0f2a48' : null">

    <app-section-top [title]="getTitlePage()" [subtitle]="'webinar.Previous_webinars_subtitle'|translate"
                     [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-webinar.jpg'"></app-section-top>
    <div class="content webinar-content">
      <div class="tabset1">
        <ul class="nav nav-tabs justify-content-start">
          <li class="nav-item">
            <a class="nav-link" routerLink="/webinars" [style.background]="isAop ? '#19487c' : '#250055'">
              {{ ('webinar.Upcoming_webinars'|translate).split('|')[1] }}
            </a>
          </li>
          <li class="nav-item"><a class="nav-link" routerLink="/webinars/on-demand" routerLinkActive="active"
                                  [style.background]="isAop ? '#19487c' : '#250055'">{{'webinar.Webinars_on_demand'|translate}}</a>
          </li>
        </ul>
      </div>
      <app-loader [color]="'#ffffff'" *ngIf="showLoading"></app-loader>

      <ng-container *ngIf="previousWebinars && !showLoading">
        <div class="webinars">
          <select #themeFilter (change)="selectPreviousTheme(themeFilter.value)" name="theme"
                  id="filter-theme-demand" class="filters-select">
            <option value="all" selected>{{'attend.Filter_by_theme'|translate}}</option>
            <option *ngFor="let speciality of specialities_list" [value]="speciality.id">{{
              translationService.getTranslatedName(speciality.translations, locale) }}
            </option>
          </select>
          <div class="webinars-item previous-webinar"
               *ngFor="let previousWebinar of shownWebinars | slice:0:15">
            <ng-template #statsmodal let-c="close" let-d="dismiss">
              <div class="modal-body modal-no-title modal-wide">
                <button type="button" class="close" aria-label="Close" (click)="d()">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
                <div class="modal-content">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <h2 class="webinars-item-title-modal">
                          {{translationService.getTranslatedAttribute('title',
                          previousWebinar.translations,
                          locale)}}
                        </h2>
                      </div>
                      <ul>
                        <li><span class="fa fa-users" aria-hidden="true"></span>
                          {{previousWebinar.webinar_registrants}}
                          {{('webinar.Registrants'|translate)}}
                        </li>
                        <li><span class="fa fa-user-md-chat" aria-hidden="true"></span>
                          {{previousWebinar.webinar_live_viewers}}
                          {{('webinar.Live_viewers'|translate)}}
                        </li>
                        <li><span class="fa fa-user-clock" aria-hidden="true"></span>
                          {{previousWebinar.replay_viewers}} {{'academy.Replay_viewers'|translate}}
                        </li>
                        <li><span class="fa fa-comments" aria-hidden="true"></span>
                          {{previousWebinar.academy_media_comments}} Comments
                        </li>
                      </ul>
                      <div class="col-sm-12">
                        <h2 class="webinars-item-title-modal">
                          {{('webinar.Registrants_distribution'|translate)}}</h2>
                      </div>
                      <div class="col-sm-6">
                        <h3>Distribution by specialty</h3>
                        <google-chart id="pieChartSpecialtyRegis" style="width: 100%;" [data]="pieChartSpecialtyDataRegis"></google-chart>
                      </div>
                      <div class="col-sm-6">
                        <h3>Distribution by geographic area</h3>
                        <google-chart id="pieChartGeoRegis"
                                      style="width: 100%;" [data]="pieChartGeoDataRegis">

                        </google-chart>
                      </div>
                      <div class="col-sm-12"><h2 class="webinars-item-title-modal">
                        {{('webinar.Live_viewers_distribution'|translate)}}</h2></div>
                      <div class="col-sm-6">
                        <h3>Distribution by specialty</h3>
                        <google-chart id="pieChartSpecialtyLiveView"  style="width: 100%;" [data]="pieChartSpecialtyDataLiveView"></google-chart>
                      </div>
                      <div class="col-sm-6">
                        <h3>Distribution by geographic area</h3>
                        <google-chart  id="pieChartGeoLiveView"
                                       style="width: 100%;" [data]="pieChartGeoDataLiveView"></google-chart>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="row">
              <div class="col-12">
                <h2 class="webinars-item-title">
                  <div *ngIf="previousWebinar.replay_soon_available">
                    {{translationService.getTranslatedAttribute('title',
                    previousWebinar.translations, locale)}}
                  </div>
                  <a
                    *ngIf="!previousWebinar.replay_soon_available"
                    [routerLink]="['/course', previousWebinar.id, previousWebinar.slug]">{{translationService.getTranslatedAttribute('title',
                    previousWebinar.translations, locale)}}</a>
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="webinars-item-theme"
                     *ngIf="previousWebinar.themes">
                  {{previousWebinar.themes.length > 1 ? ('academy.Themes'|translate) :
                  ('academy.Theme'|translate)}}:
                  <p style="display: inline;" *ngFor="let theme of previousWebinar.themes; index as i">
                    {{ translationService.getTranslatedName(theme.translations, locale) }}{{i ===
                  previousWebinar.themes.length - 1 ? '' : ', '}}
                  </p>
                </div>
                <p class="webinars-item-date" *ngIf="!previousWebinar.replay_soon_available">
                  {{'webinar.Recorded_on'|translate}}
                  {{dateMomentService.getPublicationDate(previousWebinar.recorded_at)}}</p>
                <p class="webinars-item-sponsor"
                   *ngIf="!previousWebinar.hasSeveralSponsors && previousWebinar.sponsored && previousWebinar.sponsorOrGrant">{{previousWebinar.replay_soon_available ? '' : ', '}}{{'academy.Sponsored_by_min'|translate }} {{ previousWebinar.sponsorOrGrant['company_name']
                  }}</p>
                <p class="webinars-item-sponsor" *ngIf="!previousWebinar.hasSeveralSponsors && previousWebinar.granted && previousWebinar.sponsorOrGrant">{{previousWebinar.replay_soon_available ? '' : ', '}}{{
                  'academy.With_educational_grant_min'|translate }} {{ previousWebinar.sponsorOrGrant['company_name'] }} <span *ngIf="previousWebinar.id === 2733">{{'academy.And'|translate}} NOVARTIS</span></p>

                <div class="webinars-item-profile"
                     *ngIf="!previousWebinar.hasSeveralSponsors && previousWebinar.sponsorOrGrant?.company_profiles?.length">
                  <a class="link-dark"
                     [routerLink]="['/profile', previousWebinar.sponsorOrGrant['slug']]"
                     target="_blank"><i
                    class="fa fa-rss" aria-hidden="true"></i>
                    {{'webinar.discover_sponsor' | translate:{
                      company_name:
                        previousWebinar.sponsorOrGrant['company_name']
                    } }}
                  </a>
                </div>

                <div class="webinars-item-report"
                     *ngIf="previousWebinar && !previousWebinar.replay_soon_available">
                  <div class="data-report" *ngIf="previousWebinar.webinar_registrants > 2">
                    <p style="text-align: center;">
                                                  <span style="text-align: center; display: inline-block;" placement="top"
                                                        ngbTooltip="{{previousWebinar.webinar_registrants + ' ' + ('webinar.Registrants'|translate)}}"
                                                        class="fa fa-users" aria-hidden="true">
                                                  </span>
                      {{previousWebinar.webinar_registrants}}
                      <span class="data-report-text">{{('webinar.Registrants'|translate)}}</span>
                    </p>
                  </div>
                  <div class="data-report" *ngIf="previousWebinar.webinar_live_viewers > 2">
                    <p style="text-align: center;">
                                                  <span style="text-align: center; display: inline-block;" placement="top"
                                                        ngbTooltip="{{previousWebinar.webinar_live_viewers + ' ' + ('webinar.Live_viewers'|translate)}}"
                                                        class="fa fa-user-md-chat"
                                                        aria-hidden="true">
                                                  </span>
                      {{previousWebinar.webinar_live_viewers}}
                      <span class="data-report-text">{{('webinar.Live_viewers'|translate)}}</span>
                    </p>
                  </div>
                  <span class="bar-delimit">&#124;</span>
                  <div class="data-report" *ngIf="previousWebinar.replay_viewers > 2">
                    <p style="text-align: center;">
                                                  <span style="text-align: center; display: inline-block;" placement="top" [ngbTooltip]="'academy.Replay_viewers'|translate"
                                                         class="fa fa-user-clock"
                                                        aria-hidden="true"></span>
                      {{previousWebinar.replay_viewers}}
                      <span class="data-report-text">{{'academy.Replay_viewers'|translate}}</span>
                    </p>
                  </div>
                  <div class="data-report">
                    <p style="text-align: center;cursor: pointer;" *ngIf="!isAop"
                       (click)="open(statsmodal, previousWebinar)">
                                                  <span style="text-align: center; display: inline-block;" placement="top"
                                                        ngbTooltip="Stats" class="fa fa-chart-pie"
                                                        aria-hidden="true"></span>
                      <u> {{('academy.Statistics'|translate)}}</u>
                    </p>
                  </div>
                  <div class="data-report-rating">
                    <ngb-rating *ngIf="previousWebinar" [(rate)]="previousWebinar.rating"
                                max="5"
                                [readonly]="true">
                      <ng-template let-fill="fill">
                        <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                        <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                        <i *ngIf="fill < 100 && fill > 0"
                           class="fa fa-star-half-alt"></i>
                      </ng-template>
                    </ngb-rating>
                  </div>
                </div>

              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-sm-8">
                <div class="webinars-speakers">
                  <div class="webinars-speakers-item"
                       *ngFor="let user of previousWebinar.speakers | mapToIterable">
                    <a [routerLink]="['/profile', user.slug]">
                      <div class="rounded-picture"
                           [style.background]="'url('+user.picture_url+')'"
                           [style.background-size]="'cover'"></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="webinars-button">
                  <a *ngIf="!previousWebinar.replay_soon_available"
                     [routerLink]="['/course', previousWebinar.id, previousWebinar.slug]"
                     class="button button-blue">{{user && user.premium_membership ?
                    ('academy.Watch_it_now'|translate) :
                    ('webinar.Previous_webinars_cta'|translate)}}</a>

                  <button *ngIf="previousWebinar.replay_soon_available"
                          class="button button-blue">
                    {{('webinar.Replay_soon_available'|translate)}}
                  </button>
                </div>

              </div>
            </div>


          </div>
          <a [routerLink]="['/library/courses']" (click)="goWebinarsCourses()" class="filters-select"
             style="text-align: center">
            {{user.premium_membership ? ('webinar.Button_view_all_webinars'|translate) : ('webinar.Button_view_free_webinars'|translate)}}
          </a>
        </div>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  </div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {shareReplay} from 'rxjs/internal/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class WebpageService {
    private endpoint: string = '/webpages';

    constructor(private httpClient: HttpClient) {
    }

    getWebpage(menu): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (menu) {
            reqOpts.params = reqOpts.params.set('menu', menu);
        }
        return this.httpClient.get<any>(environment.apiURL + this.endpoint, reqOpts).pipe(shareReplay());
    }

    public getBySlug(slug): Observable<any> {
        return this.httpClient.get<any>(environment.apiURL + this.endpoint + '/' + slug, {})
            .pipe(shareReplay());
    }
}

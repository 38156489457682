<div class="coordinators" *ngIf="coordinators && coordinators.length > 0">
  <h3 *ngIf="!isBoard">{{coordinators === 1 ?
    ('alert.Topic_coordinator'|translate).split('|')[0] :
    ('alert.Topic_coordinator'|translate).split('|')[1]}}</h3>
    <h3 *ngIf="isBoard">{{ 'alert.Comite_exec'|translate}}</h3>
  <div class="row">
    <div class="col-md-4 coordinators-item" *ngFor="let chair of chairs">
      <div class="chair author" >
        <div class="chairman" style="">Chairman</div>
        <div  [routerLink]="'/profile/' + chair.slug"
              [style.background]="'url(' + chair.picture_url + ')'"
              [style.background-size]="'cover'" class="rounded-circle"></div>
        <div class="chair-info">
          <h6>{{chair.fullname}}</h6>
          <span>
                {{chair.specialty ? translationService.getTranslatedAttribute('name', chair.specialty.translations, locale) : ''}}
                {{chair.country ? ', ' + translationService.getTranslatedAttribute('name', chair.country.translations, locale) : ''}}
              </span>
        </div>
      </div>
    </div>
    <div class="col-md-4 coordinators-item" *ngFor="let coordinator of coordinators">
      <div class="coordinator author media" [ngClass]="{'margin-coordinator': chairs && chairs.length > 0}">
        <div  [routerLink]="'/profile/' + coordinator.slug"
              [style.background]="'url(' + coordinator.picture_url + ')'"
             [style.background-size]="'cover'" class="rounded-circle"></div>
        <div class="media-body">
          <h6>{{coordinator.fullname}}</h6>
          <span>
                {{coordinator.specialty ? translationService.getTranslatedAttribute('name', coordinator.specialty.translations, locale) : ''}}
                {{coordinator.country ? ', ' + translationService.getTranslatedAttribute('name', coordinator.country.translations, locale) : ''}}
              </span>
        </div>
      </div>
    </div>
  </div>
</div>
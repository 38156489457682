import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {CongressProduct} from '../../../../models/congress-product.model';
import {CongressProductService} from '../../../../services/congress-product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from '../../../../services/translation.service';
import {LocaleService} from '../../../../services/locale.service';
import * as $ from 'jquery';
import 'slick-carousel';
import {CartService} from '../../../../services/cart.service';
import {AuthService} from '../../../../services/auth.service';
import {AcademySubscriptionService} from '../../../../services/academy-subscription.service';
import {User} from '../../../../models/user.model';
import {UserService} from '../../../../services/user.service';
import {ExternalApiService} from '../../../../services/external-api.service';
import {JwplayerVideoComponent} from '../../../jwplayer-video/jwplayer-video.component';
import {ConfirmationComponent} from "../../../modal/confirmation/confirmation.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [CongressProductService, TranslationService, CartService, AcademySubscriptionService, ExternalApiService]
})
export class ProductComponent implements OnInit {
  congressProduct: CongressProduct;
  slug: string;
  currency: string;
  licenseMessage: boolean;
  licenseErrorMessage: string;
  user: any;
  isLogged: boolean;
  justConnected: boolean;
  locale: string;
  @ViewChildren(JwplayerVideoComponent) videoPlayers: QueryList<JwplayerVideoComponent>;
  requestError: string = "";

  constructor(private congressProductService: CongressProductService,
              private activatedRoute: ActivatedRoute,
              private externalApiService: ExternalApiService,
              public translationService: TranslationService,
              private localeService: LocaleService,
              private cartService: CartService,
              private router: Router,
              private userService: UserService,
              private authService: AuthService,
              private academySubscriptionService: AcademySubscriptionService,
              private modalService: NgbModal
  ) {
  }

  openCart() {
    if (!this.cartService.getCurrentCartId()) {
      this.cartService.getCartId('marketplace_cart_id').subscribe(cartId => {
        this.cartService.setCurrentCartId(cartId);
        this.router.navigate(['cart']);
      }, error => {
        this.cartService.deleteCurrentCartId();
      });
    } else {
      this.router.navigate(['cart']);
    }
  }

  openCartAndAddProduct() {
    if (this.isLogged) {
      if (!this.cartService.getCurrentCartId() || this.cartService.getCurrentCartId() === 'null') {
        this.cartService.getCartId('marketplace_cart_id').subscribe(cartId => {
          this.onlyOneProductCheck(cartId);
        }, error => {
          this.cartService.deleteCurrentCartId();
        });
      } else {
        this.onlyOneProductCheck(this.cartService.getCurrentCartId());
      }
    } else {
      this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url}})
    }
  }

  addFreeProduct() {
    if (this.isLogged) {
      this.router.navigate(['/marketplace/free-confirmation/' + this.congressProduct.slug])
    } else {
      this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url}})
    }
  }

  addProductToCart(cartId) {
    this.academySubscriptionService.post({
      cart_id: cartId,
      congress_product_id: this.congressProduct.id
    }).subscribe(result => {
      this.router.navigateByUrl('/cart');
    }, error => {
      if (error.error.exception === 'App\\Exceptions\\ScienceStandingException') {
        this.licenseErrorMessage = error.error.message;
      } else {
        this.cartService.deleteCurrentCartId();
        setTimeout(() => {
          this.openCartAndAddProduct();
        }, 500)
      }

    });
  }

  resendLicense() {
    this.externalApiService.licenseRecover(this.user.email).subscribe(result => {
      this.licenseMessage = true;
      this.licenseErrorMessage = '';
    }, error => {
      this.licenseMessage = false;
      this.licenseErrorMessage = error.error.message;
    });
  }

  getSaving() {
    return Math.round(((this.congressProduct.getOriginalPriceTtc(this.currency) - this.congressProduct.getPriceTtc(this.currency))
      / this.congressProduct.getOriginalPriceTtc(this.currency)) * 100)
  }

  ngOnInit() {
    this.licenseMessage = false;
    this.licenseErrorMessage = '';
    this.authService.isLoggedIn().subscribe(logged => {
      this.isLogged = logged;
    });
    this.currency = 'usd';
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      this.userService.getUser(this.user.id, {user_country: 1}).subscribe(
        userBackEnd => {
          this.user = new User({...this.user, ...userBackEnd});
          if (userBackEnd.country_id === 78) {
            this.currency = 'eur';
          }
        });
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.justConnected = params.connected;
    });
    this.locale = this.localeService.currentLocale();
    this.activatedRoute.params.subscribe(params => {
      this.slug = params['slug'];
      this.congressProductService.getBySlug(this.slug).subscribe(congressProduct => {
        if (congressProduct.id) {
          this.congressProduct = new CongressProduct(congressProduct);
          this.currency = this.congressProduct.getPriceObj(this.currency).currency;
          setTimeout(() => {
            const product = congressProduct.product;
            const autoplay = !(product && product.medias[0] && product.medias[0].cdn_file);
            $('.academy-slider').not('.slick-initialized').slick({
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: autoplay,
              dots: true,
              prevArrow: '',
              nextArrow: '',
            });
            // We want active video player to pause at slide change, with video players ids = slide ids
            const self = this;
            $('.academy-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
              self.videoPlayers.toArray().forEach(player => {
                if (player.id === currentSlide) {
                  player.getPlayerInstance().pause();
                }
                if (player.id === nextSlide) {
                  player.getPlayerInstance().play();
                }
              })
            })
          }, 50);
        } else {
          this.router.navigateByUrl('/not-found')
        }
      });
    });
  }

  private onlyOneProductCheck(cartId: any) {
    this.cartService.hasProduct(cartId).subscribe(hasProduct => {
      if (hasProduct[0]) {
        console.log('HAS PRODUCT');
        const modalRef = this.modalService.open(ConfirmationComponent);
        modalRef.componentInstance.title = 'You already have a product in your cart. Would you like to exchange it for this one ?'; //marketplace.Cart_no_empty
        modalRef.result.then(() => {
          this.cartService.clean(cartId).subscribe(() => {
            this.cartService.getCartId('marketplace_cart_id').subscribe(newCartId => {
              this.cartService.setCurrentCartId(newCartId);
              this.addProductToCart(newCartId);
            }, () => {this.requestError = "Something went wrong, please retry later"}); //academy.something_went_wrong
          }, () => {this.requestError = "Something went wrong, please retry later"})
        }).catch(() => {this.requestError = "This product has not been added"}); //marketplace.Product_no_added
      } else {
        console.log('PAS DE PRODUCT');
        this.cartService.setCurrentCartId(cartId);
        this.addProductToCart(cartId);
      }
    }, error => {
      if (error.status === 404) {
      this.cartService.deleteCurrentCartId();
      this.openCartAndAddProduct();
    } else {
        this.cartService.deleteCurrentCartId();
        this.requestError = "Something went wrong, please retry later";
    }
    })
  }
}

<div class="back-to-imcas back-to-aop">
  <a [href]="env.base_url + '/' + localeService.currentLocale()"
     class="back-to-imcas-link">{{'academy.Back_to_imcas'|translate}}</a>
  <a routerLink="/contact" class="back-to-imcas-help">
    <span class="icon icon-help"></span>{{'aop_layout.Do_you_have_a_question'|translate}}
  </a>
</div>
<header class="header header-aop" [ngClass]="{'header-menu-opened': headerMenuOpened}">
  <a [routerLink]="user && user.id ? '/dashboard' : '/home'"
     class="header-logo header-logo-aop"><img [src]="logo"/></a>
  <a class="header-menu-trigger" (click)="headerMenuOpened = !headerMenuOpened"><span
    class="icon icon-menu-white"></span></a>
  <div class="header-menu-mobile">
    <nav class="header-menu">
      <ul>
        <li (click)="headerMenuOpened = false" [ngClass]="{'active': isActiveTab('library') }"><a routerLink="/library/courses"
                                                               class="header-menu-item">{{'academy.Library'|translate}}</a>
        </li>
      </ul>
    </nav>
    <app-header-profile (loggedOutClick)="logout()"
                        (webinarToday)="setNextWebinar($event)"
                        (menuClicked)="headerMenuOpened = false"></app-header-profile>
  </div>
  <div class="header-menu-opened-mask" *ngIf="headerMenuOpened" (click)="headerMenuOpened = false"></div>
  <div *ngIf="logged">
    <div class="header-login-mobile">
      <button class="button button-orange" (click)="logout()">
        {{'live.Logout'|translate}}
      </button>
    </div>
  </div>
  <div *ngIf="!logged">
    <a routerLink="/account/login" class="header-login-mobile">
      {{'live.Login'|translate}}
    </a>
  </div>
</header>

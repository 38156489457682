import { Observable } from 'rxjs';

export class Comment {
    entity_type: number;
    entity_id: number;
    reply_to: number;
    content: string;
    public: number;
    anonymous: number;
    user_info: any;
    user_badges: any;
    created_at: any;
    pictures: any;
    comments: any;
    id: number;
    case_closing: boolean | number;
    edited_at: any;
    videos: any;

    constructor(obj?: any) {
        this.entity_type = obj && obj.entity_type;
        this.entity_id = obj && obj.entity_id;
        this.id = obj && obj.id;
        this.reply_to = obj && obj.reply_to || null;
        this.content = obj && obj.content;
        this.public = obj && obj.public || 1;
        this.anonymous = obj && obj.anonymous || 1;
        this.user_info = obj && obj.user_info;
        this.user_badges = obj && obj.user_badges;
        this.created_at = obj && obj.created_at;
        this.pictures = obj && obj.pictures;
        this.comments = obj && obj.comments;
        this.case_closing = obj && obj.case_closing;
        this.edited_at = obj && obj.edited_at;
        this.videos = obj && obj.videos;
    }

    urlifyContent() {
        const urlRegex = /(https?:\/\/[^\s<]+)/ig;
        return this.content.replace(urlRegex, `<a href='$1'target='_blank'>$1</a>`);
    }

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SpecialtyService} from '../../../services/specialty.service';
import {TranslationService} from '../../../services/translation.service';
import {ContactService} from '../../../services/contact.service';
import {LocaleService} from '../../../services/locale.service';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {Specialty} from 'app/models/specialty.model';
import {environment} from '../../../../environments/environment';
import {CongressService} from "../../../services/congress.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../../services/alert.service";
import {Router} from "@angular/router";
import {FilesService} from "../../../services/files.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    providers: [SpecialtyService, ContactService, UserService, CongressService, FilesService]
})
export class ContactComponent implements OnInit {
    private user: User;
    public specialties: Specialty[];
    public locale: string;
    public errors: string[] = [];
    environment: any = environment;
    congresses: {
      congressID: number,
      congressName: string,
      inquiries: any[]
    }[] = [];
    contactForm: FormGroup;
    acceptedFormat = '';
    filesToUpload: any = [];
    filesFormData: FormData;
    extension: string = '';
    loading: boolean = false;
    @ViewChild('INPUTFILE', {static: false}) inputFile!: ElementRef<HTMLInputElement>;
    FILETYPE = ['video/quicktime', "image/jpeg", 'application/pdf', 'image/gif', 'image/heic', 'image/png', 'video/mp4'];
    isSending: boolean = false;
    thanks: boolean = false;
    filesError: string = '';
    fileName: string = '';

    constructor(public specialtyService: SpecialtyService,
                public localeService: LocaleService,
                public congressService: CongressService,
                public translationService: TranslationService,
                public contactService: ContactService,
                private userService: UserService,
                private fb: FormBuilder,
                private filesService: FilesService,
                private router: Router) {
      this.acceptedFormat = filesService.acceptedFormat;
    }

    ngOnInit() {
        this.filesFormData = new FormData();
        this.locale = this.localeService.currentLocale();
        this.congressService.getActivesCongresses(this.locale).subscribe(result => {
          this.congresses = result.data.map(congress => {
            return {
              congressId: congress.id,
              congressName: congress.name,
              inquiries: congress.inquiries.filter(inquiry => inquiry.public).map(inquiry => {
                return {
                  id: inquiry.id,
                  text: inquiry.name,
                  additional_infos: inquiry.additional_infos
                }
              })
            }
          });
        });
        this.getUser();

    }

    contactFormSubmit() {
        this.isSending = true;
        let data = new FormData();
        if (this.filesToUpload && this.filesToUpload.length > 0) {
          let counter = 0;
          // @ts-ignore
          for(var entry of this.filesFormData.keys()) {
            if(entry.includes('content')) {
              data.append('files[' + counter + '][content]', this.filesFormData.get(entry));
            } else if (entry.includes('name')) {
              data.append('files[' + counter + '][name]', this.filesFormData.get(entry));
              counter++;
            }
          }
        }
        data.append('email', this.contactForm.value.email);
        data.append('congress', this.contactForm.value.congress.congressId);
        data.append('inquiry', this.contactForm.value.inquiry.id);
        data.append('specialty', this.contactForm.value.specialty.id);
        data.append('last_name', this.contactForm.value.last_name);
        data.append('message', this.contactForm.value.message);

        this.contactService.send(data)
            .subscribe(r => {
              this.isSending = false;
              this.thanks = true;
              setTimeout(() => {
                this.thanks = false;
                this.router.navigateByUrl(environment.mobile ? '/dashboard' : '/');
              }, 5000);
              this.contactForm.get('message').reset();
              this.contactForm.get('congress').reset();
              this.contactForm.get('inquiry').reset();
              this.filesToUpload = [];
              this.filesError = '';
              this.filesFormData = null;
              this.inputFile.nativeElement.value = null;
                this.errors = [];
            }, r => {
              this.contactForm.get('message').reset();
              this.contactForm.get('congress').reset();
              this.contactForm.get('inquiry').reset();
              this.filesToUpload = [];
              this.filesError = 'back';
              setTimeout(() => this.filesError = '', 5000);
              this.filesFormData = null;
              this.inputFile.nativeElement.value = null;
              this.isSending = false;
            });
    }

    getUser() {
      this.loading = true;
        try {
            this.user = JSON.parse(localStorage.getItem('currentUser'));
        } catch (e) {}
        if (this.user) {
            this.userService.getUser(this.user.id, {user_specialty: 1}).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                    this.getSpecialties();
                });
        } else {
            this.getSpecialties();
        }
    }

    getSpecialties() {
        this.specialtyService
            .getSpecialties({specialty_category: this.user ? this.user.specialty_category : null, all: 1})
            .subscribe(data => {
                let speciality: Specialty = null;
                this.specialties = data;
                if (this.user && this.user.specialty) {
                    const specialtyToSelect = data.find(specialty => specialty.id === this.user.specialty.id);
                  speciality = specialtyToSelect ? specialtyToSelect : null;
                }
              this.contactForm = this.fb.group({
                last_name:[this.user ? this.user.last_name : null, this.user ? Validators.required : null],
                email: [this.user ? this.user.email : null, this.user ? [Validators.required, Validators.email] : null],
                message: [null, Validators.required],
                specialty: [speciality, this.user ? Validators.required : null],
                congress: [null, Validators.required],
                inquiry: [null, Validators.required]
              });
              this.loading = false;
            });
    }


  commentImagesUpload(event) {
    if (Object.keys(event.target.files).length <= 3 && this.filesToUpload.length < 3) {
      Object.keys(event.target.files).map((f, i) => {
        if (this.filesToUpload.length < 3) {
          if(event.target.files[f].size > 104857600) /* 100 Mo */ {
            this.filesError = 'Size';
            setTimeout(() => this.filesError = '', 5000)
            this.fileName = event.target.files[f].name;
          } else {
            if (this.FILETYPE.find(type => type === event.target.files[f].type)) {
              this.filesFormData.append('files['+ i + '][content]', event.target.files[f], event.target.files[f].name);
              this.filesFormData.append('files[' + i + '][name]', event.target.files[f].name);
              if (event.target.files[f].name.length > 30) {
                const nameSplit = event.target.files[f].name.split('.');
                this.filesToUpload.push(nameSplit[0].substring(0, 29)+ '....'+ nameSplit[1]);
              } else {
                this.filesToUpload.push(event.target.files[f].name);
              }
            }
            else {
              this.filesError = 'Error';
            }
          }
        } else {
          this.filesError = 'Max';
          setTimeout(() => this.filesError = '', 5000)
        }
      });
    } else {
      this.filesError = 'Max';
      setTimeout(() => this.filesError = '', 5000)
      this.inputFile.nativeElement.value = null;
    }

  }

  removeFromList(index: number) {
    this.filesToUpload.splice(index, 1);
    this.filesFormData.delete('files['+ index + '][content]');
    this.filesFormData.delete('files['+ index + '][name]');
  }
}

<div class="page">
  <app-section-top [title]="'premium.IMCAS_Explore'" [subtitle]="'academy.Discover_the_world'|translate"
                   [backgroundUrl]=" environment.cdn_base_url + '/assets-front/img/cover-explore.jpg'"></app-section-top>
  <div class="content">
    <div class="page-content">
      <div class="page-introduction">
        <h3>{{ 'academy.Humanitarian_missions'|translate }}</h3>
        <p>{{ 'explore.Explore_huma_missions'|translate }}</p>
      </div>
      <div class="filters">
        <div class="row">
          <div class="filters-themes col-12 col-md-6">
            <select #specialityFilter id="specialty" name="specialty"
                    (change)="filterBySpeciality(specialityFilter.value)" class="filters-select">
              <option value="" selected>{{ 'explore.Filter_by_specialty'|translate }}</option>
              <option *ngFor="let specialty of specialities_list"
                      [value]="specialty.id">{{ translationService.getTranslatedName(specialty.translations, locale) }}</option>
            </select>
          </div>
          <div class="filters-themes col-12 col-md-6">
            <select #continentFilter id="continent" name="continent" (change)="filterByContinent(continentFilter.value)"
                    class="filters-select">
              <option value="" selected>{{ 'explore.Filter_by_continent'|translate }}</option>
              <option *ngFor="let continent of continents_list" [value]="continent.id">
                {{ 'layout.' + continent.continent | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="organization-list">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12" *ngFor="let organization of filtered_org_list">
            <div class="organization-item">
              <div class="organization-image">
                <img [src]="organization.logo" alt="">
              </div>
              <div class="organization-info">
                <h6>{{ organization.name }}</h6>
                <p>{{ organization.area.continent }}</p>
                <i class="fal fa-globe"></i><a [href]="organization.website"
                                               class="website-page">{{ 'academy.Visit_website'|translate }}</a>
              </div>
              <div class="organization-missions">
                <span *ngFor="let mission of organization.humanitarian_missions">{{ mission.name }}</span>
              </div>
              <div class="organization-specialty">
                <p>{{translationService.getTranslatedName(organization.specialty.translations, locale)}}
                <p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<h1>{{ 'academy.Videos_published'|translate }}</h1>
<div class="videos">
	<div class="videos-info">
		<p [innerHTML]="'academy.Want_to_do_some_modification'|translate: {support: ':support'}"></p>
	</div>


	<div class="lectures" *ngIf="videosPublished" infiniteScroll
       [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="300" (scrolled)="onCoursesScroll()">
		<div class="row">
			<div class="lecture-items col-12 col-md-6" *ngFor="let video of videosPublished">
				<app-lecture  [card]="true" [lecture]="video" [user]="user" [favoriteElement]="false"></app-lecture>
			</div>
		</div>
	</div>

  <app-loader *ngIf="loading"></app-loader>
</div>


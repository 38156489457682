<div class="modal-body modal-no-title modal-wide">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="modal-profile">
    <h2>Select a picture from the gallery</h2>
    <div class="gallery">
        <img  *ngFor="let img of cover_images" [src]="img.image" [alt]="img.image" (click)="selectedCoverFromGallery(img.index)">
    </div>
    <h2>Or choose your own</h2>
    <p>Your cover photo needs to be at least 960 width and 150 height and the file must be a jpeg or a png image.</p>
    <input type="file" id="uploader-picture" accept="image/*" (change)="onCoverImageFileChange($event)">
    <div class="button-set text-center">
      <button class="button button-orange" [disabled]="!(coverData || selectedCover)" (click)="setCoverImage()">Update my cover</button>
    </div>
  </div>
</div>

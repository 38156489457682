import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {LocaleService} from 'app/services/locale.service';
import {Feedback} from '../../models/feedback.model';


@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  @Input() feedback: Feedback;
  @Input() home: boolean;

  locale: any;

    constructor(public translationService: TranslationService, private localeService: LocaleService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale()
    }
}

import {Webinar} from '../models/webinar.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class WebinarService {
    private endpoint: string = '/webinars';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getWebinar(slug, params?: {}): Observable<Webinar> {
        const reqParams = {
            params: new HttpParams().set('all', '1').set('slug', slug).set('webinar_lectures', '1').set('webinar_dates', '1')
        };
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                reqParams.params = reqParams.params.append(key, params[key]);
            }
        }
        return this.httpClient.get<Webinar>(environment.apiURL + this.endpoint, reqParams).pipe(shareReplay());
    }

    webinarsByRegistrants(): Observable<Webinar> {
        return this.httpClient.get<Webinar>(environment.apiURLV2 + '/academy_courses/webinars_by_registrants').pipe(shareReplay());
    }
    webinarsByLiveViewers(): Observable<Array<Webinar>> {
        return this.httpClient.get<Array<Webinar>>(environment.apiURLV2 + '/academy_courses/webinars_by_live_viewers').pipe(shareReplay());
    }
    webinarsByReplayViewers(): Observable<Array<Webinar>> {
      const reqParams = {
        params: new HttpParams().set('course_speakers', '1')
    };
        return this.httpClient.get<Array<Webinar>>(environment.apiURLV2 + '/academy_courses/webinars_by_replay_viewers', reqParams)

    }

    getNextWebinar(params?: {}): Observable<Webinar> {
        const reqParams = {
            params: new HttpParams()
        };
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                reqParams.params = reqParams.params.append(key, params[key]);
            }
        }
        return this.httpClient.get<Webinar>(environment.apiURL + this.endpoint + '/next', reqParams).pipe(shareReplay());
    }

    registerWebinar(id: number, registerToken: string, register_from?: string): Observable<any> {
        let body;
        if (registerToken) {
            body = {
                register: registerToken,
                register_from: register_from
            };
        } else {
            body = {
                register_from: register_from
            };
        }
        return this.httpClient.put<any>(environment.apiURL + this.endpoint + '/' + id + '/register', body).pipe(shareReplay());
    }

    getUpcomingWebinars(afterDate?: moment.Moment, params?: {}): Observable<Webinar[]> {
        const reqParams = {
            params: afterDate ?
                new HttpParams().set('after', afterDate.format('YYYY-MM-DD')).set('webinar_dates', '1') :
                new HttpParams()
        };
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                reqParams.params = reqParams.params.append(key, params[key]);
            }
        }
        return this.httpClient.get<Webinar[]>(environment.apiURL + this.endpoint, reqParams).pipe(shareReplay());
    }

    getPreviousWebinars(): Observable<any> {
        return this.httpClient.get<any>(environment.apiURLV2 + '/webinars/previous', {
            params: new HttpParams().set('from_webinar', '1').set('all', '1').set('course_speakers', '1').set('course_sponsor', '1').set('course_grant', '1').set('company_profiles', '1')
        }).pipe(shareReplay());
    }

    addWebinarProgress(webinarId: number, position: number): Observable<any> {
        return this.httpClient.put(environment.apiURL + '/webinars/' + webinarId + '/track', null)
            .pipe(shareReplay());
    }

    addComment(webinarId: number, comment: string): Observable<any> {
        return this.httpClient.post(environment.apiURL + '/comments', {
            entity_type: 'session',
            entity_id: webinarId,
            content: comment,
            public: 1,
            anonymous: 0,
        }).pipe(shareReplay());
    }

    addQuestion(webinarId: number, question: string): Observable<any> {
        return this.httpClient.post(environment.apiURL + '/comments', {
            entity_type: 'session',
            entity_id: webinarId,
            content: question,
            is_question_live: 1,
            public: 1,
            anonymous: 0,
        }).pipe(shareReplay());
    }

    rateWebinar(webinarId: number, feedback: string, rating: number): Observable<any> {
        return this.httpClient.post(environment.apiURL + this.endpoint + '/' + webinarId + '/rate', {
            rating: rating,
            feedback: feedback
        }).pipe(shareReplay());
    }

    getWebinarLectures(webinarId: string): Observable<any> {
        return this.httpClient.get(environment.apiURL + this.endpoint + '/' + webinarId + '/lectures').pipe(shareReplay());
    }

    getWebinarLiveViewers(webinarId: number): Observable<any> {
        return this.httpClient.get(environment.apiURL + this.endpoint + '/' + webinarId + '/live_viewers');
    }

    isUpcomingMasterclass(): Observable<Webinar> {
      return this.httpClient.get<Webinar>(environment.apiURL + this.endpoint + '/masterclass');
    }

    getNextMasterclass(params?: {}): Observable<Webinar> {
        const reqParams = {
          params: new HttpParams()
        };
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            reqParams.params = reqParams.params.append(key, params[key]);
          }
        }
        return this.httpClient.get<Webinar>(environment.apiURL + this.endpoint + '/next_masterclass', reqParams).pipe(shareReplay());
    }
}

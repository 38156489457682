import {Component, OnInit} from '@angular/core';
import {WebpageService} from '../../../services/webpage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Webpage} from '../../../models/webpage.model';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from '../../../services/translation.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    providers: [WebpageService, TranslationService],
})
export class LandingComponent implements OnInit {
    downloadApp: boolean;
    logged: boolean;
    locale: string;
    landing: Webpage;

    constructor(private activatedRoute: ActivatedRoute,
                private webpageService: WebpageService,
                private router: Router,
                private authService: AuthService,
                private translateService: TranslateService,
                public translationService: TranslationService) {
    }

    ngOnInit() {
        if (environment.domain === 'ophthalmology') {
            this.translateService.setDefaultLang('fr');
            this.locale = 'fr';
        } else {
            this.translateService.setDefaultLang('en');
            this.locale = 'en';
        }
        this.downloadApp = false;
        this.logged = false;
        this.activatedRoute.params.subscribe(params => {
            const slug = params['slug'];
            this.webpageService.getBySlug(slug).subscribe(landing => {
                this.landing = new Webpage(landing);
                if (this.landing.view === 'download-app') {
                    this.downloadApp = true;
                    this.authService.isLoggedIn().subscribe(logged => {
                        this.logged = logged
                    });
                } else {
                    this.router.navigateByUrl('/not-found');
                }
            }, e => {
                this.router.navigateByUrl('/not-found');
            })
        });
    }

}

<h1>{{'account.Picture_cover'|translate}}</h1>
<div class="pictures">
	<div class="alert alert-success alert-dismissable text-center" *ngIf="updated">
		<button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="updated = false">×</button>
		{{ 'account.Update_success_flash'|translate }}
	</div>
	<div class="row">
		<div class="col-md-6">
			<h6 class="form-header">1. {{ 'account.Choose_picture'|translate }}</h6>
			<div class="preview-wrapper">
				<img class="rounded-circle" [src]="getProfilePicture()" alt="">
			</div>
      <div>
        <label class="custom-file" for="picture-file-upload">
          <input type="file" id="picture-file-upload" name="picture-file" (change)="onProfileImageFileChange($event)" class="custom-file-input">
          <span class="custom-file-button button">{{ 'account.Upload_picture'|translate }}</span>
          <span class="custom-file-name">{{(profileImage && profileImage?.name)||'file_name.png'}}</span>
        </label>
      </div>

		</div>
		<div class="col-md-6">
			<h6 class="form-header">2. {{ 'account.Crop_your_picture'|translate }}</h6>
			<div class="preview-wrapper rounded-circle">
        <img *ngIf="!profileImage" class="rounded-circle" [src]="getProfilePicture()" alt="">
        <img-cropper *ngIf="profileImage" #cropper [image]="data2" [settings]="cropperSettings"></img-cropper>
			</div>
			<a class="button button-gray" (click)="uploadProfileImage()">{{ 'account.Crop_your_picture'|translate }}</a>
		</div>
	</div>
	<div class="row">
		<div class="col-md-7 text-center">
			<h6 class="form-header">{{ 'account.Choose_cover_from_gallery'|translate }}</h6>
			<div class="slider-for preview-wrapper">
				<div *ngFor="let image of cover_images">
					<div class="preview" [ngStyle]="{'background-image':'url('+image.src+')'}"></div>
				</div>
			</div>
			<div class="slider-nav preview-wrapper">
				<div *ngFor="let image of cover_images">
					<div class="thumbnail" [ngStyle]="{'background-image':'url('+image.src+')'}" (click)="selectedCoverFromGallery=image.index+1"></div>
				</div>
			</div>
			<a class="button button-gray" (click)="setCoverFromGallery()">{{ 'account.Update_cover_from_gallery'|translate }}</a>
		</div>
		<div class="col-md-5">
			<h6 class="form-header">{{ 'account.Or_upload_own_cover'|translate }}</h6>
      <div>
        <label class="custom-file" for="picture-file-cover">
          <input type="file" id="picture-file-cover" name="picture-file" (change)="onCoverImageFileChange($event)" class="custom-file-input">
          <span class="custom-file-button button">{{ 'account.Upload_picture'|translate }}</span>
          <span class="custom-file-name">{{(coverImage && coverImage?.name)||'file_name.png'}}</span>
        </label>
      </div>
		</div>
	</div>
</div>

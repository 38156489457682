import {Component, OnInit} from '@angular/core';
import {User} from '../../../../models/user.model';
import {UserService} from '../../../../services/user.service';
import {AuthService} from '../../../../services/auth.service';
import {ConfirmationComponent} from '../../../modal/confirmation/confirmation.component';
import {PaymentService} from '../../../../services/payment.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MediaService} from '../../../../services/media.service';
import {CongressProduct} from '../../../../models/congress-product.model';
import {CongressService} from '../../../../services/congress.service';
import {CartService} from '../../../../services/cart.service';
import {AcademySubscriptionService} from '../../../../services/academy-subscription.service';
import {DateMomentService} from '../../../../services/date-moment.service';
import {TranslationService} from '../../../../services/translation.service';
import {locale} from "moment";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-account-membership-current-plan',
  templateUrl: './current-plan.component.html',
  styleUrls: ['./current-plan.component.scss'],
  providers: [
    CongressService,
    CartService,
    AcademySubscriptionService,
    UserService,
    MediaService,
    PaymentService
  ]
})
export class AccountMembershipCurrentPlanComponent implements OnInit {
  user: User;
  canceled: boolean;
  membership: any;
  showReSubscribeButton: boolean = false;
  mediasCount$: Observable<any>;
  products: Array<CongressProduct>;
  environment = environment;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private paymentService: PaymentService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mediaService: MediaService,
    private congressService: CongressService,
    private cartService: CartService,
    private academySubscriptionService: AcademySubscriptionService,
    public dateMomentService: DateMomentService,
    public translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this.getProducts();
    this.getMediasCount();
    this.getUser();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.congressProductId && params.type) {
        this.openCart(params.congressProductId, params.type);
      }
    });
    if (window.location.href.endsWith('canceled')) {
      this.canceled = true;
    }
  }

  cancelSubscription(membership) {
    const modalRef = this.modalService.open(ConfirmationComponent);
    modalRef.componentInstance.title = 'Do you really want to cancel the automatic renewal of your subscription ?';
    modalRef.result.then(result => {
      this.paymentService.cancelSubscription({
        user_product_id: membership.user_product_id,
        academy_subscription_id: membership.academy_subscription ? membership.academy_subscription.id : '',
        registration_id: membership.registration_id !== 'null' && membership.registration_id ? membership.registration_id : '',
        payment_id: membership.payment_id !== 'null' && membership.payment_id ? membership.payment_id : '',
        cart_id: membership.cart_id !== 'null' && membership.cart_id ? membership.cart_id : ''
      }).subscribe(result1 => {
        this.canceled = true;
        this.getUser();
      })
    }, error => {
    });
  }

  openCart(congressProductId, type) {
    if (this.user && this.user.id) {
      if (!this.cartService.getCurrentCartId()) {
        this.cartService.getCartId('academy_cart_id').subscribe(cartId => {
          this.cartService.setCurrentCartId(cartId);
          this.getSubscription(congressProductId, type);
        }, error => {
          this.cartService.deleteCurrentCartId();
        });
      } else {
        this.getSubscription(congressProductId, type);
      }
    } else {
      this.router.navigate(['/account/login'], {
        queryParams: {
          returnUrl: this.router.url + '?congressProductId=' + congressProductId + '&type=' + type
        }
      })
    }
  }

  checkCartId() {
    if (!this.cartService.getCurrentCartId()) {
      this.cartService.getCartId('academy_cart_id').subscribe(cartId => {
        this.cartService.setCurrentCartId(cartId);
      });
    }
  }

  getUser() {
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      this.userService.getUserMembershipStatus(this.user.id).subscribe(
        membership => {
          this.membership = membership
          this.expiresWithin7days(this.membership) ? this.showReSubscribeButton = true : false
        }
      );

    }

  }

  getSubscription(congressProductId, type) {
    this.academySubscriptionService.post({
      congress_product_id: congressProductId,
      type: type,
      cart_id: this.cartService.getCurrentCartId()
    }).subscribe(subscriptionId => {
      this.router.navigate(['/cart/subscribe']);
    }, error => {
      this.cartService.deleteCurrentCartId();
      this.checkCartId();
      setTimeout(() => {
        this.openCart(congressProductId, type);
      }, 500)

    })
  }

  getMediasCount() {
    this.mediasCount$ = this.mediaService.getAcademyMediasCount();
    this.mediasCount$.forEach((m) => {
      if (m.lecture) {
        m.lecture = Math.floor(m.lecture / 100) * 100;
      }
      if (m.physician) {
        m.physician = Math.floor(m.physician / 10) * 10;
      }
      if (m.other) {
        m.other = Math.floor(m.other / 10) * 10;
      }
    });
  }

  getProducts() {
    this.products = [];
    this.user = this.authService.getCurrentUser();
    this.congressService.getCongressProducts({all: 1}).subscribe(data => {
      this.getUser();
      data.forEach(m => {
        this.products.push(new CongressProduct(m));
      });

    });
  }

  expiresWithin7days(membership) {
    const dateEndMembership = Date.parse(membership.ending_date)
    const diff = dateEndMembership - Date.now()
    const diffInDays = diff / (1000 * 60 * 60 * 24)

    if (diffInDays < 7) {
      return true
    }

    return false
  }

}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class ExternalApiService {
    private endpoint: string = '/omega-zeta-3d-tech';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    licenseRecover(email) {
        return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/license-recover', {email: email}).pipe(shareReplay());
    }
}

<ng-template #fromGoogle let-c="close" let-d="dismiss">
  <div class="modal-body modal-no-title modal-congresses">
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <h2>{{ 'explore.Modal_google_title'|translate }}</h2>
    <p>{{ 'explore.Modal_google_content'|translate }}</p>
    <div class="row">
      <div class="col-md-4" *ngFor="let congress of congresses">
        <div class="congress"
             (click)="openAttendCongress(congress.slug)"
             [style.background]="sanitizeCssProperty(congress.backgroundAccountBackUrl())"
             [style.background-size]="'cover'">
                     <span>
                        {{translationService.getTranslatedAttribute('complete_name', congress.translations, locale)}}
                     </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div class="page events">
  <app-section-top [title]="'premium.IMCAS_Explore'" [subtitle]="'academy.Discover_the_world'|translate"
                   [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-explore.jpg'"></app-section-top>
  <div class="content">
    <div class="page-content">
      <div class="page-introduction">
        <h3>{{ 'explore.Worldwide_agenda'|translate }}</h3>
        <p>{{ 'explore.Introduction_agenda'|translate }}</p>
      </div>
      <div class="filters">
        <div class="row">
          <div class="filters-themes col-12 col-md-6">
            <select id="theme" #countryFilter (change)="filterByCountry(countryFilter.value)"
                    [ngModel]="country_id" name="theme" class="filters-select">
              <option value="0" selected>{{'explore.Filter_by_country'|translate}}</option>
              <option *ngFor="let country of countries_list" [value]="country.id">
                {{translationService.getTranslatedName(country.translations, locale)}}
              </option>
            </select>
          </div>
          <div class="filters-selection col-12 col-md-6">
            <div class="form-check text-right pull-right">
              <h6 class="text-right">{{ 'explore.Label_partner_toggle'|translate }}</h6>
              <span>{{ 'evaluation.No'|translate }}</span>
              <input type="checkbox" [checked]="show_partners" (change)="clickToggle()"
                     class="checkbox-switch" id="display-toggle">
              <label for="display-toggle"></label>
              <span>{{ 'evaluation.Yes'|translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <app-loader *ngIf="loading"></app-loader>
      <div class="event-list" *ngFor="let group of grouped_list">
        <div class="event-header">
          <h3>{{group.month + ' ' + group.year}}</h3>
        </div>
        <!--   IN DESKTOP VERSION           -->
        <div class="event-items list-desktop">
          <div class="event-column">
            <div class="event-item"
                 *ngFor="let event of getEventsPaired(group.events,true)"
                 [class.has-badge]="event.tag"
                 [ngClass]="{'event-partner':event.banner_file != null}"
            >
              <app-single-event [event]="event" [locale]="locale"></app-single-event>
            </div>
          </div>
          <div class="event-column">
            <div class="event-item"
                 *ngFor="let event of getEventsPaired(group.events,false)"
                 [class.has-badge]="event.tag"
                 [ngClass]="{'event-partner':event.banner_file != null}"
            >
              <app-single-event [event]="event" [locale]="locale"></app-single-event>
            </div>
          </div>

        </div>

        <!--                 IN TABLET/MOBILE VERSION           -->
        <div class="event-items list-mobile">
          <div class="event-item"
               *ngFor="let event of group.events"
               [class.has-badge]="event.tag"
               [ngClass]="{'event-partner':event.banner_file != null}"
          >
            <app-single-event [event]="event" [locale]="locale"></app-single-event>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {LocaleService} from '../../../../services/locale.service';
import {environment} from '../../../../../environments/environment';


@Component({
  selector: 'app-contributor',
  templateUrl: './contributor.component.html',
  styleUrls: ['./contributor.component.scss']
})
export class TermsOfUseContributorComponent implements OnInit {
    env = environment;
    locale: any;

    constructor(public localeService: LocaleService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
    }

}

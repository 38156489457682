import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class PartnershipsService {
  private partnershipsEndpoint: string = environment.apiURL + '/partnerships';

  constructor(private httpClient: HttpClient) { }

  public get(congress_id: number | string, type: string, user_specialty?: boolean, user_country?: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.set('all', '1');
    params = params.set('type', '' + type);
    if (congress_id) {
      params = params.set('congress_id', '' + congress_id);
    }
    if (user_country) {
      params = params.set('user_country', '1');
    }
    if(user_specialty) {
      params = params.set('user_specialty', '1');
    }
    return this.httpClient.get<any>(this.partnershipsEndpoint, {params: params}).pipe(shareReplay());
  }

}

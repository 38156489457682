import {Country} from './../models/country.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class CountryService {
    private endpoint: string = '/countries';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getCountries(): Observable<Country[]> {
        return this.httpClient.get<Country[]>(environment.apiURL + this.endpoint, {
            params: new HttpParams().set('all', '1')
        }).pipe(shareReplay());
    }
}

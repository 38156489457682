<div class="page login">
  <section class="section section-login">
    <div class="content">
      <div *ngIf="this.certificateStatus && !this.passCertificatesForced && this.certificateStatus === 'not_verified' && (user.specialty_category == 'physician' || user.specialty_category == 'non physician')">
        <app-diploma-upload (statusOutput)="updateCertificateStatus($event)"></app-diploma-upload>
        <div class="button-set text-right">
          <br/>
          <br/>
          <button (click)="forcePassCertificates()" class="button">{{'academy.Skip_this_step'|translate}} >></button>
        </div>
      </div>
      <div *ngIf="(this.certificateStatus && this.certificateStatus !== 'not_verified') || this.passCertificatesForced || (user.specialty_category != 'physician' && user.specialty_category != 'non physician')">
        <app-main-interests (updated)="updatedUser($event)" [from]="'create'"></app-main-interests>
      </div>
    </div>
  </section>
</div>

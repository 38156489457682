<div class="biostatement">
  <ng-template #bioinfo>
    <div class="modal-body modal-no-title">
      <button type="button" class="close" aria-label="Close">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div class="biostatement-about">
        <h2>{{ 'profile.Bio_guidelines'|translate }}</h2>
        <p>{{ 'profile.Please_write_short'|translate }} <br><strong>- {{ 'profile.Academic_back'|translate }}</strong> <br><strong>- {{ 'profile.Experience_pro'|translate }}</strong></p>
        <br>
        <h3>{{ 'profile.May_include'|translate }}</h3>
        <ul class="list">
          <li>{{ 'profile.Educational_act'|translate }}</li>
          <li>{{ 'profile.Publications'|translate }}</li>
          <li>{{ 'profile.Titles_and_diplomas'|translate }}</li>
          <li>{{ 'profile.Memberships'|translate }}</li>
          <li>{{ 'profile.Spe_interests'|translate }}</li>
          <li>{{ 'profile.Achievement'|translate }}</li>
          <li>{{ 'profile.Employment'|translate }}</li>
        </ul>
        <p>{{ 'profile.Keep_bio_updated'|translate }}</p>
        <br>
        <h3>{{ 'profile.Fictional_bio'|translate }}</h3>
        <h4>{{ 'premium.Physicians'|translate }}</h4>
        <p>{{ 'profile.Fake_bio_line1'|translate }}</p>
        <p>{{ 'profile.Fake_bio_line2'|translate }}</p>
        <p>{{ 'profile.Fake_bio_line3'|translate }}</p>
        <p>{{ 'profile.Fake_bio_line4'|translate }}</p>
        <h3>{{ 'profile.Industrials'|translate }}</h3>
        <p>{{ 'profile.Fake_bio_industry1'|translate }}
        </p>
        <p>{{ 'profile.Fake_bio_industry2'|translate }}</p>
        <p>{{ 'profile.Fake_bio_industry3'|translate }}</p>
      </div>
    </div>
  </ng-template>
  <div class="alert alert-success alert-dismissable" *ngIf="updated">
    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="updated = false">×</button>
    {{ 'account.Update_success_flash'|translate }}
  </div>
  <div class="biostatement-text">
    <ejs-richtexteditor [(ngModel)]="newUser.biostatement_en" [toolbarSettings]="tools"></ejs-richtexteditor>
  </div>
  <div class="biostatement-info text-right">
    <p>{{ 'account.Form_bio_xxx_characters_maximum'|translate: {limit:2200} }} ({{ 'abstract.xxx_characters_left'|translate: {chars:getRemainingText(2200, newUser.biostatement_en)} }}) <i (click)="open(bioinfo)" class="fa fa-question-circle-o" aria-hidden="true"></i></p>
  </div>
  <div class="biostatement-button text-center">
    <a (click)="updateBiostatement()" class="button button-orange">{{ 'account.Update_your_biostatement'|translate }}</a>
  </div>
</div>

import {DateMomentService} from 'app/services/date-moment.service';
import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {CourseService} from 'app/services/course.service';
import {MediaService} from 'app/services/media.service';
import {AcademyMedia} from '../../../../models/academy-media.model';
import {LocaleService} from '../../../../services/locale.service';
import {Course} from '../../../../models/course.model';
import {Subject} from 'rxjs/index';
import {distinctUntilChanged, switchMap} from 'rxjs/internal/operators';


@Component({
    selector: 'account-favorites',
    templateUrl: './favorites.component.html',
    styleUrls: ['./favorites.component.scss'],
    providers: [
        UserService,
        MediaService,
        CourseService
    ]
})
export class AccountFavoritesComponent implements OnInit {
    user: User;
    favorited: any;
    academyMedias: AcademyMedia[];
    academyMedias_filtered: AcademyMedia[];
    courses: Course[];
    courses_filtered: Course[];
    locale: string;
    searchTermFav: string;
    currentTab: string = 'courses';
    loading: boolean = false;
    searchTerm$ = new Subject<string>();

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                public translationService: TranslationService,
                public dateMomentService: DateMomentService,
                public localeService: LocaleService,
                private courseService: CourseService,
                private mediaService: MediaService) {
    }

    filterFavorites() {
            this.courses_filtered = this.courses.filter(obj => {
                if (obj.translations) {
                    for (let i = 0; i < obj.translations.length; i++) {
                        if (typeof this.searchTermFav !== 'undefined' && obj.translations[i].title.toLowerCase().indexOf(this.searchTermFav.toLowerCase()) !== -1) {
                            return true;
                        } else if (typeof this.searchTermFav === 'undefined') {
                            return true;
                        }
                    }
                }
                return false;
            });
            this.academyMedias_filtered = this.academyMedias.filter(obj => {
                if (obj.media && obj.media.translations) {
                    for (let i = 0; i < obj.media.translations.length; i++) {
                        if (typeof this.searchTermFav !== 'undefined' && obj.media.translations[i].title.toLowerCase().indexOf(this.searchTermFav.toLowerCase()) !== -1) {
                            return true;
                        } else if (typeof this.searchTermFav === 'undefined') {
                            return true;
                        }
                    }
                }
                if (obj.media && obj.media.speakers) {
                    for (let i = 0; i < obj.media.speakers.length; i++) {
                        if (typeof this.searchTermFav !== 'undefined' && (obj.media.speakers[i].first_name.toLowerCase().indexOf(this.searchTermFav.toLowerCase()) !== -1 ||
                            obj.media.speakers[i].last_name.toLowerCase().indexOf(this.searchTermFav.toLowerCase()) !== -1)) {
                            return true;
                        } else if (typeof this.searchTermFav === 'undefined') {
                            return true;
                        }
                    }
                }
                return false;
            });

    }

    ngOnInit() {
        this.loading = true;
        this.locale = this.localeService.currentLocale();
        try {
            this.user = JSON.parse(localStorage.getItem('currentUser'));
        } catch (e) {}
        this.searchTerm$
            .pipe(distinctUntilChanged(), switchMap(term => {
                this.loading = true;
                return this.userService.getFavorited(this.user.id, term)
            }))
            .subscribe(
                favorited => {
                    this.academyMedias = [];
                    favorited.academy_medias.forEach(n => {
                        const academyMediaObj: AcademyMedia = new AcademyMedia(n);
                        this.academyMedias.push(academyMediaObj);
                    });
                    this.academyMedias_filtered = this.academyMedias;
                    this.courses = [];
                    favorited.courses.forEach(n => {
                        const courseObj: Course = new Course(n);
                        this.courses.push(courseObj);
                    });
                    this.courses_filtered = this.courses;
                    this.favorited = favorited;
                    this.loading = false;
                }
            );
        this.searchTerm$.next('');
    }

    removeCourseFromFavorites(course) {
        if (this.user && this.user.id) {
            this.courseService.addCourseToFavorite(course.id).subscribe(
                success => {
                    this.courses = this.courses.filter(obj => obj.id !== course.id);
                    this.filterFavorites();
                }
            );
        } else {
            this.router.navigate(['/', 'account', 'login']);
        }
    }

    removeMediaFromFavorites(media) {
        if (this.user) {
            this.mediaService.addMediaToFavorite(media.id).subscribe(
                success => {
                    this.academyMedias = this.academyMedias.filter(obj => obj.id !== media.id);
                    this.filterFavorites();
                });
        } else {
            this.router.navigate(['/', 'account', 'login']);
        }
    }
}

import {Country} from 'app/models/country.model';
import {Translation} from './translation.model';

export class ScientificSociety {
  id: number;
  acronym: string;
  country: Country;
  name: string;
  logo_url: string;
  website: string;
  facebook: string;
  translations: Translation[];


  constructor(obj?: any) {
    this.id                               = obj && obj.id || null;
    this.acronym                          = obj && obj.acronym;
    this.name                             = obj && obj.name;
    this.country                          = obj && obj.country;
    this.logo_url                         = obj && obj.logo_url;
    this.website                          = obj && obj.website;
    this.facebook                         = obj && obj.facebook;
    this.translations                     = obj && obj.translations;
  }
}

<div class="blog-page page">
    <div class="blog-carousel academy-slider" *ngIf="headerArticles$ | async as headerArticles">
        <div class="blog-carousel-item" *ngFor="let article of headerArticles">
            <div class="blog-carousel-bg"
                 [ngStyle]="{'background-image': 'url(' + article.pictures_url.header + ')'}"></div>
            <div class="blog-carousel-bg-overlay"></div>
            <div class="blog-carousel-caption d-flex">
                <div class="content align-self-center">
                    <h3>{{article.title}}</h3>
                    <p>{{article.short_description}}</p>
                    <a [routerLink]="['/blog', article.id , article.slug]"
                       class="button">{{'blog.Read_more'|translate}}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="filters">
        <div class="content">
            <div class="row">
                <div class="col-12 blog-search">
                    <app-search-ui
                            [articlesIndex]="true"
                    ></app-search-ui>
                </div>
                <div class="filters-themes col-12 col-md-6">
                    <select *ngIf="themes"
                            id="theme"
                            name="theme"
                            class="filters-select"
                            [ngModel]="selectedTheme"
                            (ngModelChange)="themeChange($event)">
                        <option [ngValue]="undefined">{{'evaluation.Select_a_theme'|translate}}</option>
                        <option *ngFor="let option of themes"
                                [ngValue]="option">
                            {{translationService.getTranslatedName(option['translations'], locale)}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <app-loader *ngIf="showLoading"></app-loader>
    <div class="blog-content content" *ngIf="pagedArticles && pagedArticles.length > 0 && !showLoading">
        <h2>{{'blog.Recent_posts'|translate}}</h2>
        <div class="post-list row">
            <div class="col-md-4 col-sm-6 col-12 post-list-col"
                 *ngFor="let article of pagedArticles[currentPage-1].articles">
                <app-blog-post-item [article]="article" [label]="false"></app-blog-post-item>
            </div>
        </div>
        <div>
            <ul class="pagination justify-content-center" *ngIf="pagesNumbers.length > 1">
                <li class="page-item" [ngClass]="{disabled: currentPage === 1}" *ngIf="pagedArticles && currentPage">
                    <a class="page-link" (click)="currentPage = currentPage - 1" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">{{'pagination.previous'|translate}}</span>
                    </a>
                </li>
                <ng-container *ngFor="let pageNr of pagesNumbers">
                    <li class="page-item" [ngClass]="{active: currentPage === pageNr}"
                        *ngIf="pagedArticles && currentPage && (currentPage === 1 ? (pageNr >= currentPage - 1 && pageNr <= currentPage + 4) :
					(currentPage === pagedArticles.length ? (pageNr >= currentPage - 4 && pageNr <= currentPage + 3) :
					(pageNr >= currentPage - 3 && pageNr <= currentPage + 3)))">
                        <a class="page-link" (click)="currentPage = pageNr">{{pageNr}}</a>
                    </li>
                </ng-container>
                <li class="page-item" [ngClass]="{disabled: currentPage === pagedArticles.length}"
                    *ngIf="pagedArticles && currentPage">
                    <a class="page-link" (click)="currentPage = currentPage + 1" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">{{'pagination.next'|translate}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="blog-contribute">
        <div class="content">
            <h5>{{'blog.Want_to_contribute'|translate}}</h5>
            <a [routerLink]="['/account', 'articles']" class="button button-orange">{{'blog.Yes_contribute_now'|translate}}</a>
        </div>
    </div>
</div>

<h1 *ngIf="currentTab ==='courses'">{{ 'account.Favorites_courses'|translate}}</h1>
<h1 *ngIf="currentTab !=='courses'">{{ 'account.Favorites_lectures'|translate }}</h1>
<div class="searchbar">
	<input type="text" [placeholder]="'account.Search_favorites'|translate" [(ngModel)]="searchTermFav" (keyup)="filterFavorites()" />
</div>
<app-loader *ngIf="loading"></app-loader>

<ul class="nav nav-tabs nav-fill" *ngIf="!loading">
	<li class="nav-item" *ngIf="favorited && favorited.courses">
		<a class="nav-link" [ngClass]="{active: currentTab==='courses'}" (click)="currentTab='courses'">
			{{'academy.courses'|translate}} <span>{{courses_filtered?.length}}</span></a>
	</li>
	<li class="nav-item" *ngIf="favorited && favorited.academy_medias">
		<a class="nav-link" [ngClass]="{active: currentTab==='lectures'}" (click)="currentTab='lectures'">
			{{'academy.Lectures'|translate}} <span>{{academyMedias_filtered?.length}}</span></a>
	</li>
</ul>
<div class="courses" *ngIf="favorited && favorited.courses && currentTab=='courses' && !loading">
	<div class="row" *ngIf="courses_filtered.length > 0">
		<div class="course-items col-6" *ngFor="let course of courses_filtered">
			<app-course-element [course]="course"></app-course-element>
			<div class="course-items-remove">
				<a (click)="removeCourseFromFavorites(course)"><i class="fa fa-trash-o" aria-hidden="true"></i> <span>{{ 'account.Remove_favorite'|translate }}</span></a>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="courses_filtered.length == 0">
		<div class="col-12">
			<p>{{ 'account.No_favorites'|translate }}</p>
			<button class="btn button-orange" [routerLink]="'/library/courses'">{{ 'academy.Browse_through'|translate }}</button>
		</div>
	</div>
	</div>
<div class="lectures" *ngIf="favorited && favorited.academy_medias && currentTab=='lectures' && !loading">
	<div class="row">
		<div class="lecture-items col-12 col-md-6" *ngFor="let lecture of academyMedias_filtered">
			<app-lecture  [card]="true" [lecture]="lecture" [favoriteElement]="false"></app-lecture>
			<div class="lecture-items-remove">
				<a (click)="removeMediaFromFavorites(lecture)"><i class="fa fa-trash-o" aria-hidden="true"></i> <span>{{ 'account.Remove_favorite'|translate }}</span></a>
			</div>
		</div>
	</div>
</div>
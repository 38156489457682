<div class="post-item" (click)="goToPost(article)">
    <div class="lecture-badges" *ngIf="article && article.sponsored == 1">
        <span class="sponsored">{{'academy.Sponsored'|translate}}</span>
    </div>
    <div class="post-thumb">
        <div *ngIf="label" class="post-thumb-filter"></div>
        <span *ngIf="label" class="category-label">{{ 'blog.Blog_article' | translate}}</span>
        <img [src]="article.pictures_url.thumbnail">
    </div>
    <div class="post-title">
        <h6>{{article.title}}</h6>
    </div>
    <div class="post-author">
        <p *ngIf="article.authors[0]">
            {{article.authors[0].title + ' ' + article.authors[0].first_name + ' ' + article.authors[0].last_name}}
            <ng-container *ngIf="article.themes[0]">[{{translationService.getTranslatedAttribute('name',
                article.themes[0].translations, locale)}}]
            </ng-container>
        </p>
        <p>{{'blog.Published_on' | translate}} {{article.publication_date | toDateObj | date:"longDate" }}</p>
    </div>
</div>

<div class="page container">
  <p><a (click)="goBack()" class="block-link"><i class="fal fa-chevron-left"></i> {{ 'profile.Back'|translate }}</a></p>
  <h1>{{ 'cart.Your_cart' | translate }}</h1>
  <div class="section-header">
    <h3>{{ 'cart.Products'|translate }}</h3>
  </div>
  <app-academy-subscriptions-recap [user]="user" [cart]="cart" [locale]="locale" [withDeliveryFees]="withDeliveryFees"
                                   [withDeliveryFeesValidated]="withDeliveryFeesValidated"
                                   [giftProduct]="isGiftProduct"
                                   *ngIf="!cartLoading"
                                   (newCart)="getCart()" [readOnly]="false"></app-academy-subscriptions-recap>
  <div *ngIf="cart && cart.total && cart.total.length === 0" class="cart-empty">
    {{ 'cart.No_product_in_cart'|translate }}
  </div>
  <app-loader *ngIf="cartLoading"></app-loader>
  <a class="block-link"
     *ngIf="!promotionalCodeBox && cart && cart.total && cart.total.length !== 0 && cart.promotional_codes.length === 0 && cart.vads_page_action != 'REGISTER_SUBSCRIBE' && !marketplace"
     (click)="promotionalCodeBox = !promotionalCodeBox">{{ 'cart.You_have_a_promotional_code' | translate }}</a>
  <div class="cart-promotional-code"
       *ngIf="(promotionalCodeBox ||  (cart && cart.promotional_codes.length !== 0)) && cart && cart.total && cart.total.length !== 0 && cart.vads_page_action != 'REGISTER_SUBSCRIBE'">
    <h6>{{ 'cart.Promotional_code' | translate }}</h6>
    <ul class="cart-promotional-code-applied">
      <li *ngFor="let promotional_code of cart.promotional_codes; index as i">{{ promotional_code.code}}
        - {{ translationService.getTranslatedAttribute('subtitle', promotional_code.translations, locale) }}</li>
    </ul>
    <div class="container">
      <div class="row" *ngIf="cart.promotional_codes.length === 0">
        <div class="col-75">
          <input type="text" placeholder="Enter code" [(ngModel)]="promotional_code" class="form-control">
          <span [class.error]="promoError" *ngIf="promoError">{{ 'cart.Wrong_promotional_code_flash'|translate }}</span>
        </div>
        <div class="col-25">
          <button class="button button-blue" (click)="applyPromoCode()">{{ 'cart.Apply'|translate }}</button>
        </div>
      </div>
      <div class="row" *ngIf="cartIsFree()">
        <button class="button button-orange"
                (click)="validateFreeCart()">{{ 'cart.Validate_free_cart' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="block-link" *ngIf="billingData && !cartIsFree() && env.domain === 'aesthetics' && oneProductIsAcademy">
    <h3>{{ 'cart.Options_title' | translate }}</h3>
    <p class="small">{{ 'cart.Options_para' | translate }}</p>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="option_injectables" [checked]="optionInjectables"
             (change)="optionInjectables = !optionInjectables">
      <label class="custom-control-label" for="option_injectables">{{ 'cart.Options_injectables' | translate }}</label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="option_laser" [checked]="optionLaser"
             (change)="optionLaser = !optionLaser">
      <label class="custom-control-label" for="option_laser">{{ 'cart.Options_lasers' | translate }}</label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="option_threads" [checked]="optionThreads"
             (change)="optionThreads = !optionThreads">
      <label class="custom-control-label" for="option_threads">{{ 'cart.Options_threads' | translate }}</label>
    </div>
  </div>
  <form action="" #deliveryForm="ngForm" *ngIf="deliveryData && withDeliveryFees">
    <div class="section-header" *ngIf="deliveryData">
      <h3>{{ 'cart.Delivery_data'|translate }}</h3>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-header">{{ 'account.First_name'|translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="deliveryData.delivery_first_name" name="first_name" (ngModelChange)="checkFirstName(deliveryData.delivery_first_name, 'delivery')"
                 placeholder="" required [class.valid]="deliveryData.delivery_first_name && !deliveryFirstNameInvalid"
                 [class.unvalid]="!deliveryData.delivery_first_name || deliveryFirstNameInvalid">
          <i class="fal" [class.fa-check-circle]="deliveryData.delivery_first_name && !deliveryFirstNameInvalid"
             [class.fa-times-circle]="!deliveryData.delivery_first_name || deliveryFirstNameInvalid" [class.valid]="deliveryData.delivery_first_name && !deliveryFirstNameInvalid"></i>
          <span [class.error]="deliveryFirstNameInvalid"> Firstname must be contains only latin letters <!--COMING SOON: account.No_latin_firstname--></span>
          <span [class.error]="!deliveryFirstNameInvalid && !deliveryData.delivery_first_name">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-header">{{ 'account.Last_name'|translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="deliveryData.delivery_last_name" name="last_name"
                 placeholder="" required [class.valid]="deliveryData.delivery_last_name && !deliveryLastNameInvalid" (ngModelChange)="checkLastName(deliveryData.delivery_last_name, 'delivery')"
                 [class.unvalid]="!deliveryData.delivery_last_name || deliveryLastNameInvalid">
          <i class="fal" [class.fa-check-circle]="deliveryData.delivery_last_name && !deliveryLastNameInvalid"
             [class.fa-times-circle]="!deliveryData.delivery_last_name || deliveryLastNameInvalid" [class.valid]="deliveryData.delivery_last_name && !deliveryLastNameInvalid"></i>
          <span [class.error]="deliveryLastNameInvalid"> Lastname must be contains only latin letters <!--COMING SOON: account.No_latin_lastname--></span>
          <span [class.error]="!deliveryLastNameInvalid && !deliveryData.delivery_last_name">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Company_name'|translate }}</label>
          <input type="text" class="form-control" name="company_name" [(ngModel)]="deliveryData.delivery_company_name"
                 placeholder="">
        </div>
      </div>

      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Address_l1'|translate }}</label>
          <input type="text" class="form-control" name="address_l1" [(ngModel)]="deliveryData.delivery_address_l1"
                 placeholder="" required [class.valid]="deliveryData.delivery_address_l1"
                 [class.unvalid]="!deliveryData.delivery_address_l1">
          <i class="fal" [class.fa-check-circle]="deliveryData.delivery_address_l1"
             [class.fa-times-circle]="!deliveryData.delivery_address_l1" [class.valid]="deliveryData.delivery_address_l1"></i>
          <span [class.error]="!deliveryData.delivery_address_l1">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Address_l2'|translate }} ({{ 'plan_your_imcas.Optional'|translate }}
            )</label>
          <input type="text" class="form-control" name="address_l2" [(ngModel)]="deliveryData.delivery_address_l2"
                 placeholder="" [class.valid]="deliveryData.delivery_address_l2">
          <i class="fal" [class.fa-check-circle]="deliveryData.delivery_address_l2"
             [class.valid]="deliveryData.delivery_address_l2"></i>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-header">{{ 'account.Zip'|translate }}</label>
          <input type="text" class="form-control" name="zip" [(ngModel)]="deliveryData.delivery_zip" placeholder=""
                 required
                 [class.valid]="deliveryData.delivery_zip" [class.unvalid]="!deliveryData.delivery_zip">
          <i class="fal" [class.fa-check-circle]="deliveryData.delivery_zip" [class.fa-times-circle]="!deliveryData.delivery_zip"
             [class.valid]="deliveryData.delivery_zip"></i>
          <span [class.error]="!deliveryData.delivery_zip">{{ 'validation.Field_required'|translate }}</span>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-header">{{ 'account.City'|translate }}</label>
          <input type="text" class="form-control" name="city" [(ngModel)]="deliveryData.delivery_city" placeholder=""
                 required
                 [class.valid]="deliveryData.delivery_city" [class.unvalid]="!deliveryData.delivery_city">
          <i class="fal" [class.fa-check-circle]="deliveryData.delivery_city"
             [class.fa-times-circle]="!deliveryData.delivery_city"
             [class.valid]="deliveryData.delivery_city"></i>
          <span [class.error]="!deliveryData.delivery_city">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-header">{{ 'account.Country'|translate }}</label>
          <select *ngIf="countries$ | async as countries"
                  id="delivery-country"
                  name="country"
                  class="form-control"
                  [ngModel]="selectedCountryDelivery"
                  (ngModelChange)="countryDeliveryChange($event)"
                  [class.valid]="selectedCountryDelivery"
                  [class.unvalid]="!selectedCountryDelivery">
            <option *ngFor="let option of countries"
                    [ngValue]="option">
              {{translationService.getTranslatedName(option['translations'], locale)}}
            </option>
          </select>
          <i class="fal" [class.fa-check-circle]="selectedCountryDelivery"
             [class.fa-times-circle]="!selectedCountryDelivery"
             [class.valid]="selectedCountryDelivery"></i>
          <span [class.error]="!selectedCountryDelivery">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Phone'|translate }} ({{ 'plan_your_imcas.Optional'|translate }}
            )</label>
          <input type="tel" class="form-control" name="phone" [(ngModel)]="deliveryData.phone" placeholder=""
                 [class.valid]="deliveryData.phone">
          <i class="fal" [class.fa-check-circle]="deliveryData.phone" [class.valid]="deliveryData.phone"></i>
        </div>
      </div>

    </div>
    <div class="form-button">
      <button class="button button-orange"
              [disabled]="!deliveryForm.valid || billingFirstNameInvalid || billingLastNameInvalid || !selectedCountryDelivery"
              (click)="proceedDelivery()">{{ 'cart.Update_delivery_data'|translate }}</button>
    </div>
  </form>
  <form action="" #billingForm="ngForm" *ngIf="billingData && !cartIsFree() && (!withDeliveryFees || (withDeliveryFees && withDeliveryFeesValidated))">
    <div class="section-header" *ngIf="billingData">
      <h3>{{ 'cart.Billing_data'|translate }}</h3>
    </div>
    <div class="form-radio">
      <label class="form-header">{{ 'account.Gender'|translate }}</label>
      <div class="custom-control custom-radio">
        <input type="radio" name="gender" [(ngModel)]="billingData.gender" id="gender-m"
               class="custom-control-input" value="m">
        <label class="custom-control-label" for="gender-m">{{ 'account.Male'|translate }}</label>
      </div>
      <div class="custom-control custom-radio">
        <input type="radio" name="gender" [(ngModel)]="billingData.gender" id="gender-f"
               class="custom-control-input" value="f">
        <label class="custom-control-label" for="gender-f">{{ 'account.Female'|translate }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-header">{{ 'account.First_name'|translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="billingData.first_name" name="first_name"
                 placeholder="" required [class.valid]="billingData.first_name && !billingFirstNameInvalid" (ngModelChange)="checkFirstName(billingData.first_name, 'billing')"
                 [class.unvalid]="!billingData.first_name || billingFirstNameInvalid">
          <i class="fal" [class.fa-check-circle]="billingData.first_name && !billingFirstNameInvalid"
             [class.fa-times-circle]="!billingData.first_name || billingFirstNameInvalid" [class.valid]="billingData.first_name && !billingFirstNameInvalid"></i>
          <span [class.error]="billingFirstNameInvalid">Firstname must be contains only latin letters <!--COMING SOON: account.No_latin_firstname--></span>
          <span [class.error]="!billingFirstNameInvalid && !billingData.first_name">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-header">{{ 'account.Last_name'|translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="billingData.last_name" name="last_name"
                 placeholder="" required [class.valid]="billingData.last_name && !billingLastNameInvalid" (ngModelChange)="checkLastName(billingData.last_name, 'billing')"
                 [class.unvalid]="!billingData.last_name || billingLastNameInvalid">
          <i class="fal" [class.fa-check-circle]="billingData.last_name && !billingLastNameInvalid"
             [class.fa-times-circle]="!billingData.last_name || billingLastNameInvalid" [class.valid]="billingData.last_name && !billingLastNameInvalid"></i>
          <span [class.error]="billingLastNameInvalid">Lastname must be contains only latin letters <!--COMING SOON: account.No_latin_lastname--> </span>
          <span [class.error]="!billingLastNameInvalid && !billingData.last_name">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Specialty'|translate }}</label>
          <select *ngIf="specialities$ | async as specialities"
                  id="specialty"
                  name="specialty"
                  class="form-control"
                  [ngModel]="selectedSpecialty"
                  (ngModelChange)="specialtyChange($event)"
                  [class.valid]="selectedSpecialty"
                  [class.unvalid]="!selectedSpecialty">
            <option *ngFor="let option of specialities"
                    [ngValue]="option">
              {{translationService.getTranslatedName(option['translations'], locale)}}
            </option>
          </select>
          <i class="fal" [class.fa-check-circle]="selectedSpecialty"
             [class.fa-times-circle]="!selectedSpecialty"
             [class.valid]="selectedSpecialty"></i>
          <span [class.error]="!selectedSpecialty">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="company" [checked]="company"
             (change)="company = !company">
      <label class="custom-control-label" for="company">{{ 'account.Are_you_a_company'|translate }}</label>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group" *ngIf="company">
          <label class="form-header">{{ 'account.Company_name'|translate }}</label>
          <input type="text" class="form-control" name="company_name" [(ngModel)]="billingData.company_name"
                 placeholder="" [class.valid]="billingData.company_name"
                 [class.unvalid]="!billingData.company_name">
          <i class="fal" [class.fa-check-circle]="billingData.company_name"
             [class.fa-times-circle]="!billingData.company_name" [class.valid]="billingData.company_name"></i>
          <span [class.error]="!billingData.company_name">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group" *ngIf="company">
          <label class="form-header">{{ 'account.Company_function'|translate }}</label>
          <input type="text" class="form-control" name="company_function"
                 [(ngModel)]="billingData.company_function" placeholder=""
                 [class.valid]="billingData.company_function" [class.unvalid]="!billingData.company_function">
          <i class="fal" [class.fa-check-circle]="billingData.company_function"
             [class.fa-times-circle]="!billingData.company_function"
             [class.valid]="billingData.company_function"></i>
          <span [class.error]="!billingData.company_function">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group" *ngIf="company">
          <label class="form-header">{{ 'account.Company_vat' | translate }}</label>
          <input type="text" class="form-control" name="company_vat" [(ngModel)]="billingData.company_vat"
                 placeholder="" [class.valid]="billingData.company_vat"
                 [class.unvalid]="!billingData.company_vat">
          <i class="fal" [class.fa-check-circle]="billingData.company_vat"
             [class.fa-times-circle]="!billingData.company_vat" [class.valid]="billingData.company_vat"></i>
          <span [class.error]="!billingData.company_vat">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Address_l1'|translate }}</label>
          <input type="text" class="form-control" name="address_l1" [(ngModel)]="billingData.address_l1"
                 placeholder="" required [class.valid]="billingData.address_l1"
                 [class.unvalid]="!billingData.address_l1">
          <i class="fal" [class.fa-check-circle]="billingData.address_l1"
             [class.fa-times-circle]="!billingData.address_l1" [class.valid]="billingData.address_l1"></i>
          <span [class.error]="!billingData.address_l1">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Address_l2'|translate }} ({{ 'plan_your_imcas.Optional'|translate }}
            )</label>
          <input type="text" class="form-control" name="address_l2" [(ngModel)]="billingData.address_l2"
                 placeholder="" [class.valid]="billingData.address_l2">
          <i class="fal" [class.fa-check-circle]="billingData.address_l2"
             [class.valid]="billingData.address_l2"></i>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-header">{{ 'account.Zip'|translate }}</label>
          <input type="text" class="form-control" name="zip" [(ngModel)]="billingData.zip" placeholder=""
                 required
                 [class.valid]="billingData.zip" [class.unvalid]="!billingData.zip">
          <i class="fal" [class.fa-check-circle]="billingData.zip" [class.fa-times-circle]="!billingData.zip"
             [class.valid]="billingData.zip"></i>
          <span [class.error]="!billingData.zip">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-header">{{ 'account.City'|translate }}</label>
          <input type="text" class="form-control" name="city" [(ngModel)]="billingData.city" placeholder=""
                 required
                 [class.valid]="billingData.city" [class.unvalid]="!billingData.city">
          <i class="fal" [class.fa-check-circle]="billingData.city"
             [class.fa-times-circle]="!billingData.city"
             [class.valid]="billingData.city"></i>
          <span [class.error]="!billingData.city">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-header">{{ 'account.Country'|translate }}</label>
          <select *ngIf="countries$ | async as countries"
                  id="country"
                  name="country"
                  class="form-control"
                  [ngModel]="selectedCountry"
                  (ngModelChange)="countryChange($event)"
                  [class.valid]="selectedCountry"
                  [class.unvalid]="!selectedCountry">
            <option *ngFor="let option of countries"
                    [ngValue]="option">
              {{translationService.getTranslatedName(option['translations'], locale)}}
            </option>
          </select>
          <i class="fal" [class.fa-check-circle]="selectedCountry"
             [class.fa-times-circle]="!selectedCountry"
             [class.valid]="selectedCountry"></i>
          <span [class.error]="!selectedCountry">{{ 'validation.Field_required'|translate }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Phone'|translate }} ({{ 'plan_your_imcas.Optional'|translate }}
            )</label>
          <input type="tel" class="form-control" name="phone" [(ngModel)]="billingData.phone" placeholder=""
                 [class.valid]="billingData.phone">
          <i class="fal" [class.fa-check-circle]="billingData.phone" [class.valid]="billingData.phone"></i>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Email'|translate }}</label>
          <input email type="email" class="form-control" name="email" [(ngModel)]="billingData.email"
                 placeholder=""
                 required #email="ngModel" [class.valid]="billingData.email && !email.invalid"
                 [class.unvalid]="!billingData.email || email.invalid">
          <i class="fal" [class.fa-check-circle]="billingData.email && !email.invalid"
             [class.fa-times-circle]="!billingData.email || email.invalid"
             [class.valid]="billingData.email && !email.invalid"></i>
          <span [class.error]="!billingData.email">{{ 'validation.Field_required'|translate }}</span>
          <span [class.error]="billingData.email && email.invalid">{{ 'validation.email'|translate }}</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-header">{{ 'account.Email_confirmation'|translate }}</label>
          <input email type="email" class="form-control" name="emailConf" [(ngModel)]="emailConfirmation"
                 placeholder=""
                 required #emailConf="ngModel"
                 [class.valid]="emailConfirmation && !emailConf.invalid && emailConfirmation == billingData.email"
                 [class.unvalid]="!emailConfirmation || emailConf.invalid || emailConfirmation != billingData.email">
          <i class="fal"
             [class.fa-check-circle]="emailConfirmation && !emailConf.invalid && emailConfirmation == billingData.email"
             [class.fa-times-circle]="!emailConfirmation || emailConf.invalid || emailConfirmation != billingData.email"
             [class.valid]="emailConfirmation && !emailConf.invalid && emailConfirmation == billingData.email"></i>
          <span [class.error]="!emailConfirmation">{{ 'validation.Field_required'|translate }}</span>
          <span
            [class.error]="emailConfirmation && emailConf.invalid">{{ 'validation.email'|translate:{'attribute': 'field'} }}</span>
          <span
            [class.error]="emailConfirmation != billingData.email && !emailConf.invalid">{{ 'validation.confirmed' | translate:{'attribute': 'email'} }}</span>
        </div>
      </div>
    </div>
    <div class="form-button">
      <button class="button button-orange" [disabled]="requestPaymentSent || !billingForm.valid || billingLastNameInvalid
                                                       || billingFirstNameInvalid || !selectedCountry || !selectedSpecialty
                                                       || (company && !billingData.company_name) || (company && !billingData.company_vat)
                                                       || (company && !billingData.company_function)"
              (click)="proceedPayment()">{{ 'cart.Proceed_to_payment'|translate }}</button>
    </div>
    <p [innerHTML]="terms_of_use_sentence"></p>
    <p *ngFor="let otherPrivacySentence of otherPrivacySentences" [innerHTML]="otherPrivacySentence"></p>
  </form>
</div>

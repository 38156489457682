<div [ngClass]="env.mobile ? env.domain === 'ophthalmology' ? 'container_app container_app_aop' : 'container_app' : 'page container'" >
  <header *ngIf="env.mobile" class="mobile-header">
    <a class="btn_header" routerLink="/account/login"><i class="fal fa-arrow-left"></i></a>
    <span>{{'reminders.remind_title'|translate}}</span>
  </header>
  <div class="bg-login" style="background-image:url(assets/img/bg_image_aop.png)" *ngIf="env.domain == 'ophthalmology'"></div>
  <div [ngClass]="env.mobile ? 'login-screen' : 'row'">
        <div [ngClass]="env.mobile ? 'login' : 'col-md-12'">
          <h1 *ngIf="!env.mobile">{{ 'reminders.remind_title'|translate}}</h1>
          <div *ngIf="env.mobile" class="login-text">
            <img class="logo_app light" src="assets/img/logo_white.png" alt="Logo IMCAS Academy" *ngIf="env.domain == 'aesthetics'">
            <img class="logo_app light" src="assets/img/logo_white_aop.png" alt="Logo AOP Academy" *ngIf="env.domain == 'ophthalmology'">
          </div>
          <div class="alert alert-danger alert-dismissable" *ngIf="!success && !requestSent && dontExist">
            {{ 'reminders.email_not_recognized'|translate }}
          </div>
            <div *ngIf="!requestSent" class="login-form">
                <div [ngClass]="env.mobile? 'line-input' : 'form-group'">
                    <label [ngClass]="env.mobile ? 'floating-label' : 'form-header'" for="email">{{ 'account.Email'|translate }}</label>
                    <input [ngClass]="env.mobile ? '' : 'form-control'" [(ngModel)]="email" name="email" id="email" type="text">
                </div>
                <button [ngClass]="env.mobile ? 'transparent-button primary' : 'button button-orange'" (click)="sendReminder()">{{ 'reminders.send_reminder'|translate }}</button>
            </div>
            <app-loader *ngIf="requestSent && !success"></app-loader>
            <div class="alert alert-success alert-dismissable" *ngIf="success">
                {{ 'reminders.sent'|translate }}
            </div>
        </div>
    </div>
</div>

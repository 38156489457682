import {Component, OnInit} from '@angular/core';
import {UserService} from 'app/services/user.service';
import {User} from 'app/models/user.model';
import {DateMomentService} from 'app/services/date-moment.service';
import {AuthService} from '../../../../services/auth.service';
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'settings-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [
    UserService
  ]
})
export class SettingsInvoicesComponent implements OnInit {
  user: User;
  invoices: any = [];
  updated: boolean = false;
  error: string;
  environment = environment;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    public dateMomentService: DateMomentService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      this.userService.getUserInvoices(this.user.id).subscribe(
        result => {
          this.invoices = result;
        }
      );
    }
  }

  updateProfile() {
    this.userService
      .updateUser(this.user)
      .subscribe(
      (result: any) => {
        if (!result.error) {
          this.updated = true;
        }else {
          this.error = result.error;
        }
      });
  }
}

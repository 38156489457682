<ng-template #friendsModal>
  <div class="friends" *ngIf="profile">
    <h1
      class="gradient">{{('profile.X_connection'|translate:{name: profile.first_name + ' ' + profile.last_name})}}</h1>
    <div class="row friends-row" *ngFor="let friend of friends">
      <a class="col-md-7" routerLink="/profile/{{friend.slug}}" target="_blank">
        <div class="row">
          <div class="col-md-3 friends-thumb">
            <img [src]="friend.picture_url">
          </div>
          <div class="col-md-9 friends-details">
            <span class="name">{{friend.title + ' ' + friend.first_name + ' ' + friend.last_name}}</span>
            <span
              class="specialty">{{friend.specialty ? translationService.getTranslatedName(friend.specialty.translations, locale) : ''}}</span>
            <span
              class="country">{{friend.country ? translationService.getTranslatedName(friend.country.translations, locale) : ''}}</span>
          </div>
        </div>
      </a>
      <div class="col-md-5 friends-status d-flex">
				<span class="align-self-center accepted" *ngIf="friend.friendship_status_with_auth==='friend'">
					<i class="fa fa-check"></i> {{'academy.Connected'|translate}}
				</span>
        <span class="align-self-center connect" *ngIf="friend.friendship_status_with_auth==='not_friend'"
              (click)="connect(friend)">
					<i class="fa fa-plus"></i> {{'profile.Add_your_connection'|translate}}
				</span>
        <span class="align-self-center send" *ngIf="friend.friendship_status_with_auth==='waiting'">
					{{'profile.Invitation_send'|translate}}
				</span>
      </div>
    </div>
  </div>
</ng-template>

<div class="page">
  <app-loader *ngIf="!profile && loadingUser"></app-loader>
  <ng-container *ngIf="profile && !loadingUser">
    <section class="section section-filtered page-top" [ngStyle]="{'background-image': 'url('+ profile.cover_url +')'}"
             [ngClass]="isAop ? 'section-filtered-aop' : 'section-filtered-imcas'">
      <div class="content">
        <div class="profile">
          <div class="thumbnail">
            <div [style.background]="'url(' + profile.picture_url + ')'" [style.background-size]="'cover'"
                 class="rounded-circle"></div>
          </div>
          <div class="info">
            <h5>{{profile.title + ' ' + profile.first_name + ' ' + profile.last_name}}</h5>
            <p>{{(profile.specialty ? translationService.getTranslatedName(profile.specialty.translations, locale) : '') +
            (profile.specialty && profile.country ? ', ' : '') +
            (profile.country ? translationService.getTranslatedName(profile.country.translations, locale) : '')}}</p>
          </div>
          <div class="actions">
            <a [routerLink]="['/inbox','thread', profile.thread_with_auth]" class="button button-white">
              <i class="far fa-envelope" aria-hidden="true"></i>
            </a>
            <button *ngIf="(!user || user.id !== profile.id) && profile.friendship_status_with_auth === 'not_friend'"
                    (click)="connect(profile)"
                    class="button button-pink">{{('academy.Add_X'|translate:{name: profile.first_name + ' ' + profile.last_name})}}</button>
            <a *ngIf="user && profile && user.id === profile.id" [routerLink]="['/account/professional-data']"
               class="add-button button button-pink">{{'academy.Edit_your_profile'|translate}}</a>
          </div>
        </div>

        <div class="edit-cover" (click)="open(editcover)" *ngIf="user && profile && user.id === profile.id">
          <div class="user-icon-picture">
            <i class="fa fa-camera"></i>
          </div>
          <p>{{'account.Modify_cover'|translate}}</p>
        </div>
      </div>
    </section>
    <div class="badges-mobile" *ngIf="profile.badges && profile.badges.length > 0">
      <div class="badge-container" *ngFor="let badge of profile.badges">
        <app-badge
          [badge]="badge"
          [size]="3"
          [tooltipMsg]="badge.title"
        ></app-badge>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <aside class="sidebar col-md-3 col-12">
          <div class="container">
            <div class="thumbnail">
              <div [style.background]="'url(' + profile.picture_url + ')'" [style.background-size]="'cover'"
                   class="rounded-circle"></div>
              <div class="thumbnail-modify" (click)="open(editavatar)" *ngIf="user && profile && user.id === profile.id">
                <span>{{'account.Modify_profile_picture'|translate}}</span>
                <div class="user-icon-picture">
                  <i class="fa fa-camera"></i>
                </div>
              </div>
            </div>
            <div class="info">
              <h5>{{profile.title + ' ' + profile.first_name + ' ' + profile.last_name}}</h5>
              <p>{{(profile.specialty ? translationService.getTranslatedName(profile.specialty.translations, locale) : '')}}</p>
              <p>{{(profile.country ? translationService.getTranslatedName(profile.country.translations, locale) : '')}}</p>
            </div>
            <div class="actions">
              <a *ngIf="user && profile && user.id === profile.id" [routerLink]="['/account', 'professional-data']"
                 class="add-button">
                <i class="fal fa-user-edit" aria-hidden="true"></i>{{'academy.Edit_profile'|translate}}
              </a>
              <button (click)="connect(profile)"
                      [routerLink]="profile && profile.friendship_status_with_auth === 'accept_friend_request' ? '/account/connections' : null"
                      *ngIf="(!user || user.id !== profile.id) && (profile.friendship_status_with_auth !== 'friend' && profile.friendship_status_with_auth !== 'waiting_friend_accept' && profile.friendship_status_with_auth !== 'friendship_refused')"
                      [ngClass]="{'connected': profile && profile.friendship_status_with_auth === 'waiting_friend_accept'}"
                      [style.background]="isAop ? '#19487c' : null">
                <i class="fal fa-user-plus" aria-hidden="true"></i>
                <i class="fal fa-user-check" aria-hidden="true"></i>
                {{'academy.Add_friend' | translate}}
              </button>

              <button *ngIf="(!user || user.id !== profile.id) && (profile.friendship_status_with_auth === 'friend')"
                      style="background-color:#0781aa;" class="connected">
                <i class="fal fa-user-check" aria-hidden="true"></i>
                {{'academy.Friend' | translate}}
              </button>

              <button
                *ngIf="(!user || user.id !== profile.id) && (profile.friendship_status_with_auth === 'waiting_friend_accept')"
                style="background-color:#0781aa;" class="connected">
                <i class="fal fa-user-check" aria-hidden="true"></i>
                {{'academy.Request_sent' | translate}}
              </button>

              <a [routerLink]="['/inbox','thread', profile.thread_with_auth]"
                 [style.background]="isAop ? '#19487c' : null">
                <i class="fal fa-envelope" aria-hidden="true"></i>{{'inbox.Inbox'| translate}}
              </a>
            </div>
            <div class="badges" *ngIf="profile.badges && profile.badges.length > 0"
                 style="display:flex;flex-wrap:wrap;justify-content:space-around;margin-top:1rem">
              <div class="badge-container" *ngFor="let badge of profile.badges">
                <app-badge
                  [badge]="badge"
                  [size]="3"
                  [tooltipMsg]="badge.title"
                ></app-badge>
              </div>
            </div>
          </div>
          <ng-template #editavatar let-c="close" let-d="dismiss">
            <app-edit-avatar-modal [dismiss]="d" [close]="c" [profile]="profile"></app-edit-avatar-modal>
          </ng-template>
          {{ cover_images }}
          <ng-template #editcover let-c="close" let-d="dismiss">
            <app-edit-cover-modal [dismiss]="d" [close]="c" [profile]="profile"></app-edit-cover-modal>
          </ng-template>
        </aside>
        <main class="container col-md-8 col-md-offset-1 col-12">
          <ng-template #biodescription let-c="close" let-d="dismiss">
            <div class="modal-body modal-no-title modal-wide">
              <button type="button" class="close" aria-label="Close" (click)="d()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <div class="modal-profile">
                <h2>{{profile.title + ' ' + profile.first_name + ' ' + profile.last_name}}</h2>
                <div *ngIf="profile.biostatement" [innerHTML]="profile.biostatement"></div>
              </div>
            </div>
          </ng-template>
          <div class="description">
            <p *ngIf="profile.biostatement"
               [innerHTML]="profile.biostatement.length > 229 ? profile.biostatement.slice(0, 229) + '...' : profile.biostatement"></p>
            <span *ngIf="profile.biostatement && profile.biostatement.length > 229" (click)="open(biodescription)"
                  style="color: #fa7732;">{{'blog.Read_more'|translate}}</span>
          </div>
          <h1
            *ngIf="profile.next_lectures && profile.next_lectures.length > 0">{{('academy.X_schedule'|translate:{name: profile.first_name + ' ' + profile.last_name})}}</h1>
          <div *ngIf="profile.next_lectures && profile.next_lectures.length > 0" class="schedule"
               [ngStyle]="{'border-left': profile.next_lectures && profile.next_lectures[0] && profile.next_lectures[0].session && profile.next_lectures[0].session.congress ? '3px solid ' + profile.next_lectures[0].session.congress.website_color : '3px solid  #444'}">
            <ng-container *ngFor="let nextLecture of profile.next_lectures; let i=index;">
              <h5
                [ngStyle]="{'color': nextLecture.session && nextLecture.session.congress ? nextLecture.session.congress.website_color : '#444'}"
                *ngIf="nextLecture.session && nextLecture.session.congress && (i === 0 || profile.next_lectures[i].session.congress_id !== profile.next_lectures[i-1].session.congress_id)">
                {{translationService.getTranslatedAttribute('complete_name',
                nextLecture.session.congress.translations, locale)}}</h5>
              <div class="schedule-item">
                <a *ngIf="nextLecture.session && nextLecture.session.congress" target="_blank"
                   [href]="nextLecture.session.is_webinar === 1 ? environment.base_url + '/en/academy/webinar/' + nextLecture.session.slug : environment.base_url + '/' + locale + '/attend/'+ nextLecture.session.congress.slug + '/program/session/' + nextLecture.session.id"
                   class="title">{{translationService.getTranslatedAttribute('subtitle',
                  nextLecture.session.translations, locale) ? translationService.getTranslatedAttribute('subtitle',
                  nextLecture.session.translations, locale) : translationService.getTranslatedAttribute('title',
                  nextLecture.session.translations, locale)}}</a>
                <p>{{translationService.getTranslatedAttribute('title',
                  nextLecture.translations, locale)}}</p>
                <span class="date"
                      *ngIf="nextLecture.session">{{nextLecture.session.date_begin | date:'MMMM d, yyyy':'':locale}}</span>
              </div>
            </ng-container>
          </div>
          <h1 *ngIf="videos && videos.length > 0">{{'academy.Lectures'|translate}} ({{videos.length}})</h1>
          <div *ngIf="videos && videos.length > 0" class="courses">
            <div class="row">
              <div class="video-related academy-slider">
                <app-lecture [card]="true" *ngFor="let media of videos" [thumbFormat]="'rectangular'" [lecture]="media"
                             [user]="user"
                             [favoriteElement]="false"></app-lecture>
              </div>
            </div>
          </div>
          <h1 *ngIf="articles && articles.length > 0">{{ 'academy.blog_articles'|translate }} ({{articles.length}})</h1>
          <div *ngIf="articles && articles.length > 0" class="row courses">
            <div class="video-related articles-slider">
              <app-blog-post-item *ngFor="let article of articles" [article]="article" [label]="false"></app-blog-post-item>
            </div>
          </div>
          <h1
            *ngIf="friends && friends.length > 0">{{('profile.X_connections'|translate:{name: profile.first_name + ' ' + profile.last_name})}}
            ({{friends.length}})</h1>
          <div class="connections" *ngIf="friends && friends.length > 0">
            <div class="row">
              <ng-container *ngFor="let friend of friends; index as i">
                <div class="col-md-6" *ngIf="i < 4">
                  <div class="author media">
                    <a routerLink="/profile/{{friend.slug}}"><img [src]="friend.picture_url"
                                                                  [alt]="friend.title + ' ' + friend.first_name + ' ' + friend.last_name"></a>
                    <div class="media-body">
                      <h6><a
                        routerLink="/profile/{{friend.slug}}">{{friend.title + ' ' + friend.first_name + ' ' + friend.last_name}}</a>
                      </h6>
                      <span>{{(friend.specialty ? translationService.getTranslatedName(friend.specialty.translations, locale) : '') +
                      (friend.specialty && friend.country ? ', ' : '') +
                      (friend.country ? translationService.getTranslatedName(friend.country.translations, locale) : '')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="connections-more" *ngIf="friends && friends.length > 4">
              <a (click)="openSmall(friendsModal)">{{'layout.More'|translate}} ({{friends.length}})</a>
            </div>
          </div>
          <h1 *ngIf="profile.pubmed_references && profile.pubmed_references.length > 0">
            {{'explore.Publications'|translate}} {{profile.pubmed_references ? '(' + profile.pubmed_references.length + ')' : ''}}
          </h1>
          <div class="publications" *ngIf="profile.pubmed_references && profile.pubmed_references.length > 0">
            <ng-container *ngFor="let publication of profile.pubmed_references; index as i">
              <div class="publications-item" *ngIf="i < 3 || showMorePubs">
                <a [href]="'https://www.ncbi.nlm.nih.gov/pubmed/?term='+publication.pmid"
                   target="_blank">{{publication.title}}</a>
                <span
                  *ngIf="publication.date">{{publication.date.split('-').join('/') | date:'MMMM d, yyyy':'':locale}}</span>
                <p>{{publication.abstract}}</p>
              </div>
              <button class="button" (click)="showMorePubs=true"
                      *ngIf="profile.pubmed_references.length > 3 && i === 2 && !showMorePubs">{{'blog.Read_more'|translate}}
                ( {{profile.pubmed_references.length - 3}})
              </button>
            </ng-container>
          </div>
          <h1
            *ngIf="profile.scientific_societies && profile.scientific_societies.length > 0">{{'explore.Learned_societies'|translate}} {{profile.scientific_societies ? '(' + profile.scientific_societies.length + ')' : ''}}</h1>
          <div *ngIf="profile.scientific_societies && profile.scientific_societies.length > 0" class="societies">
            <div class="row">
              <div class="col-md-4 col-6" *ngFor="let society of profile.scientific_societies">
                <div class="societies-item d-flex">
                  <a [href]="society.website" target="_blank" rel="noreferrer noopener" class="align-self-center"><img
                    class="societies-image" [src]="society.logo_url" [alt]="society.name"></a>
                </div>
              </div>
            </div>
          </div>
          <h1 *ngIf="profile.themes && profile.themes.length > 0">{{'profile.Main_interests'|translate}}</h1>
          <div *ngIf="profile.themes && profile.themes.length > 0" class="interest">
            <div class="row">
              <ng-container *ngFor="let theme of profile.themes; index as i">
                <div class="col-md-3 col-6" *ngIf="i < 4">
                  <a routerLink="{{ theme.linkLibrary() }}">
                    <div class="interest-box">
                      <img class="interest-image" [src]="theme.picture_squared_url"
                           [alt]="translationService.getTranslatedName(theme.translations, locale)">
                      <div class="interest-title">
                        <h5>{{translationService.getTranslatedName(theme.translations, locale)}}</h5>
                      </div>
                    </div>
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </main>
      </div>
    </div>
  </ng-container>
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Comment} from 'app/models/comment.model';
import {map, share, shareReplay, tap} from 'rxjs/operators';

@Injectable()
export class AlertService {
    private groupsEndpoint: string = '/alert_groups';
    private casesEndpoint: string = '/alert_cases';
    private newCaseEndpoint: string = '/alert-case';

    public TYPE_CASE = [{
      title: 'alert.Complication',
      value: 'complication',
      tooltip: 'alert.Complication_tooltip'
    },{
      title:'alert.Inquiry',
      value:'inquiry',
      tooltip:'alert.Inquiry_tooltip',
    },
      {
        title: 'alert.Discussion',
        value: 'discussion',
        tooltip: 'alert.Discussion_tooltip'
      },
      {
        title: 'alert.Report',
        value: 'report',
        tooltip: 'alert.Report_tooltip'
      }];
    constructor(
        private httpClient: HttpClient
    ) {
    }

    getGroup(slug: string, params?: any): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<any>(environment.apiURLV2 + this.groupsEndpoint + '/' + slug, reqOpts).pipe(shareReplay());
    }

    getUserGroups(locale = 'en'): Observable<any> {
        return this.httpClient.get<any>(environment.apiURLV2 + this.groupsEndpoint, {
            params: new HttpParams().set('all', '1').set('user_access', 'granted').set('front_locale', locale)
        }).pipe(shareReplay());
    }

    getGroups(params?: any): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        reqOpts.params = reqOpts.params.set('all', '1');
        reqOpts.params = reqOpts.params.set('coordinators', '1');
        return this.httpClient.get<any>(environment.apiURLV2 + this.groupsEndpoint, reqOpts).pipe(
            shareReplay(),
            map(data => {
                return data.sort(function(a, b) {
                    return a['rank'] - b['rank'];
                })
            })
        );
    }

    getCases(
        groupId?: number | boolean,
        themeId?: number | boolean,
        keywordId?: number | boolean,
        page?: number | boolean,
        locale?: string | boolean,
        per_page?: number
    ): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };

      if (groupId) {
        reqOpts.params = reqOpts.params.set('alert_group_id', '' + groupId);
        reqOpts.params = reqOpts.params.set('alert_group_id_2', '' + groupId);

      } else {
            reqOpts.params = reqOpts.params.set('with_group', '' + 1);
        }
        if (themeId) {
            reqOpts.params = reqOpts.params.set('theme_id', '' + themeId);
        }
        if (keywordId) {
            reqOpts.params = reqOpts.params.set('keyword_id', '' + keywordId);
        }
        if (page) {
            reqOpts.params = reqOpts.params.set('page', '' + page);
        }
        if (locale) {
            reqOpts.params = reqOpts.params.set('locale', '' + locale);
        }
        if (per_page) {
            reqOpts.params = reqOpts.params.set('per_page', '' + per_page);
        }
        if (themeId || keywordId || locale) {
            reqOpts.params = reqOpts.params.set('all', '1');
        }
        return this.httpClient.get<any>(environment.apiURL + this.casesEndpoint, reqOpts).pipe(shareReplay());
    }

    getPreview(): Observable<any>  {
      return this.httpClient.get<any>(environment.apiURLV2 + this.casesEndpoint + '/preview').pipe(shareReplay());
    }

    getCase(caseId: number, caseSlug?: string): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };

        reqOpts.params = reqOpts.params.set('user_specialty', '' + 1)
            .set('case_comments', '1')
            .set('user_coordinator', '1')
            .set('with_group', '' + 1)
            .set('alert_case_videos_related', '1');

        return this.httpClient.get<any>(environment.apiURLV2 + this.casesEndpoint + '/' + caseId, reqOpts).pipe(shareReplay());
    }


    addCaseComment(caseId: number, comment: Comment): Observable<any> {
        return this.httpClient.post<any>(environment.apiURL + '/comments', comment).pipe(shareReplay());
    }

    followCase(caseId: number): Observable<any> {
        return this.httpClient.post<any>(environment.apiURLV2 + this.casesEndpoint + '/' + caseId + '/follow', null).pipe(shareReplay());
    }

    addCaseFiles(caseId: number, fd: FormData): Observable<any> {
        return this.httpClient.post<any>(environment.apiURLV2 + this.casesEndpoint + '/' + caseId + '/upload', fd,
            {headers: {multipart: 'yes'}}).pipe(shareReplay());
    }

    addCase(newCase: any): Observable<any> {
        return this.httpClient.post<any>(environment.apiURLV2 + this.casesEndpoint, newCase).pipe(shareReplay());
    }

    updateCase(caseId, body): Observable<any> {
        return this.httpClient.put<any>(environment.apiURLNew + this.newCaseEndpoint + '/edit/' + caseId, body).pipe(shareReplay());
    }
    acceptTerms(group_id: number, accept: boolean): Observable<any> {
        return this.httpClient.post<any>(environment.apiURLV2 + this.groupsEndpoint + '/' + group_id + '/user',
            {accept: accept ? 1 : 0})
            .pipe(shareReplay());
    }

    deleteCase(caseId: number): Observable<any> {
        return this.httpClient.delete<any>(environment.apiURLV2 + this.casesEndpoint + '/' + caseId, {}).pipe(shareReplay());
    }

    openOrCloseCase(caseId: number): Observable<any> {
      return this.httpClient.post<any>(environment.apiURLNew + this.newCaseEndpoint + '/open/' + caseId, {}).pipe(shareReplay());
    }

    getV2Cases(body: any) {
      let reqOpts = {
        params: new HttpParams()
      };
      if (body.group_id) {
        reqOpts.params = reqOpts.params.set('group_id', body.group_id);
      }
      if(body.page) {
        reqOpts.params = reqOpts.params.set('page', body.page);
      }
      if (body.purpose_id) {
        reqOpts.params = reqOpts.params.set('purpose_id', body.purpose_id);
      }
      if (body.complication_id) {
        reqOpts.params = reqOpts.params.set('complication_id', body.complication_id);
      }
      return this.httpClient.get<any>( environment.apiURLNew + 'alert-case/get', reqOpts).pipe(shareReplay());
    }
    canEditAnonymousComment(commentId: number)  {
      return this.httpClient.post<any>(environment.apiURLNew + 'comment/can/edit/' + commentId, {}).pipe(shareReplay());
    }

    editComment(commentId: number, body) {
      return this.httpClient.post<any>(environment.apiURLNew + 'comment/edit/'+ commentId, body).pipe(shareReplay());
    }

  deleteFile(commentId: number, body) {
    return this.httpClient.post<any>(environment.apiURLNew + 'comment/delete/file/' + commentId, body).pipe(shareReplay());
  }

  addFiles(commentId, body) {
      return this.httpClient.post<any>(environment.apiURLNew +'comment/add/file/' + commentId, body, {headers: {multipart: 'yes'}}).pipe(shareReplay());
  }

  addCaseFilesV2(alertCaseId, body) {
      return this.httpClient.post<any>(environment.apiURLNew + 'alert-case/add/file/' + alertCaseId, body, {headers: {multipart: 'yes'}}).pipe(shareReplay());
  }

  deleteCaseFiles(alertCaseId, body) {
      return this.httpClient.post<any>(environment.apiURLNew + 'alert-case/delete/file/'+ alertCaseId, body, {headers: {multipart: 'yes'}}).pipe(shareReplay());
  }

  coordinatorReceiveRecap(body) {
      return this.httpClient.post<any>(environment.apiURLNew + 'alert-group/set/receive-recap', body).pipe(shareReplay());
  }
}

<h1>{{'academy.Current_plan'|translate}}</h1>
<div class="membership text-center" *ngIf="membership">
  <div class="alert alert-success" *ngIf="canceled">{{ 'cart.Flash_success_canceled' | translate }}</div>
  <div class="membership-details">
    <h6 *ngIf="membership.status==='premium'">{{'account.Paid_member'|translate}}</h6>
    <h6 *ngIf="membership.status==='premium_trial'">{{'account.Premium_member_trial'|translate}}</h6>
    <h6 *ngIf="membership.status==='free'">{{'account.Free_member'|translate}}</h6>
    <p *ngIf="membership.ending_date">{{ 'account.Your_subscription_ends_on'|translate }}: {{
      dateMomentService.getPublicationDate(membership.ending_date) }}</p>
    <br>
    <p *ngIf="membership.product">{{ 'account.Your_subscription'|translate }}: {{
      translationService.getTranslatedName(membership.product.translations, locale) }}</p>
  </div>
  <div class="membership-actions" *ngIf="!environment.mobile && environment.domain === 'aesthetics'">
    <a *ngIf="(membership.status==='premium' || membership.status==='premium_trial') && showReSubscribeButton === true"
       [routerLink]="['/premium']" [queryParams]="{'auto-subscribe':''}"
       class="button button-orange">{{'account.Renew_sub'|translate}}</a>
    <a *ngIf="membership.status==='free'"
       [routerLink]="'/premium'"
       [queryParams]="{'auto-subscribe':''}"
       class="button button-orange">{{'academy.Subscribe'|translate}}</a>
    <div class="clearfix"></div>
    <a *ngIf="(membership.status==='premium' || membership.status==='premium_trial') && membership.recurrence === true && (membership.academy_subscription ||  membership.registration_id || membership.cart_id || membership.payment_id)"
       (click)="cancelSubscription(membership)"
       class="link">{{ 'premium.Cancel_sub'|translate }}</a>
  </div>
  <section class="section offer-plan" *ngIf="!environment.mobile && environment.domain === 'aesthetics' && (products[0] && mediasCount$) | async as mediasCount">
    <div class="content text-center">
     <!-- <a href="javascript:void(0)" class="button button-orange" (click)="openCart(products[0].id, 'gift')">
        <i class="fal fa-gift" style="margin-right: 5px;"></i>{{'premium.Offer_title'|translate }}
      </a>
      <br>-->
      <p>{{ 'premium.Offer_para'|translate :{nb: mediasCount.lecture} }}</p>
    </div>
  </section>
  <section class="section offer-plan" *ngIf="environment.mobile">
    <div class="card">
      <h5 class="card-header"><b>Note :</b></h5>
      <div class="card-body">
        Manage your subscription on the academy website
      </div>
    </div>
  </section>
</div>

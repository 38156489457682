import {Component, Input, OnInit} from '@angular/core';
import {CongressProduct} from '../../models/congress-product.model';
import {TranslationService} from '../../services/translation.service';
import {LocaleService} from '../../services/locale.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-market-product',
    templateUrl: './market-product.component.html',
    styleUrls: ['./market-product.component.scss'],
    providers: [TranslationService]
})
export class MarketProductComponent implements OnInit {

    @Input() congressProduct: CongressProduct;
    @Input() large: boolean;
    @Input() primary: boolean;
    @Input() innerWidth: number;
    @Input() imagePosition: string;
    @Input() color: string;
    locale: string;

    constructor(public translationService: TranslationService, private localeService: LocaleService, private router: Router) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.congressProduct.setColor(this.color);
    }

    ngAfterViewChecked() {
      // We want to resize img containers after page load to fit a 780x460px ratio
      let imgs = Array.from(document.getElementsByClassName('product-image') as HTMLCollectionOf<HTMLElement>);

      for (let i = 1; i < imgs.length; i++) {
        imgs[i].style.height = Math.round(imgs[i].offsetWidth / 1.7) + 'px';
      }
    }

    openProduct() {
        this.router.navigateByUrl('marketplace/' + this.congressProduct.slug);
    }

    // We want to display a 275 char text excerpt for all products displayed in small cards
    getDescription() {
      let desc = this.translationService.getTranslatedAttribute('short_description',
        this.congressProduct.product.translations, this.locale);

      if (!this.large) {
        desc = desc.slice(0, 275) + '[...]';
      }

      return desc;
    }
}

import {DateMomentService} from 'app/services/date-moment.service';
import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {Specialty} from 'app/models/specialty.model';
import {Country} from 'app/models/country.model';
import {SpecialtyService} from 'app/services/specialty.service';
import {CountryService} from 'app/services/country.service';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';
import {tap} from 'rxjs/operators';
import {NgbDateCustomParserFormatter} from '../../../../formatters/ngb-date-custom-parser.formatter';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {UserThemesGuardService} from "../../../../services/user-themes-guard.service";

@Component({
    selector: 'profile-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.scss'],
    providers: [
        CountryService,
        SpecialtyService,
        NgbDateCustomParserFormatter,
        {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
    ]
})

export class ProfileDataComponent implements OnInit {
    mask = ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    user: User;
    favorited: any;
    locale: string;
    specialities$: Observable<Specialty[]>;
    countries$: Observable<Country[]>;
    selectedCountry: Country;
    selectedSpecialty: Specialty;
    updated: boolean = false;
    percent: number = 5;
    social_network: any[];
    newUser = {
        address_web: null,
        city_web: null,
        company_name_web: null,
        country_web_id: null,
        email: null,
        first_name: null,
        gender: null,
        last_name: null,
        phone: null,
        postal_code_web: null,
        user_specialty_id: null,
        dob: null,
        twitter_username: null,
        facebook_url: null,
        instagram_username: null,
        website: null,
    };
    today: any;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                public translationService: TranslationService,
                public localeService: LocaleService,
                public dateMomentService: DateMomentService,
                private specialtyService: SpecialtyService,
                private authService: AuthService,
                private countryService: CountryService,
                private ngbDateCustomParserFormatter: NgbDateCustomParserFormatter) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.getUser();
        this.today = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};
    }

    getUser() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {
                user_specialty: 1,
                user_country: 1,
                cover_url: 1,
                user_themes: 1,
                user_biostatement: 1,
                user_scientific_societies: 1,
                social_network: 1
            }).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                    this.newUser.address_web = this.user.address || '';
                    this.newUser.city_web = this.user.city || '';
                    this.newUser.company_name_web = this.user.company_name || '';
                    this.newUser.country_web_id = this.user.country ? this.user.country.id : '';
                    this.newUser.email = this.user.email;
                    this.newUser.website = this.user.social_network.website;
                    this.newUser.first_name = this.user.first_name;
                    this.newUser.gender = this.user.gender || 'n';
                    this.newUser.last_name = this.user.last_name;
                    this.newUser.phone = this.user.phone;
                    this.newUser.postal_code_web = this.user.postal_code || '';
                    this.newUser.user_specialty_id = this.user.specialty.id;
                    this.newUser.twitter_username = this.user.social_network.twitter || '';
                    this.newUser.facebook_url = this.user.social_network.facebook || '';
                    this.newUser.instagram_username = this.user.social_network.instagram || '';
                    this.newUser.dob = this.ngbDateCustomParserFormatter.parse(this.user.dob);
                    this.getSpecialties();
                    this.getCountries();
                    this.percent = this.userService.getCompletion(this.user);
                    this.userService.updatePercent(this.percent);
                }
            );
        }
    }



    getSpecialties() {
        this.specialities$ = this.specialtyService
            .getSpecialties({specialty_category: this.user.specialty_category, all: 1})
            .pipe(tap(data => {
                if (this.user && this.user.specialty) {
                    const specialtyToSelect = data.find(specialty => specialty.id === this.user.specialty.id);
                    if (specialtyToSelect) {
                        this.selectedSpecialty = specialtyToSelect;
                    }
                }
            }));
    }

    getCountries() {
        this.countries$ = this.countryService
            .getCountries()
            .pipe(tap(data => {
                if (this.user && this.user.country) {
                    const countryToSelect = data.find(country => country.id === this.user.country.id);
                    if (countryToSelect) {
                        this.selectedCountry = countryToSelect;
                    }
                }
            }));
    }

    specialtyChange(specialty) {
        this.newUser.user_specialty_id = specialty.id;
    }

    countryChange(country) {
        this.newUser.country_web_id = country.id;
    }

    updateProfile() {
        const dobDate = this.newUser.dob;

        if (dobDate) {
            this.newUser.dob = dobDate.year + '-' + `0${dobDate.month}`.slice(-2) +
                '-' + `0${dobDate.day}`.slice(-2);
        }
        this.userService.updateUser(this.newUser).subscribe(success => {
            this.updated = true;
            this.newUser.dob = dobDate;
            scroll({
              top: 100,
              behavior: 'smooth'
            });
        });
    }
}

<div class="page">
    <div class="page-content content">
        <h1>{{ 'alert.Code_of_conduct_title'|translate }}</h1>
        <p> {{ 'alert.Code_of_conduct_para1'|translate }}</p>
        <h4>{{ 'alert.Key_principles'|translate }}</h4>
        <ol>
            <li>{{ 'alert.Code_of_conduct_li1'|translate }}</li>
            <li>{{ 'alert.Code_of_conduct_li2'|translate }}</li>
            <li>{{ 'alert.Code_of_conduct_li3'|translate }}</li>
            <li>{{ 'alert.Code_of_conduct_li4'|translate }}</li>
            <li>{{ 'alert.Code_of_conduct_li5'|translate }}</li>
        </ol>
        <p>{{ 'alert.Code_of_conduct_para2'|translate }}</p>
        <h4>{{ 'alert.Responsibility'|translate }}</h4>
        <p>{{ 'alert.Code_of_conduct_para3'|translate }}</p>
        <p>{{ 'alert.Code_of_conduct_para4'|translate }}</p>
        <p>{{ 'alert.Code_of_conduct_para5'|translate }}</p>
        <p>{{ 'alert.Code_of_conduct_para6'|translate }}</p>
        <a href="{{ env.cdn_base_url }}/academy/alert/en_patient_consent.pdf" class="btn btn-light"><i class="fa fa-download" style="margin-right: 5px" aria-hidden="true"></i>{{ 'alert.Download_patient_consent_template'|translate }}</a>
    </div>
</div>
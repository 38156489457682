
  <div class="modal-body modal-no-title">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <div class="faq">
      <h2>{{translationService.getTranslatedAttribute('title', lecture.translations,
        locale)}}</h2>
      <h5>{{'academy.Disclosures'|translate}}</h5>
      <div class="faq-list">
        <div class="faq-item" *ngIf="lecture.disclosure1">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question1'|translate}}</h6>
          <p>{{lecture.disclosure1 == 1 ? ('abstract.Yes'|translate) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="lecture.disclosure2">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question2'|translate}}</h6>
          <p>{{lecture.disclosure2 == 1 ? ('abstract.Yes'|translate) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="lecture.disclosure3">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question3'|translate}}</h6>
          <p>{{lecture.disclosure3 == 1 ? ('abstract.Yes'|translate) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="lecture.disclosure4">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question4'|translate}}</h6>
          <p>{{lecture.disclosure4 == 1 ? ('abstract.Yes'|translate) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="lecture.disclosure5">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{lecture.disclosure5 == 1 ? ('abstract.Disclosure_answer5_1'|translate)
            : ('abstract.Disclosure_answer5_0'|translate) }}</h6>
          <p>{{lecture.disclosure5 == 1 ? lecture.disclosure5_sponsor : ''}}</p>
        </div>
      </div>
    </div>
  </div>

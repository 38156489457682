import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslationService} from '../../../../services/translation.service';
import {WorldwideEventsService} from '../../../../services/worldwide-events.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../models/user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CongressService} from '../../../../services/congress.service';
import {Congress} from '../../../../models/congress.model';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import * as moment from 'moment';
import 'intl';
import 'intl/locale-data/jsonp/en.js';
import 'intl/locale-data/jsonp/es.js';
import 'intl/locale-data/jsonp/fr.js';
import 'intl/locale-data/jsonp/ru.js';
import 'intl/locale-data/jsonp/zh.js';


@Component({
    selector: 'app-worldwide-events',
    templateUrl: './worldwide-events.component.html',
    styleUrls: ['./worldwide-events.component.scss'],
    providers: [WorldwideEventsService, CongressService]
})
export class WorldwideEventsComponent implements OnInit, AfterViewInit {
    @ViewChild('countryFilter', {static: false}) cf: ElementRef;
    @ViewChild('fromGoogle', {static: false}) modalFromGoogle: ElementRef;
    public countries_list: Array<any>;
    public worldevents_list: Array<any>;
    public filtered_we_list: Array<any>;
    public grouped_list: Array<any>;
    public grouped_list_even: Array<any>;
    public grouped_list_odd: Array<any>;
    public locale: string;
    public environment: any;
    public show_partners: boolean;
    public currentUser: User;
    public congresses: Congress[];
    public country_id: number;
    loading: boolean;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private congressService: CongressService,
                public translationService: TranslationService,
                public localeService: LocaleService,
                private modalService: NgbModal,
                private _sanitizer: DomSanitizer,
                public worldwideEventsService: WorldwideEventsService) {
    }

    openAttendCongress(slug) {
        return window.location.href = this.environment.base_url + '/' + this.locale + '/attend/' + slug;
    }

    ngOnInit() {
        this.loading = false;
        this.countries_list = [];
        this.environment = environment;
        this.locale = this.localeService.currentLocale();
        this.currentUser = this.authService.getCurrentUser();
        this.activatedRoute.params.subscribe(params => {
            this.show_partners = params['tab'] === 'partners';
            this.getWorldWideEvents();
        });

    }

    sanitizeCssProperty(url) {
        return this._sanitizer.bypassSecurityTrustStyle(url);
    }

    ngAfterViewInit() {
        if (document.referrer.indexOf('google.') > 0 && this.environment.domain === 'aesthetics') {
            this.congressService.searchCongresses({
                order_by: 'date_start',
                per_page: 3,
                after: moment().format('YYYY-MM-DD')
            }).subscribe(data => {
                this.congresses = [];
                Object.keys(data).map((objectKey, index) => {
                    this.congresses.push(new Congress(data[objectKey]));
                });
                this.modalService.open(this.modalFromGoogle);
            })
        }
    }

    showPartnersOnly(): void {
        this.country_id = 0;
        this.filterByCountry(this.country_id);
        this.getCountries(this.filtered_we_list);
    }

    getCountries(events) {
        const countries = {};
        const trans = this.translationService.getTranslatedName;
        this.countries_list = [];
        events.forEach((e, i) => {
            if (e.country) {
                countries[e.country.id] = e.country;
            }
        });
        for (const key of Object.keys(countries)) {
            this.countries_list.push(countries[key]);

        }
        this.countries_list = this.countries_list.sort((a, b) => {
            if (trans(a.translations, this.locale) < trans(b.translations, this.locale)) {
                return -1;
            } else if (trans(a.translations, this.locale) > trans(b.translations, this.locale)) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    filterByCountry(value): void {
        this.country_id = parseInt(value, 10);
        if (this.show_partners) {
            this.filtered_we_list = this.worldevents_list.filter(e => e.tag);
        } else {
            this.filtered_we_list = Array.from(this.worldevents_list);
        }

        if (this.country_id) {
            this.filtered_we_list = Array.from(this.filtered_we_list).filter(e => {
                return e.country ? e.country.id === this.country_id : false;
            });
        } else {
            this.filtered_we_list = Array.from(this.filtered_we_list);
        }

        if (!this.country_id && !this.show_partners) {
            this.filtered_we_list = Array.from(this.worldevents_list);
        }
        this.groupEventsByDate();
    }

    groupEventsByDate() {
        const grouped = {};
        this.grouped_list = [];
        const formatter = new Intl.DateTimeFormat(this.locale, {month: 'long'});
        const eventDateFormatter = new Intl.DateTimeFormat(this.locale, {day: '2-digit', month: 'long'});
        this.filtered_we_list.forEach((e, i) => {
            const start = new Date(e.starting_date);
            const end = new Date(e.ending_date);
            const key = start.getFullYear() + '' + ('0' + (start.getMonth() + 1)).slice(-2) + '';
            if (!grouped[key]) {
                grouped[key] = {
                    key: key,
                    month: formatter.format(start),
                    year: start.getFullYear(),
                    events: []
                };
            }
            e.start_date = eventDateFormatter.format(start);
            e.end_date = eventDateFormatter.format(end);
            grouped[key].events.push(e);
        });
        for (const key of Object.keys(grouped)) {
            const events = [];
            for (const event of Object.keys(grouped[key].events)) {
                events.push(grouped[key].events[event]);
            }
            grouped[key].events = events.sort((a, b) => {
                if (a.starting_date < b.starting_date) {
                    return -1;
                } else if (a.starting_date > b.starting_date) {
                    return 1;
                } else {
                    return 0;
                }
            });
            this.grouped_list.push(grouped[key]);
            this.grouped_list = this.grouped_list.sort((a, b) => {
                if (a.key < b.key) {
                    return -1;
                } else if (a.key > b.key) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

    }

    getEventsPaired(listEvent: Array<any>, isPair: boolean) {
      const even = [];
      const odd = [];

      for (let i = 0; i < listEvent.length; i ++) {
        if ( i % 2 === 0 && isPair ) {
          even.push(listEvent[i]);
        } else if (  i % 2 !== 0 && !isPair ) { odd.push(listEvent[i]); }

      }
      if ( isPair ) { return even; } else { return odd; }
    }

    clickToggle() {
        if (!this.show_partners) {
            this.router.navigateByUrl('/explore/worldwide-events/partners');
        } else {
            this.router.navigateByUrl('/explore/worldwide-events');
        }
        this.show_partners = !this.show_partners;
        this.showPartnersOnly();
    }

    getWorldWideEvents(): void {
        const rightNow = new Date();
        const res = rightNow.toISOString().slice(0, 10);
        rightNow.setFullYear(rightNow.getFullYear() + 2)
        const twoYearsAfter = rightNow.toISOString().slice(0, 10);
        this.countries_list = [];
        this.loading = true;
        this.worldwideEventsService.get(res, 0, false, twoYearsAfter, true)
            .subscribe(r => {
                this.loading = false;
                this.worldevents_list = r;
                this.filtered_we_list = r;
                this.showPartnersOnly();
            });
    }
}

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-covid-modal',
  templateUrl: './covid-modal.component.html',
  styleUrls: ['./covid-modal.component.scss']
})
export class CovidModalComponent implements OnInit {
  content: string = null;
  constructor(public activeModal: NgbActiveModal, public router: Router, private authService: AuthService) { }

  ngOnInit() {
    const currentUser = this.authService.getCurrentUser();
    if (environment.domain === 'aesthetics') {
      this.content = 'imcas';
    } else if (currentUser.user_specialty_id === 64) {
      this.content = 'orthoptist';
    } else {
      this.content = 'ophthalmologist';
    };
  }
  activate() {

    this.activeModal.dismiss();
    this.router.navigate(['/account/create/thanks']);
  }
}

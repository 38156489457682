<div class="page">
    <app-section-top [title]="'academy.Alert'" [subtitle]="'alert.Main_subtitle'|translate"
                     [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-alert.jpg'"></app-section-top>
    <div [className]="!environment.mobile ? 'content alert-list' : 'content alert-list has-back-button-padding-top-15'">

        <div [className]="environment.mobile ? 'row mt-2 justify-content-around' : 'row mt-2'" >
            <div class="col-md-6 breadcrumbs">
                <ul>
                    <li>
                        <a [routerLink]="['/alert']">{{'premium.IMCAS_Alert'|translate}}</a>
                    </li>
                    <li>
                        <span *ngIf="group">{{translationService.getTranslatedAttribute('name', group.translations, locale)}}</span>
                    </li>
                </ul>
            </div>
            <a *ngIf="environment.mobile" (click)="historyService.goBack()" class="return-button button button-blue" style="margin-bottom: 10px; margin-left: 10px; color: white;">
                <i class="fa fa-chevron-left" style="margin-right: 5px"></i>{{'academy.Return'|translate}}
            </a>
            <div [className]="environment.mobile ? 'submit' : 'col-md-6 submit'" *ngIf="group">
                <a *ngIf="group.user_access == 'granted'" [routerLink]="['/alert', slug ,'submit', group.id]"
                   class="button button-orange">{{'alert.Submit_case'|translate}}</a>
                <a *ngIf="group.user_access == 'unauthorized' || group.user_access == 'waiting'" [routerLink]="['/alert', slug ,'join', group.id]"
                   class="button button-orange">{{'alert.Join_group'|translate:{title:
                        translationService.getTranslatedName(group.translations)} }}</a>
            </div>
        </div>
        <app-alert-introduction *ngIf="environment.domain == 'ophthalmology'" [locale]="locale"
                                (playerReady)="detachChangeStrategy()"></app-alert-introduction>
        <app-alert-coordinators *ngIf="environment.domain == 'aesthetics' && group" [coordinators]="group.coordinators" [chairs]="group.chairs"></app-alert-coordinators>
        <div class="keywords">
            <h6 *ngIf="group && environment.domain === 'ophthalmology'">{{'evaluation.Select_a_theme'|translate}}:</h6>
            <select *ngIf="group && environment.domain === 'ophthalmology'"
                    id="theme"
                    name="theme"
                    class="alert-filters-select"
                    [ngModel]="selectedTheme"
                    (ngModelChange)="themeChange($event)">
                <option [ngValue]="undefined">{{'evaluation.Select_a_theme'|translate}}</option>
                <option *ngFor="let option of group.themes"
                        [ngValue]="option">
                    {{translationService.getTranslatedName(option['translations'], locale)}}
                </option>
            </select>
            <div *ngIf="group && environment.domain === 'aesthetics' " class="d-flex flex-column">
              <app-search-ui [alertGroupId]="group.id" [alertCasesIndex]="true">

              </app-search-ui>
              <div class="d-flex" style="gap: 1em">
                <select
                  id="purposes"
                  name="purposes"
                  class="alert-filters-select"
                  [(ngModel)]="selectedPurpose"
                  (ngModelChange)="keywordChange()">
                  <option [ngValue]="null">{{'alert.choose_purpose'|translate}}</option>
                  <option *ngFor="let option of purposes"
                          [ngValue]="option.id">
                    {{option.text}}
                  </option>
                </select>
                <select
                  id="complications"
                  name="complications"
                  class="alert-filters-select"
                  [(ngModel)]="selectedComplication"
                  (ngModelChange)="keywordChange()">
                  <option [ngValue]="null">{{'alert.choose_complication'|translate}}</option>
                  <option *ngFor="let option of complications"
                          [ngValue]="option.id">
                    {{option.text}}
                  </option>
                </select>
              </div>
            </div>

        </div>
        <app-loader *ngIf="loading"></app-loader>

        <div *ngIf="!loading && error">
            {{ error }}
        </div>
        <table #casesTable *ngIf="cases && !loading"  style="margin-bottom: 3em; {{environment.mobile ? ' margin-top: 2em' : ''}}">
            <thead>
            <tr>
                <th>{{'account.Language'|translate}}</th>
                <th>{{'alert.Case_title'|translate}}</th>
                <th style="min-width: 160px;" *ngIf="environment.domain == 'ophthalmology'">{{'academy.Themes'|translate}}</th>
                <th>{{'alert.Type_case'|translate }}</th>
                <th style="min-width: 150px;">{{'alert.Advisors'|translate}}</th>
                <th>{{'alert.Replies'|translate}}</th>
                <th>{{'company_profile.Views'|translate}}</th>
                <th style="min-width: 85px;">{{'alert.Last_activity'|translate}}</th>
                <th style="min-width: 35px;">Closed</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let caseAlert of cases" (click)="open(caseAlert)">
                <td>
                    <img [src]="environment.cdn_base_url + '/assets-front/img/flags/' +  caseAlert.locale + '.svg'" alt="">
                </td>
                <td class="mobile-only">
                    <span class="tag {{ caseAlert.type }}" [ngbTooltip]="getTooltipInfo(caseAlert.type) | translate">
                      {{'alert.' + caseAlert.type.charAt(0).toUpperCase() + caseAlert.type.substr(1).toLowerCase()|translate}}
                    </span>
                    <a [routerLink]="['/alert', slug , caseAlert.id, getSlug(caseAlert.title)]" style="margin-left: 8px">{{caseAlert.title}}</a>
                </td>
                <td class="desktop-only">
                    <a [routerLink]="['/alert', slug , caseAlert.id, getSlug(caseAlert.title)]">{{caseAlert.title}}</a>
                </td>
                <td *ngIf="environment.domain == 'ophthalmology'">
                    <span class="tag themes" [style.background-color]="theme.color_code" *ngFor="let theme of caseAlert.themes">
                      {{translationService.getTranslatedName(theme['translations'], locale)}}
                    </span>
                </td>
                <td class="desktop-only">
                    <span class="tag {{ caseAlert.type }}" [ngbTooltip]="getTooltipInfo(caseAlert.type) | translate">
                      {{'alert.' + caseAlert.type.charAt(0).toUpperCase() + caseAlert.type.substr(1).toLowerCase()|translate}}
                    </span>
                </td>
                <td>
                    <div *ngFor="let advisor of caseAlert.advisors_pictures_urls; index as i"
                         [style.background]="'url(' + advisor + ')'" [style.background-size]="'cover'"
                         class="rounded-circle"></div>
                </td>
                <td [className]="environment.mobile ? 'd-flex justify-content-around' : ''">
                  <span style="display: table-cell">
                    {{caseAlert.comments_count}}
                      <span>{{(caseAlert.comments_count === 0 ? ('academy.Nb_reply_alet_case'|translate).split('|')[0]?.slice(4) : caseAlert.comments_count == 1 ? ('academy.Nb_reply_alet_case'|translate :{count: ''}).split('|')[1]?.slice(4) : ('academy.Nb_reply_alet_case'|translate :{count: ''}).split('|')[2]?.slice(8))}}
                     </span></span>
                    <span class="mobile-only">{{'alert.Last_activity'|translate}}: {{getLastActivityHumanWay(dateMomentService, caseAlert.last_activity)}}</span>
                    <span style="color:green" *ngIf="!caseAlert.open"><i class="fa fa-check"></i> {{'academy.Closed'|translate}}</span>
                </td>
                <td class="desktop-only">{{caseAlert.hits_count }} <span class="mobile-only">{{ 'pluralize.Views'|translate }}</span></td>
                <td class="desktop-only">{{getLastActivityHumanWay(dateMomentService, caseAlert.last_activity)}}</td>
                <td class="desktop-only" style="text-align:center;color:green"><i *ngIf="!caseAlert.open" class="fa fa-check"></i></td>
            </tr>
            </tbody>
        </table>
        <app-pagination-list *ngIf="cases && !loading" [meta]="meta" (actionEvent)="getNextCases($event)" [displayedPagesNumber]="environment.mobile ? 3 : 8"></app-pagination-list>
        <app-alert-coordinators *ngIf="environment.domain == 'ophthalmology'" [isBoard]="boards && boards.length > 0" [coordinators]="boards && boards.length > 0 ? boards : (group ? group.coordinators : [])"></app-alert-coordinators>

    </div>
</div>

import {Component, OnInit} from '@angular/core';
import {LocaleService} from '../../../services/locale.service';
import { Router } from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'tutorial-component',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss'],
    providers: [
    ]
})

export class TutorialComponent implements OnInit {

    currentSlide = 0;

    environment = environment;
    tutoImage1 ="";
    nbPhysicians;

    constructor(private localeService: LocaleService, private router: Router) {
      if (environment.domain == 'aesthetics'){
        this.tutoImage1 = 'assets/img/tutorial/tuto1.png';
        this.nbPhysicians = "35 500";
      }
      else {
        this.tutoImage1 = 'assets/img/tutorial/tuto1_aop.png';
        this.nbPhysicians = "6 200";
      }
    }

    ngOnInit() {
        setTimeout(() => {

          $('.tutorial-slider').slick({
            dots: true,
            appendDots: $('.tutorial-dots'),
            infinite: false,
            arrows:false,
            mobileFirst:true
          });

          $('.tutorial-slider').on('afterChange', (event, slick, currentSlide) => {
            this.currentSlide = currentSlide;
          })

        });


    }

    handleNextButton(){
      if (this.currentSlide < 4){
        this.currentSlide++;
        setTimeout(() => {
          $('.tutorial-slider').slick("slickGoTo", this.currentSlide);
        });
      }
      else {
        localStorage.setItem('tutorialSeen', "1");
        this.router.navigateByUrl('/home');
      }
    }

      skip() {
      localStorage.setItem('tutorialSeen', "1");
      this.router.navigateByUrl('/home'); // TO CHECK IF IT WORKS
    }
}

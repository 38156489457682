<h1 [innerHTML]="'academy.Certificates'|translate"></h1>
<div class="main-wrapper">
  <div *ngIf="years && years.length > 0 &&
  user.personal_token &&
  (user.specialty_category === 'physician' || user.specialty_category === 'non physician');
        else noCertificates">
    <div>{{'academy.Download_your_certificates'|translate}}</div>
    <div class="certificate-wrapper">
      <div *ngFor="let year of years" class="certificate-button">
        <a class="button-orange button"
           target="_blank"
           [href]="environment.base_url + '/' + this.locale + '/academy/account/membership-certificate/' + year + '/' + user.personal_token">
          <div>{{year}} {{'academy.Certificate'|translate}}</div>
        </a>
      </div>
    </div>
  </div>
  <ng-template #noCertificates>
    <div>{{'academy.No_certificates'|translate}}</div>
  </ng-template>
</div>

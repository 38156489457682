import {Component, OnInit} from '@angular/core';
import {WebPasswordReminderService} from '../../../services/web-password-reminder.service';
import {environment} from "../../../../environments/environment";


@Component({
    selector: 'app-password-reminder',
    templateUrl: './password-reminder.component.html',
    styleUrls: ['./password-reminder.component.scss'],
    providers: [
        WebPasswordReminderService
    ]
})
export class PasswordReminderComponent implements OnInit {
    env= environment;
    email: string;
    requestSent: boolean = false;
    success: boolean = false;
    dontExist: boolean = false;

    constructor(private webPasswordReminder: WebPasswordReminderService) {
    }

    ngOnInit() {
      setTimeout(() => {
        $('.line-input input').focus(function() {
          $(this).closest('.line-input').find('.floating-label').addClass('selected');
        });

        $('.line-input input').focusout(function() {
          if ($(this).val() == '') {
            $(this).closest('.line-input').find('.floating-label').removeClass('selected');
          }
        });

      })
    }

    sendReminder() {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(this.email).toLowerCase())) {
            this.requestSent = true;
            this.webPasswordReminder.sendReminder(this.email).subscribe(response => {
                this.success = true;
            }, err => {
              console.log(err);
              this.dontExist = true;
              this.requestSent = false;
            });
        }
    }
}

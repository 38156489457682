import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {UserService} from '../../services/user.service';


@Component({
    selector: 'app-edit-cover-modal',
    templateUrl: './edit-cover-modal.component.html',
    styleUrls: ['./edit-cover-modal.component.scss']
})
export class EditCoverModalComponent implements OnInit, AfterViewInit {
    @Input() profile: any;
    @Input() close: any;
    @Input() dismiss: any;

    public cover_images: Array<any> = [];
    public _gallerySlickOptions: any;
    public selectedCover: number;
    private coverImage: File;
    public coverData: string;

    constructor(private userService: UserService) {
        this.getCoverImagesFromGallery();
    }

    ngOnInit() {
        this._gallerySlickOptions = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            dots: false,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-long-arrow-left" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>',
        };
        this.selectedCover = -1;
        this.coverImage = null;
    }

    ngAfterViewInit() {
        $('.gallery').slick(this._gallerySlickOptions);
    }

    selectedCoverFromGallery(index: number) {
        this.selectedCover = index;
    }

    getCoverImagesFromGallery(): void {
        for (let i = 1; i < 16; i++) {
            this.cover_images.push(
                {
                    index: i,
                    image: 'https://comexposium-healthcare-assets.s3.eu-west-1.amazonaws.com/users/covers/' + i + '.jpg'
                });
        }
    }

    onCoverImageFileChange(event) {
        this.coverImage = event.target.files[0];
        const myReader: FileReader = new FileReader();
        const that = this;
        myReader.onloadend = function (event: any) {
            that.coverData = event.target.result;
        };
        myReader.readAsDataURL(this.coverImage);
    }

    uploadCoverImage(coverImage: string) {
        const i = ('' + Math.random() * 1000000).split('.')[0];
        const data = {
            'images[cover][name]': 'cover-picture' + i,
            'images[cover][content]': coverImage,
            'images[cover][format]': this.coverImage.type
        };

        this.userService.uploadNewImage(data)
            .subscribe(r => {
            })
    }

    setCoverFromGallery() {
        this.userService.setCoverFromGallery(this.selectedCover).subscribe(r => {
        });
    }

    setCoverImage() {
        if (this.coverImage) {
            this.uploadCoverImage(this.coverData);
            this.dismiss();
        } else {
            if (this.selectedCover >= 0) {
                this.setCoverFromGallery();
                this.dismiss();
            }
        }
    }
}

import { Component, OnInit, Input, HostListener } from '@angular/core';
import {LinkService} from "../../../services/link.service";

@Component({
  selector: 'custom-inner-html',
  templateUrl: './custom-inner-html.component.html',
  styleUrls: ['./custom-inner-html.component.scss']
})
export class CustomInnerHtmlComponent implements OnInit {

  @Input() content: any;
  @Input() system: any = false;

  outputArray = [];

  constructor(public linkService: LinkService) { }

  ngOnInit() {


  }

  @HostListener('click', ['$event'])
  onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    let target = e.target;
    while (target) {
      if (target instanceof HTMLAnchorElement) {
        this.linkService.openBlank(target.getAttribute('href'), this.system)
        break;
      }

      target = target.parentNode;
    }


  }

}

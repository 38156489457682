import {CompanyProfileModel} from './company-profile.model';
import {Comment} from './comment.model';
import {Theme} from './theme.model';
import {TranslationService} from '../services/translation.service';


export class ArticleModel {
  id: number;
  author: any[];
  authors: any[];
  articleTitle: any[];
  translationService: TranslationService = new TranslationService();
  comments: Array<Comment>;
  company_profile: CompanyProfileModel;
  content: string;
  link: string;
  main_academy_media: any;
  min_to_read: number;
  pictures_url: { header_url: string, thumbnail: string };
  public: boolean;
  sponsored: number | boolean;
  pdf: any;
  publication_date: Date;
  related: any[];
  related_academy_medias: any[];
  short_description: string;
  slug: string;
  status: any;
  themes: Array<Theme>;
  title: string;
  zone: string;
  other_authors: string;
  theme1_id: number;
  theme2_id: number;
  theme3_id: number;
  disclosure1: any;
  disclosure2: any;
  disclosure3: any;
  disclosure4: any;
  disclosure5: any;
  disclosure1_details: string;
  disclosure2_details: string;
  disclosure3_details: string;
  disclosure4_details_date: string;
  disclosure4_details_status: string;
  disclosure5_details_sponsor: string;
  disclosures: any;
  user1: number;

  getSlug() {
    if (this.slug) {
      return this.slug;
    } else {
      const englishTitle = this.translationService.getTranslatedTitle(this.articleTitle, 'en');
      return englishTitle.toString().toLowerCase()
        .replace('à', 'a')
        .replace('ç', 'c')
        .replace('é', 'e')
        .replace('è', 'e')
        .replace('ê', 'e')
        .replace('ë', 'e')
        .replace('ö', 'o')
        .replace('ô', 'o')
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
    }
  }

  constructor(obj?: any) {
    this.sponsored = !!(obj && obj.sponsored);
    if (obj) {
      this.id = obj.id;
      this.author = obj.author;
      this.authors = obj.authors;
      this.articleTitle = obj && obj.articleTitle;
      this.comments = obj.comments || [];
      this.company_profile = obj.company_profiles;
      this.pdf = obj.pdf;
      this.content = obj.content;
      this.link = obj.link;
      this.main_academy_media = obj.main_academy_media;
      this.min_to_read = obj.min_to_read;
      this.pictures_url = obj.pictures_url;
      this.public = obj.public;
      this.publication_date = obj.publication_date;
      this.related = obj.related;
      this.related_academy_medias = obj.related_academy_medias || [];
      this.short_description = obj.short_description;
      this.slug = obj.slug;
      this.status = obj.status;
      this.themes = obj.themes || [];
      this.title = obj.title;
      this.zone = obj.zone;
      this.other_authors = obj.other_authors;
      this.theme1_id = obj.theme1_id;
      this.theme2_id = obj.theme2_id;
      this.theme3_id = obj.theme3_id;
      this.disclosure1 = obj.disclosure1;
      this.disclosure2 = obj.disclosure2;
      this.disclosure3 = obj.disclosure3;
      this.disclosure4 = obj.disclosure4;
      this.disclosure5 = obj.disclosure5;
      this.disclosure1_details = obj.disclosure1_details;
      this.disclosure2_details = obj.disclosure2_details;
      this.disclosure3_details = obj.disclosure3_details;
      this.disclosure4_details_date = obj.disclosure4_details_date;
      this.disclosure4_details_status = obj.disclosure4_details_status;
      this.disclosure5_details_sponsor = obj.disclosure5_details_sponsor;
      this.disclosures = obj.disclosures;
      this.user1 = obj.user1;
    }
  }
}

<div class="ui-search" [ngClass]="{'transparent' : transparent, 'non-transparent' : !transparent}"  (clickOutside)="close($event)">
  <input (keyup)="launchSearch($event)" type="text" [(ngModel)]="search" class="textinput"
         placeholder="{{getPlaceholder()|translate}}" [disabled]="disabled"/>
  <a (click)="launchGlobalSearch($event)" [className]="disabled ? 'ui-search-button disabled-button' : 'ui-search-button'"><span class="icon icon-search-white"></span></a>
  <div *ngIf="results > 0" class="ui-search-algolia-suggest">
    <span class="title" *ngIf="topics.length > 0" id="topics">{{ 'academy.Topics'|translate }}</span>
    <ul>
      <li *ngFor="let topic of topics" (click)="openUrl('topics', topic)"
          [innerHTML]="getTitlesTranslated(topic._highlightResult.topicsTitles)"></li>
    </ul>
  <!--  <span class="title" *ngIf="products.length > 0" id="products">{{ 'marketplace.Marketplace'|translate }}</span>
    <ul>
      <li *ngFor="let product of products" (click)="openUrl('products', product)"
          [innerHTML]="getTitlesTranslated(product._highlightResult.productNames)"></li>
    </ul>-->
    <span class="title" *ngIf="companies.length > 0" id="companies">{{ 'academy.Company_profiles'|translate }}</span>
    <ul>
      <li *ngFor="let company of companies" (click)="openUrl('companies', company)"
          [innerHTML]="company._highlightResult.company_name.value"></li>
    </ul>
    <span class="title" *ngIf="pages.length > 0" id="pages">{{ 'academy.Pages'|translate }}</span>
    <ul>
      <li *ngFor="let page of pages" (click)="openUrl('pages', page)"
          [innerHTML]="page._highlightResult.name.value"></li>
    </ul>
    <span class="title" *ngIf="users.length > 0" id="users">{{ 'home.Members'|translate}}</span>
    <ul>
      <li *ngFor="let user of users"><span (click)="openUrl('users', user)" [innerHTML]="user._highlightResult.fullname.value"></span> <span *ngIf="canAskForFriendship(user)" (click)="emitSendConnection(user)" class="connections-btn">{{ 'attend.Add_to_my_contacts'|translate }}</span></li>
    </ul>
    <a [routerLink]="['/account', 'connections']" class="more-members" *ngIf="linkToConnectionsPage && usersHitsPerPage == 2">{{ 'academy.Search_more_members'|translate }}</a>
    <span class="title" *ngIf="congresses.length > 0" id="congresses">{{ 'academy.Congresses'|translate}}</span>
    <ul>
      <li *ngFor="let congress of congresses" (click)="openUrl('congresses', congress)"
          [innerHTML]="congress._highlightResult.name.value"></li>
    </ul>
    <span class="title" *ngIf="courses.length > 0" id="courses">{{ 'pluralize.Courses'|translate}}</span>
    <ul>
      <li *ngFor="let course of courses" (click)="openUrl('courses', course)"
          [innerHTML]="getTitlesTranslated(course._highlightResult.courseTitles)"></li>
    </ul>
    <span class="title" *ngIf="articles.length > 0" id="articles">{{ 'company_profile.Articles'|translate }}</span>
    <ul>
      <li *ngFor="let article of articles" (click)="openUrl('articles', article)">
        <span [innerHTML]="article._highlightResult.articleTitle.value"></span> - <span style="font-size: 10px">{{displayAuthors(article)}}</span>
      </li>
    </ul>
    <span class="title" *ngIf="medias.length > 0" id="medias">{{ 'academy.Lectures'|translate}}</span>
    <div *ngFor="let media of medias"
         (click)="openUrl('medias', media)"
         class="medias col col-50">
      <div class="wrapper">
        <img [src]="cdn_url + '/medias/thumbs/' + media.mediaID + '.jpg'" alt="">
        <span class="media-title-wrapper" [innerHTML]="getTitlesTranslated(media._highlightResult.mediaTitles)"></span>
        <span class="author"
              [innerHTML]="getAuthorsString(media._highlightResult.users)"></span>
      </div>
    </div>
    <span class="title" *ngIf="alertCases.length > 0" id="alertCases">Alert Cases</span>
    <ul>
      <li *ngFor="let alertCase of alertCases" (click)="openUrl('alertCases', alertCase)"
          [innerHTML]="alertCase._highlightResult.alertCaseTitle.value"></li>
    </ul>
  </div>
</div>

export class PaginationMetaModel {
  current_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
  path: string;
  constructor(obj?: any) {
    this.current_page = obj && obj.current_page;
    this.per_page = obj && obj.per_page;
    this.from = obj && obj.from;
    this.to = obj && obj.to;
    this.total = obj && obj.total ;
    this.path = obj && obj.path;

  }
}

import {LocaleService} from 'app/services/locale.service';
import {Media} from '../models/media.model';
import {AcademyMedia} from '../models/academy-media.model';
import {WorldwideEvent} from '../models/worldwide-event.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {map, shareReplay} from 'rxjs/operators';

@Injectable()
export class MediaService {
    private academyMediasEndpoint: string = '/academy_medias';
    private mediasEndpoint: string = '/medias';

    constructor(private httpClient?: HttpClient,
                private router?: Router,
                private localeService?: LocaleService) {
    }

    getAcademyMedia(mediaId: number, params?: any): Observable<AcademyMedia> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<AcademyMedia>(environment.apiURL + this.academyMediasEndpoint + '/' + mediaId, reqOpts)
            .pipe(shareReplay());
    }

    getLocales(): Observable<any> {
        return this.httpClient.get<any>(environment.apiURLV2 + this.academyMediasEndpoint + '/locales').pipe(shareReplay());
    }

    getAcademyMedias(params?: any): Observable<AcademyMedia[]> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                reqOpts.params = reqOpts.params.set(k, '' + params[k]);
            }
        }
        return this.httpClient.get<AcademyMedia[]>(environment.apiURLV2 + this.academyMediasEndpoint, reqOpts);
    }

    getAcademyMediasCount(): Observable<any> {
        return this.httpClient.get<any>(environment.apiURLV2 + this.academyMediasEndpoint + '/count').pipe(shareReplay());
    }

    getMedia(mediaId: number, params?: any): Observable<Media> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<Media>(environment.apiURL + this.mediasEndpoint + '/' + mediaId, reqOpts).pipe(shareReplay());
    }

    getWorldwideEvents(): Observable<WorldwideEvent[]> {
        return this.httpClient.get<WorldwideEvent[]>(environment.apiURLV2 + this.academyMediasEndpoint + '/worldwide_events')
            .pipe(map(events => events.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            })), shareReplay());
    }

    addMediaToFavorite(mediaId: number): Observable<any> {
        return this.httpClient.post(environment.apiURLV2 + this.academyMediasEndpoint + '/' + mediaId + '/favorites', null).pipe(shareReplay());
    }

    addMediaView(mediaId: number, fullVideo: boolean): Observable<any> {
        return this.httpClient.post(environment.apiURL + this.mediasEndpoint + '/' + mediaId + '/add-view', {fullVideo: fullVideo}).pipe(shareReplay());
    }

    addMediaProgress(mediaId: number, position: number): Observable<any> {
        return this.httpClient.post(environment.apiURLV2 + this.academyMediasEndpoint + '/' + mediaId + '/progress', {position: position})
            .pipe(shareReplay());
    }

    addMediaTrueView(mediaId: number, courseId: number): Observable<any> {
        return this.httpClient.post(environment.apiURL + this.academyMediasEndpoint + '/' + mediaId + '/true-view',
            courseId ? {academy_course_id: courseId} : null)
            .pipe(shareReplay());
    }

    addMediaComment(mediaId: number, comment: string): Observable<any> {
        return this.httpClient.post(environment.apiURL + '/comments', {
            entity_type: 'academy_media',
            entity_id: mediaId,
            content: comment,
            public: 1
        }).pipe(shareReplay());
    }

    addMediaRate(mediaId: number, currentRate: number): Observable<any> {
        return this.httpClient.post(environment.apiURLV2 + this.academyMediasEndpoint + '/' + mediaId + '/rate', {
            rate: currentRate
        }).pipe(shareReplay());
    }

    acceptVideo(mediaId: number): Observable<any> {
        return this.httpClient.put<any>(environment.apiURL + this.mediasEndpoint + '/' + mediaId + '/approval', null)
            .pipe(shareReplay());
    }

    declineVideo(mediaId: number): Observable<any> {
        return this.httpClient.delete<any>(environment.apiURL + this.mediasEndpoint + '/' + mediaId + '/approval')
            .pipe(shareReplay());
    }

    goToVideo(video) {
        this.router.navigateByUrl('/show/' + video.id + '/'
            + video.media.translations[0].title.toLowerCase()
                .replace(/ /g, '-')
                .replace(/&/g, 'and')
                .replace(/[^\w-]+/g, ''));
    }

    getTopTenVideosByYear(year): Observable<AcademyMedia[]> {
        return this.httpClient.get<any[]>(environment.apiURL + this.academyMediasEndpoint + '/top_ten/' + year).pipe(
            map(medias => medias.map(media => {
                const titleObj = {};
                media.media['translations'].forEach(title => titleObj[title.locale] = title.title);
                media.mediaTitles = titleObj;
                media.media.thumb = environment.cdn_base_url + '/medias/thumbs/' + media.media_id + '.jpg';
                media.media.languages = media.media['languages'].split(',');

                const seconds = (media.media.duration % 60).toString().length > 1 ? (media.media.duration % 60) : '0' + (media.media.duration % 60);
                media.media.duration = Math.floor(media.media.duration / 60) + ':' + seconds;
                return media;
            }))
        )
    };

}

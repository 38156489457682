import {Course} from '../models/course.model';
import {Session} from '../models/session.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class CourseService {
    private endpoint: string = '/academy_courses';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getCourse(id: number, params?: any): Observable<Course> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<Course>(environment.apiURLV2 + this.endpoint + '/' + id, reqOpts).pipe(shareReplay());
    }

    getCourses(params?: any): Observable<Course[]> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        return this.httpClient.get<Course[]>(environment.apiURLV2 + this.endpoint, reqOpts).pipe(shareReplay());
    }

    getCourseSessions(courseId: number): Observable<Session[]> {
        const reqOpts = {
            params: new HttpParams().set('with_session_users', '1').set('session_congress', '1')
        };
        return this.httpClient.get<Session[]>(environment.apiURLV2 + this.endpoint + '/' + courseId + '/sessions', reqOpts)
            .pipe(shareReplay());
    }

    addCourseToFavorite(courseId: number): Observable<any> {
        return this.httpClient.post(environment.apiURLV2 + this.endpoint + '/' + courseId + '/favorites', null).pipe(shareReplay());
    }

    validateAnswersSurvey(courseId: number, answerIds: number[]): Observable<any> {
        return this.httpClient.post(environment.apiURLV2 + this.endpoint + '/' + courseId + '/answers', {answers: answerIds})
            .pipe(shareReplay());
    }
    getCoursesCount(): Observable<any> {
        return this.httpClient.get<any>(environment.apiURLV2 + this.endpoint + '/count').pipe(shareReplay());
    }
}

import {Locales} from '../../../locales';
import {Keyword} from 'app/models/keyword.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {TranslationService} from 'app/services/translation.service';
import {AlertService} from 'app/services/alert.service';
import {environment} from '../../../../environments/environment';
import {DateMomentService} from 'app/services/date-moment.service';
import {User} from 'app/models/user.model';
import {AuthService} from 'app/services/auth.service';
import {Theme} from 'app/models/theme.model';
import {LocaleService} from '../../../services/locale.service';
import {Case} from '../../../models/case.model';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {HistoryService} from "../../../services/mobile-services/history.service";
import {PaginationMetaModel} from "../../../models/pagination-meta.model";
import {KeywordService} from "../../../services/keyword.service";

export class ExtendedCase  extends Case {
  advisors_pictures_urls: any[];

  constructor(obj?: any) {
    super(obj);
    this.advisors_pictures_urls = obj && obj.advisors_pictures_urls;
  }
}
@Component({
    selector: 'alert-list',
    templateUrl: './alert-list.component.html',
    styleUrls: ['./alert-list.component.scss'],
    providers: [
        AlertService,
        KeywordService
    ],
})
export class AlertListComponent implements OnInit {
    locale: any;
    environment: any = environment;
    currentUser: User;
    slug: string;
    group: any;
    cases: ExtendedCase[];
    currentPage: number = 1;
    keywordId: number;
    selectedTheme: Theme;
    selectedPurpose: Keyword = null;
    selectedLocale: any;
    locales = Locales;
    boards: Array<any>;
    loading: boolean = false;
    error: string = '';
    caseType: any[] = [];
    meta: PaginationMetaModel;
    purposes: any[] = [];
    selectedComplication: Keyword = null;
    complications: any[] =[];

    constructor(private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                private translateService: TranslateService,
                public translationService: TranslationService,
                public dateMomentService: DateMomentService,
                public localeService: LocaleService,
                private router: Router,
                private authService: AuthService,
                protected localStorage: LocalStorage,
                public historyService: HistoryService,
                private keywordService: KeywordService) {
      this.caseType = this.alertService.TYPE_CASE;
    }

    ngOnInit() {
      this.loading = true;
        this.locale = this.localeService.currentLocale();
        this.activatedRoute.params.subscribe(params => {
            this.slug = params['group'];
        });
        this.currentUser = this.authService.getCurrentUser();
        if (!this.currentUser || !this.currentUser.id) {
            window.location.href = '/academy/login';
        }
        this.getGroup();


    }

    getGroup() {
        if (this.slug) 'group_' + this.slug; else 'group_/';

        this.alertService.getGroup(this.slug, {
            coordinators: '1',
            chairs: '1',
            keywords: '1',
            complications: '1',
            treatments: '1',
            purposes: '1',
            themes: '1'
        }).subscribe(
            currentGroup => {
                this.group = currentGroup;
                this.alertService.getV2Cases({'group_id': this.group.id}).subscribe(result => {
                  this.setDataGetCases(result);
                  this.loading = false;
                  this.meta = new PaginationMetaModel(result.meta);
                });
                this.setSelectValues(currentGroup);

            },
            error => {
                this.router.navigate(['/', 'alert'], {queryParams: {error: 'not-physician'}});
            }
        );
    }

    setSelectValues(group) {
      this.purposes.push(...group.purposes.map((item)=>{
        return {
          id: item.id,
          text: this.translationService.getTranslatedName(item.translations, this.locale)
        }
      }));
      this.keywordService.getKeywords(group.purposes.map(item => item.id)).subscribe(keywords => {
        for (let i = 0; i < keywords.length; i++) {
          for (let j = 0; j < keywords[i].complications.length; j++) {
            let translation = this.translationService.getTranslatedName(keywords[i].complications[j].translations, this.locale)
            this.complications.push({
              id: keywords[i].complications[j].id,
              text: translation != "n/a" ? translation : keywords[i].complications[j]["name"]
            });
          }
        }
        this.complications = this.complications.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.id === thing.id
            ))
        );
        this.sortPurposesAndComplications();
      });
    }

    setDataGetCases(cases) {
        this.loading = false;
        this.cases = [];
      if (cases.data && cases.data.length > 0) {
        cases.data.forEach(caseObj => {
          this.cases.push(new ExtendedCase(caseObj));
        });
      }
      if (cases.meta && cases.meta.boards && cases.meta.boards.length > 0) {
        cases.meta.boards.forEach(userObj => {
          this.boards.push(new User(userObj));
        });
      }
    }

    themeChange(theme) {
        this.router.navigate([], {
            queryParams: {
                theme_id: theme ? theme.id : null,
                keyword_id: this.keywordId,
                page: null,
                locale: this.selectedLocale
            },
            relativeTo: this.activatedRoute
        });
    }

    getSlug(string) {
        return this.translationService.getSlug(string);
    }

    getTooltipInfo(caseType) {
        return this.caseType.find(type => type.value === caseType).tooltip;

    }

    open(alertCase) {
      this.router.navigate(['/alert', this.slug , alertCase.id, this.getSlug(alertCase.title)])
    }

  getNextCases($event) {
      this.loading = true;
      this.cases = [];
      this.alertService.getV2Cases({'group_id': this.group.id, 'page': $event.param}).subscribe(result => {
        if (result.data && result.data.length > 0) {
          result.data.forEach(caseObj => {
            this.cases.push(new ExtendedCase(caseObj));
          });
          this.loading = false;
          this.meta = result.meta;
        }
      });
  }

  keywordChange() {
      this.cases = [];
      this.loading= true;
      const body = {
        'group_id': this.group.id,
        'complication_id': this.selectedComplication,
        'purpose_id': this.selectedPurpose
      };
      this.alertService.getV2Cases(body).subscribe(result => {
        if (result.data && result.data.length > 0) {
          result.data.forEach(caseObj => {
            this.cases.push(new ExtendedCase(caseObj));
          });
        }
        this.loading = false;
        this.meta = result.meta;
      })
  }

  getLastActivityHumanWay(moment, lastActivity) {
      return moment.formatHumanWay(lastActivity);
  }

  sortPurposesAndComplications() {
    this.purposes.sort((a, b) =>  {
      const title = a.text.toLowerCase();
      const title2 = b.text.toLowerCase();
      if(title < title2) {
        return -1;
      }
      if (title > title2) {
        return 1;
      }
      return 0;
    });
    this.complications.sort((a, b) =>  {
      const title = a.text.toLowerCase();
      const title2 = b.text.toLowerCase();
      if(title < title2) {
        return -1;
      }
      if (title > title2) {
        return 1;
      }
      return 0;
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {MagazineService} from '../../../services/magazine.service';
import {Magazine} from '../../../models/magazine.model';
import {CourseService} from '../../../services/course.service';
import {Course} from '../../../models/course.model';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AcademyMedia} from '../../../models/academy-media.model';
import {MediaService} from '../../../services/media.service';
import {User} from '../../../models/user.model';
import {UserService} from '../../../services/user.service';
import {Webinar} from '../../../models/webinar.model';
import {WebinarService} from '../../../services/webinar.service';
import {DateMomentService} from '../../../services/date-moment.service';
import {AlertService} from '../../../services/alert.service';
import {ArticleService} from '../../../services/article.service';
import {ArticleModel} from '../../../models/article.model';
import {LocaleService} from '../../../services/locale.service';
import {TwitterService} from '../../../services/twitter.service';
import {Tweet} from '../../../models/tweet.model';
import {ThemeService} from '../../../services/theme.service';
import {Theme} from '../../../models/theme.model';
import * as $ from 'jquery';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EndingMembershipModalComponent} from '../../modal/ending-membership-modal/ending-membership-modal.component';
import {environment} from '../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorage} from '@ngx-pwa/local-storage';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [
        MagazineService,
        CourseService,
        MediaService,
        UserService,
        ArticleService,
        AlertService,
        WebinarService,
        TwitterService,
        ThemeService
    ]
})
export class DashboardComponent implements OnInit {
    magazine: Magazine;
    courses: Array<Course>;
    academyMedias: Array<AcademyMedia> = [];
    locale: any;
    cases: any = [];
    upcomingWebinars: Webinar[];
    tweets: Array<any>;
    articles: ArticleModel[];
    themes: Array<Theme>;
    user: User;
    connected: boolean;
    env: any;
    realHeading: any;
    nbAcademyMedias: number;
    isAop: boolean = environment.domain === 'ophthalmology';
    mediasHits: number;

    dataReceivedFromWeb = [];

    environment = environment;

    constructor(private magazineService: MagazineService,
                private courseService: CourseService,
                private authService: AuthService,
                private themeService: ThemeService,
                private activatedRoute: ActivatedRoute,
                private mediaService: MediaService,
                private router: Router,
                private dateMomentService: DateMomentService,
                private webinarService: WebinarService,
                private articleService: ArticleService,
                private twitterService: TwitterService,
                private alertService: AlertService,
                private translate: TranslateService,
                private localeService: LocaleService,
                private modalService: NgbModal,
                private sanitizer: DomSanitizer,
                private userService: UserService,
                protected localStorage: LocalStorage) {
        this.locale = localeService.currentLocale();
        this.env = environment;
    }

    ngOnInit() {
        this.translate.get(this.env.domain === 'aesthetics' ? 'academy.Launching_title' : 'academy.Main_title_aop')
            .subscribe((res: string) => {
                const text = res;
                const text_length = text.length;
                this.realHeading = '';
                let i = 0;
                const timer = setInterval(() => {
                    this.realHeading = this.realHeading + text[i];
                    if (i < text_length - 1) {
                        i++;
                    } else {
                        clearInterval(timer);
                    }
                }, 30);
            });
        this.activatedRoute.queryParams.subscribe(params => {
            this.connected = params.connected;
        });
        this.user = this.authService.getCurrentUser();
        this.userService.getUser(this.user.id, {
            courses_in_progress: 0,
            courses_finished: 0,
            media_viewed: 0,
            medias_favorited: 1,
            courses_favorited: 1,
            only_media_ids: 1,
            only_course_ids: 1,
            user_country: 1,
            user_specialty: 1
        }).subscribe(
            user => {
                this.user = user;
                const currentUser = this.authService.getCurrentUser();
                if (environment.domain === 'aesthetics') {
                  if (currentUser.id === this.user.id) {
                    this.userService.getUserMembershipStatus(this.user.id).subscribe(data => {
                      try {
                        // this.modalService.open(ModalPartnerOfferComponent);
                      } catch (e) {
                      }

                      if (data.ending_date) {
                        const days = this.dateMomentService.getDifference(data.ending_date, this.dateMomentService.getNow());
                        if (days < 7 && days > 0 && !data.recurrence) {
                          const modalRef = this.modalService.open(EndingMembershipModalComponent);
                          modalRef.componentInstance.membershipInfo = data;
                          modalRef.result.then(() => {
                          }, () => {
                          });
                        }
                      }
                    });
                  }
                }
            });

        this.localStorage.getItem<any>('DASHBOARD-magazine_' + environment.domain).subscribe((magazine) => {
            if (!('magazine' in this.dataReceivedFromWeb) && magazine) {
                this.setDataGetLastMagazine(magazine);
            }
        });
        this.magazineService.getLastMagazine().subscribe(
            magazine => {
                this.setDataGetLastMagazine(magazine);

                this.dataReceivedFromWeb['magazine'] = true;
                this.localStorage.setItem('DASHBOARD-magazine_' + environment.domain, magazine).subscribe(() => {
                });
            },
            err => {

            }
        );

        this.courseService.getCourses({
            user_id: this.authService.getCurrentUser().id,
            per_page: 6,
            course_speakers: 1
        }).subscribe(
            courses => {
                this.courses = [];
                courses['data'].forEach(n => {
                    const courseObj: Course = new Course(n);
                    this.courses.push(courseObj);
                });
                setTimeout(function () {
                    const jqueryCourses = $('.courses-carousel');
                    if (jqueryCourses.length > 0) {
                        jqueryCourses.not('.slick-initialized').slick({
                            infinite: true,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            autoplay: false,
                            dots: false,
                            prevArrow: '.left-courses',
                            nextArrow: '.right-courses',
                            responsive: [
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                    }
                                },
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2
                                    }
                                }
                            ]
                        });
                    }
                }, 500);
            });

        this.localStorage.getItem<any>('DASHBOARD-lectures_' + environment.domain).subscribe((data) => {
            if (!('lectures' in this.dataReceivedFromWeb) && data) {
                this.setDataGetAcademyMedias(data);
            }
        });

        this.mediaService.getAcademyMediasCount().subscribe(
            count => {
                this.mediasHits = Math.floor(count.lecture / 100) * 100;
            });

        this.mediaService.getAcademyMedias({
            per_page: 3,
            academy_media_event: 0,
            academy_media_course: 0,
            media_cdn: 0,
            media_lecture: 0,
            media_speakers: 1,
            media_library: 1,
            lecture_user: 0,
            order_by: 'publication_date',
            order: 'desc'
        }).subscribe(data => {
            this.setDataGetAcademyMedias(data);
            this.dataReceivedFromWeb['lectures'] = true;
            this.localStorage.setItem('DASHBOARD-lectures_' + environment.domain, data).subscribe(() => {
            });
        });

        this.localStorage.getItem<any>('DASHBOARD-upcoming_webinars_' + environment.domain).subscribe((data) => {
            if (!('upcoming_webinars' in this.dataReceivedFromWeb) && data) {
                this.setDataGetUpcomingWebinars(data);
            }
        });
        this.webinarService.getUpcomingWebinars(this.dateMomentService.getNow(), {
            per_page: 2,
            webinar_picture_url: 1
        }).subscribe(data => {
            this.setDataGetUpcomingWebinars(data);
            this.dataReceivedFromWeb['upcoming_webinars'] = true;
            this.localStorage.setItem('DASHBOARD-upcoming_webinars_' + environment.domain, data).subscribe(() => {
            });
        });

        this.localStorage.getItem<any>('DASHBOARD-cases_' + environment.domain).subscribe((cases) => {
            if (!('cases' in this.dataReceivedFromWeb) && cases) {
                this.setDataGetCases(cases);
            }
        });
        this.alertService.getPreview().subscribe(cases => {
            this.setDataGetCases(cases);
            this.dataReceivedFromWeb['cases'] = true;
            this.localStorage.setItem('DASHBOARD-cases_' + environment.domain, cases).subscribe(() => {
            });
        });

        this.localStorage.getItem<any>('DASHBOARD-articles_' + environment.domain).subscribe((articles) => {
            if (!('articles' in this.dataReceivedFromWeb) && articles) {
                this.setDataGetArticles(articles);
            }
        });
        this.articleService.getArticles([], {
            per_page: '2',
            academy_blog: '1',
            public: '1',
            status: 'accepted'
        }).subscribe(articles => {
            this.setDataGetArticles(articles);
            this.dataReceivedFromWeb['articles'] = true;
            this.localStorage.setItem('DASHBOARD-articles_' + environment.domain, articles).subscribe(() => {
            });
        });

        this.localStorage.getItem<any>('DASHBOARD-last_tweets_' + environment.domain).subscribe((data) => {
            if (!('last_tweets' in this.dataReceivedFromWeb) && data) {
                this.setDataGetLastTweets(data)
            }
        });
        this.twitterService.getLastTweets().subscribe(data => {
            this.setDataGetLastTweets(data)
            this.dataReceivedFromWeb['last_tweets'] = true;
            this.localStorage.setItem('DASHBOARD-last_tweets_' + environment.domain, data).subscribe(() => {
            });
        });


        this.themeService.getThemesForUser(this.user.id).subscribe(data => {
            this.themes = [];
            data.forEach(n => {
                this.themes.push(new Theme(n));
            });
            setTimeout(function () {
                const jqueryThemes = $('.themes-carousel');
                if (jqueryThemes.length > 0) {
                    jqueryThemes.not('.slick-initialized').slick({
                        infinite: true,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        autoplay: false,
                        dots: false,
                        prevArrow: '.left-themes',
                        nextArrow: '.right-themes',
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            }
                        ]
                    });
                }
            }, 500);
        })
    }

    goToWebinar(webinar) {
        this.router.navigateByUrl('/webinar/' + webinar.slug);
    }

    setDataGetLastMagazine(magazine: any) {
        if (magazine) {
            this.magazine = magazine;
        } else {
            this.magazine = undefined;
        }
    }

    setDataGetAcademyMedias(data: any) {
        this.academyMedias = [];
        this.nbAcademyMedias = Math.round(data.total / 10) * 10;
        data['data']?.forEach(n => {
            const academyMediaObj: AcademyMedia = new AcademyMedia(n);
            this.academyMedias.push(academyMediaObj);
        });
    }

    setDataGetUpcomingWebinars(data: any) {
        this.upcomingWebinars = [];
        data?.forEach(webinar => {
            this.upcomingWebinars.push(new Webinar(webinar));
        });
    }

    setDataGetCases(cases: any) {
        this.cases = cases.data;
    }

    setDataGetArticles(articles: any) {
        this.articles = [];
        articles?.forEach(n => {
            const articleObj: ArticleModel = new ArticleModel(n);
            this.articles.push(articleObj);
        });
    }

    setDataGetLastTweets(data: any) {
        this.tweets = [];
        data?.forEach(n => {
            const tweetObj = new Tweet(n);
            this.tweets.push(tweetObj);
        });
    }

}

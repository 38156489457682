<div class="page">
  <div class="container">
    <div class="text-center">
      <i class="fas fa-check-circle"></i>
      <h1>{{ 'company_account_badges.Thank_you'|translate }}!</h1>
    </div>
    <br/>
    <p>
      You should have received a confirmation by email.
    </p>
    <p>
      {{ 'cart.IMCAS_Team' | translate }}
    </p>
    <br/>
    <br/>
    <div class="text-left">
      <a [routerLink]="['/']" class="btn btn-primary btn-md">{{ 'cart.Go_back_to_IMCAS_academy' | translate }}</a>
    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {TranslationService} from '../../services/translation.service';
import {DateMomentService} from '../../services/date-moment.service';
import {LocaleService} from '../../services/locale.service';
import {User} from '../../models/user.model';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-alert-last-cases',
    templateUrl: './alert-last-cases.component.html',
    styleUrls: ['./alert-last-cases.component.scss']
})
export class AlertLastCasesComponent implements OnInit {
    @Input() cases: any;
    locale: string;
    user: User;
    isAop: boolean = environment.domain === 'ophthalmology';

    constructor(public authService: AuthService,
                public translationService: TranslationService,
                public localeService: LocaleService,
                public dateMomentService: DateMomentService,
                private router: Router) {

    }

    ngOnInit() {
        this.user = this.authService.getCurrentUser();
        this.locale = this.localeService.currentLocale();
    }

    openCase(group) {
        this.authService.isLoggedIn().subscribe(logged => {
            if (logged) {
                this.router.navigate(['/alert', group.slug]);
            } else {
                this.router.navigate(['/account/login']);
            }
        });

    }
}

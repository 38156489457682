import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from "../../services/translation.service";

export interface Badge {
  title: string,
  color: string
}
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  providers: [ TranslationService]
})
export class CardComponent implements OnInit {
  @Input() hasBadges: boolean = false;
  @Input() badges: Array<Badge> = new Array<Badge>();
  @Input() hasButtons: boolean = false;
  @Input() buttons: Array<{ title: string, href: string }> = new Array<{ title: string, href: string }>();
  @Input() media: string = "";
  @Input() title: string = "";
  isVideo: boolean = false;
  constructor() { }

  ngOnInit() {
    this.hasBadges = this.badges !== undefined || this.badges.length > 0;
    this.hasButtons = this.buttons !== undefined || this.buttons.length > 0;
    this.isVideo = this.media.includes('.mp4');
  }
}

import {DateMomentService} from 'app/services/date-moment.service';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {Observable} from 'rxjs/index';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {Specialty} from 'app/models/specialty.model';
import {Country} from 'app/models/country.model';
import {SpecialtyService} from 'app/services/specialty.service';
import {CountryService} from 'app/services/country.service';
import {CropperSettings, ImageCropperComponent} from 'ngx-img-cropper';
import * as $ from 'jquery';
import 'slick-carousel';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';
import {tap} from 'rxjs/operators';


@Component({
  selector: 'profile-picture-cover',
  templateUrl: './picture-cover.component.html',
  styleUrls: ['./picture-cover.component.scss'],
  providers: [
    UserService,
    CountryService,
    SpecialtyService
  ]
})
export class ProfilePictureCoverComponent implements OnInit, AfterViewInit {
  user: User;
  favorited: any;
  locale: string;
  currentTab: string = 'professionalData';
  menuPage: number = 0;
  specialities$: Observable<Specialty[]>;
  countries$: Observable<Country[]>;
  selectedCountry: Country;
  selectedSpecialty: Specialty;
  updated: any = false;
  newUser = {
    address_web: null,
    city_web: null,
    company_name_web: null,
    country_web_id: null,
    email: null,
    first_name: null,
    gender: null,
    last_name: null,
    phone: null,
    postal_code_web: null,
    user_specialty_id: null,
    cover_url: null,
    cover_gallery: null
  };
  coverImage: File;
  profileImage: File;
  selectedCoverFromGallery: number;
  cover_images: Array<any> = [];
  data2: any;
  private formImageData: any;
  cropperSettings: CropperSettings;
  @ViewChild('cropper', {static: false}) cropper: ImageCropperComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              public translationService: TranslationService,
              public dateMomentService: DateMomentService,
              private specialtyService: SpecialtyService,
              private localeService: LocaleService,
              private authService: AuthService,
              private countryService: CountryService,
              ) {
  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    this.getUser();
    this.getCountries();
    this.getCoverImagesFromGallery();
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 200;
    this.cropperSettings.height = 200;
    this.cropperSettings.keepAspect = true;

    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;

    this.cropperSettings.canvasWidth = 200;
    this.cropperSettings.canvasHeight = 200;

    this.cropperSettings.minWidth = 200;
    this.cropperSettings.minHeight = 200;

    this.cropperSettings.rounded = true;
    this.cropperSettings.minWithRelativeToResolution = true;
    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 3;
    this.cropperSettings.noFileInput = true;

    this.data2 = {};

  }

  ngAfterViewInit() {
    $('.slider-for').not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      dots: false,
      asNavFor: '.slider-nav',
      prevArrow: '',
      nextArrow: ''
    });

    $('.slider-nav').not('.slick-initialized').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '30px',
      asNavFor: '.slider-for',
      centerMode: true,
      focusOnSelect: true,
      dots: false,
      prevArrow: '',
      nextArrow: ''
    });
  }

  getUser(callback?) {
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      this.userService.getUser(this.user.id, {user_specialty: 1, user_country: 1, cover_url: 1}).subscribe(
        user => {
          this.user = new User({...this.user, ...user});
          this.newUser.address_web = this.user.address;
          this.newUser.city_web = this.user.city;
          this.newUser.company_name_web = this.user.company_name;
          if (this.user.country) {
            this.newUser.country_web_id = this.user.country.id;
          }
          this.newUser.email = this.user.email;
          this.newUser.first_name = this.user.first_name;
          this.newUser.gender = this.user.gender || 'n';
          this.newUser.last_name = this.user.last_name;
          this.newUser.phone = this.user.phone;
          this.newUser.postal_code_web = this.user.postal_code;
          this.newUser.user_specialty_id = this.user.user_specialty_id;
          this.newUser.cover_url = this.user.cover_url;
          this.newUser.cover_gallery = this.user.cover_gallery;
          this.selectedCoverFromGallery = this.user.cover_gallery;
          $('.slider-nav').slick('slickGoTo', this.selectedCoverFromGallery - 2); /// nonsens
          this.getSpecialties();
          this.authService.setCurrentUser(this.user);
          if (callback) {
            callback();
          }
        }
      );
    }
  }

  getProfilePicture() {
    if (Object.keys(this.data2).length) {
      return this.data2.image;
    } else {
      return this.user.picture_url;
    }
  }

  getCoverImagesFromGallery() {
    for (let i = 1; i < 16; i++) {
      this.cover_images.push({
        src: 'https://comexposium-healthcare-assets.s3.eu-west-1.amazonaws.com/users/covers/' + (Number(i) + 1) + '.jpg', index: i + 1
      });
    }
  }

  getSpecialties() {
    this.specialities$ = this.specialtyService
      .getSpecialties({specialty_category: this.user.specialty_category, all: 1})
      .pipe(tap(data => {
        const specialtyToSelect = data.find(specialty => specialty.id === this.user.specialty.id);
        if (specialtyToSelect) {
          this.selectedSpecialty = specialtyToSelect;
        }
      }));
  }

  getCountries() {
    this.countries$ = this.countryService
      .getCountries()
      .pipe(tap(data => {
        const countryToSelect = data.find(country => country.id === this.user.country.id);
        if (countryToSelect) {
          this.selectedCountry = countryToSelect;
        }
      }));
  }

  specialtyChange(specialty) {
    this.newUser.user_specialty_id = specialty.id;
  }

  countryChange(country) {
    this.newUser.country_web_id = country.id;
  }

  onProfileImageFileChange($event) {
    this.updated = false;
    this.profileImage = $event.target.files[0];
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const myReader = new FileReader();
    const that = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);

    };
    if (file instanceof File) {
      myReader.readAsDataURL(file);
    }
  }

  onCoverImageFileChange(event) {
    this.updated = false;
    this.coverImage = event.target.files[0];
    const file: File = event.target.files[0];
    const myReader = new FileReader();
    const that = this;
    myReader.onloadend = function (ev: any) {
      that.uploadCoverImage(ev.target.result);
    };
    if (file instanceof File) {
      myReader.readAsDataURL(file);
    }
  }

  uploadCoverImage(coverImage: string) {

    const i = ('' + Math.random() * 1000000).split('.')[0];
    const data = {
      'images[cover][name]': 'cover-picture-' + i,
      'images[cover][content]': coverImage,
      'images[cover][format]': this.coverImage.type ? this.coverImage.type : ''
    };

    this.userService.uploadNewImage(data)
      .subscribe(r => {
        this.updated = {
          what: 'cover image'
        };
        scroll({
          top: 50,
          behavior: 'smooth'
        });
        this.getUser(() => {
          // location.reload();
        });
      })
  }

  uploadProfileImage() {

    const i = ('' + Math.random() * 1000000).split('.')[0];

    const data = {
      'images[profile][name]': 'profile-picture-' + i,
      'images[profile][content]': this.data2.image,
      'images[profile][format]': this.profileImage ? this.profileImage.type : ''
    };

    this.userService.uploadNewImage(data)
      .subscribe(r => {
        this.updated = {
          what: 'profile image'
        };
        scroll({
          top: 50,
          behavior: 'smooth'
        });
        this.getUser(() => {
          // location.reload();
        });
      })
  }

  setCoverFromGallery() {
    this.updated = false;
    this.userService.setCoverFromGallery(this.selectedCoverFromGallery - 1).subscribe(r => {
      this.updated = {
        what: 'cover image'
      };
      scroll({
        top: 50,
        behavior: 'smooth'
      });
    })
  }

  updateProfile() {
    this.userService.updateUser(this.newUser).subscribe(success => {
      this.updated = {
        what: 'profile image'
      };
      scroll({
        top: 50,
        behavior: 'smooth'
      });
    });
  }
}

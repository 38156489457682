<div class="comment">
  <div class="comment-list">
    <div class="comment-item" *ngFor="let comment of comments">
      <div *ngIf="comment.user_info" class="author media" [ngClass]="{'staff': comment.user_info.public == 0}" (click)="openUrlProfile(comment.user_info)">
        <div class="rounded-circle" [style.background]="'url(' + comment.user_info.picture_url + ')'" [style.background-size]="'cover'"></div>
        <div class="media-body">
          <h6>{{comment.user_info.title + ' ' + comment.user_info.first_name + ' ' +
            comment.user_info.last_name}}</h6>
          <span>
                                {{(comment.user_info.specialty ? translationService.getTranslatedName(comment.user_info.specialty.translations, locale) : '') +
                                (comment.user_info.specialty && comment.user_info.country ? ', ' : '') +
                                (comment.user_info.country ? translationService.getTranslatedName(comment.user_info.country.translations, locale) : '')}}
                            </span>
        </div>
      </div>
      <div class="comment-text">
        <p [innerHTML]="comment.content"></p>
      </div>
      <div class="comment-date">
        <p>{{dateMomentService.formatHumanWay(comment.created_at)}}</p>
      </div>
    </div>
  </div>
  <h2>{{ 'academy.Post_comment'|translate }}</h2>
  <div class="comment-area" *ngIf="user && user.id && !readOnly">
    <textarea class="input" name="" id="" cols="30" rows="5" [(ngModel)]="commentToPost"></textarea>
    <button *ngIf="!commentRequestLaunch" [disabled]="commentToPost?.length < 5 || user.comments_allowed === false" class="button button-orange"
            (click)="postComment(commentToPost)">{{ 'academy.Post_comment'|translate }}
    </button>
  </div>
</div>
<div class="comment-disabled"
     *ngIf="(!(user && user.id) || !((user?.premium_membership && mustBePremium) || !mustBePremium)) && !userIsSpeaker || readOnly">
  <div class="comment-disabled-box">
    <p>{{'academy.Connect_with_community'|translate}}</p>
    <a [routerLink]="'/premium'" *ngIf="mustBePremium" class="button button-orange">{{'academy.Join_discussion'|translate}}</a>
    <a [routerLink]="'/account/login'" *ngIf="!mustBePremium" class="button button-orange">{{'academy.Join_discussion'|translate}}</a>
  </div>
</div>

<div class="comment-disabled"
     *ngIf="mustBuyInMarketplace && !userIsSpeaker">
  <div class="comment-disabled-box">
    <p>{{'marketplace.Paid_media'|translate}}</p>
    <a [routerLink]="'/marketplace'" class="button button-orange">{{'marketplace.Marketplace'|translate}}</a>
  </div>
</div>

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class CongressProductService {
    private endpoint: string = '/congress_products';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    searchCongressProducts(params?: any): Observable<any> {
        const reqOpts = {
            params: new HttpParams()
        };
        if (params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    reqOpts.params = reqOpts.params.set(k, '' + params[k]);
                }
            }
        }
        reqOpts.params = reqOpts.params.set('congress_id', environment.congress_id + '')
            .set('product_family_parent_id', '205')
            .set('marketplace', '1');
        return this.httpClient.get<any>(environment.apiURLV2 + this.endpoint, reqOpts).pipe(shareReplay());
    }

    getBySlug(slug) {
        const reqOpts = {
            params: new HttpParams()
        };
        reqOpts.params = reqOpts.params.set('congress_id', environment.congress_id + '')
            .set('congress_product_medias', '1')
            .set('marketplace', '1')
            .set('media_cdn', '1')
            .set('media_speakers', '0')
            .set('bought_by_auth', '1')
            .set('media_lecture', '0');
        return this.httpClient.get<any>(environment.apiURLV2 + this.endpoint + '/' + slug, reqOpts).pipe(shareReplay());
    }
}

import {DateMomentService} from '../services/date-moment.service';
import {TranslationService} from '../services/translation.service';
import {Lecture} from './lecture.model';

export class Webinar {
  id: number;
  datetime_begin: Date;
  datetime_when_in_hours: number;
  minutes_before_start: number;
  display_granted: boolean;
  theme: { id: number, name: string };
  format: { id: number, name: string };
  theme_ids: number[];
  speakers: any[];
  translations: any;
  past: any;
  slug: string;
  replay_viewers: string | null;
  auth_registered: boolean;
  picture_url: string;
  live_demonstration: boolean;
  dateMomentService: DateMomentService;
  dates: any;
  registrants: string | null;
  webinar_launched: any;
  comments: any[];
  partners: any[];
  lectures: Lecture;
  sponsored: boolean;
  granted: boolean;
  has_program: boolean;
  question_live: boolean;
  webinar_registrants_specialties: any[];
  webinar_registrants_stats_specialties: any[];
  webinar_registrants_areas: any[];
  webinar_registrants_stats_areas: any[];
  video_available_languages: any[];
  user_has_rated_webinar: number;
  teaser_media: any;
  streamed_masterclass: number;
  public: number;
  date_begin: string;
  domain: string;

  constructor(obj?: any) {
    this.id = obj && obj.id;
    this.datetime_begin = obj && obj.datetime_begin;
    this.theme = obj && obj.theme;
    this.format = obj && obj.format;
    this.replay_viewers = obj && obj.replay_viewers;
    this.theme_ids = obj && obj.theme_ids;
    this.speakers = obj && obj.speakers;
    this.slug = obj && obj.slug;
    this.translations = obj && obj.translations;
    this.auth_registered = obj && obj.auth_registered;
    this.picture_url = obj && obj.picture_url;
    this.dates = obj && obj.dates || [];
    this.comments = obj && obj.comments;
    this.live_demonstration = obj && obj.live_demonstration;
    this.partners = obj && obj.partners;
    this.past = obj && obj.past;
    this.registrants = obj && obj.registrants;
    this.webinar_launched = obj && obj.webinar_launched;
    this.sponsored = obj && obj.sponsored;
    this.granted = obj && obj.granted;
    this.has_program = obj && obj.has_program;
    this.question_live = obj && obj.question_live;
    this.webinar_registrants_specialties = obj && obj.webinar_registrants_specialties;
    this.webinar_registrants_stats_specialties = obj && obj.webinar_registrants_stats_specialties;
    this.webinar_registrants_areas = obj && obj.webinar_registrants_areas;
    this.webinar_registrants_stats_areas = obj && obj.webinar_registrants_stats_areas;
    this.video_available_languages = obj && obj.video_available_languages;
    this.teaser_media = obj && obj.teaser_media;
    this.streamed_masterclass = obj && obj.streamed_masterclass;
    this.public = obj && obj.public;
    this.date_begin = obj && obj.date_begin;
    this.domain = obj && obj.domain;
  }

  getMonth() {
    if (!this.dateMomentService) {
      this.dateMomentService = new DateMomentService();
    }
    return this.dateMomentService.getMonth(this.datetime_begin);
  }

  getTitle(locale) {
    const translation = new TranslationService();
    let subtitle = translation.getTranslatedAttribute('subtitle', this.translations, locale);
    subtitle = subtitle === 'n/a' ? '' : subtitle;
    return translation.getTranslatedAttribute('title', this.translations, locale) + ' ' + subtitle;
  }

  getFullDateTime(locale) {
    if (!this.dateMomentService) {
      this.dateMomentService = new DateMomentService();
    }
    const format = locale === 'fr' ? 'Do MMMM, YYYY @HH:mm' : 'MMMM Do, YYYY @HH:mm';
    return this.dateMomentService.getDate(this.datetime_begin).format(format);
  }
}

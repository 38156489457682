<div class="sponsores" [style.background]="isAop ? '#0f2a48' : null" #program>
  <ng-template #grantSponsorContent let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry" [modal]="modal" *ngIf="webinar && webinar.partners?.industry"></app-webinar-sponsor-modal>
  </ng-template>

  <ng-template #grantSponsorContent2 let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry2" [modal]="modal" *ngIf="webinar && webinar.partners?.industry2"></app-webinar-sponsor-modal>
  </ng-template>

  <ng-template #grantSponsorContent3 let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry3" [modal]="modal" *ngIf="webinar && webinar.partners?.industry3"></app-webinar-sponsor-modal>
  </ng-template>

  <ng-template #grantSponsorContent4 let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry4" [modal]="modal" *ngIf="webinar && webinar.partners?.industry4"></app-webinar-sponsor-modal>
  </ng-template>

  <ng-template #grantSponsorContent5 let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry5" [modal]="modal" *ngIf="webinar && webinar.partners?.industry5"></app-webinar-sponsor-modal>
  </ng-template>

  <ng-template #grantSponsorContent6 let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry6" [modal]="modal" *ngIf="webinar && webinar.partners?.industry6"></app-webinar-sponsor-modal>
  </ng-template>

  <ng-template #grantSponsorContent7 let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry7" [modal]="modal" *ngIf="webinar && webinar.partners?.industry7"></app-webinar-sponsor-modal>
  </ng-template>

  <ng-template #grantSponsorContent8 let-modal>
    <app-webinar-sponsor-modal [company]="webinar.partners.industry8" [modal]="modal" *ngIf="webinar && webinar.partners?.industry8"></app-webinar-sponsor-modal>
  </ng-template>


  <div class="logos-company" *ngIf="webinar && webinar.partners?.industry && webinar.sponsored">
    <h5>{{'academy.Sponsored_by'|translate}}<span class="fas fa-info-circle"
                                                  [ngbTooltip]="('webinar.Info_sponsored'|translate)"></span>
    </h5>
    <div class="sponsores-item grant-sponsor-item">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry.company_name}}</a>
        <a (click)="open(grantSponsorContent)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry2">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry2.company_name}}</a>
        <a (click)="open(grantSponsorContent2)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent2)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry2.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry3">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry3.company_name}}</a>
        <a (click)="open(grantSponsorContent3)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent3)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry3.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry4">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry4.company_name}}</a>
        <a (click)="open(grantSponsorContent4)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent4)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry4.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry5">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry5.company_name}}</a>
        <a (click)="open(grantSponsorContent5)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent5)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry5.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry6">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry6.company_name}}</a>
        <a (click)="open(grantSponsorContent6)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent6)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry6.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry7">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry7.company_name}}</a>
        <a (click)="open(grantSponsorContent7)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent7)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry7.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry8">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry8.company_name}}</a>
        <a (click)="open(grantSponsorContent8)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent8)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry8.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>
  </div>

  <div class="logos-company" *ngIf="webinar.partners?.industry && webinar.granted == 1">
    <h5>{{'layout.Educational_grant'|translate}}<span class="fas fa-info-circle"
                                                      [ngbTooltip]="('webinar.Info_granted'|translate)"></span>
    </h5>
    <div class="sponsores-item grant-sponsor-item">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry.company_name}}</a>
        <a (click)="open(grantSponsorContent)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry2">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry2.company_name}}</a>
        <a (click)="open(grantSponsorContent2)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent2)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry2.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry3">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry3.company_name}}</a>
        <a (click)="open(grantSponsorContent3)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent3)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry3.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry4">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry4.company_name}}</a>
        <a (click)="open(grantSponsorContent4)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent4)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry4.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry5">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry5.company_name}}</a>
        <a (click)="open(grantSponsorContent5)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent5)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry5.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry6">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry6.company_name}}</a>
        <a (click)="open(grantSponsorContent6)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent6)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry6.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry7">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry7.company_name}}</a>
        <a (click)="open(grantSponsorContent7)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent7)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry7.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>

    <div class="sponsores-item grant-sponsor-item" *ngIf="webinar.partners?.industry8">
      <div class="sponsores-item-info">
        <a>{{webinar.partners.industry8.company_name}}</a>
        <a (click)="open(grantSponsorContent8)">
          {{'academy.View_profile'|translate}}
        </a>
      </div>
      <div class="sponsores-item-logo"
           (click)="open(grantSponsorContent8)"
           [ngStyle]="{'background-image': 'url(' + webinar.partners.industry8.logo_url + ')'}">
        <div class="filter-purple"></div>
      </div>
    </div>
  </div>

  <div class="logos-company"
       *ngIf="webinar.partners?.scientific_societies.length > 0 || webinar.partners?.publication">
    <h5>
      {{'academy.Scientific_partners'|translate}}
      <span class="fas fa-info-circle"
            [ngbTooltip]="('webinar.Info_scientific_societies'|translate)"></span>
    </h5>
    <div *ngFor="let society of webinar.partners.scientific_societies; let i = index"
         [attr.data-index]="i">
      <ng-template #scientificSocContent let-modal>
        <div class="modal-body text-center">
          <button type="button" class="close" aria-label="Close"
                  (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
          <img src="{{society.logo_url}}" style="margin: 0 auto;height: 100px;"
               alt="">
          <div class="col-sm-12 text-left sponsor-info">
            <h3>{{society.name}}</h3>
            <button *ngIf="society.website" class="button button-orange"><a [href]="society.website" target="_blank">
              {{'explore.Go_to_link'|translate }}
            </a></button>
            <p
              [innerHTML]="society.description"
              style="font-size: 14px;margin-top: 10px"
            ></p>

          </div>
        </div>
      </ng-template>
      <div class="sponsores-item">
        <div class="sponsores-item-info">
          <a>{{society.acronym}}</a>
          <a (click)="open(scientificSocContent)"
             [attr.data-target]="'#scientificSocContent'">
            {{'academy.View_profile'|translate}}
          </a>
        </div>
        <div class="sponsores-item-logo"
             (click)="open(scientificSocContent)"
             [ngStyle]="{'background-image': 'url(' + society.logo_url + ')'}">
          <div class="filter-purple"></div>
        </div>
      </div>
    </div>

    <ng-template #publicationContent let-modal>
      <div class="modal-body text-center">
        <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <img src="{{webinar.partners.publication.logo_url}}"
             style="margin: 0 auto;height: 100px;"
             alt="">
        <div class="col-sm-12 text-left sponsor-info">
          <h3>{{webinar.partners.publication.name}}</h3>
          <p>
            {{translationService.getTranslatedName(webinar.partners.publication.country.translations,
            locale)}}</p>
          <button *ngIf="webinar.partners.publication.website"><a [href]="webinar.partners.publication.website" target="_blank">
            {{'explore.Go_to_link'|translate }}
          </a></button>
          <p style="margin-top: 10px">{{webinar.partners.publication.description}}</p>
        </div>
      </div>
    </ng-template>
    <div class="sponsores-item" *ngIf="webinar.partners.publication">
      <div class="sponsores-item">
        <div class="sponsores-item-info">
          <a>{{webinar.partners.publication.name}}</a>
          <a (click)="open(publicationContent)">
            {{'academy.View_profile'|translate}}
          </a>
        </div>
        <div class="sponsores-item-logo"
             (click)="open(publicationContent)"
             [ngStyle]="{'background-image': 'url(' + webinar.partners.publication.logo_url + ')'}">
          <div class="filter-purple"></div>
        </div>
      </div>
    </div>
  </div>

</div>

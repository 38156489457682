<div [class]="large ? 'product product-large' : 'product product-small'" (click)="openProduct()">
    <div [class]="large ? 'product-image radius-left' : 'product-image radius-top'" *ngIf="imagePosition == 'before'"
         [style.float]="imagePosition == 'before' ? 'left' : 'right'"
         [style.background-image]="'url(' + congressProduct.product.thumb +')'"
    ></div>
    <div [class]="large ? ('product-information' + (imagePosition == 'before' ? ' radius-right' : ' radius-left')) : 'product-information radius-bottom'"
         [style.background-color]="large ? 'white' : '#' + congressProduct.color">
        <div class="product-tag" *ngIf="congressProduct.popular">{{ 'marketplace.New_product'|translate }}
            <div class="triangle"></div>
        </div>
        <div class="product-text">
            <h4>{{ translationService.getTranslatedName(congressProduct.product.translations, locale) }}</h4>
            <span class="product-vendor" *ngIf="congressProduct.partner">{{congressProduct.partner.company_name}}</span>
            <p [innerHTML]="getDescription()">
            </p>
            <div class="text-center">
                <a class="button button-orange" [class]="primary ? 'button button-orange' : 'button button-blue'">{{ 'marketplace.Discover'|translate }}</a>
            </div>
        </div>
    </div>
    <div class="product-image radius-right" *ngIf="imagePosition == 'after'"
         [style.background-image]="'url(' + congressProduct.product.thumb +')'"></div>
</div>

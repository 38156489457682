import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {map, shareReplay} from 'rxjs/internal/operators';


@Injectable()
export class HumanitarianOrganisationService {
    private endpoint: string = '/humanitarian_organizations';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getHumanitarianOrganisations(term: string): Observable<any> {
        return this.httpClient.get<any>(environment.apiURL + this.endpoint, {
            params: new HttpParams().set('all', '1')
        }).pipe(map((result: any) => {
            if (term) {
                result = result.filter(obj => {
                    if (obj.name && obj.name.toLowerCase().indexOf(term.toLowerCase()) !== -1) {
                        return true;
                    }
                    return false;
                });
            }
            return result;
        }), shareReplay());
    }

    addHumanitarianOrganisation(organisationId: number): Observable<any> {
        return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/' + organisationId + '/user', null).pipe(shareReplay());
    }

    deleteHumanitarianOrganisation(organisationId: number): Observable<any> {
        return this.httpClient.delete<any>(environment.apiURL + this.endpoint + '/' + organisationId + '/user').pipe(shareReplay());
    }
}

import {Injectable} from '@angular/core';
import {IsCordovaService} from './is-cordova.service';
import {environment} from '../../environments/environment';
import {IsCapacitorService} from "./is-capacitor.service";

declare var cordova: any;
declare var SafariViewController: any;

@Injectable()
export class LinkService {
  constructor(private isCapacitorService: IsCapacitorService) {
  }

  openBlank(url, system = false) {
    if (url.indexOf('mailto:') !== -1) {
      if (this.isCapacitorService.isAppCapacitor()) {
        window.open(url, '_system');
      } else {
        window.open(url, '_self');
      }

    } else {
      if (url.indexOf('http') === -1) {
        url = environment.base_url + url;
      }

      if (this.isCapacitorService.isAppCapacitor()) {
        SafariViewController.isAvailable(function (available) {
          if (available) {
            SafariViewController.show({
              url: url,
            });
          } else {
            if (system) {
              cordova.InAppBrowser.open(url, '_system', 'location=yes');
            } else {
              cordova.InAppBrowser.open(url, '_blank', 'location=yes');
            }
          }
        });
      } else {
        window.open(url, '_blank');
      }


    }

  }
}

import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {TranslationService} from 'app/services/translation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CongressService} from 'app/services/congress.service';
import {environment} from '../../../../environments/environment';
import {MediaService} from 'app/services/media.service';
import {AcademyMedia} from 'app/models/academy-media.model';
import {LocaleService} from '../../../services/locale.service';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [
        UserService,
        CongressService,
        MediaService
    ]
})
export class ProfileComponent implements OnInit {
    environment: any = environment;
    profile: User;
    slug: string;
    user: User;
    locale: string;
    showMorePubs: boolean = false;
    public cover_images: Array<any> = [];
    videos: AcademyMedia[];
    friends: User[];
    loadingUser: boolean;
    isAop: boolean = environment.domain === 'ophthalmology';
    articles: any[] = [];

    constructor(private activatedRoute: ActivatedRoute,
                private authService: AuthService,
                private localeService: LocaleService,
                private modalService: NgbModal,
                public translationService: TranslationService,
                private userService: UserService,
                private congressService: CongressService,
                private router: Router,
                private mediaService: MediaService) {
    }

    ngOnInit() {
        this.loadingUser = true;
        this.locale = this.localeService.currentLocale();
        this.activatedRoute.params.subscribe(params => {
            this.slug = params['slug'];
            this.videos = [];
            this.loadingUser = true;
            this.profile = null;
            this.getProfile(this.slug);
        });
        this.getUser();
    }

    getUser() {
        try {
            this.user = JSON.parse(localStorage.getItem('currentUser'));
        } catch (e) {
        }
    }

    getProfile(slug) {
        if (slug) {
            this.userService.getUser(slug, {
                cover_url: 1,
                user_badge: 1,
                user_specialty: 1,
                user_country: 1,
                user_biostatement: 1,
                thread_with_auth: 1,
                friendship_status_with_auth: 1,
                user_media_ids: 1,
                user_next_lectures: 1,
                lecture_user: 0,
                with_session_users: 0,
                pubmed: 1,
                pubmed_visibility: 'show',
                user_friends: 1,
                user_scientific_societies: 1,
                user_themes: 1,
                theme_pictures: 1,
                connections: 1,
                lecture_session: 1,
                session_congress: 1
            }).subscribe(
                user => {
                    if (user.company) {
                        window.location.reload();
                    } else {
                        this.profile = new User(user);
                        this.loadingUser = false;
                        this.getSchedule();
                        this.getVideos(user.media_ids.video_published);
                        this.getFriends(this.profile.friends);
                        this.getArticles(user.id);
                    }
                }
            );
        }
    }

    getFriends(friendsIds) {
        if (friendsIds && friendsIds.length > 0) {
            this.friends = [];
            this.userService.getUserFriends({
                ids: friendsIds.join(','),
                all: 1,
                user_media_ids: 0,
                user_next_lectures: 0,
                user_country: 1,
                user_specialty: 1,
                connections: 1,
                friendship_status_with_auth: 1
            }).subscribe(
                friends => {
                    if (friends && friends.length > 0) {
                        Object.keys(friends).map((objectKey, index) => {
                            this.friends.push(new User(friends[objectKey]));
                        });
                    }
                }
            );
        }
    }

    getSchedule() {
        if (this.profile) {
            this.profile.next_lectures = this.profile.next_lectures.sort(function (a, b) {
                return (a.session && a.session.date_begin > b.session && b.session.date_begin) ?
                    1 : ((b.session && b.session.date_begin > a.session && a.session.date_begin) ? -1 : 0);
            }).slice(0, 3);
            if (this.profile.next_lectures) {
                for (let i = 0; i < this.profile.next_lectures.length; ++i) {
                    if (this.profile.next_lectures[i].session) {
                        this.congressService.getCongress(this.profile.next_lectures[i].session.congress_id).subscribe(
                            congress => {
                                this.profile.next_lectures[i].session.congress = congress;
                            },
                            err => {
                            });
                    }
                }
            }
        }
    }

    getVideos(mediaIds) {
        const sliderSlick = $('.academy-slider');
        if (sliderSlick.hasClass('slick-initialized')) {
            sliderSlick.slick('unslick');
        }
        if (mediaIds && mediaIds.length > 0) {
            this.videos = [];
            this.mediaService.getAcademyMedias({
                media_ids: mediaIds.join(','),
                academy_media_course: 0,
                media_lecture: 0,
                media_cdn: 0,
                academy_media_event: 0,
                all: 1,
                order_by: 'publication_date',
                order: 'desc'
            }).subscribe(
                videos => {
                    Object.keys(videos).map((objectKey) => {
                        this.videos.push(new AcademyMedia(videos[objectKey]));
                    });
                    setTimeout(() => {
                        const jquerySlider = $('.academy-slider');
                        if (jquerySlider.length > 0) {
                            jquerySlider.not('.slick-initialized').slick({
                                infinite: false,
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                autoplay: false,
                                dots: false,
                                prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
                                nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
                                responsive: [{
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 2,
                                        infinite: true
                                    }
                                }, {
                                    breakpoint: 500,
                                    settings: {
                                        slidesToShow: 1,
                                    }
                                }]
                            });
                        }

                    }, 50);
                }
            );
        }
    }

    openSmall(content) {
        this.modalService.open(content);
    }

    open(content) {
        this.modalService.open(content, {windowClass: 'modal-wide'});
    }

    connect(profile) {
        this.authService.isLoggedIn().subscribe(logged => {
            if (logged) {
                if (this.user && profile && profile.friendship_status_with_auth === 'not_friend') {
                    this.userService.connect(profile.id).subscribe(success => {
                        profile.friendship_status_with_auth = 'waiting_friend_accept';
                    });
                }
            } else {
                this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url}})
            }
        });
    }

    goToVideo(mItem) {
        return this.mediaService.goToVideo(mItem);
    }

    getArticles(userId: number) {
      this.userService.getUserArticles(userId).subscribe(articles => {
        this.articles = articles;
        setTimeout(() => {
          const jquerySlider = $('.articles-slider');
          if (jquerySlider.length > 0) {
            jquerySlider.not('.slick-initialized').slick({
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: false,
              dots: false,
              prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
              nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
              responsive: [{
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  infinite: true
                }
              }, {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                }
              }]
            });
          }

        }, 50);
      });
    }
}

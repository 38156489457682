<ng-template #disclosures let-c="close" let-d="dismiss">
  <div class="modal-body modal-no-title">
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <div class="faq">
      <h2>{{translationService.getTranslatedAttribute('title', academyMedia['media'].translations, locale)}}</h2>
      <h5>{{'academy.Disclosures'|translate}}</h5>
      <div class="faq-list">
        <div class="faq-item" *ngIf="academyMedia['media']?.lecture?.disclosure1">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question1'|translate}}</h6>
          <p>{{academyMedia['media']?.lecture?.disclosure1 == 1 ? ('abstract.Yes'|translate) + (academyMedia['media']?.lecture?.disclosure1_details ? ' (' + academyMedia['media']?.lecture?.disclosure1_details + ')' : null) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="academyMedia['media']?.lecture?.disclosure2">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question2'|translate}}</h6>
          <p>{{academyMedia['media']?.lecture?.disclosure2 == 1 ? ('abstract.Yes'|translate) + (academyMedia['media']?.lecture?.disclosure2_details ? ' (' + academyMedia['media']?.lecture?.disclosure2_details + ')' : null) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="academyMedia['media']?.lecture?.disclosure3">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question3'|translate}}</h6>
          <p>{{academyMedia['media']?.lecture?.disclosure3 == 1 ? ('abstract.Yes'|translate) + (academyMedia['media']?.lecture?.disclosure3_details ? ' (' + academyMedia['media']?.lecture?.disclosure3_details + ')' : null) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="academyMedia['media']?.lecture?.disclosure4">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{'abstract.Disclosure_question4'|translate}}</h6>
          <p>{{academyMedia['media']?.lecture?.disclosure4 == 1 ? ('abstract.Yes'|translate) + (academyMedia['media']?.lecture?.disclosure4_details ? ' (' + academyMedia['media']?.lecture?.disclosure4_details + ')' : null) :
            ('abstract.No'|translate)}}</p>
        </div>
        <div class="faq-item" *ngIf="academyMedia['media']?.lecture?.disclosure5">
          <h6>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {{academyMedia['media']?.lecture?.disclosure5 == 1 ? ('abstract.Disclosure_answer5_1'|translate)
            : ('abstract.Disclosure_answer5_0'|translate) }}</h6>
          <p>{{academyMedia['media']?.lecture?.disclosure5 == 1 ? academyMedia['media']?.lecture?.disclosure5_sponsor : ''}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #abstract let-c="close" let-d="dismiss">
  <app-abstract *ngIf="academyMedia && academyMedia['media']" #abstract [d]="d"
                [media]="academyMedia['media']"></app-abstract>
</ng-template>
<div class="page" *ngIf="loadingAcademyMedia">
  <app-loader></app-loader>
</div>
<div class="page" *ngIf="!loadingAcademyMedia">
  <a *ngIf="environment.mobile" (click)="historyService.goBack()" class="return-button button button-blue" style="margin-bottom: 10px; margin-left: 10px; color: white;">
    <i class="fa fa-chevron-left" style="margin-right: 5px"></i>{{'academy.Return'|translate}}
  </a>
  <div [className]="environment.mobile ? 'video-container mt-2' : 'video-container'">
    <div class="video-wrapper" *ngIf="academyMedia && academyMedia.media"
         [style.background-image]="!academyMedia.media.cdn_file ? 'url(' + academyMedia.media.header + ')' : ''">
      <div class="video-box" *ngIf="academyMedia.media.cdn_file && !shortVersionMessage"
           [ngClass]="{'video-box-fixed': fixed}">
        <app-jwplayer-video #player
                            [file]="academyMedia.media.cdn_dash_file"
                            [file2]="academyMedia.media.cdn_file"
                            [width]="'100%'"
                            [media]="academyMedia.media"
                            [withAds]="true"
                            [adsType]="'academy'"
                            [adsEntity]="'media'"
                            [adsId]="academyMedia.media.id"
                            [mediaId]="academyMedia.media.id"
                            [aspectratio]="'16:9'"
                            (buffering)="switchIcon($event)"
                            [language]="language"
                            [languages]="academyMedia.media.languages"
                            [image]="academyMedia.media.header"
                            [sponsored]="academyMedia?.sponsored"
                            (progress)="storeProgress($event)"
                            (truelyViewed)="storeTruelyViewed($event)"
                            (audioChanged)="audioChanged($event)"
                            (completed)="openRateModal($event)"
                            (fixed)="fixed = $event"
        >
        </app-jwplayer-video>
        <div class='disclaimer' *ngIf="player && player.showDisclaimer">
          <h3>{{ 'academy.Copyright_and_disclaimer'|translate}}</h3>
          <p>{{ 'academy.IMCAS_holds_the_copyright'|translate }}</p>
          <p>{{ 'academy.The_following_video'|translate }}</p>
          <p>{{ 'academy.The_sponsor_company'|translate }}</p>
          <img class='disclaimer_logo' src='https://www.imcas.com/img/logo_white.png' alt=''>
        </div>
        <div class='video-intro-outro' *ngIf="player && player.showIntro">
          <video *ngIf="environment.domain == 'ophthalmology'"
                 src="{{ environment.cdn_base_url }}/assets-front/videos/aop_academy_intro_sans_copyright.mp4"
                 autoplay></video>
          <video *ngIf="environment.domain === 'aesthetics'"
                 src="{{ environment.cdn_base_url }}/assets-front/videos/imcas_academy_intro_sans_copyright.mp4"
                 autoplay></video>
          <div class="copyright" *ngIf="academyMedia.media.captation_date">
            <span><i
              class="far fa-copyright"></i> {{ dateMomentService.getYear(academyMedia.media.captation_date)}}</span>
          </div>
        </div>
        <div class='disclaimer disclaimer-speakers' *ngIf="player && player.showSpeakers">
          <div class="speaker-carousel  row">
            <div class="col-md-5 col-sm-6 speaker-carousel-item  "
                 *ngFor="let user of academyMedia.media.speakers">
              <div class="author media">
                <div [style.background]="'url(' + user.picture_url + ')'"
                     [style.background-size]="'cover'"
                     class="rounded-circle"></div>
                <div class="media-body">
                  <h6
                    [routerLink]="user.getProfileUrl()">{{user.title + ' ' + user.first_name + ' ' + user.last_name}}</h6>
                  <span>
                                    {{(user.specialty ? translationService.getTranslatedName(user.specialty.translations, locale) : '') + (user.specialty && user.country ? ', ' : '') + (user.country ? translationService.getTranslatedName(user.country.translations, locale) : '')}}
                                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='logoSponsored' *ngIf="academyMedia.sponsorOrGrant && player && player.showSponsoredLogo">
          <h4>{{ 'academy.This_video_is'|translate }}:</h4>
          <img class='logo_sponsored'
               [src]='academyMedia.sponsorOrGrant.logo_url'
               alt=''>
          <img class='disclaimer_logo' src='https://www.imcas.com/img/logo_white.png' alt=''>
        </div>
        <div class="video-box-play" (click)="player.start()"
             *ngIf="player && player.state !=='playing' && !player.showDisclaimer && !player.showSponsoredLogo && player.state != 'paused' && player.isFirstPlay">
          <i class="far fa-play-circle" *ngIf="!buffering "></i>
          <i class="fa fa-spinner fa-spin fa-3x" *ngIf="buffering"></i>
        </div>

        <div class="video-box-duration duration" *ngIf="player && player.state !=='playing'">
          <span>{{academyMedia['media'] && academyMedia.media?.duration ? academyMedia.media.duration : ''}}</span>
        </div>
      </div>
      <div class="no-account-video-text"
           *ngIf="academyMedia && academyMedia.media && !academyMedia.media.cdn_file">
        <div
          *ngIf="user && user.premium_membership && academyMedia">

          <h1>{{ 'academy.Access_restricted_title'|translate }}</h1>

          <p *ngIf="academyMedia.surgical_only">{{ 'academy.Access_restricted_subtitle'|translate }}</p>
          <p *ngIf="!academyMedia.surgical_only">{{ 'academy.Access_restricted_subtitle2'|translate }}</p>

          <div class="btn-center">
            <a [routerLink]="'/library/courses'" [queryParams]="{'canAccess':'true'}"
               class="btn-full orange">{{ 'academy.What_can_I_watch_button'|translate }}</a>
          </div>

        </div>
        <div *ngIf="!user || !user.premium_membership || !academyMedia">
          <h1>{{ 'academy.Join_IMCAS_academy'|translate }}</h1>
          <p>{{ 'academy.create_your_account'|translate }}</p>
          <div class="btn-center">
            <a routerLink="/account/create" class="btn-full orange">{{ 'layout.Sign_up'|translate }}</a>
            <a (click)="goToLogin()" class="link">{{ 'account.Already_have_account_login'|translate
              }}</a>
          </div>
        </div>
      </div>
      <div id='one-minute' *ngIf="shortVersionMessage">
        <h1 class='gradient'>{{ 'academy.Inspired_by_quality_content'|translate }}</h1>
        <div
          *ngIf="user.premium_membership && !(academyMedia && academyMedia.academy_course && academyMedia.academy_course.paid_course)">
          <p [innerHTML]="'academy.Only_for_physician'|translate"></p>
          <div class="btn-center">
            <a [routerLink]="'/library/courses'" class='btn-full orange'>{{
              'academy.Discover_more'|translate }}</a>
          </div>
        </div>
        <div
          *ngIf="!user.premium_membership && !(academyMedia && academyMedia.academy_course && academyMedia.academy_course.paid_course)">
          <p [innerHTML]="'academy.Become_premium_and_continue_progress'|translate: {'nb': mediasCount}"></p>
          <div class="btn-center">
            <a [routerLink]="'/premium'"
               class='btn-full orange'>{{ 'academy.Subscribe_now'|translate }}</a>
          </div>
        </div>
        <div *ngIf="academyMedia && academyMedia.academy_course && academyMedia.academy_course.paid_course">
          <p [innerHTML]="'marketplace.Paid_media'|translate"></p>
          <div class="btn-center">
            <a [href]="academyMedia.academy_course.marketplace_url"
               class='btn-full orange'>{{ 'marketplace.Marketplace'|translate }}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="content" *ngIf="!loadingAcademyMedia && academyMedia">
    <h1>{{translationService.getTranslatedTitle(academyMedia.getMediaTitles(), locale)}}</h1>
    <h6 *ngIf="academyMedia">
      {{('webinar.Published_on'|translate) + ' ' + dateMomentService.getPublicationDate(academyMedia.publication_date) + ' - ' + ('academy.Recorded_during'|translate) + ' ' + academyMedia.getCaptationSource()}}
      <span class="video-box-views"
            *ngIf="academyMedia.views > 100">{{academyMedia.views}}  {{ 'pluralize.Views'|translate }}</span>
    </h6>
    <div class="video-buttons" *ngIf="academyMedia && academyMedia.media && academyMedia.media.cdn_file">
      <a (click)="open(abstract)" class="button button-rounded button-black-outline"
         *ngIf="academyMedia && academyMedia['media']?.lecture?.abstract_status === 'accepted'">{{'academy.Abstract'|translate}}</a>
      <a (click)="open(disclosures)" class="button button-rounded button-black-outline"
         *ngIf="academyMedia && (academyMedia['media']?.lecture?.disclosure1 || academyMedia['media']?.lecture?.disclosure2 || academyMedia['media']?.lecture?.disclosure3 || academyMedia['media']?.lecture?.disclosure4 || academyMedia['media']?.lecture?.disclosure5)">{{'academy.Disclosures'|translate}}</a>
      <div class="video-rating">
        <ngb-rating [rate]="academyMedia?.rating" (rateChange)="rateMedia($event)" max="5"
                    [readonly]="!academyMedia.full_video">
          <ng-template let-fill="fill">
            <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
            <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
            <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
          </ng-template>
        </ngb-rating>
      </div>
    </div>
    <div class="video-bottom row" *ngIf="academyMedia && academyMedia.media && academyMedia.media.cdn_file">
      <div class="col-md-12">
        <div class="keywords" *ngIf="academyMedia && academyMedia.keywords">
          <p *ngIf="academyMedia.keywords.length > 0">{{'academy.Keywords'|translate}} :
            <ng-container *ngFor="let keyword of academyMedia.keywords; index as i">
              {{i > 0 ? ', ' : ''}}<a
              routerLink="/library/courses/theme/{{ keyword.theme_id }}/slug//keyword/{{keyword.id}}/{{translationService.getTranslatedName(keyword.translations, locale)}}">{{translationService.getTranslatedName(keyword.translations, locale)}}</a>
            </ng-container>
          </p>
        </div>
        <div
          *ngIf="academyMedia && academyMedia.media && academyMedia.media.cdn_file && academyMedia.media.languages.length > 1"
          class="button-languages">

          <span *ngIf="user.premium_membership || !(academyMedia && academyMedia.academy_course && academyMedia.academy_course.paid_course)">{{ 'academy.Available_trad'|translate }}</span>
          <span *ngIf="!user.premium_membership && (academyMedia && academyMedia.academy_course && academyMedia.academy_course.paid_course)">{{ 'academy.Trad_premium_only'|translate }}</span> <br>

          <img (click)="changeLanguage(language)" *ngFor="let language of academyMedia.media.languages"
               [src]="environment.cdn_base_url + '/assets-front/img/flags/' +  language + '.svg'"
               class="flag" alt="">
        </div>
      </div>
    </div>

    <div class="video-authors row" *ngIf="academyMedia">
      <div class="col-md-4 col-sm-6" *ngFor="let user of academyMedia.media.speakers">
        <div class="author media">
          <div [style.background]="'url(' + user.picture_url + ')'" [style.background-size]="'cover'"
               class="rounded-circle"></div>
          <div class="media-body">
            <h6 [routerLink]="user.getProfileUrl()">{{user.title + ' ' + user.first_name + ' ' + user.last_name}}</h6>
            <span>
                            {{(user.specialty ? translationService.getTranslatedName(user.specialty.translations, locale) : '') + (user.specialty && user.country ? ', ' : '') + (user.country ? translationService.getTranslatedName(user.country.translations, locale) : '')}}
                        </span>
          </div>
        </div>
      </div>
    </div>
    <div class="video-last-row">
      <div class="video-social">
        <app-social-list
          [showSocialItems]="showSocialItems"
          [element]="academyMedia"
          [favoriteItem]="true"
          (addToFavorite)="addMediaToFavorite(academyMedia.id); academyMedia.user_auth && academyMedia.user_auth[0] ? (academyMedia.user_auth[0].pivot.favorite === 1 ? academyMedia.user_auth[0].pivot.favorite = 0 : academyMedia.user_auth[0].pivot.favorite = 1) : '';"
          [favorite]="favorited"
          [twitterHref]="'https://twitter.com/intent/tweet?text='+('academy.Share_mail_subject'|translate)+'&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fshow%2F'+academyMedia.id+'%2F'+slug+'&amp;hashtags=IMCASAcademy&amp;via=imcascongress&amp;related=imcascongress'"
          [facebookHref]="'https://www.facebook.com/sharer/sharer.php?u='+environment.base_url+'%2F'+locale+'%2Facademy%2Fshow%2F'+academyMedia.id+'%2F'+slug"
          [linkedinHref]="'https://www.linkedin.com/shareArticle?mini=true&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fshow%2F'+academyMedia.id+'%2F'+slug+'&amp;title='+('academy.Share_mail_subject'|translate)"
          [mailHref]="'mailto:?subject='+('academy.Share_mail_subject'|translate)+'&amp;body='+('academy.Share_mail_line1'|translate)+'%0A%0A'+('academy.Share_mail_line2_course'|translate)+'%0A'+environment.base_url+'/'+locale+'/academy/show/'+academyMedia.id+'/'+slug+'%0A%0A'+('academy.Share_mail_subject'|translate)+'%0A%0A'+(user ? user.first_name + ' ' + user.last_name : '')"
          [certificateItem]="false"
        ></app-social-list>
        <ngb-alert *ngIf="showFavorited"
                   type="success">{{favorited ? ('account.Favorites_lectures_added'|translate) : ('account.Favorites_lectures_removed'|translate)}}</ngb-alert>
      </div>
      <div class="sponsored" *ngIf="academyMedia">
        <p *ngIf="(academyMedia.granted || academyMedia.sponsored) && academyMedia.sponsorOrGrant.company_name">
          <i class="fa fa-info-circle" aria-hidden="true" (click)="openCorporateFundingModal()"></i>
          {{academyMedia.granted ? ('academy.Granted_by'|translate) : academyMedia.sponsored ? ('academy.Sponsored_by'|translate) : ''}}
          <span *ngIf="academyMedia.sponsorOrGrant?.getCompanyProfile()">
                                <a target="_blank" [href]="'/academy/profile/' + company_profile.slug">
                                {{academyMedia.sponsorOrGrant.company_name}}</a>
                            </span>
          <span *ngIf="!academyMedia.sponsorOrGrant?.getCompanyProfile() && academyMedia.sponsorOrGrant.social_network">
                                <a target="_blank" [href]="academyMedia.sponsorOrGrant.social_network.website">
                                {{academyMedia.sponsorOrGrant.company_name}}
                                </a>
                            </span>
        </p>
      </div>
    </div>
    <div class="row video-content desktop-only">
      <div
        [ngClass]="academyMedia && (academyMedia.academy_course || mediasRelated && mediasRelated.length > 0) ? 'col-md-6': 'col-md-12'">
        <h4>{{'pluralize.Comments'|translate}} ({{academyMedia?.comments_count}})</h4>
        <app-comment-area [user]="user" [comments]="academyMedia.comments"
                          [locale]="locale" [id]="academyMedia.id" [type]="'academy_media'"
                          [mustBuyInMarketplace]="academyMedia.academy_course && academyMedia.academy_course.paid_course && !academyMedia.academy_course.paid_by_auth"
                          [mustBePremium]="!academyMedia.free && (academyMedia.academy_course && !academyMedia.academy_course.paid_course)"
                          [userIsSpeaker]="userIsSpeaker"
                          [readOnly]="!academyMedia.full_video"
        ></app-comment-area>
      </div>
      <div class="col-md-6 video-content-medias">
        <h4 translate *ngIf="academyMedia.academy_course"
            [translateParams]="{nb: academyMedia?.academy_course?.academy_medias_count}"
        >academy.Course_lecture_nb</h4>
        <h2 class="d-none d-lg-block d-xl-block" *ngIf="academyMedia.academy_course"
            [routerLink]="'/course/' + academyMedia.academy_course.id + '/' + academyMedia.academy_course.getSlug()">
          {{academyMedia?.academy_course ? translationService.getTranslatedAttribute('title', academyMedia.academy_course.translations, locale) : ''}}
        </h2>
        <p class="d-none d-lg-block d-xl-block"
           *ngIf="academyMedia && academyMedia.academy_course && academyMedia.academy_course.percentage_viewed">
          {{user ? ' - ' + ('academy.You_have_completed'|translate) + ' ' + academyMedia.academy_course.percentage_viewed + '%' : ''}}</p>
        <ng-container *ngIf="!loadingCourse">
          <div class="lecture-item" *ngFor="let aMedia of academyMedia?.academy_course?.academy_medias">
            <div class="mask-in-progress" *ngIf="aMedia.id == academyMedia.id"></div>
            <!-- mettre app lecture -->
            <app-lecture [thumbFormat]="'squared'" [lecture]="aMedia" *ngIf="aMedia" [user]="user"
                         [favoriteElement]="false" [card]="false"></app-lecture>
          </div>
        </ng-container>
        <app-loader *ngIf="loadingCourse && academyMedia.academy_course"></app-loader>
        <h4 *ngIf="academyMedia && mediasRelated && mediasRelated.length > 0">
          {{'academy.Related_videos'|translate}} </h4>
        <div class="video-related" *ngIf="mediasRelated && mediasRelated.length > 0">
          <div *ngFor="let media of mediasRelated">
            <div class="lecture-item" (click)="goToVideo(media)">
              <app-lecture [thumbFormat]="'squared'" [lecture]="media" *ngIf="media" [user]="user"
                           [favoriteElement]="false" [card]="false"></app-lecture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="mobile-only">
      <div class="video-tabs">
        <ul class="tabs tabs-small">
          <li class="tab" [ngClass]="{'active': activeTab === 'lectures'}" (click)="activeTab = 'lectures'"
              *ngIf="academyMedia?.academy_course?.academy_media_ids?.length > 0">
            <a translate
               [translateParams]="{nb: academyMedia?.academy_course?.academy_medias_count}"
            >academy.Course_lecture_nb</a>
          </li>
          <li class="tab"
              [ngClass]="{'active': activeTab === 'comments', 'full-width': !academyMedia?.academy_course}"
              (click)="activeTab = 'comments'">
            <a>{{'pluralize.Comments'|translate}} ({{academyMedia?.comments_count}})</a>
          </li>
        </ul>
      </div>
      <div class="lecture" *ngIf="activeTab === 'lectures' && academyMedia && academyMedia.academy_course">
        <h2 class="d-none d-lg-block d-xl-block"
            [routerLink]="'/course/' + academyMedia.academy_course.id + '/' + academyMedia.academy_course.getSlug()">
          {{academyMedia?.academy_course ? translationService.getTranslatedAttribute('title', academyMedia.academy_course.translations, locale) : ''}}</h2>
        <p class="d-none d-lg-block d-xl-block"
           *ngIf="academyMedia && academyMedia.academy_course && academyMedia.academy_course.percentage_viewed">
          {{academyMedia.academy_course.academy_medias_count}} {{'academy.videos'|translate}}{{user ? ' - ' + ('academy.You_have_completed'|translate) + ' ' + academyMedia.academy_course.percentage_viewed + '%' : ''}}</p>
        <ng-container *ngIf="!loadingCourse">
          <div class="lecture-item row" *ngFor="let aMedia of academyMedia?.academy_course?.academy_medias">
            <div class="mask-in-progress" *ngIf="aMedia.id == academyMedia.id"></div>
            <app-lecture [card]="false" [thumbFormat]="'rectangular'" [lecture]="aMedia" [user]="user"
                         [favoriteElement]="false"></app-lecture>
          </div>
        </ng-container>
        <app-loader *ngIf="loadingCourse"></app-loader>
      </div>

      <app-comment-area *ngIf="activeTab === 'comments'" [user]="user" [comments]="academyMedia.comments"
                        [locale]="locale" [id]="academyMedia.id" [type]="'academy_media'"
                        [mustBePremium]="!academyMedia.free"></app-comment-area>

      <div class="video-header" *ngIf="academyMedia && mediasRelated && mediasRelated.length > 0">
        <h2>{{'academy.Related_videos'|translate}}</h2>
      </div>
      <div class="row">
        <div class="video-related academy-slider" *ngIf="mediasRelated && mediasRelated.length > 0">
          <div *ngFor="let media of mediasRelated">
            <div class="lecture-item" (click)="goToVideo(media)">
              <app-lecture [card]="true" [thumbFormat]="'rectangular'" [lecture]="media" [user]="user"
                           [favoriteElement]="false"></app-lecture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="company-news"
         *ngIf="activeTab === 'lectures' && academyMedia && academyMedia.sponsorOrGrant &&  academyMedia.sponsorOrGrant.company_profiles && academyMedia.sponsorOrGrant.company_profiles.length > 0">
      <h3>{{('academy.Discover'|translate) + ' ' + academyMedia.sponsorOrGrant.company_name}}</h3>
      <div class="company-news-logo">
        <a [href]="'/academy/profile/' + company_profile.slug">
          <img [src]="academyMedia.sponsorOrGrant.logo_url"
               [alt]="academyMedia.sponsorOrGrant.company_name">
        </a>
      </div>
      <div class="company-news-header" *ngIf="articles && articles.length">
        <h5>{{'layout.Latest_news'|translate}}</h5>
      </div>
      <div class="row" *ngIf="articles && articles.length">
        <div class="company-news-item col-md-4"
             *ngFor="let article of articles">
          <h6>{{article.title}}</h6>
          <p>{{ dateMomentService.getPublicationDate(article.publication_date) }}</p>
          <a
            [href]="environment.base_url + '/' + locale + '/academy/profile/' + company_profile.slug + '/articles/' + article.id + '/' + article.slug">{{'academy.Read_this_article'|translate}}</a>
        </div>
      </div>
      <div class="company-news-more">
        <a [href]="environment.base_url + '/' + locale + '/academy/profile/' + company_profile.slug"
           class="button button-blue">{{('academy.Discover'|translate) + ' ' +
        academyMedia?.sponsorOrGrant?.company_name}}</a>
      </div>
    </div>
  </div>
</div>

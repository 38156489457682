<div class="quote {{home && 'home'}}" *ngIf="feedback && feedback.translations && feedback.user && feedback.user.specialty && feedback.user.specialty.translations && feedback.user.country && feedback.user.country.translations">
  <span class="avatar {{home && 'desktop-only'}}" [style.background-image]="'url(' + feedback.user.picture_url + ')'"></span>
  <span class="quote-name">
              {{ feedback.user.title + ' ' + feedback.user?.last_name + ' ' + feedback.user?.first_name }}
            </span>
  {{' - ' + translationService.getTranslatedElement(feedback.user?.specialty?.translations, locale)['name'] + ', ' + translationService?.getTranslatedElement(feedback.user?.country?.translations, locale)['name']}}
  <blockquote class="quote-quote">
    "{{home && translationService.getTranslatedElement(feedback.translations, locale)['content'].length > 150 ?
    translationService.getTranslatedElement(feedback.translations, locale)['content'].substring(0,
      translationService.getTranslatedElement(feedback.translations, locale)['content'].substring(150).search("\n")+150
    ).trim() + "..."
    : translationService.getTranslatedElement(feedback.translations, locale)['content'].trim()
    }}"
  </blockquote>
</div>

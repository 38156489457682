import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../../../../services/translation.service';

@Component({
  selector: 'app-single-event',
  templateUrl: './single-event.component.html',
  styleUrls: ['../worldwide-events.component.scss']
})
export class SingleEventComponent implements OnInit {
  @Input() event: any;
  @Input() locale: string;

  constructor(
    public translationService: TranslationService,
  ) { }

  ngOnInit() {

  }

}

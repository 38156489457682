import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {map, shareReplay} from 'rxjs/internal/operators';


@Injectable()
export class ScientificSocietyService {
  private endpoint: string = '/scientific_societies';

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getScientificSocieties(term: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiURL + this.endpoint, {
      params: new HttpParams().set('all', '1')
    })
      .pipe(map((result: any) => {
        if (term) {
          result = result.filter(obj =>
            (obj.name && obj.name.toLowerCase().indexOf(term.toLowerCase()) !== -1) ||
            (obj.acronym && obj.acronym.toLowerCase().indexOf(term.toLowerCase()) !== -1) ||
            (obj.country && obj.country.translations.find(translation =>
              translation.name.toLowerCase().indexOf(term.toLowerCase()) !== -1))
          );
        }
        return result;
      }), map(result => result.sort((a, b) => {
        if (a.country && b.country && a.country.id < b.country.id) {
          return -1;
        }
        if (a.country && b.country && a.country.id > b.country.id) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })), shareReplay());
  }

  getScientificSociety(id: number): Observable<any> {
    return this.httpClient.get<any>(environment.apiURL + this.endpoint + '/' + id).pipe(shareReplay());
  }

  addScientificSociety(societyId: number): Observable<any> {
    return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/' + societyId + '/user', null).pipe(shareReplay());
  }

  deleteScientificSociety(societyId: number): Observable<any> {
    return this.httpClient.delete<any>(environment.apiURL + this.endpoint + '/' + societyId + '/user').pipe(shareReplay());
  }
}

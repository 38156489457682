import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../models/user.model';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';


@Component({
    selector: 'app-connect-btn',
    templateUrl: './connect-btn.component.html',
    styleUrls: ['./connect-btn.component.scss']
})
export class ConnectBtnComponent implements OnInit {
    @Input() profile: any;
    user: User;

    constructor(private authService: AuthService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.user = this.authService.getCurrentUser();
    }

    connect() {
        if (this.user && this.profile && this.profile.friendship_status_with_auth === 'not_friend') {
            this.userService.connect(this.profile.id).subscribe(success => {
                this.profile.friendship_status_with_auth = 'waiting_friend_accept';
            });
        }
    }
}

import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
    static padNumber(value) {
        return typeof value === 'number' ? `0${value}`.slice(-2) : '';
    }

    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split('-');
            if (dateParts.length === 1) {
                return {day: parseInt(`${dateParts[0]}`, 10), month: null, year: null};
            } else if (dateParts.length === 2) {
                return {day: parseInt(`${dateParts[0]}`, 10), month: parseInt(`${dateParts[1]}`, 10), year: null};
            } else if (dateParts.length === 3) {
                return {
                    year: parseInt(`${dateParts[0]}`, 10),
                    month: parseInt(`${dateParts[1]}`, 10),
                    day: parseInt(`${dateParts[2]}`, 10)
                };
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        if (!date) {
            return '';
        }
        const day = typeof date.day === 'number' ? NgbDateCustomParserFormatter.padNumber(date.day) : '';
        const month = typeof date.month === 'number' ? NgbDateCustomParserFormatter.padNumber(date.month) : '';
        return date ? `${day}/${month}/${date.year}` : '';
    }


}

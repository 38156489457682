import {NotificationService} from 'app/services/notification.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {User} from '../../../models/user.model';
import {LocaleService} from '../../../services/locale.service';
import {Webinar} from 'app/models/webinar.model';
import {WebinarService} from '../../../services/webinar.service';
import {DateMomentService} from '../../../services/date-moment.service';

@Component({
  selector: 'app-header-aop',
  templateUrl: './header-aop.component.html',
  styleUrls: ['./header-aop.component.scss'],
  providers: [
    NotificationService,
    WebinarService
  ]
})
export class HeaderAopComponent implements OnInit {

  @Input() headerMenuOpened: any;
  @Input() logo: string;
  @Output() loggedOutClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  env = environment;
  showBannerWebinar: boolean;
  showBannerType: string;
  nextWebinar: Webinar;
  user: User;
  logged: any;
  showAlert: boolean;
  isMasterclassActiveTab: boolean = false;
  nextMasterclassSlug: string = '';

  constructor(public authService: AuthService,
              private router: Router,
              public localeService: LocaleService,
              private webinarService: WebinarService,
              public dateMomentService: DateMomentService) {
  }

  ngOnInit() {
    this.logged = false;
    this.showBannerWebinar = false;
    this.user = this.authService.getCurrentUser();
    this.setShowAlert();
    this.authService.isLoggedIn().subscribe(logged => {
      this.user = this.authService.getCurrentUser();
      this.logged = logged;
      this.setShowAlert();
    });
    this.router.events.subscribe(() => {
      this.setShowAlert();
    })
    this.getNextMasterclass();
  }

  setShowAlert() {
    this.showAlert = !this.logged || (this.user && this.user.specialty_category === 'others' || this.user.specialty_category === 'physician');
  }

  logout() {
    this.loggedOutClick.emit(true);
  }

  isActiveTab(tab) {
    return this.router.url.indexOf(tab) > 0;
  }

  getNextMasterclass() {
    this.webinarService.getNextMasterclass().subscribe(data => {
      if (!!data && data.domain === 'aop') {
        this.nextMasterclassSlug = data.slug;
        this.isMasterclassActiveTab = true;
      }
    });
  }

  setNextWebinar(webinar) {
    this.nextWebinar = webinar;
    if (webinar !== null) {
      if (webinar.datetime_when_in_hours === -1 && webinar.webinar_launched !== 1) {
        this.showBannerWebinar = false;
      } else {
        this.showBannerWebinar = true;
        if (webinar.webinar_launched === 1) {
          this.showBannerType = 'current';
        } else if (webinar.datetime_when_in_hours >= 0 && webinar.datetime_when_in_hours <= 2) {
          this.showBannerType = 'soon';
        } else if (webinar.datetime_when_in_hours > 2 && webinar.datetime_when_in_hours <= 24) {
          this.showBannerType = 'today';
        }
      }
    }
  }
}

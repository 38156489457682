import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {UserBadgeService} from '../../../../services/user-badge.service';
import {User} from '../../../../models/user.model';
import {AuthService} from 'app/services/auth.service';
import {TranslationService} from '../../../../services/translation.service';
import {LocaleService} from '../../../../services/locale.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class AccountBadgesComponent implements OnInit {
  user: User;
  locale: any;
  badges: any = [];
  badgeGroups: any;
  badgesRaw: any;
  userBadges: any;
  environment: any = environment;
  loading: boolean = false;
  apiError: any;

  constructor(private httpClient?: HttpClient,
              private userBadgeService?: UserBadgeService,
              private authService?: AuthService,
              public translationService?: TranslationService,
              private localeService?: LocaleService) { }

  ngOnInit() {
    this.loading = true;
    this.user = this.authService.getCurrentUser();
    this.locale = this.localeService.currentLocale();
    this.getAllAndFormat();
  }

  getAllAndFormat() {
    this.userBadgeService.getAllBadges().subscribe(badges => {
      this.badges = badges;

      this.loading = false;
    }, err => {
      this.loading = false;
      this.apiError = err.message;
    });
  }
}

<app-loader *ngIf="showLoading"></app-loader>
<form class="form form-login" #themesForm="ngForm" (ngSubmit)="addThemes()" *ngIf="!showLoading">

  <p class="form-login-subhead">
    {{'account.Choose_you_main_interests'|translate}}
  </p>
  <div>
    <u>{{ 'account.Themes_and_keywords_choose_at_least'|translate }}</u>
  </div>
  <br/>
  <div class="form-row form-select" style="border:1px solid #ccc; padding: 20px; margin-bottom: 20px;">
    <label class="form-label form-label-text" for="theme0">
      {{'account.Theme_1'|translate}}
    </label>
    <div class="select-wrap select-wrap-small" *ngIf="themes0">
      <dropdown-select-component [options]="themes0" [id]="'theme0'" [name]="'theme0'" [(selectedOption)]="selectedThemes[0]" (optionChange)="themeChange(0,$event)">
      </dropdown-select-component>
    </div>
    <div class="form-checkbox" *ngIf="keywords0.length > 0">
      <div style="font-size:11px;">
        <u>{{ 'account.Keywords'|translate }}</u>
      </div>
      <br/>
      <label *ngFor="let keyword of keywords0" style="display:block;">
        <input type="checkbox" class="form-control" [checked]="keyword.checked" (change)="keywordChange(keyword, $event.target.checked)">
        <span>{{translationService.getTranslatedName(keyword.translations, locale)}}</span>
      </label>
    </div>
  </div>
  <div class="form-row form-select" style="border:1px solid #ccc; padding: 20px; margin-bottom: 20px;">
    <label class="form-label form-label-text" for="theme1">
      {{'account.Theme_2'|translate}}
    </label>
    <div class="select-wrap select-wrap-small" *ngIf="themes1">
      <dropdown-select-component [options]="themes1" [id]="'theme1'" [name]="'theme1'" [(selectedOption)]="selectedThemes[1]" (optionChange)="themeChange(1,$event)">
      </dropdown-select-component>
    </div>
    <div class="form-checkbox" *ngIf="keywords1.length > 0">
      <div style="font-size:11px;">
        <u>{{ 'account.Keywords'|translate }}</u>
      </div>
      <br/>
      <label *ngFor="let keyword of keywords1" style="display:block;">
        <input type="checkbox" class="form-control" [checked]="keyword.checked" (change)="keywordChange(keyword, $event.target.checked)">
        <span>{{translationService.getTranslatedName(keyword.translations, locale)}}</span>
      </label>
    </div>
  </div>
  <div class="form-row form-select" style="border:1px solid #ccc; padding: 20px; margin-bottom: 20px;">
    <label class="form-label form-label-text" for="theme2">
      {{'account.Theme_3'|translate}}
    </label>
    <div class="select-wrap select-wrap-small" *ngIf="themes2">
      <dropdown-select-component [options]="themes2" [id]="'theme2'" [name]="'theme2'" [(selectedOption)]="selectedThemes[2]" (optionChange)="themeChange(2,$event)">
      </dropdown-select-component>
    </div>
    <div class="form-checkbox" *ngIf="keywords2.length > 0">
      <div style="font-size:11px;">
        <u>{{ 'account.Keywords'|translate }}</u>
      </div>
      <br/>
      <label *ngFor="let keyword of keywords2" style="display:block;">
        <input type="checkbox" class="form-control" [checked]="keyword.checked" (change)="keywordChange(keyword, $event.target.checked)">
        <span>{{translationService.getTranslatedName(keyword.translations, locale)}}</span>
      </label>
    </div>
  </div>
  <div class="form-row form-select" style="border:1px solid #ccc; padding: 20px; margin-bottom: 20px;">
    <label class="form-label form-label-text" for="theme3">
      {{'account.Theme_4'|translate}}
    </label>
    <div class="select-wrap select-wrap-small" *ngIf="themes3">
      <dropdown-select-component [options]="themes3" [id]="'theme3'" [name]="'theme3'" [(selectedOption)]="selectedThemes[3]" (optionChange)="themeChange(3,$event)">
      </dropdown-select-component>
    </div>
    <div class="form-checkbox" *ngIf="keywords3.length > 0">
      <div style="font-size:11px;">
        <u>{{ 'account.Keywords'|translate }}</u>
      </div>
      <br/>
      <label *ngFor="let keyword of keywords3" style="display:block;">
        <input type="checkbox" class="form-control" [checked]="keyword.checked" (change)="keywordChange(keyword, $event.target.checked)">
        <span>{{translationService.getTranslatedName(keyword.translations, locale)}}</span>
      </label>
    </div>
  </div>
  <div class="form-row form-button">
    <button class="button button-block button-large button-orange" [disabled]="!themesForm.valid || loading"><span *ngIf="!loading">{{'academy.Add_main_interests'|translate}}</span>
      <span *ngIf="loading">{{ 'program.Loading'|translate }}...</span>
    </button>
  </div>
  <div *ngIf="registrationError" class="form-login-error-main">
    {{ registrationError }}
  </div>
</form>

import {environment} from './../../../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {detect} from 'detect-browser';
import {shareReplay} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-system-check',
    templateUrl: './system-check.component.html',
    styleUrls: ['./system-check.component.scss']
})
export class SystemCheckComponent implements OnInit {
    browser: any;
    status: any;
    isConnectionValid: any;

    constructor(private httpClient: HttpClient) {
    }

    ngOnInit() {
        this.browser = detect();
        let request_time = (new Date).getTime();
        this.isConnectionValid = false;
        this.status = 'loading';
        this.httpClient.get<any>(environment.cdn_base_url + '/assets-front/img/icons.png', {
            responseType: 'blob' as 'json'
        })
            .pipe(shareReplay()).subscribe(() => {
            request_time = (new Date).getTime() - request_time;
            this.isConnectionValid = request_time < 600;
            if (!this.isConnectionValid) {
                this.status = 'issues';
            }
        });
        if (this.browser) {
            this.status = this.checkVersion(this.browser.name, this.browser.version) ? 'good' : 'issues';
        }
    }

    checkVersion(name, version) {
        const numberVersion = version.split('.')[0];
        let status = false;
        switch (name) {
            case 'chrome':
                status = numberVersion > 57;
                break;
            case 'firefox':
                status = numberVersion > 51;
                break;
            case 'edge':
                status = numberVersion > 13;
                break;
            case 'safari':
                status = numberVersion > 9;
                break;
            default:
                status = false;
        }
        return status;
    }

}

import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {AuthService} from '../../../../services/auth.service';
import {TranslationService} from '../../../../services/translation.service';
import {TranslateService} from '@ngx-translate/core';
import {LocaleService} from '../../../../services/locale.service';
import {Locales} from '../../../../locales';
import {environment} from '../../../../../environments/environment';
import {User} from '../../../../models/user.model';
import {Router} from '@angular/router';


@Component({
  selector: 'account-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  providers: [
    UserService,
  ]
})
export class AccountLanguagesComponent implements OnInit {
  user: User;
  locale: string;
  languageOptions = Locales;
  selectedLanguage: any;
  environment: any;
  newLanguage: boolean = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    public translationService: TranslationService,
    private translateService: TranslateService,
    private localeService: LocaleService,
    private router: Router
  ) {
  }

  getLanguagesAvailable() {
    if (this.environment.domain !== 'aesthetics') {
      delete this.languageOptions.es;
      delete this.languageOptions.ru;
      delete this.languageOptions.zh;
    }
    return this.languageOptions;
  }

  ngOnInit() {
    this.environment = environment;
    this.selectedLanguage = this.translateService.currentLang;
    this.locale = this.localeService.currentLocale();
    this.user = this.user ? this.user : this.authService.getCurrentUser();
  }

  languageChanged(data) {
    this.selectedLanguage = data;
    if (this.user) {
      this.selectedLanguage = data;
      this.userService.updateUser({
        language: this.selectedLanguage
      }).subscribe(user => {
        console.log('WINDOW LOCATION', window.location.href);
        if (!environment.mobile) {
          window.location.href = this.environment.base_url + '/' + this.selectedLanguage + '/academy' + this.router.url;
        } else {
          this.authService.setCurrentUser(user);
          this.newLanguage = true;
          setTimeout(() => {window.location.reload();}, 1000);
        }
      });
    } else {
      if (!environment.mobile) {
        const pathWithoutLocale = this.router.url.replace(this.translateService.currentLang + '/', '');
        window.location.href = environment.base_url + '/' + data + '/academy' + pathWithoutLocale;
      }
    }
  }
}

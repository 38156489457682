<ng-container>
    <h2>{{ 'faq.FAQ'|translate }}</h2>
    <div class="well" *ngIf="showSummary">
        <ng-container *ngFor="let part of parts; index as group;">
            <a [routerLink]='"."' [fragment]="encode(group)" class="btn btn-light">{{ groups[group] }}</a>
        </ng-container>
        <div class="search custom-typeahead">
            <input id="typeahead-template" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search"
                   [resultTemplate]="rt"
                   (click)="model = ''"
                   [inputFormatter]="formatter" [placeholder]="'faq.Input_search_placeholder'|translate"/>
        </div>
        <ng-template #rt let-r="result" let-t="term">
            <div (click)="navToFragment('question-' + splitRank(r.rank))">
                <div class="result-group">{{ r.group }}</div>
                <div class="result-question">{{ r.rank }} {{ r.question }}</div>
            </div>
        </ng-template>
        <div class="row summary">
            <div class="col-12 questions" *ngFor="let part of parts; index as group;" [attr.id]='encode(group)'>
                <h3>{{ groups[group] }}</h3>

                <a *ngFor="let question of part" style="display: block" [routerLink]='"."' [fragment]="'question-' + splitRank(question.rank)"
                >
                    {{ question.rank }} {{ translationService.getTranslatedAttribute('title', question.translations, locale) }}
                </a>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div [attr.id]="encode(group)" class="col-12 questions" *ngFor="let part of parts; index as group;">
                <h2>{{ groups[group] }}</h2>
                <div class="premium-faq-item" *ngFor="let question of part" [attr.id]="'question-' + splitRank(question.rank)"
                     >
                    <h3>{{ question.rank }} {{ translationService.getTranslatedAttribute('title', question.translations, locale) }}</h3>
                    <p [innerHTML]="translationService.getTranslatedAttribute('text', question.translations, locale)"></p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<router-outlet></router-outlet>

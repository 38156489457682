import {Component, OnInit} from '@angular/core';
import {UserService} from 'app/services/user.service';
import {User} from 'app/models/user.model';
import {AuthService} from '../../../../services/auth.service';
import {AlertService} from "../../../../services/alert.service";


@Component({
    selector: 'notifications-settings',
    templateUrl: './notifications-settings.component.html',
    styleUrls: ['./notifications-settings.component.scss'],
    providers: [
        UserService,
      AlertService

    ]
})
export class NotificationsSettingsComponent implements OnInit {
    user: User;
    updated: boolean = false;
    error: string;
    newUser = {
        partners_optin: null,
        subscribe_academy_web: null,
        subscribe_web: null,
        website_email_notification_alert: null,
        website_email_notification_connection_request: null,
        website_email_notification_message: null,
        website_email_notification_video_comment: null,
        website_email_notification_blog: null,
    };
    coordinatorNotifs = {
      coordinator_receive_recap: null
    }
    loading: boolean;
    isCoordinator: boolean | number = false;
    constructor(private userService: UserService,
                private authService: AuthService,
                private alertService: AlertService) {
    }

    ngOnInit() {
        this.loading = true;
        this.getUser();
    }

    getUser() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {user_notifications: 1}).subscribe(
                user => {
                    this.loading = false;
                    this.user = new User({...this.user, ...user});
                    this.newUser.partners_optin = this.user.email_notifications.partners_optin;
                    this.newUser.subscribe_academy_web = this.user.email_notifications.subscribe_academy_web;
                    this.newUser.subscribe_web = this.user.email_notifications.subscribe_web;
                    this.newUser.website_email_notification_alert = this.user.email_notifications.website_email_notification_alert;
                    this.newUser.website_email_notification_connection_request = this.user.email_notifications.website_email_notification_connection_request;
                    this.newUser.website_email_notification_message = this.user.email_notifications.website_email_notification_message;
                    this.newUser.website_email_notification_video_comment = this.user.email_notifications.website_email_notification_video_comment;
                    this.newUser.website_email_notification_blog = this.user.email_notifications.website_email_notification_blog;
                    this.coordinatorNotifs.coordinator_receive_recap = this.user.email_notifications.coordinator_receive_recap;
                    this.isCoordinator = this.user.email_notifications.is_coordinator;
                }
            );
        }
    }

    updateProfile() {
        const tmpUser = Object.assign({}, this.newUser);
        tmpUser.partners_optin = tmpUser.partners_optin ? 1 : 0;
        tmpUser.subscribe_academy_web = tmpUser.subscribe_academy_web ? 1 : 0;
        tmpUser.subscribe_web = tmpUser.subscribe_web ? 1 : 0;
        tmpUser.website_email_notification_alert = tmpUser.website_email_notification_alert ? 1 : 0;
        tmpUser.website_email_notification_connection_request = tmpUser.website_email_notification_connection_request ? 1 : 0;
        tmpUser.website_email_notification_message = tmpUser.website_email_notification_message ? 1 : 0;
        tmpUser.website_email_notification_video_comment = tmpUser.website_email_notification_video_comment ? 1 : 0;
        tmpUser.website_email_notification_blog = tmpUser.website_email_notification_blog ? 1 : 0;
        this.userService
            .updateUser(tmpUser)
            .subscribe(
                (result: any) => {
                    if (!result.error) {
                        this.updated = true;
                    } else {
                        this.error = result.error;
                    }
                });
    }

    isNotUndefined(val) {
        return typeof val !== 'undefined';
    }

  updateCoordinatorNotif() {
      const tmp = this.coordinatorNotifs;
      tmp.coordinator_receive_recap = this.coordinatorNotifs.coordinator_receive_recap ? 1 : 0;
      this.alertService.coordinatorReceiveRecap(tmp).subscribe(result => {
        if (!result.error) {
          this.updated = true;
        } else {
          this.error = result.error;
        }
      })
  }
}

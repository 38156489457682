import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorage } from '@ngx-pwa/local-storage';

declare var window: any;
declare var document: any;
declare var cordova: any;

@Injectable()
export class OfflineVideoService {

  videosState:any = [];
  public videoSaved:any = [];

  constructor(
    private ngZone:NgZone,
    protected localStorage: LocalStorage,
  ) {
    this.localStorage.getItem('VIDEO_index').subscribe(
      res => {
        if (res) {
          this.videoSaved = res;
          this.videoSaved.forEach(video => {
            this.getVideoState(video.id).offlineVideo = video;
          })
        }
      }
    );
  }

  getVideoState(id){
    if (this.videosState[id]){
      return this.videosState[id];
    } else {
      this.videosState[id] = {
        offlineVideo:null,
        downloading:false,
        percentDownload:0,
        written:0,
        BLOCK_SIZE: 1 * 1024 * 1024,
        fileWriter:null,
        fileEntry:null,
        data:null
      }
      return this.videosState[id];
    }
  }

  deleteVideo(id) {

    let scope = this;

    document.addEventListener('deviceready', () => {
      let storageLocation = cordova.file.dataDirectory;

      window.resolveLocalFileSystemURL(storageLocation, fileSystem => {
        fileSystem.getDirectory('Download', {
          create: true,
          exclusive: false
        }, directory => {
          directory.getFile("video_" + id + ".mp4", {
            create: false
          }, fileEntry => {
            fileEntry.remove(function () {
              scope.ngZone.run(() => {
                setTimeout(() => {

                }, 200);
              });
            }, function (error) {
              console.log("error")
            }, function () {
              console.log("does not exist")
            });
          }, errorCallback)
        }, errorCallback);

        var errorCallback = function (e) {
          scope.ngZone.run(() => {
            console.log("Error File: " + e);
            console.log("Erreur lors de la suppression");
          });
        }

      })
    })
  }
}

<div class="container_app" [class.container_app_aop]="environment.domain == 'ophthalmology'">
  <div class="tutorial">

    <div class="skip text-center" (click)="skip()">
        <span>{{'application.Skip'|translate}}</span>
    </div>

    <div class="tutorial-slider">

        <div class="slide" [ngStyle]="{'background-image': 'linear-gradient(0deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 100%), url(\''+tutoImage1+'\')'}">
            <div class="text">
                <h2 *ngIf="environment.domain == 'aesthetics'">{{'application.Tutorial_screen1_Title'|translate}}</h2>
                <h2 *ngIf="environment.domain == 'ophthalmology'">{{'application.Tutorial_screen1_Title_aop'|translate}}</h2>
                <p *ngIf="environment.domain == 'aesthetics'" [innerHTML]="'application.Tutorial_screen1_para'|translate"></p>
                <p *ngIf="environment.domain == 'ophthalmology'" [innerHTML]="'application.Tutorial_screen1_para_aop'|translate"></p>
            </div>
        </div>

        <div class="slide" style="background-image: linear-gradient(0deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 100%), url('assets/img/tutorial/tuto2.png')">
            <div class="text">
                <h2>{{'application.Tutorial_screen2_title'|translate}}</h2>
                <p><i class="fal fa-search tuto-picture"></i></p>

                <p [innerHTML]="'application.Tutorial_screen2_para'|translate"></p>
            </div>
        </div>

        <div class="slide" style="background-image: linear-gradient(0deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 100%), url('assets/img/tutorial/tuto3.png')">
            <div class="text">
                <h2>{{'application.Tutorial_screen3_title'|translate}}</h2>
                <p>
                  <i *ngIf="environment.domain == 'aesthetics'" class="fal fa-film tuto-picture"></i>
                  <i *ngIf="environment.domain == 'ophthalmology'" class="fal fa-users tuto-picture"></i>
                </p>
                <p *ngIf="environment.domain == 'aesthetics'" [innerHTML]="'application.Tutorial_screen3_para'|translate:{nb: nbPhysicians}"></p>
                <p *ngIf="environment.domain == 'ophthalmology'" [innerHTML]="'application.Tutorial_screen3_para_aop'|translate:{nb: nbPhysicians}"></p>
            </div>
        </div>

        <div class="slide" style="background-image: linear-gradient(0deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 100%), url('assets/img/tutorial/tuto4.png')">
            <div class="text">
                <h2>{{'application.Tutorial_screen4_title'|translate}}</h2>
                <p><i class="fal fa-user-md tuto-picture"></i></p>
                <p [innerHTML]="'application.Tutorial_screen4_para'|translate"></p>
            </div>
        </div>

        <div class="slide" style="background-image: linear-gradient(0deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 100%), url('assets/img/tutorial/tuto5.png')">
            <div class="text">
                <h2>{{'application.Tutorial_screen5_title'|translate}}</h2>
                <p>
                  <i *ngIf="environment.domain == 'aesthetics'" class="fal fa-envelope tuto-picture"></i>
                  <i *ngIf="environment.domain == 'ophthalmology'" class="fal fa-graduation-cap tuto-picture"></i>
                </p>
                <p [innerHTML]="'application.Tutorial_screen5_para'|translate"></p>
            </div>
        </div>

    </div>

    <button class="tutorial-next text-center" (click)="handleNextButton()">
      <span *ngIf="currentSlide < 4">{{'academy.Next'|translate}}</span>
      <span *ngIf="currentSlide == 4">{{'application.Tutorial_cta'|translate}}</span>
    </button>

    <div class="tutorial-dots"></div>

  </div>
</div>

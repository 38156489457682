<header class="mobile-header header_app" [class.centered]="exploreOpened || accountOpened" [class.search]="searchOpened" [class.header_aop]="environment.domain == 'ophthalmology'">


  <ng-container *ngIf="!exploreOpened && !accountOpened && !searchOpened">
    <div class="user-container" (click)="toggleAccountMenu()">
      <div *ngIf="user && user.picture_url" class="user-picture" [style.background-image]="'url('+ user.picture_url + ')'"></div>
      <div *ngIf="!user || !user.picture_url" class="user-picture" [style.background-image]="'url('+ environment.cdn_base_url + '/users/default/user.png)'"></div>
      <div class="notification" *ngIf="alertUnread.length"></div>
    </div>
    <a [routerLink]="'/dashboard'" (click)="closeAllMenu()">
      <img src="../../../../assets/img/logo_white.png" class="logo_app" alt="Logo IMCAS Academy" *ngIf="environment.domain == 'aesthetics'">
      <img src="../../../../assets/img/logo_white_aop.png" class="logo_app" alt="Logo AOP Academy" *ngIf="environment.domain == 'ophthalmology'">
    </a>
    <i class="fal fa-search" style="font-size: 20px;" (click)="toggleSearchMenu()"></i>
  </ng-container>



  <ng-container *ngIf="exploreOpened">
    <a class="btn_header" (click)="toggleExploreMenu()"><i class="fal fa-arrow-left"></i></a>
    <p>{{ 'academy.Explore'|translate }}</p>
  </ng-container>

  <ng-container *ngIf="accountOpened">
    <a class="btn_header" (click)="toggleAccountMenu()"><i class="fal fa-arrow-left"></i></a>
    <p>{{ 'account.My_account'|translate }}</p>
  </ng-container>

  <ng-container *ngIf="searchOpened">
    <a class="btn_header search-btn_header" (click)="toggleSearchMenu()"><i class="fal fa-arrow-left"></i></a>
    <div class="input-zone">
      <input class="search-input" type="text" placeholder="{{ 'academy.Search_all'|translate }}" (keyup)="launchSearch($event)" [(ngModel)]="search">
    </div>
  </ng-container>

  <div class="offline_banner" *ngIf="networkService.isOffline">
    <p>{{ 'application.Offline_banner'|translate }}</p>
  </div>
</header>
<div class="webinar-banner" *ngIf="showBannerWebinar && environment.domain !== 'ophthalmology'">
  <a [routerLink]="'/webinars'" *ngIf="showBannerType =='today'">
    {{ 'webinar.Live_webinar_banner_hours'|translate: {hours: nextWebinar.datetime_when_in_hours} }}
  </a>
  <a [routerLink]="'/webinar/' + nextWebinar.slug" *ngIf="showBannerType =='current'">
    {{ 'webinar.Live_webinar_now'|translate }}
  </a>
  <a [routerLink]="'/webinars'" *ngIf="showBannerType =='soon'">
    {{ 'webinar.Live_webinar_banner_starting_soon'|translate }}
  </a>
  <i class="fal fa-times" *ngIf="showBannerType" (click)="showBannerWebinar = false"></i>
</div>

<div class="menu-list" *ngIf="moreOpened" [class.menu_aop]="environment.domain == 'ophthalmology'">
  <a class="menu-item" (click)="closeAllMenu()" routerLink="/inbox" *ngIf="user && (user.specialty_category == 'physician' || user.specialty_category == 'others')">
    <i class="fal fa-inbox more_picture"></i>
    <span>{{ 'inbox.Inbox'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
  <a class="menu-item" (click)="closeAllMenu()" routerLink="/blog" *ngIf="environment.domain == 'aesthetics'">
    <i class="fal fa-newspaper more_picture"></i>
    <span>{{ 'blog.Articles'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
  <a class="menu-item" (click)="toggleExploreMenu()">
    <i class="fal fa-map-signs more_picture"></i>
    <span>{{ 'academy.Explore'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
  <a class="menu-item" (click)="closeAllMenu()" (click)="linkService.openBlank(environment.base_url+'/en/privacy-policy')">
    <i class="fal fa-user-secret more_picture"></i>
    <span>{{ 'application.Privacy'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
  <a class="menu-item" (click)="closeAllMenu()" routerLink="/contact">
    <i class="fal fa-envelope more_picture"></i>
    <span>{{ 'sponsor.Contact'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
</div>

<div class="menu-list" *ngIf="exploreOpened" [class.menu_aop]="environment.domain == 'ophthalmology'">
  <a class="menu-item" (click)="closeAllMenu()" routerLink="/about" *ngIf="environment.domain == 'aesthetics'">
    <i class="fal fa-users more_picture"></i>
    <span>The Academy</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
  <a class="menu-item" (click)="closeAllMenu()" routerLink="/explore/scientific-board">
    <i class="fal fa-users more_picture"></i>
    <span>{{ 'academy.Scientific_board'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
  <a class="menu-item" (click)="closeAllMenu()" routerLink="/explore/partners">
    <i class="fal fa-handshake more_picture"></i>
    <span>{{ 'academy.Scientific_partners'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
  <a class="menu-item" (click)="closeAllMenu()" routerLink="/explore/worldwide-events">
    <i class="fal fa-globe more_picture"></i>
    <span>{{ 'explore.Worldwide_events'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>

  <a class="menu-item" (click)="closeAllMenu()" routerLink="/explore/humanitarian-missions" *ngIf="environment.domain == 'aesthetics'">
    <i class="fal fa-hands-heart more_picture"></i>
    <span>{{ 'academy.Humanitarian_missions'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>

  <a class="menu-item" (click)="closeAllMenu()" routerLink="/faq">
    <i class="fal fa-question-circle more_picture"></i>
    <span>{{ 'faq.FAQ'|translate }}</span>
    <p class="arrow"><i class="fal fa-angle-right"></i></p>
  </a>
</div>

<div class="menu-list" *ngIf="accountOpened" [class.menu_aop]="environment.domain == 'ophthalmology'">
  <div class="navigation">
    <li class="navigation-item">
      <i class="fal fa-bell more_picture"></i>
      <a [routerLink]="['/account/notifications']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
         class="navigation-link" id="link-app-notifications">{{ 'application.Notifications'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
    <li class="navigation-item">
      <i class="fal fa-address-card more_picture"></i>
      <a (click)="toggleSubmenu('membership')" class="navigation-link">{{ 'academy.Membership'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
    <div class="navigation-submenu-wrapper" id="membership-menu-wrapper">
      <li class="navigation-submenu">
        <a [routerLink]="['/account/current-plan']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-current-plan">{{ 'academy.Current_plan'|translate }}</a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/certificate']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-certificate">{{ 'academy.Certificates'|translate }}</a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/badges']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-badges">{{ 'academy.Badges'|translate }}</a>

      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/invoices']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-invoices">{{ 'account.Your_invoices'|translate }}</a>
      </li>
    </div>
    <li class="navigation-item">
      <i class="fal fa-list more_picture"></i>
      <a [routerLink]="['/account/courses']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
         class="navigation-link" id="link-courses">{{ 'academy.courses'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
    <li class="navigation-item">
      <i class="fal fa-heart more_picture"></i>
      <a [routerLink]="['/account/favorites']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
         class="navigation-link" id="link-favorites">{{ 'academy.Favorites'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
    <li class="navigation-item">
      <i class="fal fa-user-edit more_picture"></i>
      <div class="flex-grow-1">
        <a (click)="toggleSubmenu('profile')" class="navigation-link">{{ 'attend.Profile'|translate }}</a>
        <p *ngIf="percent < 95" style="padding: 0 10px">
          <ngb-progressbar type="success" [value]="percent">
            {{ 'academy.profile_percent_completed'|translate:{percent: percent} }}
          </ngb-progressbar>
        </p>
      </div>
      <i class="fal fa-angle-right"></i>
    </li>
    <div class="navigation-submenu-wrapper" id="profile-menu-wrapper">
      <li class="navigation-submenu">
        <a [routerLink]="['/account/professional-data']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-professional-data">
          {{ 'account.Professional_data'|translate }}
        </a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/certifications']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-certifications"
           *ngIf="user && user.specialty_category === 'physician'">
          {{ 'academy.certifications'|translate }}
        </a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/picture-and-cover']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-picture-and-cover">
          {{ 'account.Picture_cover'|translate }}
        </a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/main-interests']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-main-interests"
           *ngIf="user && user.specialty_category === 'physician'">
          {{ 'profile.Main_interests'|translate }}
        </a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/publications']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-publications"
           *ngIf="user && user.specialty_category === 'physician'">
          {{ 'profile.Publications'|translate }}
        </a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/scientific-societies']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-scientific-societies"
           *ngIf="user && user.specialty_category === 'physician'">
          {{ 'academy.Scientific_societies'|translate }}
        </a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/humanitarian-missions']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-humanitarian-missions"
           *ngIf="user && user.specialty_category === 'physician'">
          {{ 'academy.Humanitarian_missions'|translate }}
        </a>
      </li>
    </div>
    <li class="navigation-item">
      <i class="fal fa-user-friends more_picture"></i>
      <a [routerLink]="['/account/connections']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
         class="navigation-link" id="link-connections">{{ 'account.Connections'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
    <li class="navigation-item">
      <i class="fal fa-save more_picture"></i>
      <a (click)="toggleSubmenu('contributions')" class="navigation-link">{{ 'account.Your_contribution'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
    <div class="navigation-submenu-wrapper" id="contributions-menu-wrapper">
      <li class="navigation-submenu">
        <a [routerLink]="['/account/videos']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-videos">{{ 'academy.Videos_published'|translate }}</a>
      </li>
      <!--
        Page under construction
        <li class="navigation-submenu">
          <a [routerLink]="['videos-approval']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
             class="navigation-link" id="link-videos-approval">{{ 'academy.Videos_approval'|translate }}</a>
        </li>
        -->
      <li class="navigation-submenu">
        <a [routerLink]="['/account/articles']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-articles">{{ 'academy.Articles'|translate }}</a>
      </li>
    </div>
    <li class="navigation-item">
      <i class="fal fa-cog more_picture"></i>
      <a (click)="toggleSubmenu('settings')" class="navigation-link">{{ 'account.Settings'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
    <div class="navigation-submenu-wrapper" id="settings-menu-wrapper">
      <li class="navigation-submenu">
        <a [routerLink]="['/account/languages']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-languages">{{ 'academy.Languages'|translate }}</a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/change-password']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-change-password">{{ 'account.Change_password'|translate }}</a>
      </li>
      <li class="navigation-submenu">
        <a [routerLink]="['/account/notifications-settings']" [routerLinkActive]="['active']" (click)="closeAllMenu()"
           class="navigation-link" id="link-notifications">{{ 'account.Email_notifications'|translate }}</a>
      </li>
      <li class="navigation-submenu">
        <a (click)="openDeleteConfirmation()"
           class="navigation-link" style="color:red;" >{{ 'account.Delete_your_account'|translate }}</a>
      </li>
    </div>
    <li class="navigation-item">
      <i class="fal fa-user more_picture"></i>
      <a class="navigation-link" (click)="closeAllMenu()" [routerLinkActive]="['active']" [routerLink]="user.getProfileUrl()">
        {{ 'account.Your_public_profile'|translate }}
      </a>
      <i class="fal fa-angle-right"></i>
    </li>
    <li class="navigation-item">
      <i class="fal fa-sign-out more_picture"></i>
      <a class="navigation-link d-none d-md-block" (click)="logout()">{{ 'account.Logout_button'|translate }}</a>
      <a class="navigation-link d-md-none d-lg-none" (click)="logout()">{{ 'account.Logout_button'|translate }}</a>
      <i class="fal fa-angle-right"></i>
    </li>
  </div>

  <ng-container *ngIf="memberShip == 'free'">
    <ng-container *ngIf="isCapacitorService.isPaymentAvailable()">
      <a class="menu-item" (click)="closeAllMenu()" routerLink="/account/current-plan">
        <i class="fal fa-lock-open more_picture"></i>
        <span>{{ 'layout.Subscribe'|translate }}</span>
        <p class="arrow"><i class="fal fa-angle-right"></i></p>
      </a>
    </ng-container>
  </ng-container>

  <div class="mobile-version">
    <span style="padding-right: 2.5%">v.{{environment.version!}}</span>
  </div>
</div>

<div class="search-results" *ngIf="searchOpened">
  <div class="results-list" *ngIf="results > 0">

    <div class="results-category" *ngIf="topics.length > 0" id="topics">
      <h2>{{ 'academy.Topics'|translate }}</h2>
      <ul>
        <li *ngFor="let topic of topics" (click)="openUrl('topics', topic)" [innerHTML]="getTitlesTranslated(topic._highlightResult.topicsTitles)"></li>
      </ul>
    </div>

    <div class="results-category" *ngIf="pages.length > 0" id="pages">
      <h2>{{ 'academy.Pages'|translate }}</h2>
      <ul>
        <li *ngFor="let page of pages" (click)="openUrl('pages', page)" [innerHTML]="page._highlightResult.name.value"></li>
      </ul>
    </div>

    <div class="results-category" *ngIf="users.length > 0" id="users">
      <h2>{{ 'home.Members'|translate }}</h2>
      <ul>
        <li *ngFor="let user of users" (click)="openUrl('users', user)" [innerHTML]="user._highlightResult.fullname.value"></li>
      </ul>
    </div>

    <div class="results-category" *ngIf="courses.length > 0" id="courses">
      <h2>{{ 'pluralize.Courses'|translate }}</h2>
      <ul>
        <li *ngFor="let course of courses" (click)="openUrl('courses', course)" [innerHTML]="getTitlesTranslated(course._highlightResult.courseTitles)"></li>
      </ul>
    </div>
      <div class="results-category" *ngIf="articles.length > 0" id="articles">
      <h2>{{ 'academy.Lectures'|translate }}</h2>
      <ul>
        <li  *ngFor="let article of articles" (click)="openUrl('articles', article)">
          <span [innerHTML]="article._highlightResult.articleTitle.value"></span> - <span style="font-size: 10px">{{displayAuthors(article)}}</span>
        </li>
      </ul>
    </div>
    <div class="results-category" *ngIf="medias.length > 0" id="medias">
      <h2>{{ 'academy.Lectures'|translate }}</h2>
      <ul>
        <li *ngFor="let media of medias" (click)="openUrl('medias', media)" [innerHTML]="getTitlesTranslated(media._highlightResult.mediaTitles)"></li>
      </ul>
    </div>

  </div>
</div>


<footer class="footer-menu" [class.footer_aop]="environment.domain == 'ophthalmology'">
  <a class="menu-element" [routerLink]="'/library/courses'" [class.active]="isActiveTab('library')" (click)="closeAllMenu()">
    <i class="fal fa-film menu-picture"></i>
    <p>{{ 'academy.Library'|translate }}</p>
  </a>

  <a class="menu-element" [routerLink]="'/alert'" [class.active]="isActiveTab('alert')" (click)="closeAllMenu()" *ngIf="user && (user.specialty_category == 'physician' || user.specialty_category == 'others')">
    <i class="fal fa-user-md menu-picture"></i>
    <p>{{ 'academy.Alert'|translate }}</p>
  </a>

  <a class="menu-element" [routerLink]="'/webinars'" *ngIf="environment.domain !== 'ophthalmology'" [class.active]="isActiveTab('webinars')" (click)="closeAllMenu()" >
    <i class="fal fa-video menu-picture"></i>
    <p>{{ 'application.Live_webinar'|translate }}</p>
  </a>

  <a class="menu-element" (click)="closeAllMenu()" routerLink="/inbox" *ngIf="user && (user.specialty_category != 'physician' && user.specialty_category != 'others')">
    <i class="fal fa-inbox more_picture"></i>
    <span>{{ 'inbox.Inbox'|translate }}</span>
  </a>


  <div class="menu-element" (click)="toggleMoreMenu()" [class.active]="moreOpened">
    <i class="fal fa-ellipsis-h menu-picture"></i>
    <p>{{ 'layout.More'|translate }}</p>
  </div>
</footer>

<div class="page scientific-board">
  <app-scroll-top></app-scroll-top>
  <app-section-top [title]="'premium.IMCAS_Explore'" [subtitle]="'academy.Discover_the_world'|translate" [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-explore.jpg'"></app-section-top>
  <div class="filters">
    <div class="content">
      <div class="row">
        <div class="filters-themes col-12 col-md-4">
          <select id="theme" #themeFilter (change)="filterByThemeId(themeFilter.value)" name="theme" class="filters-select">
            <option value="" selected>{{'attend.Filter_by_theme'|translate}}</option>
            <option *ngFor="let speciality of specialities_list" [value]="speciality.id">{{ translationService.getTranslatedName(speciality.translations, locale) }}</option>
          </select>
        </div>
        <div class="filters-themes col-12 col-md-4">
          <select id="country" #countryFilter name="country" (change)="filterByCountryId(countryFilter.value)" class="filters-select">
            <option value="" selected>{{'attend.Filter_by_country'|translate}}</option>
            <option *ngFor="let country of countries_list" [value]="country.id">
              {{translationService.getTranslatedName(country.translations, locale)}}
            </option>
          </select>
        </div>
        <div class="filters-selection col-12 col-md-4">
          <div class="form-check text-right pull-right">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="order" name="order" class="custom-control-input" (change)="sortByAlphabeticOrder()">
              <label class="custom-control-label" for="order">{{'attend.Sort_by_alphabetical_order'|translate}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sort-by">
    <div class="content">
      <span>{{ 'academy.sort_by'|translate }}:</span>
      <span class="value" *ngIf="!sort_alphabetic">{{ 'academy.Theme'|translate }}</span>
      <span class="value" *ngIf="sort_alphabetic">{{ 'academy.Alphabetic_order'|translate }}</span>
    </div>
  </div>

  <section class="section user-list">

    <div class="content">
      <div class="alert alert-success alert-dismissable" *ngIf="success">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="success = false">×</button>
        {{'attend.Friend_request_sent'|translate}}
      </div>
      <div class="alert alert-danger alert-dismissable" *ngIf="error">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="error = false">×</button>
        {{error}}
      </div>
      <div *ngFor="let group of grouped">
        <div class="section-header">
          <h3>{{ group.name }}</h3>
        </div>
        <div class="section-content">
          <div class="row">
            <div class="user-box-item col-md-3 col-sm-4 col-6" *ngFor="let doctor of group.doctors">
              <a [href]="['/' + locale + '/academy/profile/' + doctor.user.slug]" class="user-box-link">
                <div  [style.background]="'url(' + doctor.user.picture_url + ')'"
                      [style.background-size]="'cover'" class="rounded-circle"></div>
                <span class="user-box-name">{{doctor.user.title + ' ' + doctor.user.first_name + ' ' + doctor.user.last_name}}</span>
                <span class="user-box-profession" *ngIf="doctor.user.specialty">{{translationService.getTranslatedName(doctor.user.specialty.translations, locale)}}</span>
                <span class="user-box-country" *ngIf="doctor.user.country">{{translationService.getTranslatedName(doctor.user.country.translations, locale)}}</span>
              </a>
              <div class="user-box-wrap">
                <a [routerLink]="['/profile', doctor.user.slug]" class="user-box-round-link user-box-round-link-profile" placement="bottom" [ngbTooltip]="'academy.See_the_profile'|translate"><i class="fa fa-user"></i></a>
                <a [routerLink]="['/inbox/thread/with', doctor.user.slug]" class="user-box-round-link user-box-round-link-message" placement="bottom" [ngbTooltip]="('academy.Contact_X'|translate:{name:doctor.user.fullname})"><i class="fa fa-envelope"></i></a>
                <a *ngIf="doctor.coi" [href]="doctor.coi" class="user-box-round-link user-box-round-link-invite" placement="bottom" [ngbTooltip]="'speaker_account.COI_disclosure'|translate" target="_blank"><i class="fal fa-file-pdf"></i></a>
                <a *ngIf="!doctor.coi" (click)="addToFriend(doctor.user)" class="user-box-round-link user-box-round-link-invite" placement="bottom" [ngbTooltip]="'academy.Add_to_your_netword'|translate"><i class="fa fa-user-plus"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-container>
  <!-- Product simple (for marketplace for example) -->
  <ng-container *ngIf="cart && cart.products && cart.products.length > 0">
    <div class="row product product-simple" *ngFor="let product of cart.products; index as i">
      <div class="col-1 text-center desktop-only">
        <span class="font-weight-bold">1 x </span>
      </div>
      <div class="col-1 product-picture">
        <img [src]="product.congress_product.product.thumb_squared" alt="">
      </div>
      <div class="col-9 product-title">
        <h5>{{ translationService.getTranslatedName(product.congress_product.product.translations, locale)}}</h5>
      </div>
      <div class="col-1 product-total">
        <p class="desktop-only">
          {{ getPrice(product.prices, 'price')}}<span *ngIf="!readOnly" (click)="removeAcademySubscription(product.id)"><i class="fal fa-trash-alt"></i></span>
        </p>
        <span *ngIf="!readOnly" (click)="removeAcademySubscription(product.id)"><i class="fal fa-trash-alt"></i></span>
      </div>
      <div class="product-quantity-mobile col-12">
        <span>{{ 'sponsor.Quantity'|translate }} : 1</span>
        <span class="product-total">{{ getPrice(product.prices, 'price')}}</span>
      </div>
      <hr>
    </div>
  </ng-container>
  <!-- Product subscription (academy for example) -->
  <ng-container *ngIf="cart && cart.academy_subscriptions && cart.academy_subscriptions.length > 0">
    <div class="row product product-academy"
         *ngFor="let academy_subscription of cart.academy_subscriptions; index as i">
      <div class="product-quantity col-1 desktop-only" *ngIf="!academy_subscription.user">
        <span>{{ academy_subscription.quantity }}</span> <br>
        <button *ngIf="academy_subscription.quantity > 1 && !readOnly"
                (click)="updateQuantity(academy_subscription, '-')">-
        </button>
        <button *ngIf="!readOnly" (click)="updateQuantity(academy_subscription, '+')">+</button>
      </div>
      <div class="col-{{ academy_subscription.user ? 7 : 10 }}">
        <h5>{{translationService.getTranslatedAttribute('name',
          academy_subscription.congress_product.product.translations, locale)}} <span *ngIf="giftProduct" style="font-weight: bold">- Gift</span></h5>
      </div>
      <div class="col-4" *ngIf="academy_subscription.user">
        <span>{{ academy_subscription.user.fullname }}</span> <br>
        <span
          *ngIf="academy_subscription.user.country">{{translationService.getTranslatedAttribute('name', academy_subscription.user.country.translations, locale)}}</span>
      </div>
      <div class="col-1 text-right">
        <div class="desktop-only price-del"
             *ngIf="getPrice(academy_subscription.prices, 'price') !== getPrice(academy_subscription.prices, 'price_subscription')">
          <del>{{ getPrice(academy_subscription.prices, 'price_subscription') }}</del>
        </div>
        <div class="desktop-only">
          {{ getPrice(academy_subscription.prices, 'price')}}
        </div>
        <span *ngIf="!readOnly" (click)="removeAcademySubscription(academy_subscription.id)" class="remove-icon"><i
          class="fal fa-trash-alt"></i></span>
      </div>
      <div class="product-quantity-mobile col-12">
        <span class="product-total"
              *ngIf="academy_subscription.user">&nbsp;{{ getPrice(academy_subscription.prices, 'price')}}</span>
        <del class="price-del product-total"
             *ngIf="academy_subscription.user && getPrice(academy_subscription.prices, 'price') !== getPrice(academy_subscription.prices, 'price_subscription')">{{ getPrice(academy_subscription.prices, 'price_subscription') }}</del>
      </div>
    </div>
  </ng-container>
  <h3 *ngIf="cart && (withDeliveryFees && !withDeliveryFeesValidated)">{{ 'cart.Delivery_fees'|translate }} <span
    style="color:red; font-size:12px; font-weight:bold;">Please fill your delivery data</span></h3>
  <h3 *ngIf="cart && (withDeliveryFees && withDeliveryFeesValidated)">{{ 'cart.Delivery_fees'|translate }}
    <span>{{ getCartDeliveryFees() }}</span></h3>
  <h2 *ngIf="cart && cart.total && cart.total.length !== 0">{{ 'cart.Total'|translate }}
    <span>{{ getCartTotal() }}</span></h2>
</ng-container>

import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {User} from '../../../../models/user.model';
import {AuthService} from '../../../../services/auth.service';
import {LocaleService} from '../../../../services/locale.service';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-account-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
  providers: [
    UserService,
    AuthService,
    LocaleService,
  ]
})
export class AccountMembershipComponent implements OnInit {
  user: User;
  years: any;
  locale: string;
  environment: any = environment;
  routeId: string;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public localeService: LocaleService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    this.user = this.authService.getCurrentUser();
    this.userService.getUserMembershipYears(this.user.id).subscribe(r => {
      if (r.length > 0) {
        this.years = r;
      }
    });
    this.route.params.subscribe(params => this.routeId = params['id']);
  }
}

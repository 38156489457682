import {DateMomentService} from 'app/services/date-moment.service';
import {TranslationService} from '../../../services/translation.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CorporateFundingModalComponent} from 'app/components/corporate-funding-modal/corporate-funding-modal.component';
import {RateModalComponent} from 'app/components/rate-modal/rate-modal.component';
import {AcademyMedia} from 'app/models/academy-media.model';
import {MediaService} from 'app/services/media.service';
import {UserService} from 'app/services/user.service';
import {Course} from 'app/models/course.model';
import * as $ from 'jquery';
import {LocaleService} from '../../../services/locale.service';
import {AuthService} from '../../../services/auth.service';
import {CompanyProfileModel} from '../../../models/company-profile.model';
import {ArticleModel} from '../../../models/article.model';
import {map} from 'rxjs/operators';
import {CourseCompletedComponent} from '../../modal/course-completed/course-completed.component';
import {timer} from 'rxjs/index';
import {HistoryService} from "../../../services/mobile-services/history.service";

@Component({
    selector: 'app-lecture-page',
    templateUrl: './lecture.component.html',
    styleUrls: ['./lecture.component.scss'],
    providers: [
        MediaService,
        UserService
    ]
})
export class LecturePageComponent implements OnInit, OnDestroy {
    showSocialItems: any;
    user: any;
    academyMedia: AcademyMedia;
    id: any;
    locale: any;
    mediasCount: any;
    environment: any = environment;
    activeTab: string = 'lectures';
    slug: string;
    loadingCourse: boolean;
    loadingAcademyMedia: boolean;
    shortVersionMessage: boolean;
    buffering: boolean;
    company_profile: CompanyProfileModel;
    articles: ArticleModel[];
    mediasRelated: AcademyMedia[];
    fixed: boolean = false;
    completedCourseAndLastVideo: boolean = false;
    language: any;
    pctViewed: any;
    userIsSpeaker: boolean = false;
    favorited: boolean = false;
    showFavorited: boolean = false;
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public translationService: TranslationService,
                private modalService: NgbModal,
                private userService: UserService,
                private authService: AuthService,
                private localeService: LocaleService,
                private mediaService: MediaService,
                public dateMomentService: DateMomentService,
                public historyService: HistoryService) {


    }

    ngOnDestroy() {
        this.loadingAcademyMedia = true;
    }

    ngOnInit() {
        this.loadingCourse = true;
        this.shortVersionMessage = false;
        this.loadingAcademyMedia = true;
        this.locale = this.localeService.currentLocale();
        this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.slug = params['slug'];
            this.getAcademyMedia();
            this.getUser();
        });


    }

    getUser() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id).subscribe(
                user => {
                    this.user = Object.assign(this.user, user);
                });
        }
    }

    getAcademyMedia() {
        this.loadingAcademyMedia = true;
        this.academyMedia = new AcademyMedia();
        const sliderSlick = $('.academy-slider');
        if (sliderSlick.hasClass('slick-initialized')) {
            sliderSlick.slick('unslick');
        }
        this.mediasRelated = [];
        this.mediaService.getAcademyMedia(this.id, {
            user_country: 1,
            user_specialty: 1,
            user_media_ids: 0,
            company_profiles: 1,
            academy_media_comments: 1,
            company_profile_articles: 1,
            academy_media_keywords: 1,
            academy_media_related: 1
        }).subscribe(
            academyMedia => {
                let mediaIds = [];
                this.loadingAcademyMedia = false;
                this.academyMedia = new AcademyMedia(academyMedia);
                this.favorited = this.academyMedia.user_auth
                  && this.academyMedia.user_auth.length > 0
                  && this.academyMedia.user_auth[0].pivot.favorite;
              if (this.user.language) {
                    const languages = this.academyMedia.media.languages;
                    const indexLang = languages.indexOf(this.user.language);
                    if (indexLang !== -1) {
                        this.language = languages[indexLang];
                    } else {
                        this.language = this.academyMedia.media.languages[0];
                    }
                } else {
                    this.language = this.academyMedia.media.languages[0];
                }
                if (this.academyMedia.academy_course) {
                    this.academyMedia.academy_course = new Course(this.academyMedia.academy_course);
                    this.pctViewed = this.user ? this.academyMedia.academy_course.getPctViewed(this.user) : 0;

                    this.fixed = false;
                    const course = this.academyMedia.academy_course;
                    if (course) {
                        mediaIds = course.academy_media_ids;
                    }
                }
                if (this.academyMedia.sponsorOrGrant) {
                    this.company_profile = this.academyMedia.sponsorOrGrant.getCompanyProfile();
                    if (this.company_profile) {
                        this.articles = this.company_profile.getArticles(3);
                    }
                }
                let $related;
                if (academyMedia?.related && academyMedia.related.length > 0) {
                    const ids = academyMedia.related.join(',');
                    if (ids) {
                        $related = this.mediaService.getAcademyMedias({
                            ids: ids,
                            academy_media_course: 0,
                            media_lecture: 0,
                            media_cdn: 0,
                            academy_media_event: 0
                        }).pipe(map(related => {
                            return related['data'];
                        })).subscribe(
                            related => {
                                Object.keys(related).map((objectKey, index) => {
                                    this.mediasRelated.push(new AcademyMedia(related[objectKey]));
                                });
                                setTimeout(function () {
                                    $('.academy-slider').not('.slick-initialized').slick({
                                        infinite: true,
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                        autoplay: true,
                                        dots: true,
                                        prevArrow: '',
                                        nextArrow: '',
                                    });
                                }, 500);
                            }
                        );
                    }
                }

                if (mediaIds && mediaIds.length === 0) {
                    this.activeTab = 'comments';
                }
                if (academyMedia.academy_course && mediaIds && mediaIds.length >= 0) {
                    if ($related) {
                        $related.unsubscribe();
                    }
                    const ids = mediaIds.join(',');
                    if (ids) {
                        this.mediaService.getAcademyMedias({
                            ids: ids,
                            all: 1,
                            user_country: 1,
                            user_specialty: 1,
                            user_media_ids: 0,
                            media_cdn: 0,
                            academy_media_course: 0,
                            media_lecture: 0,
                            order_by: 'rank'
                        }).subscribe(
                            academyMedias => {
                                this.loadingCourse = false;
                                if (this.academyMedia.academy_course) {
                                    const academy_medias = [];
                                    Object.keys(academyMedias).map((objectKey, index) => {
                                        academy_medias.push(new AcademyMedia(academyMedias[objectKey]));
                                    });
                                    this.academyMedia.academy_course.academy_medias = academy_medias;
                                }

                            }
                        );
                    }
                }

              if (academyMedia.media && academyMedia.media.speakers) {
                  this.checkIsAuthor(academyMedia.media.speakers)
              }
            }, err => {
                this.loadingAcademyMedia = false;
                if (err.status === 404) {
                    this.router.navigateByUrl('/not-found')
                }
            });
    }

    openCorporateFundingModal() {
        const modalContent = CorporateFundingModalComponent;
        const options: NgbModalOptions = {
            size: 'lg'
        };
        const modalRef = this.modalService.open(modalContent, options);
        modalRef.componentInstance.academyMedia = this.academyMedia;
    }

    openRateModal(event) {
        if (this.user && this.user.id) {
            if (!this.academyMedia.free && !this.academyMedia.full_video) {
                this.shortVersionMessage = true;
                this.mediaService.getAcademyMediasCount().subscribe((r) => {
                    this.mediasCount = r.physician;
                });
            } else if (this.academyMedia.full_video && !this.completedCourseAndLastVideo) {
                const modalRef = this.modalService.open(RateModalComponent);
                modalRef.componentInstance.title = 'academy.Thank_you_for_watching';
                modalRef.componentInstance.subtitle = 'academy.Please_provide_feedback';
                modalRef.result.then((result) => {
                    if (result.comment) {
                        this.mediaService.addMediaComment(this.academyMedia.id, result.comment).subscribe();
                    }
                    if (result.rate) {
                        this.mediaService.addMediaRate(this.academyMedia.id, result.rate).subscribe();
                    }
                }, error => {

                });
            } else if (this.completedCourseAndLastVideo) {
                const modalRef = this.modalService.open(CourseCompletedComponent);
                modalRef.componentInstance.showSocialItems = this.showSocialItems;
                modalRef.componentInstance.academyMedia = this.academyMedia;
                modalRef.componentInstance.user = this.user;
                modalRef.componentInstance.locale = this.locale;
                modalRef.componentInstance.environment = environment;
                modalRef.componentInstance.slug = this.slug;
                modalRef.componentInstance.title = this.translationService
                  .getTranslatedTitle(this.academyMedia.getMediaTitles(), this.locale);
                modalRef.result.then((result) => {
                  if (result) {
                    this.router.navigateByUrl('/course/' + this.academyMedia.academy_course.id + '/'
                      + this.academyMedia.academy_course.getSlug() + '?modal=certificate');

                  }
              });
            }
        }
    }

    rateMedia(rate) {
        if (this.academyMedia.full_video && this.user && this.user.id) {
            this.mediaService.addMediaRate(this.academyMedia.id, rate).subscribe();
        } else if (this.academyMedia.full_video) {
            this.goToLogin();
        }
    }

    storeProgress(position) {
        if (this.user && this.user.id && this.academyMedia) {
            this.mediaService.addMediaProgress(this.academyMedia.id, position).subscribe(data => {
                if (data) {
                    const academyCourse = data.academy_course;
                    if (academyCourse && academyCourse.percentage_viewed === 100 && academyCourse.mcq) {
                        this.completedCourseAndLastVideo = true;
                    } else if (academyCourse && academyCourse.percentage_viewed === 100 && !academyCourse.mcq) {
                        this.completedCourseAndLastVideo = true;
                    }
                }
            });
        }
    }

    switchIcon(buffering) {
        this.buffering = buffering;
    }

    changeLanguage(language) {
        this.language = language;
    }

    audioChanged(event) {
        setTimeout(() => {
            this.language = event;
        });
    }

    storeTruelyViewed(event) {
        if (this.user && this.user.id) {
            this.mediaService.addMediaTrueView(this.academyMedia.id,
                this.academyMedia.academy_course ?
                    this.academyMedia.academy_course.id :
                    null).subscribe();
        }
    }

    goToVideo(mItem) {
        if (mItem.id !== this.academyMedia.id) {
            this.loadingAcademyMedia = true;
        }

        this.mediaService.goToVideo(mItem);
        return;
    }

    open(content) {
        this.modalService.open(content);
    }

    addMediaToFavorite(id) {
        if (this.user && this.user.id) {
            this.mediaService.addMediaToFavorite(id).subscribe(
              (favorited)=> {
                this.favorited = favorited.favorited
                this.showFavorited = true
                const timerFavorited = timer(3000).subscribe(()=>{
                  this.showFavorited = false
                  timerFavorited.unsubscribe()
                })
              }
            );
        } else {
            this.goToLogin();
        }
    }

    goToLogin() {
        this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url}})
    }

    checkIsAuthor(authorsList) {
      const userId = this.authService.getCurrentUser().id
      this.userIsSpeaker = authorsList.findIndex(author => author.id = userId) === -1 ? false : true
    }
}

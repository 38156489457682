<ng-template #content let-c="close" let-d="dismiss">
  <form
    #rateForm="ngForm"
    (ngSubmit)="c(mediasToRate)"
    class="new-academy-form">
    <div class="modal-header">
      <h4 class="modal-title" id="review-modal-label">To get your certificate of completion, please review each
        lecture.</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body lecture-rate">
      <div class="lecture-rate-item row" *ngFor="let media of mediasToRate">
        <div class="lecture-rate-thumb col-md-4">
          <img [src]="media.media.thumb_squared"
               alt="">
        </div>
        <div class="lecture-rate-content col-md-8">
          <h4>{{media['media'] ? translationService.getTranslatedAttribute('title',
            media['media']['translations'], locale) : ''}}</h4>
          <div class="lecture-rate-text">
            <label>How did you find this lecture?</label>
            <textarea [(ngModel)]="media.media['comment']" required [name]="'comments[' + media.media['id'] + ']'"
                      title=""></textarea>
          </div>
          <div class="lecture-rate-stars">
            <ngb-rating [(rate)]="media.media['currentRate']" max="5">
              <ng-template let-fill="fill">
                <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                <i *ngIf="fill < 100 && fill > 0" class="fa fa-star-half-alt"></i>
              </ng-template>
            </ngb-rating>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="button button-blue" (click)="d('Close click')">{{'webinar.Close'|translate}}
      </button>
      <button type="submit" class="button button-orange"
              [disabled]="!rateForm.form.valid">{{ 'certificate.Save_download'|translate }}
      </button>
    </div>
  </form>
</ng-template>
<ng-template #surveyModal let-c="close" let-d="dismiss">
  <form
    #surveyForm="ngForm"
    (ngSubmit)="checkAnswers()"
    class="new-academy-form" *ngIf="!certificateValidated">
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="course.cme_accredited">{{ 'certificate.Modal_title_cme'|translate }}</h4>
      <h4 class="modal-title" *ngIf="!course.cme_accredited">{{ 'certificate.Modal_title_not_cme'|translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body lecture-rate">
      <div *ngFor="let survey of course.surveys">
        <h4>{{ translationService.getTranslatedAttribute('title', survey.translations, locale) }}</h4>
        <div class="custom-control custom-checkbox" *ngFor="let answer of survey.answers">
          <input type="checkbox" class="custom-control-input" [value]="answer.id" [id]="answer.id"
                 [checked]="!!answers[answer.id]" (change)="changeAnswer(answer.id)">
          <label class="custom-control-label" [for]="answer.id">{{
            translationService.getTranslatedAttribute('name', answer.translations, locale) }}</label>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="errorSurvey">
        {{ errorSurvey }}
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="button button-orange"
              [disabled]="!surveyForm.form.valid">{{ 'academy.Launching_register_submit_button'|translate }}</button>
    </div>
  </form>
  <div class="modal-header" *ngIf="certificateValidated">
    <h4 class="modal-title" *ngIf="course.cme_accredited">{{ 'certificate.Modal_title_validated_cme'|translate }}</h4>
    <h4 class="modal-title"
        *ngIf="!course.cme_accredited">{{ 'certificate.Modal_title_validated_not_cme'|translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body" *ngIf="certificateValidated">
    <div class="alert alert-success" *ngIf="course.cme_accredited">
      {{ 'certificate.Modal_body_cme'|translate }}
    </div>
    <div class="alert alert-success" *ngIf="!course.cme_accredited">
      {{ 'certificate.Modal_body_not_cme'|translate }}
    </div>

    <a [href]="course.certificate_url ? '/' + course.certificate_url : '/academy/courses/certificate/' + course.id "
       class="button button-orange" target="_blank">{{ 'certificate.Download_btn'|translate }}</a>
  </div>
</ng-template>
<div class="page course">
  <section class="section course-top page-top"
           [ngStyle]="{'background-image': 'url(' + course?.header_url + ')'}">
    <div class="course-top-mask"></div>
    <div class="content">
      <a (click)="goBack()" id="back-span"><span class="back-span"><i
        class="fa fa-chevron-left"></i> {{ 'academy.Back_to_courses'|translate }}</span></a>
      <span *ngIf="course && course.cme_accredited"
            class="cme-title">{{ 'academy.CME_accredited_course'|translate }}</span>
      <h1 class="d-none d-lg-block d-xl-block" *ngIf="course">{{translationService.getTranslatedAttribute('title',
        course.translations, locale)}}</h1>
      <div class="course-top-rating">
        <ngb-rating *ngIf="course" [(rate)]="course.rating" max="5" [readonly]="true">
          <ng-template let-fill="fill">
            <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
            <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
            <i *ngIf="fill < 100 && fill > 0" class="fa fa-star-half-alt"></i>
          </ng-template>
        </ngb-rating>
      </div>
      <div class="course-top-published d-none d-lg-block d-xl-block">
        <p *ngIf="course && course.publication_date">{{'webinar.Published_on'|translate}}
          {{dateMomentService.getPublicationDate(course.publication_date)}}</p>
      </div>
      <div class="course-top-info d-none d-lg-block d-xl-block" *ngIf="course && !course.cme_accredited">
        <div class="course-top-duration" placement="top" [ngbTooltip]="'academy.Course_duration'|translate">
          <i class="far fa-clock"></i>
          <p *ngIf="course && course.duration">{{course.duration}}</p>
        </div>
        <div class="course-top-duration">

          <a class="" *ngIf="course && course.webinar_session_id"
             (click)="downloadPDF(course.webinar_session_id)">
            <i class="fa fa-download"></i>
            <p>{{ ('academy.PDF_program'|translate) }}</p>
          </a>
        </div>
        <div class="course-top-theme" placement="top" [ngbTooltip]="'academy.Course_theme'|translate">
					<span class="icon-theme"
                [ngClass]="theme && theme.id ? 'icon-theme icon-theme_' + theme.id : ''"></span>
          <p *ngIf="theme">{{translationService.getTranslatedAttribute('name',
            theme.translations, locale)}}</p>
        </div>
        <div class="course-top-completed" placement="top"
             [ngbTooltip]="'academy.Members_that_completed_the_course'|translate"
             *ngIf="course && course.total_completed >= 10">
          <i class="fa fa-user-md"></i>
          <p *ngIf="course">{{course.total_completed}}</p>
        </div>
        <div class="course-top-certificate">
          <a class="button-orange button"
             *ngIf="course && course.mcq === true && course.percentage_viewed === 100"
             (click)="openModalCertificate()">
            {{'account.Certificate'|translate}}
          </a>
          <a class="button-orange button"
             *ngIf="course && course.with_certificate && course.mcq === false && course.percentage_viewed === 100"
             [href]="environment.base_url + '/academy/course/'+course.id+'/certificate/'+userToken"
             target="_blank">
            {{'account.Certificate'|translate}}
          </a>
          <a class="button-disabled button"
             *ngIf="course && course.with_certificate && course.mcq === false && course.percentage_viewed !== 100"
             ngbTooltip="{{'academy.Complete_all_videos'|translate}}">
            {{'account.Certificate'|translate}}
          </a>
        </div>
      </div>
      <div class="course-top-duration-mobile d-lg-none d-xl-none" *ngIf="course">
        <p>
          <i class="far fa-clock"></i> {{course.duration}}
        </p>
      </div>
    </div>
    <div class="course-top-progress" *ngIf="course && course.percentage_viewed > 0">
      <div class="course-top-progress-bar"
           [ngStyle]="{'width': course ? course.percentage_viewed + '%' : 0}">
        <div class="course-top-progress-number">{{course.percentage_viewed}}%</div>
      </div>

    </div>
  </section>
  <div class="course-header-mobile">
    <div class="content">
      <div class="course-header-mobile d-lg-none d-xl-none" *ngIf="course">
        <span *ngIf="course.cme_accredited">{{ 'academy.CME_accredited_course'|translate }}</span>
        <h1>{{translationService.getTranslatedAttribute('title',
          course.translations, locale)}}</h1>
        <p *ngIf="course && course.publication_date">{{'webinar.Published_on'|translate}}
          {{dateMomentService.getPublicationDate(course.publication_date)}}</p>
      </div>
    </div>
  </div>
  <div class="course-cme" *ngIf="course && course.cme_accredited">
    <div class="content">
      <div>
        <i class="fas fa-graduation-cap"></i>
        <span class="desktop"> CME:</span>
        <span> {{(course.nb_cme_credit < 1 ? 'academy.No_credit' : (course.nb_cme_credit == 1 ? 'academy.Credit' : 'academy.Credits')) |translate: {nb: course.nb_cme_credit} }}</span>
      </div>
      <div>
        <i class="far fa-clock"></i>
        <span class="desktop"> Duration:</span>
        <span *ngIf="theme"> {{course.duration}}</span>
      </div>
      <div>
                <span class="icon-theme"
                      [ngClass]="theme && theme.id ? 'icon-theme icon-theme_' + theme.id : ''"></span>
        <span class="desktop"> {{ 'academy.Theme'|translate }}:</span>
        <span *ngIf="theme"> {{ translationService.getTranslatedAttribute('name', theme.translations, locale) }}</span>
      </div>
    </div>
  </div>
  <div class="course-page">
    <div class="content">
      <div class="course-educational-objectives"
           *ngIf="course && course.educational_objectives && course.cme_accredited">
        <h3>{{ 'academy.Educational_objectives'|translate }}</h3>
        <p [style.max-height]="!showMoreEducationalObjectives ? '65px' : 'inherit'">
          {{ course.educational_objectives }}
          <span (click)="showMoreEducationalObjectives = true"
                *ngIf="!showMoreEducationalObjectives && course.educational_objectives.length > 400">{{ 'blog.Read_more'|translate }}</span>
        </p>
      </div>
      <div class="course-summary" *ngIf="course && course.summary && course.cme_accredited">
        <h3>{{ 'academy.Summary'|translate }}</h3>
        <p [style.height]="!showMoreSummary ? '65px' : 'inherit'">
          {{ course.summary }}
          <br>
          <b>Disclosure</b>: This course is compliant with all stipulated legal, ethic, confidentiality and
          copyright legislations. For any information regarding these subjects, please contact the IMCAS
          Academy team at:
          <a href="mailto:support@imcas.com">support@imcas.com</a>
          <span (click)="showMoreSummary = true"
                *ngIf="!showMoreSummary && course.summary.length > 130">{{ 'blog.Read_more'|translate }}</span>
        </p>
      </div>
      <div class="course-target" *ngIf="course && course.target_audience && course.cme_accredited">
        <h3>{{ 'academy.Target_audience'|translate }}</h3>
        <p>
          {{ course.target_audience }}
        </p>
      </div>

      <div class="course-target" *ngIf="course && course.cme_accredited">
        <h3>{{ 'academy.Process'|translate }}</h3>
        <p>
          1. {{ 'academy.Course_process_step1'|translate }}
          <br/>2. {{ 'academy.Course_process_step2'|translate }}
          <br/>3. {{ 'academy.Course_process_step3'|translate }}
        </p>
      </div>

      <div class="course-target" *ngIf="course && course.module && course.cme_accredited">
        <h3>{{ 'academy.Module'|translate }}</h3>
        <p>
          {{ course.module }}
        </p>
      </div>
      <div class="course-target certificate" *ngIf="course && course.cme_accredited">
        <a *ngIf="course.percentage_viewed != 100" class="button-orange button disabled" href="javascript:void(0)"
           placement="top" [ngbTooltip]="'Watch all the videos to get your certificate'">
          {{'account.Certificate'|translate}}
        </a>
        <a *ngIf="course.percentage_viewed == 100" class="button-orange button" href="javascript:void(0)"
           (click)="openModalCertificate()">
          {{'account.Certificate'|translate}}
        </a>
      </div>
      <div class="course-marketplace" *ngIf="course && course.paid_course && !course.paid_by_auth">
        <p>{{ 'marketplace.Paid_course'|translate }}</p>
        <a [href]="course.marketplace_url" *ngIf="course.marketplace_url"
           class="button button-orange">{{ 'marketplace.Marketplace'|translate }}</a>
      </div>
      <div class="course-header">
        <h2 *ngIf="course && course.academy_medias"
            [innerHTML]="'academy.Course_lecture_nb'|translate:{nb: course.academy_medias_count}"></h2>
      </div>

      <app-loader *ngIf="loadingAcademyMedia"></app-loader>

      <div class="lecture" *ngIf="course && !loadingAcademyMedia">
        <div class="lecture-item row"
             *ngFor="let mItem of course.academy_medias">
          <div class="col-6 col-md-5">
            <div class="lecture-image" (click)="mediaService.goToVideo(mItem)">
              <div class="lecture-badges">
                <span [class]="getTag(mItem).class" *ngIf="getTag(mItem)">{{getTag(mItem).label}}</span>
              </div>
              <div class="lecture-play">
                <i *ngIf="mItem.user_auth && mItem.user_auth[0] && mItem.user_auth[0].pivot.viewed===1; else playIcon"
                   class="fa fa-check"></i>
                <ng-template #playIcon><i class="far fa-play-circle"></i></ng-template>
              </div>
              <div class="lecture-languages" *ngIf="mItem.media.languages.length > 0">
                <img *ngFor="let language of mItem.media.languages"
                     [src]="environment.cdn_base_url + '/assets-front/img/flags/' +  language + '.svg'"
                     class="flag"
                     alt="">
              </div>
              <div class="lecture-duration">
                <span>{{mItem.media.duration}}</span>
              </div>
              <img [src]="mItem.media.thumb">
              <div class="lecture-mask"></div>
            </div>
          </div>
          <div class="col-6 col-md-7">
            <div class="lecture-content" *ngIf="mItem.media">
              <h3 (click)="mediaService.goToVideo(mItem)">
                {{translationService.getTranslatedAttribute('title', mItem.media.translations, locale)}}
              </h3>
              <div class="lecture-author media">
                <ng-container *ngFor="let user of mItem.media.speakers">
                  <div [style.background]="'url(' + user.picture_url + ')'" [style.background-size]="'cover'"
                       [routerLink]="'/profile/' + user.slug"
                       *ngIf="user.picture_url" class="rounded-circle"></div>
                  <div class="media-body" *ngIf="mItem.media.speakers.length === 1">
                    <h6 [routerLink]="'/profile/' + user.slug">{{user.title + ' ' + user.first_name
                    + ' ' + user.last_name}}</h6>
                    <span>
										{{(user.specialty ? translationService.getTranslatedName(user.specialty.translations, locale) : '') +
                    (user.specialty && user.country ? ', ' : '') +
                    (user.country ? translationService.getTranslatedName(user.country.translations, locale) : '')}}
									</span>
                  </div>
                </ng-container>
              </div>
              <div class="lecture-published">
                <p *ngIf="mItem['publication_date']">
                  {{'webinar.Published_on'|translate}}
                  {{dateMomentService.getPublicationDate(mItem.publication_date)}}
                  {{mItem.event ? ' - ' + ('academy.Recorded_during'|translate) + ' ' +
                  mItem.event.title : ''}}
                </p>
                <a (click)="openAbstract(abstract)"
                   *ngIf="mItem.media?.lecture?.abstract_status == 'abstract accepted'">{{
                  'academy.View_Abstract' | translate}}</a>
                <ng-template #abstract let-c="close" let-d="dismiss">
                  <app-abstract *ngIf="mItem.media" [media]="mItem.media" [d]="d"></app-abstract>
                </ng-template>
              </div>
              <div class="lecture-comments" *ngIf="mItem.comments_count > 0 || mItem.views > 100">
                <span>{{mItem.comments_count === 1 ? '1 ' + ('pluralize.Comment'|translate) : mItem.comments_count > 1 ? mItem.comments_count + ' ' + ('pluralize.Comments'|translate) : ''}}{{mItem.views > 100 && mItem.comments_count > 0 ? ' - ' : ''}}{{ mItem.views > 100 ? mItem.views + ' ' + ('pluralize.Views'|translate) : '' }}</span>
              </div>
              <div class="lecture-rating">
                <ngb-rating [(rate)]="mItem.rating" max="5" [readonly]="true">
                  <ng-template let-fill="fill">
                    <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                    <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                    <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
                  </ng-template>
                </ngb-rating>
                <p *ngIf="mItem.rating == 0"> No reviews yet</p>
              </div>
              <div class="lecture-sponsored" *ngIf="mItem.sponsorOrGrant.id">
                <p>
                  {{mItem.granted ? ('academy.Granted_by'|translate) :
                  ('academy.Sponsored_by'|translate)}}
                  <span>
									{{mItem.sponsorOrGrant.company_name}}
									</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="course-social" *ngIf="course">
          <app-social-list
            [showSocialItems]="showSocialItems"
            (addToFavorite)="addCourseToFavorite($event)"
            (openCertificate)="course.mcq === true ? openModalCertificate() : downloadCertificate()"
            [element]="course"
            [favoriteItem]="true"
            [favorite]="favorited"
            [certificateItem]="course && course.with_certificate && course.percentage_viewed === 100"
            [facebookHref]="'https://www.facebook.com/sharer/sharer.php?u='+environment.base_url+'%2F'+locale+'%2Facademy%2Fcourse%2F'+course.id+'%2F'+course.slug"
            [linkedinHref]="'https://www.linkedin.com/shareArticle?mini=true&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fcourse%2F'+course.id+'%2F'+course.slug+'&amp;title='+('academy.Share_mail_subject_course'|translate)"
            [twitterHref]="'https://twitter.com/intent/tweet?text='+('academy.Share_mail_subject_course'|translate)+'&amp;url='+environment.base_url+'%2F'+locale+'%2Facademy%2Fcourse%2F'+course.id+'%2F'+course.slug+'&amp;hashtags=IMCASAcademy&amp;via=imcascongress&amp;related=imcascongress'"
            [mailHref]="'mailto:?subject='+('academy.Share_mail_subject_course'|translate)+'&amp;body='+('academy.Share_mail_line1'|translate)+'%0A%0A'+('academy.Share_mail_line2_course'|translate)+'%0A'+environment.base_url+'/'+locale+'/academy/course/'+course.id+'/'+course.slug+'%0A%0A'+('academy.Share_mail_subject_course'|translate)+'%0A%0A'+(user ? user.first_name + ' ' + user.last_name : '')"
          ></app-social-list>
          <ngb-alert *ngIf="showFavorited"
                     type="success">{{favorited ? ('account.Favorites_courses_added'|translate) : ('account.Favorites_courses_removed'|translate)}}</ngb-alert>
        </div>
        <div class="lecture-sponsore row" *ngIf="course">
          <div
            *ngIf="!course.sponsorAndGrant && course.scientific_society1 || course.scientific_society2 || course.scientific_society3 || course.scientific_society4 || course.publication"
            [ngClass]="course.sponsorOrGrant ? 'col-6' : 'col-12'">
            <h2>{{'academy.Course_page_partner_title'|translate}}</h2>
            <a [href]="course.scientific_society1.website" target="_blank"
               *ngIf="course.scientific_society1">
              <img [src]="course.scientific_society1.logo_url" [alt]="course.scientific_society1.name">
            </a>
            <a [href]="course.scientific_society2.website" target="_blank"
               *ngIf="course.scientific_society2">
              <img [src]="course.scientific_society2.logo_url" [alt]="course.scientific_society2.name">
            </a>
            <a [href]="course.scientific_society3.website" target="_blank"
               *ngIf="course.scientific_society3">
              <img [src]="course.scientific_society3.logo_url" [alt]="course.scientific_society3.name">
            </a>
            <a [href]="course.scientific_society4.website" target="_blank"
               *ngIf="course.scientific_society4">
              <img [src]="course.scientific_society4.logo_url" [alt]="course.scientific_society4.name">
            </a>
            <a [href]="course.publication.website" target="_blank" *ngIf="course.publication">
              <img [src]="course.publication.logo_url" [alt]="course.publication.name">
            </a>
          </div>
          <div *ngIf="!course.sponsorAndGrant && course.sponsorOrGrant && course.display_granted"
               [ngClass]="course.scientific_society1 || course.scientific_society2 || course.scientific_society3 || course.publication ? 'col-6' : 'col-12'">
            <h2>{{(course.sponsored ? 'academy.Sponsored_by' : 'academy.With_educational_grant')|translate}}</h2>
            <a [href]="course.sponsorOrGrant.social_network.website" target="_blank">
              <img [src]="course.sponsorOrGrant.logo_url" [alt]="course.sponsorOrGrant.name">
            </a>
            <a *ngIf="course.id === 2733" [href]="'https://www.novartis.fr/'" target="_blank">
              <img [src]="'https://uploads.imcas.com/users/236248/logo/HfTsM8TEJsliQSEyvMJNYvWZgVEhkWjKnN2Oe4sV.png'" alt="Logo Novartis" />
            </a>
          </div>
          <div class="col-12" *ngIf="course.sponsorAndGrant">
            <h2>{{'academy.With_the_support_of'|translate}}</h2>
            <div *ngFor="let sponsor of course.sponsorAndGrant">
              <a [href]="sponsor.social_network.website" target="_blank">
                <img [src]="sponsor.logo_url" [alt]="sponsor.name">
              </a>
            </div>
          </div>
          <div class="col-12"
               *ngIf="course.sponsored && course['sponsorOrGrant'] && course['sponsorOrGrant']['company_profiles'] && course['sponsorOrGrant']['company_profiles'].length>0">
            <a target="_blank" href="" class="btn-full blue">{{'academy.Learn_more_about_X'|translate}}
              {{course.sponsorOrGrant.company_profiles[0].display_name}}</a>
          </div>
        </div>
        <div class="course-lecture-related row"
             *ngIf="course['academy_partner_links'] && course['academy_partner_links'].length > 0">
          <div class="external-links-webinar col-12 text-left">
            <h4>{{ "academy.Related_articles"|translate }}</h4>
            <ul>
              <li *ngFor="let partnerLink of course.academy_partner_links">
                <a [href]="partnerLink['link']">{{translationService.getTranslatedAttribute('title',
                  partnerLink['translations'], locale)}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="course-header" *ngIf="sessions?.length > 0">
        <h2>{{'academy.Upcoming_sessions'|translate}}</h2>
      </div>
      <div class="course-upcoming" *ngIf="sessions?.length > 0">
        <ng-container *ngFor="let session of sessions; let i=index;">
          <div class="course-upcoming-congress"
               *ngIf="session['congress'] && (i === 0 || sessions[i].congress_id !== sessions[i-1].congress_id)">
            <h5>
              {{ 'academy.Congress_in_city'|translate:{
              congress: translationService.getTranslatedAttribute('complete_name', session['congress']['translations'], locale),
              city: translationService.getTranslatedAttribute('city', session.congress.venue.translations, locale)
            } }}
            </h5>
            <span>
                            {{dateMomentService.getPublicationDate(session.congress.datetime_start) }}
              - {{dateMomentService.getPublicationDate(session.congress.datetime_end) }}
                        </span>
          </div>
          <div class="course-upcoming-item">
            <a
              [href]="environment.base_url + '/' + locale +  '/attend/' + session.congress?.slug +'/program/session/' + session.id"
              class="title">{{translationService.getTranslatedAttribute('subtitle',
              session['translations'], locale)}}</a>
            <p>{{session.speakers ? ('academy.by'|translate) : ''}} {{ session.getSpeakersString()}}</p>
          </div>
        </ng-container>
      </div>
      <div class="course-header" *ngIf="course && coursesRelated && coursesRelated.length > 0">
        <h2>{{'academy.Related_courses'|translate}}</h2>
      </div>
      <div class="row">
        <div class="course-related academy-slider"
             *ngIf="course && coursesRelated && coursesRelated.length > 0">
          <div *ngFor="let course of coursesRelated">
            <div class="course-item" (click)="goToCourse(course, locale)">
              <app-course-element [course]="course" [user]="user"></app-course-element>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {User} from './user.model';
import {Translation} from 'app/models/translation.model';
import {DateMomentService} from '../services/date-moment.service';
import {TranslationService} from '../services/translation.service';

export class Course {
  pivot: any;
  id: number;
  dateMoment: DateMomentService;
  translationService: TranslationService = new TranslationService();
  objectID: number;
  pctViewed: any;
  enrolled: boolean;
  free: boolean;
  highlighted: boolean;
  certificate_cme: boolean;
  granted: boolean;
  others: string;
  summary: string;
  educational_objectives: string;
  target_audience: string;
  module: string;
  date: number | string;
  sponsored: boolean;
  mcq: boolean;
  courseTitles: any[];
  event: string;
  nbVideos: number;
  speakers: any[];
  sponsor_name: string;
  themes: any[];
  themes_ids: any[];
  cme_accredited: any;
  nb_cme_credit: number;
  rating: number;
  favorite: boolean;
  theme1_id: number;
  academy_medias: any[];
  academy_media_ids: any[];
  academy_medias_count: number;
  academy_media_comments: number;
  translations: Translation[];
  publication_date: string | number;
  total_completed: number;
  scientific_society1: any;
  scientific_society2: any;
  scientific_society3: any;
  scientific_society4: any;
  publication: any;
  display_granted: number;
  sponsorOrGrant: any;
  sponsorAndGrant: any;
  academy_partner_links: any;
  related: any;
  duration: number | string;
  slug: string;
  certificate_url: string;
  picture_url: string;
  percentage_viewed: number;
  board_selection: boolean;
  header_url: boolean;
  certificate_validated: boolean;
  with_certificate: boolean;
  paid_course: boolean;
  marketplace_url: string;
  paid_by_auth: boolean;
  surveys: any[];
  webinar_session_id: number;
  webinar_registrants: string | null;
  webinar_live_viewers: string | null;
  webinar_registrants_specialties: any[];
  webinar_registrants_stats_specialties: any[];
  webinar_live_viewers_specialties: any[];
  webinar_live_viewers_stats_specialties: any[];
  webinar_registrants_areas: any[];
  webinar_registrants_stats_areas: any[];
  webinar_live_viewers_areas: any[];
  webinar_live_viewers_stats_areas: any[];
  recorded_at: string | number;
  replay_soon_available: boolean;
  captation_source: string;

  getPctViewed(user: User): number | boolean {
    if (this.pctViewed === false) {
      if (!user || (!user.courses_in_progress && !user.courses_finished)) {
        this.pctViewed = 0;
      }
      if (user.courses_in_progress) {
        for (let i = 0; i < user.courses_in_progress.length; ++i) {
          if (+user.courses_in_progress[i].id === +this.objectID) {
            this.pctViewed = user.courses_in_progress[i].percentage_viewed;
          }
        }
      }
      if (user.courses_finished) {
        for (let i = 0; i < user.courses_finished.length; ++i) {
          if (+user.courses_finished[i] === +this.objectID) {
            this.pctViewed = 100;
          }
        }
      }
    }
    return this.pctViewed;
  }

  isBoughtByAuth(user: User): boolean {
    if (user && user.courses_bought) {
      for (let i = 0; i < user.courses_bought.length; ++i) {
        if (+user.courses_bought[i] === +this.objectID) {
          return true;
        }
      }
    }
    return false;
  }

  isEnrolled(user: User): boolean {
    if (!user || !user.favorited || !user.favorited.courses) {
      return false;
    }

    for (let i = 0; i < user.favorited.courses.length; ++i) {
      if (+user.favorited.courses[i] === +this.objectID) {
        return true;
      }
      if (user.favorited.courses[i].id === +this.id) {
        return true;
      }
    }
    return false;
  }

  getDate() {
    if (this.date) {
      return this.date;
    } else {
      return this.publication_date;
    }
  }

  getDateFormatted() {
    if (this.date) {
      return this.getDateMoment().formatPublication(this.date);
    } else {
      return this.getDateMoment().getPublicationDate(this.publication_date);
    }
  }

  getDateMoment() {
    if (!this.dateMoment) {
      this.dateMoment = new DateMomentService();
    }
    return this.dateMoment;
  }

  getId() {
    if (this.id) {
      return this.id;
    } else {
      return this.objectID;
    }
  }

  getSlug() {
    if (this.slug) {
      return this.slug;
    } else {
      const englishTitle = this.translationService.getTranslatedTitle(this.courseTitles, 'en');
      return englishTitle.toString().toLowerCase()
        .replace('à', 'a')
        .replace('ç', 'c')
        .replace('é', 'e')
        .replace('è', 'e')
        .replace('ê', 'e')
        .replace('ë', 'e')
        .replace('ö', 'o')
        .replace('ô', 'o')
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
    }
  }

  getPictureUrl() {
    if (this.picture_url) {
      return this.picture_url;
    }
  }

  getMediaCount() {
    if (this.academy_medias_count) {
      return this.academy_medias_count;
    } else {
      return this.nbVideos;
    }
  }

  getSpeakersArr() {
    const speakers = [];
    if (this.speakers) {
      Object.keys(this.speakers).map((objectKey, index) => {
        speakers.push(new User(this.speakers[objectKey]));
      });
    }

    return speakers;
  }

  getSpeakers() {
    if (typeof this.speakers === 'string') {
      return this.speakers;
    } else if (this.speakers) {
      let string = '';
      Object.keys(this.speakers).map((objectKey, index) => {
        const value = this.speakers[objectKey];
        string += value.title + ' ' + value.first_name + ' ' + value.last_name + ', ';
      });
      return string.slice(0, -2);
    }
    return '';
  }

  getSpeakersTitleFormatted() {
    let titles = this.getSpeakers();
    if (titles.length > 125) {
      titles = titles.slice(0, 125) + '...';
    }
    const pos1 = titles.endsWith('&nbsp...');
    const pos2 = titles.endsWith('&nbs...');
    const pos3 = titles.endsWith('&nb...');
    const pos4 = titles.endsWith('&n...');
    if (pos1) {
      titles = titles.slice(0, -8) + '...';
    }
    if (pos2) {
      titles = titles.slice(0, -7) + '...';
    }
    if (pos3) {
      titles = titles.slice(0, -6) + '...';
    }
    if (pos4) {
      titles = titles.slice(0, -5) + '...';
    }
    return titles;

  }

  getThemesIds() {
    if (this.themes_ids) {
      return this.themes_ids;
    }
    if (this.themes) {
      return Object.keys(this.themes['en']);
    }
  }

  constructor(obj?: any) {
    this.id = obj && obj.id;
    this.objectID = obj && obj.objectID;
    this.module = obj && obj.module;
    this.pivot = obj && obj.pivot;
    this.pctViewed = obj && obj.pctViewed ? obj.pctViewed : false;
    this.enrolled = obj && obj.enrolled;
    this.free = obj && obj.free;
    this.highlighted = obj && obj.highlighted;
    this.others = obj && obj.others;
    this.date = obj && obj.date;
    this.surveys = obj && obj.surveys;
    this.sponsored = obj && obj.sponsored;
    this.cme_accredited = obj && obj.cme_accredited;
    this.nb_cme_credit = obj && obj.nb_cme_credit;
    this.paid_course = obj && obj.paid_course;
    this.summary = obj && obj.summary;
    this.educational_objectives = obj && obj.educational_objectives;
    this.target_audience = obj && obj.target_audience;
    this.mcq = obj && obj.mcq;
    this.nbVideos = obj && obj.nbVideos;
    this.courseTitles = obj && obj.courseTitles;
    this.event = obj && obj.event;
    this.speakers = obj && obj.speakers;
    this.sponsor_name = obj && obj.sponsor_name;
    this.certificate_validated = obj && obj.certificate_validated;
    this.themes = obj && obj.themes;
    this.themes_ids = obj && obj.themes_ids;
    this.rating = obj && obj.rating;
    this.favorite = obj && obj.favorite;
    this.theme1_id = obj && obj.theme1_id;
    this.academy_medias = obj && obj.academy_medias;
    this.translations = obj && obj.translations;
    this.publication_date = obj && obj.publication_date;
    this.total_completed = obj && obj.total_completed;
    this.scientific_society1 = obj && obj.scientific_society1;
    this.scientific_society2 = obj && obj.scientific_society2;
    this.scientific_society3 = obj && obj.scientific_society3;
    this.scientific_society4 = obj && obj.scientific_society4;
    this.publication = obj && obj.publication;
    this.display_granted = obj && obj.display_granted;
    this.sponsorOrGrant = obj && obj.sponsorOrGrant;
    this.sponsorAndGrant = obj && obj.sponsorAndGrant;
    this.academy_partner_links = obj && obj.academy_partner_links;
    this.related = obj && obj.related;
    this.duration = obj && obj.duration;
    this.academy_media_ids = obj && obj.academy_media_ids;
    this.slug = obj && obj.slug;
    this.academy_medias_count = obj && obj.academy_medias_count;
    this.academy_media_comments = obj && obj.academy_media_comments;
    this.certificate_url = obj && obj.certificate_url;
    this.certificate_cme = obj && obj.certificate;
    this.with_certificate = obj && obj.with_certificate;
    this.picture_url = obj && obj.picture_url;
    if (obj.thumb && !this.picture_url) {
      this.picture_url = obj.thumb;
    }
    this.percentage_viewed = obj && obj.percentage_viewed;
    this.board_selection = obj && obj.board_selection;
    this.header_url = obj && obj.header_url;
    this.webinar_registrants = obj && obj.webinar_registrants;
    this.webinar_live_viewers = obj && obj.webinar_live_viewers;
    this.webinar_registrants_specialties = obj && obj.webinar_registrants_specialties;
    this.webinar_registrants_stats_specialties = obj && obj.webinar_registrants_stats_specialties;
    this.webinar_live_viewers_specialties = obj && obj.webinar_live_viewers_specialties;
    this.webinar_live_viewers_stats_specialties = obj && obj.webinar_live_viewers_stats_specialties;
    this.webinar_registrants_areas = obj && obj.webinar_registrants_areas;
    this.webinar_registrants_stats_areas = obj && obj.webinar_registrants_stats_areas;
    this.webinar_live_viewers_areas = obj && obj.webinar_live_viewers_areas;
    this.webinar_live_viewers_stats_areas = obj && obj.webinar_live_viewers_stats_areas;
    this.webinar_session_id = obj && obj.webinar_session_id;
    this.marketplace_url = obj && obj.marketplace_url;
    if (this.marketplace_url) {
      this.paid_course = true;
    }
    this.paid_by_auth = obj && obj.paid_by_auth;
    this.captation_source = obj && obj.captation_source;
  }
}

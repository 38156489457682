import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslationService} from 'app/services/translation.service';
import {environment} from '../../../environments/environment';
import {LocaleService} from 'app/services/locale.service';


@Component({
    selector: 'app-rate-modal',
    templateUrl: './rate-modal.component.html',
    styleUrls: ['./rate-modal.component.scss'],
    providers: [
        LocaleService
    ]
})

export class RateModalComponent implements OnInit{
    locale: any;
    title: string;
    subtitle: string;
    userAction: any = {comment: '', rate: 5};
    environment: any = environment;

    @Input()preselection: number

    constructor(public activeModal: NgbActiveModal,
                public translationService: TranslationService,
                public localeService: LocaleService) {
        this.locale = localeService.currentLocale();

    }

    ngOnInit() {
      this.userAction.rate = this.preselection
    }
}

<div id="main-container" >
  <div class="row">
    <div class="col-sm-12" >
      <app-loader *ngIf="showLoading"></app-loader>
      <div *ngFor="let currentPoll of pollData" class="mb-3">
        <div *ngIf="currentPoll && currentPoll.choices && !currentPoll.answers && !showLoading">
          <h6>Question: {{currentPoll.question}}</h6>

          <div *ngFor="let choice of currentPoll.choices">

            <label class="checkmark" [for]="choice + 'Input'" style="margin-left: 10px;">
              <input type="radio" id="disclosure-1-yes" [name]="choice + 'Input'"  [(ngModel)]="currentPoll.response" [value]="choice.id">
              {{choice.content}}
            </label>
          </div>
          <button class="btn btn-info" (click)="answerPoll(currentPoll.id)" [disabled]="currentPoll.response ? false : true">Send response</button>

        </div>
        <div *ngIf="currentPoll && currentPoll.answers">
          <div class="d-flex flex-column align-items-center">
            <h6>Question: {{currentPoll.question}}</h6>
            <!--<google-chart-->
                    <!--#chart-->
                    <!--type="PieChart"-->
                    <!--[data]="currentPoll.answers"-->
                    <!--[options]="chartOptions"-->
            <!--&gt;-->
            <!--</google-chart>-->

            <ol class="poll-answers">
              <li *ngFor="let answer of currentPoll.answers; index as i">

                <div style="display: flex"><span style="width: 75%; float:left">
                 {{answer[0]}}
                </span>
                <span style="width: 25%; text-align: end;">
                 ({{answer[1]}}) {{answer[2]}}%
                </span>
                </div>
                <p><ngb-progressbar type="success" [value]="answer[2]" height=".2rem" class="poll-progress-bar"></ngb-progressbar></p>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CartService} from '../../../services/cart.service';

@Component({
    selector: 'app-form-systempay',
    templateUrl: './form-systempay.component.html',
    styleUrls: ['./form-systempay.component.scss']
})
export class FormSystempayComponent implements OnInit {
    @Input() systempayVads: any;
    @Input() action: any;
    @ViewChild('externalForm', {static: false}) externalForm: ElementRef;

    constructor(private cartService: CartService) {
    }

    ngOnInit() { }

    sendData() {
        this.cartService.deleteCurrentCartId();
        setTimeout(() => {
            this.externalForm.nativeElement.submit()
        }, 0);
    }
}

import {Component, OnInit} from '@angular/core';
import {CongressProduct} from '../../../../models/congress-product.model';
import {CongressProductService} from '../../../../services/congress-product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from '../../../../services/translation.service';
import {LocaleService} from '../../../../services/locale.service';
import 'slick-carousel';
import {CartService} from '../../../../services/cart.service';
import {AuthService} from '../../../../services/auth.service';
import {AcademySubscriptionService} from '../../../../services/academy-subscription.service';
import {User} from '../../../../models/user.model';
import {UserService} from '../../../../services/user.service';
import {ExternalApiService} from '../../../../services/external-api.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-marketplace-free-confirmation',
  templateUrl: './marketplace-free-confirmation.component.html',
  styleUrls: ['./marketplace-free-confirmation.component.scss'],
  providers: [CongressProductService, TranslationService, CartService, AcademySubscriptionService, ExternalApiService]
})
export class MarketplaceFreeConfirmationComponent implements OnInit {
  congressProduct: CongressProduct;
  slug: string;
  currency: string;
  terms_of_use_sentence: string;
  terms_of_use_sentence2: string;
  user: any;
  isLogged: boolean;
  justConnected: boolean;
  locale: string;

  constructor(private congressProductService: CongressProductService,
              private activatedRoute: ActivatedRoute,
              private externalApiService: ExternalApiService,
              public translationService: TranslationService,
              public translateService: TranslateService,
              private localeService: LocaleService,
              private cartService: CartService,
              private router: Router,
              private userService: UserService,
              private authService: AuthService,
              private academySubscriptionService: AcademySubscriptionService,
  ) {
  }

  confirmOrder() {
    this.academySubscriptionService.postMarketplaceFree({
      congress_product_id: this.congressProduct.id
    }).subscribe(result => {
      this.router.navigateByUrl('/marketplace/free-thanks/' + this.congressProduct.slug);
    }, error => {
      alert('Error...');
    });
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe(logged => {
      this.isLogged = logged;
    });
    this.currency = 'usd';
    this.user = this.authService.getCurrentUser();
    if (this.user.id) {
      this.userService.getUser(this.user.id, {user_country: 1}).subscribe(
        userBackEnd => {
          this.user = new User({...this.user, ...userBackEnd});
          if (userBackEnd.country_id === 78) {
            this.currency = 'eur';
          }
        });
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.justConnected = params.connected;
    });
    this.locale = this.localeService.currentLocale();
    this.activatedRoute.params.subscribe(params => {
      this.slug = params['slug'];
      this.congressProductService.getBySlug(this.slug).subscribe(congressProduct => {
        if (congressProduct.id) {
          this.congressProduct = new CongressProduct(congressProduct);
          this.currency = this.congressProduct.getPriceObj(this.currency).currency;

          this.translateService.get('layout.Privacy_Policy').subscribe((privacy: string) => {
            this.translateService.get('layout.Terms_of_use').subscribe((terms: string) => {
              this.translateService.get('marketplace.Free_product_confirmation_confirming_message2', {
                privacy_policy_link: '<a href=\'' + this.congressProduct.product.privacy_policy_url + '\' target=\'_blank\'>' + privacy + '</a>',
                company_name: (this.congressProduct.partner && this.congressProduct.partner.company_name ?
                  this.congressProduct.partner.company_name :
                  'n/a')
              }).subscribe((res: string) => {
                this.terms_of_use_sentence2 = res;
              });
            });
          });


        } else {
          this.router.navigateByUrl('/not-found')
        }
      });
    });


    this.translateService.get('layout.Privacy_Policy').subscribe((privacy: string) => {
      this.translateService.get('layout.Terms_of_use').subscribe((terms: string) => {
        this.translateService.get('marketplace.Free_product_confirmation_confirming_message', {
          privacy_policy_link: '<a href=\'/' + this.locale + '/academy/privacy-policy\' target=\'_blank\'>' + privacy + '</a>',
          terms_of_use_link: '<a href=\'/' + this.locale + '/academy/terms-of-use\' target=\'_blank\'>' + terms + '</a>.'
        }).subscribe((res: string) => {
          this.terms_of_use_sentence = res;
        });
      });
    });


  }
}

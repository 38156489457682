import {Component, Input} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {JwplayerVideoComponent} from '../jwplayer-video/jwplayer-video.component';
import {Media} from '../../models/media.model';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {

  @Input() video: Media;

  constructor(private modalService: NgbModal) {
  }

  open(type) {
    let content;
    if (type === 'video') {
      content = JwplayerVideoComponent;

        const options: NgbModalOptions = {
        size: 'lg'
      };
      const modalRef = this.modalService.open(content, options);
      if (typeof this.video.cdn_file_short !== 'undefined') {
          modalRef.componentInstance.file = this.video.cdn_file_short;
      }
      modalRef.componentInstance.file2 = this.video.cdn_file_short;
      modalRef.componentInstance.mediaId = this.video.id;
      modalRef.componentInstance.autoload = true;
      modalRef.componentInstance.width = '100%';
      modalRef.componentInstance.aspectratio = '16:9';
    }
  }
}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CropperSettings, ImageCropperComponent} from 'ngx-img-cropper';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';


@Component({
    selector: 'app-edit-avatar-modal',
    templateUrl: './edit-avatar-modal.component.html',
    styleUrls: ['./edit-avatar-modal.component.scss']
})
export class EditAvatarModalComponent implements OnInit {
    @Input() profile: any;
    @Input() close: any;
    @Input() dismiss: any;
    public profileImage: File;
    data2: any;
    cropperSettings: CropperSettings;
    @ViewChild('cropper', {static: false}) cropper: ImageCropperComponent;

    constructor(private authService: AuthService,
                private userService: UserService) {
        this.cropperSettings = new CropperSettings();
        this.cropperSettings.width = 200;
        this.cropperSettings.height = 200;
        this.cropperSettings.keepAspect = true;

        this.cropperSettings.croppedWidth = 200;
        this.cropperSettings.croppedHeight = 200;

        this.cropperSettings.canvasWidth = 200;
        this.cropperSettings.canvasHeight = 200;

        this.cropperSettings.minWidth = 200;
        this.cropperSettings.minHeight = 200;

        this.cropperSettings.rounded = true;
        this.cropperSettings.minWithRelativeToResolution = true;
        this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
        this.cropperSettings.cropperDrawSettings.strokeWidth = 3;
        this.cropperSettings.noFileInput = true;

        this.data2 = {};
    }

    ngOnInit() {
    }

    getProfilePicture() {
        if (Object.keys(this.data2).length) {
            return this.data2.image;
        } else {
            if (this.profile) {
                return this.profile.picture_url;
            } else {
                return '';
            }
        }
    }

    onProfileImageFileChange($event) {
        this.profileImage = $event.target.files[0];
        const image: any = new Image();
        const file: File = $event.target.files[0];
        const myReader: FileReader = new FileReader();
        const that = this;
        myReader.onloadend = function (loadEvent: any) {
            image.src = loadEvent.target.result;
            that.cropper.setImage(image);
        };
        myReader.readAsDataURL(file);
    }

    cropProfilePicture() {

    }

    uploadProfileImage() {
        const i = ('' + Math.random() * 1000000).split('.')[0];
        const data = {
            'images[profile][name]': 'profile-picture-' + i,
            'images[profile][content]': this.data2.image,
            'images[profile][format]': this.profileImage ? this.profileImage.type : ''
        };

        this.userService.uploadNewImage(data)
            .subscribe(r => {
                this.profile.picture_url = r.picture_url;
                this.dismiss();
            })
    }
}

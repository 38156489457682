<app-search-ui
		[transparent]="false"
		[usersIndex]="true"
		[topicsIndex]="false"
		[productsIndex]="false"
		[companiesIndex]="false"
		[pagesIndex]="false"
		[congressesIndex]="false"
		[coursesIndex]="false"
		[mediasIndex]="false"
		[usersHitsPerPage]="10"
		(sendConnection)="sendConnection($event)"
		[currentUser]="user"
		[disabled]="loading"
></app-search-ui>
<h1 class="title-margin">{{(currentTab==='pending' ? 'academy.Pending_connections' : 'account.Connections')|translate}}</h1>
<ul class="nav nav-tabs nav-fill">
	<li class="nav-item">
		<a class="nav-link" [ngClass]="{active: currentTab==='pending'}" (click)="currentTab='pending'">
			{{ 'academy.Pending_request'|translate }}
			<span *ngIf="user && user.connections && user.connections.pending && user.connections.pending">
				{{ user.connections.pending.length }}
			</span>
		</a>
	</li>
	<li class="nav-item">
		<a class="nav-link" [ngClass]="{active: currentTab==='connected'}" (click)="currentTab='connected'">
			{{ 'account.Connections'|translate }}
			<span *ngIf="user && user.connections && user.connections.accepted">
				{{user.connections.accepted.length}}
			</span>
		</a>
	</li>
</ul>

<div class="request row loading" *ngIf="loading"><span><i class="fa fa-spinner fa-spin"></i></span></div>

<div class="request row" *ngIf="user && user.connections && currentTab==='pending' && !loading">
	<div class="request-item col-md-4" *ngFor="let connection of user.connections.pending">
		<div class="author media" (click)="openUserProfile(connection)">
			<div class="rounded-circle" [style.background]="'url(' + connection.picture_url + ')'" [style.background-size]="'cover'"></div>
			<div class="media-body">
				<h6>{{connection.title + ' ' + connection.first_name + ' ' + connection.last_name}}</h6>
				<span>{{(connection.specialty ? translationService.getTranslatedName(connection.specialty.translations, locale) : '') +
						(connection.specialty && connection.country ? ', ' : '') +
						(connection.country ? translationService.getTranslatedName(connection.country.translations, locale) : '')}}</span>
			</div>
		</div>
		<div class="request-actions">
			<a (click)="acceptConnection(connection)" class="request-actions-item">
				<i class="fal fa-check-circle" aria-hidden="true"></i>
			</a>
			<a (click)="declineConnection(connection)" class="request-actions-item">
				<i class="fal fa-times-circle" aria-hidden="true"></i>
			</a>
		</div>
	</div>
</div>
<div class="request row" *ngIf="user && user.connections && currentTab==='connected' && !loading">
	<div class="request-item col-md-4" *ngFor="let connection of user.connections.accepted">
		<div class="author media" (click)="openUserProfile(connection)">
			<div class="rounded-circle" [style.background]="'url(' + connection.picture_url + ')'" [style.background-size]="'cover'"></div>
			<div class="media-body">
				<h6>{{connection.title + ' ' + connection.first_name + ' ' + connection.last_name}}</h6>
				<span>{{(connection.specialty ? translationService.getTranslatedName(connection.specialty.translations, locale) : '') +
						(connection.specialty && connection.country ? ', ' : '') +
						(connection.country ? translationService.getTranslatedName(connection.country.translations, locale) : '')}}</span>
			</div>
		</div>
		<div class="request-actions">
			<a [routerLink]="'/inbox/thread/'+connection.thread_with_auth" class="request-actions-item">
				<i class="fal fa-envelope"></i>
			</a>
			<a (click)="deleteConnection(connection)" class="request-actions-item">
				<i class="fal fa-trash-alt"></i>
			</a>
		</div>
	</div>
</div>

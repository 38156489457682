import {Component, OnInit} from '@angular/core';
import {PaymentService} from '../../../../services/payment.service';
import {Payment} from '../../../../models/payment.model';
import {DateMomentService} from '../../../../services/date-moment.service';
import {LocaleService} from '../../../../services/locale.service';
import {environment} from '../../../../../environments/environment';
import {CartService} from '../../../../services/cart.service';

//declare var gtag;

@Component({
  selector: 'app-marketplace-free-thanks',
  templateUrl: './marketplace-free-thanks.component.html',
  styleUrls: ['./marketplace-free-thanks.component.scss'],
  providers: [PaymentService, CartService]
})
export class MarketplaceFreeThanksComponent implements OnInit {
  payment: Payment;
  locale: string;
  environment: any = environment;

  constructor(private cartService: CartService,
              public dateMomentService: DateMomentService,
              public localeService: LocaleService) {
  }

  ngOnInit() {
    this.cartService.deleteCurrentCartId();
    this.locale = this.localeService.currentLocale();
    // gtag('event', 'academy_subscription_step3_payment_thanks');
  }

}

import {User} from './user.model';

export class Feedback {
  id: number;
  sorting_order: number;
  translations: Array<object>;
  user: User;

  constructor(obj?: any) {
    this.id              = obj && obj.id;
    this.sorting_order   = obj && obj.sorting_order;
    this.translations    = obj && obj.translations;
    this.user            = obj && obj.user ? new User(obj.user) : null;
  }
}

<div class="back-to-imcas">
  <a [href]="env.base_url + '/' + localeService.currentLocale()"
     class="back-to-imcas-link">{{'academy.Back_to_imcas'|translate}}</a>
  <a routerLink="/contact" class="back-to-imcas-help">
    <span class="icon icon-help"></span>{{'aop_layout.Do_you_have_a_question'|translate}}
  </a>
</div>
<header class="header" [ngClass]="{'header-menu-opened': headerMenuOpened}">
  <a [routerLink]="logged ? '/dashboard' : '/home'" class="header-logo"><img [src]="logo" alt=""/></a>
  <a class="header-menu-trigger" (click)="headerMenuOpened = !headerMenuOpened"><span
    class="icon icon-menu-white"></span></a>
  <div class="header-menu-mobile">
    <nav class="header-menu">
      <ul>
        <li *ngIf="isMasterclassActiveTab" (click)="headerMenuOpened = false" [ngClass]="{'active': isActiveTab('masterclass') }">
          <a [routerLink]="'/masterclass/'+ nextMasterclassSlug" class="header-menu-item">
            Live Summit
          </a>
        </li>
        <li (click)="headerMenuOpened = false" [ngClass]="{'active': isActiveTab('library') }"><a
          [routerLink]="'/library/courses'" class="header-menu-item">{{'academy.Library'|translate}}</a></li>
        <li (click)="headerMenuOpened = false" [ngClass]="{'active': isActiveTab('webinars') || isActiveTab('webinar') }"><a
          routerLink="/webinars" class="header-menu-item">{{'account.Email_webinars'|translate}}</a></li>
        <li (click)="headerMenuOpened = false" [ngClass]="{'active': isActiveTab('alert') }" *ngIf="showAlert"><a
          routerLink="/alert" class="header-menu-item">{{'academy.Alert'|translate}}</a></li>
        <li (click)="headerMenuOpened = false" [ngClass]="{'active': isActiveTab('blog') }"><a routerLink="/blog"
                                                                                               class="header-menu-item ">
          <!-- header-imcas-menu-item-active -->
          {{'academy.Blog'|translate}}
        </a></li>
 <!--       <li (click)="headerMenuOpened = false" [ngClass]="{'active': isActiveTab('industry') }"><a routerLink="/industry"
                                                                                                   class="header-menu-item ">
          {{'explore.Industry'|translate}}
        </a></li>-->
        <li [ngClass]="{'active': isActiveTab('explore') }">
          <div class="dropdown dropdown-hover">
            <a class="header-menu-item">
              {{'layout.Explore'|translate}}
              <span class="arrow-down"></span></a>
            <div class="dropdown-content" (click)="headerMenuOpened = false">
              <a routerLink="/about"
                 class="dropdown-item">The Academy</a>
              <a routerLink="/explore/scientific-board"
                 class="dropdown-item">{{'academy.Scientific_board'|translate}}</a>
              <a routerLink="/explore/partners"
                 class="dropdown-item">{{'pluralize.Scientific_partners'|translate}}</a>
              <a routerLink="/explore/worldwide-events"
                 class="dropdown-item">{{'explore.Worldwide_events'|translate}}</a>
              <a routerLink="/explore/humanitarian-missions"
                 class="dropdown-item">{{'explore.Humanitarian_missions'|translate}}</a>
              <a routerLink="/feedback"
                 class="dropdown-item">{{'layout.Feedbacks'|translate}}</a>
              <a routerLink="/faq"
                 class="dropdown-item">Academy {{'layout.FAQ'|translate}}</a>
            </div>
          </div>
        </li>
        <li>
          <span class="sponsor-pipe">&nbsp;|&nbsp;</span><a [href]="env.base_url + '/academy/sponsor'" class="header-menu-item">{{'academy.Sponsor'|translate}}</a>
        </li>
      </ul>
    </nav>
    <app-header-profile (loggedOutClick)="logout()"
                        (webinarToday)="setNextWebinar($event)"
                        (menuClicked)="headerMenuOpened = false"></app-header-profile>
  </div>
  <div class="header-menu-opened-mask" *ngIf="headerMenuOpened" (click)="headerMenuOpened = !headerMenuOpened"></div>
  <div *ngIf="logged">
    <div class="header-login-mobile">
      <button class="button button-orange" (click)="logout()">
        {{'live.Logout'|translate}}
      </button>
    </div>
  </div>
  <div *ngIf="!logged">
    <a [routerLink]="'/account/login'" class="header-login-mobile">
      {{'live.Login'|translate}}
    </a>
  </div>
</header>
<div class="webinar-banner" *ngIf="showBannerWebinar">
  <a [routerLink]="'/webinars'" *ngIf="showBannerType =='today'">
    {{ 'webinar.Live_webinar_banner_hours'|translate: {hours: nextWebinar.datetime_when_in_hours} }}
  </a>
  <a [routerLink]="'/webinar/' + nextWebinar.slug" *ngIf="showBannerType =='current'">
    {{ 'webinar.Live_webinar_now'|translate }}
  </a>
  <a [routerLink]="'/webinars'" *ngIf="showBannerType =='soon'">
    {{ 'webinar.Live_webinar_banner_starting_soon'|translate }}
  </a>
  <i class="fal fa-times" *ngIf="showBannerType" (click)="showBannerWebinar = false"></i>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  environment: any = environment;
  @Input() badge: any;
  @Input() size: number;
  @Input() tooltipMsg: any;

  constructor() { }

  ngOnInit() {
  }

}

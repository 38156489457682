<h1>{{ 'academy.Badges'|translate }}</h1>
<div class="row loading" *ngIf="loading"><span><i class="fa fa-spinner fa-spin"></i></span></div>
<div class="row" *ngIf="!loading && apiError">
  <p>Sorry, we were unable to fetch badge data.</p>
  <p>
    {{apiError}}
  </p>
</div>
<div class="row" *ngIf="!loading && !apiError && badges.length == 0">
  <p>Sorry, badges are not available for now.</p>
</div>
<div class="wrapper" *ngIf="!loading && !apiError && badges.length > 0">
  <div *ngFor="let badgeGroup of badges">
    <!-- Unlocked badges -->
    <div class="user-badge owned" *ngIf="badgeGroup.current && badgeGroup.current.isOwned">
      <div class="user-badge-wrapper">
        <app-badge
          [badge]="badgeGroup.current"
          [size]="7"
          [tooltipMsg]="badgeGroup.current.description"
        ></app-badge>
        <div class="description" *ngIf="badgeGroup.next && badgeGroup.next.description">
          {{ badgeGroup.next.description }}
        </div>
        <div class="description" *ngIf="!badgeGroup.next && badgeGroup.current.description">
          {{ badgeGroup.current.description }}
          <i class="fa fa-check" aria-hidden="true"></i>
        </div>
        <div class="progress-wrapper" *ngIf="badgeGroup.next">
          <div class="progress-bar" [style.width.%]="badgeGroup.progress / badgeGroup.next.threshold * 100"></div>
          <div class="progress-text">
            <span>{{ badgeGroup.progress }} / {{ badgeGroup.next.threshold }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Locked badges -->
    <div class="user-badge locked" *ngIf="badgeGroup.current && !badgeGroup.current.isOwned">
      <div class="locked-overlay"></div>
      <div class="user-badge-wrapper">
        <div class="badge-logo" id="{{badgeGroup.current.id}}">
          <div class="badge-icon">
            <i class="fas fa-lock" aria-hidden="true"></i>
          </div>
        </div>
        <div class="description" *ngIf="badgeGroup.current.description">
          {{ badgeGroup.current.description }}
        </div>
        <div class="progress-wrapper" *ngIf="badgeGroup.current">
          <div class="progress-bar" [style.width.%]="badgeGroup.progress / badgeGroup.current.threshold * 100"></div>
          <div class="progress-text">
            <span>{{ badgeGroup.progress }} / {{ badgeGroup.current.threshold }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section section-filtered page-top" [ngStyle]="{'background-image' : 'url(' + backgroundUrl + ')'}"
         [ngClass]="{'user-top' : user, 'webinar' : webinar, 'section-filtered-aop' : isAop, 'section-filtered-imcas' : !isAop}">
    <div class="content">
        <h1 *ngIf="title  && !title2">{{ title|translate }}</h1>
        <h1 *ngIf="title && title2">{{ title|translate }}: {{ title2|translate }}</h1>
        <p *ngIf="subtitle" class="page-top-intro">{{ subtitle }}</p>
        <a *ngIf="subtitle2" routerLink="/feedback" class="page-top-intro">{{ subtitle2 }}</a>
        <div class="media" *ngIf="user">
            <img [src]="user.picture_url" class="rounded-circle" alt="">
            <div class="media-body">
                <h4>{{ 'academy.Welcome_back'|translate }}</h4>
                <h5>{{ user.getFullName() }}</h5>
                <a [routerLink]="user.getProfileUrl()" class="page-top-intro">{{ 'academy.View_profile'|translate}}</a>
            </div>
        </div>
        <div *ngIf="webinar" class="row dates">

            <div [ngClass]="{'col-md-4' : webinar.length == 3, 'col-md-6' : webinar.length == 2, 'col-md-12' : webinar.length == 1}"
                 *ngFor="let pairs of webinar;let i = index">
                <p><span>{{ dateMomentService.getDateTimeBeginEnd(pairs.val) }}, {{pairs.key}}</span></p>
            </div>
        </div>
    </div>
</section>

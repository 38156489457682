import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as algoliasearch from 'algoliasearch';
import {Router} from '@angular/router';
import {LocaleService} from '../../services/locale.service';
import {ClickOutsideDirective} from '../../directives/click-outside.directive';
import {User} from '../../models/user.model';
import {TranslationService} from '../../services/translation.service';


@Component({
  selector: 'app-search-ui',
  templateUrl: './search-ui.component.html',
  styleUrls: ['./search-ui.component.scss'],
  providers: [ClickOutsideDirective]
})
export class SearchUiComponent implements OnInit, OnChanges {
  search: String;
  client: any;
  cdn_url: any;
  users: any;
  medias: any;
  courses: any;
  articles: any;
  topics: any;
  products: any;
  congresses: any;
  companies: any;
  pages: any;
  linkToConnectionsPage: boolean = false;
  results = 0;
  locale: any;
  @Input() disabled: boolean = false;
  @Input() transparent: boolean;
  @Input() currentUser: User;
  @Input() usersIndex: boolean;
  @Input() topicsIndex: boolean;
  @Input() productsIndex: boolean;
  @Input() companiesIndex: boolean;
  @Input() pagesIndex: boolean;
  @Input() congressesIndex: boolean;
  @Input() coursesIndex: boolean;
  @Input() articlesIndex: boolean;
  @Input() mediasIndex: boolean;
  @Input() usersHitsPerPage: number = 2;
  @Output() sendConnection: EventEmitter<number> = new EventEmitter<number>();
  @Input() alertCasesIndex: boolean;
  @Input() alertGroupId: number = 1;
  alertCases: any;

  getSlug(string) {
    return this.translationService.getSlug(string);
  }

  constructor(private localeService: LocaleService, private router: Router, private translationService: TranslationService) {
  }

  getAlgoliaUserId(user) {
    return Number(user.objectID.replace('aesthetics', '').replace('ophthalmology', ''));
  }

  emitSendConnection(user) {
    this.sendConnection.emit(this.getAlgoliaUserId(user));
  }

  canAskForFriendship(user) {
    const userId = this.getAlgoliaUserId(user);
    if (this.currentUser) {
      const exist1 = this.currentUser.connections?.pending?.find(element => {
        return element.id === userId;
      });
      const exist2 = this.currentUser.connections?.pending_their_answer?.find(element => {
        return element.id === userId;
      });
      const exist3 = this.currentUser.connections?.accepted?.find(element => {
        return element.id === userId;
      });
      return !(exist1 || exist2 || exist3);
    } else {
      return false;
    }

  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    const options = environment.algolia;
    this.client = algoliasearch(options.appId, options.apiKey);
    this.cdn_url = environment.cdn_base_url;
  }

  ngOnChanges(changes) {
    if (changes.currentUser) {
      this.currentUser = changes.currentUser.currentValue;
    }
  }

  openUrl(type, object) {
    const id = object.objectID;
    if (type === 'topics') {
      this.router.navigateByUrl(object.link);
    }
/*    if (type === 'products') {
      this.router.navigateByUrl('/marketplace/' + object.slug);
    }*/
    if (type === 'companies' || type === 'users') {
      this.router.navigateByUrl('/profile/' + object.slug);
    }
    if (type === 'pages') {
      this.router.navigateByUrl('/' + object.angularUrl);
    }
    if (type === 'congresses') {
      document.location.href = object.url;
    }
    if (type === 'courses' && object.courseTitles) {
      const slug = this.getSlug(object.courseTitles.en);
      this.router.navigateByUrl('/course/' + id + '/' + slug);
    }
    if (type === 'articles' && object.articleTitle) {
      const slug = this.getSlug(object.articleTitle);
      this.router.navigateByUrl('/blog/' + id + '/' + slug);
    }
    if (type === 'medias' && object.mediaTitles) {
      const slug = this.getSlug(object.mediaTitles.en);
      this.router.navigateByUrl('/show/' + id + '/' + slug);
    }
    if(type==='alertCases' && object.alertCaseTitle) {
      const slug = this.getSlug(object.alertCaseTitle);
      this.router.navigateByUrl('/alert/'+object.alert_group_slug+'/' + id +'/'+slug);
    }
  }

  launchGlobalSearch(event) {
    if (this.search) {
      this.router.navigateByUrl('/library/lectures?q=' + this.search);
    }
    if (this.articlesIndex && !this.mediasIndex) {
      this.router.navigateByUrl('/blog');
    }
  }

  launchSearch(event) {
    if (!this.search) {
      this.results = 0;
      return true;
    }
    if (this.search.length < 3) {
      this.results = 0;
      return true;
    }
    if (event.key === 'Enter') {
      this.launchGlobalSearch(null);
      return true;
    }
    const queries = [];
    if (this.usersIndex) {
      queries.push({
        indexName: 'academy_users',
        query: this.search,
        params: {
          hitsPerPage: this.usersHitsPerPage,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.mediasIndex) {
      queries.push({
        indexName: 'medias',
        query: this.search,
        params: {
          hitsPerPage: 4,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.coursesIndex) {
      queries.push({
        indexName: 'courses',
        query: this.search,
        params: {
          hitsPerPage: 6,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.articlesIndex) {
      queries.push({
        indexName: 'articles',
        query: this.search,
        params: {
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.topicsIndex) {
      queries.push({
        indexName: 'topics',
        query: this.search,
        params: {
          hitsPerPage: 6,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.congressesIndex) {
      queries.push({
        indexName: 'congresses',
        query: this.search,
        params: {
          hitsPerPage: 6,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.companiesIndex) {
      queries.push({
        indexName: 'profile',
        query: this.search,
        params: {
          hitsPerPage: 6,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.pagesIndex) {
      queries.push({
        indexName: 'pages',
        query: this.search,
        params: {
          hitsPerPage: 6,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if (this.productsIndex) {
      queries.push({
        indexName: 'congress_products',
        query: this.search,
        params: {
          hitsPerPage: 6,
          filters: '_tags:' + environment.domain
        }
      });
    }
    if(this.alertCasesIndex) {
      queries.push({
        indexName: 'alert_cases',
        query: this.search,
        params: {
          filters: 'alert_group_id:' + this.alertGroupId
        }
      })
    }
    this.client.search(queries, (err, content) => {
      if (err) {
        return;
      }
      this.users = [];
      this.medias = [];
      this.courses = [];
      this.articles = [];
      this.topics = [];
     /* this.products = [];*/
      this.congresses = [];
      this.companies = [];
      this.pages = [];
      this.results = 0;
      this.alertCases = [];

      const users = content.results.find(function (element) {
        return element.index === 'academy_users';
      });
      if (users) {
        this.linkToConnectionsPage = users.nbHits > 2;
        for (let i = 0; i < users.hits.length; ++i) {
          this.users.push(users.hits[i]);
        }
        this.results += users.nbHits;
      }

      const medias = content.results.find(function (element) {
        return element.index === 'medias';
      });
      if (medias) {
        for (let i = 0; i < medias.hits.length; ++i) {
          this.medias.push(medias.hits[i]);
        }
        this.results += medias.nbHits;
      }
      const courses = content.results.find(function (element) {
        return element.index === 'courses';
      });
      if (courses) {
        for (let i = 0; i < courses.hits.length; ++i) {
          this.courses.push(courses.hits[i]);
        }
        this.results += courses.nbHits;
      }

      const articles = content.results.find(function (element) {
        return element.index === 'articles';
      });
      if (articles) {
        for (let i = 0; i < articles.hits.length; ++i) {
          this.articles.push(articles.hits[i]);
        }
        this.results += articles.nbHits;
      }

      const topics = content.results.find(function (element) {
        return element.index === 'topics';
      });
      if (topics) {
        for (let i = 0; i < topics.hits.length; ++i) {
          this.topics.push(topics.hits[i]);
        }
        this.results += topics.nbHits;
      }
/*
      const products = content.results.find(function (element) {
        return element.index === 'congress_products';
      });
      if (products) {
        for (let i = 0; i < products.hits.length; ++i) {
          this.products.push(products.hits[i]);
        }
        this.results += products.nbHits;
      }
*/

      const congresses = content.results.find(function (element) {
        return element.index === 'congresses';
      });
      if (congresses) {
        for (let i = 0; i < congresses.hits.length; ++i) {
          this.congresses.push(congresses.hits[i]);
        }
        this.results += congresses.nbHits;
      }

      const companies = content.results.find(function (element) {
        return element.index === 'profile';
      });
      if (companies) {
        for (let i = 0; i < companies.hits.length; ++i) {
          this.companies.push(companies.hits[i]);
        }
        this.results += companies.nbHits;
      }

      const pages = content.results.find(function (element) {
        return element.index === 'pages';
      });
      if (pages) {
        for (let i = 0; i < pages.hits.length; ++i) {
          this.pages.push(pages.hits[i]);
        }
        this.results += pages.nbHits;
      }

      const alertCases = content.results.find(function (element) {
        return element.index === 'alert_cases';
      });
      if (alertCases) {
        for (let i = 0; i < alertCases.hits.length; ++i) {
          this.alertCases.push(alertCases.hits[i]);
        }
        this.results += alertCases.nbHits;

      }
    });
  }

  getTitlesTranslated(titles) {
    if (titles[this.locale]) {
      return titles[this.locale].value;
    } else if (titles['en']) {
      return titles['en'].value;
    } else {
      return titles['fr'].value;
    }
  }

  getAuthorsString(authors) {
    let authorsString = '';
    if (typeof authors !== 'undefined') {
      authors.forEach(function (author) {
        authorsString += author.value + ', ';
      });
    }
    authorsString = authorsString.substr(0, authorsString.length - 2);
    return authorsString;
  }

  close(event) {
    this.search = '';
    this.results = 0;
  }

  getPlaceholder() {
    if (this.transparent) {
      return 'academy.Search_all';
    }
    if (this.alertCasesIndex && !this.transparent) {
      return 'Search Alert case';
    } else if (this.articlesIndex && !this.transparent) {
      return 'academy.Search_articles';
    } else {
      return 'live.Search_User';
    }
  }

  displayAuthors(article: any) {
    var authors = '';
    var first = true;
    Object.keys(article.authors).forEach(key => {
      if (first) {
        authors += article.authors[key].name;
        first = false;
      } else {
        authors += ', ' + article.authors[key].name;
      }
    });
    return authors;
  }
}

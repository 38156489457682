import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {WebsiteLaunchingEmail} from '../models/website_launching_email.model';
import {HttpClient} from '@angular/common/http';
import {shareReplay} from 'rxjs/internal/operators';

@Injectable()
export class WebsiteLaunchingEmailService {
    private endpoint: string = '/website_launching_emails';

    constructor(private httpClient: HttpClient) {
    }

    subscribeToBlog(email): Observable<WebsiteLaunchingEmail> {
        return this.httpClient.post<WebsiteLaunchingEmail>(environment.apiURL + this.endpoint, {
            campaign_code: 'academy_blog',
            email: email
        }).pipe(shareReplay());
    }
}

<app-mobile-login *ngIf="env.mobile"></app-mobile-login>
<div *ngIf="!env.mobile" class="page login">
  <section class="section section-login">
    <div class="content">
      <h1>{{'account.Login_title'|translate}}</h1>
      <form class="form form-login" #loginForm="ngForm" (ngSubmit)="login()">
        <h3>{{'account.Enter_your_details_below'|translate}}</h3>
        <div class="form-row">
          <label for="username" class="form-label form-label-text">{{ 'account.Username'|translate }} / {{'account.Email'|translate}}*</label>
          <input id="username" name="username" [disabled]="loading" autocomplete="username" class="textinput form-control" required #username="ngModel" [(ngModel)]="loginData.username">
          <div *ngIf="username.invalid && (username.dirty || username.touched)" class="form-login-error">
            <div *ngIf="username.errors.required">
              {{'validation.required'|translate:{attribute: 'account.Username'|translate } }}
            </div>
          </div>
        </div>
        <div class="form-row">
          <label for="password" class="form-label form-label-text">{{'account.Password'|translate}}*</label>
          <input type="password" id="password"  [disabled]="loading" autocomplete="current-password" name="password" class="textinput form-control" required [(ngModel)]="loginData.password"
                 #password="ngModel">
          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="form-login-error">
            <div *ngIf="password.errors.required">
              {{'validation.required'|translate:{attribute: 'account.Password'|translate } }}
            </div>
          </div>
        </div>
        <div class="form-row form-checkbox">
          <label>
            <input type="checkbox" name="rememberMeChecked" class="form-checkbox form-control" [(ngModel)]="loginData.rememberMeChecked">
            <span>{{'account.Keep_me_logged_in'|translate}}</span>
          </label>
        </div>
        <div class="row">
          <div class="col col-50 col-m-50 box-right">
            <button class="button button-block button-large button-orange" [disabled]="!loginForm.valid && !loading">
              <span *ngIf="!loading">{{ 'account.Login_button'|translate}}</span>
              <span *ngIf="loading" ><i class="fas fa-spinner fa-spin"></i> Loading...</span>
            </button>
          </div>
          <div class="col col-50 col-m-50 form-login-forgot">
            <a [routerLink]="'/password-lost'">{{'account.Lost_password'|translate}}?</a>
          </div>
        </div>
        <div *ngIf="loginError" class="form-login-error form-login-error-main">
          {{loginError|translate}}
        </div>
        <div class="form-login-signup">
          <span>{{'account.Dont_have_an_account'|translate}}</span>
          <a [routerLink]="'/account/create'"> {{'account.Sign_up_now'|translate}}</a>
        </div>
      </form>
    </div>
  </section>
</div>

<div class="page container" >
    <div class="row">
        <div class="col-md-12">
            <div class="title">
                <h3>{{ 'page.Feedback_title'|translate }}</h3>
            </div>
          <app-loader *ngIf="loading"></app-loader>
          <ng-container *ngIf="!loading">
            <div class="imcas-best-feedbacks" *ngFor="let feedback of mainFeedbacks; index as i">
              <div class="row">
                <div class="col-md-4" *ngIf="i % 2 == 0">
                  <div class="thumbnail-speaker">
                    <div [style.background-image]="'url(' + feedback.user.picture_url + ')'"></div>
                  </div>
                </div>
                <div class="col-md-8 testimonial">
                  <p class="testimonial-speaker">
                    "{{ translationService.getTranslatedAttribute('content',feedback.translations, locale)}}"
                  </p>
                  <span class="testimonial-author">{{ feedback.user.fullname }}</span>
                  <span class="testimonial-speciality-country">{{ translationService.getTranslatedAttribute('name',feedback.user.specialty.translations, locale)}}, {{ translationService.getTranslatedAttribute('name',feedback.user.country.translations, locale)}}</span>
                </div>
                <div class="col-md-4" *ngIf="i % 2 !== 0">
                  <div class="thumbnail-speaker">
                    <div [style.background-image]="'url(' + feedback.user.picture_url + ')'"
                         [style.float]="i % 2 !== 0 ? 'right' : 'left'"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-columns">
              <div class="card" *ngFor="let feedback of feedbacks">
                <div class="imcas-misc-feedback">
                  <div class="feedback-content">"{{
                    translationService.getTranslatedAttribute('content',feedback.translations, locale)}}"
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="thumbnail-speaker">
                        <div [style.background-image]="'url(' + feedback.user.picture_url + ')'"></div>
                      </div>
                    </div>
                    <div class="col-md-9">
                                <span class="testimonial-author"
                                      *ngIf="feedback.user ">{{ feedback.user.fullname }}</span>
                      <span class="testimonial-speciality-country"
                            *ngIf="feedback.user.specialty && feedback.user.country">{{ translationService.getTranslatedAttribute('name',feedback.user.specialty.translations, locale)}}, {{ translationService.getTranslatedAttribute('name',feedback.user.country.translations, locale)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="end-sentence">
              {{ 'page.Feedbacks_more1_academy'|translate }} <br>
              {{ 'page.Feedbacks_more2_academy'|translate }}
            </p>
          </ng-container>
        </div>
    </div>
</div>

import {ArticleModel} from './article.model';
import {Country} from './country.model';
import {Theme} from './theme.model';

export interface Exhibitor {
  id: number;
  sponsorship_level: string;
}
export class CompanyProfileModel {
  id: number;
  slug: string;
  logo_url: string;
  website: string;
  display_name: string;
  company_name: string;
  country: Country;
  articles: ArticleModel[];
  themes: Theme[];
  industry_categories: [];
  total_videos: number;
  cover_url: string;
  exhibitor: Exhibitor;
  thumbnail_url: string;

  constructor(obj?: any) {
    this.id = obj && obj.id;
    this.slug = obj && obj.slug;
    this.logo_url = obj && obj.logo_url;
    this.website = obj && obj.website;
    this.country = obj && obj.country;
    this.total_videos = obj && obj.total_videos;
    this.display_name = obj && obj.display_name;
    this.company_name = obj && obj.company_name;
    this.articles = obj && obj.articles;
    if (obj && obj.articles) {
      this.articles = [];
      obj.articles.forEach(element => {
        this.articles.push(new ArticleModel(element));
      });
    }
    this.themes = obj && obj.themes;
    if (obj && obj.themes) {
      this.themes = [];
      obj.themes.forEach(element => {
        this.themes.push(new Theme(element));
      });
    }
    this.industry_categories = obj && obj.industry_categories;
    this.cover_url = obj && obj.cover_url;
    this.exhibitor = obj && obj.exhibitor;
    this.thumbnail_url = obj && obj.thumbnail_url;
  }

  getArticles(nb) {
    return this.articles.slice(0, nb);
  }
}

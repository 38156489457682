import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'dropdown-locale-component',
  templateUrl: './dropdown-locale.component.html',
  styleUrls: ['./dropdown-locale.component.scss']
})

export class DropdownLocaleComponent implements OnInit {
  @Input() options: any;
  @Input() selectedOption: any;
  @Input() id: any;
  @Input() name: any;
  @Output() optionChange = new EventEmitter();

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.selectedOption = this.translate.currentLang;
  }

  onOptionChange() {
    this.optionChange.emit(this.selectedOption);
  }
}

<form #rateForm="ngForm" (ngSubmit)="activeModal.close(userAction)">
  <div class="modal-body lecture-rate">
    <h4 id="review-modal-label">{{title|translate}}</h4>
    <p>{{subtitle|translate}}</p>
    <div class="lecture-rate-stars">
      <ngb-rating [(rate)]="userAction.rate" max="5" min="1">
        <ng-template let-fill="fill">
          <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
          <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
          <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
        </ng-template>
      </ngb-rating>
    </div>
    <div class="lecture-rate-input">
      <textarea [(ngModel)]="userAction.comment" required name="comment" title=""></textarea>
    </div>
    <button type="submit" class="button button-rounded">{{'account.Submit'|translate}}</button>
  </div>
</form>
<select id="{{id}}"
        [(ngModel)]="selectedOption"
        name="{{name}}"
        (ngModelChange)="onOptionChange()"
        class="{{ componentClass }}"
        [class.active]="selectedOption != 0">
  <option *ngIf="addEmpty" [value]="null">{{ placeholder }} </option>
  <option *ngFor="let option of options | mapToIterable"
          [ngValue]="option">
    {{translationService.getTranslatedName(option.translations, locale)}}
  </option>
</select>

import {Component, OnInit} from '@angular/core';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {TranslationService} from 'app/services/translation.service';
import {merge, Observable, of, Subject} from 'rxjs';
import {ScientificSocietyService} from 'app/services/scientific-society.service';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';


@Component({
    selector: 'profile-societies',
    templateUrl: './societies.component.html',
    styleUrls: ['./societies.component.scss'],
    providers: [
        UserService,
        ScientificSocietyService
    ]
})
export class ProfileSocietiesComponent implements OnInit {
    focus$ = new Subject<string>();
    user: User;
    updated: boolean = false;
    model: any;
    search: any;
    searching = false;
    searchFailed = false;

    hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
    locale: string;
    formatter = (r: { name: string, acronym: string, country: any }) =>
        (this.translationService.getTranslatedName(r.country.translations, this.locale)).toUpperCase()
        + ' - ' + r.name + (r.acronym ? ' (' + r.acronym + ')' : '');


    constructor(private userService: UserService,
                private scientificSocietyService: ScientificSocietyService,
                private authService: AuthService,
                public translationService: TranslationService,
                private localeService: LocaleService) {
        this.search = (text$: Observable<string>) => {
            const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged(),
                tap(() => this.searching = true),
                switchMap(term => {
                        return this.scientificSocietyService.getScientificSocieties(term).pipe(
                            tap(() => this.searchFailed = false),
                            catchError(() => {
                                this.searchFailed = true;
                                return of([]);
                            })
                        )
                    }
                ),
                tap(() => this.searching = false),
            );
            return merge(debouncedText$, this.focus$, this.hideSearchingWhenUnsubscribed);
        }
    }
    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }
    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {user_scientific_societies: 1}).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                }
            );
        }

    }

    addSociety(event, input) {
        if (event && event.item && event.item.id) {
            const alreadyAdded = this.user.scientific_societies.find(society => society.id === event.item.id);
            if (!alreadyAdded) {
                this.scientificSocietyService.addScientificSociety(event.item.id).subscribe(success => {
                        this.user.scientific_societies.push(event.item);
                        this.updated = true;
                    });
            }
        }
        event.preventDefault();
        input.value = '';
    }

    removeSociety(item) {
        this.scientificSocietyService.deleteScientificSociety(item.id).subscribe(success => {
            this.user.scientific_societies = this.user.scientific_societies.filter(obj => obj !== item);
            this.updated = true;
        });
    }
}

import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'slick-carousel';
import {ArticleService} from 'app/services/article.service';
import {ArticleModel} from 'app/models/article.model';
import {Observable, Subject} from 'rxjs/index';
import {ActivatedRoute, Router} from '@angular/router';
import {Theme} from 'app/models/theme.model';
import {TranslationService} from 'app/services/translation.service';
import {ThemeService} from 'app/services/theme.service';
import {LocaleService} from '../../../services/locale.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    providers: [ArticleService, ThemeService]
})
export class BlogComponent implements OnInit {
    private _slickOptions: any;
    locale: string;
    headerArticles$: Observable<ArticleModel[]>;
    pagedArticles: any;
    currentPage: number = 1;
    selectedTheme: Theme;
    themes: Theme[];
    themeId: number;
    pagesNumbers: number[];
    showLoading: boolean = false;
    searchQuery = '';
    private subject: Subject<string> = new Subject();

    dataReceivedFromWeb = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private articleService: ArticleService,
        private router: Router,
        public translationService: TranslationService,
        public localeService: LocaleService,
        private themeService: ThemeService,
        protected localStorage: LocalStorage
    ) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.getHeader();
        this.activatedRoute.queryParams.subscribe(params => {
            this.getArticles(params['theme_id']);
        });
        this.getThemes();
    }

    getHeader() {
        this.headerArticles$ = this.articleService.getHeaderArticles();
        this.headerArticles$.subscribe(r => {
            setTimeout(function () {
                $('.blog-carousel').not('.slick-initialized').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    dots: true,
                    prevArrow: '',
                    nextArrow: '',
                });
            }, 500);
        });

    }

    getThemes() {
        this.localStorage.getItem <any>('BLOG-themes_' + environment.domain).subscribe((data) => {
            if (!('themes' in this.dataReceivedFromWeb) && data) {
                this.setDataThemes(data);
            }
        });

        this.themeService.getMainThemes('alpha').subscribe(themes => {
            this.setDataThemes(themes);

            this.dataReceivedFromWeb['themes'] = true;
            this.localStorage.setItem('BLOG-themes_' + environment.domain, themes).subscribe(() => {
            });

        });
    }

    getArticles(themeId) {
        let themeIdStr: any = themeId;
        if (!themeId) {
            themeIdStr = '';
        }

        this.localStorage.getItem <any>('BLOG-articles_' + themeIdStr + '_' + environment.domain).subscribe((data) => {
            if (!(('articles' + themeIdStr) in this.dataReceivedFromWeb) && data) {
                this.setDataArticles(data, themeId);
            }
        });

        this.showLoading = true;
        this.articleService.getArticles(null, {
            all: 1,
            theme_id: themeId,
            academy_blog: 1,
            article_academy_medias: 0,
            article_min: 0,
            article_content: 0,
            article_related: 0,
            article_comments: 0
        }).subscribe(
            results => {
                this.dataReceivedFromWeb['articles' + themeIdStr] = true;
                this.localStorage.setItem('BLOG-articles_' + themeIdStr + '_' + environment.domain, JSON.parse(JSON.stringify(results))).subscribe(() => {
                });

                this.setDataArticles(results, themeId);
            },
            error => {
                this.showLoading = false;
            }
        );
    }

    themeChange(theme) {
        this.currentPage = 1;
        this.router.navigate([], {
            queryParams: {theme_id: theme ? theme.id : null},
            relativeTo: this.activatedRoute
        });
    }

    goToPost(article) {
        this.router.navigate(['/', 'blog', article.id, article.slug]);
    }

    setDataArticles(results, themeId) {
        const arrays = [];
        while (results.length > 0) {
            arrays.push({
                articles: results.splice(0, 12)
            });
        }
        this.pagedArticles = arrays;
        if (themeId !== this.themeId) {
            this.themeId = themeId;
            this.selectedTheme = this.themes.find(theme => theme.id === +this.themeId);
        }
        this.pagesNumbers = Array.from({
            length: this.pagedArticles.length
        }, (v, i) => i + 1);
        this.showLoading = false;
    }

    setDataThemes(themes) {
        this.themes = themes;
    }

    searchQueryChange(searchTextValue) {
        this.subject.next(searchTextValue);
    }
}

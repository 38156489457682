<div class="page alert alert-service">
    <app-section-top [title]="'academy.Alert'" [subtitle]="'alert.Main_subtitle'|translate"
                     [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-alert.jpg'"></app-section-top>
    <div class="content alert-home">
        <div class="alert alert-danger alert-dismissable" *ngIf="error">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="error = false">×
            </button>
            {{error}}
        </div>
        <p>{{'alert.Home_descr_link'|translate}}</p>
        <h6>{{'registration_new.Choose_your_topic'|translate}}</h6>
        <h1>{{'alert.Enter_Alert'|translate}}</h1>

        <!--<div class="alert-cases" *ngIf="highCase">-->
            <!--<div class="topic-list">-->
                <!--<div class="topic-item" style="border: 1px solid #000000;border-radius: 9px;background-color: rgba(242,120,51, 0.3);">-->
                    <!--<div class="topic-type" *ngIf="false">{{translationService.getTranslatedAttribute('name', highCase.group.translations, locale)}}</div>-->
                    <!--<div [routerLink]="['/alert', highCase.group.slug, highCase.id, highCase.slug]" class="topic-case-data">-->
                        <!--<h5>{{highCase.title}}</h5>-->
                        <!--<p style="color: #666666;">{{dateMomentService.getPublicationDate(highCase.created_at)}}</p>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <app-alert-group *ngIf="userGroups && userGroups.length > 0" [groups]="groups"></app-alert-group>
        <app-alert-introduction [locale]="locale"></app-alert-introduction>
        <div class="alert-steps">
            <div class="row no-gutter">
                <div class="col-4 step-one">
                    <div class="step">
                        <span class="step-number">1</span>
                    </div>
                    <span class="step-label">{{ 'registration_new.Choose_your_topic'|translate}}</span>
                </div>
                <div class="col-4 step-two">
                    <div class="step disabled">
                        <span class="step-number">2</span>
                    </div>
                    <span class="step-label disabled">{{ 'alert.Home_step_2'|translate }}</span>
                </div>
                <div class="col-4 step-three">
                    <div class="step disabled">
                        <span class="step-number">3</span>
                    </div>
                    <span class="step-label disabled">{{ 'alert.Home_step_3'|translate }}</span>
                </div>
            </div>
        </div>
        <app-alert-group *ngIf="(userGroups && userGroups.length == 0 ) || !userGroups"
                         [groups]="groups"></app-alert-group>
        <app-alert-coordinators *ngIf="environment.domain == 'ophthalmology' && group"
                                [coordinators]="group.coordinators"></app-alert-coordinators>
        <div class="alert-cases" *ngIf="cases">
            <app-alert-last-cases [cases]="cases"></app-alert-last-cases>
        </div>
    </div>
</div>

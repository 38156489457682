import {ThreadService} from 'app/services/thread.service';
import {NotificationService} from 'app/services/notification.service';
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from "../../../models/user.model";
import {LocaleService} from "../../../services/locale.service";
import {UserService} from '../../../services/user.service';
import {LinkService} from '../../../services/link.service';
import {NetworkService} from '../../../services/mobile-services/network.service';
import * as algoliasearch from 'algoliasearch';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
declare var navigator: any;
declare var window: any;
import {ConfirmationComponent} from '../../modal/confirmation/confirmation.component';
import {IsCapacitorService} from "../../../services/is-capacitor.service";
import {Webinar} from "../../../models/webinar.model";
import {WebinarService} from "../../../services/webinar.service";
import {StartingWebinarComponent} from "../../modal/starting-webinar/starting-webinar.component";
import {TranslationService} from "../../../services/translation.service";

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    providers: [
      NotificationService,
      UserService,
      WebinarService
    ]
})
export class MobileMenuComponent implements OnInit {


    @Output() loggedOutClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('nvgBack', {static: false}) navBackElt: ElementRef;

    environment = environment;
    user: User;
    moreOpened = false;
    exploreOpened = false;
    accountOpened = false;
    searchOpened = false;

    alertUnread: any = {};
    memberShip = "";

    search: '';
    client: any;
    cdn_url: any;
    users: any;
    medias: any;
    courses: any;
    topics: any;
    congresses: any;
    companies: any;
    pages: any;
    linkToConnectionsPage: boolean = false;
    results = 0;
    locale: any;

    currentUser: User;
    usersIndex: boolean = true;
    topicsIndex: boolean = true;
    companiesIndex: boolean = true;
    pagesIndex: boolean = true;
    congressesIndex: boolean = true;
    coursesIndex: boolean = true;
    mediasIndex: boolean = true;
    usersHitsPerPage: number = 2;

    messageUnreadCount: number = 0;
    userUpdatedSubscription;
    percent: number = 5;
    subscription: Subscription;
    showBannerWebinar: boolean;
    showBannerType: string;
    nextWebinar: Webinar;
    articles: any;
    articlesIndex: boolean= true;

    constructor(
      public authService: AuthService,
      private router: Router,
      public localeService: LocaleService,
      private userService: UserService,
      private threadService: ThreadService,
      public linkService: LinkService,
      public isCapacitorService: IsCapacitorService,
      public networkService:NetworkService,
      private cd: ChangeDetectorRef,
      private modalService: NgbModal,
      private route: ActivatedRoute,
      private webinarService: WebinarService,
      private translationService: TranslationService
    ) {
    }

    ngOnInit() {

      this.user = this.authService.getCurrentUser();
      this.percent = this.userService.getCompletion(this.user);

        this.locale = this.localeService.currentLocale();
        const options = environment.algolia;
        this.client = algoliasearch(options.appId, options.apiKey);
        this.cdn_url = environment.cdn_base_url;

        this.authService.isLoggedIn().subscribe(logged => {
          if (logged){

            this.user = this.authService.getCurrentUser();
            this.currentUser = this.user;
            this.userService.getUserMembershipStatus(this.user.id).subscribe(data => {
              this.memberShip = data.status;
            });

            // update subscription if premium has been purchased
            this.userService.membershipEmitter.subscribe(res => {
              this.userService.getUserMembershipStatus(this.user.id).subscribe(data => {
                this.memberShip = data.status;
              });
            });

            this.threadService.observeMessagesUnreadCount().subscribe(messageUnreadCount => {
                this.messageUnreadCount = messageUnreadCount;
            });
            this.setNextWebinar();


          }
          else {
            this.authService.logoutWithoutRedirect();
            this.router.navigate(['/home']);
          }
        });

        this.userUpdatedSubscription = this.authService.getUserUpdatedEmitter().subscribe(
          (res) => {
            this.user = this.authService.getCurrentUser();
            this.cd.detectChanges();
          },
          err => {
            console.warn(err);
          }
        );
    }

    ngOnDestroy() {
      if (this.userUpdatedSubscription){
        this.userUpdatedSubscription.unsubscribe();
      }
    }

    ngOnChanges(changes) {
        if (changes.currentUser) {
            this.currentUser = changes.currentUser.currentValue;
        }
    }

    closeAllMenu(){
      this.moreOpened = false;
      this.exploreOpened = false;
      this.accountOpened = false;
      this.searchOpened = false;
      this.modalService.dismissAll();
    }

    //for app, must be kept during merge
    closeAndRedirect(redirection, isCapacitorApp = true) {
      this.closeAllMenu;
      if (isCapacitorApp) {
        return this.linkService.openBlank(this.environment.base_url + redirection);
      }
      return this.router.navigate(redirection);
    }

    toggleMoreMenu(){
      if (!this.moreOpened){
        this.moreOpened = true;
      }
      else {
        this.closeAllMenu();
      }

    }

    toggleExploreMenu(){
      this.exploreOpened = !this.exploreOpened;
    }

    toggleAccountMenu(){
      this.moreOpened = false;
      this.accountOpened = !this.accountOpened;
    }

    toggleSearchMenu(){
      this.moreOpened = false;
      this.searchOpened = !this.searchOpened;
    }

    logout() {
        this.closeAllMenu();
        this.loggedOutClick.emit(true);
    }

    isActiveTab(tab) {
        return this.router.url.indexOf(tab) > 0;
    }

    static getSlug(string) {
        return string.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    openUrl(type, object) {
        this.closeAllMenu();
        const id = object.objectID;

        if (type === 'topics') {
            this.router.navigateByUrl(object.link);
        }
        if (type === 'pages') {
            this.router.navigateByUrl('/' + object.angularUrl);
        }
        if (type === 'courses') {
            const slug = MobileMenuComponent.getSlug(object.courseTitles.en);
            this.router.navigateByUrl('/course/' + id + '/' + slug);

        }
        if (type === 'medias') {
            const slug = MobileMenuComponent.getSlug(object.mediaTitles.en);
            this.router.navigateByUrl('/show/' + id + '/' + slug);
        }
        if (type === 'companies' || type === 'users') {
            this.router.navigateByUrl('/profile/' + object.slug);
        }
        if (type === 'articles' && object.articleTitle) {
         const slug = this.translationService.getSlug(object.articleTitle);
          this.router.navigateByUrl('/blog/' + id + '/' + slug);
        }
    }


     launchSearch(event) {
        if (!this.search) {
            this.results = 0;
            return true;
        }

        const queries = [];
        if (this.usersIndex) {
            queries.push({
                indexName: 'academy_users',
                query: this.search,
                params: {
                    hitsPerPage: this.usersHitsPerPage,
                    filters: '_tags:' + environment.domain
                }
            });
        }
        if (this.mediasIndex) {
            let paramsMedia:any = {
                hitsPerPage: 4,
                filters: '_tags:' + environment.domain
            }

            queries.push({
                indexName: 'medias',
                query: this.search,
                params: paramsMedia
            });
        }
        if (this.coursesIndex) {
            let paramsCourses:any = {
                hitsPerPage: 6,
                filters: '_tags:' + environment.domain
            }

            queries.push({
                indexName: 'courses',
                query: this.search,
                params: paramsCourses
            });
        }
        if (this.topicsIndex) {
            queries.push({
                indexName: 'topics',
                query: this.search,
                params: {
                    hitsPerPage: 6,
                    filters: '_tags:' + environment.domain
                }
            });
        }
        if (this.congressesIndex) {
            queries.push({
                indexName: 'congresses',
                query: this.search,
                params: {
                    hitsPerPage: 6,
                    filters: '_tags:' + environment.domain
                }
            });
        }
        if (this.companiesIndex) {
            queries.push({
                indexName: 'profile',
                query: this.search,
                params: {
                    hitsPerPage: 6,
                    filters: '_tags:' + environment.domain
                }
            });
        }
        if (this.pagesIndex) {
            queries.push({
                indexName: 'pages',
                query: this.search,
                params: {
                    hitsPerPage: 6,
                    filters: '_tags:' + environment.domain
                }
            });
        }
       if (this.articlesIndex) {
         queries.push({
           indexName: 'articles',
           query: this.search,
           params: {
             filters: '_tags:' + environment.domain
           }
         });
       }

        this.client.search(queries, (err, content) => {
            if (err) {
                return;
            }
            this.users = [];
            this.medias = [];
            this.courses = [];
            this.topics = [];
            this.congresses = [];
            this.companies = [];
            this.pages = [];
            this.articles = [];
            this.results = 0;

            const users = content.results.find(function (element) {
                return element.index === 'academy_users';
            });
            if (users) {
                this.linkToConnectionsPage = users.nbHits > 2;
                for (let i = 0; i < users.hits.length; ++i) {
                    this.users.push(users.hits[i]);
                }
                this.results += users.nbHits;
            }

            const medias = content.results.find(function (element) {
                return element.index === 'medias';
            });
            if (medias) {
                for (let i = 0; i < medias.hits.length; ++i) {
                    this.medias.push(medias.hits[i]);
                }
                this.results += medias.nbHits;
            }
            const courses = content.results.find(function (element) {
                return element.index === 'courses';
            });
            if (courses) {
                for (let i = 0; i < courses.hits.length; ++i) {
                    this.courses.push(courses.hits[i]);
                }
                this.results += courses.nbHits;
            }

            const topics = content.results.find(function (element) {
                return element.index === 'topics';
            });
            if (topics) {
                for (let i = 0; i < topics.hits.length; ++i) {
                    this.topics.push(topics.hits[i]);
                }
                this.results += topics.nbHits;
            }


            const congresses = content.results.find(function (element) {
                return element.index === 'congresses';
            });
            if (congresses) {
                for (let i = 0; i < congresses.hits.length; ++i) {
                    this.congresses.push(congresses.hits[i]);
                }
                this.results += congresses.nbHits;
            }

            const companies = content.results.find(function (element) {
                return element.index === 'profile';
            });
            if (companies) {
                for (let i = 0; i < companies.hits.length; ++i) {
                    this.companies.push(companies.hits[i]);
                }
                this.results += companies.nbHits;
            }

            const pages = content.results.find(function (element) {
                return element.index === 'pages';
            });
            if (pages) {
                for (let i = 0; i < pages.hits.length; ++i) {
                    this.pages.push(pages.hits[i]);
                }
                this.results += pages.nbHits;
            }
          const articles = content.results.find(function (element) {
            return element.index === 'articles';
          });
          if (articles) {
            for (let i = 0; i < articles.hits.length; ++i) {
              this.articles.push(articles.hits[i]);
            }
            this.results += articles.nbHits;
          }
        });
    }

    getTitlesTranslated(titles) {
        if (titles[this.locale]) {
            return titles[this.locale].value;
        } else {
            return titles['en'].value;
        }
    }

  openDeleteConfirmation() {
    this.closeAllMenu();
    const modalRef = this.modalService.open(ConfirmationComponent);
    modalRef.componentInstance.title = 'account.Delete_your_account_confirm_message';
    modalRef.result.then(result => {
      this.authService.delete();
      // window.location.reload();
    }, result => {
    });
  }

  toggleSubmenu(menu) {
    // We calculate submenu height to make the CSS transition work
    const submenu = $('#' + menu + '-menu-wrapper');
    const height = this.getHeightFromChildren(submenu);

    if (submenu.css('height') === '0px') {
      // Hide all open submenus
      $('.navigation-submenu-wrapper').css('height', 0);
      submenu.css('height', height);
    } else {
      submenu.css('height', 0);
    }
  }

  getHeightFromChildren(elt) {
    let height = 0;
    Array.from(elt.children()).forEach(item => {
      height += parseInt($(item).css('height'), 10);
    });
    return height;
  }

  setNextWebinar() {
    this.webinarService.getNextWebinar().subscribe(webinar => {
      this.nextWebinar = webinar;
      if (webinar !== null) {
        if (webinar.datetime_when_in_hours == -1 && webinar.webinar_launched != 1) {
          this.showBannerWebinar = false;
        } else {
          this.showBannerWebinar = true;
          if (webinar.webinar_launched == 1) {
            this.showBannerType = 'current';
          } else if (webinar.datetime_when_in_hours >= 0 && webinar.datetime_when_in_hours <= 2) {
            this.showBannerType = 'soon';
          } else if (webinar.datetime_when_in_hours > 2 && webinar.datetime_when_in_hours <= 24) {
            this.showBannerType = 'today';
          }
        }
      }
    });
  }

  displayAuthors(article: any) {
    var authors = '';
    var first = true;
    Object.keys(article.authors).forEach(key => {
      if (first) {
        authors += article.authors[key].name;
        first = false;
      } else {
        authors += ', ' + article.authors[key].name;
      }
    });
    return authors;
  }
}

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';
import {User} from "../models/user.model";

@Injectable()
export class CartService {
  private endpoint: string = '/carts';

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getCartId(key) {
    const body = {
      key: key
    };
    return this.httpClient.post<any>(environment.apiURL + this.endpoint, body)
      .pipe(shareReplay());
  }

  getCart(cartId) {
    const reqOpts = {
      params: new HttpParams()
    };
    reqOpts.params = reqOpts.params.set('user_country', '' + 1);
    reqOpts.params = reqOpts.params.set('systempay_vads', '' + 1);
    reqOpts.params = reqOpts.params.set('payment_billing_data', '' + 1);
    reqOpts.params = reqOpts.params.set('marketplace', '' + 1);
    return this.httpClient.get<any>(environment.apiURL + this.endpoint + '/' + cartId, reqOpts)
      .pipe(shareReplay());
  }

  clean(cartId) {
    return this.httpClient.post(environment.apiURLNew + 'cart/clean'+ '/' + cartId, {})
      .pipe(shareReplay());
  }

  applyPromoCode(code, cartId) {
    const body = {
      code: code,
      purpose: 'academy_subscription',
      user_country: 1,
      payment_billing_data: 1
    };
    return this.httpClient.post<any>(environment.apiURL + this.endpoint + '/' + cartId + '/promo_code', body)
      .pipe(shareReplay());
  }

  payByBankTransfert(cartId) {
    const body = {};
    return this.httpClient.post<any>(environment.apiURLV2 + this.endpoint + '/' + cartId + '/pay_bank_transfert', body)
      .pipe(shareReplay());
  }

  deleteCurrentCartId() {
    try {
      localStorage.removeItem('currentCartId');
    } catch (e) {
    }
  }

  setCurrentCartId(cartId) {
    try {
      localStorage.setItem('currentCartId', cartId);
    } catch (e) {
    }
  }

  getCurrentCartId() {
    let currentId;
    try {
      currentId = localStorage.getItem('currentCartId');
    } catch (e) {
    }
    return currentId === 'undefined' ? null : currentId;
  }

  hasProduct(cartId){
    return this.httpClient.get(environment.apiURLNew + `cart/has-academy-subscriptions/${cartId}`);
  }
}

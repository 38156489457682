import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../../services/translation.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LocaleService} from '../../../services/locale.service';


@Component({
    selector: 'app-ending-membership-modal',
    templateUrl: './ending-membership-modal.component.html',
    styleUrls: ['./ending-membership-modal.component.scss']
})
export class EndingMembershipModalComponent implements OnInit {
    @Input() d: any;
    @Input() membershipInfo: any;
    locale: any;

    constructor(public activeModal: NgbActiveModal, public translationService: TranslationService, private localeService: LocaleService) {
    }

    close() {
        if (this.d) {
            this.d();
        } else {
            this.activeModal.close('Cross click')
        }
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
    }

}

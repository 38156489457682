import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {DateMomentService} from 'app/services/date-moment.service';
import {environment} from '../../../../../environments/environment';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {Observable} from 'rxjs/index';
import {ArticleModel} from 'app/models/article.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Theme} from 'app/models/theme.model';
import {ThemeService} from 'app/services/theme.service';
import {LocaleService} from '../../../../services/locale.service';
import {AuthService} from '../../../../services/auth.service';


@Component({
    selector: 'contributions-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss'],
    providers: [
        UserService,
        ThemeService
    ]
})
export class ContributionsArticlesComponent implements OnInit {
    user: User;
    locale: string;
    articles: ArticleModel[];
    showSubmitForm: boolean = false;
    themes$: Observable<Theme[]>;
    newArticle = new ArticleModel();
    environment: any = environment;
    tmpDate: any;
    articleSubmitted = false;
    showLoading: boolean = false;
    showAddLoading: boolean = false;
    disclosure4_details_date: Date;
    article_content_placeholder: string;
    notSubmitted: boolean = true;
    @ViewChild('theme1', {static: false}) theme1;
    @ViewChild('articleForm', {static: false}) articleForm;
    private wordslimit: number = 2500;

    constructor(private activatedRoute: ActivatedRoute,
                public translationService: TranslationService,
                public dateMomentService: DateMomentService,
                private userService: UserService,
                private localeService: LocaleService,
                private modalService: NgbModal,
                private authService: AuthService,
                private themeService: ThemeService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
        this.notSubmitted = true;
        this.showLoading = true;
        this.getUserArticles();
        this.themes$ = this.themeService.getMainThemes('alpha');
        this.article_content_placeholder = 'Guidelines for blog articles:\n' +
            ' - Between 600 and 2500 words in length\n' +
            ' - In English\n' +
            ' - You are free to write about any scientific subject you consider to be relevant'
        ;
    }

    getUserArticles() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id, {account: 1}).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                    this.userService.getUserArticles(this.user.id).subscribe(articles => {
                        this.articles = articles;
                        this.showLoading = false;
                    });
                }
            );
        }

    }

    truncateContent(event) {
        const words = this.newArticle.content.replace(/\s\s+/g, ' ').split(' ');
        if (words.length > (this.wordslimit - 1)) {
            const cut_off_over = words.slice(0, this.wordslimit).join(' ').length;
            this.newArticle.content = this.newArticle.content.slice(0, cut_off_over);
        }
    }

    updateArticleLimit() {
        if (this.newArticle.content) {
            const words = this.newArticle.content.replace(/\s\s+/g, ' ').split(' ');
            if (words.length > (this.wordslimit - 1)) {
                const cut_off_over = words.slice(0, this.wordslimit).join(' ').length;
                return cut_off_over;
            }
        }
        return 25000;
    }

    updateCounter() {
        if (this.newArticle.content) {
            const words = this.newArticle.content.replace(/\s\s+/g, ' ').split(' ');
            if (words.length > (this.wordslimit - 1)) {
                return 0;
            }
            return this.wordslimit - words.length;
        }
        return this.wordslimit;
    }

    open(content) {
        this.modalService.open(content);
    }

    addArticle() {
        this.showAddLoading = true;
        this.notSubmitted = false;
        if (this.newArticle.disclosure4_details_date && this.newArticle.disclosure4) {
            this.newArticle.disclosure4_details_date = new Date(this.newArticle.disclosure4_details_date)
                .toISOString().slice(0, 10);
        }
        if (this.articleForm.valid) {
            this.userService.createArticle(this.newArticle).subscribe(success => {
                this.articleSubmitted = true;
                this.showAddLoading = false;
                this.showSubmitForm = false;
                this.newArticle = new ArticleModel();
                this.userService.getUserArticles(this.user.id).subscribe(articles => {
                    this.articles = articles;
                    this.showLoading = false;
                });
            }, error => {
                this.showAddLoading = false;
            });
        } else {
            this.showAddLoading = false;
        }
    }
}

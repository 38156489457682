import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-no-premium',
  templateUrl: './alert-no-premium.component.html',
  styleUrls: ['./alert-no-premium.component.scss']
})
export class AlertNoPremiumComponent implements OnInit {

   constructor(public activeModal: NgbActiveModal) {
    }

  ngOnInit() {
  }

}

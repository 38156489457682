import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';

@Component({
    selector: 'app-page-partner-offer',
    templateUrl: './partner-offer.component.html',
    styleUrls: ['./partner-offer.component.scss']
})
export class PagePartnerOfferComponent implements OnInit {
    acceptRule: boolean;
    showWarning: boolean = false;
    showSuccess: boolean = false;
    newUser = {
    };

    constructor(private userService: UserService) {
    }

    ngOnInit() {
    }

    submit() {
        if (this.acceptRule) {
            this.showWarning = false;
            this.userService.updateUser(this.newUser).subscribe(success => {
                this.showSuccess = true;
            }, error1 => {
                this.showWarning = true;
            });
        } else {
            this.showWarning = true;
        }
    }
}

import {Component, OnInit} from '@angular/core';
import {Cart} from '../../../../models/cart.model';
import {CartService} from '../../../../services/cart.service';
import {PaymentService} from '../../../../services/payment.service';
import {LocaleService} from '../../../../services/locale.service';
import {TranslationService} from '../../../../services/translation.service';
import {ConfirmationComponent} from '../../../modal/confirmation/confirmation.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../models/user.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [CartService, PaymentService, TranslationService]
})
export class PaymentComponent implements OnInit {
  cart: Cart;
  locale: any;
  systempayVads: any;
  marketplace: boolean = false;
  user: User;
  withDeliveryFees: Boolean = false;
  marketplaceBankTransfer: Boolean = false;
  withDeliveryFeesValidated: Boolean = false;

  constructor(private cartService: CartService,
              private paymentService: PaymentService,
              private localeService: LocaleService,
              public translationService: TranslationService,
              private authService: AuthService,
              private modalService: NgbModal,
              private router: Router
  ) {

  }

  ngOnInit() {
    this.getCart();
    this.locale = this.localeService.currentLocale();
    this.user = this.authService.getCurrentUser();
  }

  cancelCart() {
    const modalRef = this.modalService.open(ConfirmationComponent);
    if (this.marketplace) {
      modalRef.componentInstance.title = 'Do you want to cancel your cart? You will be redirected to the marketplace.';
    } else {
      modalRef.componentInstance.title = 'Do you want to cancel your cart? You will be redirected to the premium page.';
    }
    modalRef.result.then(result => {
      this.cartService.clean(this.cart.id).subscribe(() => {
        if (this.marketplace) {
          this.router.navigate(['marketplace']);
        } else {
          this.router.navigate(['premium']);
        }
      })
    }, result => {
    });
  }

  getCart() {
    const cartId = this.cartService.getCurrentCartId();
    this.withDeliveryFees = false;
    this.marketplaceBankTransfer = false;
    this.withDeliveryFeesValidated = false;
    if (cartId) {
      this.cartService.getCart(cartId).subscribe(cart => {
        this.cart = new Cart(cart);
        this.systempayVads = [];
        if (this.cart.products[0] && this.cart.products[0].congress_product.product.marketplace_para_medias) {
          this.marketplace = true;
        }
        for (const property in this.cart.payment.systempay_vads) {
          if (this.cart.payment.systempay_vads.hasOwnProperty(property)) {
            this.systempayVads.push({property: property, value: this.cart.payment.systempay_vads[property]})
          }
        }

        if (this.cart.delivery_fees_id) {
          this.withDeliveryFees = true;
          this.withDeliveryFeesValidated = true;
        }

        if (this.cart.products[0] && this.cart.products[0].congress_product.marketplace_bank_transfer) {
          this.marketplaceBankTransfer = true;
        }

      }, error => {
        if (error.error.message.includes('already paid')) {
          this.cartService.deleteCurrentCartId();
          this.router.navigate(['premium']);
        }
      })
    } else {
      this.router.navigate(['premium']);
    }

  }

  bankTransfertValidate() {
    const modalRef = this.modalService.open(ConfirmationComponent);
    modalRef.componentInstance.title = 'Are you sure you want to validate your payment by Bank Transfert?';
    modalRef.result.then(result => {
      this.cartService.payByBankTransfert(this.cart.id).subscribe(() => {
        this.router.navigate(['cart/payment/thanks-bank-transfert']);
      })
    }, result => {
    });
  }

}

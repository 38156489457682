<h1>{{'academy.Humanitarian_missions'|translate}}</h1>
<div class="humanitarian">
	<h5>{{'academy.Are_you_involved_humanitarian'|translate}}</h5>

	<ng-template #rt let-r="result" let-t="term">
		{{r.name}}
	</ng-template>

	<div class="humanitarian-search custom-typeahead">
		<input id="typeahead-template" #input type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search" [resultTemplate]="rt"
		placeholder="{{'academy.Search_humanitarian'|translate}}" (focus)="onFocus($event)" (selectItem)="addOrganisation($event, input)"
		[inputFormatter]="formatter"/>
		<span *ngIf="searching">{{ 'academy.Searching'|translate }}...</span>
	</div>
	<div class="humanitarian-note">
		<p>{{'academy.Humanitarian_missing'|translate}} <a [routerLink]="['/contact']">{{'layout.Contact_us'|translate}}</a>.</p>
	</div>
	<div class="humanitarian-list" *ngIf="user.humanitarian_organizations">
		<div class="row">
			<div class="col-md-4" *ngFor="let organisation of user.humanitarian_organizations">
				<div class="humanitarian-item">
					<div class="humanitarian-item-remove">
						<span (click)="removeOrganisation(organisation)"><i class="fa fa-times" aria-hidden="true"></i> {{'academy.Remove'|translate}}</span>
					</div>
					<p>{{organisation.name}}</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="page">
  <app-section-top [title]="'academy.Alert'" [subtitle]="'alert.Main_subtitle'|translate" [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-alert.jpg'"></app-section-top>
  <div class="page-apply content text-center">
    <div class="row" *ngIf="group">
      <div class="col-md-12 apply">
        <h2>{{ 'alert.Join_group'|translate: {title: transService.getTranslatedName(group?.translations, locale)} }}</h2>
        <p>{{ 'alert.Background_verified'|translate }}</p>
        <h5>{{ ('alert.Topic_coordinator'|translate).split('|')[1] }}</h5>
        <ul class="coordinator">
          <li class="coordinator-item" *ngFor="let coord of group?.coordinators">
            <div [style.background]="'url(' + coord.picture_url + ')'" [style.background-size]="'cover'"
                 class="rounded-circle"></div>
            <h6>{{ coord.title + ' ' + coord.first_name + ' ' + coord.last_name }}</h6>
            <span>{{ transService.getTranslatedName(coord.specialty.translations, locale) }} - {{ transService.getTranslatedName(coord.country.translations, locale ) }}</span>
          </li>
        </ul>
<!--        <div *ngIf="isPhysicianNotVerified()">-->
<!--          <div class="alert alert-danger">-->
<!--            <p>{{'academy.diploma_required_title_alert'|translate}}</p>-->
<!--            <p>{{'academy.diploma_required_text_alert'|translate}}</p>-->
<!--            <button [routerLink]="'/account/settings'" class="button button-violet-outline">{{ 'academy.upload_diploma_now' | translate }}</button>-->
<!--          </div>-->
<!--        </div>-->
        <app-diploma-upload (statusOutput)="updateStatus($event)"></app-diploma-upload>
        <br>
        <div class="custom-control custom-checkbox" *ngIf="group && group.user_access !== 'waiting'">
          <input type="checkbox" id="accept-rules1" name="accept-rules" [(ngModel)]="acceptedTerms"
                 class="custom-control-input">
          <label class="custom-control-label" for="accept-rules1"
                 [innerHTML]="'alert.By_joining'|translate: {link: 'academy/terms-of-use/alert'}"></label>
        </div>
        <button class="button button-orange" (click)="joinGroup()"
                [disabled]="!canValidate() || group.user_access === 'waiting'">{{ group.user_access ===
          'waiting' ? ('profile.Request_sent'|translate) : ('alert.Btn_no_group'|translate: {name:
            transService.getTranslatedName(group.translations)}) }}
        </button>
      </div>
    </div>
  </div>
</div>

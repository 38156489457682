import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {App} from '@capacitor/app';
import {BranchDeepLinks, BranchInitEvent} from 'capacitor-branch-deep-links';

declare var Branch: any;

@Injectable()
export class BranchService {


  constructor(
    private ngZone: NgZone,
    private router: Router,
    private authService: AuthService
  ) {

  }

  initializeApp() {
    App.addListener('appStateChange', state => {
      if (state.isActive) {
        BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
          if (event.referringParams['+clicked_branch_link']) {
            let routerNavigation;

            console.log('Branch' + event.referringParams['$deeplink_path']);

            if (event.referringParams['$deeplink_path'] == 'blog-article') {
              routerNavigation = ['/blog', event.referringParams.article_id, event.referringParams.article_slug];
            } else if (event.referringParams['$deeplink_path'] == 'lecture') {
              routerNavigation = ['/show', event.referringParams.show_id, event.referringParams.show_slug];
            } else if (event.referringParams['$deeplink_path'] == 'course') {
              routerNavigation = ['/course', event.referringParams.course_id, event.referringParams.course_slug];
            } else if (event.referringParams['$deeplink_path'] == 'alert-case') {
              routerNavigation = ['/alert', event.referringParams.alert_group, event.referringParams.topic_id, event.referringParams.topic_slug];
            } else if (event.referringParams['$deeplink_path'] == 'profile') {
              routerNavigation = ['/profile', event.referringParams.profile_slug];
            } else if (event.referringParams['$deeplink_path'] == 'webinar') {
              routerNavigation = ['/webinar', event.referringParams.webinar_slug];
            }

            if (routerNavigation) {
              if (this.authService.getToken()) {
                this.ngZone.run(() => {
                  this.router.navigate(routerNavigation);
                })
              } else {
                localStorage.setItem('branch_redirect', JSON.stringify(routerNavigation));
              }
            }
          }
          // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
          // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
          console.log(event.referringParams);
        });

        BranchDeepLinks.addListener('initError', (error: any) => {
          console.error(error);
        });
      }
    });
  }

  /* init(){
     Branch.initSession().then((data) => {
       if (data["+clicked_branch_link"]) {

         let routerNavigation;

         if (data['$deeplink_path'] == "blog-article"){
           routerNavigation = ['/blog', data.article_id, data.article_slug];
         }
         else if (data['$deeplink_path'] == "lecture"){
           routerNavigation = ['/show', data.show_id, data.show_slug];
         }
         else if (data['$deeplink_path'] == "course"){
           routerNavigation = ['/course', data.course_id, data.course_slug];
         }
         else if (data['$deeplink_path'] == "alert-case"){
           routerNavigation = ['/alert', data.alert_group, data.topic_id, data.topic_slug];
         }
         else if (data['$deeplink_path'] == "profile"){
           routerNavigation = ['/profile', data.profile_slug];
         }
         else if (data['$deeplink_path'] == "webinar"){
           routerNavigation = ['/webinar', data.webinar_slug];
         }

         if (routerNavigation){
           if (this.authService.getToken()){
             this.ngZone.run(() => {
               this.router.navigate(routerNavigation);
             })
           }
           else {
             localStorage.setItem('branch_redirect', JSON.stringify(routerNavigation));
           }
         }
       }
     });
   }
 */
  navigateToDashboardOrBranchUrl() {
    let branchRedirect = localStorage.getItem('branch_redirect');
    let pushRedirect = localStorage.getItem('push_redirect');
    if (pushRedirect) {
      this.router.navigate([pushRedirect]);
    } else if (branchRedirect) {
      let routerNavigation = JSON.parse(branchRedirect);
      this.router.navigate(routerNavigation);
      localStorage.removeItem('branch_redirect');
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}

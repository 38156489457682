import {Injectable} from "@angular/core";

@Injectable()
export class FilesService {
  public acceptedFormat: string = ".jpg, .jpeg, .png, .gif, .HEIC, .mp4, .mov, .pdf";
  public fileTypes: Array<{type: string, extension: string}> = [
    {
      type: 'video/quicktime',
      extension: 'mov'
    },
    {
      type: "image/jpeg",
      extension: 'jpg'
    },
    {
      type: "image/jpeg",
      extension: 'jpeg'
    },
    {
      type: 'application/pdf',
      extension: 'pdf'
    },
    {
      type: 'image/gif',
      extension: 'gif'
    },
    {
      type: 'image/heic',
      extension: 'heic'
    },
    {
      type: 'image/png',
      extension: 'png'
    },
    {
      type:  'video/mp4',
      extension: 'mp4'
    }
  ];
  public imageFileTypes:Array<{type: string, extension: string}> = [
    {
      type: "image/jpeg",
      extension: 'jpg'
    },
    {
      type: "image/jpeg",
      extension: 'jpeg'
    },
    {
      type: 'image/gif',
      extension: 'gif'
    },
    {
      type: 'image/heic',
      extension: 'heic'
    },
    {
      type: 'image/png',
      extension: 'png'
    }
  ];
  public videoFileTypes: Array<{type: string, extension: string}> = [
    {
      type: 'video/quicktime',
      extension: 'mov'
    },
    {
      type:  'video/mp4',
      extension: 'mp4'
    }
  ];

  public documentFileTypes: Array<{type: string, extension: string}> = [
    {
      type: 'application/pdf',
      extension: 'pdf'
    }
  ];
  constructor() {
  }
  uploadFiles(event,  currentFilesListUploaded: Array<any>, currentFilesList?: Array<any>): {error: {name: string, subTextError: string}, newFilesList: Array<any>, } {
    const returnValue= {
      error: {
        name: '',
        subTextError: ''
      },
      newFilesList: currentFilesListUploaded
    };
    if (Object.keys(event.target.files).length <= 5 && currentFilesListUploaded.length < 5) {
      if(currentFilesList && (currentFilesList.length + Object.keys(event.target.files).length + currentFilesListUploaded.length > 5)) {
        returnValue.error.name = 'Max2';
      } else {
        Object.keys(event.target.files).map((f, i) => {
          if (currentFilesListUploaded.length < 5) {
            if(event.target.files[f].size > 104857600) /* 100 Mo */ {
              returnValue.error.name = 'Size';
              returnValue.error.subTextError = event.target.files[f].name;
            } else {
              if (this.fileTypes.find(fileType => fileType.type === event.target.files[f].type)) {
                let text = "";
                if (event.target.files[f].name.length > 30) {
                  const nameSplit = event.target.files[f].name.split('.');
                  text = nameSplit[0].substring(0, 29)+ '....'+ nameSplit[nameSplit.length - 1];
                } else {
                  text = event.target.files[f].name;
                }
                returnValue.newFilesList.push({
                  text: text,
                  file: event.target.files[f],
                  type: event.target.files[f].type.includes('video/') ? 'video' : 'picture'
                });
              }
              else {
                returnValue.error.name = 'Error';
                returnValue.error.subTextError = '.' + event.target.files[f].name.split('.').pop();
              }
            }
          } else {
            returnValue.error.name = 'Max';
          }
        });
      }
    } else {
      returnValue.error.name = 'Max';
    }
    return returnValue;
  }

  isPicture(picture) {
    if (!picture && picture.length === 0) {
      return false;
    }
    const substrings = picture.title.split('.');
    const extension = substrings !== undefined ? substrings[substrings.length - 1] : undefined;
    if (extension) {
      if (this.documentFileTypes.find(format => format.extension.toLowerCase() === extension.toLowerCase())) {
        return false;
      } else {
        this.imageFileTypes.find(format => {
          return format.extension === extension;
        })
        const match = this.imageFileTypes.find(format => format.extension.toLowerCase() === extension.toLowerCase());
        return match !== undefined;
      }
    }
    return false;
  }

  isVideo(title: string) {
    return this.videoFileTypes.find(fileType => fileType.extension.toLowerCase() === title.split('.').pop().toLowerCase()) !== undefined;
  }

  replaceFile(event,  listOfFiles: Array<any>, index: number): {error: {name: string, subTextError: string}, newFilesList: Array<any>, } {
    const returnValue= {
      error: {
        name: '',
        subTextError: ''
      },
      newFilesList: listOfFiles
    };
    Object.keys(event.target.files).map((f, i) => {
        if(event.target.files[f].size > 104857600) /* 100 Mo */ {
          returnValue.error.name = 'Size';
          returnValue.error.subTextError = event.target.files[f].name;
        } else {
          if (this.fileTypes.find(fileType => fileType.type === event.target.files[f].type)) {
            let text = "";
            if (event.target.files[f].name.length > 30) {
              const nameSplit = event.target.files[f].name.split('.');
              text = nameSplit[0].substring(0, 29)+ '....'+ nameSplit[nameSplit.length - 1];
            } else {
              text = event.target.files[f].name;
            }
            returnValue.newFilesList[index] = {
              text: text,
              file: event.target.files[f],
              type: event.target.files[f].type.includes('video/') ? 'video' : 'picture'
            };
          }
          else {
            returnValue.error.name = 'Error';
            returnValue.error.subTextError = '.' + event.target.files[f].name.split('.').pop();
          }
        }
    });
    return returnValue;
  }

  isHeic(fileTitle) {
    const substrings = fileTitle.split('.');
    const extension = substrings !== undefined ? substrings[substrings.length - 1] : undefined;
    if (extension.toLowerCase() === 'heic') {
      return true;
    }
  }
}

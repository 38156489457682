import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LocaleService} from '../../../../services/locale.service';


@Component({
    selector: 'profile-main-interests',
    templateUrl: './main-interests.component.html',
    styleUrls: ['./main-interests.component.scss']
})
export class ProfileMainInterestsComponent implements OnInit {
    locale: string;
    updated: boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
                private localeService: LocaleService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
    }

    updatedUser(event) {
        this.updated = event;
        window.setTimeout(function () {
            $('#update-success').fadeTo(500, 0).slideUp(500, function () {
                $(this).remove();
            });
        }, 4000);
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Notification} from '../../../models/notification.model';
import {NotificationService} from '../../../services/notification.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() notifications: Notification[];
  notificationsJustRead = [];
  @Output() notificationViewed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private notificationService: NotificationService) {
  }

  ngOnInit() {

  }

  readNotification(event, notification: Notification) {
    if (!notification.read && this.notificationsJustRead.indexOf(notification.id) < 0) {
      this.notificationService.markAsRead(notification.id).subscribe(() => {
        this.notificationViewed.emit();
        this.notificationsJustRead.push(notification.id);
        event.target.classList.remove('read');
      });
    }

  }

  readAllNotifications(event) {
    this.notifications.map((notification, i) => {
      if (!notification.read) {
        this.notifications[i].read = true;
      }
    });
    this.notificationService.markAllAsRead().subscribe(() => {
      this.notificationViewed.emit();
    });
  }
}

import {UserService} from '../../services/user.service';
import {ThemeService} from '../../services/theme.service';
import {Keyword} from '../../models/keyword.model';
import {Theme} from '../../models/theme.model';
import {TranslationService} from '../../services/translation.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from 'app/models/user.model';
import {AuthService} from 'app/services/auth.service';
import {LocaleService} from '../../services/locale.service';


@Component({
  selector: 'app-main-interests',
  templateUrl: './main-interests.component.html',
  styleUrls: ['./main-interests.component.scss'],
  providers: [
    TranslationService,
    ThemeService,
    UserService
  ]
})

export class MainInterestsComponent implements OnInit {
  user: User;
  @Input() from: string;
  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();
  locale: string;
  themes0: Theme[];
  themes1: Theme[];
  themes2: Theme[];
  themes3: Theme[];
  keywords0: Keyword[] = [];
  keywords1: Keyword[] = [];
  keywords2: Keyword[] = [];
  keywords3: Keyword[] = [];
  userKeywords: Keyword[] = [];
  userThemesIds: number[] = [];
  userKeywordsIds: number[] = [];
  registrationError: string;
  selectedThemes: Theme[] = [];
  showLoading: boolean = false;
  loading: boolean = false;

  constructor(public translationService: TranslationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private localeService: LocaleService,
              private themeService: ThemeService,
              private userService: UserService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.locale = this.localeService.currentLocale();
    try {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    } catch (e) {
    }
    this.showLoading = true;
    if (this.user) {
      this.userService.getUser(this.user.id, {user_themes: 1, user_keywords: 1}).subscribe(
        user => {
          this.user = new User({...this.user, ...user});
          this.getThemes();
        }
      );
    } else {
      this.getThemes();
    }
  }

  getThemes() {
    this.themeService.getMainThemes('alpha')
      .subscribe((themes: Theme[]) => {
        this.themes0 = themes;
        this.themes1 = themes;
        this.themes2 = themes;
        this.themes3 = themes;
        if (this.user) {
          this.selectTheme(0, themes, this.user);
          this.selectTheme(1, themes, this.user);
          this.selectTheme(2, themes, this.user);
          this.selectTheme(3, themes, this.user);
        }
        this.showLoading = false;
      });
  }

  selectTheme(themeNr, themes, user) {
    if (user.themes && user.themes[themeNr]) {
      const themeToSelect = themes.find(t => t.id === this.user.themes[themeNr].id);
      if (themeToSelect) {
        this.selectedThemes[themeNr] = themeToSelect;
        this.getKeywords(themeNr, themeToSelect.id);
      }
    }
  }

  getKeywords(themeNr, themeId) {
    this.themeService.getKeywords(themeId).subscribe(
      keywords => {
        for (const keyword of keywords) {
          let keywordToSelect = false;
          if (this.from !== 'create') {
            keywordToSelect = this.user.keywords.find(k => k.id === keyword.id);
          }/*
          else {
            keywordToSelect = keywords.find(k => k.id === keyword.id);
          }
           */
          if (keywordToSelect) {
            keyword.checked = true;
            this.userKeywords.push(keyword);
          } else if (this.from === 'create') {
            keyword.checked = true;
            this.userKeywords.push(keyword);
          }
        }
        switch (themeNr) {
          case 0:
            this.keywords0 = keywords;
            break;
          case 1:
            this.keywords1 = keywords;
            break;
          case 2:
            this.keywords2 = keywords;
            break;
          default:
            this.keywords3 = keywords;
            break;
        }
        this.showLoading = false;
      },
      err => {
        this.showLoading = false;
      }
    );
  }

  themeChange(themeNr, value) {
    switch (themeNr) {
      case 0:
        this.selectedThemes[0] = value;
        break;
      case 1:
        this.selectedThemes[1] = value;
        break;
      case 2:
        this.selectedThemes[2] = value;
        break;
      default:
        this.selectedThemes[3] = value;
        break;
    }
    this.getKeywords(themeNr, value.id);
  }

  keywordChange(keyword: Keyword, isChecked: boolean) {
    if (isChecked) {
      this.userKeywords.push(keyword);
    } else {
      const index = this.userKeywords.indexOf(keyword, 0);
      if (index > -1) {
        this.userKeywords.splice(index, 1);
      }
    }
  }

  addThemes() {
    this.loading = true;
    const userKeywordsIds = [];
    this.userThemesIds = [];

    this.userKeywords.forEach(function (keyword) {
      userKeywordsIds.push(keyword.id);
    });

    if (this.selectedThemes[0]) {
      this.userThemesIds.push(this.selectedThemes[0].id);
    }
    if (this.selectedThemes[1]) {
      this.userThemesIds.push(this.selectedThemes[1].id);
    }
    if (this.selectedThemes[2]) {
      this.userThemesIds.push(this.selectedThemes[2].id);
    }
    if (this.selectedThemes[3]) {
      this.userThemesIds.push(this.selectedThemes[3].id);
    }

    if (this.userThemesIds.length < 1) {
      return alert('You must select at least 1 theme');
    }
    if (userKeywordsIds.length < 2) {
      return alert('You must select at least 2 keywords');
    }

    this.userService.addUserInterests(this.userThemesIds, userKeywordsIds).subscribe(
      user => {
        this.authService.setCurrentUser(user);
        this.loading = false;
        this.updated.emit(true);
      },
      err => {
        this.loading = false;
        this.updated.emit(false);
      }
    );
  }
}

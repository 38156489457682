import {Injectable, NgZone} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {LocalStorage} from '@ngx-pwa/local-storage';

@Injectable()
export class HistoryService {

  public history = [];
  private customBackButtonEvent = new Event('customBackButton');

  constructor(
    public router: Router,
    protected localStorage: LocalStorage,
    private ngZone: NgZone) {
  }

  goBack() {
    dispatchEvent(this.customBackButtonEvent);
  }
  initHistory() {
    this.history = [...this.history, this.router.url];
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history = [...this.history, event.url];
      }
    });
  }

  navigateBack(){
    this.history.pop();
    let lastUrl = this.history.pop();
    this.ngZone.run(() => {
      this.router.navigateByUrl(lastUrl);
    });
  }
}

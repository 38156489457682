import {Country} from './country.model';
import {Specialty} from './specialty.model';
import {PaymentPrice} from './payment-price.model';

export class Payment {
  id: number;
  gender: string;
  first_name: string;
  last_name: string;
  company_name: string;
  company_function: string;
  company_vat: string;
  address_l1: string;
  address_l2: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
  user_specialty_id: number;
  country_id: number;
  user_specialty: Specialty;
  country: Country;
  card_page_action: string;
  created_at: string;
  type: string;
  currency: string;
  prices: PaymentPrice[];
  card_transaction_id: number;
  card_sub_effect_date: string;
  card_recurrence_of_payment_id: number;
  academy_doctor: boolean;
  systempay_vads: any;
  is_gift: boolean;

  delivery_first_name: string;
  delivery_last_name: string;
  delivery_company_name: string;
  delivery_address_l1: string;
  delivery_address_l2: string;
  delivery_zip: string;
  delivery_city: string;
  delivery_phone: string;
  delivery_country_id: number;
  delivery_country: Country;

  constructor(obj?: any) {
    this.id = obj && obj.id;
    this.gender = obj && obj.gender;
    this.first_name = obj && obj.first_name;
    this.last_name = obj && obj.last_name;
    this.user_specialty = obj && obj.user_specialty;
    this.country = obj && obj.country;
    this.is_gift = obj && obj.is_gift;
    if (obj) {
      this.user_specialty_id = obj.specialty ? obj.specialty.id : obj.user_specialty_id;
      this.country_id = obj.country ? obj.country.id : obj.country_id;
      this.address_l1 = obj.address ? obj.address : obj.address_l1;
      this.zip = obj.postal_code ? obj.postal_code : obj.zip;
    }
    this.company_name = obj && obj.company_name;
    this.company_function = obj && obj.company_function;
    this.company_vat = obj && obj.company_vat;
    this.address_l2 = obj && obj.address_l2 ? obj.address_l2 : '';
    this.city = obj && obj.city;
    this.phone = obj && obj.phone ? obj.phone : '';
    this.email = obj && obj.email;
    this.delivery_first_name = obj && obj.delivery_first_name;
    this.delivery_last_name = obj && obj.delivery_last_name;
    this.delivery_country = obj && obj.delivery_country;
    if (obj) {
      this.delivery_country_id = obj.delivery_country ? obj.delivery_country.id : obj.delivery_country_id;
      this.delivery_address_l1 = obj.delivery_address ? obj.delivery_address : obj.delivery_address_l1;
      this.delivery_zip = obj.delivery_postal_code ? obj.delivery_postal_code : obj.delivery_zip;
    }
    this.delivery_company_name = obj && obj.delivery_company_name;
    this.delivery_address_l2 = obj && obj.delivery_address_l2 ? obj.delivery_address_l2 : '';
    this.delivery_city = obj && obj.delivery_city;
    this.delivery_phone = obj && obj.delivery_phone ? obj.delivery_phone : '';
    this.card_page_action = obj && obj.card_page_action;
    this.currency = obj && obj.currency;
    this.type = obj && obj.type;
    this.created_at = obj && obj.created_at;
    this.card_transaction_id = obj && obj.card_transaction_id;
    this.card_sub_effect_date = obj && obj.card_sub_effect_date;
    this.card_recurrence_of_payment_id = obj && obj.card_recurrence_of_payment_id;
    this.systempay_vads = obj && obj.systempay_vads;
    this.academy_doctor = obj && obj.academy_doctor;
    if (obj && obj.prices) {
      this.prices = [];
      obj.prices.forEach(element => {
        this.prices.push(new PaymentPrice(element));
      });
    }
  }

  isRegisterSubscribePayment() {
    return this.card_page_action === 'REGISTER_SUBSCRIBE' && !this.card_recurrence_of_payment_id;
  }
}

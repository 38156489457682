<h1>{{'academy.Scientific_societies'|translate}}</h1>
<div class="societies">
	<div class="societies-search custom-typeahead">

		<ng-template #rt let-r="result" let-t="term">
			{{ r && r.country ? (translationService.getTranslatedName(r.country.translations, locale)).toUpperCase() + ' - ' + r.name + (r.acronym ? ' ('+ r.acronym +')' : '') : '' }}
		</ng-template>

		<input id="typeahead-template" #input type="text" class="form-control" [ngModel]="model" [ngbTypeahead]="search" [resultTemplate]="rt"
			placeholder="{{'academy.Search_scientific_society'|translate}}" (focus)="onFocus($event)" (selectItem)="addSociety($event, input)"
			[inputFormatter]="formatter"/>
		<span *ngIf="searching">{{ 'academy.Searching'|translate }}...</span>
		<i class="fa fa-search" aria-hidden="true"></i>
	</div>
	<div>
		{{ 'academy.Scientific_society_missing'|translate }} <a [routerLink]="'/contact'">{{ 'layout.Contact_us'|translate }}</a>.
	</div>
	<div class="societies-list" *ngIf="user.scientific_societies">
		<div class="row">
			<div class="societies-item col-md-6" *ngFor="let society of user.scientific_societies">
				<div class="societies-item-content">
					<h6>{{society.acronym}}</h6>
					<p>{{society.name}}</p>
					<span>{{translationService.getTranslatedName(society.country.translations, locale)}}</span>
				</div>
				<div class="societies-item-actions">
					<a (click)="removeSociety(society)"><i class="fa fa-trash-o" aria-hidden="true"></i> <span>{{'academy.Remove'|translate}}</span></a>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="alert alert-success alert-dismissable" *ngIf="updated">
		<button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="updated = false">×</button>
		{{'account.Update_success_flash'|translate}}
	</div> -->
</div>

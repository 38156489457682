<li class="navigation-item">
  <a (click)="toggleSubmenu('membership')" class="navigation-link">{{ 'academy.Membership'|translate }}</a>
</li>
<div class="navigation-submenu-wrapper" id="membership-menu-wrapper">
  <li class="navigation-submenu">
    <a [routerLink]="['current-plan']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-current-plan">{{ 'academy.Current_plan'|translate }}</a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['certificate']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-certificate">{{ 'academy.Certificates'|translate }}</a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['badges']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-badges">{{ 'academy.Badges'|translate }}</a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['invoices']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-invoices">{{ 'account.Your_invoices'|translate }}</a>
  </li>
</div>
<li class="navigation-item">
  <a [routerLink]="['courses']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
     class="navigation-link" id="link-courses">{{ 'academy.courses'|translate }}</a>
</li>
<li class="navigation-item">
  <a [routerLink]="['favorites']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
     class="navigation-link" id="link-favorites">{{ 'academy.Favorites'|translate }}</a>
</li>
<li class="navigation-item">
  <a (click)="toggleSubmenu('profile')" class="navigation-link">{{ 'attend.Profile'|translate }}</a>
  <p *ngIf="percent < 95" style="padding: 0 10px">
    <ngb-progressbar type="success" [value]="percent">
      {{ 'academy.profile_percent_completed'|translate:{percent: percent} }}
    </ngb-progressbar>
  </p>
</li>
<div class="navigation-submenu-wrapper" id="profile-menu-wrapper">
  <li class="navigation-submenu">
    <a [routerLink]="['professional-data']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-professional-data">
      {{ 'account.Professional_data'|translate }}
    </a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['certifications']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-certifications"
       *ngIf="user && user.specialty_category === 'physician'">
      {{ 'academy.certifications'|translate }}
    </a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['picture-and-cover']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-picture-and-cover">
      {{ 'account.Picture_cover'|translate }}
    </a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['main-interests']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-main-interests"
       *ngIf="user && user.specialty_category === 'physician'">
      {{ 'profile.Main_interests'|translate }}
    </a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['publications']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-publications"
       *ngIf="user && user.specialty_category === 'physician'">
      {{ 'profile.Publications'|translate }}
    </a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['scientific-societies']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-scientific-societies"
       *ngIf="user && user.specialty_category === 'physician'">
      {{ 'academy.Scientific_societies'|translate }}
    </a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['humanitarian-missions']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-humanitarian-missions"
       *ngIf="user && user.specialty_category === 'physician'">
      {{ 'academy.Humanitarian_missions'|translate }}
    </a>
  </li>
</div>
<li class="navigation-item">
  <a [routerLink]="['connections']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
     class="navigation-link" id="link-connections">{{ 'account.Connections'|translate }}</a>
</li>
<li class="navigation-item">
  <a (click)="toggleSubmenu('contributions')" class="navigation-link">{{ 'account.Your_contribution'|translate }}</a>
</li>
<div class="navigation-submenu-wrapper" id="contributions-menu-wrapper">
  <li class="navigation-submenu">
    <a [routerLink]="['videos']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-videos">{{ 'academy.Videos_published'|translate }}</a>
  </li>
<!--
  Page under construction
  <li class="navigation-submenu">
    <a [routerLink]="['videos-approval']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-videos-approval">{{ 'academy.Videos_approval'|translate }}</a>
  </li>
  -->
  <li class="navigation-submenu">
    <a [routerLink]="['articles']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-articles">{{ 'academy.Articles'|translate }}</a>
  </li>
</div>
<li class="navigation-item">
  <a (click)="toggleSubmenu('settings')" class="navigation-link">{{ 'account.Settings'|translate }}</a>
</li>
<div class="navigation-submenu-wrapper" id="settings-menu-wrapper">
  <li class="navigation-submenu">
    <a [routerLink]="['languages']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-languages">{{ 'academy.Languages'|translate }}</a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['change-password']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-change-password">{{ 'account.Change_password'|translate }}</a>
  </li>
  <li class="navigation-submenu">
    <a [routerLink]="['notifications-settings']" [routerLinkActive]="['active']" (click)="toggleMobileMenu()"
       class="navigation-link" id="link-notifications">{{ 'account.Email_notifications'|translate }}</a>
  </li>

  <li class="navigation-submenu">
    <a class="navigation-link" style="color:red;" (click)="openDeleteConfirmation()">{{ 'account.Delete_your_account'|translate }}</a>
  </li>

</div>
<li class="navigation-item">
  <a class="navigation-link d-none d-md-block" (click)="openLogoutConfirmation()">{{ 'account.Logout_button'|translate }}</a>
  <a class="navigation-link d-md-none d-lg-none" (click)="openLogoutConfirmation()">{{ 'account.Logout_button'|translate }}</a>
</li>

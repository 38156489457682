import {Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../../models/user.model';
import {TranslationService} from '../../../services/translation.service';
import {DateMomentService} from '../../../services/date-moment.service';
import {WebinarService} from '../../../services/webinar.service';
import {ActivatedRoute} from '@angular/router';
import {ThemeService} from '../../../services/theme.service';
import {LocaleService} from '../../../services/locale.service';
import {Theme} from '../../../models/theme.model';
import {MediaService} from '../../../services/media.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {ReportStatisticsService} from '../../../services/report-statistics.service';
import {ReportStatistics} from '../../../models/report-statistics';
import {AcademyMedia} from '../../../models/academy-media.model';

import {AuthService} from '../../../services/auth.service';
import {GoogleChartType} from "ng2-google-charts";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [
    WebinarService,
    MediaService,
    ThemeService,
    NgbTabsetConfig,
    ReportStatistics
  ]
})
export class AboutComponent implements OnInit {
  dynamicResize = true;
  geoChartData = {
    chartType: GoogleChartType.GeoChart,
    containerId: 'geoChart',
    options: {
      displayMode: 'regions',
      colorAxis: {minValue: 0, colors: ['white', 'rgb(37, 5, 83)']}},
    dataTable: [
      ['Country', 'Popularity'],
      ['Afghanistan', 41],
      ['Albania', 41],
      ['Algeria', 103],
      ['American Samoa', 10],
      ['Andorra', 16],
      ['Angola', 4],
      ['Anguilla', 9],
      ['Antigua and Barbuda', 2],
      ['Argentina', 290],
      ['Armenia', 36],
      ['Aruba', 3],
      ['Australia', 405],
      ['Austria', 113],
      ['Azerbaijan', 70],
      ['Bahamas', 11],
      ['Bahrain', 37],
      ['Bangladesh', 30],
      ['Barbados', 2],
      ['Belarus', 33],
      ['Belgium', 246],
      ['Belize', 1],
      ['Benin', 1],
      ['Bolivia', 34],
      ['Bosnia and Herzegovina', 15],
      ['Botswana', 1],
      ['Brazil', 1323],
      ['British Virgin Islands', 2],
      ['Brunei', 3],
      ['Bulgaria', 94],
      ['Burkina Faso', 1],
      ['Cambodia', 16],
      ['Cameroon', 1],
      ['Canada', 281],
      ['Cape Verde', 2],
      ['Cayman Islands', 3],
      ['Chile', 91],
      ['China', 256],
      ['Colombia', 491],
      ['Democratic Republic of the Congo', 2],
      ['Republic of the Congo', 1],
      ['Costa Rica', 25],
      ['Côte d\'Ivoire', 6],
      ['Croatia', 70],
      ['Cuba', 1],
      ['Cyprus', 36],
      ['Czech Republic', 92],
      ['Denmark', 42],
      ['Dominican Republic', 27],
      ['Ecuador', 48],
      ['Egypt', 682],
      ['El Salvador', 7],
      ['Estonia', 20],
      ['Ethiopia', 4],
      ['Faroe Islands', 1],
      ['Fiji', 1],
      ['Finland', 18],
      ['France', 1241],
      ['French Guiana', 1],
      ['French Polynesia', 2],
      ['Georgia', 65],
      ['Germany', 523],
      ['Ghana', 1],
      ['Gibraltar', 1],
      ['Greece', 431],
      ['Greenland', 1],
      ['Grenada', 2],
      ['Guadeloupe', 3],
      ['Guatemala', 15],
      ['Guyana', 1],
      ['Haiti', 2],
      ['Honduras', 13],
      ['Hong Kong SAR China', 90],
      ['Hungary', 70],
      ['Iceland', 3],
      ['India', 984],
      ['Indonesia', 1341],
      ['Iran', 253],
      ['Iraq', 218],
      ['Ireland', 56],
      ['Isle of Man', 1],
      ['Israel', 315],
      ['Italy', 539],
      ['Jamaica', 6],
      ['Japan', 106],
      ['Jersey', 1],
      ['Jordan', 90],
      ['Kazakhstan', 85],
      ['Kenya', 9],
      ['Kosovo', 7],
      ['Kuwait', 148],
      ['Kyrgyzstan', 7],
      ['Latvia', 48],
      ['Lebanon', 149],
      ['Lesotho', 1],
      ['Libya', 26],
      ['Lithuania', 64],
      ['Luxembourg', 12],
      ['Macau SAR China', 6],
      ['Macedonia', 23],
      ['Malawi', 1],
      ['Malaysia', 222],
      ['Maldives', 1],
      ['Mali', 3],
      ['Malta', 15],
      ['Martinique', 4],
      ['Mauritius', 6],
      ['Mexico', 515],
      ['Micronesia', 1],
      ['Moldova', 28],
      ['Monaco', 3],
      ['Mongolia', 11],
      ['Montenegro', 9],
      ['Morocco', 214],
      ['Mozambique', 1],
      ['Myanmar [Burma]', 27],
      ['Namibia', 1],
      ['Nepal', 35],
      ['Netherlands', 200],
      ['New Caledonia', 6],
      ['New Zealand', 27],
      ['Nicaragua', 12],
      ['Nigeria', 15],
      ['Northern Mariana Islands', 1],
      ['Norway', 44],
      ['Oman', 44],
      ['Pakistan', 136],
      ['Palestine', 11],
      ['Panama', 29],
      ['Paraguay', 19],
      ['Peru', 122],
      ['Philippines', 331],
      ['Poland', 277],
      ['Portugal', 165],
      ['Puerto Rico', 12],
      ['Qatar', 69],
      ['Réunion', 3],
      ['Romania', 337],
      ['Russia', 751],
      ['Saint Lucia', 3],
      ['Saint Martin', 1],
      ['Saint Vincent and the Grenadines', 2],
      ['Saudi Arabia', 454],
      ['Senegal', 7],
      ['Serbia', 137],
      ['Seychelles', 1],
      ['Sierra Leone', 1],
      ['Singapore', 137],
      ['Sint Maarten', 1],
      ['Slovakia', 40],
      ['Slovenia', 35],
      ['Solomon Islands', 1],
      ['Somalia', 2],
      ['South Africa', 138],
      ['South Korea', 259],
      ['Spain', 675],
      ['Sri Lanka', 12],
      ['Sudan', 11],
      ['Sweden', 96],
      ['Switzerland', 247],
      ['Syria', 17],
      ['Taiwan', 363],
      ['Tajikistan', 1],
      ['Tanzania', 2],
      ['Thailand', 437],
      ['Timor-Leste', 1],
      ['Tonga', 1],
      ['Trinidad and Tobago', 6],
      ['Tunisia', 131],
      ['Turkey', 494],
      ['Turks and Caicos Islands', 1],
      ['Uganda', 3],
      ['Ukraine', 351],
      ['United Arab Emirates', 339],
      ['United Kingdom', 610],
      ['United States', 1171],
      ['Uruguay', 12],
      ['Uzbekistan', 25],
      ['Vanuatu', 1],
      ['Venezuela', 53],
      ['Vietnam', 247],
      ['Yemen', 14],
      ['Zambia', 3],
      ['Zimbabwe', 2]
    ],
  }
  imcasMembership = {
    totalMembers: {
      value: null,
    },
    physiciansMembers: {
      value: null,
    },
    premiumMembers: {
      value: null,
    },
  };

  specialtyDistribPieChart = {
    title: '',
    chartType: GoogleChartType.PieChart,
    dataTable: [['Specialty', 'Percentage']],
    options: {
      pieHole: 0.4,
      legend: {position: 'bottom'},
      chartArea: {left: 5, width: '80%', height: '80%'},
      colors: [
        'rgb(37, 5, 83)',
        'rgb(250, 119, 50)',
        'rgb(240, 173, 138)',
        'rgb(255, 219, 74)',
      ],
      height: 300,
      width: 300
    }
  };

  geographicalDistribPieChart = {
    title: '',
    chartType: GoogleChartType.PieChart,
    dataTable: [['Continent', 'Percentage']],
    options: {
      pieHole: 0.4,
      legend: {position: 'bottom'},
      chartArea: {left: 5, width: '80%', height: '80%'},
      colors: [
        'rgb(37, 5, 83)',
        'rgb(250, 119, 50)',
        'rgb(240, 173, 138)',
        'rgb(255, 219, 74)',
      ],
      height: 300,
      width: 300
    },
  };

  specialtyTypeDistrib = {
    surgical: {
      value: null,
      percent: null,
    },
    nonSurgical: {
      value: null,
      percent: null,
    },
  };

  genderDistrib = {
    female: {
      value: null,
      percent: null,
    },
    male: {
      value: null,
      percent: null,
    },
  };

  topTenCountries = [
    {
      key: 'France',
      value: null,
      percent: null,
    }
  ];

  topFiveLanguages = [
    {
      key: '',
      value: null,
      percent: null,
    }
  ];

  library = [
    {
      key: '',
      value: '',
    }
  ];

  videosTypes = {
    sponsored: {
      value: null,
      percent: null,
    },
    nonSponsored: {
      value: null,
      percent: null,
    },
  };
  videosByLanguage = {

    title: 'Number of videos available in',
    type: 'BarChart',
    data: [['', 'Language', 'Nb videos'], ['', 0, 0]],
    options: {
      backgroundColor: {
        'fill': '#ededed',
        'opacity': 35
      }
    },
    columnNames: ['Language', 'Nb videos', {role: 'style'}],

  };

  devices = {
    Mobile: {
      value: null,
    },
    Tablet: {
      value: null,
    },
    Desktop: {
      value: null,
    },
  };

  videosViewsByYear = {
    chartType: GoogleChartType.BarChart,
    dataTable: [ ['Years', 'Nb videos', { role: 'style' }]],
    options: {
      title: 'Video view per year',
      width: window.innerWidth * 0.8,
      height: 200
    }
  };

  overviewByYear = {
    yearOne: {
      members: 2050,
      physicianMembers: 2000,
      premiumMembers: 1500,
    },
  }


  bounceRate: number;

  alertOverview = {
    Cases: {
      value: ''
    },
    AverageReponsePerCase: {
      value: ''
    },
    Responses: {
      value: ''
    },
    NumberOfReplyingExperts: {
      value: ''
    }
  };

  topTenSearch = '40,36,44,42,52,54,38,34,50,32';
  locale: string;
  innerWidth: number;
  webinarsByRegistrants = null;
  webinarsByLiveViewers = null;
  webinarsByReplayViewers = null;
  topTenVideosViewedByYear: AcademyMedia[] = [];
  videosYearTab: any = new Date().getFullYear();
  videosYearTabs: Array<Number> = [this.videosYearTab, this.videosYearTab - 1, this.videosYearTab - 2];
  environment: any = environment;
  user: User;
  themes: Array<Theme>;
  chartsReady:  boolean = false;

  constructor(config: NgbTabsetConfig,
              private activatedRoute: ActivatedRoute,
              private webinarService: WebinarService,
              private mediaService: MediaService,
              private themeService: ThemeService,
              private localeService: LocaleService,
              private reportStatService: ReportStatisticsService,
              public dateMomentService: DateMomentService,
              public translationService: TranslationService,
              public authService: AuthService,
              protected localStorage: LocalStorage) {
    config.justify = 'fill';
  }

  ngOnInit() {
    this.user = this.authService.getCurrentUser();
    this.locale = this.localeService.currentLocale();
    if (this.environment.domain === 'aesthetics') {
      this.fetchStats();
    }
  }

  fetchStats() {
    this.webinarService.webinarsByRegistrants().subscribe(
      response => {
        this.webinarsByRegistrants = response;
      }
    );
    this.webinarService.webinarsByLiveViewers().subscribe(
      response => {
        this.webinarsByLiveViewers = response;
      }
    );
    this.webinarService.webinarsByReplayViewers().subscribe(
      response => {

        this.webinarsByReplayViewers = response
          .sort(
            (a, b) => {
              if (a.replay_viewers > b.replay_viewers) {
                return -1;
              }
              if (a.replay_viewers < b.replay_viewers) {
                return 1;
              }
              return 0;
            }
          )
          .slice(0, 3);
      }
    );
    this.selectYear(this.videosYearTab);
    this.reportStatService.fetchReportStats().subscribe(
      response => {
        this.imcasMembership = response.ImcasMembership;
        this.specialtyDistribPieChart.dataTable.push(...this.formatForChartData(response.ImcasSpecialtyDistribution));
        this.geographicalDistribPieChart.dataTable.push(...this.formatForChartData(response.ImcasGeographicalDistribution));
        this.specialtyTypeDistrib = this.formatPercentFromAbsoluteValues(response.ImcasSpecialtyTypeDistribution);
        this.genderDistrib = this.formatPercentFromAbsoluteValues(response.ImcasGenderDistribution);
        this.topTenCountries = this.sortArr(response.ImcasTopTenCountries, 'value');
        this.topFiveLanguages = this.sortArr(response.ImcasPreferredLanguages, 'value');
        this.library = response.ImcasLibrary;
        this.videosTypes = this.formatPercentFromAbsoluteValues(response.ImcasVideosTypes);
        // this.videosByLanguage.dataTable[1] = this.formatForChartData(response.ImcasVideosByLanguage, true);
        this.videosByLanguage.data = this.formatForChartData(response.ImcasVideosByLanguage, true);
        this.devices = response.ImcasActivityDevice;
        this.videosViewsByYear.dataTable.push(...this.formatForChartData(response.ImcasVideosViewsByYear, true));
        this.overviewByYear = response.ImcasOverviewByYear;
        this.bounceRate = Number(response.ImcasBounceRate.bounceRate.value);
        this.alertOverview = response.ImcasAlertOverview;
        this.topTenSearch = response.ImcasTopTenSearch.themeIds.value;
        this.geoChartData.dataTable = [['Country', 'Popularity'], ...response.ImcasCountries.map((country) => {
          return [country.key, parseInt(country.value, 10)]
        })]
        this.locale = this.localeService.currentLocale();
        this.chartsReady = true;

        this.themeService.getThemes({
          ids: this.topTenSearch
        }).subscribe(
          res => {
            this.themes = [];
            res.forEach(m => {
              this.themes.push(new Theme(m));
            });

          });
      },
      err => {
      }
    );
  }


  formatForChartData(dataObject, withColors = false) {
    const colors = ['#250553', '#FA7732', '#F0AD8A', '#FFDB4A', '#FFF0B2'];
    let obj = {};
    if (withColors === true) {
      obj = Object.keys(dataObject).map(function (key, index) {
        return [key, Number(dataObject[key]['value']), colors[index]]
      });
    } else {
      obj = Object.keys(dataObject).map(function (key, index) {
        return [key, Number(dataObject[key]['value'])];
      });
    }

    const arr = [];
    for (const item of Object.keys(obj)) {
      arr.push(obj[item]);
    }
    ;

    arr.sort((a, b) => {
      return b[1] - a[1];
    });
    return arr;
  }

  formatPercentFromAbsoluteValues(dataObject, type = 'object') {
    let total = 0;
    Object.keys(dataObject).forEach(function (key) {
      dataObject[key] = {key: dataObject[key]['key'], value: Number(dataObject[key]['value'])};
      total += dataObject[key]['value'];
    });
    Object.keys(dataObject).forEach(function (key) {
      dataObject[key]['percent'] = Math.round(dataObject[key]['value'] / total * 1000) / 10;
    });
    return dataObject;
  }


  selectYear(year) {
    this.videosYearTab = year;
    this.mediaService.getTopTenVideosByYear(year).subscribe(
      response => {
        this.topTenVideosViewedByYear = [];
        response.forEach(item => {
          this.topTenVideosViewedByYear.push(new AcademyMedia(item))
        })
      }
    );
  }

  sortArr(arrayToSort, keyToSortBy) {
    return arrayToSort.sort((a, b) => {
      return parseFloat(b[keyToSortBy].replace(',', '.')) - parseFloat(a[keyToSortBy].replace(',', '.'));
    });
  }
}


  <div class="modal-body modal-no-title">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <div class="abstract">
      <div class="abstract-details text-center" *ngIf="lecture.user">
        <a routerLink="/profile/{{lecture.user.slug}}">
          <img [src]="lecture.user.picture_url"
               class="abstract-details-thumb" alt=""/>
        </a>
        <p class="abstract-details-name">{{lecture.user.title + ' ' +
        lecture.user.first_name + ' ' +
        lecture.user.last_name}}</p>
        <p class="abstract-details-degree">
          {{(lecture.user.specialty ?
          translationService.getTranslatedName(lecture.user.specialty.translations,
            locale) : '')}}
        </p>
        <p class="abstract-details-country">
          {{(lecture.user.country ?
          translationService.getTranslatedName(lecture.user.country.translations, locale)
          : '')}}
        </p>
        <p class="abstract-details-other" *ngIf="lecture && lecture.other_authors">
          {{'program.Other_authors'|translate}}: {{ lecture.other_authors}}
        </p>
      </div>
      <div class="abstract-content" *ngIf="lecture">
        <h2>{{translationService.getTranslatedAttribute('title', lecture.translations,
          locale)}}</h2>
        <p *ngIf="lecture.abstract_objectives && lecture.abstract_objectives != 'n/a'">
          {{'abstract.Objectives'|translate}}: {{lecture.abstract_objectives}}</p>
        <p *ngIf="lecture.abstract_introduction && lecture.abstract_introduction != 'n/a'">
          {{'abstract.Introduction'|translate}}: {{lecture.abstract_introduction}}</p>
        <p *ngIf="lecture.abstract_materials && lecture.abstract_materials != 'n/a'">
          {{'abstract.Materials_method'|translate}}: {{lecture.abstract_materials}}</p>
        <p *ngIf="lecture.abstract_results && lecture.abstract_results != 'n/a'">
          {{'abstract.Results'|translate}}: {{lecture.abstract_results}}</p>
        <p *ngIf="lecture.abstract_conclusion && lecture.abstract_conclusion != 'n/a'">
          {{'abstract.Conclusion'|translate}}: {{lecture.abstract_conclusion}}</p>
        <p *ngIf="lecture.abstract_content1 && lecture.abstract_content1 != 'n/a'">
          {{lecture.abstract_content1}}</p>
        <p *ngIf="lecture.abstract_content2 && lecture.abstract_content2 != 'n/a'">
          {{lecture.abstract_content2}}</p>
        <p *ngIf="lecture.abstract_content3 && lecture.abstract_content3 != 'n/a'">
          {{lecture.abstract_content3}}</p>
        <p *ngIf="lecture.abstract_content4 && lecture.abstract_content4 != 'n/a'">
          {{lecture.abstract_content4}}</p>
        <p *ngIf="lecture.abstract_content5 && lecture.abstract_content5 != 'n/a'">
          {{lecture.abstract_content5}}</p>
      </div>
    </div>
  </div>

import {Media} from './media.model';

export class Product {
  id: number;
  translations: any;
  marketplace_para_medias: any;
  privacy_policy_url: any;
  terms_of_use_url: any;
  thumb: any;
  marketplace_free_product: boolean;
  marketplace_confirmation_media_url: any;
  marketplace_confirmation_media_id: any;
  thumb_squared: any;
  medias: Media[];

  constructor(obj?: any) {
    this.id = obj && obj.id;
    this.translations = obj && obj.translations;
    this.marketplace_para_medias = obj && obj.marketplace_para_medias;
    this.marketplace_free_product = obj && obj.marketplace_free_product;
    this.marketplace_confirmation_media_url = obj && obj.marketplace_confirmation_media_url;
    this.marketplace_confirmation_media_id = obj && obj.marketplace_confirmation_media_id;
    this.privacy_policy_url = obj && obj.privacy_policy_url;
    this.terms_of_use_url = obj && obj.terms_of_use_url;
    this.thumb = obj && obj.thumb;
    this.thumb_squared = obj && obj.thumb_squared;
    if (obj && obj.medias) {
      this.medias = [];
      obj.medias.forEach(element => {
        this.medias.push(new Media(element));
      });
    }
  }
}

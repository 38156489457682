import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../models/user.model';

@Component({
    selector: 'app-social-list',
    templateUrl: './social-list.component.html',
    styleUrls: ['./social-list.component.scss']
})
export class SocialListComponent implements OnInit {
    @Input() showSocialItems: boolean;
    @Input() user: User;
    @Input() certificateItem: boolean;
    @Input() favoriteItem: boolean;
    @Input() element: any;
    @Input() twitterHref: string;
    @Input() facebookHref: string;
    @Input() linkedinHref: string;
    @Input() mailHref: string;
    @Input() favorite: boolean;
    @Output() addToFavorite: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() openCertificate: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

}

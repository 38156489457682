<div class="page container">
    <p><a [routerLink]="'/cart/subscribe'"><i class="fal fa-chevron-left"></i> {{ 'profile.Back'|translate }}</a></p>
    <h1>{{ 'cart.Payment' | translate }}</h1>
    <div class="section-header mobile-only">
        <h3>{{ 'cart.By_card'|translate }}</h3>
    </div>
    <div class="text-center mobile-only" *ngIf="cart">
        <app-form-systempay [systempayVads]="systempayVads" [action]="cart.vads_page_action"></app-form-systempay>
    </div>
  <div class="row">
      <div class="col-md-8">
          <div class="section-header">
              <h3>{{ 'cart.Recap'|translate }}</h3>
          </div>
          <app-academy-subscriptions-recap [user]="user" [cart]="cart" [locale]="locale" (newCart)="getCart()"
                                           [withDeliveryFees]="withDeliveryFees"
                                           [withDeliveryFeesValidated]="withDeliveryFeesValidated"
                                           [readOnly]="true"></app-academy-subscriptions-recap>
      </div>
      <div class="col-md-4">
        <div class="section-header" *ngIf="withDeliveryFees">
          <h3>{{ 'cart.Delivery_data'|translate }}</h3>
        </div>
        <div class="payment-billing-data" *ngIf="withDeliveryFees && cart && cart.payment">
          <a [routerLink]="'/cart/subscribe'">{{ 'cart.Edit'|translate }}</a>
          <b>{{ cart.payment.delivery_first_name }} {{ cart.payment.delivery_last_name }}</b> <br>
          <span>{{ cart.payment.delivery_city }}</span> <br>
          <span>{{ cart.payment.delivery_zip }}</span> <br>
          <span>{{ translationService.getTranslatedName(cart.payment.delivery_country.translations, locale)}}</span> <br>
          <span>{{ cart.payment.delivery_phone }}</span>
        </div>
          <div class="section-header">
              <h3>{{ 'cart.Billing_data'|translate }}</h3>
          </div>
          <div class="payment-billing-data" *ngIf="cart && cart.payment">
              <a [routerLink]="'/cart/subscribe'">{{ 'cart.Edit'|translate }}</a>
              <b>{{ cart.payment.first_name }} {{ cart.payment.last_name }}</b> <br>
              <span>{{ translationService.getTranslatedName(cart.payment.user_specialty.translations, locale)}}</span> <br>
              <span>{{ cart.payment.city }}</span> <br>
              <span>{{ cart.payment.zip }}</span> <br>
              <span>{{ translationService.getTranslatedName(cart.payment.country.translations, locale)}}</span> <br>
              <span>{{ cart.payment.email }}</span>
          </div>
      </div>
  </div>
    <div class="text-center" *ngIf="cart">
        <app-form-systempay [systempayVads]="systempayVads" [action]="cart.vads_page_action"></app-form-systempay>


      <input type="submit" *ngIf="marketplace && marketplaceBankTransfer" (click)="bankTransfertValidate()" class="button button-orange" [value]="'cart.Pay_by_bank_transfert'|translate">

    </div>
    <a class="button button-red" (click)="cancelCart()"><i class="fas fa-times-circle"></i> {{ 'cart.Cancel_your_cart_and_go_back'|translate }}</a>
</div>
